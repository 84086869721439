import React, { Component, useContext } from "react";
import Input from "../../components/fields/input/input.component";
import Button from "../../components/fields/button/button.component";
import DropDown from "../../components/fields/dropDown/dropDown.component";
import svgIcons from "../../services/icon.service";
import AddNewModal from "../../components/modal/modal.component";
import baseUrl from "../../services/baseURL.service";
import { getToken } from "../../services/authUtil";
import { NotificationContext } from "../../contexts/notficationContext";
import { BotContext } from "../../contexts/BotContext";
import { UserContext } from "../../contexts/userContext";
import { getImageUrl } from "../../services/baseURL.service";
import apiServices from "../../services/api.services";
import userLogo from "../../assets/icons/user_logo.svg";

import "./profileSettings.scss";

class ProfileSetting extends Component {
  static contextType = BotContext;
  constructor(props) {
    super(props);
    this.state = {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
      userProfile: {},
      organization: "",
      modal: "Account settings",
      hidden: true,
      newPasswordHide: true,
      confirmPasswordHide: true,
      inCorrect: false,
      isPasswordCorrect: false,
    };
    this.dropDownRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ userProfile: this.props.getUserDetails() });
  }

  componentWillUnmount() {
    this.context.setNavBarButtons("");
  }

  getUserProfile = () => {
    let { userProfile } = this.state;
    let reqOptions = {
      method: "GET",
      headers: { token: getToken() },
    };
    apiServices
      .fetchData(`${baseUrl}/get_profile`, reqOptions)
      .then((res) => {
        let obj = { ...userProfile, ...res.data };
        this.props.setUserDetails(obj);
      })
      .catch((err) => console.log(err));
  };

  toggle = (tab) => {
    this.setState({ modal: tab });
  };

  handlePasswordChange = async (e) => {
    let { oldPassword, newPassword, confirmPassword } = this.state;
    e.preventDefault();
    if (oldPassword && newPassword && newPassword === confirmPassword) {
      let formData = new FormData();
      formData.append("password", newPassword);
      const requestOptions = {
        method: "POST",
        headers: { token: getToken(), profile_settings: "yes" },
        body: formData,
      };
      try {
        const { message, status } = await apiServices.fetchData(
          `${baseUrl}/reset_password`,
          requestOptions
        );

        if (status === "success") {
          this.props.setNotifications({ color: "danger", message });
          this.setState({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            modal: false,
          });
        } else {
          this.props.setNotifications({ color: "danger", message });
        }
      } catch ({ message }) {
        this.props.setNotifications({ color: "danger", message });
        this.setState({
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
      }
    }
    if (newPassword !== confirmPassword) {
      this.setState({ inCorrect: true });
    }
  };

  togglePassword = () => {
    this.setState({ hidden: !this.state.hidden });
  };

  toggleConfirmPassword = () => {
    this.setState({ confirmPasswordHide: !this.state.confirmPasswordHide });
  };

  toggleNewPassword = () => {
    this.setState({ newPasswordHide: !this.state.newPasswordHide });
  };

  handleImgUpload = ({ target }) => {
    let file = target.files;
    if (file.length) {
      const formData = new FormData();
      formData.append("file", file[0]);
      apiServices
        .uploadImageOnCustRep(formData)
        .then((res) => {
          if (res.status === "success") {
            let { userProfile } = this.state;
            userProfile.profile_pic = res.url;
            this.setState({ userProfile }, (target.value = null));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  onSave = () => {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(this.state.userProfile),
      headers: {
        "Content-Type": "application/json",
        token: getToken(),
      },
    };
    apiServices
      .fetchData(`${baseUrl}/update_profile`, requestOptions)
      .then((response) => {
        if (response.status === "success")
          this.props.setNotifications({
            color: "success",
            message: response.message,
          });
        this.getUserProfile();
      })
      .catch((error) => {
        this.props.setNotifications({
          color: "danger",
          message: error.message,
        });
      });
  };

  render() {
    let {
      userProfile,
      organization,
      hidden,
      oldPassword,
      newPassword,
      newPasswordHide,
      confirmPassword,
      confirmPasswordHide,
      inCorrect,
      modal,
    } = this.state;
    return (
      <div className="body h-100">
        <div
          className="w-100 row m-0"
          style={{ background: "#F2F4FF", height: "255px" }}
        >
          <div className="col" style={{ maxWidth: "22rem" }}>
            <div className="rounded-circle d-flex m-auto overflow-hidden profile-img-container">
              <img
                className="profile-img m-auto rounded-circle"
                alt=""
                src={
                  userProfile.profile_pic
                    ? getImageUrl(userProfile.profile_pic)
                    : userLogo
                }
              />
            </div>
            <div className="img-edit-btn rounded-circle">
              <Input
                type="file"
                id="file"
                hidden
                onChange={this.handleImgUpload}
              />
              {/* <label className="cursor-pointer" htmlFor="file">
                {svgIcons("edit", "#fff", "", 24)}
              </label> */}
              <DropDown
                isContent
                ref={this.dropDownRef}
                isCustomDropDownList
                actionButton={() => svgIcons("edit", "#fff", "", 24)}
                dropdownList={() => (
                  <div className="">
                    <div className="col px-3 py-2 list">
                      <label className="cursor-pointer" htmlFor="file">
                        Change Photo
                      </label>
                    </div>
                    <div
                      className="col px-3 py-2 list"
                      onClick={() => {
                        this.setState({
                          userProfile: { ...userProfile, profile_pic: "" },
                        });
                        this.dropDownRef.current.toggle();
                      }}
                    >
                      <label className="cursor-pointer">Remove</label>
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
          <div className="col row m-0 align-items-end my-3">
            <span
              className={`col-auto cursor-pointer p-0 me-4 ms-3 ${
                modal === "Account settings" ? "tab" : "tab-font"
              }`}
              onClick={() => this.toggle("Account settings")}
            >
              Account settings
            </span>
            <span
              className={`col-auto cursor-pointer p-0 ${
                modal === "password" ? "tab" : "tab-font"
              }`}
              onClick={() => this.toggle("password")}
            >
              Change password
            </span>
          </div>
        </div>
        <div className="row m-0">
          <div className="col m-auto" style={{ maxWidth: "22rem" }}>
            <div className="user-info-cont ">
              <p className="m-0 profile-name-font">{`${userProfile.first_name}   ${userProfile.last_name}`}</p>
              <p className="email-font">
                {userProfile.email
                  ?.split("@")[1]
                  .substring(0, 1)
                  .toUpperCase() +
                  userProfile.email?.split("@")[1].substring(1)}
              </p>
              {/* <span className="desc-font">
                This account was created on June 7 2022, 8:30 PM
              </span> */}
            </div>
          </div>
          <div className="col">
            <div className="p-5 user-inp-card mt-3 me-4 ms-2">
              {modal === "Account settings" ? (
                <>
                  <div className="row m-0 mb-4">
                    <div className="col d-flex flex-column">
                      <label className="mb-3 text-labels">First Name</label>
                      <Input
                        className="input px-3 me-3"
                        placeholder="Enter your first name"
                        value={userProfile.first_name}
                        onChange={({ target }) => {
                          userProfile.first_name = target.value;
                          this.setState({ userProfile });
                        }}
                      />
                    </div>
                    <div className="col d-flex flex-column">
                      <label className="mb-3 text-labels">Last Name</label>
                      <Input
                        className="input px-3 me-3"
                        placeholder="Enter your last name"
                        value={userProfile.last_name}
                        onChange={({ target }) => {
                          userProfile.last_name = target.value;
                          this.setState({ userProfile });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row m-0 mb-5">
                    <div className="col d-flex flex-column">
                      <label className="mb-3 text-labels">Email Id</label>
                      <Input
                        className="col-auto email-inpt px-3 me-3"
                        readOnly
                        value={userProfile.email}
                      />
                    </div>
                    <div className="col d-flex flex-column">
                      <label className="mb-3 text-labels">Phone Number</label>
                      <Input
                        className="col-auto input px-3 me-3"
                        placeholder="Enter your contact number"
                        value={userProfile.mobile}
                        onChange={({ target }) => {
                          userProfile.mobile = target.value;
                          this.setState({ userProfile });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row m-0 justify-content-end me-4">
                    <Button
                      onClick={() =>
                        this.setState({
                          userProfile: this.props.getUserDetails(),
                        })
                      }
                      className="me-2 btn-cancel-modal px-4 col-auto"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={this.onSave}
                      className="btn-confirm-modal px-4 ms-2 col-auto"
                    >
                      Save Changes
                    </Button>
                  </div>
                </>
              ) : (
                <form
                  className="needs-validation"
                  onSubmit={this.handlePasswordChange}
                >
                  <div className="row m-0 mb-4">
                    <div className="col d-flex flex-column password">
                      <label className="mb-3 form-label text-labels">
                        Old password
                      </label>
                      <Input
                        type={hidden ? "password" : "text"}
                        className={`col-auto input p-2 form-control inp-placeholder-clr px-3 me-3`}
                        placeholder="Enter old password"
                        value={oldPassword}
                        onChange={(e) =>
                          this.setState({ oldPassword: e.target.value })
                        }
                      />
                      <i
                        className={`cursor-pointer fas ${
                          hidden ? "fa-eye" : "fa-eye-slash"
                        }`}
                        onClick={this.togglePassword}
                      />
                    </div>
                    <div className="col d-flex flex-column password confirm-password">
                      <label className="mb-3 form-label text-labels">
                        New password
                      </label>
                      <Input
                        type={newPasswordHide ? "password" : "text"}
                        className={`col-auto input p-2 form-control inp-placeholder-clr px-3 me-3`}
                        placeholder="Enter new password"
                        value={newPassword}
                        onChange={(e) =>
                          this.setState({ newPassword: e.target.value })
                        }
                      />
                      <i
                        className={`cursor-pointer fas ${
                          newPasswordHide ? "fa-eye" : "fa-eye-slash"
                        }`}
                        onClick={this.toggleNewPassword}
                      />
                    </div>
                  </div>
                  <div className="row m-0 mb-5">
                    <div className="col d-flex flex-column password">
                      <label
                        for="password"
                        className="mb-3 form-label text-labels"
                      >
                        Confirm
                      </label>
                      <Input
                        type={confirmPasswordHide ? "password" : "text"}
                        className={`${
                          inCorrect && "border border-danger"
                        } col-auto p-2 form-control inp-placeholder-clr me-3 input`}
                        placeholder="Renter new password"
                        value={confirmPassword}
                        onChange={(e) =>
                          this.setState({ confirmPassword: e.target.value })
                        }
                      />
                      {inCorrect && (
                        <div className="text-center text-danger offset-2">
                          Password mismatch..!
                        </div>
                      )}
                      <i
                        className={`cursor-pointer fas ${
                          confirmPasswordHide ? "fa-eye" : "fa-eye-slash"
                        }`}
                        onClick={this.toggleConfirmPassword}
                      />
                    </div>
                    <div className="col"> </div>
                  </div>
                  <div className="row m-0 justify-content-end me-4">
                    <Button
                      className="me-2 btn-cancel-modal px-4 col-auto"
                      onClick={() =>
                        this.setState({
                          modal: "Account settings",
                          oldPassword: "",
                          newPassword: "",
                          confirmPassword: "",
                        })
                      }
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn-confirm-modal px-4 ms-2 col-auto"
                      styles={{ width: "146px" }}
                      type="submit"
                    >
                      Confirm
                    </Button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default function ProfileSettings() {
  const { setNotifications } = useContext(NotificationContext);
  const { getUserDetails, setUserDetails } = useContext(UserContext);
  return (
    <ProfileSetting
      setNotifications={setNotifications}
      getUserDetails={getUserDetails}
      setUserDetails={setUserDetails}
    />
  );
}
