import { Component, useContext } from "react";
import Bot from "../../components/bot/bot";
import HomeRightContent from "../../components/homeRightContent/homeRightContent";
import HorizontalScroll from "../../components/horizontalScroll/horizontalScroll";

import apiService from "../../services/api.services";
import BotServices from "../../services/bot.service";

import { NotificationContext } from "../../contexts/notficationContext";
import { UserContext } from "../../contexts/userContext";

import noBot1 from "../../assets/images/no_bot_1.png";
import noBot2 from "../../assets/images/no_bot_2.png";

import "./home.scss";

class _Home extends Component {
  static contextType = NotificationContext;
  constructor(props) {
    super(props);
    this.state = {
      bots: {
        copy: [],
        original: [],
      },
      categories: [],
      isBools: [
        {
          label: "marketPlace",
          value: window.location.pathname.includes("marketPlace"),
        },
        {
          label: "sharedbots",
          value: window.location.pathname.includes("sharedbots"),
        },
      ],
      selectedItems: [
        { label: "Bot", value: "" },
        { label: "Industry", value: "all" },
      ],
      selectedBot: {},
      basicStats: {},
      graphData: [],
      isHome: window.location.pathname.includes("home") && true,
    };
  }

  componentDidMount() {
    this.isMarketPlace();
    BotServices.clearSelectedBot();
    this.getIndustries();
  }

  getIndustries = () => {
    apiService
      .getIndustries()
      .then((res) => {
        let temp = res.data.map((ele) => {
          return { label: ele.industry, value: ele.industry };
        });
        temp.unshift({ label: "All", value: "all" });
        this.setState({ categories: temp });
      })
      .catch((error) => console.log(error));
  };

  isMarketPlace = () => {
    if (this.handleBools("marketPlace")) {
      this.getTemplates();
    } else if (this.handleBools("sharedbots")) {
      this.getSharedBots();
    } else this.getBotList();
  };

  getSharedBots = async () => {
    try {
      const r = await BotServices.getSharedBots();
      if (r.status === "success") {
        const bots = { ...this.state.bots };
        bots.original = r.data;
        bots.copy = r.data;
        // const availIndustries = r.data.map((d) => d.bot_industry);
        // const categories = this.state.categories.filter(
        //   (c) => availIndustries.includes(c.value) || c.value === "all"
        // );
        this.setState({ bots }, () => {
          this.handleKnowMore(bots.copy[0]);
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  getTemplates = () => {
    BotServices.getTemplateList()
      .then((res) => {
        if (res.status === "success") {
          const bots = { ...this.state.bots };

          bots.original = res.data;
          bots.copy = res.data;
          if (bots.copy.length) this.handleKnowMore(bots.copy[0]);
          this.setState({ bots });
        }
      })
      .catch((error) => console.log(error));
  };

  getBotList = async () => {
    try {
      const { data } = await BotServices.getBotList(true);
      const bots = { ...this.state.bots };
      bots.original = data;
      bots.copy = data;
      // const availIndustries = data.map((d) => d.bot_industry);
      // const categories = this.state.categories.filter(
      //   (c) => availIndustries.includes(c.value) || c.value === "all"
      // );
      this.handleKnowMore(bots.copy[0]);
      this.setState({ bots });
    } catch (error) {
      console.log(error);
    }
  };

  handleBools = (labelName, actionType = "get", value) => {
    const isBools = [...this.state.isBools];
    const index = isBools.findIndex(({ label }) => label === labelName);

    if (actionType === "set") {
      isBools[index].value =
        value === undefined ? !isBools[index].value : value;
      return this.setState({ isBools });
    }

    return isBools[index].value;
  };

  handleSelect = (name, actionType = "get", value) => {
    const selectedItems = [...this.state.selectedItems];
    const index = selectedItems.findIndex((s) => s.label === name);

    if (actionType === "set") {
      selectedItems[index].value = value;
      return this.setState({ selectedItems });
    }
    return selectedItems[index].value;
  };

  handleKnowMore = async (bot) => {
    let basicStats = {};
    let graphData = [];
    const selectedItems = [...this.state.selectedItems];

    try {
      selectedItems[0].value = bot.bot_name || bot.template_name;

      const r = await apiService.getBasicStats(bot.bot_id);

      if (r.status === "success") {
        const { data } = r;
        basicStats = data;

        for (const key in data) {
          if (key !== "_id") {
            graphData.push(data[key]);
          }
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.setState({ selectedBot: bot, selectedItems, basicStats, graphData });
    }
  };

  handleIndustrySelection = (industry) => {
    const bots = { ...this.state.bots };
    if (industry !== "all")
      bots.copy = bots.original.filter((d) => d.bot_industry === industry);
    else bots.copy = bots.original;
    if (bots.copy.length) this.handleKnowMore(bots.copy[0]);
    else this.handleSelect("Bot", "set");
    this.setState({ bots });
  };

  handleBot = (bot) => {
    if (this.handleBools("marketPlace")) {
      this.props.permisions &&
        this.props.permisions.dashboard.marketplace === "enabled" &&
        this.props.history.push("/admin/createbot", { bot });
    } else if (
      this.state.isHome &&
      this.props.permisions &&
      this.props.permisions.my_bots.mybot_content === "enabled"
    ) {
      BotServices.setSelectedBot(bot);
      this.props.history.push("/bot/flows");
    } else if (
      window.location.pathname.includes("sharedbots") &&
      this.props.permisions &&
      this.props.permisions.dashboard.shared_bots === "enabled"
    ) {
      BotServices.setSelectedBot(bot);
      this.props.history.push("/bot/flows");
    }
  };

  deleteBot = (bot) => {
    apiService
      .deleteBot(bot)
      .then((res) => {
        if (res.status === "success") {
          this.handleSelect("Bot", "set");
          this.getBotList();
          this.context.setNotifications({
            color: "success",
            message: res.message,
          });
        } else {
          this.context.setNotifications({
            color: "danger",
            message: res.message,
          });
        }
      })
      .catch((err) => {
        this.context.setNotifications({
          color: "danger",
          message: err.message,
        });
      });
  };

  renderBots = () =>
    this.state.bots.copy.map((b, i) => {
      if (b.hasOwnProperty("bot_id") && !b.bot_id.includes("cprod"))
        return (
          <Bot
            bot={b}
            key={i}
            handleBot={this.handleBot}
            handleSelect={(botName) => this.handleSelect("Bot", "set", botName)}
            handleKnowMore={this.handleKnowMore}
          />
        );
      else if (!window.location.pathname.includes("home"))
        return (
          <Bot
            bot={b}
            key={i}
            handleBot={this.handleBot}
            handleSelect={(botName) => this.handleSelect("Bot", "set", botName)}
            handleKnowMore={this.handleKnowMore}
          />
        );
    });

  renderBotsIfAvail = () => {
    const { categories, selectedBot, basicStats, graphData } = this.state;
    return (
      <main className="home-bot-list-container">
        <div className="row m-0 h-100">
          <div className="col home-main-content-container p-5">
            <HorizontalScroll
              items={categories}
              selected_item={this.handleIndustrySelection}
            />
            {this.state.bots.copy.length ? (
              <div className="row m-0 px-5 py-5">{this.renderBots()}</div>
            ) : (
              <div className="row h-100 align-items-center">
                {this.renderNoBotsDisplay()}
                {/* <p className="text-muted text-center">Coming Soon...</p> */}
              </div>
            )}
          </div>
          <div
            className={`col home-right-content-container  ${
              this.handleSelect("Bot") && "home-know-more-panel-container"
            }`}
          >
            {this.handleSelect("Bot") && (
              <HomeRightContent
                deleteBot={this.deleteBot}
                bot={selectedBot}
                getBotList={this.getBotList}
                basicStats={basicStats}
                graphData={graphData}
              />
            )}
          </div>
        </div>
      </main>
    );
  };

  renderMarketplace = () => {
    return (
      <>
        <div className="align-items-center w-auto ms-3">
          <p className="no-bots-avail-title w-auto h-auto mt-3">
            Bots will appear here as they are added to the marketplace.
          </p>
          <p
            className="no-bots-avail-title-2 m-0 mt-3"
            style={{ color: "#868686" }}
          >
            To access marketplace bots please reach out to us through our
            contact support form.
          </p>
        </div>
        <div className="">
          <div className="d-flex">
            <img alt="No bot 1" src={noBot1} />
            <img alt="No bot 2" className="align-self-end ms-3" src={noBot2} />
          </div>
        </div>
      </>
    );
  };

  renderNoBotsDisplay = () => {
    const { isHome } = this.state;
    return (
      <main className="bg-light mt-1 h-100">
        <div
          className={`container d-flex justify-content-center align-items-center h-100 ${
            this.handleBools("marketPlace") ? "flex-row-reverse" : "flex-column"
          }`}
        >
          {!this.handleBools("marketPlace") && (
            <p
              className="no-bots-avail-title"
              style={{ width: !isHome && "auto" }}
            >
              {this.handleBools("sharedbots")
                ? "Bots will appear here once someone shares a bot with you"
                : "Bots will appear here once you create bot"}
            </p>
          )}
          {this.handleBools("marketPlace") && this.renderMarketplace()}
          {!this.handleBools("marketPlace") && (
            <div className="row">
              <div className="col text-end">
                <div className="d-flex">
                  <img alt="No bot 1" src={noBot1} />
                  <img
                    alt="No bot 2"
                    className="align-self-end ms-3"
                    src={noBot2}
                  />
                </div>
              </div>
              {isHome && (
                <div className="col-auto">
                  <div className="d-flex flex-column h-100 justify-content-end">
                    <p className="no-bots-avail-title-2">To Create bot</p>
                    <p className="no-bots-avail-steps">
                      1. Choose from market place template (or)
                    </p>
                    <p className="no-bots-avail-steps">2. Select Create bot</p>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </main>
    );
  };

  render() {
    return this.state.bots.original.length
      ? this.renderBotsIfAvail()
      : this.renderNoBotsDisplay();
  }
}

function Home(props) {
  const { getUserDetails } = useContext(UserContext);
  return <_Home {...props} permisions={getUserDetails().feature_permission} />;
}

export default Home;
