import botService from "../bot.service";
import baseUrl from "../baseURL.service";
import { getToken } from "../authUtil";
import apiServices from "../api.services";

const fetchData = (url, options) => {
  return apiServices.fetchData(url, { ...options });
};
const reqOpts = () => ({
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    token: getToken(),
  },
});

const getBotUtterances = () => {
  let bot_id = botService.getSelectedBotId();
  let reqOptions = {
    method: "GET",
    headers: {
      token: getToken(),
    },
  };
  return fetchData(`${baseUrl}/get_bot_utterances_list/${bot_id}`, reqOptions);
};

const addBotUtterance = (dataObj, isShortMsg) => {
  let bot_id = botService.getSelectedBotId();
  const rO = reqOpts();
  rO.body = JSON.stringify(dataObj);

  return fetchData(
    `${baseUrl}/add_bot_utterance/${bot_id}?short_message=${
      isShortMsg ? "yes" : "no"
    }`,
    rO
  );
};

const renameBotUtterance = (dataObj) => {
  let bot_id = botService.getSelectedBotId();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };
  return fetchData(`${baseUrl}/rename_bot_utterance/${bot_id}`, requestOptions);
};

const getBotUtteranceDetails = (intent) => {
  let bot_id = botService.getSelectedBotId();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  return fetchData(
    `${baseUrl}/get_utterance_responses/${bot_id}?utterance=${intent}`,
    requestOptions
  );
};

const deleteBotUtterance = (dataObj) => {
  let bot_id = botService.getSelectedBotId();
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };
  return fetchData(`${baseUrl}/delete_bot_utterance/${bot_id}`, requestOptions);
};

const uploadAudio = (formData, isShortMsg) => {
  let bot_id = botService.getSelectedBotId();
  const requestOptions = {
    method: "POST",
    headers: {
      token: getToken(),
    },
    body: formData,
  };
  return fetchData(
    `${baseUrl}/upload_audio_response/${bot_id}?short_message=${
      isShortMsg ? "yes" : "no"
    }`,
    requestOptions
  );
};

const getAudio = (dataObj) => {
  let bot_id = botService.getSelectedBotId();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };
  return fetchData(`${baseUrl}/get_presigned_url/${bot_id}`, requestOptions);
};

const deleteAudio = (dataObj, isShortMsg) => {
  let bot_id = botService.getSelectedBotId();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };
  return fetchData(
    `${baseUrl}/delete_audio_response/${bot_id}?short_message=${
      isShortMsg ? "yes" : "no"
    }`,
    requestOptions
  );
};

const updateBotUtterance = (dataObj, isShortMsg) => {
  let bot_id = botService.getSelectedBotId();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };
  return fetchData(
    `${baseUrl}/modify_bot_utterance/${bot_id}?short_message=${
      isShortMsg ? "yes" : "no"
    }`,
    requestOptions
  );
};

const uploadImgForUtterance = (formData) => {
  const bot_id = botService.getSelectedBotId();

  const requestOptions = {
    method: "POST",
    headers: {
      token: getToken(),
    },
    body: formData,
  };

  return fetchData(`${baseUrl}/upload_image/${bot_id}`, requestOptions);
};

const importResponses = (file) => {
  const bot_id = botService.getSelectedBotId();
  let formData = new FormData();
  formData.append("file", file);
  let ext = file?.name?.split(".").pop();
  let url =
    ext === "json"
      ? `import_json/${bot_id}?config_import_key=responses&update_config_data=True`
      : `import_responses/${bot_id}`;

  const requestOptions = {
    method: "POST",
    headers: {
      token: getToken(),
    },
    body: formData,
  };

  return fetchData(`${baseUrl}/${url}`, requestOptions);
};

const utteranceAPI = {
  addBotUtterance,
  deleteBotUtterance,
  getBotUtteranceDetails,
  getBotUtterances,
  importResponses,
  renameBotUtterance,
  updateBotUtterance,
  uploadImgForUtterance,
  uploadAudio,
  getAudio,
  deleteAudio,
};
export default utteranceAPI;
