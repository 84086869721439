import React, { useContext, useState, useRef, useEffect } from "react";
import { Handle, Position } from "react-flow-renderer";
import {
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
  Row,
  Col,
  Dropdown,
  UncontrolledTooltip,
} from "reactstrap";
import "./nodes.scss";
import DeleteConfirmation from "../../components/deleteConfirmation.component";
import { BotContext } from "../../contexts/BotContext";
import { UserContext } from "../../contexts/userContext";
import copyIcon from "../../assets/icons/copy.svg";
import deleteIcon from "../../assets/icons/delete.svg";
import hideEye from "../../assets/icons/hide.svg";
import verticalOptions from "../../assets/icons/vertical-options.svg";
import SelectSearch from "./SelectSearch";
import DropDown from "../../components/fields/dropDown/dropDown.component";
import Input from "../../components/fields/input/input.component";
import svgIcons from "../../services/icon.service";
import intentServices from "../../services/api.services/intentsApi.service";
import utteranceServices from "../../services/api.services/utteranceApi.service";
import botService from "../../services/bot.service";

const botType = {
  Bot: "bot",
  User: "user",
  Decision: "decision",
  Restart: "restart",
  EOC: "eoc",
  TTA: "tta",
  DTMF: "dtmf-input",
  SLOT: "slot",
  FORM: "form",
  Event: "event",
  Action: "action",
  Flow: "flow",
};

export const BotNode = ({ data, isConnectable, id, selected }) => {
  const context = useContext(BotContext);
  const { getUserDetails } = useContext(UserContext);

  return getNode(
    data,
    isConnectable,
    id,
    selected,
    context,
    getUserDetails().feature_permission
  );
};

export const ActionNode = ({ data, isConnectable, id, selected }) => {
  const context = useContext(BotContext);
  const { getUserDetails } = useContext(UserContext);

  return getNode(
    data,
    isConnectable,
    id,
    selected,
    context,
    getUserDetails().feature_permission
  );
};

export const UserNode = ({ data, isConnectable, id, selected }) => {
  const context = useContext(BotContext);
  const { getUserDetails } = useContext(UserContext);

  return getNode(
    data,
    isConnectable,
    id,
    selected,
    context,
    getUserDetails().feature_permission
  );
};

export const DecisionNode = ({ data, isConnectable, id, selected }) => {
  const context = useContext(BotContext);
  const { getUserDetails } = useContext(UserContext);

  return getNode(
    data,
    isConnectable,
    id,
    selected,
    context,
    getUserDetails().feature_permission
  );
};

export const RestartNode = ({ data, isConnectable, id, selected }) => {
  const context = useContext(BotContext);
  const { getUserDetails } = useContext(UserContext);

  return getNode(
    data,
    isConnectable,
    id,
    selected,
    context,
    getUserDetails().feature_permission
  );
};

export const EventNode = ({ data, isConnectable, id, selected }) => {
  const context = useContext(BotContext);
  const { getUserDetails } = useContext(UserContext);

  return getNode(
    data,
    isConnectable,
    id,
    selected,
    context,
    getUserDetails().feature_permission
  );
};

export const EOCNode = ({ data, isConnectable, id, selected }) => {
  const context = useContext(BotContext);
  const { getUserDetails } = useContext(UserContext);

  return getNode(
    data,
    isConnectable,
    id,
    selected,
    context,
    getUserDetails().feature_permission
  );
};

export const TTANode = ({ data, isConnectable, id, selected }) => {
  const context = useContext(BotContext);
  const { getUserDetails } = useContext(UserContext);

  return getNode(
    data,
    isConnectable,
    id,
    selected,
    context,
    getUserDetails().feature_permission
  );
};

export const DTMFNode = ({ data, isConnectable, id, selected }) => {
  const context = useContext(BotContext);
  const { getUserDetails } = useContext(UserContext);

  return getNode(
    data,
    isConnectable,
    id,
    selected,
    context,
    getUserDetails().feature_permission
  );
};

export const SlotNode = ({ data, isConnectable, id, selected }) => {
  const context = useContext(BotContext);
  const { getUserDetails } = useContext(UserContext);

  return getNode(
    data,
    isConnectable,
    id,
    selected,
    context,
    getUserDetails().feature_permission
  );
};

export const FormNode = ({ data, isConnectable, id, selected }) => {
  const context = useContext(BotContext);
  const { getUserDetails } = useContext(UserContext);

  return getNode(
    data,
    isConnectable,
    id,
    selected,
    context,
    getUserDetails().feature_permission
  );
};

export const StartNode = ({ data, isConnectable, id, selected }) => {
  const context = useContext(BotContext);
  const { getUserDetails } = useContext(UserContext);
  return (
    <div
      className="start-node"
      style={{ width: "16.25rem", textAlign: "-webkit-center" }}
    >
      {getStartBody(data, id, context)}
      {getBottomHandle(data, id, context, getUserDetails().feature_permission)}
    </div>
  );
};

export const LinkNode = ({ data, isConnectable, id, selected }) => {
  const context = useContext(BotContext);
  return (
    <div
      className="start-node"
      style={{ width: "16.25rem", textAlign: "-webkit-center" }}
    >
      {getTopHandle(data, id, context)}
      {GetLinkBody(data, id, context)}
    </div>
  );
};

const getNode = (data, isConnectable, id, selected, context, permission) => {
  // let { who, isBranchNode } = data.exts;
  // var isToolBox = true;
  var type = "";
  return (
    <>
      {getTopHandle(data)}
      {GetBody(data, isConnectable, id, selected, context, type)}
      {getBottomHandle(data, id, context, permission)}
    </>
  );
};

const GetBody = (data, isConnectable, id, selected, context, type) => {
  const [newPhrase, setNewPhrase] = useState();
  const [modal, setModal] = useState(false);
  const [intents, setIntents] = useState([]);
  const [slotSelection, setslotSelection] = useState("");

  const ref = useRef();

  const { getUserDetails } = useContext(UserContext);

  let { who, selections, pathName, checkpoints } = data.exts;

  let dropDownList = [{ icon: getDeleteModal(), label: "Delete" }];

  useEffect(() => {
    if (data.exts.checkpoints && data.exts.type === "user") {
      let removed = {};
      let temp = {};
      let intents = context.getIntents();
      let some = Object.values(data.exts.decisionData);

      some.forEach((bundle, i) => {
        temp[i] = {};
        Object.keys(bundle).forEach((pId) => {
          temp[i]["paths"] = temp[i]["paths"] || [];
          temp[i]["paths"].push(pId);
          bundle[pId].forEach((obj, ix) => {
            temp[i]["intents"] = temp[i]["intents"] || [];
            temp[i]["intents"].push(obj.intent);
          });
        });
      });

      Object.keys(temp).forEach((index) => {
        if (temp[index]["paths"].includes(pathName)) {
          let x = temp[index]["intents"];
          let final = intents.filter((int) => !x.includes(int));

          removed[pathName] = final;
        }
      });
      setIntents(removed);
    }
  }, []);

  let otherDropDown = (isUsed) => [
    { icon: getDeleteModal(), label: "Delete" },
    { icon: svgIcons("add"), label: "OR Intent", isUsed },
  ];

  const reply = (who) => {
    if (who === botType.User) {
      if (checkpoints && intents[pathName]) return intents[pathName];
      return context.getIntents();
    } else if (who === botType.Bot) {
      return context.getUtterances();
    } else if (who === botType.FORM) {
      return context.getForms();
    } else if (who === botType.SLOT) {
      return Object.keys(context.getSlots());
    } else if (who === botType.Action) {
      return context.getActions();
    }
  };

  function handleAddNewIntent(who, newSentence) {
    switch (who) {
      case "user":
        intentServices
          .addBotIntent({
            intent: newSentence.title,
            data: [newSentence.phrase],
          })
          .then((res) => {
            if (res.status === "success") {
              context.setIntents();
              context.updateNode({ id, data }, newSentence.title);
              context.setAddNewIntent(null);
              setNewPhrase("");
            }
          });
        break;
      case "bot":
        utteranceServices
          .addBotUtterance({
            utterance: newSentence.title,
            data: [{ text: newSentence.phrase }],
          })
          .then((res) => {
            if (res.status === "success") {
              context.setUtterances();
              context.updateNode({ id, data }, newSentence.title);
              context.setAddNewIntent(null);
              setNewPhrase("");
            }
          });
        break;
      default:
        break;
    }
  }

  function getDeleteModal() {
    return (
      <DeleteConfirmation
        open={modal}
        delete={() => context.deleteNode({ data, id })}
      />
    );
  }

  function getIntentPhraseEntityView(phrase) {
    let splitArr = phrase.split(/([\[\}]+)/);
    let setArray = [];
    splitArr.forEach((ele) => {
      if (["[", "}}"].indexOf(ele) > -1) {
        setArray.push("");
      } else if (ele.indexOf("]${{") > -1) {
        setArray.push({
          type: "span",
          txt: ele.split("]${{")[0],
        });
      } else if (ele.trim() !== "") {
        setArray.push({
          type: "noSpan",
          txt: ele,
        });
      }
    });
    if (phrase.indexOf("[") > -1) {
      return setArray.map((ele, index) => {
        return getEntityStr2(ele, index);
      });
    } else {
      return <span>{phrase}</span>;
    }
  }

  function getEntityStr2(ele, index) {
    if (ele !== "" && ele.type === "span") {
      return (
        <span
          key={index}
          style={{ backgroundColor: "#D9736A", border: "1px solid red" }}
          className="p-0 px-1 mx-1 text-white btn-sm rounded-2 btn position-relative"
        >
          {ele.txt}
        </span>
      );
    } else if (ele !== "" && ele.type === "noSpan") {
      return <span key={index}>{ele.txt}</span>;
    } else {
      return;
    }
  }

  let savedSlotKey =
    who === botType.SLOT && selections && Object.keys(selections)[0];

  let slotOptins =
    context.getSlots() &&
    context.getSlots()[slotSelection?.key] &&
    context.getSlots()[slotSelection.key].map((ele) => {
      return { label: ele, value: ele };
    });

  return who === botType.Decision ? (
    getDecisionBody(data, id, context)
  ) : who === botType.Restart ? (
    getRestartBody(data, id, context)
  ) : who === botType.EOC ? (
    getEOCBody(data, id, context)
  ) : who === botType.TTA ? (
    getTTABody(data, id, context)
  ) : who === botType.DTMF ? (
    GetDTMFBody(data, id, context)
  ) : who === botType.Event ? (
    GetEventBody(data, id, context)
  ) : (
    <div className="bg-transparent border-0" style={{ width: "16.25rem" }}>
      <div
        style={{
          borderRadius: ` ${
            !["action", "form"].includes(who) ? ".8rem .8rem 0 0" : ".8rem"
          }`,
        }}
        className={`row header bg-shadow mx-0 ${
          who === botType.Bot
            ? "bg-black text-white"
            : who === botType.User
            ? "bg-light_blue"
            : who === botType.Action
            ? "bg-action text-white"
            : who === botType.SLOT
            ? "bg-slot"
            : who === botType.FORM
            ? "bg-form"
            : "bg-gray"
        }`}
      >
        <div style={{ alignSelf: "center" }} className="col-auto p-2">
          {who === botType.Bot
            ? svgIcons("flowBot", "#fff")
            : who === botType.Action
            ? svgIcons("flowBot", "#fff")
            : who === botType.User
            ? svgIcons("flowUser")
            : who === botType.SLOT
            ? svgIcons("flowSlot")
            : svgIcons("flowForm")}
        </div>
        <div
          style={{ alignSelf: "center" }}
          className={`${
            data.exts.index === 0 ? "col" : "col-9"
          } d-flex justify-content-center px-0`}
          id={`${selections}_header`}
        >
          <SelectSearch
            who={who}
            id={id}
            disabled={
              context.displayState ||
              getUserDetails()?.feature_permission?.flowChart?.edit ===
                "disabled"
            }
            // pathName={data.exts.pathName}
            selections={
              who === botType.SLOT && selections
                ? Object.keys(selections)[0]
                : selections
            }
            selectionList={reply(who)}
            onChange={(e, isAddNew) => {
              if (!isAddNew) {
                e &&
                  e.length > 0 &&
                  who !== botType.SLOT &&
                  context.updateNode({ data, id }, e[0].label);
                if (e && e.length > 0 && who === botType.SLOT) {
                  setslotSelection({ key: [e[0].label], id: id });
                  context.updateNode(
                    { data, id },
                    { [e[0].label]: "" },
                    "slot-category"
                  );
                }
              } else {
                if (e) {
                  context.setAddNewIntent({ id, title: e.label });
                }
              }
            }}
          />
          {/* {selections &&
            !!selections.length &&
            who !== botType.SLOT &&
            !context.displayState && (
              <UncontrolledTooltip
                placement="right"
                target={`${selections}_header`}
              >
                {selections}
              </UncontrolledTooltip>
            )} */}
        </div>
        <div
          style={{ alignSelf: "center" }}
          className={`${
            data.exts.index === 0 ? "col-auto" : "col-1"
          } p-0 align-self-center`}
        >
          <DropDown
            disabled={
              (data.exts.pathName === "main_path" && who === botType.Action) ||
              context.displayState ||
              getUserDetails()?.feature_permission?.flowChart?.edit ===
                "disabled"
            }
            actionButton={() =>
              svgIcons(
                "verticalOptions",
                [botType.Bot, botType.Action].includes(who) && "white"
              )
            }
            btnClassName="px-1"
            dropdownList={
              // data.exts.index === 0
              who === "user"
                ? data.exts.index === 0
                  ? otherDropDown(
                      data.exts.checkpoints[data.exts.pathName]?.length >= 6
                    )
                  : otherDropDown(
                      typeof data.exts.intent === "object" &&
                        data.exts.intent?.length >= 6
                    )
                : dropDownList
            }
            onActionItemClick={(menu) => {
              if (menu.label === "Delete") {
                setModal(true);
                setTimeout(() => {
                  setModal(false);
                }, 100);
              } else if (menu.label === "OR Intent") {
                context.addAnotherIntent({ data, id });
              }
            }}
          />
        </div>
      </div>
      {!["action", "form"].includes(who) && (
        <div style={{ height: "5rem" }}>
          <div
            className={`bg-shadow row h-100 content py-2 bg-white shadow-sm m-0`}
            style={{ borderRadius: "0 0 .5rem .5rem" }}
          >
            <div
              className="col p-0 px-1 text-center m-1 bg-shadow rounded text-left node-body-font"
              style={
                who !== botType.SLOT
                  ? {
                      width: "15rem",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }
                  : {}
              }
            >
              {who === botType.User ? (
                context.getNewIntent() && context.getNewIntent().id === id ? (
                  <Input
                    className="user-body-input bg-dark border-white border w-100 rounded-3 text-white px-3"
                    placeholder="Add new intent phrase"
                    value={newPhrase}
                    onChange={(e) => {
                      setNewPhrase(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleAddNewIntent(who, {
                          ...context.getNewIntent(),
                          phrase: newPhrase || "",
                        });
                      }
                    }}
                    autoFocus
                  />
                ) : (
                  <p
                    className="node-body-text"
                    id={`${selections}-${data.parentIndex}-body`}
                  >
                    {getIntentPhraseEntityView(
                      context.getIntentPhrase(selections)
                    )}
                  </p>
                )
              ) : who === botType.Bot ? (
                context.getNewIntent() && context.getNewIntent().id === id ? (
                  <Input
                    className="user-body-input bg-lgt_blue border-white border w-100 rounded-3 text-dark px-3"
                    placeholder="Add new utterence phrase"
                    value={newPhrase}
                    onChange={(e) => {
                      setNewPhrase(e.target.value);
                    }}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleAddNewIntent(who, {
                          ...context.getNewIntent(),
                          phrase: newPhrase || "",
                        });
                      }
                    }}
                    autoFocus
                  />
                ) : (
                  <p
                    className="node-body-text"
                    id={`${selections}-${data.parentIndex}-body`}
                  >
                    {context.getUtterancePhrase(selections)}
                  </p>
                )
              ) : who === botType.SLOT ? (
                <DropDown
                  caret={true}
                  actionButton={() =>
                    // slotSelection.value ||
                    selections[savedSlotKey] || "Select a Category"
                  }
                  dropdownList={slotOptins}
                  onActionItemClick={(m) => {
                    slotSelection.id &&
                      id === slotSelection.id &&
                      context.updateNode(
                        { data, id },
                        { [slotSelection.key]: m.label },
                        "slot-category"
                      );
                    setslotSelection({
                      key: slotSelection.key,
                      id: id,
                      value: m.label,
                    });
                  }}
                />
              ) : (
                ""
              )}
              {/* {(context.getUtterancePhrase(selections) ||
                context.getIntentPhrase(selections)) &&
                who !== botType.SLOT &&
                !context.displayState && (
                  <UncontrolledTooltip
                    placement="right"
                    target={`${selections}-${data.parentIndex}-body`}
                  >
                    {who === botType.Bot
                      ? context.getUtterancePhrase(selections)
                      : getIntentPhraseEntityView(
                          context.getIntentPhrase(selections)
                        )}
                  </UncontrolledTooltip>
                )} */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const getTopHandle = (data) => {
  return (
    !data?.exts?.isRoot && (
      <Handle type="target" position={Position.Top} isConnectable={false} />
    )
  );
};

const getBottomHandle = (data, id, context, permission) => {
  return (
    <>
      {!["restart", "eoc", "tta", "dtmf", "event"].includes(data?.exts?.who) ? (
        <Handle
          type="source"
          position={Position.Bottom}
          isConnectable={false}
        />
      ) : (
        ""
      )}
      {["restart", "eoc", "tta", "dtmf", "event"].indexOf(data?.exts?.who) ===
        -1 &&
      !data.isChildren &&
      data.exts.pathName !== "main_path" &&
      !context.displayState &&
      permission &&
      permission.flowChart.edit !== "disabled" ? (
        GetAddButton(data, id, context)
      ) : data.exts.pathName === "main_path" &&
        data?.exts?.who === "action" &&
        permission &&
        permission.flowChart.edit !== "disabled" ? (
        GetAddButton(data, id, context)
      ) : (
        <></>
      )}
    </>
  );
};

const getDecisionBody = (data, id, context) => {
  return (
    <div id="diamond" className="text-light">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        className="bi bi-diagram-3 position-absolute"
        viewBox="0 0 16 16"
        style={{ top: "15px", left: "-10" }}
      >
        <path
          fillRule="evenodd"
          d="M6 3.5A1.5 1.5 0 0 1 7.5 2h1A1.5 1.5 0 0 1 10 3.5v1A1.5 1.5 0 0 1 8.5 6v1H14a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0V8h-5v.5a.5.5 0 0 1-1 0v-1A.5.5 0 0 1 2 7h5.5V6A1.5 1.5 0 0 1 6 4.5v-1zM8.5 5a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1zM0 11.5A1.5 1.5 0 0 1 1.5 10h1A1.5 1.5 0 0 1 4 11.5v1A1.5 1.5 0 0 1 2.5 14h-1A1.5 1.5 0 0 1 0 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5A1.5 1.5 0 0 1 7.5 10h1a1.5 1.5 0 0 1 1.5 1.5v1A1.5 1.5 0 0 1 8.5 14h-1A1.5 1.5 0 0 1 6 12.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1zm4.5.5a1.5 1.5 0 0 1 1.5-1.5h1a1.5 1.5 0 0 1 1.5 1.5v1a1.5 1.5 0 0 1-1.5 1.5h-1a1.5 1.5 0 0 1-1.5-1.5v-1zm1.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1z"
        />
      </svg>
      <b
        className="position-absolute m-0"
        style={{ top: "38px", left: "-37px", zIndex: "999" }}
      >
        Condition
      </b>

      <div
        className="position-absolute"
        style={{ zIndex: "999", top: "60px", left: "-18px" }}
      >
        <DeleteConfirmation
          delete={() => {
            context.removeNode({ data, id });
          }}
        />
      </div>
    </div>
  );
};

const getRestartBody = (data, id, context) => {
  return (
    <div className="bg-transparent border-0" style={{ width: "16.25rem" }}>
      <div style={{ padding: ".2rem" }}>
        <div
          className="bg-shadow row h-100 content bg-white m-0"
          style={{ borderRadius: ".5rem" }}
        >
          <div
            className="col p-0 py-2 text-center alert-info m-1 bg-shadow rounded text-left"
            style={{ width: "15rem" }}
          >
            <b>
              <strong>Restart</strong>
              <br />
            </b>
            <DeleteConfirmation
              delete={() => {
                context.removeNode({ data, id });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const getEOCBody = (data, id, context) => {
  return (
    <div className="bg-transparent border-0" style={{ width: "16.25rem" }}>
      <div style={{ padding: ".2rem" }}>
        <div
          className="bg-shadow row h-100 content bg-white m-0"
          style={{ borderRadius: ".5rem" }}
        >
          <div
            className="col p-0 py-2 text-center alert-danger m-1 bg-shadow rounded text-left"
            style={{ width: "15rem" }}
          >
            <b>
              <strong>End Call</strong>
              <br />
            </b>
            <DeleteConfirmation
              delete={() => {
                context.removeNode({ data, id });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const getTTABody = (data, id, context) => {
  let dropDownList = [
    { alt: "View tool bar", img: hideEye, label: "View tool bar" },
    {
      alt: "Copy",
      img: copyIcon,
      label: "Copy",
    },
    { alt: "Delete", img: deleteIcon, label: "Delete" },
  ];

  const renderDropDowns = () => {
    return dropDownList.map((d, i) => (
      <DropdownItem
        className="bot-dropdown-list d-flex align-items-center"
        key={i}
      >
        <img
          alt={d.alt}
          className="img-fluid"
          style={{ height: "25px" }}
          src={d.img}
        />
        <span className="ps-2 pt-1 text-dark fs-5">{d.label}</span>
      </DropdownItem>
    ));
  };

  return (
    <div className="bg-transparent border-0" style={{ width: "16.25rem" }}>
      <div
        style={{ borderRadius: ".8rem .8rem 0 0" }}
        className={`row header mx-0 bg-secondary text-light`}
      >
        <div style={{ alignSelf: "center" }} className="col-auto">
          <i className="fas fa-headset"></i>
        </div>
        <div style={{ alignSelf: "center" }} className="col">
          Transfer to Agent
        </div>
        <div style={{ alignSelf: "center" }} className="col-auto p-0">
          <UncontrolledDropdown>
            <DropdownToggle color="white" style={{ outline: "none" }}>
              <img src={verticalOptions} alt="dots" />
            </DropdownToggle>
            <DropdownMenu style={{ width: "15rem" }}>
              {" "}
              {renderDropDowns()}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
      <div className="bg-shadow">
        <div
          className="bg-dark row h-100 content m-0"
          style={{ borderRadius: "0 0 .5rem .5rem" }}
        >
          <div
            className="col p-0 py-2 text-center m-1 rounded text-left"
            style={{ width: "15rem" }}
          >
            <span className="text-white">9879539093</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const GetEventBody = (data, id, context) => {
  const [modal, setModal] = useState(false);

  const { getUserDetails } = useContext(UserContext);

  let dropDownList = [
    { alt: "restart", icon: svgIcons("restart"), label: "Restart" },
    { alt: "eoc", icon: svgIcons("eoc"), label: "End Of Call" },
    { alt: "tta", icon: svgIcons("tta"), label: "Transfer to Agent" },
    { alt: "Delete", icon: getDeleteModal(), label: "Delete" },
  ];

  const renderDropDowns = () => {
    return dropDownList.map((d, i) => (
      <DropdownItem
        className="bot-dropdown-list d-flex align-items-center"
        key={i}
        disabled={context.displayState}
        onClick={() => {
          if (d.label === "Delete") {
            setModal(true);
            setTimeout(() => {
              setModal(false);
            }, 100);
          } else {
            context.updateNode({ data, id }, d.alt);
          }
        }}
      >
        {d.icon && d.icon}
        <span className="ps-2 pt-1 text-dark fs-5">{d.label}</span>
      </DropdownItem>
    ));
  };

  let eventType = {
    "": { label: "Please set a event type.", icon: svgIcons("event") },
    restart: { label: "Restart", icon: svgIcons("restart") },
    eoc: { label: "End of Call", icon: svgIcons("eoc") },
    tta: { label: "Transfer to Agent", icon: svgIcons("tta") },
  };

  function getDeleteModal() {
    return (
      <DeleteConfirmation
        open={modal}
        delete={() => context.deleteNode({ data, id })}
      />
    );
  }

  return (
    <div className="bg-transparent border-0" style={{ width: "16.25rem" }}>
      <div
        style={{ borderRadius: ".8rem .8rem 0 0", background: "#EF7676" }}
        className={`row header mx-0 text-light py-1`}
      >
        <div style={{ alignSelf: "center" }} className="col-auto">
          {eventType[data.exts.selections].icon}
        </div>
        <div
          style={{
            alignSelf: "center",
            // fontSize: "1.2rem",
            // font: "normal normal 900 20px/32px Avenir",
          }}
          className="col header event-header-font"
        >
          Event
        </div>
        <div style={{ alignSelf: "center" }} className="col-auto p-0">
          <UncontrolledDropdown>
            <DropdownToggle
              color="white"
              style={{ outline: "none" }}
              className="px-1"
              disabled={
                context.displayState ||
                (getUserDetails().feature_permission &&
                  getUserDetails().feature_permission.flowChart.edit ===
                    "disabled")
              }
            >
              {svgIcons("verticalOptions")}
            </DropdownToggle>
            <DropdownMenu style={{ width: "15rem" }}>
              {" "}
              {renderDropDowns()}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
      <div className="bg-shadow">
        <div
          className="bg-white shadow-sm row h-100 content m-0 node-body-font"
          style={{
            borderRadius: "0 0 .5rem .5rem",
          }}
        >
          <div
            className="col p-0 py-2 text-center m-1 rounded text-left node-body-font"
            style={{
              width: "15rem",
            }}
          >
            {eventType[data.exts.selections].label}
            <br />
            {data.exts.selections === "tta" && (
              <>
                {botService.getSelectedBot()?.voice_bot &&
                  !context.channelIntegrations.voice && (
                    <p className="text-danger mt-1">
                      Voice Transfer to Agent configuration not available.
                    </p>
                  )}

                {botService.getSelectedBot()?.voice_bot &&
                  context.channelIntegrations.voice && (
                    <Row className="m-0 mt-1">
                      <Col className="col-auto align-self-center">Voice:</Col>
                      <Col className="ps-1">
                        {/* {channelIntegrations.voice} */}
                        {/* <input
                        className="w-75 border-0 rounded"
                        value={data.exts.agent_numbers[0]}
                        onChange={({ target }) => {
                          data.exts.agent_numbers[0] = target.value;
                        }}
                        onKeyPress={(event) => {
                          if (event.key === "Enter")
                            context.updateNode(
                              { data, id },
                              { agent_numbers: data.exts.agent_numbers[0] }
                            );
                        }}
                      /> */}
                        <UncontrolledDropdown className="text-start">
                          <DropdownToggle
                            className="text-start"
                            color="white"
                            style={{ outline: "none" }}
                            disabled={
                              context.displayState ||
                              (getUserDetails().feature_permission &&
                                getUserDetails().feature_permission.flowChart
                                  .edit === "disabled")
                            }
                          >
                            {data.exts.agent_numbers.length === 0
                              ? "Select a number"
                              : data.exts.agent_numbers[0]}
                          </DropdownToggle>
                          <DropdownMenu style={{ width: "15rem" }}>
                            {context.channelIntegrations.voice?.length > 0 &&
                              context.channelIntegrations.voice.map(
                                (num, i) => (
                                  <DropdownItem
                                    key={i}
                                    className="bot-dropdown-list d-flex align-items-center"
                                    onClick={() => {
                                      context.updateNode(
                                        { data, id },
                                        { agent_numbers: num }
                                      );
                                    }}
                                  >
                                    <span className="ps-2 pt-1 text-dark fs-5">
                                      {num}
                                    </span>
                                  </DropdownItem>
                                )
                              )}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </Col>
                    </Row>
                  )}

                {botService.getSelectedBot()?.chat_bot &&
                  context.channelIntegrations?.chat?.length === 0 && (
                    <p className="text-danger mt-1">
                      * Chat Transfer to Agent configuration not available.
                    </p>
                  )}
                {botService.getSelectedBot()?.chat_bot &&
                  context.channelIntegrations?.chat?.length > 0 && (
                    <Row className="m-0 mt-1 ">
                      <Col className="col-auto align-self-center">Chat:</Col>
                      <Col>
                        <UncontrolledDropdown className="text-start">
                          <DropdownToggle
                            className="text-start"
                            color="white"
                            style={{ outline: "none" }}
                            disabled={
                              context.displayState ||
                              (getUserDetails().feature_permission &&
                                getUserDetails().feature_permission.flowChart
                                  .edit === "disabled")
                            }
                          >
                            {data.exts.live_desk === ""
                              ? "Select transfer configuration"
                              : data.exts.live_desk[0].toUpperCase() +
                                data.exts.live_desk.substr(1)}
                          </DropdownToggle>
                          <DropdownMenu style={{ width: "15rem" }}>
                            {context.channelIntegrations.chat?.length > 0 &&
                              context.channelIntegrations.chat.map(
                                (chat, index) => (
                                  <DropdownItem
                                    key={index}
                                    className="bot-dropdown-list d-flex align-items-center"
                                    onClick={() => {
                                      context.updateNode(
                                        { data, id },
                                        { live_desk: chat }
                                      );
                                    }}
                                  >
                                    <span className="ps-2 pt-1 text-dark fs-5">
                                      {chat[0].toUpperCase() + chat.substr(1)}
                                    </span>
                                  </DropdownItem>
                                )
                              )}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </Col>
                    </Row>
                  )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const GetDTMFBody = (data, id, context) => {
  const [modal, setModal] = useState(false);
  const [text, setText] = useState(data.exts.digits);
  const [dtmfVal, setDtmfVal] = useState(data.exts.dtmfValue);

  const { getUserDetails } = useContext(UserContext);
  return (
    <div className="bg-transparent border-0" style={{ width: "16.25rem" }}>
      <div
        style={{ borderRadius: ".8rem .8rem 0 0", background: "#F5D6A5" }}
        className={`row header mx-0 text-center py-1`}
      >
        <div style={{ alignSelf: "center" }} className="col-auto">
          {svgIcons("flowDtmf")}
        </div>
        <div
          style={{
            alignSelf: "center",
          }}
          className="col event-header-font"
        >
          DTMF
        </div>
        <div style={{ alignSelf: "center" }} className="col-auto p-0">
          <DropDown
            actionButton={() => svgIcons("verticalOptions")}
            btnClassName="px-1"
            disabled={
              context.displayState ||
              (getUserDetails().feature_permission &&
                getUserDetails().feature_permission.flowChart.edit ===
                  "disabled")
            }
            dropdownList={[
              {
                icon: (
                  <DeleteConfirmation
                    open={modal}
                    delete={() => context.deleteNode({ data, id })}
                  />
                ),
                label: "Delete",
              },
            ]}
            onActionItemClick={(menu) => {
              if (menu.label === "Delete") {
                setModal(true);
                setTimeout(() => {
                  setModal(false);
                }, 100);
              }
            }}
          />
        </div>
      </div>
      <div className="bg-shadow">
        <div
          className="bg-white shadow-sm row h-100 content m-0"
          style={{ borderRadius: "0 0 .5rem .5rem" }}
        >
          <div
            className="col p-0 py-2 text-center m-1 rounded text-left node-body-font"
            style={{
              width: "15rem",
            }}
          >
            <Row className="m-0 mt-1">
              <Col className="col-auto">
                {data.exts.digits || data.exts.digits === 0
                  ? "Input Length: "
                  : "Input: "}
              </Col>
              <Col className="text-start">
                {(data.exts.digits || data.exts.digits === 0) && (
                  <input
                    type="number"
                    min="1"
                    max="20"
                    className="w-100 border-0 rounded"
                    value={text}
                    onChange={({ target }) => {
                      setText(target.value);
                      data.exts.digits = target.value;
                    }}
                    onBlur={() =>
                      context.updateNode({ data, id }, data.exts.digits)
                    }
                    onKeyPress={(event) => {
                      if (event.key === "Enter")
                        context.updateNode({ data, id }, data.exts.digits);
                    }}
                    readOnly={
                      context.displayState ||
                      (getUserDetails().feature_permission &&
                        getUserDetails().feature_permission.flowChart.edit ===
                          "disabled")
                    }
                  />
                )}
                {!(data.exts.digits || data.exts.digits === 0) && (
                  <input
                    type="number"
                    max="9"
                    min="0"
                    className="w-100 border-0 rounded"
                    value={dtmfVal}
                    readOnly={
                      context.displayState ||
                      (getUserDetails().feature_permission &&
                        getUserDetails().feature_permission.flowChart.edit ===
                          "disabled")
                    }
                    onChange={({ target }) => {
                      setDtmfVal(target.value);
                      data.exts.dtmfValue = target.value;
                    }}
                    onBlur={() =>
                      context.updateNode({ data, id }, data.exts.dtmfValue)
                    }
                    onKeyPress={(event) => {
                      if (event.target.value + "" + event.key > 9)
                        event.preventDefault();
                      if (event.key === "Enter")
                        context.updateNode({ data, id }, data.exts.dtmfValue);
                    }}
                  />
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

const getStartBody = () => {
  return (
    <div className="bg-transparent border-0">
      <div
        className="row header bg-white align-items-center justify-content-center node-body-font"
        style={{
          borderRadius: "50%",
          width: "60px",
          height: "60px",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          boxShadow: "0px 0px 6px #00000062",
        }}
      >
        Start
      </div>
    </div>
  );
};

const GetLinkBody = (data, id, context) => {
  const [modal, setModal] = useState(false);

  const { getUserDetails } = useContext(UserContext);

  let { flow } = data.exts;

  const dropDownList = [{ icon: getDeleteModal(), label: "Delete" }];

  const subPaths = Object.keys(context.paths)
    .filter((p) => p !== data.exts.pathName && p.includes("sub-"))
    .map((p) => ({ label: p.split(":")[0].substr(4), value: p }));

  function getDeleteModal() {
    return (
      <DeleteConfirmation
        open={modal}
        delete={() => context.deleteNode({ data, id })}
      />
    );
  }

  const actionButton = () => {
    return <div className="text-white">Select path</div>;
    // if (data.exts.selections) {
    //   return (
    //     <div>
    //       {/* {typeof data.exts.selections === "string"
    //         ? data.exts.selections.split(":")[0].substr(4)
    //         : data.exts.selections[0].split(":")[0].substr(4)} */}
    //     </div>
    //   );
    // } else {
    //   return <div>Select path</div>;
    // }
  };

  return (
    <div className="bg-transparent border-0" style={{ width: "16.25rem" }}>
      <div
        style={{ borderRadius: ".8rem .8rem 0 0", background: "#EF7676" }}
        className={`row header mx-0 text-light py-1`}
      >
        <div style={{ alignSelf: "center" }} className="col-auto">
          <i className="fas fa-circle" />
        </div>
        <div
          style={{
            alignSelf: "center",
          }}
          className="col header event-header-font"
        >
          <DropDown
            actionButton={actionButton}
            dropdownList={subPaths}
            disabled={
              context.displayState ||
              (getUserDetails().feature_permission &&
                getUserDetails().feature_permission.flowChart.edit ===
                  "disabled")
            }
            dropDownMenuClsName="dropdown-scroll"
            onActionItemClick={(menu) =>
              context.updateNode({ data, id }, menu.value)
            }
          />
        </div>
        <div style={{ alignSelf: "center" }} className="col-auto p-0">
          <DropDown
            actionButton={() => svgIcons("verticalOptions", "white")}
            btnClassName="px-1"
            disabled={
              context.displayState ||
              (getUserDetails().feature_permission &&
                getUserDetails().feature_permission.flowChart.edit ===
                  "disabled")
            }
            dropdownList={dropDownList}
            onActionItemClick={(menu) => {
              setModal(true);
              setTimeout(() => {
                setModal(false);
              }, 100);
            }}
          />
        </div>
      </div>
      <div className="bg-shadow" style={{ height: "5rem" }}>
        <div
          className="bg-white shadow-sm overflow-auto row h-100 content m-0 w-100 px-1 scroll node-body-font"
          style={{
            borderRadius: "0 0 .5rem .5rem",
          }}
        >
          {flow.length > 0 &&
            flow.map((pName, i) => {
              return (
                <span
                  key={i}
                  className="badge rounded-pill bg-primary w-auto align-self-center m-1 py-2"
                >
                  {pName.split(":")[0].substr(4)}
                  <span
                    className="cursor-pointer"
                    style={{ position: "relative", top: "-9px", left: "5px" }}
                    onClick={() =>
                      !context.displayState &&
                      getUserDetails().feature_permission &&
                      getUserDetails().feature_permission.flowChart.edit !==
                        "disabled" &&
                      context.updateNode({ data, id }, i, "delete")
                    }
                  >
                    <i className="fa  fa-times-circle text-danger" />
                  </span>
                </span>
              );
            })}
        </div>
      </div>
    </div>
  );
};

const GetAddButton = (data, id, context) => {
  let [dropDownOpen, setDropDownOpen] = useState(false);
  return (
    <div
      className="add-button text-center w-100"
      style={dropDownOpen ? { visibility: "visible" } : {}}
    >
      <small className="text-dark">
        <i className="fas fa-long-arrow-alt-down prevent-click-event-triger"></i>
      </small>
      <Dropdown
        isOpen={dropDownOpen}
        toggle={() => setDropDownOpen(!dropDownOpen)}
        className="prevent-click-event-triger"
        disabled={false}
        direction="right"
      >
        <DropdownToggle className="btn btn-sm p-0 text-blue bg-transparent border-0 outline-0 prevent-click-event-triger">
          <i className="fas fa-plus-circle prevent-click-event-triger fs-2"></i>
        </DropdownToggle>

        <DropdownMenu className="prevent-click-event-triger">
          {data.exts.pathName.includes("sub-") && data.exts.who === "start" && (
            <>
              <DropdownItem
                className="prevent-click-event-triger"
                onClick={(e) => {
                  e.preventDefault();
                  context.addNode("user", { data, id });
                }}
              >
                <span className="me-2">{svgIcons("flowUser")}</span>
                User
              </DropdownItem>
            </>
          )}
          {["user", "dtmf-input", "slot", "decision", "form"].includes(
            data.exts.who
          ) && (
            <>
              <DropdownItem
                className="prevent-click-event-triger"
                onClick={() => {
                  //context.addNode({ data, id }, { who: "bot" });
                  context.addNode("bot", { data, id });
                }}
              >
                <span className="me-2">{svgIcons("flowBot", "#000")}</span>
                Bot
              </DropdownItem>
              <DropdownItem
                className="prevent-click-event-triger"
                onClick={() => {
                  context.addNode("action", { data, id });
                }}
              >
                <i className="fas fa-directions me-2" />
                Action
              </DropdownItem>
            </>
          )}
          {["slot", "form"].includes(data.exts.who) && (
            <DropdownItem
              className="prevent-click-event-triger"
              onClick={(e) => {
                e.preventDefault();
                context.addNode("user", { data, id });
              }}
            >
              <span className="me-2">{svgIcons("flowUser")}</span>
              User
            </DropdownItem>
          )}
          {["slot"].includes(data.exts.who) && (
            <DropdownItem
              className="prevent-click-event-triger"
              onClick={(e) => {
                e.preventDefault();
                context.addNode("form", { data, id });
              }}
            >
              <i className="fab fa-wpforms me-2" />
              Form
            </DropdownItem>
          )}
          {["user", "dtmf-input"].includes(data.exts.who) && (
            <>
              <DropdownItem
                className="prevent-click-event-triger"
                onClick={(e) => {
                  e.preventDefault();
                  context.addNode("slot", { data, id });
                }}
              >
                <i className="fas fa-square me-2"></i>
                Slot
              </DropdownItem>
              <DropdownItem
                className="prevent-click-event-triger"
                onClick={(e) => {
                  e.preventDefault();
                  context.addNode("form", { data, id });
                }}
              >
                <i className="fab fa-wpforms me-2" />
                Form
              </DropdownItem>
            </>
          )}
          {["bot", "action"].includes(data.exts.who) && (
            <>
              {data.exts.pathName === "main_path" &&
                data.exts.who === "action" && (
                  <>
                    <DropdownItem
                      className="prevent-click-event-triger"
                      onClick={(e) => {
                        e.preventDefault();
                        context.addNode("bot", { data, id });
                      }}
                    >
                      <span className="me-2">
                        {svgIcons("flowBot", "#000")}
                      </span>
                      Bot
                    </DropdownItem>
                  </>
                )}
              {data.isDTMFBranch && (
                <DropdownItem
                  onClick={() => {
                    context.addNode("dtmf-decision", { data, id });
                  }}
                >
                  <i className="fab fa-buromobelexperte me-2"></i>
                  DTMF
                </DropdownItem>
              )}
              {data.isSlotBranch && (
                <DropdownItem
                  onClick={() => {
                    context.addNode("slot-decision", { data, id });
                  }}
                >
                  <i className="fas fa-square me-2"></i>
                  Slot
                </DropdownItem>
              )}
              {!data.isDTMFBranch &&
                !data.isSlotBranch &&
                data.exts.pathName !== "main_path" && (
                  <DropdownItem
                    className="prevent-click-event-triger"
                    onClick={(e) => {
                      e.preventDefault();
                      context.addNode("user", { data, id });
                    }}
                  >
                    <span className="me-2">{svgIcons("flowUser")}</span>
                    User
                    {console.log("here")}
                  </DropdownItem>
                )}
              {!data.isBranchParent &&
                !data.isDTMFBranch &&
                !data.isSlotBranch &&
                data.exts.pathName !== "main_path" && (
                  <>
                    <DropdownItem
                      className="prevent-click-event-triger"
                      onClick={(e) => {
                        e.preventDefault();
                        context.addNode("bot", { data, id });
                      }}
                    >
                      <span className="me-2">
                        {svgIcons("flowBot", "#000")}
                      </span>
                      Bot
                    </DropdownItem>

                    <DropdownItem
                      className="prevent-click-event-triger"
                      onClick={() => {
                        context.addNode("action", { data, id });
                      }}
                    >
                      <i className="fas fa-directions me-2" />
                      Action
                    </DropdownItem>

                    <DropdownItem
                      className="prevent-click-event-triger"
                      onClick={(e) => {
                        e.preventDefault();
                        context.addNode("slot", { data, id });
                      }}
                    >
                      <i className="fas fa-square me-2"></i>
                      Slot
                    </DropdownItem>
                    <DropdownItem
                      className="prevent-click-event-triger"
                      onClick={(e) => {
                        e.preventDefault();
                        context.addNode("form", { data, id });
                      }}
                    >
                      <i className="fab fa-wpforms me-2" />
                      Form
                    </DropdownItem>
                    <DropdownItem
                      className="prevent-click-event-triger"
                      onClick={(e) => {
                        e.preventDefault();
                        context.addNode("event", { data, id });
                      }}
                    >
                      <span className="me-2">{svgIcons("event")}</span>
                      Event
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        context.addNode("dtmf-input", { data, id });
                      }}
                    >
                      <i className="fab fa-buromobelexperte me-2"></i>
                      DTMF
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        context.addNode("flow", { data, id });
                      }}
                    >
                      <i className="fas fa-circle me-2"></i>
                      Flow
                    </DropdownItem>
                  </>
                )}
            </>
          )}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
