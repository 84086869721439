import baseUrl from "../baseURL.service";
import { getToken } from "../authUtil";
import apiServices from "../api.services";

const fetchData = (url, options) => {
  return apiServices.fetchData(url, { ...options });
};

function getUrlList(organisationId) {
  let reqOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify({ organization_id: organisationId }),
  };
  return fetchData(`${baseUrl}/get_url_list`, reqOptions);
}

function submitUrl(dataObj) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };
  return fetchData(`${baseUrl}/submit_url`, requestOptions);
}

function getAssociateBots(dataObj) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };
  return fetchData(`${baseUrl}/associate_bots`, requestOptions);
}

function getKbUsageCost(botId) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify({
      kb_bot_id: botId,
    }),
  };
  return fetchData(`${baseUrl}/get_kb_usage_cost`, requestOptions);
}

function getConversations(botId) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify({
      kb_bot_id: botId,
    }),
  };
  return fetchData(`${baseUrl}/get_conversations`, requestOptions);
}

function getKbCreCost(botId) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify({
      kb_bot_id: botId,
    }),
  };
  return fetchData(`${baseUrl}/get_kb_cre_cost`, requestOptions);
}

function getCompletedUrls(botId) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify({
      kb_bot_id: botId,
    }),
  };
  return fetchData(`${baseUrl}/get_completed_urls`, requestOptions);
}

function deleteUrl(data) {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(data),
  };
  return fetchData(`${baseUrl}/delete_url`, requestOptions);
}

function getConversation(convId, kbBotId) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify({ conversation_id: convId, kb_bot_id: kbBotId }),
  };
  return fetchData(`${baseUrl}/get_conversation`, requestOptions);
}

function getDownloadReport(kbBotId) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify({ kb_bot_id: kbBotId }),
  };
  return fetchData(`${baseUrl}/download_report`, requestOptions);
}

function getValidateOpenapiConfig(openapiConfig) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify({ openapi_config: openapiConfig }),
  };
  return fetchData(`${baseUrl}/validate_openapi_config`, requestOptions);
}

const kbServices = {
  getUrlList,
  submitUrl,
  deleteUrl,
  getAssociateBots,
  getKbCreCost,
  getKbUsageCost,
  getConversations,
  getCompletedUrls,
  getConversation,
  getDownloadReport,
  getValidateOpenapiConfig,
};
export default kbServices;
