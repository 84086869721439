import { UncontrolledTooltip } from "reactstrap";
import { getImageUrl } from "../../services/baseURL.service";
import "./bot.scss";

const Bot = ({ bot, handleBot, handleKnowMore }) => {
  return (
    <div className="col-auto bot-container">
      <div
        className="bot"
        onClick={() => handleBot(bot)}
        style={{ background: `${bot.bg} 0% 0% no-repeat padding-box` }}
      >
        <img alt={bot.label} src={getImageUrl(bot.bot_icon || bot.icon)} />
      </div>
      <p className="bot-name m-1" id={`botTip-${bot.bot_id}`}>
        {bot.bot_name || bot.template_name}
      </p>
      <UncontrolledTooltip placement="right" target={`botTip-${bot.bot_id}`}>
        {bot.bot_name || bot.template_name}
      </UncontrolledTooltip>
      <p className="bot-know-more" onClick={() => handleKnowMore(bot)}>
        Know more
      </p>
    </div>
  );
};

export default Bot;
