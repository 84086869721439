import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import routes from "../../routes";

function SuperAdmin(props) {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/super_admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <React.Fragment>
      <Switch>
        {getRoutes(routes)}
        <Redirect from="*" to="/super_admin/login" />
      </Switch>
    </React.Fragment>
  );
}

export default SuperAdmin;
