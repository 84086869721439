import React, { Component, useContext } from "react";
import BotLayoutContent from "../../components/botLayout/botLayoutContent.component";
import BotLayoutLeftPanel from "../../components/botLayout/botLayoutLeftPanel.component";
import BotLayoutRightPanel from "../../components/botLayout/botLayoutRightPanel.component";
import DropDown from "../../components/fields/dropDown/dropDown.component";
import Input from "../../components/fields/input/input.component";
import botService from "../../services/bot.service";
import intentServices from "../../services/api.services/intentsApi.service";
import getIcon from "../../services/icon.service";
import BulkImport from "../../components/bulkImport.component";
import { NotificationContext } from "../../contexts/notficationContext";
import { BotContext } from "../../contexts/BotContext";
import { UserContext } from "../../contexts/userContext";
// import baseUrl from "../../services/baseURL.service";
// import { getToken } from "../../services/authUtil";
// import svgIcons from "../../services/icon.service";
import "./intents.scss";
import entityService from "../../services/api.services/entityApi.service";
import apiService from "../../services/api.services";
import { Button, Button as span } from "reactstrap";
import DeleteConfirmation from "../../components/deleteConfirmation.component";

// const wordCount = addNewValue.match(/(\w+)/g).length;
const regex = {
  "english-in": /[A-Za-z0-9\.\:\_\$\{\}\[\]]+$/,
  "english-uk": /[A-Za-z0-9\.\:\_\$\{\}\[\]]+$/,
  "english-us": /[A-Za-z0-9\.\:\_\$\{\}\[\]]+$/,
  french: /[A-Za-z0-9\.\:\_\$\{\}\[\]]+$/,
  german: /[A-Za-z0-9\.\:\_\$\{\}\[\]]+$/,
  italian: /[A-Za-z0-9\.\:\_\$\{\}\[\]]+$/,
  spanish: /[A-Za-z0-9\.\:\_\$\{\}\[\]]+$/,
  "spanish-us": /[A-Za-z0-9\.\:\_\$\{\}\[\]]+$/,
  bahasa: /[A-Za-z0-9\.\:\_\$\{\}\[\]]+$/,
  "urdu pk": /\p{Script=Devanagari}/u,
  bengali: /\p{Script=Bengali}/u,
  gujarati: /\p{Script=Gujarati}/u,
  hindi: /\p{Script=Devanagari}/u,
  kannada: /\p{Script=Kannada}/u,
  malayalam: /\p{Script=Malayalam}/u,
  marathi: /\p{Script=Devanagari}/u,
  nepali: /\p{Script=Devanagari}/u,
  punjabi: /\p{Script=Devanagari}/u,
  tamil: /\p{Script=Tamil}/u,
  telugu: /\p{Script=Telugu}/u,
  urdu: /\p{Script=Devanagari}/u,
  arabic: /\p{Script=Arabic}/u,
};
class Intent extends Component {
  static contextType = BotContext;
  constructor(props) {
    super(props);
    this.state = {
      addNewValue: "",
      addNewIntent: "",
      intents: [],
      oldPhrase: "",
      intentNewName: "",
      intentOldName: "",
      selectionText: "",
      modal: false,
      posY: null,
      posX: null,
      listId: "",
      industryList: [],
      libraryData: [],
      libraryIntentSearch: "",
      addLibrary: true,
      count: "",
      size: 50,
      pageNo: 1,
      bulkImportModal: false,
      validationStatus: "validationPending",
    };
    this.ref = React.createRef();
    this.listIdRef = React.createRef();
  }

  setNavBarButtons() {
    this.context.setNavBarButtons(
      <div className="row">
        <div className="col-auto">
          <Button
            className="btn intents-btn intent-font-save rounded"
            onClick={this.handleValidateData}
          >
            Validate Data
          </Button>
        </div>
      </div>
    );
  }

  bulkImportComp = () => {
    return (
      <BulkImport
        isFile
        onlyIcon={true}
        isContentSwitch={true}
        open={this.state.bulkImportModal}
        importData={this.state.importData}
        sampleFileName="Intents-Sample.json"
        fileName={this.state.importFileName}
        intentSentences={this.state.intentSentences}
        validationStatus={this.state.validationStatus}
        description={{ title: "Import Intent JSON or CSV file" }}
        sampleUrl="/sample_bulk_import_intents_sample_json_file"
        importFile={
          this.state.importData
            ? "Validation Results"
            : "Select the file to import Intents"
        }
        confirmSuccess={(e, update_config_data) => {
          let formData = new FormData();
          formData.append("file", e.target.files[0]);
          intentServices
            .importIntents(e.target.files[0], update_config_data)
            .then((response) => {
              if (response.status === "success") {
                if (update_config_data) {
                  this.onPageRefresh();
                  this.props.setNotifications({
                    color: "success",
                    message: "Intents imported successfully.",
                  });
                } else {
                  this.setState({
                    importData: response,
                    validationStatus: "importStage",
                  });
                }
              } else {
                this.props.setNotifications({
                  color: "danger",
                  message: response.message,
                });
              }
            })
            .catch((e) =>
              this.props.setNotifications({
                color: "danger",
                message: e.message,
              })
            );
        }}
        validateImport={(e) => {
          let formData = new FormData();
          formData.append("file", e.target.files[0]);
          intentServices
            .validateImport(e.target.files[0])
            .then((response) =>
              response.status === "success"
                ? this.setState({
                    importData: response,
                    importFileName: e.target.files[0].name,
                    validationStatus: "validationComplete",
                  })
                : this.props.setNotifications({
                    color: "danger",
                    message: response.message,
                  })
            )
            .catch((e) =>
              this.props.setNotifications({
                color: "danger",
                message: e.message,
              })
            );
        }}
        resetState={() =>
          this.setState({
            importData: null,
            importFileName: "",
            validationStatus: "validationPending",
          })
        }
      />
    );
  };

  onClickImport = () => {
    this.setState({ bulkImportModal: true });
    setTimeout(() => {
      this.setState({ bulkImportModal: false });
    }, 500);
  };

  componentWillUnmount() {
    this.context.setNavBarButtons("");
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  componentDidMount() {
    let bot_id = botService.getSelectedBotId();
    if (bot_id) {
      this.setNavBarButtons();
      this.getBotIntents();
      this.getIndustries();
      this.setState({
        lang: botService.getSelectedBotLang(),
      });
    }
    document.addEventListener("click", this.handleClickOutside, true);
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  handleOutsideClick = ({ target }) => {
    if (this.listIdRef.current && !this.listIdRef.current.contains(target)) {
      this.setState({ listId: "" });
    }
  };

  handleClickOutside = (event) => {
    if (this.ref.current && !this.ref.current.contains(event.target)) {
      if (event.target instanceof HTMLParagraphElement) {
        event.detail !== 1 &&
          this.setState({
            selectionText: "",
            position: "",
            tagEntitySelectedPhrase: "",
            text: "",
          });
      } else {
        event.detail !== 1 &&
          this.setState({
            selectionText: "",
            position: "",
            tagEntitySelectedPhrase: "",
            text: "",
          });
      }
    }
  };

  getIntentLibrary = (params, isExpand, isSelectImport) => {
    let { libraryData } = this.state;
    let query =
      params &&
      Object.keys(params)
        .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
        .join("&");

    intentServices
      .getIntentLibrary(query)
      .then((r) => {
        if (r.status === "success") {
          if (isExpand) {
            let index = 0;
            let temp = libraryData.map((e) => {
              if (
                e.intent === params?.intent &&
                e.industry === params?.industries
              ) {
                e = { ...r.data[index], isSelected: false };
                index++;
              }
              return e;
            });
            this.setState({ libraryData: temp });
          } else if (isSelectImport) {
            let temp = libraryData.map((e) => {
              if (
                e.intent === params?.intent &&
                e.industry === params?.industries
              ) {
                e = { ...r.data[0], isSelected: true };
              }
              return e;
            });
            console.log(temp);
            this.setState({ libraryData: temp });
          } else {
            const libraryData = r.data.map((d) => ({
              ...d,
              isSelected: false,
            }));
            this.setState({ libraryData });
          }
        }
      })
      .catch((e) => {
        console.log("Failed: ", e);
      });
  };

  addIntentLibrary = (callBack) => {
    let { libraryData } = this.state;

    let data = libraryData.filter((ele) => {
      if (ele.isSelected) {
        delete ele.isSelected;
        return ele;
      }
    });

    if (data.length > 0) {
      intentServices
        .addIntentLibrary(data)
        .then((res) => {
          if (res.status === "success") {
            this.getBotIntents();
            this.onCancelClick();
            this.props.setNotifications({
              color: "success",
              message: res.message,
            });
          } else
            this.props.setNotifications({
              color: "danger",
              message: res.message,
            });
        })
        .catch((err) =>
          this.props.setNotifications({ color: "danger", message: err.message })
        );
      callBack();
    }
  };

  getBotIntents = async () => {
    try {
      const r = await intentServices.getBotIntents();

      if (r.status === "success") {
        const intents = r.data.map((d) => ({ label: d, isSelected: false }));

        this.setState({ intents, trainedIntents: r.trained });
      }
    } catch (e) {
      console.log("Failed: ", e);
    }
  };

  getIndustries = () => {
    apiService
      .getIndustries()
      .then((res) => {
        let industryList = res.data.map((ele) => ({
          label: ele.industry,
          icon: getIcon("check-box-outline-blank"),
          isSelected: false,
        }));
        industryList.unshift({ label: "All", isSelected: false });
        this.setState({ industryList });
      })
      .catch((error) => console.log(error));
  };

  searchLibrary = (e) => {
    let { industryList, libraryIntentSearch } = this.state;

    this.setState({ libraryIntentSearch: e.target.value });
    if (e.key === "Enter") {
      let temp2 = industryList
        .filter((i) => i.isSelected && i.label && i.label !== "All")
        .map((i) => i.label)
        .join(",");

      let params = { intent: libraryIntentSearch, industries: temp2 };
      this.setState({ addLibrary: false }, () => this.getIntentLibrary(params));
    }
  };

  industryClick = (item, action, ref) => {
    let { industryList, libraryIntentSearch } = this.state;
    if (!action) {
      ref.current.state = true;
      let temp = industryList.map((ele) => {
        if (ele.label === item.label && item.label !== "All") {
          ele.isSelected = !ele.isSelected;
        } else if (item.label === "All") {
          ele.label === "All" && (ele.isSelected = !ele.isSelected);
          item.label === "All" && item.isSelected
            ? (ele.isSelected = true)
            : (ele.isSelected = false);
        }
        return ele;
      });
      this.setState({ industryList: temp });
    } else if (action === "confirm") {
      let temp2 = industryList
        .filter((i) => i.isSelected && i.label && i.label !== "All")
        .map((i) => i.label)
        .join(",");
      let params = { intent: libraryIntentSearch, industries: temp2 };
      temp2.length > 0 && this.getIntentLibrary(params);
      ref.current.toggle();
    } else if (action === "cancel") {
      industryList.map((ele) => (ele.isSelected = false));
      this.setState({ industryList }, () => ref.current.toggle());
    }
  };

  handleLibraryClick = (item, i) => {
    let { libraryData } = this.state;
    libraryData.map((ele, ix) => {
      if (ele.intent === item.intent && ix === i) {
        ele.isSelected = !ele.isSelected;
        if (ele.isSelected) {
          this.getIntentLibrary(
            {
              intent: ele.intent,
              industries: ele.industry,
              show_all_fields: true,
            },
            null,
            true
          );
        }
      } else if (item.label === "Select All") ele.isSelected = true;
      else if (item.label === "Clear") ele.isSelected = false;
      return ele;
    });
    this.setState({ libraryData });
  };

  handleLibraryExpand = (industry, intent) => {
    let param =
      industry && intent
        ? { intent: intent, industries: industry, show_all_fields: true }
        : undefined;
    this.setState({ addLibrary: true }, () =>
      this.getIntentLibrary(param, true)
    );
  };

  onCancelClick = (callBack) => {
    let { industryList } = this.state;
    industryList.map((ele) => (ele.isSelected = false));
    this.setState({
      libraryData: [],
      industryList,
      libraryIntentSearch: "",
      addLibrary: false,
    });
    callBack && callBack();
  };

  getIntentSentences(intent, page) {
    intentServices
      .getIntentSentences(intent, page)
      .then((res) => {
        if (res.status === "success")
          this.setState({
            intentSentences: res.data,
            trainedIntentSentences: res.trained,
            count: res.total_sentence_count,
          });
      })
      .catch((error) => {
        console.log("Failed: ", error);
      });
    entityService
      .getBotEntity()
      .then((res) => this.setState({ entites: res.data }))
      .catch((err) => console.log("Failed: ", err));
  }

  handleValidateData = async () => {
    this.props.setNotifications({
      color: "info",
      message: "Started validating!",
    });

    try {
      const r = await entityService.validateData();

      if (r.status === "success") {
        this.handleDownload(r.outlier_result);

        this.props.setNotifications({
          color: "success",
          message:
            "File downloaded successfully & you can check the file at the bottom left.",
        });
      } else
        this.props.setNotifications({
          color: "danger",
          message: r.message,
        });
    } catch (e) {
      this.props.setNotifications({
        color: "danger",
        message: e.message,
      });
      console.log(e);
    }
  };

  handleAddNewValue = (e) => {
    let { intentSentences, addNewValue, selectedIntent } = this.state;
    if (typeof e === "string") {
      this.setState({ addNewValue: e });
    } else {
      this.setState({ addNewValue: e.target.value });
    }

    if (e.key === "Enter") {
      if (regex[this.state.lang].test(addNewValue)) {
        intentSentences.push(addNewValue);
        this.setState({ intentSentences, addNewValue: "" });
        this.toggleSave(
          "_addNewUtterance",
          intentSentences,
          selectedIntent,
          true
        );
      } else {
        this.props.setNotifications({
          color: "black",
          message:
            "Sentence should not contain speacial characters and should be less than 30 words",
        });
      }
    }
  };

  handleDownload = (data) => {
    const url = window.URL.createObjectURL(
      new Blob([JSON.stringify(data)], {
        type: "application/json",
      })
    );
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", "file.json");

    document.body.appendChild(link);

    link.click();
  };

  handleDropDownClick = (menu, i) => {
    // let { intentSentences, selectedIntent } = this.state;

    switch (menu.label) {
      case "Delete":
        this.setState({ modal: true });
        setTimeout(() => {
          this.setState({ modal: false });
        }, 100);
        break;
      case "Edit":
        let div = document.getElementById(i);
        div.querySelector(".bot-layout-greetings-inp") &&
          div.querySelector(".bot-layout-greetings-inp").focus();
        break;
      default:
        break;
    }
  };

  handleTitleChange = ({ key, target }, type) => {
    let { selectedIntent, intentOldName, intentNewName, intents } = this.state;
    if (!intentOldName) {
      intentOldName = selectedIntent;
    }
    this.setState({ intentNewName: target.value, intentOldName });
    if (
      (key === "Enter" || type) &&
      intentOldName &&
      intentNewName &&
      intentNewName !== intentOldName
    ) {
      if (!intents.some((e) => e.label === intentNewName)) {
        if (/^[A-Za-z][A-Za-z0-9_]+$/.test(intentNewName)) {
          this.toggleSave("_rename", intentOldName, intentNewName);
          this.setState({ intentOldName: "", selectedIntent: intentNewName });
        } else {
          this.props.setNotifications({
            color: "black",
            message:
              "Intent name should not contain any special characters or spaces",
          });
        }
      } else {
        this.props.setNotifications({
          color: "black",
          message: "Intent already exists",
        });
      }
    }
  };

  toggleSave = (
    action,
    index,
    intentCurrent,
    addNewUtterance = false,
    old_sen,
    new_sen,
    entity_tagged
  ) => {
    let { intentOldName, intentNewName, selectedIntent } = this.state;
    if (action === "_delete") {
      intentServices
        .deleteBotIntent({ intent: index })
        .then((res) => {
          if (
            res.status === "success" &&
            Object.keys(res.failed || {}).length === 0
          )
            this.props.setNotifications({
              color: "success",
              message: res.message,
            });
          else
            this.props.setNotifications({
              color: "danger",
              message:
                Object.keys(res.failed || {}).length === 0
                  ? res.message
                  : res.failed[Object.keys(res.failed)[0]],
            });
        })
        .catch((err) =>
          this.props.setNotifications({ color: "danger", message: err.message })
        );
      this.onPageRefresh();
    } else if (action === "_add") {
      intentServices
        .addBotIntent({ intent: index, data: [] })
        .then((res) => {
          if (res.status === "success")
            this.props.setNotifications({
              color: "success",
              message: res.message,
            });
          else
            this.props.setNotifications({
              color: "danger",
              message: res.message,
            });
        })
        .catch((err) =>
          this.props.setNotifications({ color: "danger", message: err.message })
        );
      this.onPageRefresh();
    } else if (action === "_update") {
      intentServices
        .updateBotIntent({
          intent: intentCurrent,
          data: index,
          old_sentence: old_sen,
          new_sentence: new_sen,
          entity_tagged: entity_tagged ? entity_tagged : false,
        })
        .then((res) => {
          if (res.status === "success") {
            this.getIntentSentences(
              this.state.selectedIntent,
              this.state.pageNo
            );
          }
        })
        .catch((err) =>
          this.props.setNotifications({ color: "danger", message: err.message })
        );
    } else if (action === "_addNewUtterance") {
      intentServices
        .updateBotIntent({
          intent: intentCurrent,
          data: index,
          overwrite_sentences: addNewUtterance,
          entity_tagged: entity_tagged ? entity_tagged : false,
        })
        .then((res) => {
          if (res.status === "success") {
            this.getIntentSentences(
              this.state.selectedIntent,
              this.state.pageNo
            );
          }
        })
        .catch((err) =>
          this.props.setNotifications({ color: "danger", message: err.message })
        );
    } else if (action === "_rename") {
      intentServices
        .renameBotIntent({ old_name: index, new_name: intentCurrent })
        .then((res) => {
          if (res.status === "success") {
            this.props.setNotifications({
              color: "success",
              message: res.message,
            });
            this.getBotIntents();
          } else {
            this.props.setNotifications({
              color: "danger",
              message: res.message,
            });
            this.setState({ intentNewName: "", selectedIntent: "" });
          }
        })
        .catch((err) => {
          this.props.setNotifications({
            color: "danger",
            message: err.message,
          });
        });
      // this.onPageRefresh();
    } else if (action === "_addEntity") {
      entityService.addBotEntity(index).then((response) => {
        if (response.status === "success")
          this.setState({
            intentSentences: intentCurrent,
            tagEntity: false,
            tagEntitySelectedPhrase: "",
          });
      });
    } else {
      return;
    }
  };

  getDeleteModal = (action, i) => {
    let { intents, selectedIntent, intentSentences } = this.state;
    return (
      <DeleteConfirmation
        open={this.state.modal}
        delete={() => {
          if (action === "phrase") {
            let oldPraseTemp = intentSentences[i];
            this.setState({ intentSentences }, () => {
              this.toggleSave(
                "_update",
                intentSentences,
                selectedIntent,
                "",
                oldPraseTemp,
                ""
              );
            });
          } else if (action === "intent") {
            let temp = intents.filter((intent) => intent !== selectedIntent);
            this.setState({ intents: temp, selectedIntent: "" }, () =>
              this.toggleSave("_delete", selectedIntent)
            );
          }
        }}
      />
    );
  };

  getSplitArr(phrase) {
    return phrase.split(/([\[\}]+)/);
  }

  getIntentPhraseEntityView(phrase, strIndex) {
    let splitArr = this.getSplitArr(phrase);
    let setArray = [];
    splitArr.forEach((ele) => {
      if (["[", "}}"].indexOf(ele) > -1) {
        setArray.push("");
      } else if (ele.indexOf("]${{") > -1) {
        setArray.push({
          type: "span",
          txt: ele.split("]${{")[0],
          value: ele.split("]${{")[1],
        });
      } else if (ele.trim() !== "") {
        setArray.push({
          type: "input",
          txt: ele,
        });
      }
    });
    setArray.push({
      type: "input",
      txt: "",
    });
    if (phrase.indexOf("[") > -1) {
      return (
        <div
          className="border-0 rounded text-left bg-transparent  p-1"
          style={{
            overflow: "auto",
            whiteSpace: "nowrap",
          }}
        >
          {setArray.map((ele, index) => {
            return this.getEntityStr2(ele, index, strIndex);
          })}
          {setArray.forEach((ele, index) => {
            if (ele !== "" && ele.type === "input") {
              this.resize(`input_${index}_${strIndex}`);
            }
          })}
        </div>
      );
    } else {
      let { intentSentences, selectedIntent, oldPhrase } = this.state;
      return (
        <Input
          isLiteration
          value={phrase}
          className="w-100 bg-transparent bot-layout-greetings-inp border-0 p-2"
          style={{ outline: "none" }}
          readOnly={this.props.permissions.intents.modify !== "enabled"}
          onChange={(e) => {
            if (!oldPhrase) {
              oldPhrase = intentSentences[strIndex];
              this.setState({ oldPhrase });
            }
            typeof e === "string"
              ? (intentSentences[strIndex] = e)
              : (intentSentences[strIndex] = e.target.value);
            this.setState({ intentSentences });
          }}
          onSelect={(event) => {
            this.props.permissions.intents.modify === "enabled" &&
              this.inputOnSelect(event, strIndex);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              if (
                /[A-Za-z0-9\.\:\_\$\{\}\[\]]+$/.test(
                  intentSentences[strIndex]
                ) &&
                intentSentences[strIndex].match(/(\w+)/g).length < 30
              ) {
                this.setState(
                  { oldPhrase: "" },
                  () =>
                    oldPhrase &&
                    this.toggleSave(
                      "_update",
                      intentSentences,
                      selectedIntent,
                      "",
                      oldPhrase,
                      intentSentences[strIndex]
                    )
                );
              } else {
                this.props.setNotifications({
                  color: "black",
                  message:
                    "Sentence should not contain speacial characters and should be less than 30 words",
                });
              }
            }
          }}
        />
      );
    }
  }

  getEntityStr2(ele, index, strIndex) {
    let { intentSentences, selectedIntent, oldPhrase } = this.state;
    if (ele !== "" && ele.type === "span") {
      return (
        <>
          <div
            // style={{ borderRadius: "1rem", padding: "0 .5rem" }}
            style={{ backgroundColor: "#D9736A", border: "1px solid red" }}
            className="p-0 btn-sm rounded-0 btn position-relative"
            onMouseDown={(e) =>
              this.setState({
                posY: e.nativeEvent.clientY,
                posX:
                  e.target.offsetParent.offsetLeft > 945
                    ? 945
                    : e.target.offsetParent.offsetLeft,
              })
            }
          >
            <p
              className="m-0"
              onClick={() => {
                this.props.permissions.intents.modify === "enabled" &&
                  this.setState({
                    selectedEntity: { ele, index: strIndex },
                  });
              }}
            >
              {ele.txt}
            </p>
          </div>
        </>
      );
    } else if (ele !== "" && ele.type === "input") {
      return (
        <Input
          className="bg-transparent p-2 px-0 bot-layout-greetings-inp d-inline-block"
          style={{
            boxShadow: "none",
            border: 0,
            outline: 0,
            width: `${(ele.txt.length + 1) * 8}px`,
          }}
          value={ele.txt}
          id={`input_${index}_${strIndex}`}
          readOnly={this.props.permissions.intents.modify !== "enabled"}
          onChange={(ele) => {
            if (!oldPhrase) {
              oldPhrase = intentSentences[strIndex];
              this.setState({ oldPhrase });
            }
            let splitArr = this.getSplitArr(intentSentences[strIndex]);
            typeof ele === "string"
              ? (splitArr[index] = ele)
              : (splitArr[index] = ele.target.value);
            splitArr.join("");
            intentSentences[strIndex] = splitArr.join("");
            this.setState(
              { intentSentences },
              this.resize(`input_${index}_${strIndex}`)
            );
          }}
          onSelect={(event) => {
            this.props.permissions.intents.modify === "enabled" &&
              this.inputOnSelect(event, strIndex, index);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              if (
                /[A-Za-z0-9\.\:\_\$\{\}\[\]]+$/.test(
                  intentSentences[strIndex]
                ) &&
                intentSentences[strIndex].match(/(\w+)/g).length < 30
              ) {
                this.setState(
                  { oldPhrase: "" },
                  () =>
                    oldPhrase &&
                    this.toggleSave(
                      "_update",
                      intentSentences,
                      selectedIntent,
                      "",
                      oldPhrase,
                      intentSentences[strIndex]
                    )
                );
              } else {
                this.props.setNotifications({
                  color: "black",
                  message:
                    "Sentence should not contain speacial characters and should be less than 30 words",
                });
              }
            }
          }}
        />
      );
    } else {
      return;
    }
  }

  inputOnSelect(event, strIndex, index) {
    if (
      event.target.value.substring(
        event.target.selectionStart,
        event.target.selectionEnd
      ) !== ""
    ) {
      this.setState({
        posY: event.nativeEvent.clientY,
        posX: event.nativeEvent.offsetX > 945 ? 945 : event.nativeEvent.offsetX,
        newTagEntityName: "",
        selectedEntity: false,
        createIntent: false,
        newIntentName: "",
        newIntentSentences: [],
        newIntentNewSentence: "",
        position: strIndex,
        tagEntity: true,
        tagEntitySelectedPhrase:
          event.target.value.substring(
            event.target.selectionStart,
            event.target.selectionEnd
          ) + `#${strIndex}#${index}`,
      });
    } else {
      this.setState({
        showLeft: false,
        tagEntity: false,
        newTagEntityName: "",
        selectedEntity: false,
        createIntent: false,
        newIntentName: "",
        newIntentSentences: [],
        newIntentNewSentence: "",
        tagEntitySelectedPhrase: "",
      });
    }
  }

  getResizeNumber(num) {
    if (num < 10) return 11;
    else if (num < 12) return 9.2;
    else if (num <= 16) return 10.5;
    else if (num < 20) return 9.2;
    else if (num <= 30) return 9;
    else if (num < 35) return 8;
    else if (num < 65) return 8.6;
    else if (num < 75) return 7.8;
    else if (num < 90) return 7.6;
    else return 7.8;
  }

  resize(txtID) {
    var hide = document.getElementById("hide");
    var txt = document.getElementById(txtID);
    if (hide && txt) {
      hide.textContent = txt.value;
      txt.style.width =
        txt.value === "" ? "auto" : `${(txt.value.length + 1) * 8}px`;
      // : `${txt.value.length}ch`;
    }
  }

  handleSelect(strIndex, index) {
    let temp = document.getSelection().toString();
    this.setState({
      tagEntitySelectedPhrase: temp + `#${strIndex}#${index}`,
      position: strIndex,
      tagEntity: true,
      text: temp,
    });
  }

  handleEntityClick(e) {
    let { intentSentences, tagEntitySelectedPhrase, entites, selectedIntent } =
      this.state;

    if (tagEntitySelectedPhrase) {
      let splitPhrase = tagEntitySelectedPhrase.split("#");
      if (splitPhrase.length > 3) {
        splitPhrase[3] = e;
        tagEntitySelectedPhrase = splitPhrase.join("#");
      } else {
        tagEntitySelectedPhrase = `${tagEntitySelectedPhrase}#${e}#select`;
      }
      this.setState({ tagEntitySelectedPhrase });
      let splitPhrases = tagEntitySelectedPhrase.split("#");
      let oldPhraseTemp = intentSentences[splitPhrases[1]];
      if (oldPhraseTemp.split(splitPhrases[0]).length > 2) {
        intentSentences[splitPhrases[1]] = intentSentences[
          splitPhrases[1]
        ].replaceAll(
          splitPhrases[0],
          `[${splitPhrases[0]}]\${{${splitPhrases[3] || entites[0]},${
            splitPhrases[0]
          }}}`
        );
      } else {
        intentSentences[splitPhrases[1]] = intentSentences[
          splitPhrases[1]
        ].replace(
          splitPhrases[0],
          `[${splitPhrases[0]}]\${{${splitPhrases[3] || entites[0]},${
            splitPhrases[0]
          }}}`
        );
      }
      this.setState(
        {
          selectionText: "",
          position: "",
          intentSentences,
          tagEntitySelectedPhrase: "",
          posX: null,
          posY: null,
        },
        () => {
          this.toggleSave(
            "_update",
            intentSentences,
            selectedIntent,
            true,
            oldPhraseTemp,
            intentSentences[splitPhrases[1]],
            true
          );
        }
      );
    }
  }

  handleUntagEntity(selectedEntity) {
    let { intentSentences, selectedIntent } = this.state;
    let oldPhraseTemp = intentSentences[selectedEntity.index];
    if (
      oldPhraseTemp.split(
        `[${selectedEntity.ele.txt}]\${{${selectedEntity.ele.value}}}`
      ).length > 2
    ) {
      intentSentences[selectedEntity.index] = intentSentences[
        selectedEntity.index
      ].replaceAll(
        `[${selectedEntity.ele.txt}]\${{${selectedEntity.ele.value}}}`,
        selectedEntity.ele.txt
      );
    } else {
      intentSentences[selectedEntity.index] = intentSentences[
        selectedEntity.index
      ].replace(
        `[${selectedEntity.ele.txt}]\${{${selectedEntity.ele.value}}}`,
        selectedEntity.ele.txt
      );
    }

    this.setState(
      {
        selectedEntity: null,
        intentSentences,
        tagEntity: false,
        position: "",
        selectionText: null,
        posX: null,
        posY: null,
      },
      () => {
        this.toggleSave(
          "_update",
          intentSentences,
          selectedIntent,
          "",
          oldPhraseTemp,
          intentSentences[selectedEntity.index]
        );
      }
    );
  }

  renderBotPhrases() {
    let {
      intentSentences,
      trainedIntentSentences,
      selectedIntent,
      // oldPhrase,
      selectionText,
      position,
      entites,
      tagEntitySelectedPhrase,
      tagEntity,
      selectedEntity,
      onPhraseHover,
      posY,
      posX,
      listId,
      oldPhrase,
    } = this.state;

    let colors = [
      "text-primary",
      "text-danger",
      "text-warning",
      "text-secondary",
    ];

    return (
      intentSentences &&
      intentSentences.map((b, i) => (
        <div className="p-0 m-0" style={{ position: "relative" }} key={i}>
          <div
            className={`col-12 greeting-msg-container rounded${
              onPhraseHover === i ? " shadow-sm" : ""
            }`}
            key={i}
            id={i}
            onMouseEnter={() => this.setState({ onPhraseHover: i })}
            onMouseLeave={() => this.setState({ onPhraseHover: false })}
          >
            <div className="row m-0 align-items-center">
              <div
                className="col d-flex align-items-center"
                onMouseUp={() => this.handleSelect(i)}
              >
                <div className="img-fluid me-4">
                  {trainedIntentSentences?.indexOf(b) > -1
                    ? getIcon("activeBot", "#25293D")
                    : getIcon("activeBot", "#e2e2e2")}
                </div>
                {this.getIntentPhraseEntityView(b, i)}
              </div>
              <div
                className={`col-auto pe-0 justify-content-end${
                  onPhraseHover === i || listId === i ? " d-flex" : " d-none"
                }`}
                ref={this.listIdRef}
                onClick={() => this.setState({ listId: i })}
              >
                <DropDown
                  dropdownList={[
                    { label: "Disable", id: i, icon: getIcon("disable") },
                    {
                      label: "Edit",
                      id: i,
                      icon: getIcon("edit"),
                      isUsed:
                        this.props.permissions.intents.modify !== "enabled",
                    },
                    {
                      label: "Delete",
                      id: i,
                      icon: this.getDeleteModal("phrase", i),
                      isUsed:
                        this.props.permissions.intents.modify !== "enabled",
                    },
                  ]}
                  actionButton={() => getIcon("verticalOptions")}
                  onActionItemClick={(menu) =>
                    this.handleDropDownClick(menu, i)
                  }
                  btnClassName="px-1 py-0"
                  isContent={true}
                />
              </div>
            </div>
          </div>
          {tagEntitySelectedPhrase &&
            position === i &&
            tagEntity &&
            this.props.permissions.intents.modify === "enabled" && (
              <div
                className="entity-list shadow rounded-3"
                style={{
                  bottom: `${posY > 700 ? "60px" : ""}`,
                  left: `${posX}px`,
                }}
                ref={this.ref}
              >
                <Input
                  className="border-0 p-2"
                  placeholder="Create new Entity"
                  value={
                    tagEntitySelectedPhrase &&
                    tagEntitySelectedPhrase.split("#")[4] === "new"
                      ? tagEntitySelectedPhrase.split("#")[3]
                      : ""
                  }
                  onChange={(e) => {
                    let splitPhrase = tagEntitySelectedPhrase.split("#");
                    if (splitPhrase.length > 3) {
                      splitPhrase[3] = e.target.value;
                      tagEntitySelectedPhrase = splitPhrase.join("#");
                    } else {
                      tagEntitySelectedPhrase = `${tagEntitySelectedPhrase}#${e.target.value}#new`;
                    }
                    this.setState({ tagEntitySelectedPhrase });
                  }}
                  onKeyPress={({ key }) => {
                    if (key === "Enter") {
                      let splitPhrase = tagEntitySelectedPhrase.split("#");
                      let oldPhraseTemp = intentSentences[splitPhrase[1]];
                      intentSentences[splitPhrase[1]] = intentSentences[
                        splitPhrase[1]
                      ].replace(
                        splitPhrase[0],
                        `[${splitPhrase[0]}]\${{${
                          splitPhrase[3] || entites[0]
                        },${splitPhrase[0]}}}`
                      );
                      this.setState(
                        {
                          selectionText: "",
                          position: "",
                          intentSentences,
                          tagEntitySelectedPhrase: "",
                        },
                        () =>
                          this.toggleSave(
                            "_update",
                            intentSentences,
                            selectedIntent,
                            "",
                            oldPhraseTemp,
                            intentSentences[splitPhrase[1]]
                          )
                      );
                      if (splitPhrase[4] === "new") {
                        let synonyms = {};
                        let body = {};
                        synonyms[splitPhrase[0]] = [];
                        body = {
                          entity: splitPhrase[3],
                          values: [splitPhrase[0]],
                          synonyms,
                        };
                        this.toggleSave("_addEntity", body, intentSentences);
                      } else {
                        this.setState({
                          intentSentences,
                          tagEntity: false,
                          tagEntitySelectedPhrase: "",
                        });
                      }
                    }
                  }}
                />
                <hr className="mt-0" />
                <div>
                  <span className="mb-1 header px-2">Existing Entity</span>
                  {entites &&
                    entites.map((e, ix) => (
                      <div
                        key={ix}
                        className={`${
                          selectedEntity &&
                          selectedEntity.ele.value.split(",")[0] === e &&
                          "alert-success"
                        } p-2 cursor-pointer list d-flex justify-content-between`}
                        onClick={() =>
                          !selectedEntity && this.handleEntityClick(e)
                        }
                      >
                        <span className="">
                          {getIcon(
                            "bot-list",
                            "",
                            `${colors[ix]} me-2`,
                            "0.7rem"
                          )}{" "}
                          {e}
                        </span>
                        {selectedEntity &&
                          selectedEntity.ele.value.split(",")[0] === e && (
                            <Button
                              className="btn btn-sm"
                              onClick={() =>
                                this.handleUntagEntity(selectedEntity)
                              }
                            >
                              Untag
                            </Button>
                          )}
                      </div>
                    ))}
                </div>
              </div>
            )}
        </div>
      ))
    );
  }

  onPageRefresh = () => {
    this.setState(
      {
        intents: [],
        trainedIntents: [],
        addNewIntent: "",
        selectedIntent: undefined,
        intentNewName: "",
        intentOldName: "",
        modal: false,
      },
      () => {
        setTimeout(() => {
          this.getBotIntents();
        }, 100);
      }
    );
  };

  render() {
    let {
      intents,
      trainedIntents,
      addNewIntent,
      selectedIntent,
      intentNewName,
      industryList,
      intentSentences,
      count,
      pageNo,
      size,
    } = this.state;

    return (
      <div className="container-fluid h-100">
        <span
          id="hide"
          style={{ position: "absolute", height: 0, overflow: "hidden" }}
        ></span>
        <div className="row h-100">
          <BotLayoutLeftPanel
            title="Intent"
            sideBarList={intents}
            industryList={industryList}
            industryClick={this.industryClick}
            searchLibrary={this.searchLibrary}
            onCancelClick={this.onCancelClick}
            libraryData={this.state.libraryData}
            addLibraryStatus={this.state.addLibrary}
            addIntentLibrary={this.addIntentLibrary}
            handleLibraryClick={this.handleLibraryClick}
            handleLibraryExpand={this.handleLibraryExpand}
            BulkImport={this.bulkImportComp}
            onClickImport={this.onClickImport}
            trainedIntent={trainedIntents}
            selectedIntent={selectedIntent}
            value={addNewIntent}
            isIntent={true}
            onPageRefresh={this.onPageRefresh}
            addBtnHide={this.props.permissions.intents.add}
            disableMultiDelete={
              this.props.permissions.intents.delete === "disabled"
            }
            onChange={(e) => {
              addNewIntent = e.target.value;
              this.setState({ addNewIntent });
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter" && addNewIntent) {
                if (!intents.find((e) => e.label === addNewIntent)) {
                  if (/^[A-Za-z][A-Za-z0-9_]+$/.test(addNewIntent)) {
                    intents.push(addNewIntent);
                    this.toggleSave("_add", addNewIntent);
                    this.setState({ intents, addNewIntent: "" });
                  } else {
                    this.setState({ addNewIntent: "" });
                    this.props.setNotifications({
                      color: "black",
                      message:
                        "Intent name should not contain special characters or spaces",
                    });
                  }
                } else {
                  this.setState({ addNewIntent: "" });
                  this.props.setNotifications({
                    color: "black",
                    message: "Intent already exists",
                  });
                }
              }
            }}
            onMenuSelect={({ label }) => {
              if (this.props.permissions.intents.list === "enabled") {
                const localIntents = intents.map((i) => {
                  i.isSelected = i.label === label;
                  return i;
                });

                this.setState({
                  intents: localIntents,
                  selectedIntent: label,
                  intentNewName: label,
                  pageNo: 1,
                  size: 50,
                });
                this.props.permissions.intents.content === "enabled" &&
                  this.getIntentSentences(label);
              }
            }}
          />

          <BotLayoutContent
            titleValue={intentNewName}
            isSelected={selectedIntent}
            addNewValue={this.state.addNewValue}
            titleOnChange={this.handleTitleChange}
            onAddNewChange={this.handleAddNewValue}
            addNewPlacholder={"In other words user may say…"}
            intentsWarning={"*Please add a minimum of 5 sentences"}
            titleReadOnly={this.props.permissions.intents.modify !== "enabled"}
            disableAddNew={this.props.permissions.intents.modify !== "enabled"}
            isShowContent={this.props.permissions.intents.content === "enabled"}
            actions={[
              {
                label: "Delete",
                icon: this.getDeleteModal("intent"),
                isUsed: this.props.permissions.intents.delete === "disabled",
              },
            ]}
            onActionClick={(menu) => {
              this.setState({ modal: true });
              setTimeout(() => {
                this.setState({ modal: false });
              }, 100);
            }}
          >
            {!!intents.filter((int) => int.label === selectedIntent).length &&
              this.props.permissions.intents.content === "enabled" && (
                <div className="m-0">
                  <div className="row m-0">
                    {this.props.permissions.intents.content === "enabled" &&
                      this.renderBotPhrases()}
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      bottom: "10px",
                      left: "48%",
                    }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <i
                      className={`fas fa-angle-left mx-2 col-auto fs-5 me-2 ${
                        pageNo > 1 ? "cursor-pointer" : "text-muted"
                      }`}
                      onClick={() => {
                        if (
                          this.props.permissions.intents.content ===
                            "enabled" &&
                          pageNo > 1
                        ) {
                          let temp = pageNo - 1;
                          this.setState(
                            { pageNo: temp },
                            this.getIntentSentences(selectedIntent, temp)
                          );
                        }
                      }}
                    />
                    <span className="intent-pagination-text px-3">
                      {" "}
                      {pageNo === 1 ? 1 : (pageNo - 1) * size} -{" "}
                      {(pageNo - 1) * size + intentSentences?.length} of {count}{" "}
                    </span>
                    <i
                      className={`fas fa-angle-right mx-2 col-auto fs-5 ms-2 ${
                        pageNo * size < count ? "cursor-pointer" : "text-muted"
                      }`}
                      onClick={() => {
                        if (
                          this.props.permissions.intents.content ===
                            "enabled" &&
                          pageNo * size < count
                        ) {
                          let temp = pageNo + 1;
                          this.setState(
                            { pageNo: temp },
                            this.getIntentSentences(selectedIntent, temp)
                          );
                        }
                      }}
                    />
                  </div>
                </div>
              )}
          </BotLayoutContent>

          <BotLayoutRightPanel />
        </div>
      </div>
    );
  }
}

export default function Intents() {
  const { setNotifications } = useContext(NotificationContext);
  const { getUserDetails, getUserFlows } = useContext(UserContext);

  return (
    <Intent
      setNotifications={setNotifications}
      getUserFlows={getUserFlows}
      permissions={getUserDetails().feature_permission}
    />
  );
}
