import React, { Component } from "react";
import dagre from "dagre";
import { isNode } from "react-flow-renderer";
import intentServices from "../services/api.services/intentsApi.service";
import utteranceServices from "../services/api.services/utteranceApi.service";
import formsServices from "../services/api.services/formsApi.service";
import slotService from "../services/api.services/slotsApi.service";
// import actionService from "../services/api.services/actionsApi.service";
import botService from "../services/bot.service";
import actionSerivces from "../services/api.services/actionsApi.service";
import baseUrl from "../services/baseURL.service";
import { getToken } from "../services/authUtil";
import apiServices from "../services/api.services";

import { v4 as uuidv4 } from "uuid";

export const BotContext = React.createContext();

const getLayoutedElements = (elements, direction = "TB", isIntent) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 250;
  const nodeHeight = 180;

  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction });

  elements.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, { width: nodeWidth, height: nodeHeight });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  return elements.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.targetPosition = isHorizontal ? "left" : "top";
      el.sourcePosition = isHorizontal ? "right" : "bottom";

      if (
        el.type === "U" &&
        typeof el.data.exts.intent === "object" &&
        el.data.exts.intent.length > 1
      ) {
        el.position = {
          x:
            nodeWithPosition.x -
            nodeWidth / 2 +
            Math.random() / 1000 +
            (el.type === "D" ? 74 : 0),
          y:
            nodeWithPosition.y -
            (el.type === "D" ? nodeHeight + 80 : nodeHeight) / 2,
        };
      } else {
        if (!el.position || isIntent) {
          el.position = {
            x:
              nodeWithPosition.x -
              nodeWidth / 2 +
              Math.random() / 1000 +
              (el.type === "D" ? 74 : 0),
            y:
              nodeWithPosition.y -
              (el.type === "D" ? nodeHeight + 80 : nodeHeight) / 2,
          };
        }
      }
    }

    return el;
  });
};

const getBotType = (key) => {
  return {
    bot: "B",
    action: "A",
    user: "U",
    restart: "R",
    eoc: "E",
    tta: "T",
    // dtmf: "DT",
    decision: "D",
    slot: "S",
    form: "F",
    event: "EV",
    "dtmf-input": "DT-I",
    "dtmf-decision": "DT-I",
    flow: "L",
  }[key];
};

var nodeCount = 0,
  // isHidden = false,
  resultPaths = [],
  resultEdges = [],
  decisionData = {},
  loopStack = [];

const addInitialNodes = (elements, parentIndex, main_path) => {
  elements.push(
    getNodeObj(`s1`, "A", {
      exts: {
        pathName: "main_path",
        who: "action",
        actions: [],
        selections: "",
        // actions: ["initial_action"],
        // selections: "initial_action",
      },
      isChildren: false,
      isDefaultNode: true,
    })
  );
  elements.push(
    getNodeObj(`s2`, "B", {
      exts: { pathName: "main_path", who: "bot", actions: [], selections: "" },
      isChildren: false,
      isDefaultNode: true,
    })
  );

  elements.push(getNodeEdge(`edge-0->s1`, parentIndex, `s1`));
  elements.push(getNodeEdge(`edge-s1->s2`, `s1`, `s2`));
};

const getFlowData = (_paths, _pathName, subflow, fullView, prevBotId) => {
  let elements = [];
  let edges = [];
  let nodeCount = 1;
  let parentIndex = 0;
  let decisionData = {};
  let intentData = {};
  let intentIndex;

  if (fullView) {
    _pathName === "main_path" &&
      elements.push(
        getNodeObj(parentIndex, "Sa", {
          isStartNode: true,
          exts: { pathName: _pathName, who: "start" },
          isChildren: _paths[_pathName].length > 0,
        })
      );
  } else {
    elements.push(
      getNodeObj(parentIndex, "Sa", {
        isStartNode: true,
        exts: { pathName: _pathName, who: "start" },
        isChildren: _paths[_pathName].length > 0,
      })
    );
  }

  !subflow &&
    !_paths["main_path"].length &&
    addInitialNodes(elements, parentIndex, _paths["main_path"]);

  (function recurse(paths, pathName, _parentID = 0, decisionID) {
    paths[pathName] &&
      paths[pathName].forEach((path, index) => {
        let data = {},
          type = "",
          id = fullView
            ? nodeCount + "-" + uuidv4() + Math.random()
            : nodeCount + "-" + uuidv4();
        nodeCount++;

        if (fullView) {
          parentIndex =
            index === 0 && pathName.includes("sub-")
              ? prevBotId
              : decisionID && index === 0
              ? _parentID
              : parentIndex;
        } else {
          parentIndex =
            index === 0 && pathName !== "main_path" ? _parentID : parentIndex;
        }

        switch (path.who) {
          case "tta":
          case "flow":
          case "restart":
          case "eoc":
          case "dtmf-input":
          case "event":
          case "slot":
          case "form":
          case "bot":
          case "action":
          case "user":
            type = getBotType(path.who);
            data = {
              parentIndex,
              isChildren: ["bot", "action"].includes(path.who)
                ? paths[pathName].length > index + 1 &&
                  [
                    "eoc",
                    "tta",
                    "restart",
                    "form",
                    "bot",
                    "action",
                    "event",
                    "flow",
                  ].includes(paths[pathName][index + 1].who)
                : paths[pathName].length > index + 1,
              exts: {
                ...path,
                selections: ["eoc", "restart"].includes(path.who)
                  ? ""
                  : ["event"].includes(path.who)
                  ? path.type
                  : // : path.who === "bot"
                  ["bot", "action"].includes(path.who)
                  ? path.actions[0]
                  : path.who === "user"
                  ? typeof path.intent === "object"
                    ? path.intent[0]
                    : path.intent
                  : path.who === "slot"
                  ? path.slots
                  : path.who === "form"
                  ? path.form
                  : path.who === "dtmf-input"
                  ? path.digits
                  : path.who === "flow"
                  ? path.flow
                  : "",
                pathName,
                index: index + 1,
              },
            };
            if (
              paths[pathName].length > index + 1 &&
              ["decision", "user"].includes(paths[pathName][index + 1].who)
            ) {
              data["isBranchParent"] = true;
            }

            if (
              paths[pathName].length > index + 1 &&
              ["slot-decision", "slot"].includes(paths[pathName][index + 1].who)
            ) {
              data["isSlotBranch"] = true;
            }
            if (
              paths[pathName].length > index + 1 &&
              ["dtmf-decision", "dtmf-input"].includes(
                paths[pathName][index + 1].who
              )
            ) {
              data["isDTMFBranch"] = true;
            }
            if (
              path.who === "user" &&
              typeof path.intent === "object" &&
              path.intent.length > 1
            ) {
              intentData[index] = [];
              path.intent.forEach((int, ix) => {
                if (ix > 0) {
                  let id = nodeCount++ + "-" + uuidv4();
                  let newData = JSON.parse(JSON.stringify(data));
                  newData.exts.selections = int;
                  newData.exts.intent = path.intent;
                  newData.exts.intentID = ix;
                  elements.push(getNodeObj(id, type, newData));
                  edges.push(
                    getNodeEdge(`edge-${parentIndex}-${id}`, parentIndex, id)
                  );
                }
              });
              intentIndex = index;
            }

            path.position
              ? elements.push(getNodeObj(id, type, data, path.position))
              : elements.push(getNodeObj(id, type, data));
            edges.push(
              getNodeEdge(`edge-${parentIndex}-${id}`, parentIndex, id)
            );
            parentIndex = id;
            if (
              path.who === "user" &&
              typeof path.intent === "object" &&
              path.intent.length > 1
            )
              intentData[index] = elements.filter(
                (ele) =>
                  ele.data.exts.index === index + 1 &&
                  ele.data.exts.who === "user"
              );
            break;

          case "decision":
            if (pathName !== "main_path") {
              if (Object.keys(path.checkpoints).length > 0) {
                decisionData[parentIndex] = path.checkpoints;
                Object.keys(path.checkpoints).forEach((checkpoint, cIndex) => {
                  type = "U";
                  id = cIndex > 0 ? nodeCount + "-" + uuidv4() : id; // + "-" + Date.now();
                  cIndex > 0 && nodeCount++;
                  data = {
                    parentIndex,
                    isChildren: paths[checkpoint]?.length > 0,
                    exts: {
                      ...path,
                      selections:
                        path.checkpoints[checkpoint].length > 0
                          ? path.checkpoints[checkpoint][0].intent
                          : "",
                      pathName: checkpoint,
                      index: 0,
                    },
                  };
                  data.exts.who = "user";
                  data.exts.decisionData = decisionData;
                  data.exts.checkpointID = 0;
                  path?.checkpoints[checkpoint][0]?.position
                    ? elements.push(
                        getNodeObj(
                          id,
                          type,
                          data,
                          path.checkpoints[checkpoint][0].position
                        )
                      )
                    : elements.push(getNodeObj(id, type, data));

                  if (path.checkpoints[checkpoint].length > 1) {
                    path.checkpoints[checkpoint].forEach((ete, ix) => {
                      if (ix > 0) {
                        let id = nodeCount++ + "-" + uuidv4();
                        let newData = JSON.parse(JSON.stringify(data));
                        newData.exts.selections = ete.intent;
                        newData.exts.checkpointID = ix;
                        ete?.position
                          ? elements.push(
                              getNodeObj(id, type, newData, ete.position)
                            )
                          : elements.push(getNodeObj(id, type, newData));
                      }
                    });
                  }
                  recurse(paths, checkpoint, id, true);
                });
              }
            }
            break;
          case "dtmf-decision":
            if (pathName !== "main_path") {
              if (Object.keys(path.checkpoints).length > 0) {
                decisionData[parentIndex] = path.checkpoints;
                Object.keys(path.checkpoints).forEach((checkpoint, cIndex) => {
                  type = "DT-I";
                  id = cIndex > 0 ? nodeCount + "-" + uuidv4() : id; // + "-" + Date.now();
                  cIndex > 0 && nodeCount++;
                  data = {
                    isDTMFBranch: true,
                    parentIndex,
                    isChildren: paths[checkpoint].length > 0,
                    exts: {
                      ...path,
                      selections:
                        path.checkpoints[checkpoint].length > 0
                          ? path.checkpoints[checkpoint][0]["dtmf-value"]
                          : "",
                      dtmfValue:
                        path.checkpoints[checkpoint].length > 0
                          ? path.checkpoints[checkpoint][0]["dtmf-value"]
                          : 0,
                      pathName: checkpoint,
                      index: 0,
                    },
                  };
                  data.exts.who = "dtmf-input";

                  data.exts.checkpointID = 0;
                  path.checkpoints[checkpoint][0]["position"]
                    ? elements.push(
                        getNodeObj(
                          id,
                          type,
                          data,
                          path.checkpoints[checkpoint][0]["position"]
                        )
                      )
                    : elements.push(getNodeObj(id, type, data));

                  if (path.checkpoints[checkpoint].length > 1) {
                    path.checkpoints[checkpoint].forEach((ete, ix) => {
                      if (ix > 0) {
                        let id = nodeCount++ + "-" + uuidv4();
                        let newData = JSON.parse(JSON.stringify(data));
                        newData.exts.selections = ete["dtmf-value"];
                        newData.exts.checkpointID = ix + 1;
                        elements.push(getNodeObj(id, type, newData));
                      }
                    });
                  }
                  recurse(paths, checkpoint, id, true);
                });
              }
            }
            break;
          case "slot-decision":
            if (pathName !== "main_path") {
              if (Object.keys(path.checkpoints).length > 0) {
                decisionData[parentIndex] = path.checkpoints;
                Object.keys(path.checkpoints).forEach((checkpoint, cIndex) => {
                  type = "DT-I";
                  id = cIndex > 0 ? nodeCount + "-" + uuidv4() : id; // + "-" + Date.now();
                  cIndex > 0 && nodeCount++;
                  data = {
                    isSlotBranch: true,
                    parentIndex,
                    isChildren: paths[checkpoint].length > 0,
                    exts: {
                      ...path,
                      selections:
                        path.checkpoints[checkpoint].length > 0
                          ? path.checkpoints[checkpoint][0].slots
                          : "",
                      pathName: checkpoint,
                      index: 0,
                    },
                  };
                  data.exts.who = "slot";

                  data.exts.checkpointID = 0;
                  path.checkpoints[checkpoint][0] &&
                  path.checkpoints[checkpoint][0]["position"]
                    ? elements.push(
                        getNodeObj(
                          id,
                          type,
                          data,
                          path.checkpoints[checkpoint][0]["position"]
                        )
                      )
                    : elements.push(getNodeObj(id, type, data));

                  recurse(paths, checkpoint, id, true);
                });
              }
            }
            break;
          default:
            break;
        }
      });
  })(_paths, _pathName);

  Object.keys(decisionData).forEach((pId) => {
    Object.keys(decisionData[pId]).forEach((id) => {
      let childEle = elements.filter(
        (ele) => ele.data.exts.pathName === id && ele.data.exts.index === 0
      );
      childEle.forEach((chdElement, index) => {
        edges.push(
          getNodeEdge(`edge-${pId}->${chdElement.id}`, pId, chdElement.id)
        );
        if (index > 0) {
          let childPathID = edges.find(
            (ed) => ed.source === childEle[index - 1].id
          );
          if (childPathID)
            edges.push(
              getNodeEdge(
                `edge-${chdElement.id}->${childPathID.target}`,
                chdElement.id,
                childPathID.target
              )
            );
        }
      });
    });
  });

  Object.keys(intentData).forEach((index) => {
    if (intentData[index].length > 1) {
      intentData[index].forEach((int) => {
        if (int.data.exts.intent && int.data.exts.intent.length > 1) {
          let childEle = elements.find(
            (ele) =>
              ele.data.exts.index === int.data.exts.index + 1 &&
              ele.data.exts.pathName === int.data.exts.pathName
          );
          if (int.data.exts.intentID && childEle) {
            edges.push(
              getNodeEdge(
                `edge-${int.id}->${childEle?.id}`,
                int.id,
                childEle?.id
              )
            );
          }
        }
      });
    }
  });

  // if (
  //   _paths["main_path"].length &&
  //   !_paths["main_path"].some((obj) => obj.who === "bot") &&
  //   !elements.some(
  //     (node) =>
  //       node.data.exts === "bot" && node.data.exts.pathName === "main_path"
  //   )
  // ) {
  //   // elements.push(
  //   //   getNodeObj(`s2`, "B", {
  //   //     exts: {
  //   //       pathName: "main_path",
  //   //       who: "bot",
  //   //       actions: [],
  //   //       selections: "",
  //   //     },
  //   //     isChildren: false,
  //   //     isDefaultNode: true,
  //   //   })
  //   // );

  //   let actionNode = elements.find(
  //     (node) =>
  //       node.data.exts.pathName === "main_path" &&
  //       node.data.exts.who === "action"
  //   );
  //   elements.push(getNodeEdge(`edge-s1->s2`, actionNode.id, `s2`));
  // }

  return elements.concat(edges);
};

function getNodeObj(id, type, data, position) {
  return {
    id: id.toString(),
    type,
    data,
    position,
  };
}

function getNodeEdge(
  id,
  source,
  target,
  type = "smoothstep",
  animated = true,
  arrowHeadType = "arrowclosed"
) {
  return {
    id,
    source: source.toString(),
    target: target.toString(),
    type,
    animated,
    arrowHeadType,
  };
}

function getNodesAndLinks(data, remove) {
  let nodes = {};
  data
    .filter((obj) => obj.hasOwnProperty("data"))
    .forEach((ele) => (nodes[`${ele.id}`] = ele));

  let links = data
    .filter((obj) => obj.hasOwnProperty("arrowHeadType"))
    .map((obj) => {
      return {
        id: obj.id,
        source: obj.source,
        target: obj.target,
      };
    });

  if (remove)
    return {
      nodes: nodes,
      links: links,
    };
  else
    return {
      nodes: nodes,
      links: data.filter((obj) => obj.hasOwnProperty("arrowHeadType")),
    };
}

export class BotProvider extends Component {
  state = {
    paths: {},
    pathsCopy: {},
    flowData: [],
    fullNodePath: [],
    navBarButtons: "",
    intents: [],
    utterances: [],
    forms: [],
    slots: [],
    addNewIntent: undefined,
    channelIntegrations: {},
    subFlowName: "",
    sub_path: [{ label: "main_path", isSelected: false }],
    isTrainingLog: { show: false, data: [] },
    onlyDisplay: false,
    parentBotId: 0,
  };

  setAddNewIntent = (obj) => {
    this.setState({ addNewIntent: obj });
  };

  getNewIntent = () => {
    return this.state.addNewIntent;
  };

  getFullPath = (paths) => {
    let fullNodePath = [];
    let parentBotId = 0;
    Object.keys(paths).forEach((pathName) => {
      if (pathName === "main_path" || pathName.includes("sub-")) {
        let temp = getFlowData(paths, pathName, "", true, parentBotId);
        fullNodePath = [...fullNodePath, ...temp];
        if (
          pathName === "main_path" &&
          fullNodePath.some(
            (ele) => ele.type === "B" && ele.data.exts.pathName === "main_path"
          )
        ) {
          let mainBotNode = fullNodePath.filter(
            (ele) => ele.type === "B" && ele.data.exts.pathName === "main_path"
          );
          parentBotId = mainBotNode[0].id;
        } else if (
          pathName === "main_path" &&
          !fullNodePath.some(
            (ele) => ele.type === "B" && ele.data.exts.pathName === "main_path"
          )
        ) {
          let mainActionNode = fullNodePath.filter(
            (ele) => ele.type === "A" && ele.data.exts.pathName === "main_path"
          );
          parentBotId = mainActionNode[0].id;
        }
      }
    });
    fullNodePath = getLayoutedElements(fullNodePath, "TB", true);
    this.setState({ fullNodePath, onlyDisplay: true });
  };

  setPaths = (paths) => {
    let flowData = [];
    nodeCount = 0;
    resultPaths = [];
    resultEdges = [];
    decisionData = {};
    loopStack = [];
    let sub = [];

    // let { sub_path } = this.state;
    sub.push({ label: "main_path", isSelected: false });
    Object.keys(paths).forEach((pathName) => {
      if (
        pathName.includes("sub-") &&
        !sub.some((obj) => obj.label === pathName)
      ) {
        sub.push({ label: pathName, isSelected: false });
      }
    });

    if (this.state.pathName) {
      this.setState({ sub_path: sub });
      flowData = getFlowData(paths, this.state.pathName, true);
      flowData = getLayoutedElements(flowData);
      this.setState({ paths, flowData });
    } else {
      flowData = getFlowData(paths, "main_path");
      flowData = getLayoutedElements(flowData);
      this.setState({
        paths,
        flowData,
        sub_path: sub,
        onlyDisplay: false,
        fullNodePath: [],
      });
    }
  };

  getIntents = (isForce) => {
    let { intents } = this.state;

    return intents?.data || [];
  };
  getIntentPhrase = (intent) => {
    let { intents } = this.state;

    return intents?.sample_sentences
      ? intents?.sample_sentences[intent] || ""
      : "";
  };

  setIntents = () => {
    intentServices.getBotIntents().then((res) => {
      if (res.status === "success") this.setState({ intents: res });
    });
  };

  getUtterances = () => {
    return this.state.utterances?.data || [];
  };

  getUtterancePhrase = (utter) => {
    let { utterances } = this.state;

    return utterances?.sample_responses
      ? utterances?.sample_responses[utter] || ""
      : "";
  };

  setUtterances = () => {
    utteranceServices.getBotUtterances().then((res) => {
      if (res.status === "success") this.setState({ utterances: res });
    });
  };

  setForms = () => {
    formsServices.getBotForms().then((res) => {
      if (res.status === "success") this.setState({ forms: res.data });
    });
  };

  getForms = () => {
    return this.state.forms;
  };

  getFormPhrase = () => {
    return;
  };

  setSlots = () => {
    slotService.getBotCategoricalSlots().then((res) => {
      if (res.status === "success") this.setState({ slots: res.data });
    });
  };

  getSlots = () => {
    return this.state.slots;
  };

  setActions = () => {
    actionSerivces.getBotActions().then((res) => {
      if (res.status === "success") this.setState({ actions: res.data });
    });
  };

  getActions = () => {
    return this.state.actions;
  };

  getSlotPhrase = (intent) => {
    let { slots } = this.state;
    slotService.getSlotDetails(intent).then((res) => {});
  };

  setChannels = () => {
    let reqOptions = {
      method: "GET",
      headers: {
        token: getToken(),
      },
    };
    apiServices
      .fetchData(
        `${baseUrl}/get_channel_details/${botService.getSelectedBotId()}`,
        reqOptions
      )
      .then((response) => {
        let integrations = {
          chat: response?.data
            ? Object.keys(response.data).filter((int) =>
                ["freshchat", "salesforce_chat", "servicenow_chat"].includes(
                  int
                )
              )
            : [],
          voice:
            response?.data?.gnani_voice?.agent_transfer_number ||
            response?.data?.twilio_voice?.agent_transfer_number,
        };
        // setChannelIntegrations(integrations);
        this.setState({ channelIntegrations: integrations });
      });
  };

  setZoom = (e) => {
    this.setState({ zoom: e });
  };
  getZoom = () => {
    return this.state.zoom;
  };

  render() {
    let { paths, flowData, navBarButtons, pathsCopy } = this.state;
    return (
      <BotContext.Provider
        value={{
          paths,
          pathsCopy,
          flowData,
          navBarButtons,
          setTainingLog: (status, obj) =>
            this.setState({
              isTrainingLog: {
                show: status,
                data: obj,
              },
            }),
          getTrainingLog: this.state.isTrainingLog,
          setNavBarButtons: (buttons) => {
            this.setState({ navBarButtons: buttons });
          },
          subFlowName: this.state.subFlowName,
          setModal: (s, f) =>
            this.setState({ confirmModal: s, locationPush: f }),
          setSubFlowName: (e) => this.setState({ subFlowName: e.target.value }),
          getFullPath: this.getFullPath,
          addNewSubFlow: this.addNewSubFlow,
          removeUnusedPaths: this.removeUnusedPaths,
          locationPush: this.state.locationPush,
          confirmModal: this.state.confirmModal,
          fullPathList: this.state.fullNodePath,
          subPathList: this.state.sub_path,
          currentPath: this.state.pathName,
          displayState: this.state.onlyDisplay,
          getSubFlow: this.getSubFlow,
          renameSubPath: this.renameSubPath,
          deleteSubPath: this.deleteSubPath,
          getIntents: this.getIntents,
          getIntentPhrase: this.getIntentPhrase,
          setIntents: this.setIntents,
          getUtterances: this.getUtterances,
          getUtterancePhrase: this.getUtterancePhrase,
          setUtterances: this.setUtterances,
          getForms: this.getForms,
          setForms: this.setForms,
          getSlots: this.getSlots,
          setActions: this.setActions,
          getActions: this.getActions,
          getSlotPhrase: this.getSlotPhrase,
          setSlots: this.setSlots,
          getNewIntent: this.getNewIntent,
          setAddNewIntent: this.setAddNewIntent,
          setChannels: this.setChannels,
          setZoom: this.setZoom,
          getZoom: this.getZoom,
          updatePosition: this.updatePosition,
          savePathWithTrain: this.savePath,
          onDragUpdatePosition: this.onDragUpdatePosition,
          getGraphStructure: this.getGraphStructure,
          channelIntegrations: this.state.channelIntegrations,
          paths: this.state.paths,
          setPaths: (paths) => {
            this.setPaths(paths);
          },
          updateNode: this.updateNode,
          addNode: (ele, node) => {
            this.addNode(ele, node);
          },
          addAnotherIntent: this.addAnotherIntent,
          deleteNode: this.deleteNode,
        }}
      >
        {this.props.children}
      </BotContext.Provider>
    );
  }

  getGraphStructure(paths) {
    let newPath = {};

    Object.keys(paths).forEach((pathName) => {
      if (pathName === "main_path" || pathName.includes("sub-")) {
        newPath[pathName] = getNodesAndLinks(
          getFlowData(paths, pathName),
          true
        );
      }
    });
    return newPath;
  }

  getSubFlow = (pathName, main) => {
    let { paths, flowData } = this.state;

    let p = new Object(paths);
    let b = Object.keys(p).reduce((a, k) => {
      if (!k.includes("sub-")) {
        a[k] = p[k];
      }
      return a;
    }, {});
    pathName === "main_path"
      ? (flowData = getFlowData(b, "main_path"))
      : (flowData = getFlowData(paths, pathName, true));
    flowData = getLayoutedElements(flowData);
    this.setState({ paths: [], flowData: [], pathName }, () =>
      this.setState({
        paths,
        flowData,
        pathName,
        onlyDisplay: false,
        fullNodePath: [],
      })
    );
  };

  renameSubPath = (name) => {
    const { pathName, paths } = this.state;
    let temp = paths;
    temp[name] = temp[pathName];
    delete temp[pathName];
    this.setState({ paths: temp }, () => {
      this.savePath(paths);
    });
  };

  savePath = (paths, callback) => {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(paths),
      headers: {
        "Content-Type": "application/json",
        token: getToken(),
      },
    };

    let bot_id = botService.getSelectedBotId();
    !!Object.keys(paths).length &&
      fetch(`${baseUrl}/update_bot_paths/${bot_id}`, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success")
            callback ? callback() : console.log(response.message);
        })
        .catch((error) => {
          console.log(error);
        });
  };

  addNode = (type, parentNode) => {
    let { pathName, index, who } = parentNode.data.exts;
    let { paths, flowData } = this.state;

    let node = {
      who: type,
    };

    switch (type) {
      case "action":
      case "bot":
        node["actions"] = [];
        break;
      case "tta":
        node["agent_numbers"] = [];
        break;
      case "user":
        node["intent"] = [];
        break;
      case "form":
        node["form"] = "";
        break;
      case "slot":
        node["slots"] = "";
        break;
      case "event":
        node["type"] = "";
        break;
      case "dtmf-input":
        node["digits"] = 0;
        node["timeout"] = 5;
      case "dtmf-decision":
        node.who = "dtmf-input";
      case "flow":
        node["flow"] = [];
      case "eoc":
      case "restart":
      default:
        break;
    }

    let newPathName = `path-${uuidv4()}`;

    if (type === "dtmf-decision" && ["bot", "action"].includes(who)) {
      if (paths[pathName][index].who === "dtmf-input") {
        paths[pathName][index].who = "dtmf-decision";
        paths[pathName][index].checkpoints = {};
        paths[pathName][index].checkpoints[newPathName] = [{ "dtmf-value": 0 }];
        paths[newPathName] =
          paths[pathName].length > index
            ? paths[pathName].splice(index + 1)
            : [];

        delete paths[pathName][index].digits;
        delete paths[pathName][index].timeout;

        paths[newPathName + "1"] = [];
        paths[pathName][index].checkpoints[newPathName + "1"] = [
          { "dtmf-value": 0 },
        ];
      } else {
        paths[newPathName] = [];
        paths[pathName][index].checkpoints[newPathName] = [{ "dtmf-value": 0 }];
      }
    } else if (
      ["bot", "action"].includes(who) &&
      paths[pathName].length > index &&
      ["decision", "user"].includes(paths[pathName][index].who)
    ) {
      // let newPathName = `path-${Date.now()}`;
      paths[newPathName] = [];
      if (paths[pathName][index].who === "user") {
        paths[pathName][index].who = "decision";
        paths[pathName][index].checkpoints = {};
        paths[pathName][index].checkpoints[newPathName] = [
          { intent: paths[pathName][index].intent },
        ];
        paths[newPathName] =
          paths[pathName].length > index
            ? paths[pathName].splice(index + 1)
            : [];
        delete paths[pathName][index].intent;
        paths[newPathName + "1"] = [];
        paths[pathName][index].checkpoints[newPathName + "1"] = [
          { intent: "" },
        ];
      } else {
        paths[pathName][index].checkpoints[newPathName] = [];
      }
    } else if (
      ["bot", "action"].includes(who) &&
      paths[pathName].length > index &&
      ["slot-decision", "slot"].includes(paths[pathName][index].who)
    ) {
      paths[newPathName] = [];
      if (paths[pathName][index].who === "slot") {
        paths[pathName][index].who = "slot-decision";
        paths[pathName][index].checkpoints = {};
        paths[pathName][index].checkpoints[newPathName] = [
          { slots: paths[pathName][index].slots },
        ];
        paths[newPathName] =
          paths[pathName].length > index
            ? paths[pathName].splice(index + 1)
            : [];
        delete paths[pathName][index].slots;
        paths[newPathName + "1"] = [];
        paths[pathName][index].checkpoints[newPathName + "1"] = [{ slots: "" }];
      } else {
        paths[pathName][index].checkpoints[newPathName] = [{ slots: "" }];
      }
    } else {
      paths[pathName].push(node);
    }

    flowData = getFlowData(
      paths,
      this.state.pathName ? this.state.pathName : "main_path",
      this.state.pathName
    );
    flowData = getLayoutedElements(flowData);
    this.setState({ paths, flowData });
  };

  deleteSubPath = () => {
    let { paths, pathName, flowData, sub_path } = this.state;

    let sub;
    if (pathName) {
      sub = sub_path.filter((p) => p.label !== pathName);
      let checkpoint = paths[pathName].filter((e) =>
        e.hasOwnProperty("checkpoints")
      )[0];
      if (checkpoint) {
        Object.keys(checkpoint.checkpoints).forEach((path) =>
          this.deletePaths(paths, path)
        );
      }
      delete paths[pathName];
    }

    flowData = getFlowData(paths, "main_path");
    flowData = getLayoutedElements(flowData);
    this.setState({ paths, flowData, sub_path: sub, pathName: "" });
  };

  removeUnusedPaths = (paths) => {
    let connnectedPaths = [];
    Object.keys(paths).forEach((pName) => {
      if (pName.includes("sub")) {
        let temp = paths[pName].filter((e) =>
          e.hasOwnProperty("checkpoints")
        )[0];
        if (temp)
          Object.keys(temp.checkpoints).forEach((point) =>
            this.getConnectedPaths(paths, point, connnectedPaths)
          );
      }
    });
    Object.keys(paths).forEach((name) => {
      !(name.includes("sub") || name.includes("main_path")) &&
        !connnectedPaths.includes(name) &&
        delete paths[name];
    });
    return paths;
  };

  getConnectedPaths(paths, name, array) {
    let dpath = paths[name].find((path) => path.who === "decision");
    let dtmfDpath = paths[name].find((path) => path.who === "dtmf-decision");
    let slotDpath = paths[name].find((path) => path.who === "slot-decision");
    array.push(name);
    if (dpath || dtmfDpath || slotDpath) {
      if (dpath) {
        Object.keys(dpath.checkpoints).forEach((point) => {
          this.getConnectedPaths(paths, point, array);
        });
      }
      if (dtmfDpath) {
        Object.keys(dtmfDpath.checkpoints).forEach((point) => {
          this.getConnectedPaths(paths, point, array);
        });
      }
      if (slotDpath) {
        Object.keys(slotDpath.checkpoints).forEach((point) => {
          this.getConnectedPaths(paths, point, array);
        });
      }
    } else {
      return paths;
    }
  }

  addNewSubFlow = (e) => {
    let { subFlowName, sub_path, paths } = this.state;
    let name = `sub-${subFlowName}:${Date.now()}`;
    let obj = {
      label: name,
      isSelected: false,
    };
    if (e.key === "Enter") {
      paths[name] = [];
      this.setState({
        paths,
        sub_path: [...sub_path, obj],
        subFlowName: "",
      });
    }
  };

  updateNode = (parentNode, selections, action) => {
    let { pathName, index, who } = parentNode.data?.exts;
    let { isDefaultNode } = parentNode.data;
    let { paths, flowData } = this.state;

    if (!parentNode.data.exts.checkpoints) {
      switch (who) {
        case "action":
          if (isDefaultNode) {
            !paths[pathName].some((obj) => obj.who === "action")
              ? paths[pathName].push({ actions: [selections], who: who })
              : (paths[pathName][0]["actions"] = [selections]);
            !paths[pathName].some((obj) => obj.who === "bot") &&
              paths[pathName].push({ actions: [], who: "bot" });
          }
          paths[pathName][index - 1] &&
            (paths[pathName][index - 1]["actions"] = [selections]);
          break;
        case "bot":
          if (isDefaultNode) {
            !paths[pathName].some((obj) => obj.who === "action") &&
              paths[pathName].push({
                actions: ["initial_action"],
                who: "action",
              });
            !paths[pathName].some((obj) => obj.who === "bot")
              ? paths[pathName].push({ actions: [selections], who: who })
              : (paths[pathName][1]["actions"] = [selections]);
          }
          paths[pathName][index - 1] &&
            (paths[pathName][index - 1]["actions"] = [selections]);
          break;
        case "tta":
          paths[pathName][index - 1]["agent_numbers"] = [selections];
          break;
        case "user":
          typeof paths[pathName][index - 1]["intent"] === "string" &&
            (paths[pathName][index - 1]["intent"] = [
              paths[pathName][index - 1]["intent"],
            ]);
          if (parentNode.data.exts.intentID) {
            paths[pathName][index - 1]["intent"][
              parentNode.data.exts.intentID
            ] = selections;
          } else {
            paths[pathName][index - 1]["intent"][0] = selections;
          }
          break;
        case "form":
          paths[pathName][index - 1]["form"] = selections;
          break;
        case "slot":
          paths[pathName][index - 1]["slots"] = selections;
          break;
        case "dtmf-input":
          paths[pathName][index - 1]["digits"] = selections;
          break;
        case "event":
          if (["tta", "eoc", "restart"].includes(selections)) {
            ["restart", "eoc"].includes(selections)
              ? (paths[pathName][index - 1] = {
                  who: "event",
                  type: selections,
                })
              : (paths[pathName][index - 1] = {
                  who: "event",
                  type: selections,
                  live_desk: "",
                  agent_numbers: [""],
                });
          } else {
            if (Object.keys(selections)[0] === "agent_numbers") {
              paths[pathName][index - 1].agent_numbers[0] =
                selections.agent_numbers;
            } else {
              paths[pathName][index - 1].live_desk = selections.live_desk;
            }
          }
          break;
        case "flow":
          if (action === "delete") {
            paths[pathName][index - 1]["flow"].splice(selections, 1);
          } else {
            !paths[pathName][index - 1]["flow"].includes(selections) &&
              paths[pathName][index - 1]["flow"].push(selections);
          }
        case "eoc":
        case "restart":
        default:
          break;
      }
    } else {
      let checkpointNodeEdge = flowData.find(
        (ele) => ele.target === parentNode.id
      );
      let checkpointNode = flowData.find(
        (ele) => ele.id === checkpointNodeEdge.source
      );
      paths[checkpointNode.data.exts.pathName][
        checkpointNode.data.exts.index
      ].checkpoints[parentNode.data.exts.pathName][
        parentNode.data.exts.checkpointID
      ] =
        who === "dtmf-input"
          ? { "dtmf-value": selections }
          : who === "slot"
          ? { slots: selections }
          : { intent: selections };
      flowData.forEach((exe) => {
        if (exe.id === parentNode.id) {
          exe.data.exts.selections = selections;
        }
      });
    }

    // if (
    //   pathName === "main_path" &&
    //   paths[pathName].length > 1 &&
    //   paths[pathName][1].who === "action"
    // ) {
    //   let y = paths[pathName].splice(1, 1);
    //   paths[pathName].splice(0, 0, y[0]);
    // }

    if (!["eoc", "restart"].includes(who)) {
      flowData.forEach((ele) => {
        if (ele.id === parentNode.id) {
          ele.data.exts.selections = selections;

          if (who === "event") {
            if (["tta", "eoc", "restart"].includes(selections)) {
              ele.data.exts.type = selections;
              ele.data.exts.selections = selections;
              if (selections === "tta") {
                ele.data.exts.live_desk = "";
                ele.data.exts.agent_numbers = [];
              } else {
                delete ele.data.exts.live_desk;
                delete ele.data.exts.agent_numbers;
              }
            } else {
              ele.data.exts.selections = "tta";
              if (Object.keys(selections)[0] === "agent_numbers") {
                ele.data.exts.agent_numbers[0] = selections.agent_numbers;
              } else {
                ele.data.exts.live_desk = selections.live_desk;
              }
            }
          }
        }
      });
      this.setState({ flowData, paths });
    }
  };

  onDragUpdatePosition = (node) => {
    let { paths, flowData } = this.state;
    let x = flowData.find((ele) => ele.id === node.id);
    x.position = node.position;

    this.setState({ flowData }, () => {
      this.updatePosition();
      this.savePath(paths);
    });
  };

  updatePosition = () => {
    let { paths, flowData } = this.state;

    Object.keys(paths).forEach((pathName) => {
      paths[pathName].forEach((ele, index) => {
        let type = ["actions", "intent", "form", "slot", "type", "digits"];
        const isIncludes = !["decision", "dtmf-decision"].includes(ele.who);
        if (isIncludes) {
          const whichType = Object.keys(ele).filter((e) => type.includes(e));
          let node = this.findNode(
            pathName,
            ele.who,
            ele[whichType[0]],
            whichType[0],
            index
          );
          node?.position && (ele["position"] = node.position);
        } else if (ele.who === "decision") {
          Object.keys(ele.checkpoints).forEach((checkpoint) => {
            if (ele.checkpoints[checkpoint].length > 1) {
              ele.checkpoints[checkpoint].forEach((obj, i) => {
                let checkPointNode = this.findCheckpointNode(
                  checkpoint,
                  obj.intent,
                  ele.checkpoints,
                  ele.who,
                  true,
                  i
                );
                checkPointNode?.position &&
                  (ele.checkpoints[checkpoint][i]["position"] =
                    checkPointNode.position);
              });
            } else {
              let intent =
                ele.checkpoints[checkpoint].length &&
                ele.checkpoints[checkpoint][0]["intent"];
              let checkPointNode = this.findCheckpointNode(
                checkpoint,
                intent,
                ele.checkpoints,
                ele.who
              );
              checkPointNode?.position &&
                (ele.checkpoints[checkpoint][0]["position"] =
                  checkPointNode.position);
            }
          });
        } else if (ele.who === "dtmf-decision") {
          delete ele.position;
          Object.keys(ele.checkpoints).forEach((checkpoint) => {
            let dtmfValue = ele.checkpoints[checkpoint][0]["dtmf-value"];
            let checkPointNode = this.findCheckpointNode(
              checkpoint,
              dtmfValue,
              ele.checkpoints
            );
            checkPointNode?.position &&
              (ele.checkpoints[checkpoint][0]["position"] =
                checkPointNode.position);
          });
        }
      });
    });
    this.setState({ paths });
  };

  findNode = (pathName, who, selections, type, index) => {
    const { flowData } = this.state;
    let node = flowData.find(
      (node) =>
        node.data?.exts?.pathName === pathName &&
        node.data?.exts?.who === who &&
        node.data?.exts[type] === selections &&
        node.data?.exts.index === index + 1
    );
    return node;
  };

  findCheckpointNode = (
    pathName,
    intent,
    checkpoints,
    who,
    orIntent,
    index
  ) => {
    const { flowData } = this.state;
    if (who === "decision") {
      if (orIntent) {
        let checkPointOrIntent = flowData.find(
          (node) =>
            node.data?.exts?.selections === intent &&
            node.data?.exts?.pathName === pathName &&
            node.data?.exts?.checkpointID === index
        );
        return checkPointOrIntent;
      } else {
        let checkPointNode = flowData.find(
          (node) =>
            node.data?.exts?.pathName === pathName &&
            node.data?.exts?.checkpoints === checkpoints &&
            node.data?.exts?.selections === intent
        );
        return checkPointNode;
      }
    } else {
      let checkPointNode = flowData.find(
        (node) =>
          node.data?.exts?.pathName === pathName &&
          node.data?.exts?.checkpoints === checkpoints &&
          node.data?.exts?.dtmfValue === intent
      );
      return checkPointNode;
    }
  };

  addAnotherIntent = (parentNode) => {
    let { paths, flowData } = this.state;

    let { index, pathName } = parentNode.data.exts;

    let checkpointNodeEdge = flowData.find(
      (ele) => ele.target === parentNode.id
    );

    let checkpointNode = flowData.find(
      (ele) => ele.id === checkpointNodeEdge.source
    );

    if (index === 0) {
      paths[checkpointNode.data.exts.pathName][
        checkpointNode.data.exts.index
      ].checkpoints[parentNode.data.exts.pathName].push({ intent: "" });
    } else {
      if (typeof paths[pathName][index - 1].intent === "string") {
        paths[pathName][index - 1].intent = [
          paths[pathName][index - 1].intent,
          "",
        ];
        // paths[pathName][index - 1].intent.push("");
      } else {
        typeof paths[pathName][index - 1].intent === "object" &&
          paths[pathName][index - 1].intent.push("");
      }
    }

    this.state.pathName
      ? (flowData = getFlowData(paths, this.state.pathName, true))
      : (flowData = getFlowData(paths, "main_path"));
    flowData = getLayoutedElements(flowData, "TB", true);
    this.setState({ flowData, paths }, () => this.savePath(paths));
  };

  deleteNode = (node) => {
    let { pathName, index, who, checkpointID } = node.data.exts;
    let { paths, flowData } = this.state;

    if (index === 0) {
      let edge = flowData.find((data) => data.target === node.id);
      let source = flowData.find((data) => data.id === edge.source);
      if (who !== "user") {
        this.deletePaths(paths, pathName);

        delete paths[source.data.exts.pathName][source.data.exts.index]
          .checkpoints[pathName];
        let checkpointsLast =
          paths[source.data.exts.pathName][source.data.exts.index]
            .checkpoints || [];
        if (who === "dtmf-input" && Object.keys(checkpointsLast).length === 1) {
          let jointNodes = new Object(paths[Object.keys(checkpointsLast)[0]]);
          // console.log(jointNodes, "jointNodes")

          paths[source.data.exts.pathName][source.data.exts.index] = {
            who: "dtmf-input",
            digits: 1,
            timeout: 5,
          };
          paths[source.data.exts.pathName] =
            paths[source.data.exts.pathName].concat(jointNodes);
          // console.log(paths[source.data.exts.pathName], "paths")
          delete paths[Object.keys(checkpointsLast)[0]];
        }
      } else {
        let checkPointOrIntent =
          paths[source.data.exts.pathName][source.data.exts.index].checkpoints[
            pathName
          ];
        if (checkPointOrIntent.length > 1) {
          checkPointOrIntent.splice(checkpointID, 1);
        } else {
          this.deletePaths(paths, pathName);

          delete paths[source.data.exts.pathName][source.data.exts.index]
            .checkpoints[pathName];
        }
      }
    } else {
      if (who === "user") {
        let orIntents = paths[pathName][index - 1].intent;
        if (orIntents.length > 1) {
          node.data.exts.intentID
            ? paths[pathName][index - 1].intent.splice(
                node.data.exts.intentID,
                1
              )
            : paths[pathName][index - 1].intent.splice(0, 1);
        } else {
          let deletedNodes = paths[pathName].splice(index - 1);
          let checkpoints = deletedNodes.find(
            (node) => node.who === "decision"
          );
          let dtmfCheckpoints = deletedNodes.find(
            (node) => node.who === "dtmf-decision"
          );
          if (checkpoints) {
            Object.keys(checkpoints.checkpoints).forEach((pName) => {
              this.deletePaths(paths, pName);
            });
          }
          if (dtmfCheckpoints) {
            Object.keys(dtmfCheckpoints.checkpoints).forEach((pName) => {
              this.deletePaths(paths, pName);
            });
          }
        }
      } else {
        let deletedNodes = paths[pathName].splice(index - 1);
        let checkpoints = deletedNodes.find((node) => node.who === "decision");
        let dtmfCheckpoints = deletedNodes.find(
          (node) => node.who === "dtmf-decision"
        );
        if (checkpoints) {
          Object.keys(checkpoints.checkpoints).forEach((pName) => {
            this.deletePaths(paths, pName);
          });
        }
        if (dtmfCheckpoints) {
          Object.keys(dtmfCheckpoints.checkpoints).forEach((pName) => {
            this.deletePaths(paths, pName);
          });
        }
      }
    }

    this.state.pathName
      ? (flowData = getFlowData(paths, this.state.pathName, true))
      : (flowData = getFlowData(paths, "main_path"));
    flowData = getLayoutedElements(flowData);
    this.setState({ paths, flowData });
  };

  deletePaths(paths, name, index) {
    if (index || index === 0) {
      // let dpath = paths[name];
      // let dpathChckpoint =
      // dpath[index]
    } else {
      let dpath = paths[name].find((path) => path.who === "decision");
      let dtmfDpath = paths[name].find((path) => path.who === "dtmf-decision");
      let slotDpath = paths[name].find((path) => path.who === "slot-decision");
      delete paths[name];
      if (dpath || dtmfDpath || slotDpath) {
        if (dpath) {
          Object.keys(dpath.checkpoints).forEach((point) => {
            this.deletePaths(paths, point);
          });
        }
        if (dtmfDpath) {
          Object.keys(dtmfDpath.checkpoints).forEach((point) => {
            this.deletePaths(paths, point);
          });
        }
        if (slotDpath) {
          Object.keys(slotDpath.checkpoints).forEach((point) => {
            this.deletePaths(paths, point);
          });
        }
      } else {
        return paths;
      }
    }
  }
}
