import React from "react";
import ReactDOM from "react-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import Notifications from "./components/notifications/notifications";
import "./assets/scss/botFrameWork.scss";
import BotLayout from "./layouts/bot/bot";
import AdminLayout from "./layouts/admin/admin";
import AuthLayout from "./layouts/auth/auth";
import SuperAdminLayout from "./layouts/super_admin/super_admin_layout";
import ForgotPassword from "./pages/forgotPassword/forgotPassword";
import ContactUs from "./pages/contactUs/contactUs";
import { NotificationContextWrapper } from "./contexts/notficationContext";
import { BotProvider } from "./contexts/BotContext";
import { UserContextWrapper } from "./contexts/userContext";

ReactDOM.render(
  <React.StrictMode>
    <NotificationContextWrapper>
      <UserContextWrapper>
        <BotProvider>
          <BrowserRouter>
            <Notifications />
            <Switch>
              <Route
                path="/admin"
                render={(props) => <AdminLayout {...props} />}
              />
              <Route path="/bot" render={(props) => <BotLayout {...props} />} />
              <Route
                path="/auth/login"
                render={(props) => <AuthLayout {...props} />}
              />
              <Route
                path="/forgot_password"
                render={(props) => <ForgotPassword {...props} />}
              />
              <Route
                path="/contact_us"
                render={(props) => <ContactUs {...props} />}
              />
              <Route
                path="/super_admin"
                render={(props) => <SuperAdminLayout {...props} />}
              />
              <Redirect from="/" to="/auth/login" />
            </Switch>
          </BrowserRouter>
        </BotProvider>
      </UserContextWrapper>
    </NotificationContextWrapper>
  </React.StrictMode>,
  document.getElementById("root")
);
