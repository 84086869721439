import React from "react";
import ReactFlow, {
  ReactFlowProvider,
  MiniMap,
  Controls,
} from "react-flow-renderer";
// import ReactFlow, { ReactFlowProvider, MiniMap } from 'react-flow-renderer/nocss';

// you need these styles for React Flow to work properly
import "react-flow-renderer/dist/style.css";

// additionally you can load the default theme
import "react-flow-renderer/dist/theme-default.css";

import {
  BotNode,
  UserNode,
  DecisionNode,
  RestartNode,
  EOCNode,
  TTANode,
  DTMFNode,
  SlotNode,
  FormNode,
  StartNode,
  ActionNode,
  EventNode,
  LinkNode,
} from "./Nodes";

const onLoad = (reactFlowInstance, getZoom, elements) => {
  // let x = getZoom();
  // let {x, y} = reactFlowInstance.toObject().elements[0].position;
  // console.log(x, y);
  // reactFlowInstance.fitView({padding: 2});
  // reactFlowInstance.zoomOut();
  // reactFlowInstance.zoomOut();
  // reactFlowInstance.zoomOut();
  // reactFlowInstance.zoomTo(.5);
  // reactFlowInstance.zoomTo(10);
  // setTimeout(() => {x = reactFlowInstance.toObject().elements[0].position.x;
  // y = reactFlowInstance.toObject().elements[0].position.y;
  // console.log(x, y);}, 1000)
  // x = 180; y = 10;
  // 450 >> 250
  // 900 >> 0
  // 0.000805 >> 450
  // 300 >> 180
  elements.length < 10
    ? reactFlowInstance.setTransform({ x: 100, y: 20, zoom: 0.6 })
    : reactFlowInstance.fitView();
};

const nodeType = {
  Sa: StartNode,
  B: BotNode,
  U: UserNode,
  D: DecisionNode,
  R: RestartNode,
  E: EOCNode,
  T: TTANode,
  DT: DTMFNode,
  S: SlotNode,
  F: FormNode,
  A: ActionNode,
  EV: EventNode,
  "DT-I": DTMFNode,
  L: LinkNode,
};

const NodeTree = (props) => {
  const { elements, getZoom, onDragUpdatePosition, setZoom, disableDrag } =
    props;

  return (
    elements &&
    elements.length > 0 && (
      <ReactFlowProvider>
        <ReactFlow
          defaultZoom={0.5}
          minZoom={0.25}
          elements={elements}
          onLoad={(e) => onLoad(e, getZoom, elements)}
          snapToGrid={true}
          snapGrid={[15, 15]}
          nodeTypes={nodeType}
          onNodeDragStop={(e, n) => {
            disableDrag ? console.log("disable") : onDragUpdatePosition(n);
          }}
          // onMoveEnd={(e) => setZoom(e.zoom)}
        >
          {/* <MiniMap
            nodeStrokeColor={(n) => {
              if (n.style?.background) return n.style.background;
              if (n.type === "B") return "#0041d0";
              if (n.type === "u") return "#ff0072";
              if (n.type === "Sa") return "#1a192b";

              return "#eee";
            }}
            nodeColor={(n) => {
              if (n.style?.background) return n.style.background;

              return "#fff";
            }}
            nodeBorderRadius={2}
            maskColor="#ccc"
            className="rounded-3"
          /> */}
          <Controls />
        </ReactFlow>
      </ReactFlowProvider>
    )
  );
};

export default NodeTree;
