import React, { Component, useContext } from "react";
import BotLayoutContent from "../../components/botLayout/botLayoutContent.component";
import BotLayoutLeftPanel from "../../components/botLayout/botLayoutLeftPanel.component";
import BotLayoutRightPanel from "../../components/botLayout/botLayoutRightPanel.component";
import getIcon from "../../services/icon.service";
import Input from "../../components/fields/input/input.component";
import DropDown from "../../components/fields/dropDown/dropDown.component";
import Buttons from "../../components/fields/button/button.component";
import entityService from "../../services/api.services/entityApi.service";
import "./entity.scss";
import BulkImport from "../../components/bulkImport.component";
import { NotificationContext } from "../../contexts/notficationContext";
import { BotContext } from "../../contexts/BotContext";
import { UserContext } from "../../contexts/userContext";

import DeleteConfirmation from "../../components/deleteConfirmation.component";

class Entity extends Component {
  static contextType = BotContext;
  constructor(props) {
    super(props);
    this.state = {
      addNewEntity: "",
      addNewEntityValue: "",
      addNewEntitySynonym: "",
      isDeleteSynonym: "",
      selectedEntity: "",
      isSynonym: "",
      isInput: false,
      delSyno: [],
      entitOldName: "",
      entityNewName: "",
      oldValue: "",
      modal: false,
      listId: "",
      entityList: [],
      bulkImportModal: false,
      validationStatus: "validationPending",
    };
    this.listIdRef = React.createRef();
  }

  // setNavBarButtons() {
  //   this.context.setNavBarButtons(
  //     <div className="row">
  //       <div className="col-auto">
  //         <BulkImportComp
  //           onPageRefresh={() => {
  //             this.onPageRefresh();
  //           }}
  //         />
  //       </div>
  //     </div>
  //   );
  // }

  bulkImportComp = () => {
    return (
      <BulkImport
        isFile
        onlyIcon={true}
        isEntity={true}
        isContentSwitch={true}
        open={this.state.bulkImportModal}
        importData={this.state.importData}
        sampleFileName="Entity-Sample.json"
        fileName={this.state.importFileName}
        intentSentences={this.state.intentSentences}
        validationStatus={this.state.validationStatus}
        importFile="Select the file to import Entity"
        description={{ title: "Import Entity JSON or CSV file" }}
        sampleUrl="/sample_bulk_import_entities_sample_json_file"
        confirmSuccess={(e, update_config_data) => {
          let formData = new FormData();
          formData.append("file", e.target.files[0]);
          entityService
            .importEntites(e.target.files[0], update_config_data)
            .then((response) => {
              if (response.status === "success") {
                if (update_config_data) {
                  this.onPageRefresh();
                  this.props.setNotifications({
                    color: "success",
                    message: response.message
                      ? response.message
                      : "Entity imported successfully",
                  });
                } else {
                  this.setState({
                    importData: response,
                    validationStatus: "importStage",
                  });
                }
              } else {
                this.props.setNotifications({
                  color: "danger",
                  message: response.message
                    ? response.message
                    : "Something went wrong",
                });
              }
            });
        }}
        validateImport={(e) => {
          let formData = new FormData();
          formData.append("file", e.target.files[0]);
          entityService
            .validateImport(e.target.files[0])
            .then((response) =>
              response.status === "success"
                ? this.setState({
                    importData: response,
                    importFileName: e.target.files[0].name,
                    validationStatus: "validationComplete",
                  })
                : this.props.setNotifications({
                    color: "danger",
                    message: response.message,
                  })
            )
            .catch((e) =>
              this.props.setNotifications({
                color: "danger",
                message: e.message,
              })
            );
        }}
        resetState={() =>
          this.setState({
            importData: null,
            importFileName: "",
            validationStatus: "validationPending",
          })
        }
      />
    );
  };

  onClickImport = () => {
    this.setState({ bulkImportModal: true });
    setTimeout(() => {
      this.setState({ bulkImportModal: false });
    }, 500);
  };

  componentWillUnmount() {
    this.context.setNavBarButtons("");
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  componentDidMount() {
    this.getBotEntity();
    this.getSystemEntities();
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  handleOutsideClick = ({ target }) => {
    if (this.listIdRef.current && !this.listIdRef.current.contains(target)) {
      this.setState({ listId: "" });
    }
  };

  getBotEntity = async () => {
    try {
      const r = await entityService.getBotEntity();

      if (r.status === "success") {
        const entityList = r.data.map((d) => ({ label: d, isSelected: false }));

        this.setState({
          entityList,
          trainedEntityList: r.trained,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  getSystemEntities() {
    entityService
      .getSystemEntities()
      .then((res) => this.setState({ systemEntities: res.data }))
      .catch((err) => console.log("Failed: ", err));
  }

  getEntitySentences(intent) {
    entityService
      .getEntityValues(intent)
      .then((res) => {
        if (res.status === "success") {
          this.setState({
            entityDetails: res.data,
            trainedEntityDetails: res.trained,
          });
        }
      })
      .catch((error) => {
        console.log("Failed: ", error);
      });
  }

  handleToggleCheck = (item) => {
    let { systemEntities } = this.state;

    systemEntities[item] = !systemEntities[item];
    this.setState({ systemEntities }, () =>
      this.toggle("_updateToggle", { data: systemEntities })
    );
  };

  handleOnActionItemClick = (menu, i, b, selectedEntity) => {
    switch (menu.label) {
      case "Add Synonyms":
        this.setState({ isSynonym: i, isInput: true });
        break;
      case "Delete Synonyms":
        this.setState({ isDeleteSynonym: i, isInput: false });
        break;
      case "Delete Phrase":
        this.setState({ modal: true });
        setTimeout(() => {
          this.setState({ modal: false });
        }, 100);
        break;
      default:
        break;
    }
  };

  getDeleteModal = (action, i) => {
    let { entityDetails, entityList, selectedEntity } = this.state;

    return (
      <DeleteConfirmation
        open={this.state.modal}
        delete={() => {
          if (action === "phrase") {
            entityDetails?.values?.splice(i, 1);
            this.setState({ entityDetails }, () =>
              this.toggle("_update", selectedEntity, entityDetails)
            );
          } else if (action === "entity") {
            entityList.filter((entiy) => entiy !== selectedEntity);
            this.setState(
              {
                entityList,
                isSelected: false,
                selectedEntity: "",
              },
              () => {
                this.toggle("_delete", selectedEntity);
              }
            );
          }
        }}
      />
    );
  };

  toggle(action, index, entityDetails) {
    if (action === "_delete") {
      entityService
        .deleteBotEntity({ entity: index })
        .then((res) => {
          if (
            res.status === "success" &&
            Object.keys(res.failed || {}).length === 0
          ) {
            this.props.setNotifications({
              color: "success",
              message: res.message,
            });
          } else {
            this.props.setNotifications({
              color: "danger",
              message:
                Object.keys(res.failed || {}).length === 0
                  ? res.message
                  : res.failed[Object.keys(res.failed)[0]],
            });
          }
        })
        .catch((err) =>
          this.props.setNotifications({ color: "danger", message: err.message })
        );
      this.onPageRefresh();
    } else if (action === "_addEntity") {
      entityService
        .addBotEntity({
          entity: index,
          synonyms: {},
          values: [],
        })
        .then((res) => {
          if (res.status === "success") {
            this.props.setNotifications({
              color: "success",
              message: res.message,
            });
          } else {
            this.props.setNotifications({
              color: "danger",
              message: res.message,
            });
          }
        })
        .catch((err) =>
          this.props.setNotifications({ color: "danger", message: err.message })
        );
      this.onPageRefresh();
    } else if (action === "_update") {
      let payload = {
        entity: index,
        synonyms: entityDetails.synonyms,
        values: entityDetails.values,
      };
      entityService
        .updateBotEntity(payload)
        .then((res) => {
          if (res.status === "failure")
            this.props.setNotifications({
              color: "danger",
              message: res.message,
            });
        })
        .catch((err) =>
          this.props.setNotifications({ color: "danger", message: err.message })
        );
    } else if (action === "_rename") {
      entityService
        .renameBotEntity({
          old_name: index,
          new_name: entityDetails,
        })
        .then((res) => {
          if (res.status === "success") {
            this.setState({ selectedEntity: entityDetails });
            this.props.setNotifications({
              color: "success",
              message: res.message,
            });
            this.getBotEntity();
          } else {
            this.props.setNotifications({
              color: "danger",
              message: res.message,
            });
          }
        })
        .catch((err) =>
          this.props.setNotifications({ color: "danger", message: err.message })
        );
      // this.getBotEntity();
    } else if (action === "_updateToggle") {
      entityService
        .updateSystemEntities(index)
        .then((res) => console.log(res))
        .catch((err) =>
          this.props.setNotifications({ color: "danger", message: err.message })
        );
    } else {
      return;
    }
  }

  renderEntities = () => {
    let {
      entityDetails,
      trainedEntityDetails,
      isDeleteSynonym,
      selectedEntity,
      isInput,
      isSynonym,
      addNewEntitySynonym,
      delSyno,
      oldValue,
      onPhraseHover,
      listId,
    } = this.state;

    return (
      entityDetails &&
      entityDetails?.values?.map((b, i) => (
        <div
          className={`col-12 greeting-msg-container rounded${
            onPhraseHover === i ? " shadow-sm" : ""
          }`}
          key={i}
          id={i}
          onMouseEnter={() => this.setState({ onPhraseHover: i })}
          onMouseLeave={() =>
            this.setState({
              onPhraseHover: false,
              // isDeleteSynonym: "",
              // delSyno: [],
            })
          }
        >
          <div className="row align-items-center m-0 p-0">
            <div className="col d-flex align-items-center py-2">
              <div className="img-fluid me-4 align-items-center d-flex">
                {trainedEntityDetails && trainedEntityDetails.includes(b)
                  ? getIcon("activeBot", "#25293D")
                  : getIcon("activeBot", "#e2e2e2")}
              </div>
              <Input
                isLiteration
                value={b}
                className="bot-layout-greetings-inp pt-1"
                readOnly={this.props.permissions.intents.modify !== "enabled"}
                onChange={(e) => {
                  if (!oldValue) {
                    oldValue = entityDetails.values[i];
                  }
                  if (typeof e === "string") {
                    entityDetails.values[i] = e;
                  } else {
                    entityDetails.values[i] = e.target.value;
                  }
                  this.setState({ entityDetails, oldValue });
                }}
                onKeyPress={(e) => {
                  if (
                    e.key === "Enter" &&
                    /^(?!\s*$).+/.test(entityDetails.values[i])
                  ) {
                    entityDetails.synonyms[entityDetails.values[i]] =
                      entityDetails.synonyms[oldValue];
                    delete entityDetails.synonyms[oldValue];
                    this.setState({ entityDetails, oldValue: null }, () =>
                      this.toggle("_update", selectedEntity, entityDetails)
                    );
                    let div = document.getElementById(i);
                    div.querySelector(".bot-layout-greetings-inp").blur();
                  }
                }}
              />
            </div>
            <div className={`col-auto pe-0 justify-content-end`}>
              {isDeleteSynonym === i ? (
                <div className="del-container">
                  <div>
                    <Buttons
                      className="delete-synonym-btn text-white me-2"
                      onClick={() => {
                        let temp = entityDetails?.synonyms[b];
                        temp = temp.filter((item) => !delSyno.includes(item));
                        entityDetails.synonyms[b] = temp;
                        this.setState({
                          entityDetails,
                          delSyno: [],
                          isDeleteSynonym: "",
                        });
                        this.toggle("_update", selectedEntity, entityDetails);
                      }}
                    >
                      {getIcon("delete", "white", "me-2", 17)} Delete Synonyms
                    </Buttons>
                    <small
                      className="cursor-pointer btn px-2 cancel-btns"
                      onClick={() =>
                        this.setState({ isDeleteSynonym: "", delSyno: [] })
                      }
                    >
                      Cancel
                    </small>
                  </div>
                  <p>* You can select multiple synonyms</p>
                </div>
              ) : (
                <div
                  className={`${
                    onPhraseHover === i || listId === i ? " d-flex" : " d-none"
                  }`}
                  ref={this.listIdRef}
                  onClick={() => this.setState({ listId: i })}
                >
                  <DropDown
                    dropdownList={[
                      { label: "Disable", id: i, icon: getIcon("disable") },
                      {
                        label: "Add Synonyms",
                        id: i,
                        icon: getIcon("repeat"),
                        isUsed:
                          this.props.permissions.entity.modify !== "enabled",
                      },
                      {
                        label: "Delete Synonyms",
                        id: i,
                        icon: getIcon("delete"),
                        isUsed:
                          this.props.permissions.entity.modify !== "enabled",
                      },
                      {
                        label: "Delete Phrase",
                        id: i,
                        icon: this.getDeleteModal("phrase", i),
                        isUsed:
                          this.props.permissions.entity.modify !== "enabled",
                      },
                    ]}
                    dropDownMenuMinWidth="13rem"
                    btnClassName="px-1"
                    actionButton={() => getIcon("verticalOptions")}
                    onActionItemClick={(menu) =>
                      this.handleOnActionItemClick(
                        menu,
                        i,
                        entityDetails,
                        selectedEntity
                      )
                    }
                    isContent={true}
                  />
                </div>
              )}
            </div>
            <div className="p-0 px-5 synonym-font">
              {isSynonym === i && isInput ? (
                <Input
                  isLiteration
                  autoFocus={true}
                  placeholder="Enter synonyms..."
                  value={addNewEntitySynonym}
                  className="synonym d-block mb-2 px-2"
                  style={{ color: "#6F6F6FBE" }}
                  onChange={(e) => {
                    if (typeof e === "string") {
                      this.setState({ addNewEntitySynonym: e });
                    } else {
                      this.setState({ addNewEntitySynonym: e.target.value });
                    }
                  }}
                  onBlur={() => this.setState({ isInput: false })}
                  onKeyPress={(e) => {
                    if (
                      e.key === "Enter" &&
                      /^(?!\s*$).+/.test(addNewEntitySynonym)
                    ) {
                      if (
                        addNewEntitySynonym &&
                        entityDetails &&
                        entityDetails.synonyms &&
                        !entityDetails.synonyms[b].includes(addNewEntitySynonym)
                      ) {
                        entityDetails?.synonyms[b].push(addNewEntitySynonym);
                        this.setState(
                          { entityDetails, addNewEntitySynonym: "" },
                          () => {
                            this.toggle(
                              "_update",
                              selectedEntity,
                              entityDetails
                            );
                          }
                        );
                      } else {
                        this.props.setNotifications({
                          color: "black",
                          message: "Synonym already exists",
                        });
                      }
                    } else {
                      this.props.setNotifications({
                        color: "black",
                        message: "Invalid Synonym",
                      });
                    }
                  }}
                />
              ) : entityDetails?.synonyms[b] &&
                entityDetails?.synonyms[b].length > 0 ? (
                <p>Synonyms</p>
              ) : (
                ""
              )}
              {entityDetails?.synonyms[b] &&
                entityDetails?.synonyms[b].map((item, ix) => {
                  return (
                    <Buttons
                      key={ix}
                      className={`mb-2 btn me-2 ${
                        delSyno.includes(item)
                          ? "synonymSelected"
                          : "synonymUnSelected"
                      }`}
                      onClick={(e) => {
                        let temp = e.detail;
                        if (isDeleteSynonym === i) {
                          !delSyno.includes(item) &&
                            temp === 1 &&
                            delSyno.push(item);
                          if (delSyno.includes(item) && temp === 2) {
                            let x = delSyno.indexOf(item);
                            delSyno.splice(x, 1);
                          }

                          this.setState({ delSyno });
                        }
                      }}
                    >
                      {item}
                    </Buttons>
                  );
                })}
            </div>
          </div>
        </div>
      ))
    );
  };

  onPageRefresh = () => {
    this.setState(
      {
        entityList: [],
        trainedEntityList: [],
        entityDetails: undefined,
        addNewEntity: "",
        addNewEntityValue: "",
        selectedEntity: undefined,
        isSelected: false,
        entityOldName: "",
        entityNewName: "",
        modal: false,
      },
      () => {
        setTimeout(() => {
          this.getBotEntity();
        }, 100);
      }
    );
  };

  render() {
    let {
      entityList,
      trainedEntityList,
      entityDetails,
      addNewEntity,
      addNewEntityValue,
      selectedEntity,
      isSelected,
      entityOldName,
      entityNewName,
      systemEntities,
    } = this.state;

    return (
      <div className="container-fluid h-100">
        <div className="row h-100">
          <BotLayoutLeftPanel
            handleToggleCheck={this.handleToggleCheck}
            BulkImport={this.bulkImportComp}
            onClickImport={this.onClickImport}
            sideBarList={entityList}
            isEntity={true}
            systemEntities={this.state.systemEntities}
            title="Entity"
            trainedIntent={trainedEntityList}
            value={addNewEntity}
            onPageRefresh={this.onPageRefresh}
            addBtnHide={this.props.permissions.entity.add}
            disableMultiDelete={
              this.props.permissions.entity.delete === "disabled"
            }
            onMenuSelect={({ label }) => {
              if (this.props.permissions.entity.list === "enabled") {
                const localEntityList = entityList.map((e) => {
                  e.isSelected = e.label === label;
                  return e;
                });

                this.props.permissions.entity.content === "enabled" &&
                  this.getEntitySentences(label);
                this.setState({
                  entityList: localEntityList,
                  entityNewName: label,
                  isSelected: true,
                  selectedEntity: label,
                });
              }
            }}
            onChange={(e) => this.setState({ addNewEntity: e.target.value })}
            onKeyPress={(e) => {
              if (e.key === "Enter" && addNewEntity) {
                if (
                  !entityList.find((e) => e.label === addNewEntity) &&
                  !Object.keys(systemEntities).includes(addNewEntity)
                ) {
                  if (/^[A-Za-z][A-Za-z0-9_]+$/.test(addNewEntity))
                    this.setState({ entityList, addNewEntity: "" }, () => {
                      this.toggle("_addEntity", addNewEntity);
                    });
                  else {
                    this.setState({ addNewEntity: "" });
                    this.props.setNotifications({
                      color: "black",
                      message:
                        "Entity name should not contain special characters and spaces",
                    });
                  }
                } else {
                  this.setState({ addNewEntity: "" });
                  this.props.setNotifications({
                    color: "black",
                    message: "Entity already exists",
                  });
                }
              }
            }}
          />
          <BotLayoutContent
            titleValue={entityNewName}
            titleReadOnly={this.props.permissions.entity.modify !== "enabled"}
            titleOnChange={(e, type) => {
              if (!entityOldName) {
                entityOldName = selectedEntity;
              }
              this.setState({ entityNewName: e.target.value, entityOldName });
              if (
                (e.key === "Enter" || type) &&
                entityOldName &&
                entityNewName &&
                entityNewName !== entityOldName
              ) {
                if (/^[A-Za-z][A-Za-z0-9_]+$/.test(entityNewName)) {
                  if (
                    !entityList.find((e) => e.label === entityNewName) &&
                    !Object.keys(systemEntities).includes(entityNewName)
                  ) {
                    this.toggle("_rename", entityOldName, entityNewName);
                    this.setState({ entityOldName: null });
                  } else {
                    this.props.setNotifications({
                      color: "black",
                      message: "Entity already exists",
                    });
                  }
                } else
                  this.props.setNotifications({
                    color: "black",
                    message:
                      "Entity name should not contain any special characters or spaces",
                  });
              }
            }}
            isSelected={isSelected}
            addNewValue={addNewEntityValue}
            addNewPlacholder="In other words user may say…"
            actions={[
              {
                label: "Delete",
                icon: this.getDeleteModal("entity"),
                isUsed: this.props.permissions.entity.delete === "disabled",
              },
            ]}
            onActionClick={() => {
              this.setState({ modal: true });
              setTimeout(() => {
                this.setState({ modal: false });
              }, 100);
            }}
            onAddNewChange={(e) => {
              typeof e === "string"
                ? this.setState({ addNewEntityValue: e })
                : this.setState({ addNewEntityValue: e.target.value });
              if (e.key === "Enter" && /^(?!\s*$).+/.test(addNewEntityValue)) {
                let temp = entityDetails;
                temp.synonyms[this.state.addNewEntityValue.trim()] = [];
                temp.values.push(addNewEntityValue.trim());
                this.setState({ entityDetails: temp, addNewEntityValue: "" });
                this.toggle("_update", selectedEntity, entityDetails);
              }
            }}
            isShowContent={this.props.permissions.entity.content === "enabled"}
            disableAddNew={this.props.permissions.entity.modify !== "enabled"}
          >
            <div className="row m-0">
              {this.props.permissions.entity.content === "enabled" &&
                this.renderEntities()}
            </div>
          </BotLayoutContent>
          <BotLayoutRightPanel />
        </div>
      </div>
    );
  }
}

export default function Entitys() {
  const { setNotifications } = useContext(NotificationContext);
  const { getUserDetails } = useContext(UserContext);

  return (
    <Entity
      setNotifications={setNotifications}
      permissions={getUserDetails().feature_permission}
    />
  );
}
