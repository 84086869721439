import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Buttons from "./fields/button/button.component";

const LinkUnlinkModal = (props) => {
  const [isModal, setModal] = useState(props.open);

  const toggle = () => {
    setModal(!isModal);
    props.toggle();
  };

  const submit = () => {
    props.submit();
  };

  return (
    <>
      <Modal
        color="black"
        isOpen={isModal}
        style={{ marginTop: "20vh" }}
        toggle={toggle}
      >
        <ModalHeader className="d-flex justify-content-center">
          {props.heading}
        </ModalHeader>
        <ModalBody className=" bot-del-btn-font  pt-2 px-4 mx-2">
          <p className="text-center">{props.subheading}</p>
          {props.children}
        </ModalBody>
        <ModalFooter className="bg-white border-0 modal-footer d-flex justify-content-center">
          <Buttons
            className="w-auto rounded-1 py-2 modal-btn-cancel"
            onClick={() => {
              toggle();
              props.cancel && props.cancel();
            }}
          >
            Cancel
          </Buttons>
          <Buttons
            className="w-auto rounded-1 py-2 text-white modal-btn-confirm"
            onClick={() => {
              toggle();
              submit();
            }}
          >
            Confirm
          </Buttons>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default LinkUnlinkModal;
