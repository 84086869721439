import React, { Component, createRef, useContext } from "react";
import BotLayoutContent from "../../components/botLayout/botLayoutContent.component";
import BotLayoutLeftPanel from "../../components/botLayout/botLayoutLeftPanel.component";
import BotLayoutRightPanel from "../../components/botLayout/botLayoutRightPanel.component";
import BulkImport from "../../components/bulkImport.component";
import Button from "../../components/fields/button/button.component";
import DropDown from "../../components/fields/dropDown/dropDown.component";
import Input from "../../components/fields/input/input.component";
import Modal from "../../components/modal/modal.component";
import apiService from "../../services/api.services";
import getIcon from "../../services/icon.service";
import DeleteConfirmation from "../../components/deleteConfirmation.component";
import resService from "../../services/api.services/utteranceApi.service";
import { BotContext } from "../../contexts/BotContext";
import { NotificationContext } from "../../contexts/notficationContext";
import { UserContext } from "../../contexts/userContext";
import "./response.scss";
import botService from "../../services/bot.service";
import { getImageUrl } from "../../services/baseURL.service";
import HorizontalScroll from "../../components/horizontalScroll/horizontalScroll";
import svgIcons from "../../services/icon.service";
import vmsg from "vmsg";

const handleConfirmation = async (
  { target },
  onPageRefresh,
  setNotifications
) => {
  const file = target.files;

  if (file.length) {
    const formData = new FormData();

    formData.append("file", file[0]);

    try {
      const r = await resService.importResponses(target.files[0]);

      if (r.status === "success") {
        setNotifications({
          color: "success",
          message: "Successfully imported!",
        });
        onPageRefresh();
      } else {
        setNotifications({ color: "danger", message: "Something went wrong!" });
      }
    } catch (e) {
      console.log(`Failed: ${e}`);
    }
    target.value = null;
  }
};

const recorder = new vmsg.Recorder({
  wasmURL: "https://unpkg.com/vmsg@0.3.0/vmsg.wasm",
});

class Response extends Component {
  static contextType = BotContext;

  constructor(props) {
    super(props);
    this.state = {
      addBtnToNewPhrase: {
        title: "",
        value: "",
      },
      channels: [],
      channelsCopy: [],
      isDelete: {
        isShown: false,
        index: "",
        selectedBtns: [],
      },
      isMsgDelete: {
        isShown: false,
        index: "",
        selectedBtns: [],
      },
      response: {
        isResSelected: false,
        newResName: "",
        nonTrained: [],
        resName: "",
        resNameCopy: "",
        trained: [],
      },
      phrase: {
        newPhraseName: "",
        nonTrained: [],
        trained: [],
      },
      uploadingPhrase: {
        buttons: [],
        image: "",
        audios: [],
      },
      shortMsg: {
        newPhraseName: "",
        nonTrained: [],
        trained: [],
      },
      uploadingShortMsg: {
        buttons: [],
        image: "",
        audios: [],
      },
      modal: false,
      isAddBtnModal: false,
      isAddImgModal: false,
      isEditBtnModal: null,
      isAddPhraseAudio: null,
      delPhraseAudio: false,
      isRecording: false,
      isMicLoading: false,
      isMsgMicLoading: false,
      isMsgRecording: false,
      currentlyPlaying: null,
      isMsgImgModal: false,
      isMsgBtnModal: false,
      isAddMsgAudio: null,
      msgModal: false,
      delMsgImgModal: false,
      fetchedAudio: { url: "", index: "" },
      fetchedMsgAudio: { url: "", index: "" },
      modalInps: {
        title: "",
        value: "",
      },
      listId: "",
      msgId: "",
      selectedChannelPhrase: {
        newPhraseName: "",
        nonTrained: [],
        trained: [],
      },
      selectedChannelMsg: {
        newPhraseName: "",
        nonTrained: [],
      },
      selectedChannel: null,
      navBarMenu: [{ label: "Default", value: "Default" }],
      phrasesToDelete: [],
      msgsToDelete: [],
      phraseAudios: [],
      msgAudios: [],
      bulkImportModal: false,
    };

    this.dropDownBtnNewTitle = this.utilizeFocus();
    this.dropDownBtnNewVal = this.utilizeFocus();
    this.dropDownTitle = this.utilizeFocus();
    this.dropDownValue = this.utilizeFocus();
    this.phraseIndex = "";
    this.msgIndex = "";
    this.listIdRef = React.createRef();
    this.msgIdRef = React.createRef();
    this.sharePhraseRef = React.createRef();
    this.shareMsgRef = React.createRef();
  }

  bulkImportComp = () => {
    return (
      <BulkImport
        description={{ title: "Import Response JSON or CSV file " }}
        importFile="Select the file to import Response"
        isFile
        open={this.state.bulkImportModal}
        onlyIcon={true}
        sampleFileName="Response-Sample.json"
        sampleUrl="/sample_bulk_import_responses_sample_json_file"
        confirmSuccess={(e) =>
          handleConfirmation(e, this.getResponses, this.props.setNotifications)
        }
      />
    );
  };

  onClickImport = () => {
    this.setState({ bulkImportModal: true });
    setTimeout(() => {
      this.setState({ bulkImportModal: false });
    }, 500);
  };

  componentDidMount() {
    this.getChannels();
    this.getResponses();
    this.setState({ lang: botService.getSelectedBotLang() });
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  componentWillUnmount() {
    this.context.setNavBarButtons("");
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  handleOutsideClick = ({ target }) => {
    if (this.listIdRef.current && !this.listIdRef.current.contains(target)) {
      this.setState({ listId: "" });
    }
  };

  addNewRes = async (res) => {
    try {
      const body = {
        data: [],
        utterance: res,
      };
      const r = await resService.addBotUtterance(body);

      if (r.status === "success") {
        this.setState(
          {
            response: { ...this.state.response, newResName: "" },
          },
          this.getResponses
        );
        this.props.setNotifications({ color: "success", message: r.message });
      } else {
        this.props.setNotifications({ color: "danger", message: r.message });
      }
    } catch (e) {
      this.props.setNotifications({ color: "danger", message: e.message });
    }
  };

  editResName = async () => {
    const { response } = this.state;
    const { resName, resNameCopy } = response;
    const body = {
      new_name: resName,
      old_name: resNameCopy,
    };

    try {
      const r = await resService.renameBotUtterance(body);

      if (r.status === "success") {
        this.setState(
          { response: { ...response, resNameCopy: resName } },
          this.getResponses
        );
        this.props.setNotifications({ color: "success", message: r.message });
      } else {
        this.props.setNotifications({ color: "danger", message: r.message });
      }
    } catch (e) {
      this.props.setNotifications({ color: "danger", message: e.message });
    }
  };

  getChannels = async () => {
    try {
      const r = await apiService.getChannels();

      if (r.status === "success") {
        let channels = r.data
          .filter((c) => c.category === "chat" || c.category === "voice")
          .map((c, i) => ({
            label: c.name,
            isSelected: i === 0,
            isUsed: false,
            value: c.key,
          }));
        let shareList = channels.map((ele) => {
          return {
            label: ele.label,
            icon2: svgIcons(ele.value),
            isUsed: false,
            isSelected: false,
            value: ele.value,
          };
        });
        this.setState({
          channels,
          shareList,
          shareListCopy: shareList,
          shareListCopyMsg: shareList,
          channelsCopy: channels.unshift({
            label: "Default",
            value: "Default",
          }),
        });
      }
    } catch (e) {
      console.log("Failed: ", e);
    }
  };

  getPhrases = async (resName) => {
    try {
      const r = await resService.getBotUtteranceDetails(resName);

      if (r.status === "success") {
        const { selectedChannel } = this.state;
        const phrase = { ...this.state.phrase };
        const shortMsg = { ...this.state.shortMsg };
        const selectedChannelPhrase = { ...this.state.selectedChannelPhrase };
        const selectedChannelMsg = { ...this.state.selectedChannelMsg };
        const phraseChannels = [];
        let channels = [...this.state.channels];
        let phraseAudios = [];
        let msgAudios = [];

        phrase.nonTrained = r.data;
        phrase.trained = r.trained;
        shortMsg.nonTrained = r.short_msg_utterances;

        if (selectedChannel && selectedChannel !== "Default") {
          selectedChannelPhrase.nonTrained = r.data.filter(
            (obj) => obj.channel === selectedChannel
          );
          selectedChannelMsg.nonTrained = r.short_msg_utterances?.filter(
            (obj) => obj.channel === selectedChannel
          );
        } else {
          selectedChannelPhrase.nonTrained = r.data.filter(
            (obj) => !obj.hasOwnProperty("channel") || obj.channel === "default"
          );
          selectedChannelMsg.nonTrained = r.short_msg_utterances?.filter(
            (obj) => !obj.hasOwnProperty("channel") || obj.channel === "default"
          );
        }

        selectedChannelPhrase.nonTrained.forEach((ele, i) => {
          if (ele.text.includes(".wav")) {
            ele.text.split(" ").forEach((e) => {
              if (e.includes(".wav]") && e.includes("["))
                phraseAudios.push({
                  title: e.substring(1, e.length - 1),
                  isSelected: false,
                  index: i,
                  channel: ele.channel,
                });
              else if (e.includes(".wav"))
                phraseAudios.push({
                  title: e,
                  isSelected: false,
                  index: i,
                  channel: ele.channel,
                });
            });
          }
        });

        selectedChannelMsg.nonTrained.forEach((ele, i) => {
          if (ele.text.includes(".wav")) {
            ele.text.split(" ").forEach((e) => {
              if (e.includes(".wav]") && e.includes("["))
                msgAudios.push({
                  title: e.substring(1, e.length - 1),
                  isSelected: false,
                  index: i,
                  channel: ele.channel,
                });
              else if (e.includes(".wav"))
                msgAudios.push({
                  title: e,
                  isSelected: false,
                  index: i,
                  channel: ele.channel,
                });
            });
          }
        });

        r.data.forEach((d) => {
          const { channel } = d;
          if (channel) phraseChannels.push(channel);
        });

        if (phraseChannels.length) {
          channels = channels.map((c, i) => {
            if (phraseChannels.includes(c.value)) {
              c.isUsed = true;
              c.isSelected = false;
            } else {
              c.isUsed = false;
              c.isSelected = false;
            }
            // if (!c.isUsed && !notSelectedChannel) {
            //   c.isSelected = true;
            //   notSelectedChannel = c?.label;
            // }
            return c;
          });
        } else {
          channels = channels.map((c, i) => {
            if (i === 0) {
              c.isSelected = true;
            }
            return c;
          });
        }
        let temp = [
          { label: "Default", value: "Default" },
          ...channels.filter((e) => e.isUsed),
        ];
        selectedChannelPhrase.nonTrained.map((obj) => {
          obj["isSelected"] = false;
          return obj;
        });
        selectedChannelMsg.nonTrained.map((obj) => {
          obj["isSelected"] = false;
          return obj;
        });

        this.setState({
          phrase,
          shortMsg,
          channels,
          selectedChannelMsg,
          selectedChannelPhrase,
          navBarMenu: [...temp],
          phraseAudios: phraseAudios,
          msgAudios: msgAudios,
        });
      }
    } catch (e) {
      this.props.setNotifications({ color: "danger", message: e.message });
    }
  };

  getBracketedText = (ele) => {
    if (ele.text.includes(".wav")) {
      ele.text = ele.text
        .split(" ")
        .map((str) => {
          if (
            str.includes(".wav") &&
            !str.includes(".wav]") &&
            !str.includes("[")
          )
            return `[${str}]`;
          else return str;
        })
        .join(" ");
      return ele;
    } else return ele;
  };

  getResponses = async () => {
    try {
      const r = await resService.getBotUtterances();

      if (r.status === "success") {
        const response = { ...this.state.response };

        response.nonTrained = r.data.map((d) => ({
          label: d,
          isSelected: false,
        }));
        response.trained = r.trained;

        this.setState({ response });
      }
    } catch (e) {
      this.props.setNotifications({ color: "danger", message: e.message });
    }
  };

  updateRes = async (isAudio) => {
    const { phrase, response } = this.state;
    const { resName } = response;

    let nonTrained = phrase.nonTrained.map((ele) => {
      ele.hasOwnProperty("isSelected") && delete ele.isSelected;
      return ele;
    });

    const body = {
      data: nonTrained,
      utterance: resName,
    };

    try {
      const r = await resService.updateBotUtterance(body);
      if (r.status === "success") {
        !isAudio && this.getPhrases(resName, this.state.selectedChannel);
        isAudio && this.handleNewAudioUpload();
      }
    } catch (e) {
      this.props.setNotifications({ color: "danger", message: e.message });
    }
  };

  updateMsgRes = async (isAudio) => {
    const { shortMsg, response } = this.state;
    const { resName } = response;

    let nonTrained = shortMsg.nonTrained.map((ele) => {
      ele.hasOwnProperty("isSelected") && delete ele.isSelected;
      return ele;
    });

    const body = {
      data: nonTrained,
      utterance: resName,
    };

    try {
      const r = await resService.updateBotUtterance(body, true);
      if (r.status === "success") {
        !isAudio && this.getPhrases(resName, this.state.selectedChannel);
        isAudio && this.handleNewAudioUpload(true);
      }
    } catch (e) {
      this.props.setNotifications({ color: "danger", message: e.message });
    }
  };

  updatePhrase = async (isShortMsg) => {
    const { phrase, response, shortMsg } = this.state;
    const body = isShortMsg
      ? {
          data: shortMsg.nonTrained,
          utterance: response.resName,
        }
      : {
          data: phrase.nonTrained,
          utterance: response.resName,
        };
    try {
      await resService.updateBotUtterance(body, isShortMsg);
    } catch (e) {
      this.props.setNotifications({ color: "danger", message: e.message });
    }
  };

  uploadAudio = async (formData, resName, isShortMsg) => {
    try {
      const r = await resService.uploadAudio(formData, isShortMsg);
      if (r.status === "success")
        this.setState({ isAddPhraseAudio: null, isAddMsgAudio: null }, () => {
          this.getPhrases(resName);
          this.props.setNotifications({
            color: "success",
            message: r.message,
          });
        });
      else
        this.props.setNotifications({
          color: "danger",
          message: r.message,
        });
    } catch (e) {
      this.props.setNotifications({ color: "danger", message: e.message });
    }
  };

  getPhraseAudio = (index, title, isShortMsg) => {
    const body = {
      response_type: "utterances",
      utterance_name: isShortMsg
        ? `${this.state.response.resName}_short_msg`
        : this.state.response.resName,
      file_names_list: [title],
    };

    resService
      .getAudio(body)
      .then((res) => {
        if (res.status === "success") {
          if (isShortMsg)
            this.setState({
              fetchedMsgAudio: { url: res.data[title], index: index },
            });
          else
            this.setState({
              fetchedAudio: { url: res.data[title], index: index },
            });
        } else {
          this.props.setNotifications({
            color: "danger",
            message: res.message,
          });
        }
      })
      .catch((e) =>
        this.props.setNotifications({ color: "danger", message: e.message })
      );
  };

  utilizeFocus = () => {
    const ref = createRef();
    const setFocus = () => {
      ref.current && ref.current.focus();
    };

    return { setFocus, ref };
  };

  // Started Clearing
  deleteChannel = (channel) => {
    let {
      phrase,
      navBarMenu,
      selectedChannelPhrase,
      channels,
      shortMsg,
      selectedChannelMsg,
    } = this.state;

    if (this.props.permissions.responses.modify === "enabled") {
      let temp = phrase.nonTrained.filter(
        (ele) => ele.channel !== channel.value
      );
      selectedChannelPhrase.nonTrained = phrase.nonTrained.filter(
        (obj) => !obj.hasOwnProperty("channel")
      );

      let temp2 = shortMsg.nonTrained.filter(
        (ele) => ele.channel !== channel.value
      );
      selectedChannelMsg.nonTrained = shortMsg.nonTrained.filter(
        (obj) => !obj.hasOwnProperty("channel")
      );

      let tmpChannel = channels.map((e) => {
        if (e.label === channel.label) {
          e.isUsed = false;
          e.isSelected = false;
        }
        return e;
      });

      let tmpNav = navBarMenu.filter((e) => e.label !== channel.label);
      this.setState(
        {
          phrase: { ...phrase, nonTrained: temp },
          shortMsg: { ...shortMsg, nonTrained: temp2 },
          selectedChannel: "Default",
          selectedChannelPhrase,
          selectedChannelMsg,
          navBarMenu: tmpNav,
          channels: tmpChannel,
        },
        () => this.updateRes()
      );
    }
  };

  handleNavMenuSelect = (menu, isDropdown) => {
    let { phrase, channels, shortMsg, phraseAudios, msgAudios } = this.state;
    let temp = {};
    let temp2 = {};
    let selectedChannel;
    let navBarMenuTemp;
    if (isDropdown && this.props.permissions.responses.modify === "enabled") {
      channels.forEach((i) => {
        if (i.label === menu.label) {
          i.isUsed = true;
          i.isSelected = true;
        } else i.isSelected = false;
      });
      selectedChannel = channels.filter((c) => c.label === menu.label)[0]
        ?.value;
    } else {
      if (menu.value === "Default") {
        temp.nonTrained = phrase.nonTrained.filter(
          (obj) => !obj.hasOwnProperty("channel")
        );
        temp2.nonTrained = shortMsg.nonTrained.filter(
          (obj) => !obj.hasOwnProperty("channel")
        );
        channels.map((c) =>
          c.value === menu.value
            ? (c.isSelected = true)
            : (c.isSelected = false)
        );
      } else {
        temp.nonTrained = phrase.nonTrained.filter(
          (obj) => obj.channel === menu.value
        );
        temp2.nonTrained = shortMsg?.nonTrained?.filter(
          (obj) => obj.channel === menu.value
        );
        channels.map((c) =>
          c.value === menu.value
            ? (c.isSelected = true)
            : (c.isSelected = false)
        );
      }
      temp.nonTrained.map((ele) => (ele.isSelected = false));
      temp.newPhraseName = phrase.newPhraseName;
      selectedChannel = channels.filter((c) => c.isSelected)[0]?.value;

      temp2?.nonTrained?.map((ele) => (ele.isSelected = false));
      temp.newPhraseName = shortMsg.newPhraseName;

      if (
        ["gnani_voice", "twilio_voice"].includes(selectedChannel) &&
        !phraseAudios.some((ele) => ele.channel === selectedChannel)
      ) {
        temp.nonTrained.map((ele, i) => {
          if (ele.text.includes(".wav")) {
            ele.text.split(" ").forEach((e) => {
              if (e.includes(".wav]") && e.includes("["))
                phraseAudios.push({
                  title: e.substring(1, e.length - 1),
                  isSelected: false,
                  index: i,
                  channel: ele.channel,
                });
              else if (e.includes(".wav"))
                phraseAudios.push({
                  title: e,
                  isSelected: false,
                  index: i,
                  channel: ele.channel,
                });
            });
          }
        });

        temp2.nonTrained.map((ele, i) => {
          if (ele.text.includes(".wav")) {
            ele.text.split(" ").forEach((e) => {
              if (e.includes(".wav]") && e.includes("["))
                msgAudios.push({
                  title: e.substring(1, e.length - 1),
                  isSelected: false,
                  index: i,
                  channel: ele.channel,
                });
              else if (e.includes(".wav"))
                msgAudios.push({
                  title: e,
                  isSelected: false,
                  index: i,
                  channel: ele.channel,
                });
            });
          }
        });
      }
    }
    navBarMenuTemp = channels.filter((e) => e.isUsed);
    navBarMenuTemp.unshift({ label: "Default", value: "Default" });
    this.setState({
      selectedChannelPhrase: temp,
      selectedChannelMsg: temp2,
      channels,
      selectedChannel,
      navBarMenu: navBarMenuTemp,
      phraseAudios,
      msgAudios,
      uploadingShortMsg: {
        buttons: [],
        image: "",
        audios: [],
      },
      uploadingPhrase: {
        buttons: [],
        image: "",
        audios: [],
      },
      phrase: { ...this.state.phrase, newPhraseName: "" },
    });
  };

  handleAddNewRes = ({ key, target }) => {
    const { value } = target;
    let { response } = this.state;

    if (key === "Enter" && response.newResName) {
      if (!response.nonTrained.find((r) => r.label === response.newResName)) {
        this.addNewRes(value);
      } else {
        this.setState({
          response: { ...response, newResName: "" },
        });
        this.props.setNotifications({
          color: "black",
          message: "Response already exists",
        });
      }
    } else {
      this.setState({
        response: { ...this.state.response, newResName: value },
      });
    }
  };

  handleResSelect = ({ label }) => {
    const { phrase, channels, response } = this.state;
    const localPhrase = { ...phrase };

    const localChannels = channels.map((c, i) => {
      if (c.isUsed || c.isSelected) {
        c.isUsed = false;
        c.isSelected = i === 1;
      }
      return c;
    });

    localPhrase.newPhraseName = "";

    const nonTrained = response.nonTrained.map((d) => {
      d.isSelected = d.label === label;
      return d;
    });

    this.setState(
      {
        response: {
          ...this.state.response,
          nonTrained,
          resName: label,
          resNameCopy: label,
          isResSelected: true,
        },
        phrase: localPhrase,
        channels: localChannels,
        selectedChannel: "Default",
        uploadingPhrase: {
          buttons: [],
          image: "",
          audios: [],
        },
        uploadingShortMsg: {
          buttons: [],
          image: "",
          audios: [],
        },
      },
      () =>
        this.props.permissions.responses.content === "enabled" &&
        this.getPhrases(label)
    );
  };

  handleResEdit = ({ key, target }, type) => {
    const { response } = this.state;
    const { resName, resNameCopy } = response;

    if ((key === "Enter" || type) && resName !== resNameCopy) {
      if (!response.nonTrained.some((r) => r.label === resName)) {
        if (/^[A-Za-z][A-Za-z0-9_]+$/.test(resName)) {
          this.editResName();
        } else {
          this.props.setNotifications({
            color: "black",
            message:
              "Intent name should not contain any special characters or spaces",
          });
        }
      } else {
        this.props.setNotifications({
          color: "black",
          message: "Response already exists",
        });
      }
    } else this.setState({ response: { ...response, resName: target.value } });
  };

  handleResDelete = async () => {
    const { response } = this.state;
    const body = {
      utterance: response.resName,
    };

    try {
      const r = await resService.deleteBotUtterance(body);

      if (r.status === "success" && Object.keys(r.failed || {}).length === 0) {
        const channels = this.state.channels.map((c) => {
          if (c.isUsed) {
            c.isUsed = false;
          } else c.isSelected = false;
          return c;
        });

        channels[0].isSelected = true;

        this.setState(
          { response: { ...response, isResSelected: false }, channels },
          this.getResponses
        );
        this.props.setNotifications({ color: "success", message: r.message });
      } else {
        this.props.setNotifications({
          color: "danger",
          message:
            Object.keys(r.failed || {}).length === 0
              ? r.message
              : r.failed[Object.keys(r.failed)[0]],
        });
      }
    } catch (e) {
      this.props.setNotifications({ color: "danger", message: e.message });
    }
  };

  handleNewAudioUpload = (isShortMsg) => {
    const { uploadingPhrase, response, phrase, uploadingShortMsg, shortMsg } =
      this.state;
    let index = phrase.nonTrained.length - 1;
    let index2 = shortMsg.nonTrained.length - 1;

    if (!!uploadingPhrase.audios?.length) {
      const formData = new FormData();
      formData.append("response_type", "utterances");
      formData.append("utterance", response.resName);
      formData.append("audio_file_index", index);

      uploadingPhrase.audios?.map((file, i) => {
        formData.append(
          "file",
          file,
          `${response.resName}_${index}_${i}.${file.name.split(".")[1]}`
        );
      });

      setTimeout(() => {
        this.setState({
          uploadingPhrase: {
            buttons: [],
            image: "",
            audios: [],
          },
        });
        this.uploadAudio(formData, response.resName);
      }, 100);
    } else if (!!uploadingShortMsg.audios?.length && isShortMsg) {
      const formData = new FormData();
      formData.append("response_type", "utterances");
      formData.append("utterance", response.resName);
      formData.append("audio_file_index", index2);

      uploadingShortMsg.audios?.map((file, i) => {
        formData.append(
          "file",
          file,
          `${response.resName}_short_msg_${index2}_${i}.${
            file.name.split(".")[1]
          }`
        );
      });

      setTimeout(() => {
        this.setState({
          uploadingShortMsg: {
            buttons: [],
            image: "",
            audios: [],
          },
        });
        this.uploadAudio(formData, response.resName, true);
      }, 100);
    }
  };

  handleAddNewPhrase = (e, btn) => {
    const { channels, phrase, uploadingPhrase, selectedChannel, response } =
      this.state;
    const isAudio = ["gnani_voice", "twilio_voice"].includes(selectedChannel);

    if (
      (e && e.key === "Enter" && /^(?!\s*$).+/.test(phrase.newPhraseName)) ||
      btn
    ) {
      const p = { ...phrase };
      let nonTrained = p.nonTrained;
      let newPhraseName = p.newPhraseName;
      // const selectedChannel = channels.filter((c) => c.isSelected)[0]?.value;
      const isUploadedImg = uploadingPhrase.image || "";
      const isUploadedBtn = uploadingPhrase.buttons;

      if (nonTrained.length && selectedChannel !== "Default") {
        p.nonTrained = [
          ...nonTrained,
          {
            text: newPhraseName,
            channel: selectedChannel,
            image: isUploadedImg,
            buttons: isUploadedBtn,
          },
        ];
        channels.map((c, i) => {
          if (c.isSelected) {
            c.isUsed = true;
          }
          return c;
        });

        this.setState({ channels });
      } else {
        if (nonTrained.length)
          p.nonTrained = [
            ...nonTrained,
            {
              text: newPhraseName,
              image: isUploadedImg,
              buttons: isUploadedBtn,
            },
          ];
        else
          p.nonTrained = [
            {
              text: newPhraseName,
              image: isUploadedImg,
              buttons: isUploadedBtn,
            },
          ];
      }

      p.newPhraseName = "";
      this.setState(
        {
          phrase: p,
          uploadingPhrase: {
            buttons: [],
            image: "",
            audios: uploadingPhrase.audios,
          },
        },
        () => this.updateRes(!!uploadingPhrase.audios.length)
      );
    } else if (e && typeof e === "string" && !btn) {
      this.setState({
        phrase: { ...phrase, newPhraseName: e },
      });
    } else if (e && !btn) {
      this.setState({
        phrase: { ...phrase, newPhraseName: e.target.value },
      });
    }
  };

  handleAddNewShortMsg = (e, btn) => {
    const { shortMsg, uploadingShortMsg, selectedChannel } = this.state;
    const isAudio = ["gnani_voice", "twilio_voice"].includes(selectedChannel);

    if (
      (e && e.key === "Enter" && /^(?!\s*$).+/.test(shortMsg.newPhraseName)) ||
      btn
    ) {
      const p = { ...shortMsg };
      let nonTrained = p.nonTrained;
      let newPhraseName = p.newPhraseName;
      const isUploadedImg = uploadingShortMsg.image || "";
      const isUploadedBtn = uploadingShortMsg.buttons;

      if (selectedChannel && selectedChannel !== "Default") {
        p.nonTrained = [
          ...nonTrained,
          {
            text: newPhraseName,
            channel: selectedChannel,
            image: isUploadedImg,
            buttons: isUploadedBtn,
          },
        ];
      } else {
        if (nonTrained.length)
          p.nonTrained = [
            ...nonTrained,
            {
              text: newPhraseName,
              image: isUploadedImg,
              buttons: isUploadedBtn,
            },
          ];
        else
          p.nonTrained = [
            {
              text: newPhraseName,
              image: isUploadedImg,
              buttons: isUploadedBtn,
            },
          ];
      }

      p.newPhraseName = "";
      this.setState(
        {
          shortMsg: p,
          uploadingShortMsg: {
            buttons: [],
            image: "",
            audios: uploadingShortMsg.audios,
          },
        },
        () => this.updateMsgRes(!!uploadingShortMsg.audios.length, "isShortMsg")
      );
    } else if (e && typeof e === "string" && !btn) {
      this.setState({
        shortMsg: { ...shortMsg, newPhraseName: e },
      });
    } else if (e && !btn) {
      this.setState({
        shortMsg: { ...shortMsg, newPhraseName: e.target.value },
      });
    }
  };

  handlePhraseAction = ({ value }, index) => {
    switch (value) {
      case "delete_phrase":
        this.setState({ modal: true });
        setTimeout(() => {
          this.setState({ modal: false });
        }, 100);
        console.log("delete phrase", this.state.selectedChannel);
        return;
      case "delete_img":
        this.setState({ imgModal: true });
        setTimeout(() => {
          this.setState({ imgModal: false });
        }, 100);
        console.log("delete image");
        break;
      case "delete_btn":
        const isDelete = { ...this.state.isDelete };
        isDelete.isShown = true;
        isDelete.index = index;
        return this.setState({ isDelete });
      case "add_img":
        this.phraseIndex = index;
        return this.setState({ isAddImgModal: true });
      case "delete_audio":
        const isDeleteAudio = { ...this.state.isDelete };
        isDeleteAudio.isShown = true;
        isDeleteAudio.index = index;
        return this.setState({ isDelete: isDeleteAudio });
      case "add_audio":
        return this.setState({ isAddPhraseAudio: index });
      case "add_msg_img":
        this.msgIndex = index;
        this.setState({ isMsgImgModal: true });
        return;
      case "add_msg_btn":
        this.msgIndex = index;
        this.setState({ isMsgBtnModal: true });
        return;
      case "add_msg_audio":
        return this.setState({ isAddMsgAudio: index });
      case "delete_msg_btn":
        const isDeleteMsgBtn = { ...this.state.isMsgDelete };
        isDeleteMsgBtn.isShown = true;
        isDeleteMsgBtn.index = index;
        return this.setState({ isMsgDelete: isDeleteMsgBtn });
      case "delete_msg_img":
        this.setState({ delMsgImgModal: true });
        setTimeout(() => {
          this.setState({ delMsgImgModal: false });
        }, 100);
        break;
      case "delete_msg_audio":
        const isDeleteMsgAudio = { ...this.state.isMsgDelete };
        isDeleteMsgAudio.isShown = true;
        isDeleteMsgAudio.index = index;
        return this.setState({ isMsgDelete: isDeleteMsgAudio });
      case "delete_msg_phrase":
        this.setState({ msgModal: true });
        setTimeout(() => {
          this.setState({ msgModal: false });
        }, 100);
        break;
      default:
        this.phraseIndex = index;
        this.setState({ isAddBtnModal: true });
        return;
    }
  };

  handlePharseDelete = (index, obj, multiDel) => {
    const { phrase, channels, selectedChannelPhrase, multiPhraseDlt } =
      this.state;

    let localPhrase;
    let localChannels = [...channels];
    let channel;

    if (multiDel) {
      if (index.length > 0) {
        let temp1 = phrase.nonTrained.filter((ele) => {
          return (
            index
              .map((e) => {
                return JSON.stringify(e);
              })
              .indexOf(JSON.stringify(ele)) === -1
          );
        });
        this.setState(
          { phrase: { ...phrase, nonTrained: temp1 }, phrasesToDelete: [] },
          this.updateRes
        );
      }
    } else {
      localPhrase = selectedChannelPhrase.nonTrained.find(
        (p, i) => i === index
      );
      let temp = phrase.nonTrained.filter(
        (obj) => obj.text !== localPhrase.text
      );
      this.setState(
        { phrase: { ...phrase, nonTrained: temp }, channels: localChannels },
        this.updateRes
      );
    }

    // if (channel) {
    //   localChannels = localChannels.map((l, i) => {
    //     if (l.value === channel) l.isUsed = false;
    //     return l;
    //   });
    // }
  };

  handleMsgDelete = (index, obj, multiDel) => {
    const { shortMsg, selectedChannelMsg } = this.state;

    let localPhrase;
    if (multiDel) {
      if (index.length > 0) {
        let temp1 = shortMsg.nonTrained.filter((ele) => {
          return (
            index
              .map((e) => {
                return JSON.stringify(e);
              })
              .indexOf(JSON.stringify(ele)) === -1
          );
        });
        this.setState(
          { shortMsg: { ...shortMsg, nonTrained: temp1 }, msgsToDelete: [] },
          () => this.updateMsgRes()
        );
      }
    } else {
      localPhrase = selectedChannelMsg.nonTrained.find((p, i) => i === index);
      let temp = shortMsg.nonTrained.filter(
        (obj) => obj.text !== localPhrase.text
      );
      this.setState({ shortMsg: { ...shortMsg, nonTrained: temp } }, () =>
        this.updateMsgRes()
      );
    }
  };

  handlePhraseImgUpload = async ({ target }, isShortMsg) => {
    const file = target.files;

    if (file.length) {
      const formData = new FormData();

      formData.append("file", file[0]);

      try {
        const r = await resService.uploadImgForUtterance(formData);

        if (r.status === "success") {
          const {
            phrase,
            uploadingPhrase,
            selectedChannelPhrase,
            selectedChannelMsg,
            uploadingShortMsg,
          } = this.state;
          const localPhrase = { ...selectedChannelPhrase };
          const newPhrase = { ...uploadingPhrase };
          const newMsg = { ...uploadingShortMsg };
          const localMsg = { ...selectedChannelMsg };

          if (isShortMsg) {
            if (this.msgIndex !== "") {
              localMsg.nonTrained[this.msgIndex].image = r.url;
            } else {
              newMsg.image = r.url;
            }
          } else {
            if (this.phraseIndex !== "") {
              localPhrase.nonTrained[this.phraseIndex].image = r.url;
            } else {
              newPhrase.image = r.url;
            }
          }
          console.log(newMsg, isShortMsg);
          this.setState(
            {
              phrase,
              uploadingPhrase: newPhrase,
              isAddImgModal: false,
              uploadingShortMsg: newMsg,
              isMsgImgModal: false,
            },
            () => {
              if (this.phraseIndex !== "" || this.msgIndex !== "") {
                isShortMsg
                  ? this.updatePhrase("isShortMsg")
                  : this.updatePhrase();
                this.phraseIndex = "";
                this.msgIndex = "";
              }
            }
          );
        }
      } catch (e) {
        console.log(`Failed: ${e}`);
      }
      target.value = "";
    }
  };

  handleAddBtnToNewPhrase = ({ key, target }, isShortMsg) => {
    const {
      addBtnToNewPhrase,
      phrase,
      uploadingPhrase,
      shortMsg,
      uploadingShortMsg,
    } = this.state;

    const { title, value } = addBtnToNewPhrase;

    if (key === "Enter") {
      if (!title || !value) {
        target.name === "title"
          ? this.dropDownBtnNewVal.setFocus()
          : this.dropDownBtnNewTitle.setFocus();
      } else {
        const nonTrained = [...phrase.nonTrained];
        const newPhrase = { ...uploadingPhrase };

        const newMsg = { ...uploadingShortMsg };
        const shortMsgs = [...shortMsg.nonTrained];

        if (isShortMsg) {
          if (this.msgIndex !== "") {
            shortMsgs[this.msgIndex].buttons.push({
              title,
              payload: value,
            });
          } else newMsg.buttons.push({ title, payload: value });
        } else {
          if (this.phraseIndex !== "") {
            nonTrained[this.phraseIndex].buttons.push({
              title,
              payload: value,
            });
          } else newPhrase.buttons.push({ title, payload: value });
        }

        document.getElementById("addBtnToNewPhrase").classList.remove("show");
        document.getElementById("addBtnToNewMsg").classList.remove("show");

        this.setState(
          {
            addBtnToNewPhrase: { title: "", value: "" },
            phrase: { ...phrase, nonTrained },
            uploadingPhrase: newPhrase,
            shortMsg: { ...shortMsg, nonTrained: shortMsgs },
            uploadingShortMsg: newMsg,
          },
          () => {
            if (this.phraseIndex !== "" || this.msgIndex !== "") {
              isShortMsg
                ? this.updatePhrase("isShortMsg")
                : this.updatePhrase();
              this.phraseIndex = "";
              this.msgIndex = "";
            }
          }
        );
      }
    } else {
      const localAddBtnToNewPhrase = { ...addBtnToNewPhrase };
      localAddBtnToNewPhrase[target.name] = target.value;
      this.setState({ addBtnToNewPhrase: localAddBtnToNewPhrase });
    }
  };

  handlePhraseDelImgOrBtn = (index, name, isShortMsg) => {
    const {
      phrase,
      isDelete,
      selectedChannelPhrase,
      shortMsg,
      selectedChannelMsg,
      isMsgDelete,
    } = this.state;

    const nonTrained = [...phrase.nonTrained];
    const msgNonTrained = [...shortMsg.nonTrained];

    if (isShortMsg) {
      let temp1 = selectedChannelMsg.nonTrained.find((b, i) => i === index);

      let msgIndex = shortMsg.nonTrained.findIndex(
        (obj) => JSON.stringify(obj) === JSON.stringify(temp1)
      );

      if (name === "msg_buttons") {
        msgNonTrained[msgIndex]["buttons"] = msgNonTrained[msgIndex][
          "buttons"
        ].filter((b) => !isMsgDelete.selectedBtns.includes(b.title));
      } else delete msgNonTrained[msgIndex]["buttons"];
    } else {
      let temp = selectedChannelPhrase.nonTrained.find((b, i) => i === index);

      let mainIndex = phrase.nonTrained.findIndex(
        (obj) => obj.text === temp.text && obj.channel === temp.channel
      );

      if (name === "buttons") {
        nonTrained[mainIndex][name] = nonTrained[mainIndex][name].filter(
          (b) => !isDelete.selectedBtns.includes(b.title)
        );
      } else delete nonTrained[mainIndex][name];
    }
    this.setState(
      {
        phrase: { ...phrase, nonTrained },
        isDelete: { ...isDelete, isShown: false },
        isMsgDelete: { ...isMsgDelete, isShown: false },
        shortMsg: { ...shortMsg, nonTrained: msgNonTrained },
      },
      () => {
        // if (isDelete.selectedBtns.length)
        isShortMsg ? this.updatePhrase(isShortMsg) : this.updatePhrase();
      }
    );
  };

  handlePhraseEdit = (e, i, obj, type, isShortMsg) => {
    if (e.key === "Enter" || type) {
      isShortMsg ? this.updatePhrase(isShortMsg) : this.updatePhrase();
    } else {
      const { selectedChannelPhrase, phrase, selectedChannelMsg, shortMsg } =
        this.state;

      const nonTrained = [...selectedChannelPhrase.nonTrained];
      const msgNonTrained = [...selectedChannelMsg.nonTrained];

      let realIndex = isShortMsg
        ? shortMsg.nonTrained.findIndex(
            (ele) => JSON.stringify(ele) === JSON.stringify(obj)
          )
        : phrase.nonTrained.findIndex(
            (ele) => JSON.stringify(ele) === JSON.stringify(obj)
          );

      if (typeof e === "string") {
        isShortMsg ? (msgNonTrained[i].text = e) : (nonTrained[i].text = e);
      } else {
        isShortMsg
          ? (msgNonTrained[i].text = e.target.value)
          : (nonTrained[i].text = e.target.value);
      }

      if (isShortMsg) shortMsg.nonTrained[realIndex] = msgNonTrained[i];
      else phrase.nonTrained[realIndex] = nonTrained[i];

      this.setState({
        phrase,
        selectedChannelPhrase: { ...selectedChannelPhrase, nonTrained },
        selectedChannelMsg: {
          ...selectedChannelMsg,
          nonTrained: msgNonTrained,
        },
        shortMsg,
      });
    }
  };

  handlePhraseActionList = (pObj, i, isShortMsg) => {
    const phraseActionsList = isShortMsg
      ? [
          {
            icon: this.getDeleteModal("msg_phrase", i, "", pObj),
            label: "Delete phrase",
            value: "delete_msg_phrase",
            isUsed: this.props.permissions.responses.modify !== "enabled",
          },
        ]
      : [
          {
            icon: this.getDeleteModal("phrase", i, "", pObj),
            label: "Delete phrase",
            value: "delete_phrase",
            isUsed: this.props.permissions.responses.modify !== "enabled",
          },
        ];

    const channel = pObj.channel;

    if (channel && !isShortMsg) {
      const btnChannels = ["telegram", "gnani_chat"];
      const isImg = ["whatsapp", "twilio_whatsapp", ...btnChannels].includes(
        channel
      );
      const isVoice = ["gnani_voice", "twilio_voice"].includes(channel);
      const isBtn = btnChannels.includes(channel);

      if (isImg)
        phraseActionsList.push({
          icon: <i className="fas fa-image" style={{ color: "#919191C3" }} />,
          label: "Add Image",
          value: "add_img",
          isUsed: this.props.permissions.responses.modify !== "enabled",
        });

      if (isBtn)
        phraseActionsList.push({
          icon: (
            <i className="fas fa-sync-alt" style={{ color: "#919191C3" }} />
          ),
          label: "Add Button",
          value: "add_btn",
          isUsed: this.props.permissions.responses.modify !== "enabled",
        });

      if (isVoice)
        phraseActionsList.unshift({
          label: "Add Audio",
          value: "add_audio",
          icon: svgIcons("headphones-grey"),
          isUsed: this.props.permissions.responses.modify !== "enabled",
        });
    }

    !isShortMsg &&
      pObj.image &&
      phraseActionsList.splice(2, 0, {
        icon: this.getDeleteModal("Delete Image", i),
        label: "Delete Image",
        value: "delete_img",
        isUsed: this.props.permissions.responses.modify !== "enabled",
      });
    !isShortMsg &&
      pObj.buttons?.length &&
      phraseActionsList.push({
        icon: getIcon("delete", "#919191C3"),
        label: "Delete Button",
        value: "delete_btn",
        isUsed: this.props.permissions.responses.modify !== "enabled",
      });

    !isShortMsg &&
      pObj?.text?.includes(".wav") &&
      phraseActionsList.splice(1, 0, {
        icon: getIcon("delete", "#919191C3"),
        label: "Delete Audio",
        value: "delete_audio",
        isUsed: this.props.permissions.responses.modify !== "enabled",
      });

    if (channel && isShortMsg) {
      const btnChannels = ["telegram", "gnani_chat"];
      const isImg = ["whatsapp", "twilio_whatsapp", ...btnChannels].includes(
        channel
      );
      const isVoice = ["gnani_voice", "twilio_voice"].includes(channel);
      const isBtn = btnChannels.includes(channel);

      if (isImg)
        phraseActionsList.splice(0, 0, {
          icon: <i className="fas fa-image" style={{ color: "#919191C3" }} />,
          label: "Add Image",
          value: "add_msg_img",
          isUsed: this.props.permissions.responses.modify !== "enabled",
        });

      if (isBtn)
        phraseActionsList.splice(1, 0, {
          icon: (
            <i className="fas fa-sync-alt" style={{ color: "#919191C3" }} />
          ),
          label: "Add Button",
          value: "add_msg_btn",
          isUsed: this.props.permissions.responses.modify !== "enabled",
        });

      if (isVoice)
        phraseActionsList.unshift({
          label: "Add Audio",
          value: "add_msg_audio",
          icon: svgIcons("headphones-grey"),
          isUsed: this.props.permissions.responses.modify !== "enabled",
        });
    }

    isShortMsg &&
      pObj.image &&
      phraseActionsList.splice(2, 0, {
        icon: this.getDeleteModal("Delete_msg_Image", i),
        label: "Delete Image",
        value: "delete_msg_img",
        isUsed: this.props.permissions.responses.modify !== "enabled",
      });
    isShortMsg &&
      pObj.buttons?.length &&
      phraseActionsList.push({
        icon: getIcon("delete", "#919191C3"),
        label: "Delete Button",
        value: "delete_msg_btn",
        isUsed: this.props.permissions.responses.modify !== "enabled",
      });

    isShortMsg &&
      pObj?.text?.includes(".wav") &&
      phraseActionsList.splice(1, 0, {
        icon: getIcon("delete", "#919191C3"),
        label: "Delete Audio",
        value: "delete_msg_audio",
        isUsed: this.props.permissions.responses.modify !== "enabled",
      });

    return phraseActionsList;
  };

  handleBtnDeleteSelection = ({ title }, pIndex, btnIndex) => {
    const isDelete = { ...this.state.isDelete };

    isDelete.index = pIndex;

    if (isDelete.isShown) {
      if (isDelete.selectedBtns.includes(title))
        isDelete.selectedBtns = isDelete.selectedBtns.filter(
          (b) => b !== title
        );
      else isDelete.selectedBtns.push(title);

      this.setState({ isDelete });
    } else {
      this.setState({ isEditBtnModal: btnIndex });
    }
  };

  handleMsgBtnDelSelection = ({ title }, pIndex, btnIndex) => {
    const isMsgDelete = { ...this.state.isMsgDelete };

    isMsgDelete.index = pIndex;

    if (isMsgDelete.isShown) {
      if (isMsgDelete.selectedBtns.includes(title))
        isMsgDelete.selectedBtns = isMsgDelete.selectedBtns.filter(
          (b) => b !== title
        );
      else isMsgDelete.selectedBtns.push(title);

      this.setState({ isMsgDelete });
    } else {
      // this.setState({ isEditBtnModal: btnIndex });
    }
  };

  handleBtnEdit = ({ target }, pIndex, btnIndex) => {
    let { selectedChannelPhrase } = this.state;

    const { name, value } = target;

    selectedChannelPhrase.nonTrained[pIndex].buttons[btnIndex][name] = value;
    this.setState({ selectedChannelPhrase });
  };

  getEditBtnModal = (pIndex, btnIndex) => {
    let { isEditBtnModal, modalInps, selectedChannelPhrase } = this.state;

    return (
      <Modal
        addNewModalOpen={isEditBtnModal >= 0}
        className="duplicate-modal-container"
        isHeader={false}
        bodyClassName="p-0"
        modalSize="md"
      >
        <div className="row mb-3 align-items-center">
          <div className="col-auto u-s-dropdown-list-btn-title">Title</div>
          <div className="col" style={{ width: "calc(100% - 4rem)" }}>
            <Input
              autoFocus={true}
              className="u-s-dropdown-list-inp"
              name="title"
              onChange={(e) => this.handleBtnEdit(e, pIndex, btnIndex)}
              placeholder="Button title"
              value={
                selectedChannelPhrase?.nonTrained[pIndex]?.buttons[btnIndex]
                  ?.title
              }
            />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-auto u-s-dropdown-list-btn-title">Value</div>
          <div className="col" style={{ width: "calc(100% - 4rem)" }}>
            <Input
              className="u-s-dropdown-list-inp"
              name="payload"
              onChange={(e) => this.handleBtnEdit(e, pIndex, btnIndex)}
              placeholder="Button value"
              value={
                selectedChannelPhrase?.nonTrained[pIndex]?.buttons[btnIndex]
                  ?.payload
              }
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col text-end">
            <Button
              className="btn-outline-light rounded-3 px-4 py-2 btn-secondary"
              styles={{ width: "10rem" }}
              onClick={() => this.setState({ isEditBtnModal: null })}
            >
              Cancel
            </Button>
          </div>
          <div className="col-auto">
            <Button
              className="duplicate-modal-confirm-btn"
              // isDisabled={!modalInps.title || !modalInps.value}
              onClick={() =>
                this.setState({ isEditBtnModal: false }, () => this.updateRes())
              }
            >
              Add Button
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  renderPhraseBtns = ({ selectedBtns, index }, buttons, pIndex) => {
    return buttons.map((b, i) => (
      <>
        <Button
          className={`utterence-sentence-bottom-btn me-2 ${
            selectedBtns.includes(b.title) &&
            index === pIndex &&
            "res-phrase-del-btn-border"
          }`}
          key={i}
          onClick={() => this.handleBtnDeleteSelection(b, pIndex, i)}
        >
          {b.title}
        </Button>
        {this.state.isEditBtnModal === i && this.getEditBtnModal(pIndex, i)}
      </>
    ));
  };

  renderMsgBtns = ({ selectedBtns, index }, buttons, pIndex) => {
    return buttons.map((b, i) => (
      <>
        <Button
          className={`utterence-sentence-bottom-btn me-2 ${
            selectedBtns.includes(b.title) &&
            index === pIndex &&
            "res-phrase-del-btn-border"
          }`}
          key={i}
          onClick={() => this.handleMsgBtnDelSelection(b, pIndex, i)}
        >
          {b.title}
        </Button>
        {/* {this.state.isEditBtnModal === i &&
              !isShortMsg &&
              this.getEditBtnModal(pIndex, i)} */}
      </>
    ));
  };

  renderPhraseAudiosList = ({ selectedBtns, isShown, index }, pIndex) => {
    return this.state?.phraseAudios?.map(
      (b, i) =>
        b.index === pIndex && (
          <div className="col-6 row m-0 justify-content-between mb-3">
            <div
              className="col-auto d-flex ps-0"
              onClick={() => this.handleAudioDeleteSelection(b, pIndex, i)}
            >
              {isShown && index === pIndex && (
                <div className="align-items-center me-2 m-auto">
                  {svgIcons(
                    `${
                      selectedBtns.includes(b.title)
                        ? "check-box-marked"
                        : "check-box-outline-blank"
                    }`
                  )}
                </div>
              )}
              <div className="u-audio-icn-cont">
                {svgIcons("headphones-grey")}
              </div>
              <p className="align-self-center m-auto">{b.title}</p>
            </div>
            <div
              className="col-auto cursor-pointer"
              onClick={() => {
                if (this.state.fetchedAudio.url) {
                  this.setState({
                    fetchedAudio: { url: "", index: null },
                  });
                } else {
                  this.getPhraseAudio(i, b.title);
                  setTimeout(() => {
                    let temp = document.getElementById(`phraseAudio-${i}`);
                    temp && temp.play();
                    temp.addEventListener("ended", () => {
                      this.setState({
                        fetchedAudio: { url: "", index: null },
                      });
                    });
                  }, 100);
                }
              }}
            >
              {this.state.fetchedAudio.url && (
                <audio
                  src={this.state.fetchedAudio.url}
                  id={`phraseAudio-${i}`}
                  // autoPlay
                />
              )}
              {this.state.fetchedAudio.index === i
                ? svgIcons("stop-circle")
                : svgIcons("play-circle", "none")}
            </div>
          </div>
        )
    );
  };

  renderMsgAudiosList = ({ selectedBtns, isShown, index }, pIndex) => {
    return this.state?.msgAudios?.map(
      (b, i) =>
        b.index === pIndex && (
          <div className="col-6 row m-0 justify-content-between mb-3">
            <div
              className="col-auto d-flex ps-0"
              onClick={() => this.handleMsgAudioDeleteSelection(b, pIndex, i)}
            >
              {isShown && index === pIndex && (
                <div className="align-items-center me-2 m-auto">
                  {svgIcons(
                    `${
                      selectedBtns.includes(b.title)
                        ? "check-box-marked"
                        : "check-box-outline-blank"
                    }`
                  )}
                </div>
              )}
              <div className="u-audio-icn-cont">
                {svgIcons("headphones-grey")}
              </div>
              <p className="align-self-center m-auto">{b.title}</p>
            </div>
            <div
              className="col-auto cursor-pointer"
              onClick={() => {
                if (this.state.fetchedMsgAudio.url) {
                  this.setState({
                    fetchedMsgAudio: { url: "", index: null },
                  });
                } else {
                  this.getPhraseAudio(i, b.title, true);
                  setTimeout(() => {
                    let temp = document.getElementById(`phraseAudioMsg-${i}`);
                    temp && temp.play();
                    temp &&
                      temp.addEventListener("ended", () => {
                        this.setState({
                          fetchedMsgAudio: { url: "", index: null },
                        });
                      });
                  }, 100);
                }
              }}
            >
              {this.state.fetchedMsgAudio.url && (
                <audio
                  src={this.state.fetchedMsgAudio.url}
                  id={`phraseAudioMsg-${i}`}
                  // autoPlay
                />
              )}
              {this.state.fetchedMsgAudio.index === i
                ? svgIcons("stop-circle")
                : svgIcons("play-circle", "none")}
            </div>
          </div>
        )
    );
  };

  renderBottomPanel = (
    { buttons = [], image, isSelected, channel },
    pIndex,
    isShortMsg
  ) => {
    if (buttons.length || image || !!this.state?.phraseAudios?.length) {
      return (
        <div
          className="row m-0"
          style={{
            background: `${isSelected ? "#F2F4FF" : "#fafafa"}`,
            border: `${isSelected ? "1px solid #46569940" : ""}`,
          }}
        >
          {image && (
            <div className="col ps-3 py-3 border-end">
              <p className="u-s-b-p-text">Image</p>
              <div className="u-s-bottom-content-holder">
                <img
                  alt=""
                  className="img-fluid utterence-sentence-img"
                  src={getImageUrl(image)}
                />
              </div>
            </div>
          )}
          {!!buttons.length && (
            <div className={`col py-3 ${!image ? "ps-3" : "ps-4"}`}>
              <p className="u-s-b-p-text">Button</p>
              <div className="u-s-bottom-content-holder py-2 ps-2">
                {!isShortMsg
                  ? this.renderPhraseBtns(this.state.isDelete, buttons, pIndex)
                  : this.renderMsgBtns(this.state.isMsgDelete, buttons, pIndex)}
              </div>
            </div>
          )}
          {!isShortMsg &&
            this.state.phraseAudios.some((ele) => ele.channel === channel) &&
            this.state.phraseAudios.some((ele) => ele.index === pIndex) && (
              <div className={`col py-3 ${!image ? "ps-3" : "ps-4"}`}>
                <p className="u-s-b-p-text">Audio</p>
                <div className="row">
                  {this.renderPhraseAudiosList(this.state.isDelete, pIndex)}
                </div>
              </div>
            )}
          {isShortMsg &&
            this.state.msgAudios.some((ele) => ele.channel === channel) &&
            this.state.msgAudios.some((ele) => ele.index === pIndex) && (
              <div className={`col py-3 ${!image ? "ps-3" : "ps-4"}`}>
                <p className="u-s-b-p-text">Audio</p>
                <div className="row">
                  {this.renderMsgAudiosList(this.state.isMsgDelete, pIndex)}
                </div>
              </div>
            )}
        </div>
      );
    }
  };

  renderPhrases() {
    const {
      channels,
      isDelete,
      phrase,
      onPhraseHover,
      listId,
      selectedChannelPhrase,
      phraseAudios,
    } = this.state;

    return selectedChannelPhrase?.nonTrained?.map((p, i) => (
      <div className="row m-0 mb-3" key={i}>
        <div
          className="col-auto me-2 pe-0"
          onClick={() =>
            this.props.permissions.responses.modify === "enabled" &&
            this.handleSelectPhrase(p)
          }
        >
          {p?.isSelected
            ? svgIcons("check-box-marked")
            : svgIcons("check-box-outline-blank")}
        </div>
        <div
          className={`col ps-0 utterence-sentence-bottom-panel${
            onPhraseHover === i ? " shadow-sm" : ""
          }`}
          onMouseEnter={() => this.setState({ onPhraseHover: i })}
          onMouseLeave={() =>
            this.setState({
              onPhraseHover: false,
              isDelete: {
                isShown: false,
                index: "",
                selectedBtns: [],
              },
            })
          }
        >
          <div
            className={`row m-0 ps-2 align-items-center ${
              (p.image || !!p.buttons?.length || phraseAudios?.length) &&
              "border-bottom"
            }`}
            style={{
              background: `${p?.isSelected ? "#F2F4FF" : "#fafafa"}`,
              border: `${p?.isSelected ? "1px solid #46569940" : ""}`,
            }}
          >
            <div className="col py-2">
              <div className="row">
                <div className="col-auto u-bottom-panel-header ps-2 u-b-p-header-key">
                  Phrase:
                </div>
                <div className="col u-bottom-panel-header">
                  <Input
                    isLiteration
                    className="u-bottom-panel-inp"
                    readOnly={
                      this.props.permissions.responses.modify !== "enabled"
                    }
                    onBlur={(e) => this.handlePhraseEdit(e, i, p, "onBlur")}
                    onChange={(e) => this.handlePhraseEdit(e, i, p)}
                    onKeyPress={(e) => this.handlePhraseEdit(e, i, p)}
                    value={p.text}
                  />
                </div>
              </div>
            </div>
            <div
              className={`col-auto p-0 align-items-center ${
                onPhraseHover === i || listId === i ? "" : " d-none"
              }`}
              ref={this.listIdRef}
              onClick={() => this.setState({ listId: i })}
            >
              {isDelete.isShown && isDelete.index === i ? (
                <div className="row align-items-center">
                  <div className="col">
                    <p className="res-phrase-delete-imp-text">
                      * You can Select multiple buttons
                    </p>
                  </div>
                  <div className="col">
                    {["gnani_voice", "twilio_voice"].includes(p.channel)
                      ? this.getDeleteModal("delete_audio", i, "isAudio", p)
                      : this.getDeleteModal("delete_btn", i, "isButton")}
                  </div>
                </div>
              ) : (
                <DropDown
                  actionButton={() => getIcon("verticalOptions")}
                  direction="left"
                  btnClassName="px-1"
                  dropdownList={this.handlePhraseActionList(p, i)}
                  onActionItemClick={(m) => this.handlePhraseAction(m, i)}
                  dropDownMenuMinWidth="12rem"
                />
              )}
            </div>
          </div>
          {this.renderBottomPanel(p, i)}
        </div>
        {["gnani_voice", "twilio_voice"].includes(p.channel) &&
          this.state.isAddPhraseAudio === i && (
            <div className="row m-0">{this.renderUploadAudioBtnCont()}</div>
          )}
      </div>
    ));
  }

  handleSelectPhrase(p, isShortMsg) {
    let {
      phrasesToDelete,
      selectedChannelPhrase,
      msgsToDelete,
      selectedChannelMsg,
    } = this.state;

    if (isShortMsg) {
      p.isSelected = !p.isSelected;
      msgsToDelete = selectedChannelMsg.nonTrained.filter((e) => e.isSelected);
    } else {
      p.isSelected = !p.isSelected;
      phrasesToDelete = selectedChannelPhrase.nonTrained.filter(
        (e) => e.isSelected
      );
    }

    this.setState(
      {
        selectedChannelPhrase,
        phrasesToDelete,
        msgsToDelete,
        selectedChannelMsg,
      },
      () => {
        this.handleShareDropDownValidation(
          phrasesToDelete,
          isShortMsg,
          msgsToDelete
        );
      }
    );
  }

  handleShareDropDownValidation(phrasesToDelete, isShortMsg, msgsToDelete) {
    let { shareListCopy, navBarMenu, shareListCopyMsg } = this.state;

    const isImage = [
      "whatsapp",
      "twilio_whatsapp",
      "gnani_chat",
      "telegram",
      "slack",
      "microsoft_botframework",
    ];
    const isBtn = ["gnani_chat", "telegram", "slack", "microsoft_botframework"];
    const isMsg = navBarMenu.map((e) => e.value);

    if (isShortMsg) {
      let temp1;
      if (msgsToDelete.some((ele) => ele.buttons?.length > 0)) {
        temp1 = shareListCopyMsg
          .map((c) => {
            !isBtn.includes(c.value) && (c.isUsed = true);
            return c;
          })
          .map((e) => !isMsg.includes(e.value) && (e.isUsed = true));
      } else if (msgsToDelete.some((ele) => ele?.image)) {
        temp1 = shareListCopyMsg
          .map((c) => {
            !isImage.includes(c.value) && (c.isUsed = true);
            return c;
          })
          .map((e) => !isMsg.includes(e.value) && (e.isUsed = true));
      } else {
        temp1 = shareListCopyMsg.map((c) => {
          !isMsg.includes(c.value) && (c.isUsed = true);
          return c;
        });
      }

      this.setState({ shareListCopyMsg: temp1 });
    } else {
      if (phrasesToDelete.some((ele) => ele.buttons?.length > 0)) {
        let temp = shareListCopy.map((c) => {
          !isBtn.includes(c.value) && (c.isUsed = true);
          return c;
        });
        this.setState({ shareListCopy: temp });
      } else if (phrasesToDelete.some((ele) => ele?.image)) {
        let temp = shareListCopy.map((c) => {
          !isImage.includes(c.value) && (c.isUsed = true);
          return c;
        });
        this.setState({ shareListCopy: temp });
      }
    }
  }

  handleShareSelectedPhrases = () => {
    let { shareListCopy, phrasesToDelete, phrase } = this.state;

    let temp = [];
    shareListCopy.forEach((c) => {
      if (c.isSelected) {
        phrasesToDelete.forEach((p) => {
          temp.push({ ...p, channel: c.value });
        });
      }
    });

    let tempList = shareListCopy.map((e) => {
      e.isSelected = false;
      return e;
    });

    this.setState(
      {
        phrase: {
          ...phrase,
          nonTrained: [...phrase.nonTrained, ...temp],
        },
        phrasesToDelete: [],
        shareListCopy: tempList,
      },
      this.updateRes
    );
    this.sharePhraseRef.current.toggle();
  };

  handleShareSelectedMsgs = () => {
    let { shareListCopyMsg, msgsToDelete, shortMsg } = this.state;

    let temp = [];
    shareListCopyMsg.forEach((c) => {
      if (c.isSelected) {
        msgsToDelete.forEach((p) => {
          temp.push({ ...p, channel: c.value });
        });
      }
    });

    let tempList = shareListCopyMsg.map((e) => {
      e.isSelected = false;
      return e;
    });

    this.setState(
      {
        shortMsg: {
          ...shortMsg,
          nonTrained: [...shortMsg.nonTrained, ...temp],
        },
        msgsToDelete: [],
        shareListCopy: tempList,
      },
      () => this.updateMsgRes()
    );
    this.shareMsgRef.current.toggle();
  };

  handleCancelShareDropDown = () => {
    const { shareListCopy } = this.state;
    this.setState({
      shareListCopy: shareListCopy.map((e) => {
        e.isSelected = false;
        return e;
      }),
    });
    this.sharePhraseRef.current.toggle();
  };

  renderDropDownBtnActionContainer = () => {
    return (
      <div className="row align-items-center">
        <div className="col cursor-pointer">
          {getIcon("plus", "#495072", "", "10")}
        </div>
        <div className="col-auto pt-1">
          <i className="fas fa-image me-2" />
          <span>Button</span>
        </div>
      </div>
    );
  };

  renderDropDownListBtnContainer = (isShortMsg) => {
    const { title, value } = this.state.addBtnToNewPhrase;

    return (
      <>
        <div className="row mb-3 align-items-center">
          <div className="col-auto u-s-dropdown-list-btn-title">Title</div>
          <div className="col" style={{ width: "calc(100% - 4rem)" }}>
            <Input
              autoFocus={true}
              className="u-s-dropdown-list-inp"
              name="title"
              onChange={(e) =>
                isShortMsg
                  ? this.handleAddBtnToNewPhrase(e, isShortMsg)
                  : this.handleAddBtnToNewPhrase(e)
              }
              onKeyPress={(e) =>
                isShortMsg
                  ? this.handleAddBtnToNewPhrase(e, isShortMsg)
                  : this.handleAddBtnToNewPhrase(e)
              }
              placeholder="Button title"
              ref={this.dropDownBtnNewTitle.ref}
              value={title}
            />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-auto u-s-dropdown-list-btn-title">Value</div>
          <div className="col" style={{ width: "calc(100% - 4rem)" }}>
            <Input
              className="u-s-dropdown-list-inp"
              name="value"
              onChange={(e) =>
                isShortMsg
                  ? this.handleAddBtnToNewPhrase(e, isShortMsg)
                  : this.handleAddBtnToNewPhrase(e)
              }
              onKeyPress={(e) =>
                isShortMsg
                  ? this.handleAddBtnToNewPhrase(e, isShortMsg)
                  : this.handleAddBtnToNewPhrase(e)
              }
              placeholder="Button value"
              ref={this.dropDownBtnNewVal.ref}
              value={value}
            />
          </div>
        </div>
      </>
    );
  };

  renderUploadingBtns = (buttons) => {
    return buttons.map((b, i) => (
      <Button className="utterence-sentence-bottom-btn me-2" key={i}>
        {b.title}
      </Button>
    ));
  };

  renderUploadingAudios = (audios, isShortMsg) => {
    let { uploadingPhrase, response, currentlyPlaying, uploadingShortMsg } =
      this.state;
    return (
      <div className="row justify-content-between align-items-center m-0">
        {audios.map((a, i) => {
          return (
            <div className="col-6 mb-2" key={i}>
              <div className="row justify-content-between">
                <div className="col-auto d-flex">
                  <div className="u-audio-icn-cont">
                    {svgIcons("headphones-grey")}
                  </div>
                  <p className="align-self-center m-auto res-uploading-audioname-trim">
                    {isShortMsg
                      ? `${response.resName}_short_msg_${i + 1}`
                      : `${response.resName}_${i + 1}`}
                  </p>
                </div>
                <div className="col-auto cursor-pointer d-flex">
                  <audio
                    className=""
                    src={URL.createObjectURL(a)}
                    id={`sound${i}`}
                  />
                  <div
                    className="px-2"
                    onClick={() => {
                      if (currentlyPlaying === null) {
                        this.setState({ currentlyPlaying: i }, () =>
                          document.getElementById(`sound${i}`).play()
                        );
                      } else {
                        this.setState({ currentlyPlaying: null }, () =>
                          document.getElementById(`sound${i}`).pause()
                        );
                      }
                    }}
                  >
                    {currentlyPlaying === i
                      ? svgIcons("stop-circle")
                      : svgIcons("play-circle", "none")}
                  </div>
                  {isShortMsg ? (
                    <div
                      className="px-2"
                      onClick={() => {
                        uploadingShortMsg.audios = audios.filter(
                          (aud, ix) => ix !== i
                        );
                        this.setState({ uploadingShortMsg });
                      }}
                    >
                      {svgIcons("close-small")}
                    </div>
                  ) : (
                    <div
                      className="px-2"
                      onClick={() => {
                        uploadingPhrase.audios = audios.filter(
                          (aud, ix) => ix !== i
                        );
                        this.setState({ uploadingPhrase });
                      }}
                    >
                      {svgIcons("close-small")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  handlePhraseAudioUpload = ({ target }) => {
    let {
      uploadingPhrase,
      response,
      selectedChannelPhrase,
      isAddPhraseAudio,
      phrase,
      phraseAudios,
    } = this.state;
    const file = target.files;

    if (file.length) {
      if (isAddPhraseAudio !== null) {
        let temp = selectedChannelPhrase.nonTrained[isAddPhraseAudio];
        let index = phrase.nonTrained.findIndex(
          (ele) => JSON.stringify(ele) === JSON.stringify(temp)
        );
        let newIndex = phraseAudios.filter(
          (ele) => ele.index === isAddPhraseAudio
        ).length;
        const formData = new FormData();
        formData.append(
          "file",
          file[0],
          `${response.resName}_${index}_${newIndex}.${
            file[0].name.split(".")[1]
          }`
        );
        formData.append("response_type", "utterances");
        formData.append("utterance", response.resName);
        formData.append("audio_file_index", index);

        this.uploadAudio(formData, response.resName);
      } else {
        Object.keys(file).forEach((f) => {
          if (file[f].size / 1024 < 5632) {
            uploadingPhrase.audios.push(file[f]);
          } else {
            this.props.setNotifications({
              color: "danger",
              message: "File size should be less than 5mb",
            });
          }
        });
        this.setState({ uploadingPhrase });
      }
    }
  };

  handleMsgAudioUpload = ({ target }) => {
    let {
      uploadingShortMsg,
      shortMsg,
      selectedChannelMsg,
      isAddMsgAudio,
      response,
      msgAudios,
    } = this.state;
    const file = target.files;

    if (file.length) {
      if (isAddMsgAudio !== null) {
        let temp = selectedChannelMsg.nonTrained[isAddMsgAudio];
        let index = shortMsg.nonTrained.findIndex(
          (ele) => JSON.stringify(ele) === JSON.stringify(temp)
        );
        let newIndex = msgAudios.filter(
          (ele) => ele.index === isAddMsgAudio
        ).length;
        const formData = new FormData();
        formData.append(
          "file",
          file[0],
          `${response.resName}_short_msg_${index}_${newIndex}.${
            file[0].name.split(".")[1]
          }`
        );
        formData.append("response_type", "utterances");
        formData.append("utterance", response.resName);
        formData.append("audio_file_index", index);

        this.uploadAudio(formData, response.resName, true);
      } else {
        Object.keys(file).forEach((f) => {
          if (file[f].size / 1024 < 5632) {
            uploadingShortMsg.audios.push(file[f]);
          } else {
            this.props.setNotifications({
              color: "danger",
              message: "File size should be less than 5mb",
            });
          }
        });
        this.setState({ uploadingShortMsg });
      }
    }
  };

  record = async () => {
    this.setState({ isMicLoading: true });

    if (this.state.isRecording) {
      const blob = await recorder.stopRecording();
      this.handleRecordedPhraseAudio(blob);
    } else {
      try {
        await recorder.initAudio();
        await recorder.initWorker();
        recorder.startRecording();
        this.setState({ isMicLoading: false, isRecording: true });
      } catch (e) {
        console.error(e);
        this.setState({ isMicLoading: false });
      }
    }
  };

  msgRecord = async () => {
    this.setState({ isMsgMicLoading: true });

    if (this.state.isMsgRecording) {
      const blob = await recorder.stopRecording();
      this.handleRecordedMsgAudio(blob);
    } else {
      try {
        await recorder.initAudio();
        await recorder.initWorker();
        recorder.startRecording();
        this.setState({ isMsgMicLoading: false, isMsgRecording: true });
      } catch (e) {
        console.error(e);
        this.setState({ isMsgMicLoading: false });
      }
    }
  };

  handleRecordedPhraseAudio = (blob) => {
    let {
      uploadingPhrase,
      selectedChannelPhrase,
      phrase,
      isAddPhraseAudio,
      response,
      phraseAudios,
    } = this.state;

    if (isAddPhraseAudio !== null) {
      let number = phraseAudios.filter(
        (ele) => ele.index === isAddPhraseAudio
      ).length;
      let temp = selectedChannelPhrase.nonTrained[isAddPhraseAudio];
      let index = phrase.nonTrained.findIndex(
        (ele) => JSON.stringify(ele) === JSON.stringify(temp)
      );
      const file = new File(
        [blob],
        `${response.resName}_${index}_${number}.mp3`
      );
      const formData = new FormData();
      formData.append("file", file);
      formData.append("response_type", "utterances");
      formData.append("utterance", response.resName);
      formData.append("audio_file_index", index);

      this.uploadAudio(formData, response.resName);
    } else {
      const file = new File([blob], `voice.mp3`);
      file.size / 1024 < 5632
        ? uploadingPhrase.audios.push(file)
        : this.props.setNotifications({
            color: "danger",
            message: "File size should be less than 5mb",
          });
    }
    this.setState({
      isMicLoading: false,
      isRecording: false,
      uploadingPhrase,
    });
  };

  handleRecordedMsgAudio = (blob) => {
    let {
      uploadingShortMsg,
      shortMsg,
      selectedChannelMsg,
      isAddMsgAudio,
      response,
      msgAudios,
    } = this.state;

    if (isAddMsgAudio !== null) {
      let number = msgAudios.filter(
        (ele) => ele.index === isAddMsgAudio
      ).length;
      let temp = selectedChannelMsg.nonTrained[isAddMsgAudio];
      let index = shortMsg.nonTrained.findIndex(
        (ele) => JSON.stringify(ele) === JSON.stringify(temp)
      );
      const file = new File(
        [blob],
        `${response.resName}_${index}_${number}.mp3`
      );
      const formData = new FormData();
      formData.append("file", file);
      formData.append("response_type", "utterances");
      formData.append("utterance", response.resName);
      formData.append("audio_file_index", index);

      this.uploadAudio(formData, response.resName, true);
    } else {
      const file = new File([blob], `voice.mp3`);
      file.size / 1024 < 5632
        ? uploadingShortMsg.audios.push(file)
        : this.props.setNotifications({
            color: "danger",
            message: "File size should be less than 5mb",
          });
    }
    this.setState({
      isMsgMicLoading: false,
      isMsgRecording: false,
      uploadingShortMsg,
    });
  };

  handleAudioDeleteSelection = ({ title }, pIndex) => {
    const isDelete = { ...this.state.isDelete };

    isDelete.index = pIndex;

    if (isDelete.isShown) {
      if (isDelete.selectedBtns.includes(title))
        isDelete.selectedBtns = isDelete.selectedBtns.filter(
          (b) => b !== title
        );
      else isDelete.selectedBtns.push(title);

      this.setState({ isDelete });
    }
  };

  handleMsgAudioDeleteSelection = ({ title }, pIndex) => {
    const isMsgDelete = { ...this.state.isMsgDelete };

    isMsgDelete.index = pIndex;

    if (isMsgDelete.isShown) {
      if (isMsgDelete.selectedBtns.includes(title))
        isMsgDelete.selectedBtns = isMsgDelete.selectedBtns.filter(
          (b) => b !== title
        );
      else isMsgDelete.selectedBtns.push(title);

      this.setState({ isMsgDelete });
    }
  };

  handlePhraseAudioDel = (isShortMsg, dataObj) => {
    resService
      .deleteAudio(dataObj, isShortMsg)
      .then((res) => {
        if (res.status === "success")
          this.setState(
            {
              isDelete: { index: null, isShown: false, selectedBtns: [] },
            },
            () => {
              this.props.setNotifications({
                color: "success",
                message: res.message,
              });
              this.getPhrases(this.state.response.resName);
            }
          );
        else
          this.props.setNotifications({
            color: "danger",
            message: res.message,
          });
      })
      .catch((e) =>
        this.props.setNotifications({
          color: "danger",
          message: e.message,
        })
      );
  };

  renderUploadingImgOrBtn = ({ image, buttons, audios }, isShortMsg) => {
    // const { image, buttons, audios } = this.state.uploadingPhrase;
    return (
      !!(image || buttons?.length || audios?.length) && (
        <div className="row m-0 border-top">
          <div className="col p-3">
            {image && (
              <div className="row">
                <div className="col">
                  <p className="res-new-phrase-img-text">Image</p>
                  <img alt="" height="73" src={getImageUrl(image)} width="73" />
                </div>
              </div>
            )}

            {!!buttons?.length && (
              <div className={`row ${image && "mt-3"}`}>
                <div className="col">
                  <p className="res-new-phrase-img-text">Buttons</p>
                  {this.renderUploadingBtns(buttons)}
                </div>
              </div>
            )}
            {!!audios?.length && (
              <div className="row">
                <div className="col">
                  <p className="res-new-phrase-img-text text-dark">Audio</p>
                  {this.renderUploadingAudios(audios, isShortMsg)}
                </div>
              </div>
            )}
          </div>
        </div>
      )
    );
  };

  renderUploadAudioBtnCont = () => {
    const { selectedChannel, phrase, uploadingPhrase } = this.state;
    const isAudio = ["gnani_voice", "twilio_voice"].includes(selectedChannel);
    return (
      <>
        <div className="col-auto d-flex align-items-center">
          <div
            className={`${
              uploadingPhrase.audios?.length >= 10 && "opacity-25"
            } row align-items-center`}
          >
            <div className="col ps-1">
              <Input
                hidden
                type={"file"}
                multiple={true}
                accept="audio/*"
                id="res-top-img-upload"
                onChange={this.handlePhraseAudioUpload}
                disabled={uploadingPhrase.audios?.length > 10}
              />
              {!(uploadingPhrase.audios?.length >= 10) ? (
                <label
                  className="cursor-pointer row"
                  htmlFor="res-top-img-upload"
                >
                  <div className="col-auto align-items-center m-auto">
                    {getIcon("plus", "#495072", "", 10)}
                  </div>
                  <div className="col-auto d-flex align-items-center">
                    {getIcon("headphones")}
                    <p className="m-0 ms-2">Upload Audio</p>
                  </div>{" "}
                </label>
              ) : (
                <span>{getIcon("plus", "#495072", "", 10)}</span>
              )}
            </div>
          </div>
        </div>
        <div className="col-auto">
          <Button
            className={`${
              uploadingPhrase.audios?.length >= 10 && "opacity-25"
            } d-flex align-items-center`}
            onClick={this.record}
            isDisabled={
              this.state.isMicLoading || uploadingPhrase.audios?.length >= 10
            }
          >
            {this.state.isRecording
              ? getIcon("stop-circle")
              : getIcon("microphone")}
            <p
              className={`${this.state.isRecording && "text-danger"} m-0 ms-2`}
            >
              {this.state.isRecording ? "Stop Recording" : "Record Audio"}
            </p>
          </Button>
        </div>
      </>
    );
  };

  renderUploadMsgAudioBtnCont = () => {
    const { uploadingShortMsg } = this.state;
    return (
      <>
        <div className="col-auto d-flex align-items-center">
          <div
            className={`${
              uploadingShortMsg.audios?.length >= 10 && "opacity-25"
            } row align-items-center`}
          >
            <div className="col ps-1">
              <Input
                hidden
                type={"file"}
                multiple={true}
                accept="audio/*"
                id="res-top-msg-upload-audio"
                onChange={this.handleMsgAudioUpload}
                disabled={uploadingShortMsg.audios?.length > 10}
              />
              {!(uploadingShortMsg.audios?.length >= 10) ? (
                <label
                  className="cursor-pointer row"
                  htmlFor="res-top-msg-upload-audio"
                >
                  <div className="col-auto align-items-center m-auto">
                    {getIcon("plus", "#495072", "", 10)}
                  </div>
                  <div className="col-auto d-flex align-items-center">
                    {getIcon("headphones")}
                    <p className="m-0 ms-2">Upload Audio</p>
                  </div>
                </label>
              ) : (
                <span>{getIcon("plus", "#495072", "", 10)}</span>
              )}
            </div>
          </div>
        </div>
        <div className="col-auto">
          <Button
            className={`${
              uploadingShortMsg.audios?.length >= 10 && "opacity-25"
            } d-flex align-items-center`}
            onClick={this.msgRecord}
            isDisabled={
              this.state.isMsgMicLoading ||
              uploadingShortMsg.audios?.length >= 10
            }
          >
            {this.state.isMsgRecording
              ? getIcon("stop-circle")
              : getIcon("microphone")}
            <p
              className={`${
                this.state.isMsgRecording && "text-danger"
              } m-0 ms-2`}
            >
              {this.state.isMsgRecording ? "Stop Recording" : "Record Audio"}
            </p>
          </Button>
        </div>
      </>
    );
  };

  renderAddNewPhraseInpCont = () => {
    const {
      selectedChannel,
      phrase,
      uploadingPhrase,
      shortMsg,
      uploadingShortMsg,
    } = this.state;

    const pLen = phrase?.nonTrained?.length;

    // const isSelected = this.state.channels.find((ch) => ch.isSelected);

    const isImage = [
      "whatsapp",
      "twilio_whatsapp",
      "gnani_chat",
      "telegram",
      "slack",
      "microsoft_botframework",
    ].includes(selectedChannel);

    const isBtn = [
      "gnani_chat",
      "telegram",
      "slack",
      "microsoft_botframework",
    ].includes(selectedChannel);

    const isAudio = ["gnani_voice", "twilio_voice"].includes(selectedChannel);
    return (
      <div className="col mx-2">
        <div
          className={` ${
            !(uploadingPhrase.image || uploadingPhrase.buttons.length) &&
            "align-items-center"
          }`}
        >
          <div className="col utterence-sentence-add-section mb-2 ms-1">
            <div className="row m-0 align-items-center pe-2">
              <div className="col row m-0">
                <Input
                  isLiteration
                  readOnly={
                    this.props.permissions.responses.modify !== "enabled"
                  }
                  value={phrase.newPhraseName}
                  className="utterence-sentence-inp col"
                  onChange={this.handleAddNewPhrase}
                  onKeyPress={this.handleAddNewPhrase}
                  placeholder="Type other ways of response"
                  style={{
                    minWidth: "15rem",
                  }}
                />
                {isAudio && (
                  <div
                    style={{
                      opacity: `${
                        !!uploadingPhrase.audios.length ? "1" : "0.4"
                      }`,
                    }}
                    className={`col-auto align-items-center m-auto ${
                      !!uploadingPhrase.audios.length && "cursor-pointer"
                    }`}
                    onClick={() =>
                      !!uploadingPhrase.audios.length &&
                      this.props.permissions.responses.modify === "enabled" &&
                      this.handleAddNewPhrase(null, true)
                    }
                  >
                    {svgIcons("caret-right", "", "fs-3")}
                  </div>
                )}
              </div>
            </div>
            {this.props.permissions.responses.modify === "enabled" &&
              this.renderUploadingImgOrBtn(uploadingPhrase)}
          </div>
          {!!pLen && (
            <div
              className="row m-auto align-items-center"
              style={{ background: "#F2F2F2" }}
            >
              {isImage &&
                this.props.permissions.responses.modify === "enabled" && (
                  <div
                    className={`col-auto ps-1 ${
                      !isImage && "isDisabled isDisabled-padding"
                    }`}
                  >
                    <div className="row align-items-center">
                      <div className="col">
                        <Input
                          accept="image/*"
                          hidden
                          id="res-top-img-upload"
                          onChange={(e) => this.handlePhraseImgUpload(e)}
                          type={isImage ? "file" : "text"}
                        />
                        <label
                          className="cursor-pointer row"
                          htmlFor="res-top-img-upload"
                        >
                          <div className="mb-1 col-auto align-items-center m-auto">
                            {getIcon("plus", "#495072", "", 10)}
                          </div>
                          <div className="col-auto d-flex align-items-center">
                            <i className="fas fa-image me-2" />
                            <span>Image</span>
                          </div>{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              {isBtn && (
                <div className={`col-auto ${!isBtn && "isDisabled"}`}>
                  <DropDown
                    id="addBtnToNewPhrase"
                    actionButton={this.renderDropDownBtnActionContainer}
                    dropdownList={this.renderDropDownListBtnContainer}
                    isCustomDropDownList
                    isCustomStyle
                    toggle={(e) => {
                      // console.log(e)
                    }}
                    disabled={
                      !isBtn ||
                      this.props.permissions.responses.modify !== "enabled"
                    }
                  />
                </div>
              )}
              {isAudio &&
                this.state.isAddPhraseAudio === null &&
                this.props.permissions.responses.modify === "enabled" &&
                this.renderUploadAudioBtnCont()}
            </div>
          )}
        </div>
      </div>
    );
  };

  renderAddNewMsgInpCont = (isShortMsg) => {
    const { selectedChannel, uploadingPhrase, shortMsg, uploadingShortMsg } =
      this.state;

    const pLen = shortMsg?.nonTrained?.length;

    // const isSelected = this.state.channels.find((ch) => ch.isSelected);

    const isImage = [
      "whatsapp",
      "twilio_whatsapp",
      "gnani_chat",
      "telegram",
      "slack",
      "microsoft_botframework",
    ].includes(selectedChannel);

    const isBtn = [
      "gnani_chat",
      "telegram",
      "slack",
      "microsoft_botframework",
    ].includes(selectedChannel);

    const isAudio = ["gnani_voice", "twilio_voice"].includes(selectedChannel);
    return (
      <div className="col mx-2">
        <div
          className={` ${
            !(uploadingShortMsg.image || uploadingShortMsg.buttons.length) &&
            "align-items-center"
          }`}
        >
          <div className="col utterence-sentence-add-section mb-2 ms-1">
            <div className="row m-0 align-items-center pe-2">
              <div className="col row m-0">
                <Input
                  isLiteration
                  readOnly={
                    this.props.permissions.responses.modify !== "enabled"
                  }
                  value={shortMsg.newPhraseName}
                  className="utterence-sentence-inp col"
                  onChange={this.handleAddNewShortMsg}
                  onKeyPress={this.handleAddNewShortMsg}
                  placeholder="Type other ways of response"
                  style={{
                    minWidth: "15rem",
                  }}
                />
                {isAudio &&
                  this.props.permissions.responses.modify === "enabled" && (
                    <div
                      style={{
                        opacity: `${
                          !!uploadingShortMsg.audios.length ? "1" : "0.4"
                        }`,
                      }}
                      className={`col-auto align-items-center m-auto ${
                        !!uploadingShortMsg.audios.length && "cursor-pointer"
                      }`}
                      onClick={() =>
                        !!uploadingShortMsg.audios.length &&
                        this.handleAddNewShortMsg(null, true)
                      }
                    >
                      {svgIcons("caret-right", "", "fs-3")}
                    </div>
                  )}
              </div>
            </div>
            {isShortMsg &&
              this.props.permissions.responses.modify === "enabled" &&
              this.renderUploadingImgOrBtn(uploadingShortMsg, isShortMsg)}
          </div>
          {selectedChannel !== "Default" && (
            <div
              className="row m-auto align-items-center"
              style={{ background: "#F2F2F2" }}
            >
              {isImage &&
                this.props.permissions.responses.modify === "enabled" && (
                  <div
                    className={`col-auto ps-1 ${
                      !isImage && "isDisabled isDisabled-padding"
                    }`}
                  >
                    <div className="row align-items-center">
                      <div className="col">
                        <Input
                          accept="image/*"
                          hidden
                          id="msg-top-img-upload"
                          onChange={(e) => this.handlePhraseImgUpload(e, true)}
                          type={isImage ? "file" : "text"}
                        />
                        <label
                          className="cursor-pointer row"
                          htmlFor="msg-top-img-upload"
                        >
                          <div className="mb-1 col-auto align-items-center m-auto">
                            {getIcon("plus", "#495072", "", 10)}
                          </div>
                          <div className="col-auto d-flex align-items-center">
                            <i className="fas fa-image me-2" />
                            <span>Image</span>
                          </div>{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              {isBtn && (
                <div className={`col-auto ${!isBtn && "isDisabled"}`}>
                  <DropDown
                    id="addBtnToNewMsg"
                    actionButton={this.renderDropDownBtnActionContainer}
                    dropdownList={() =>
                      this.renderDropDownListBtnContainer(isShortMsg)
                    }
                    isCustomDropDownList
                    isCustomStyle
                    toggle={(e) => {
                      // console.log(e)
                    }}
                    disabled={
                      !isBtn ||
                      this.props.permissions.responses.modify !== "enabled"
                    }
                  />
                </div>
              )}
              {isAudio &&
                isShortMsg &&
                this.state.isAddMsgAudio === null &&
                this.props.permissions.responses.modify === "enabled" &&
                this.renderUploadMsgAudioBtnCont()}
            </div>
          )}
        </div>
      </div>
    );
  };

  getDeleteModal = (action, index, isButton, obj) => {
    let { shortMsg, phrase } = this.state;
    return (
      <DeleteConfirmation
        isButton={isButton}
        open={
          action === "Delete Image"
            ? this.state.imgModal
            : action === "msg_phrase"
            ? this.state.msgModal
            : action === "Delete_msg_Image"
            ? this.state.delMsgImgModal
            : this.state.modal
        }
        btnClassName={"res-phrase-delete-btn"}
        delete={() => {
          if (action === "phrase") {
            return this.handlePharseDelete(index, obj);
          } else if (action === "delete_btn") {
            this.handlePhraseDelImgOrBtn(index, "buttons");
          } else if (action === "Delete Image") {
            this.handlePhraseDelImgOrBtn(index, "image");
          } else if (action === "delete_audio") {
            let mainIndex = phrase.nonTrained.findIndex(
              (ele) => JSON.stringify(ele) === JSON.stringify(obj)
            );
            let dataObj = {
              response_type: "utterances",
              utterance_name: this.state.response.resName,
              file_names_list: this.state.isDelete.selectedBtns,
              audio_file_index: mainIndex,
            };
            this.handlePhraseAudioDel(false, dataObj);
          } else if (action === "msg_phrase") {
            this.handleMsgDelete(index, obj);
          } else if (action === "delete_msg_audio") {
            let msgIndex = shortMsg.nonTrained.findIndex(
              (ele) => JSON.stringify(ele) === JSON.stringify(obj)
            );
            let dataObj = {
              response_type: "utterances",
              utterance_name: this.state.response.resName,
              file_names_list: this.state.isMsgDelete.selectedBtns,
              audio_file_index: msgIndex,
            };
            this.handlePhraseAudioDel(true, dataObj);
          } else if (action === "delete_msg_btn") {
            this.handlePhraseDelImgOrBtn(index, "msg_buttons", true);
          } else if (action === "Delete_msg_Image") {
            this.handlePhraseDelImgOrBtn(index, "image", true);
          } else {
            this.handleResDelete();
          }
        }}
        buttons={() => (
          <div className="row">
            <div className="col-auto pe-0">{getIcon("delete", "white")}</div>
            <div className="col">
              {isButton === "isAudio" ? "Delete" : "Delete button"}
            </div>
          </div>
        )}
      />
    );
  };

  handleModalInpChange = ({ target }) => {
    const modalInps = { ...this.state.modalInps };
    const { name, value } = target;
    modalInps[name] = value;
    this.setState({ modalInps });
  };

  handleModalAddBtn = (isShortMsg) => {
    const {
      phrase,
      modalInps,
      selectedChannelPhrase,
      selectedChannelMsg,
      shortMsg,
    } = this.state;
    const { title, value } = modalInps;
    const nonTrained = [...selectedChannelPhrase.nonTrained];
    const msgNonTrained = [...selectedChannelMsg.nonTrained];

    if (isShortMsg)
      msgNonTrained[this.msgIndex].buttons.push({ title, payload: value });
    else nonTrained[this.phraseIndex].buttons.push({ title, payload: value });

    // let temp = {
    //   newPhraseName: "",
    //   nonTrained: [...phrase.nonTrained, ...nonTrained],
    //   trained: phrase.trained,
    // };

    this.setState(
      {
        phrase,
        shortMsg,
        isAddBtnModal: false,
        isMsgBtnModal: false,
        modalInps: { title: "", value: "" },
      },
      () => {
        this.phraseIndex = "";
        this.msgIndex = "";
        isShortMsg ? this.updatePhrase(true) : this.updatePhrase();
      }
    );
  };

  returnShareDropDownList = (isShortMsg) => {
    let { shareListCopyMsg, shareListCopy } = this.state;

    if (isShortMsg) {
      return shareListCopyMsg
        .map((e) => {
          e.isSelected
            ? (e.icon = svgIcons("check-box-marked"))
            : (e.icon = svgIcons("check-box-outline-blank"));
          return e;
        })
        .sort((x) => (x.isUsed ? 1 : -1));
    } else {
      return shareListCopy
        .map((e) => {
          e.isSelected
            ? (e.icon = svgIcons("check-box-marked"))
            : (e.icon = svgIcons("check-box-outline-blank"));
          return e;
        })
        .sort((x) => (x.isUsed ? 1 : -1));
    }
  };

  renderShareDropDown = (isShortMsg) => {
    let {
      shareList,
      shareListCopy,
      phrasesToDelete,
      msgsToDelete,
      shareListCopyMsg,
    } = this.state;

    if (isShortMsg) {
      return (
        <DropDown
          isContent={true}
          globalIntent={true}
          ref={this.shareMsgRef}
          disabled={!msgsToDelete.length}
          btnClassName={"new-phrase-dlt-btn"}
          dropdownList={this.returnShareDropDownList(true)}
          actionButton={() => <span>Share</span>}
          onActionItemClick={(m) => {
            this.shareMsgRef.current.state = true;
            shareListCopyMsg.map(
              (e) => e.label === m.label && (e.isSelected = !e.isSelected)
            );
            this.setState({ shareListCopyMsg });
          }}
          isInput={
            <div className="px-2 my-2 d-flex align-items-center">
              <Input
                className="p-2 share-drop-down-search"
                placeholder="Search for channels"
                onChange={(e) => {
                  let temp = shareList.filter((ele) =>
                    ele.label.toLowerCase().includes(e.target.value)
                  );
                  this.setState({ shareListCopyMsg: temp });
                }}
              />
              {svgIcons("search", "", "share-drop-down-search-icon")}
            </div>
          }
          isButtons={
            <div className="row m-0 justify-content-around mt-3">
              <Button
                className="col-auto btn share-drop-down-cancel"
                onClick={this.handleCancelShareDropDown}
              >
                Cancel
              </Button>
              <Button
                className="col-auto btn share-drop-down-confirm"
                onClick={this.handleShareSelectedMsgs}
              >
                Confirm
              </Button>
            </div>
          }
        />
      );
    } else {
      return (
        <DropDown
          isContent={true}
          globalIntent={true}
          ref={this.sharePhraseRef}
          disabled={!phrasesToDelete.length}
          btnClassName={"new-phrase-dlt-btn"}
          dropdownList={this.returnShareDropDownList(false)}
          actionButton={() => <span>Share</span>}
          onActionItemClick={(m) => {
            this.sharePhraseRef.current.state = true;
            shareListCopy.map(
              (e) => e.label === m.label && (e.isSelected = !e.isSelected)
            );
            this.setState({ shareListCopy });
          }}
          isInput={
            <div className="px-2 my-2 d-flex align-items-center">
              <Input
                className="p-2 share-drop-down-search"
                placeholder="Search for channels"
                onChange={(e) => {
                  let temp = shareList.filter((ele) =>
                    ele.label.toLowerCase().includes(e.target.value)
                  );
                  this.setState({ shareListCopy: temp });
                }}
              />
              {svgIcons("search", "", "share-drop-down-search-icon")}
            </div>
          }
          isButtons={
            <div className="row m-0 justify-content-around mt-3">
              <Button
                className="col-auto btn share-drop-down-cancel"
                onClick={this.handleCancelShareDropDown}
              >
                Cancel
              </Button>
              <Button
                className="col-auto btn share-drop-down-confirm"
                onClick={this.handleShareSelectedPhrases}
              >
                Confirm
              </Button>
            </div>
          }
        />
      );
    }
  };

  renderDelShareBtn = (isShortMsg) => {
    let { phrasesToDelete, msgsToDelete } = this.state;
    return (
      <>
        <div className="col-auto response-font">
          {isShortMsg ? "Messages" : "Responses"}
        </div>
        <div className="col-auto row m-0 pe-1">
          {isShortMsg ? (
            <Button
              isDisabled={msgsToDelete.length < 1}
              styles={{
                opacity: `${!!msgsToDelete.length ? "1" : "0.4"}`,
              }}
              onClick={() => this.handleMsgDelete(msgsToDelete, "", true)}
              className={"col-auto me-3 new-phrase-dlt-btn"}
            >
              Delete
            </Button>
          ) : (
            <Button
              isDisabled={phrasesToDelete.length < 1}
              styles={{
                opacity: `${!!phrasesToDelete.length ? "1" : "0.4"}`,
              }}
              onClick={() => this.handlePharseDelete(phrasesToDelete, "", true)}
              className={"col-auto me-3 new-phrase-dlt-btn"}
            >
              Delete
            </Button>
          )}
          <div className="col-auto pe-1 ps-0">
            {isShortMsg
              ? this.renderShareDropDown(true)
              : this.renderShareDropDown(false)}
          </div>
        </div>
      </>
    );
  };

  renderMsgs() {
    const {
      channels,
      isMsgDelete,
      onMsgHover,
      msgId,
      selectedChannelMsg,
      msgAudios,
    } = this.state;

    return selectedChannelMsg?.nonTrained?.map((p, i) => (
      <div className="row m-0 mb-3">
        <div
          className="col-auto me-2 pe-0"
          onClick={() =>
            this.props.permissions.responses.modify === "enabled" &&
            this.handleSelectPhrase(p, true)
          }
        >
          {p?.isSelected
            ? svgIcons("check-box-marked")
            : svgIcons("check-box-outline-blank")}
        </div>
        <div
          className={`col ps-0 utterence-sentence-bottom-panel${
            onMsgHover === i ? " shadow-sm" : ""
          }`}
          key={i}
          onMouseEnter={() => this.setState({ onMsgHover: i })}
          onMouseLeave={() =>
            this.setState({
              onMsgHover: false,
              isMsgDelete: {
                isShown: false,
                index: "",
                selectedBtns: [],
              },
            })
          }
        >
          <div
            className={`row m-0 ps-2 align-items-center ${
              (p.image || !!p.buttons?.length || msgAudios?.length) &&
              "border-bottom"
            }`}
            style={{
              background: `${p?.isSelected ? "#F2F4FF" : "#fafafa"}`,
              border: `${p?.isSelected ? "1px solid #46569940" : ""}`,
            }}
          >
            <div className="col py-2">
              <div className="row">
                <div className="col-auto u-bottom-panel-header ps-2 u-b-p-header-key">
                  Phrase:
                </div>
                <div className="col u-bottom-panel-header">
                  <Input
                    isLiteration
                    className="u-bottom-panel-inp"
                    readOnly={
                      this.props.permissions.responses.modify !== "enabled"
                    }
                    onBlur={(e) =>
                      this.handlePhraseEdit(e, i, p, "onBlur", true)
                    }
                    onChange={(e) =>
                      this.handlePhraseEdit(e, i, p, false, true)
                    }
                    onKeyPress={(e) =>
                      this.handlePhraseEdit(e, i, p, false, true)
                    }
                    value={p.text}
                  />
                </div>
              </div>
            </div>
            <div
              className={`col-auto p-0 align-items-center ${
                onMsgHover === i || msgId === i ? "" : " d-none"
              }`}
              ref={this.msgIdRef}
              onClick={() => this.setState({ msgId: i })}
            >
              {isMsgDelete.isShown && isMsgDelete.index === i ? (
                <div className="row align-items-center">
                  <div className="col">
                    <p className="res-phrase-delete-imp-text">
                      * You can Select multiple buttons
                    </p>
                  </div>
                  <div className="col">
                    {["gnani_voice", "twilio_voice"].includes(p.channel)
                      ? this.getDeleteModal("delete_msg_audio", i, "isAudio", p)
                      : this.getDeleteModal("delete_msg_btn", i, "isButton")}
                  </div>
                </div>
              ) : (
                <DropDown
                  actionButton={() => getIcon("verticalOptions")}
                  direction="left"
                  btnClassName="px-1"
                  dropdownList={this.handlePhraseActionList(p, i, true)}
                  onActionItemClick={(m) => this.handlePhraseAction(m, i)}
                  dropDownMenuMinWidth="12rem"
                />
              )}
            </div>
          </div>
          {this.renderBottomPanel(p, i, true)}
        </div>
        {["gnani_voice", "twilio_voice"].includes(p.channel) &&
          this.state.isAddMsgAudio === i && (
            <div className="row m-0">{this.renderUploadMsgAudioBtnCont()}</div>
          )}
      </div>
    ));
  }

  render() {
    const {
      response,
      isAddBtnModal,
      isMsgImgModal,
      isMsgBtnModal,
      modalInps,
      isAddImgModal,
      channels,
      selectedChannel,
    } = this.state;

    return (
      <div className="container-fluid h-100">
        <div className="row h-100">
          <BotLayoutLeftPanel
            onChange={this.handleAddNewRes}
            onKeyPress={this.handleAddNewRes}
            onMenuSelect={(m) =>
              this.props.permissions.responses.list === "enabled" &&
              this.handleResSelect(m)
            }
            sideBarList={response.nonTrained}
            isResponse={true}
            title="Response"
            trainedIntent={response.trained}
            value={response.newResName}
            getResponses={this.getResponses}
            BulkImport={this.bulkImportComp}
            onClickImport={this.onClickImport}
            addBtnHide={this.props.permissions.responses.add}
            disableMultiDelete={
              this.props.permissions.responses.delete === "disabled"
            }
          />
          <BotLayoutContent
            actions={[
              {
                label: "Delete",
                icon: this.getDeleteModal(),
                isUsed: this.props.permissions.responses.delete === "disabled",
              },
            ]}
            isSelected={response.isResSelected}
            onActionClick={() => {
              this.setState({ modal: true });
              setTimeout(() => {
                this.setState({ modal: false });
              }, 100);
            }}
            isFaq
            responsePage={{
              isAvail: true,
              resPgCont: this.renderAddNewPhraseInpCont,
            }}
            titleReadOnly={
              this.props.permissions.responses.modify === "disabled"
            }
            titleOnChange={this.handleResEdit}
            titleValue={response.resName}
            horizontalMenu={channels}
            selectedChannel={selectedChannel}
            deleteChannel={this.deleteChannel}
            handleNavMenuSelect={this.handleNavMenuSelect}
            navBarMenu={this.state.navBarMenu}
            isShowContent={this.props.permissions.intents.content === "enabled"}
            navDropDownDisable={
              this.props.permissions.responses.modify !== "enabled" ||
              !this.state.selectedChannelPhrase.nonTrained?.length
            }
          >
            {response.isResSelected &&
              this.props.permissions.responses.content === "enabled" && (
                <>
                  <div
                    className="w-100"
                    style={{ height: "calc(50% - .5rem)" }}
                  >
                    <div className="mb-3 w-100">
                      {this.renderAddNewPhraseInpCont()}
                    </div>

                    <div className="row m-0 w-100 justify-content-between mb-3">
                      {this.props.permissions.responses.modify === "enabled" &&
                        this.renderDelShareBtn()}
                    </div>
                    <div
                      className="overflow-auto w-100"
                      style={{ height: "calc(100% - 11rem)" }}
                    >
                      {this.renderPhrases()}
                    </div>
                  </div>
                  <div
                    className="w-100"
                    style={{ height: "calc(50% - .5rem)" }}
                  >
                    <p className="response-font ms-2 mt-3">Short Messages</p>
                    <div className="mb-3 w-100">
                      {this.renderAddNewMsgInpCont(true)}
                    </div>
                    <div className="row m-0 justify-content-between mb-3 w-100">
                      {this.renderDelShareBtn(true)}
                    </div>
                    <div
                      className="overflow-auto w-100"
                      style={{ height: "calc(100% - 12.7rem)" }}
                    >
                      {this.renderMsgs()}
                    </div>
                  </div>
                </>
              )}
          </BotLayoutContent>

          <BotLayoutRightPanel />
        </div>
        <Modal
          addNewModalOpen={isAddImgModal || isMsgImgModal}
          className="duplicate-modal-container"
          isHeader={false}
          bodyClassName="p-0"
          modalSize="md"
        >
          <div className="mb-3">
            {isMsgImgModal ? (
              <Input
                accept="image/*"
                className="form-control form-control-sm"
                id="formFileSm"
                onChange={(e) => this.handlePhraseImgUpload(e, true)}
                type="file"
              />
            ) : (
              <Input
                accept="image/*"
                className="form-control form-control-sm"
                id="formFileSm"
                onChange={(e) => this.handlePhraseImgUpload(e)}
                type="file"
              />
            )}
          </div>
          <div className="row mt-4">
            <div className="col text-end">
              <Button
                className="btn-outline-light rounded-3 px-4 py-2 btn-secondary"
                styles={{ width: "10rem" }}
                onClick={() =>
                  this.setState({ isAddImgModal: false, isMsgImgModal: false })
                }
              >
                Cancel
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          addNewModalOpen={isAddBtnModal || isMsgBtnModal}
          className="duplicate-modal-container"
          isHeader={false}
          bodyClassName="p-0"
          modalSize="md"
        >
          <div className="row mb-3 align-items-center">
            <div className="col-auto u-s-dropdown-list-btn-title">Title</div>
            <div className="col" style={{ width: "calc(100% - 4rem)" }}>
              <Input
                autoFocus={true}
                className="u-s-dropdown-list-inp"
                name="title"
                onChange={this.handleModalInpChange}
                placeholder="Button title"
                value={modalInps.title}
              />
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-auto u-s-dropdown-list-btn-title">Value</div>
            <div className="col" style={{ width: "calc(100% - 4rem)" }}>
              <Input
                className="u-s-dropdown-list-inp"
                name="value"
                onChange={this.handleModalInpChange}
                placeholder="Button value"
                value={modalInps.value}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col text-end">
              <Button
                className="btn-outline-light rounded-3 px-4 py-2 btn-secondary"
                styles={{ width: "10rem" }}
                onClick={() =>
                  this.setState({ isAddBtnModal: false, isMsgBtnModal: false })
                }
              >
                Cancel
              </Button>
            </div>
            <div className="col-auto">
              <Button
                className="duplicate-modal-confirm-btn"
                isDisabled={!modalInps.title || !modalInps.value}
                onClick={() => this.handleModalAddBtn(isMsgBtnModal)}
              >
                Add Button
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default function Responses() {
  const { setNotifications } = useContext(NotificationContext);
  const { getUserDetails } = useContext(UserContext);

  return (
    <Response
      setNotifications={setNotifications}
      permissions={getUserDetails().feature_permission}
    />
  );
}