import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import svgIcons from "../../services/icon.service";
import "./modal.component.scss";

export default function AddNewModal({
  addNewModalOpen,
  toggle,
  children,
  footerContent,
  title,
  className = "modal-component",
  isHeader = true,
  modalSize = "lg",
  bodyClassName,
  headerClassName,
  noCloseBtn,
  maxwidth
}) {
  return (
    <Modal
      size={modalSize}
      className={className}
      isOpen={addNewModalOpen}
      toggle={toggle}
      style={{ maxWidth: maxwidth }}
    >
      {isHeader && (
        <ModalHeader
          className={`pb-0 border-0 ${!noCloseBtn ? "justify-content-between" : "justify-content-center p-5"} ${headerClassName} title-font`}
          style={{ borderBottom: "1px solid" }}
          close={!noCloseBtn &&
            <button className="btn font-weight-bold" onClick={toggle}>
              {svgIcons("close-circle")}
            </button>
          }
        >
          {title}
        </ModalHeader>
      )}

      <ModalBody className={`${isHeader && "pt-0"} ${bodyClassName}`}>
        {children}
      </ModalBody>
      {footerContent && (
        <ModalFooter className="border-0 modal-footer">
          {footerContent}
        </ModalFooter>
      )}
    </Modal>
  );
}
