export {
  getToken,
  setToken,
  getUserName,
  setUserName,
  setUserDetails,
  getRefreshToken,
  getUserDetails,
};

function setUserDetails(details) {
  sessionStorage.setItem(btoa("loggedInUser"), btoa(details));
}

function getUserDetails() {
  try {
    return JSON.parse(atob(sessionStorage.getItem(btoa("UserDetails"))));
  } catch (e) {
    return null;
  }
}

function getToken() {
  try {
    return atob(sessionStorage.getItem(btoa("loggedInToken")));
  } catch (e) {
    return null;
  }
}

function getRefreshToken() {
  try {
    const userDetails = getUserDetails();
    return userDetails.refresh_token;
  } catch (e) {
    return null;
  }
}

function setToken(token) {
  sessionStorage.setItem(btoa("loggedInToken"), btoa(token));
}

function getUserName() {
  return sessionStorage.getItem(btoa("userName"));
}

function setUserName(name) {
  sessionStorage.setItem(btoa("userName"), name);
}
