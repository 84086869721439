"add_user";

import botService from "../bot.service";
import baseUrl from "../baseURL.service";
import { getToken } from "../authUtil";
import apiServices from "../api.services";

const fetchData = (url, options) => {
  return apiServices.fetchData(url, { ...options });
};

const addUser = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(data),
  };
  return fetchData(`${baseUrl}/add_user`, requestOptions);
};

const activateUser = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(data),
  };
  return fetchData(`${baseUrl}/activate_user`, requestOptions);
};

const deActivateUser = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(data),
  };
  return fetchData(`${baseUrl}/deactivate_user`, requestOptions);
};

const removeUser = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(data),
  };
  return fetchData(`${baseUrl}/delete_user`, requestOptions);
};

const getUserList = () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  return fetchData(`${baseUrl}/list_users`, requestOptions);
};

const getUserBotList = () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  return fetchData(`${baseUrl}/list_user_bots`, requestOptions);
};

const getBotActiveStatus = () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  return fetchData(`${baseUrl}/get_bots_active_status`, requestOptions);
};

const getUserBots = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(data),
  };
  return fetchData(`${baseUrl}/get_user_bots`, requestOptions);
};

const orgBotsList = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(data),
  };
  return fetchData(`${baseUrl}/get_org_bots`, requestOptions);
};

const deleteUserBots = (data) => {
  const reqOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(data),
  };
  return fetchData(`${baseUrl}/delete_bot`, reqOptions);
};

const addShareBots = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(data),
  };
  return fetchData(`${baseUrl}/share_bot`, requestOptions);
};

const organisationSettings = {
  addUser,
  removeUser,
  getBotActiveStatus,
  getUserList,
  getUserBotList,
  activateUser,
  deActivateUser,
  getUserBots,
  orgBotsList,
  deleteUserBots,
  addShareBots,
};

export default organisationSettings;
