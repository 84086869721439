import React, { Children, useContext, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import svgIcons from "../services/icon.service";
import DropDown from "./fields/dropDown/dropDown.component";
import { NotificationContext } from "../contexts/notficationContext";
import Buttons from "./fields/button/button.component";
import kbServices from "../services/api.services/kbApi.service";

const CreateKbBotModal = (props) => {
  const [isModal, setModal] = useState(props.open);
  const [onClickNext, setOnClickNext] = useState(false);
  const [depth, setDepth] = useState(null);
  const [isValidUrl, setIsValidUrl] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [openApiConfig, setOpenApiConfig] = useState({
    OPENAI_API_KEY: "",
    OPENAI_API_BASE: "",
    OPENAI_API_TYPE: "",
    OPENAI_API_VERSION: "",
    OPENAI_DEPLOYMENT_GENERATOR: "",
    OPENAI_DEPLOYMENT_EMBEDDING: "",
  });
  const [websiteUrl, setWebsiteUrl] = useState("");
  const { setNotifications } = useContext(NotificationContext);
  const openApiConfigLabel = [
    { label: "OpenAI API Key", item: "OPENAI_API_KEY" },
    { label: "OpenAI API Base", item: "OPENAI_API_BASE" },
    { label: "OpenAI API Type", item: "OPENAI_API_TYPE" },
    { label: "OpenAI API Version", item: "OPENAI_API_VERSION" },
    {
      label: "OpenAI Deployment Generator",
      item: "OPENAI_DEPLOYMENT_GENERATOR",
    },
    {
      label: "OpenAI Deployment Embedding",
      item: "OPENAI_DEPLOYMENT_EMBEDDING",
    },
  ];

  const toggle = () => {
    setModal(!isModal);
    props.toggle();
  };

  const submit = () => {
    if (isValidUrl) {
      let body = {
        website_url: websiteUrl,
        organization_id: props.org_id,
        depth: depth,
        openapi_config: openApiConfig,
      };
      props.submitUrl(body);
      props.submit();
    } else {
      setNotifications({
        color: "info",
        message: "Enter valid URL",
      });
    }
  };

  const validateUrl = (inputUrl) => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(inputUrl);
  };

  const getValidateOpenapiConfig = async (body) => {
    try {
      const r = await kbServices.getValidateOpenapiConfig(body);

      if (r.status === "success") {
        setIsDisabled(false);
        setNotifications({
          color: "success",
          message: "Validation successfull",
        });
        setOnClickNext(true);
      } else {
        if (r.gen.status === "failure") {
          setNotifications({
            color: "danger",
            message: `Generator Error: ${r.gen.message}`,
          });
        }
        if (r.embed.status === "failure") {
          setNotifications({
            color: "danger",
            message: `Embedding Error: ${r.embed.message}`,
          });
        }
        setIsDisabled(false);
      }
    } catch (e) {
      setNotifications({
        color: "danger",
        message: e.message,
      });
    }
  };

  return (
    <>
      <Modal
        color="black"
        isOpen={isModal}
        style={{ marginTop: "20vh" }}
        toggle={toggle}
        size="lg"
      >
        <ModalHeader className="d-flex justify-content-center">
          {props.heading}
        </ModalHeader>
        <ModalBody className=" bot-del-btn-font  pt-2 px-4 mx-2">
          <p className="text-center py-2">{props.subheading}</p>
          <form>
            {onClickNext ? (
              <>
                <div className="mb-3 ms-2 me-3 row">
                  <label className="col-form-label col-4">Website Link :</label>
                  <input
                    type="text"
                    className=" col"
                    style={{
                      background: "#fff",
                      border: "1px solid rgba(0, 0, 0, 0.11)",
                      borderRadius: "6px",
                    }}
                    placeholder={"Enter Url link"}
                    value={websiteUrl}
                    onChange={(e) => {
                      setWebsiteUrl(e.target.value);
                      setIsValidUrl(validateUrl(e.target.value));
                    }}
                  />
                </div>
                <div className="mb-3 ms-2 me-3 row">
                  <label className="col-form-label col-4">Depth :</label>
                  <div className="col px-0">
                    <DropDown
                      btnClassName="recrawl-depth-dropdown"
                      dropdownList={[
                        { label: 1 },
                        { label: 2 },
                        { label: 3 },
                        { label: 4 },
                        { label: 5 },
                      ]}
                      actionButton={() => (
                        <div className="d-flex justify-content-between align-items-center depth-dropdown-text">
                          <div>{depth === null ? "Select Depth" : depth}</div>
                          {svgIcons("angle-down")}
                        </div>
                      )}
                      onActionItemClick={(l) => {
                        setDepth(l.label);
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              openApiConfigLabel.map((o, i) => {
                return (
                  <div className="mb-3 ms-2 me-3 row" key={i}>
                    <label className="col-form-label col-4">{o.label} :</label>
                    <input
                      type="text"
                      className=" col"
                      style={{
                        background: "#fff",
                        border: "1px solid rgba(0, 0, 0, 0.11)",
                        borderRadius: "6px",
                      }}
                      placeholder={`Enter ${o.label}`}
                      value={openApiConfig[o.item]}
                      onChange={(e) => {
                        setOpenApiConfig((prevstate) => {
                          let data = { ...prevstate };
                          data[o.item] = e.target.value;
                          return data;
                        });
                      }}
                      required
                    />
                  </div>
                );
              })
            )}
          </form>
        </ModalBody>
        <ModalFooter className="bg-white border-0 modal-footer d-flex justify-content-center">
          <Buttons
            className=" w-auto rounded-1 py-2 modal-btn-cancel"
            onClick={() => {
              if (onClickNext) {
                setOnClickNext(false);
              } else {
                toggle();
                props.cancel && props.cancel();
              }
            }}
          >
            {onClickNext ? "Back" : "Cancel"}
          </Buttons>
          <Buttons
            className="w-auto rounded-1 py-2 text-white modal-btn-confirm"
            onClick={() => {
              if (onClickNext) {
                toggle();
                submit();
              } else {
                setIsDisabled(true);
                getValidateOpenapiConfig(openApiConfig);
              }
            }}
            isDisabled={
              onClickNext
                ? !(websiteUrl && depth)
                : isDisabled
                ? true
                : openApiConfig.OPENAI_API_BASE.trim().length === 0 ||
                  openApiConfig.OPENAI_API_KEY.trim().length === 0 ||
                  openApiConfig.OPENAI_API_TYPE.trim().length === 0 ||
                  openApiConfig.OPENAI_API_VERSION.trim().length === 0 ||
                  openApiConfig.OPENAI_DEPLOYMENT_EMBEDDING.trim().length ===
                    0 ||
                  openApiConfig.OPENAI_DEPLOYMENT_GENERATOR.trim().length === 0
            }
          >
            {onClickNext ? "Submit" : "Validate"}
          </Buttons>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CreateKbBotModal;
