import botService from "../bot.service";
import baseUrl from "../baseURL.service";
import { getToken } from "../authUtil";
import apiServices from "../api.services";

const fetchData = (url, options) => {
  return apiServices.fetchData(url, { ...options });
};

const deleteBotAction = (dataObj, isFaq) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };
  return fetchData(
    `${baseUrl}/delete_bot_action/${botService.getSelectedBotId()}?is_faq_action=${
      isFaq ? "yes" : "no"
    }`,
    requestOptions
  );
};

const updateBotAction = (dataObj, isFaq) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };
  return fetchData(
    `${baseUrl}/modify_bot_action/${botService.getSelectedBotId()}?is_faq_action=${
      isFaq ? "yes" : "no"
    }`,
    requestOptions
  );
};

const addBotAction = (body, isFaq) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(body),
  };
  return fetchData(
    `${baseUrl}/add_bot_action/${botService.getSelectedBotId()}?is_faq_action=${
      isFaq ? "yes" : "no"
    }`,
    requestOptions
  );
};

const renameBotAction = (dataObj, isFaq) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };

  return fetchData(
    `${baseUrl}/rename_bot_action/${botService.getSelectedBotId()}?is_faq_action=${
      isFaq ? "yes" : "no"
    }`,
    requestOptions
  );
};

const getActionDetails = (intent, isFaq) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  return fetchData(
    `${baseUrl}/get_action_details/${botService.getSelectedBotId()}?action=${intent}&is_faq_action=${
      isFaq ? "yes" : "no"
    }`,
    requestOptions
  );
};

function getBotActions() {
  let reqOptions = {
    method: "GET",
    headers: {
      token: getToken(),
    },
    mode: "cors",
  };
  return fetchData(
    `${baseUrl}/get_bot_actions_list/${botService.getSelectedBotId()}`,
    reqOptions
  );
}

const validateCodeSyntax = (code) => {
  const reqOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(code),
  };

  return fetchData(
    `${baseUrl}/validate_actions_code/${botService.getSelectedBotId()}`,
    reqOptions
  );
};

const getActionLogs = () => {
  const reqOptions = {
    method: "GET",
    headers: {
      token: getToken(),
      "Content-Type": "application/json",
    },
  };
  return fetchData(
    `${baseUrl}/action_server_logs/${botService.getSelectedBotId()}`,
    reqOptions
  );
};

const actionSerivces = {
  getBotActions,
  getActionDetails,
  renameBotAction,
  deleteBotAction,
  updateBotAction,
  addBotAction,
  validateCodeSyntax,
  getActionLogs,
};

export default actionSerivces;
