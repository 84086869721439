import React, { useContext } from "react";
import {
  Route,
  Redirect,
  Switch,
  // , useLocation
} from "react-router-dom";

import Footer from "../../components/footer/footer";
import Navbar from "../../components/navbar/navbar.component";
import routes from "../../routes";
import { UserContext } from "../../contexts/userContext";

function Admin(props) {
  // const location = useLocation();
  const getRoutes = (routes, permissions) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        if (
          !["hide", "disabled"].includes(
            permissions && permissions.dashboard[prop.key]
          )
        )
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
      } else {
        return null;
      }
    });
  };

  const isHome = () => {
    // const pathNames = ["home", "marketPlace"];
    // return pathNames.some((p) => location.pathname.includes(p));
    return false;
  };

  const { isUserLoggedIn, getUserDetails } = useContext(UserContext);
  isUserLoggedIn(props.history);

  return (
    <React.Fragment>
      <div className="wrapper w-100 h-100 overflow-hidden">
        {/* <Sidebar /> */}
        <div className="main-content w-100 h-100">
          <Navbar {...props} />
          <div
            className="w-100"
            style={{ height: `calc(100% - ${isHome() ? "5" : "3"}.2rem)` }}
          >
            <Switch>
              {getUserDetails().user_type === "admin"
                ? getRoutes(routes, getUserDetails().feature_permission)
                : getRoutes(
                    routes.filter(
                      (comp) => comp.name !== "OrganisationSettings"
                    ),
                    getUserDetails().feature_permission
                  )}{" "}
              <Redirect from="*" to="/admin/home" />
            </Switch>
          </div>
          {isHome() && <Footer fluid />}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Admin;
