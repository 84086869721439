import React from "react";
export const UserContext = React.createContext();
export const UserContextWrapper = (props) => {
  return (
    <UserContext.Provider
      value={{
        setUserDetails: (data) => {
          setStorage("UserDetails", data);
        },
        setUserFlows: (data) => {
          setStorage("FlowDetails", data);
        },
        setSuperAdminDetails: (data) => {
          setStorage("SuperAdminDetails", data);
        },
        getUserDetails: () => {
          return getStorage("UserDetails");
        },
        getUserFlows: () => {
          return getStorage("FlowDetails");
        },
        getSuperAdminDetails: () => {
          return getStorage("SuperAdminDetails");
        },
        selectedBot: () => {
          return getStorage("SelectedBot");
        },
        setSelectedBot: (data) => {
          setStorage("SelectedBot", data);
        },
        isUserLoggedIn,
        isSuperAdminLoggedIn,
        clearStorage,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );

  function clearStorage() {
    sessionStorage.clear();
  }

  function setStorage(key, data) {
    try {
      if (key && data) {
        sessionStorage.setItem(btoa(key), btoa(JSON.stringify(data)));
      }
    } catch (error) {
      /**console.error('User Context Set Storage: ', error);**/
    }
  }

  function getStorage(key) {
    try {
      return JSON.parse(atob(sessionStorage.getItem(btoa(key))));
    } catch (error) {
      /**console.error('User Context Get Storage: ', error);**/ return null;
    }
  }

  function isUserLoggedIn(history, path = "/auth/login") {
    if (
      (history && getStorage("UserDetails") && path !== "/auth/login") ||
      (!getStorage("UserDetails") && path === "/auth/login")
    ) {
      history.push(path);
    }
  }
  function isSuperAdminLoggedIn(history, path = "/super_admin/login") {
    if (
      (history &&
        getStorage("SuperAdminDetails") &&
        path !== "/super_admin/login") ||
      (!getStorage("SuperAdminDetails") && path === "/super_admin/login")
    ) {
      history.push(path);
    }
  }
};
