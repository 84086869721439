import { Component, createRef } from "react";
import { Modal, ModalBody } from "reactstrap";

import Button from "../components/fields/button/button.component";

import baseUrl from "../services/baseURL.service";

import { getToken } from "../services/authUtil";
import { NotificationContext } from "../contexts/notficationContext";

import apiService from "../services/api.services";
import botService from "../services/bot.service";
import svgIcons from "../services/icon.service";

class ChatWindow extends Component {
  static contextType = NotificationContext;
  constructor(props) {
    super(props);
    this.state = {
      botList: [],
      botListModal: false,
      modal: false,
      sideBar: false,
      iFrame: null,
      min: false,
      random: 1,
    };
  }

  componentDidMount() {
    // let bot_id = botService.getSelectedBotId();
    // if (bot_id) {
    //   this.getBotConfig();
    // } else {
    //   this.getBotConfig(this.props.test_bot_ID);
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.botId !== this.props.botId) {
      this.setState({ modal: false, sideBar: false }, () =>
        this.getBotConfig()
      );
    }
  }

  getBotConfig(bot_ID) {
    botService
      .getBotConfig(bot_ID)
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success")
          this.setState(
            { botConfig: response.data, senderID: Date.now() },
            this.getDeploymentDetails()
          );
      })
      .catch((error) => {
        console.error("Failed: ", error);
      });
  }

  getDeploymentDetails() {
    let bot_id = botService.getSelectedBotId() || this.props.test_bot_ID;
    let reqOptions = {
      method: "GET",
      headers: {
        token: getToken(),
      },
    };
    apiService
      .fetchData(`${baseUrl}/deployment_details/${bot_id}`, reqOptions)
      .then((response) => {
        if (response.status === "success") {
          this.setState({ deploymentDetails: response.data });
        } else {
          this.setState({ deploymentDetails: { error: response.message } });
        }
      })
      .catch((error) => {
        console.error("Failed: ", error);
      });
  }

  triggerCall(event) {
    if (event) {
      event.preventDefault();
    }
    let { triggerPhone, triggerName } = this.state;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: getToken(),
      },
      body: JSON.stringify({ phone: triggerPhone, name: triggerName }),
    };

    let bot_id = botService.getSelectedBotId() || this.props.test_bot_ID;
    apiService
      .fetchData(`${baseUrl}/trigger_call/${bot_id}`, requestOptions)
      .then((response) => {
        if (response.status === "success") {
          this.setState({ triggerPhone: "", triggerName: "" });
          this.context.setNotifications({
            color: "success",
            message: response.message,
          });
        } else {
          this.context.setNotifications({
            color: "danger",
            message: response.message,
          });
        }
      })
      .catch((error) => {
        console.error("Failed: ", error);
        this.context.setNotifications({
          color: "danger",
          message: error.message,
        });
      });
  }

  getBotList = async () => {
    try {
      const r = await botService.getBotList();
      this.setState({ botList: r.data, botListModal: true });
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const { isCRM, botConfig, deploymentDetails } = this.props;
    let { botListModal, botList, iFrame, triggerPhone, triggerName } =
      this.state;
    return (
      <>
        {isCRM && (
          <button className="btn btn-gold" onClick={this.getBotList}>
            Call Trigger
          </button>
        )}

        {!isCRM && (
          <Button
            className="intents-play-btn rounded-2 px-3 me-2 "
            onClick={() => {
              if (!this.state.isChatOpen) {
                this.setState({
                  modal: true,
                  random: this.state.random + 1,
                  isChatOpen: true,
                });
                setTimeout(() => {
                  let iFrame = document.getElementById("chat-window");
                  iFrame?.contentDocument?.body?.addEventListener(
                    "click",
                    (e) => {
                      if (
                        [
                          "chat-window-min-icon",
                          "chat-window-min-btn",
                        ].includes(e.srcElement.id)
                      ) {
                        this.setState({ min: !this.state.min, sideBar: false });
                      }
                    }
                  );
                }, 1000);
              }
            }}
            id="testButton"
            toolTipText="Test Bot"
            styles={{ color: "#51597e" }}
          >
            Test
          </Button>
        )}

        {/* Bot Selection Modal */}
        <Modal
          size="md"
          isOpen={botListModal}
          toggle={() => {
            this.setState({ botListModal: false });
          }}
          className="rounded"
        >
          <ModalBody
            className="bg-second text-white p-0"
            style={{ height: "80vh" }}
          >
            <h4 className="text-center w-100">
              Select a bot to trigger a call / chat{" "}
            </h4>
            <div className="list-group overflow-auto h-auto">
              {botList &&
                botList.map((bot, index) => {
                  return (
                    <div
                      key={index}
                      className="mb-1 list-group-item list-group-item-action bg-second shadow"
                      style={{ background: "#272736" }}
                      onClick={() => {
                        this.setState({
                          selectedBot: bot.bot_id,
                          botListModal: false,
                          modal: true,
                        });
                      }}
                    >
                      <div className="row m-0 text-light">
                        <div
                          className={`p-0 col`}
                          style={{
                            borderRadius: "15rem",
                            maxWidth: "3.8rem",
                            writingMode: "vertical-lr",
                          }}
                        ></div>
                        <div className="col text-left">
                          <h5 className=" font-weight-bold">
                            {bot.bot_name}
                            <small
                              style={{ fontSize: "60%" }}
                              className={`${
                                bot.bot_type?.toLowerCase() === "inbound"
                                  ? "phill-gold"
                                  : "phill-blue"
                              } ml-2 text-uppercase`}
                            >
                              {bot.bot_type}
                            </small>
                            <small
                              style={{ fontSize: "60%" }}
                              className="phill-orange ml-2 text-uppercase"
                            >
                              {bot.language}
                            </small>
                          </h5>
                          <small>{bot.bot_description}</small>
                        </div>
                        <div
                          style={{ width: "7rem" }}
                          className="date text-center"
                        >
                          <span>Modified By</span>
                          <br />
                          <small>
                            {bot.created_date
                              ? `${new Date(bot.created_date).toLocaleString(
                                  "default",
                                  { month: "long" }
                                )} 
                            ${new Date(bot.created_date).toLocaleString(
                              "default",
                              { day: "numeric" }
                            )}`
                              : ""}
                          </small>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </ModalBody>
        </Modal>

        {/* Modal */}
        {/* <Modal
          size="lg"
          isOpen={this.state.modal}
          toggle={() => {
            if(this.state.intervelID) {
              clearInterval(this.state.intervelID);
            }
            this.setState({ modal: false, intervelID: null });
          }}
          className="rounded-3"
        >
          <ModalBody
            className="text-white p-0 rounded-3"
            style={{ background: "#eeeeee", height: "80vh" }}
          >
            {this.props.isCRM && (
              <Test
                test_bot_ID={this.state.selectedBot}
                selectedContact={this.props.selectedContact}
              ></Test>
            )}
            {!this.props.isCRM && <Test selectedContact={this.props.selectedContact} setIntervelID={(intervelID) => {this.setState({intervelID})}}/>}
          </ModalBody>
        </Modal> */}

        {/* iframe running app.js with sidebar */}
        <div
          style={{
            position: "absolute",
            background: "transparent",
            bottom: "0%",
            right: "0%",
            zIndex: 9999999,
            height: "auto",
            width: "auto",
          }}
        >
          {this.state.sideBar && (
            <div
              className="pb-1 text-white"
              style={{
                position: "relative",
                bottom: "-760px",
                right: "160px",
                background: "#1b2b65",
                height: "720px",
                zIndex: 9999999,
                width: `${this.state.sideBar ? "260px" : "0px"}`,
                opacity: `${this.state.sideBar ? "1" : "0"}`,
                transition: "all .5s ease-in-out",
              }}
            >
              <div className="w-100 h-100 text-left p-3">
                <div className="row m-0">
                  <div className="col-5 p-0 font-weight-bold text-golder">
                    <label>Bot Name</label>
                  </div>
                  <div className="col p-0 text-white font-weight-bold">
                    <i>{botConfig && botConfig.bot_name}</i>
                  </div>
                </div>

                <div className="row m-0">
                  <div className="col-5 p-0 text-golder font-weight-bold">
                    <label>Bot Type</label>
                  </div>
                  <div className="col p-0 text-white font-weight-bold">
                    <i>{botConfig && botConfig.bot_type}</i>
                  </div>
                </div>

                <div className="row m-0">
                  <div className="col-5 p-0 text-golder font-weight-bold">
                    <label>Bot Lang</label>
                  </div>
                  <div className="col p-0 text-white font-weight-bold">
                    <i>{botConfig && botConfig.language}</i>
                  </div>
                </div>

                {deploymentDetails &&
                  !deploymentDetails.error &&
                  deploymentDetails.type === "outbound" && (
                    <>
                      <form onSubmit={this.triggerCall.bind(this)}>
                        <div className="row m-0">
                          <div className="col-5 p-0 text-golder font-weight-bold">
                            <label>Phone No</label>
                          </div>
                          <div className="col p-0 text-white font-weight-bold">
                            <input
                              className="w-100 bg-transparent text-white border-0"
                              placeholder="Phone Number."
                              value={triggerPhone}
                              name="triggerPhone"
                              onChange={this.handleChange.bind(this)}
                              type="tel"
                              // pattern="^((\+1)|(\+91)?)[789]{1}\d{9}$" matches indian numbers only
                              // pattern="^((?:\+[0-9]{0,3}) | 0)[0-9]{9,15}$" country code mandatory
                              pattern="^([0|\+[0-9]{1,5})?[0-9]{9,15}$"
                              // title="Format:(+91)9876543210"
                              title="Please add a valid number"
                            ></input>
                          </div>
                        </div>

                        <div className="row m-0">
                          <div className="col-5 p-0 text-golder font-weight-bold">
                            <label>Client Name</label>
                          </div>
                          <div className="col p-0 text-white font-weight-bold">
                            <input
                              className="w-100 bg-transparent text-white border-0"
                              placeholder="Client Name."
                              value={triggerName}
                              name="triggerName"
                              onChange={this.handleChange.bind(this)}
                            ></input>
                          </div>
                        </div>
                        <div className="text-center w-100 mt-4">
                          <button
                            className="btn btn-warning"
                            type="submit"
                            disabled={!triggerName && !triggerPhone}
                          >
                            Call Me
                          </button>
                        </div>
                      </form>
                      <p className="text-white mt-4">
                        Now, lets begin testing!
                      </p>
                      <p>
                        <strong>Voice:</strong> Enter the phone # and click
                        "Call Me"
                        {/* <b className="bg-warning text-dark p-1 rounded mx-1 w-100">
                                    Call Me
                                  </b> */}
                      </p>
                      <p>
                        <strong> Chat:</strong> Initiate by typing "Hi" in the
                        window
                      </p>
                    </>
                  )}

                {deploymentDetails &&
                  !deploymentDetails.error &&
                  deploymentDetails.type === "inbound" && (
                    <>
                      <p className="text-white mt-4">
                        Please call to below phone number to get into a voice
                        converstation with the bot, or you can initiate the chat
                        by saying <b className="text-golder">'Hi'</b> in the
                        chat window.
                      </p>
                      <div className="row m-0">
                        <div className="col-5 p-0 text-golder font-weight-bold">
                          <label>Phone Number</label>
                        </div>
                        <div className="col p-0 text-white font-weight-bold">
                          {deploymentDetails.inbound_number.replace("+91", "0")}
                        </div>
                      </div>
                    </>
                  )}

                {deploymentDetails &&
                  !deploymentDetails.error &&
                  deploymentDetails.type === "none" && (
                    <>
                      <p className="text-white mt-4">
                        Please deployed the bot.
                      </p>
                    </>
                  )}

                {deploymentDetails && deploymentDetails.error && (
                  <>
                    <p className="text-white mt-4">
                      {deploymentDetails.error}.
                    </p>
                  </>
                )}
              </div>
            </div>
          )}
          {this.state.modal && (
            <>
              <iframe
                id="chat-window"
                key={this.state.random}
                className={`${this.state.modal ? "" : "d-none"}`}
                style={{
                  position: "relative",
                  background: "transparent",
                  bottom: "0%",
                  right: "-60px",
                  zIndex: 9999999,
                }}
                width="550"
                height="800"
                srcDoc={`
              <html>
                <body style="background: transparent">
                  <script
                    src="${process.env.PUBLIC_URL}/web-sdk/app.js"
                    id="gnani-bot-chatwindow-script"
                    bot-id="${botService.getSelectedBotId()}"
                    isBotFrameWork="isBotFrameWork"
                    token="${getToken()}"
                  ></script>
                </body>
              </html>
            `}
              ></iframe>
              <Button
                className={`${this.state.modal ? "" : "d-none"} text-white`}
                styles={{
                  position: "relative",
                  right: "10px",
                  bottom: `${this.state.min ? "80px" : "716px"}`,
                  zIndex: 9999999,
                  transition: "all .5s ease-in-out",
                }}
                onClick={() => {
                  this.setState({
                    modal: false,
                    sideBar: false,
                    min: false,
                    isChatOpen: false,
                  });
                  iFrame?.contentWindow.location.reload(true);
                }}
              >
                {svgIcons("close")}
              </Button>
              <Button
                className={`${
                  this.state.modal ? "" : "d-none"
                } fs-4 text-white`}
                isDisabled={this.state.min}
                onClick={() => this.setState({ sideBar: !this.state.sideBar })}
                styles={{
                  position: "relative",
                  bottom: `${this.state.min ? "109px" : "720px"}`,
                  right: `${this.state.sideBar ? "790px" : "530px"}`,
                  background: "#1b2b65",
                  opacity: `${this.state.min ? "0" : "1"}`,
                  transition: "all .4s ease-in-out",
                  zIndex: 9999999,
                }}
              >
                {this.state.sideBar
                  ? svgIcons("arrow-right")
                  : svgIcons("arrow-left")}
              </Button>
            </>
          )}
        </div>
      </>
    );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
}

export default ChatWindow;
