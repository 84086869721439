import botService from "../bot.service";
import baseUrl from "../baseURL.service";
import { getToken } from "../authUtil";
import apiServices from "../api.services";

const fetchData = (url, options) => {
  return apiServices.fetchData(url, { ...options });
};

function getBotFaqs() {
  let reqOptions = {
    method: "GET",
    headers: {
      token: getToken(),
    },
  };
  return fetchData(
    `${baseUrl}/get_bot_faqs_list/${botService.getSelectedBotId()}`,
    reqOptions
  );
}

const uploadFaq = (formData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      // "Content-Type": "application/json",
      token: getToken(),
    },
    body: formData,
  };
  fetchData(
    `${baseUrl}/upload_faq/${botService.getSelectedBotId()}`,
    requestOptions
  );
};

const updateBotFaq = (intentSentences, dynamic) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(intentSentences),
  };
  return fetchData(
    `${baseUrl}/modify_bot_faq/${botService.getSelectedBotId()}?faq_type=${
      dynamic ? "dynamic" : "static"
    }`,
    requestOptions
  );
};

const addBotFaq = (dataObj) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };
  return fetchData(
    `${baseUrl}/add_bot_faq/${botService.getSelectedBotId()}?faq_type=static`,
    requestOptions
  );
};

const renameBotFaq = (dataObj) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };
  return fetchData(
    `${baseUrl}/rename_bot_faq/${botService.getSelectedBotId()}`,
    requestOptions
  );
};

const getBotFaqDetails = (intent) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  return fetchData(
    `${baseUrl}/get_bot_faq_details/${botService.getSelectedBotId()}?faq=${intent}`,
    requestOptions
  );
};

const deleteBotFaq = (dataObj) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };
  return fetchData(
    `${baseUrl}/delete_bot_faq/${botService.getSelectedBotId()}`,
    requestOptions
  );
};

const importFaq = (file) => {
  let formData = new FormData();
  formData.append("file", file);
  let ext = file?.name?.split(".").pop();
  let url = `${baseUrl}/${
    ext === "json" ? "bulk_upload_faq" : "upload_faq"
  }/${botService.getSelectedBotId()}`;
  const requestOptions = {
    method: "POST",
    headers: { token: getToken() },
    body: formData,
  };
  return fetchData(url, requestOptions);
};

const faqServices = {
  getBotFaqDetails,
  renameBotFaq,
  updateBotFaq,
  uploadFaq,
  addBotFaq,
  getBotFaqs,
  deleteBotFaq,
  importFaq,
};

export default faqServices;
