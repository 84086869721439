import React, { useState, useContext } from "react";
import Input from "../../components/fields/input/input.component";
import Button from "../../components/fields/button/button.component";
import "./forgotPassword.scss";
import svgIcons from "../../services/icon.service";
import baseUrl from "../../services/baseURL.service";
import { NotificationContext } from "../../contexts/notficationContext";
import apiServices from "../../services/api.services";

function ForgotPassword(props) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [mismatch, setMismatch] = useState(false);
  const [isForm, setIsForm] = useState(true);

  const { setNotifications } = useContext(NotificationContext);

  const token = window.location.search?.split("=")[1];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password && password === confirmPassword) {
      let formData = new FormData();
      formData.append("password", password);
      const requestOptions = {
        method: "POST",
        headers: { token: token },
        body: formData,
      };
      try {
        const { message, status } = await apiServices.fetchData(
          `${baseUrl}/reset_password`,
          requestOptions
        );

        if (status === "success") {
          setIsForm(false);
          setNotifications({ color: "success", message });
          setConfirmPassword("");
          setPassword("");
        } else {
          setNotifications({ color: "danger", message });
          setConfirmPassword("");
          setPassword("");
        }
      } catch ({ message }) {
        setNotifications({ color: "danger", message });
        setConfirmPassword("");
        setPassword("");
      }
    } else {
      setMismatch(true);
    }
  };

  return (
    <div className="text-center mt-5">
      <h5>Reset Password</h5>
      <div className="mt-5">
        {isForm && (
          <form className="needs-validation" onSubmit={handleSubmit}>
            <div className="mb-3 row m-0 justify-content-center">
              <label className="col-2 align-self-center text-start">
                New password
              </label>
              <Input
                type="password"
                className="form-input px-2 col-4"
                placeholder="Enter your new password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="mb-5 row m-0 justify-content-center">
              <label
                for="password"
                className="col-2 align-self-center form-label text-start"
              >
                Confirm password
              </label>
              <Input
                id="password"
                type="password"
                className={`${
                  mismatch ? "is-invalid" : "valid"
                } col-4 form-input form-control px-2`}
                placeholder="Confirm password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <div id="passwordFeedback" className="invalid-feedback offset-3">
                Password mismatch...!
              </div>
            </div>
            <div className="">
              <Button className="cancel-btn me-3 px-4">Cancel</Button>
              <Button className="submit-btn ms-3 px-4" type="submit">
                Change password
              </Button>
            </div>
          </form>
        )}
        {!isForm && (
          <>
            <div className="mb-5">{svgIcons("check-circle")}</div>
            <div className="text-center">
              <p>Successfully changed your password!</p>
              <a href="https://botbuilder-prod.gnani.site/auth/login">
              {/* <a href="https://botframework-dev2s.gnani.site/auth/login"> */}
                Click here to login
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ForgotPassword;
