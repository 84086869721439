import { useRef, useContext, useState } from "react";
// import { NavLink } from "react-router-dom";
import { Nav } from "reactstrap";
import { BotContext } from "../../contexts/BotContext";
import { UserContext } from "../../contexts/userContext";

import gnaniLogoV from "../../assets/images/gnani-logoV.png";
import "./sidebar.scss";

const Sidebar = (props) => {
  const sidebarRef = useRef();
  const url = window.location.pathname;
  const { setModal, paths } = useContext(BotContext);
  const { getUserFlows, setUserFlows } = useContext(UserContext);

  const renderRoutes = () =>
    props.routes
      .filter((r) => r.layout === "/bot")
      .map((prop, key) => {
        if (prop.redirect) return null;
        else if (
          ["enabled", "disabled"].includes(
            props.permissions.left_nav[prop.iconName]
          )
        ) {
          return (
            <div
              className={`${
                props.location.pathname === `${prop.layout}${prop.path}`
                  ? "sidebar-nav-link-active"
                  : ""
              } sidebar-nav-link cursor-pointer`}
              key={key}
              onClick={() => handleTriggerModal(prop)}
            >
              {!prop.svg && (
                <img alt="" className="img-fluid" src={prop.icon} />
              )}
              {!prop.icon &&
                prop.svg(
                  prop.iconName,
                  url.includes(prop.path) ? "white" : "#5E668D",
                  "menu-icon"
                )}
              <p className="m-0">{prop.name}</p>
            </div>
          );
        }
      });

  const handleTriggerModal = (prop) => {
    if (window.location.pathname === `/bot/flows`) {
      if (
        prop.path !== "/flows" &&
        getUserFlows() &&
        JSON.stringify(getUserFlows()) === JSON.stringify(paths)
      ) {
        setModal(false, () => console.log(""));
        props.history.push(`${prop.layout}${prop.path}`);
        setUserFlows({});
      } else {
        setModal(true, () => props.history.push(`${prop.layout}${prop.path}`));
      }
    } else {
      props.history.push(`${prop.layout}${prop.path}`);
    }
  };

  return (
    <div className="sidebar-container" ref={sidebarRef}>
      <div className="d-flex justify-content-center py-3 cursor-pointer">
        <div
          onClick={() =>
            handleTriggerModal({ path: "/home", layout: "/admin" })
          }
        >
          <img alt="Gnani Logo" className="img-fluid" src={gnaniLogoV} />
        </div>
      </div>

      <Nav className="sidebar-links-container overflow-auto">
        {renderRoutes()}
      </Nav>
    </div>
  );
};

export default Sidebar;
