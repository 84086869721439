import { forwardRef } from "react";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownItem,
} from "reactstrap";
import MultiLevelDropDown from "react-multilevel-dropdown";
import "./dropDown.scss";
import "../../botLayout/botLayout.scss";

const DropDown = forwardRef(
  (
    {
      actionButton,
      btnClassName,
      direction,
      disabled = false,
      dropdownList = [],
      dropDownMenuMinWidth,
      handleToggleClick,
      id,
      isContent,
      isCustomDropDownList,
      isCustomDropDownMenu,
      isCustomMenuStyle,
      isCustomStyle,
      isMultiLevelDropDown,
      itemClassName,
      manualAction,
      manualOnClick,
      manualOpen,
      navWidth,
      onActionItemClick,
      position = "right",
      subMenuPosition = "right",
      isMultiDropDownDisabled,
      subMenuClass,
      caret,
      dropDownMenuClsName,
      isIntentModal,
      isButtons,
      globalIntent,
      isInput,
      isDropdownBtns,
    },
    ref
  ) => {
    const renderDropDownList = (dropdownList) => {
      if (dropdownList.length && !isCustomDropDownList) {
        return dropdownList.map((item, index) => (
          <DropdownItem
            className={itemClassName}
            key={index}
            onClick={() => {
              onActionItemClick(item);
            }}
            disabled={item.label === "Disable" || item.isUsed}
            header={item.header}
          >
            {item.isHTMLEle ? (
              item.htmlEle
            ) : (
              <div
                className={`row m-0 list-font ${
                  item.channel || (item?.icon2 && "mt-2")
                }`}
              >
                {item?.icon && (
                  <div
                    className={`col-auto p-0 ${!item.channel && "me-1"}`}
                    style={{
                      width: "1.1rem",
                      margin: `${item.channel && "0px 20px 0px 10px "}`,
                    }}
                  >
                    {item.icon}
                  </div>
                )}
                {item?.icon2 && (
                  <div
                    className="col-auto mx-3 p-0"
                    style={{ width: "1.1rem" }}
                  >
                    {item.icon2}
                  </div>
                )}
                <div
                  className="col"
                  style={{
                    width: item?.icon ? "80%" : "100%",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item.label}
                  {item.label === "Intent Library" &&
                    isIntentModal &&
                    isIntentModal()}
                  {item.importModal && item.importModal}
                </div>
              </div>
            )}
          </DropdownItem>
        ));
      } else if (isCustomDropDownList) {
        return dropdownList();
      }
    };

    if (isMultiLevelDropDown) {
      return (
        <MultiLevelDropDown
          isDisabled={isMultiDropDownDisabled}
          title={actionButton()}
          position={position}
          ref={ref}
          buttonClassName="p-0 multi-level-dropdown-btn"
        >
          {dropdownList.map((d, index) => {
            const isSubMenu = Object.keys(d).includes("subMenu");

            return isSubMenu ? (
              <MultiLevelDropDown.Item className={itemClassName} key={index}>
                {d.label}
                <MultiLevelDropDown.Submenu
                  position={subMenuPosition}
                  className={subMenuClass}
                >
                  {d.subMenu.map((sMenu, index) => {
                    return (
                      <MultiLevelDropDown.Item
                        className={`${sMenu.disabled && "disabled-item"}`}
                        isDisabled={!!sMenu.disabled}
                        key={index}
                        onClick={(e) => onActionItemClick(sMenu, e)}
                      >
                        <button
                          className={`btn dropDown-sub-item ${
                            sMenu.disabled && "disabled-item"
                          }`}
                          disabled={sMenu.disabled}
                        >
                          <div className="d-inline-block align-items-center w-50px">
                            {sMenu.hasOwnProperty("icon")
                              ? sMenu.icon
                              : sMenu.image}
                          </div>
                          <span className="align-self-center m-auto">
                            {sMenu.label}
                            {sMenu.disabled && ` (${sMenu.disabled})`}
                          </span>
                        </button>
                      </MultiLevelDropDown.Item>
                    );
                  })}
                </MultiLevelDropDown.Submenu>
              </MultiLevelDropDown.Item>
            ) : (
              <MultiLevelDropDown.Item
                className={`${itemClassName} list-font`}
                key={index}
                onClick={(e) => onActionItemClick(d, e)}
              >
                {d.label}
              </MultiLevelDropDown.Item>
            );
          })}
        </MultiLevelDropDown>
      );
    } else {
      if (manualAction) {
        return (
          <UncontrolledDropdown
            direction={direction}
            disabled={disabled}
            isOpen={manualOpen}
            onClick={manualOnClick}
            ref={ref}
            caret={caret}
          >
            <DropdownToggle
              className={`button ${btnClassName}`}
              color="white"
              onClick={handleToggleClick}
              style={disabled ? { pointerEvents: "none", opacity: "0.65" } : {}}
            >
              {actionButton()}
            </DropdownToggle>
            {isCustomDropDownMenu ? (
              isCustomDropDownMenu
            ) : isDropdownBtns ? (
              <DropdownMenu id={id} end={isContent ? true : false}>
                <div
                  className="dropDownMenuClsName"
                  style={
                    isCustomMenuStyle
                      ? isCustomMenuStyle
                      : {
                          width: isCustomStyle
                            ? "19rem"
                            : navWidth
                            ? "16.3rem"
                            : "",
                          padding: isCustomStyle && "1rem",
                          maxHeight: "30vh",
                          overflow: "auto",
                          minWidth: dropDownMenuMinWidth || "10rem",
                        }
                  }
                >
                  {renderDropDownList(dropdownList, isCustomDropDownList)}
                </div>
                {isButtons && isButtons}
              </DropdownMenu>
            ) : (
              <DropdownMenu
                id={id}
                end={isContent ? true : false}
                style={
                  isCustomMenuStyle
                    ? isCustomMenuStyle
                    : {
                        width: isCustomStyle && "19rem",
                        padding: isCustomStyle && "1rem",
                        maxHeight: "35vh",
                        overflow: "auto",
                        minWidth: dropDownMenuMinWidth || "10rem",
                      }
                }
              >
                {renderDropDownList(dropdownList, isCustomDropDownList)}
              </DropdownMenu>
            )}
          </UncontrolledDropdown>
        );
      }

      return (
        <UncontrolledDropdown
          direction={direction}
          disabled={disabled}
          ref={ref}
        >
          <DropdownToggle
            className={`button ${btnClassName}`}
            style={disabled ? { pointerEvents: "none", opacity: "0.4" } : {}}
            color="white"
            onClick={handleToggleClick}
            caret={caret}
          >
            {actionButton()}
          </DropdownToggle>
          {isCustomDropDownMenu ? (
            isCustomDropDownMenu
          ) : globalIntent && isButtons ? (
            <DropdownMenu id={id} end={isContent ? true : false}>
              {isInput && isInput}
              <div
                className="dropDownMenuClsName"
                style={
                  isCustomMenuStyle
                    ? isCustomMenuStyle
                    : {
                        width: isCustomStyle
                          ? "19rem"
                          : navWidth
                          ? "16.3rem"
                          : "",
                        padding: isCustomStyle && "1rem",
                        maxHeight: "30vh",
                        overflow: "auto",
                        minWidth: dropDownMenuMinWidth || "10rem",
                      }
                }
              >
                {renderDropDownList(dropdownList, isCustomDropDownList)}
              </div>
              {isButtons && isButtons}
            </DropdownMenu>
          ) : (
            <DropdownMenu
              id={id}
              end={isContent ? true : false}
              className={dropDownMenuClsName}
              style={
                isCustomMenuStyle
                  ? isCustomMenuStyle
                  : {
                      width: isCustomStyle
                        ? "19rem"
                        : navWidth
                        ? "16.3rem"
                        : "",
                      padding: isCustomStyle && "1rem",
                      maxHeight: "30vh",
                      overflow: "auto",
                      minWidth: dropDownMenuMinWidth || "10rem",
                    }
              }
            >
              {renderDropDownList(dropdownList, isCustomDropDownList)}
            </DropdownMenu>
          )}
        </UncontrolledDropdown>
      );
    }
  }
);

export default DropDown;
