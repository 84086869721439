import React, { Component, useContext } from "react";
import { Tooltip } from "reactstrap";

import BackUp from "./backup";
import BotLayoutContent from "../../components/botLayout/botLayoutContent.component";
import BotLayoutLeftPanel from "../../components/botLayout/botLayoutLeftPanel.component";
// import BotLayoutRightPanel from "../../components/botLayout/botLayoutRightPanel.component";
import Button from "../../components/fields/button/button.component";
import DropDown from "../../components/fields/dropDown/dropDown.component";
import HorizontalScroll from "../../components/horizontalScroll/horizontalScroll";
import Input from "../../components/fields/input/input.component";
import Modal from "../../components/modal/modal.component";
import TextArea from "../../components/fields/textArea/textArea.component";
import Toggle from "react-toggle";

import apiService from "../../services/api.services";
import baseUrl, { getImageUrl } from "../../services/baseURL.service";
import botService from "../../services/bot.service";
import getIcon from "../../services/icon.service";
import settingsAPIService from "../../services/api.services/settingsApi.service";

import { BotContext } from "../../contexts/BotContext";
import { UserContext } from "../../contexts/userContext";
import { NotificationContext } from "../../contexts/notficationContext";

import DeleteConfirmation from "../../components/deleteConfirmation.component";
import plusIcon from "../../assets/icons/first-aid.svg";
import svgIcons from "../../services/icon.service";

import "./settings.scss";
import WebSdkConfigurations from "./WebSdkConfigurations";

const dropDownListForShare = [
  { label: "Read/Write", value: "write" },
  { label: "Read Only", value: "read" },
];

class SETTINGS_COMP extends Component {
  static contextType = BotContext;
  constructor(props) {
    super(props);
    this.state = {
      botData: {},
      sideBarList: [
        { label: "General", isSelected: true, value: "_general" },
        { label: "Backup and Restore", isSelected: false, value: "_backup" },
        { label: "Share", isSelected: false, value: "_share" },
        {
          label: "Web SDK Configurations",
          isSelected: false,
          value: "_websdkconfigurations",
        },
      ],
      selectedChannel: [],
      isToolTip: false,
      regions: [],
      selectedRegion: "",
      timeZones: [],
      selectedTimeZone: "",
      selectedLang: "",
      channels: [],
      isCreateInp: false,
      createBackUpName: "",
      backUpData: [],
      backUpIndex: "",
      oldBackUpName: "",
      email: "",
      access: "",
      sharedUserData: [],
      modal: false,
      isModal: false,
      isRestoreModal: false,
      linkBotsList: [],
      BotslinkedList: [],
      isLinkOpen: false,
      selectedBotCategory: null,
      languageDetection: false,
    };
    this.linkBotDropRef = React.createRef();
  }

  componentDidMount() {
    this.setNavBarButtons();
    this.getApis();
    this.getBackUp();
    this.hideRestrictedTabs();
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  componentWillUnmount() {
    this.context.setNavBarButtons("");
  }

  getDeleteModal = (backup_name) => {
    return (
      <DeleteConfirmation
        open={this.state.isModal}
        delete={() => this.removeBackUp({ backup_name })}
      />
    );
  };

  getLinkedBots = (callback, lang_detection = this.state.languageDetection) => {
    settingsAPIService
      .getBotsLinked(lang_detection)
      .then((r) => {
        if (r.status === "success") {
          const BotslinkedList = r.linked_bots_list.map((e) => ({
            bot_id: e.bot_id,
            name: `${e.bot_name} - ${this.returnProperStr(e.language)}`,
          }));
          this.setState({ BotslinkedList });
          callback(r.linked_bots_list);
        } else {
        }
      })
      .catch((e) =>
        this.props.setNotifications({
          color: "danger",
          message: e.message,
        })
      );
  };

  getLinkBotsAvail = (linkedList) => {
    settingsAPIService
      .getLinkBotsAvail()
      .then((r) => {
        if (r.status === "success" && !!r.bots_list.length) {
          if (botService.getSelectedBotId().includes("cdev")) {
            const linkBotsList = r.bots_list
              .filter((ele) => ele.bot_id.includes("cdev"))
              .map((ele) => {
                return {
                  value: ele.bot_id,
                  label: `${ele.bot_name} - ${this.returnProperStr(
                    ele.language
                  )}`,
                  isSelected: linkedList?.some((m) => m.bot_id === ele.bot_id),
                  icon: svgIcons("check-box-outline-blank"),
                };
              });
            this.setState({ linkBotsList });
          } else {
            const linkBotsList = r.bots_list
              .filter((ele) => ele.bot_id.includes("cprod"))
              .map((ele) => {
                return {
                  value: ele.bot_id,
                  label: `${ele.bot_name} - ${this.returnProperStr(
                    ele.language
                  )}`,
                  isSelected: linkedList?.some((m) => m.bot_id === ele.bot_id),
                  icon: svgIcons("check-box-outline-blank"),
                };
              });
            this.setState({ linkBotsList });
          }
        }
      })
      .catch((e) =>
        this.props.setNotifications({
          color: "danger",
          message: e.message,
        })
      );
  };

  linkBots = (link) => {
    settingsAPIService
      .linkBots(link)
      .then((r) => {
        if (r.status === "success") {
          this.props.setNotifications({
            color: "success",
            message: r.message,
          });
          this.getLinkedBots(this.getLinkBotsAvail);
        } else {
          this.props.setNotifications({
            color: "danger",
            message: r.message,
          });
        }
      })
      .catch((e) =>
        this.props.setNotifications({
          color: "danger",
          message: e.message,
        })
      );
  };

  unLinkBot = (list) => {
    settingsAPIService
      .unLinkBots(list)
      .then((r) => {
        if (r.status === "success") {
          this.props.setNotifications({
            color: "success",
            message: r.message,
          });
          this.getLinkedBots(this.getLinkBotsAvail);
        } else {
          this.props.setNotifications({
            color: "danger",
            message: r.message,
          });
        }
      })
      .catch((e) =>
        this.props.setNotifications({
          color: "danger",
          message: e.message,
        })
      );
  };

  getUserAccess = async () => {
    try {
      const r = await settingsAPIService.getUserAccess();

      if (r.status === "success") {
        this.setState({ sharedUserData: r.data });
      } else {
        this.props.setNotifications({
          color: "danger",
          message: r.message,
        });
      }
    } catch (e) {
      this.props.setNotifications({
        color: "danger",
        message: e.message,
      });
      console.log(e);
    }
  };

  returnProperStr = (str) => {
    if (str.includes("-")) {
      let s = str.split("-");
      const first = s[0].substring(0, 1).toUpperCase() + s[0].substring(1);
      return `${first} ${s[1].toUpperCase()}`;
    } else {
      return str.substring(0, 1).toUpperCase() + str.substring(1);
    }
  };

  renameBackUp = async (new_name) => {
    try {
      const body = {
        old_name: this.state.oldBackUpName,
        new_name,
      };
      const r = await settingsAPIService.renameBackUp(body);

      if (r.status === "success") {
        this.setState({ backUpIndex: "" });
        this.props.setNotifications({ color: "success", message: r.message });
      } else {
        this.props.setNotifications({ color: "danger", message: r.message });
      }
    } catch (e) {
      this.props.setNotifications({ color: "danger", message: e.message });
      console.log(e);
    }
  };

  restoreBackUp = async (backup_name) => {
    try {
      const body = {
        backup_name,
        retrain: false,
      };
      const r = await settingsAPIService.restoreBackUp(body);

      if (r.status === "success") {
        this.setState({ backUpIndex: "" });
        this.props.setNotifications({
          color: "success",
          message: "Bot restored",
        });
      } else {
        this.props.setNotifications({ color: "danger", message: r.message });
      }
      this.setState({ isRestoreModal: false });
    } catch (e) {
      this.props.setNotifications({ color: "danger", message: e.message });
      console.log(e);
    }
  };

  downloadFullBackUp = async () => {
    try {
      const r = await settingsAPIService.downloadFullBackUp();
      this.handleDownload(r);
    } catch (e) {
      console.log(e);
      this.props.setNotifications({ color: "danger", message: e.message });
    }
  };

  downloadSingleBackUp = async (backup_name) => {
    try {
      const r = await settingsAPIService.downloadSingleBackUp(backup_name);
      this.handleDownload(r);
    } catch (e) {
      console.log(e);
    }
  };

  createBackUp = async () => {
    try {
      const body = {
        backup_name: this.state.createBackUpName,
        overwrite: false,
      };
      const r = await settingsAPIService.createBackUp(body);

      if (r.status === "success") {
        this.setState(
          { isCreateInp: false, createBackUpName: "" },
          this.getBackUp
        );
        this.props.setNotifications({
          color: "success",
          message: "Cloud backup added successfully",
        });
      } else {
        this.props.setNotifications({
          color: "danger",
          message: r.message,
        });
      }
    } catch (e) {
      this.props.setNotifications({
        color: "danger",
        message: e.message,
      });
      console.log(e);
    }
  };

  getBackUp = async () => {
    try {
      const r = await settingsAPIService.getBackUp();
      if (r.status === "success") {
        this.setState({ backUpData: r.data });
      }
    } catch (e) {
      console.log(e);
    }
  };

  removeBackUp = async (backup_name) => {
    try {
      const r = await settingsAPIService.removeBackUp({ backup_name });

      if (r.status === "success") {
        this.props.setNotifications({ color: "success", message: r.message });
        this.getBackUp();
      } else {
        this.props.setNotifications({ color: "danger", message: r.message });
      }
    } catch (e) {
      this.props.setNotifications({ color: "danger", message: e.message });
      console.log(e);
    }
  };

  setNavBarButtons() {
    this.context.setNavBarButtons(
      <div className="row">
        <div className="col-auto">
          <Button
            id="saveButton"
            toolTipText={botService.getSelectedBotSavedDate()}
            className="btn intents-btn intent-font-save rounded-2"
            onClick={this.updateBotDetails}
          >
            Save
          </Button>
        </div>
      </div>
    );
  }

  updateBotDetails = () => {
    const {
      botData,
      selectedRegion,
      selectedTimeZone,
      selectedChannel,
      selectedBotCategory,
    } = this.state;
    const body = selectedBotCategory
      ? {
          bot_name: botData.bot_name,
          bot_description: botData.bot_description,
          region: selectedRegion,
          timezone: selectedTimeZone,
          bot_channels: selectedChannel,
          bot_icon: botData.bot_icon,
          bot_category: selectedBotCategory,
        }
      : {
          bot_name: botData.bot_name,
          bot_description: botData.bot_description,
          region: selectedRegion,
          timezone: selectedTimeZone,
          bot_channels: selectedChannel,
          bot_icon: botData.bot_icon,
        };
    console.log(botData);
    apiService
      .updateBotDetails(body, botData.bot_id)
      .then((res) => {
        if (res.status === "success") {
          botService.setSelectedBot({ ...this.state.botData, ...body });
          this.props.setNotifications({
            color: "success",
            message: res.message,
          });
        } else {
          this.props.setNotifications({
            color: "danger",
            message: res.message,
          });
        }
      })
      .catch((err) =>
        this.props.setNotifications({ color: "danger", message: err.message })
      );
  };

  getBotData = () => {
    const botData = botService.getSelectedBot();
    let lid = false;

    if (botData.hasOwnProperty("language_detection"))
      lid = botData.language_detection;

    this.setState({ botData, languageDetection: lid });
    return botData;
  };

  getApis = async (callback) => {
    let [regions, industries, languages, channels, botData] = await Promise.all(
      [
        this.getRegions(),
        this.getIndustries(),
        this.getLanguages(),
        this.getChannels(),
        this.getBotData(),
      ]
    );

    regions = regions.data.map((r) => ({ label: r }));
    industries = industries.data.map((i) => ({
      label: i.industry,
      iconURL: i.icons[0],
    }));

    languages = languages.data.map((l) => ({ label: l, isSelected: false }));
    channels = channels.data.map((c) => ({ label: c.name, icon: c.icon }));

    this.setState(
      {
        regions,
        industries,
        languages,
        channels,
        selectedLang: botData.language,
        selectedChannel: botData.bot_channels,
        selectedRegion: botData.region,
        selectedTimeZone: botData.timezone,
        selectedBotCategory: botData.bot_category ? botData.bot_category : null,
      },
      () => {
        this.getLinkedBots(this.getLinkBotsAvail, botData?.language_detection);
        this.getTimeZone();
      }
    );
  };

  getChannels = () => {
    return apiService
      .getChannels()
      .then((res) => res)
      .catch((error) => console.log(error));
  };

  getLanguages = () => {
    return apiService
      .getLanguages()
      .then((res) => res)
      .catch((error) => console.log(error));
  };

  getRegions = () => {
    return apiService
      .getRegions()
      .then((res) => res)
      .catch((error) => console.log(error));
  };

  getIndustries = () => {
    return apiService
      .getIndustries()
      .then((res) => res)
      .catch((error) => console.log(error));
  };

  getTimeZone = () => {
    apiService
      .getTimeZone(this.state.selectedRegion)
      .then((res) => {
        const timeZones = res.data.map((r) => ({ label: r.timezone }));
        this.setState({ timeZones });
      })
      .catch((error) => console.log(error));
  };

  handleOutsideClick = ({ target }) => {
    if (
      this.linkBotDropRef.current &&
      !this.linkBotDropRef.current.contains(target)
    ) {
      this.setState({ isLinkOpen: false });
    }
  };

  handleChannelSelection = ({ label }) => {
    let channels = [...this.state.selectedChannel];
    const isSelected = channels.includes(label);

    if (isSelected) channels = channels.filter((ch) => label !== ch);
    else channels = [...channels, label];

    this.setState({ selectedChannel: channels });
  };

  handleSideBarSelection = ({ value }) => {
    let sideBarList = [...this.state.sideBarList];

    sideBarList = sideBarList.map((s) => {
      s.value === value ? (s.isSelected = true) : (s.isSelected = false);
      value === "_share" && s.isSelected && this.getUserAccess();
      return s;
    });

    this.setState({ sideBarList });
  };

  handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    this.setState({ isToolTip: true });
    return setTimeout(() => this.setState({ isToolTip: false }), 3000);
  };

  handleOnChange = ({ target }) => {
    const botData = { ...this.state.botData };
    botData[target.name] = target.value;
    console.log(botData[target.name], botData);
    this.setState({ botData });
  };

  handleImgUpload = ({ target }) => {
    let file = target.files;
    if (file.length) {
      const formData = new FormData();
      formData.append("file", file[0]);
      apiService
        .uploadImageOnCustRep(formData)
        .then((res) => {
          if (res.status === "success") {
            const botData = { ...this.state.botData };
            botData.bot_icon = res.url;
            this.setState({ botData }, (target.value = null));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  handleDownload = (data) => {
    const url = window.URL.createObjectURL(
      new Blob([JSON.stringify(data)], {
        type: "application/json",
      })
    );
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", "file.json");

    document.body.appendChild(link);

    link.click();
    this.props.setNotifications({
      color: "success",
      message: "Export successful",
    });
  };

  handleFileUpload = ({ target }) => {
    const file = target.files;

    if (file.length) {
      const formData = new FormData();
      formData.append("file", file[0]);

      apiService
        .importFileData(formData)
        .then((r) => {
          if (r.status === "success") {
            this.props.setNotifications({
              color: "success",
              message: "Import Successful!",
            });
          } else {
            this.props.setNotifications({
              color: "danger",
              message: r.message,
            });
          }
          target.value = null;
        })
        .catch((e) => {
          this.props.setNotifications({
            color: "danger",
            message: e.message,
          });
        });
    }
  };

  handleDropDownActions = ({ value }, { backup_name }, index) => {
    switch (value) {
      case "_delete":
        this.setState({ isModal: true });
        setTimeout(() => {
          this.setState({ isModal: false });
        }, 100);
        break;
      case "_download":
        return this.downloadSingleBackUp(backup_name);
      case "_restore":
        return this.setState({ isRestoreModal: true });
      default:
        return this.setState({
          backUpIndex: index,
          oldBackUpName: backup_name,
        });
    }
  };

  handleBackUpDataRename = ({ key, target }, index, eventType) => {
    if (key === "Enter") {
      return this.renameBackUp(target.value);
    } else {
      if (eventType === "onChange") {
        const backUpData = [...this.state.backUpData];
        backUpData[index].backup_name = target.value;
        this.setState({ backUpData });
      }
    }
  };

  toggleCreateBackUpInp = () => {
    this.setState({ isCreateInp: !this.state.isCreateInp });
  };

  hideRestrictedTabs = () => {
    let temp = [];
    Object.keys(this.props.permissions.settings).forEach((tab) => {
      if (
        tab === "general" &&
        this.props.permissions.settings[tab] !== "hide"
      ) {
        temp.splice(0, 0, {
          label: "General",
          isSelected: true,
          value: "_general",
        });
      } else if (
        tab === "backup" &&
        this.props.permissions.settings[tab] !== "hide"
      ) {
        temp.push({
          label: "Backup and Restore",
          isSelected: false,
          value: "_backup",
        });
      } else if (
        tab === "share" &&
        this.props.permissions.settings[tab] !== "hide"
      ) {
        temp.push({ label: "Share", isSelected: false, value: "_share" });
      } else if (
        tab === "sdk_config" &&
        this.props.permissions.settings[tab] !== "hide"
      ) {
        temp.push({
          label: "Web SDK Configurations",
          isSelected: false,
          value: "_websdkconfigurations",
        });
      }
    });
    this.setState({ sideBarList: temp });
  };

  handleTabs = () => {
    const sideBarList = this.state.sideBarList.filter((s) => s.isSelected)[0]
      .value;

    switch (sideBarList) {
      case "_general":
        // if (this.props.permissions.settings.general !== "hide")
        return this.renderSettingsTab;
      case "_backup":
        // if (this.props.permissions.settings.backup !== "hide")
        return this.renderBackUp2Tab;
      case "_websdkconfigurations":
        // if (this.props.permissions.settings.sdk_config !== "hide")
        return this.renderWebsdk2Tab;
      default:
        // if (this.props.permissions.settings.share !== "hide")
        return this.renderShareTab;
    }
  };

  handleRemoveUser = async (email) => {
    try {
      const r = await settingsAPIService.removeUserAccess({ email });

      if (r.status === "success") {
        this.props.setNotifications({
          color: "success",
          message: "Access revoked",
        });
        this.getUserAccess();
      } else {
        this.props.setNotifications({
          color: "danger",
          message: r.message,
        });
      }
    } catch (e) {
      this.props.setNotifications({
        color: "danger",
        message: e.message,
      });
      console.log(e);
    }
  };

  handleLinkBotClick = (menu, action) => {
    let { linkBotsList, BotslinkedList } = this.state;

    switch (action) {
      case "list":
        let list = linkBotsList.map((e) => {
          if (e.value === menu.value) {
            e.isSelected = !e.isSelected;
          }
          return e;
        });
        this.setState({ linkBotsList: list });
        break;
      case "cancel":
        let temp = linkBotsList.map((e) => {
          e.isSelected = BotslinkedList.some((m) => m.bot_id === e.value);
          return e;
        });
        this.setState({ linkBotsList: temp, isLinkOpen: false });
        break;
      case "confirm":
        try {
          const botsToUnlink = (linkBotsList ?? [])
            .filter(
              (bot) =>
                (BotslinkedList ?? []).some((sBot) => sBot.bot_id === bot.value) &&
                !bot.isSelected
            )
            .map((bot) => bot.value);

          const botsToLink = (linkBotsList ?? [])
            .filter((bot) => bot.isSelected)
            .map((bot) => bot.value);

          if (botsToLink.length > 0) {
            let link = {
              link_bot_ids: botsToLink,
              language_detection: this.state.languageDetection,
            };

            this.linkBots(link);
          }

          if (botsToUnlink.length > 0) {
            let unLink = {
              linked_bot_ids: botsToUnlink,
              language_detection: this.state.languageDetection,
            };
            this.unLinkBot(unLink);
          }
        } catch {
          console.log("Failed to complete the operation");
        } finally {
          this.setState({ isLinkOpen: false });
        }
        break;
      default:
        break;
    }
  };

  toggleLinkDropdown = () => {
    this.setState({ isLinkOpen: !this.state.isLinkOpen });
  };

  renderSettingsTab = () => {
    const {
      botData,
      selectedRegion,
      regions,
      timeZones,
      selectedTimeZone,
      selectedLang,
      selectedChannel,
      channels,
      linkBotsList,
      BotslinkedList,
      selectedBotCategory,
    } = this.state;

    return (
      <div className="container h-100 overflow-auto pb-3">
        <div className="row pt-4">
          <div className="col">
            <div className="row align-items-center mb-4">
              <div className="col-auto" style={{ width: "12rem" }}>
                <label
                  className="settings-content-layout-label"
                  htmlFor="settings-bot-id"
                >
                  Bot ID
                </label>
              </div>
              <div
                className="col d-flex align-items-center"
                id="settings-pg-copied"
              >
                <Input
                  className="settings-content-inp settings-bot-id-inp"
                  id="settings-bot-id"
                  placeholder="9901b8ad2c4944f2818847deb78d0_dev"
                  value={botData.bot_id}
                  readOnly
                />
                <div
                  className="settings-pg-fa-copy-container"
                  onClick={() => this.handleCopy(botData.bot_id)}
                >
                  {getIcon("copy")}
                </div>
              </div>
              <Tooltip
                isOpen={this.state.isToolTip}
                target="settings-pg-copied"
              >
                Copied!
              </Tooltip>
            </div>

            <div className="row align-items-center mb-4">
              <div className="col-auto" style={{ width: "12rem" }}>
                <label
                  className="settings-content-layout-label"
                  htmlFor="settings-bot-id"
                >
                  Web SDK
                </label>
              </div>
              <div
                className="col d-flex align-items-center"
                id="settings-pg-copied"
              >
                <Input
                  className="settings-content-inp settings-bot-id-inp"
                  id="settings-bot-id"
                  placeholder=""
                  value={`<script defer id="chat_bot_widget" src="${baseUrl}/static/chat_bot_widget.js?bot_id=${botData.bot_id}"></script>`}
                  readOnly
                />
                <div
                  className="settings-pg-fa-copy-container"
                  onClick={() =>
                    this.handleCopy(
                      `<script defer id="chat_bot_widget" src="${baseUrl}/static/chat_bot_widget.js?bot_id=${botData.bot_id}"></script>`
                    )
                  }
                >
                  {getIcon("copy")}
                </div>
              </div>
              <Tooltip
                isOpen={this.state.isToolTip}
                target="settings-pg-copied"
              >
                Copied!
              </Tooltip>
            </div>

            <div className="row align-items-center mb-4">
              <div className="col-auto" style={{ width: "12rem" }}>
                <label
                  className="settings-content-layout-label"
                  htmlFor="settings-label-name"
                >
                  Name
                </label>
              </div>
              <div className="col">
                <Input
                  className="settings-content-layout-inp settings-content-inp"
                  id="settings-label-name"
                  placeholder="Give a name for your bot"
                  name="bot_name"
                  value={botData.bot_name}
                  onChange={this.handleOnChange}
                />
              </div>
            </div>

            <div className="row align-items-center mb-4">
              <div className="col-auto" style={{ width: "12rem" }}>
                <label className="settings-content-layout-label">Region</label>
              </div>
              <div className="col">
                <DropDown
                  isContent={true}
                  btnClassName="settings-content-inp settings-content-layout-inp"
                  dropdownList={regions}
                  actionButton={() => (
                    <div className="row">
                      <div className="col-auto">
                        <span
                          className={`${
                            selectedRegion
                              ? "settings-content-layout-label"
                              : "settings-channel-col-title"
                          }`}
                        >
                          {selectedRegion || "Select region"}
                        </span>
                      </div>
                      <div className="col d-flex align-items-center justify-content-end">
                        {getIcon("chevronDown", "#C4C4C6", "", 10)}
                      </div>
                    </div>
                  )}
                  onActionItemClick={(menu) =>
                    this.setState(
                      {
                        selectedRegion: menu.label,
                        selectedTimeZone: "",
                      },
                      this.getTimeZone
                    )
                  }
                  itemClassName=""
                />
              </div>
            </div>

            <div className="row align-items-center mb-4">
              <div className="col-auto" style={{ width: "12rem" }}>
                <label className="settings-content-layout-label">
                  Time Zone
                </label>
              </div>
              <div className="col">
                <DropDown
                  isContent={true}
                  btnClassName="settings-content-inp settings-content-layout-inp"
                  dropdownList={timeZones}
                  actionButton={() => (
                    <div className="row">
                      <div className="col-auto">
                        <span
                          className={`${
                            selectedTimeZone
                              ? "settings-content-layout-label"
                              : "settings-channel-col-title"
                          }`}
                        >
                          {selectedTimeZone || "Select time zone"}
                        </span>
                      </div>
                      <div className="col d-flex align-items-center justify-content-end">
                        {getIcon("chevronDown", "#C4C4C6", "", 10)}
                      </div>
                    </div>
                  )}
                  onActionItemClick={(menu) =>
                    this.setState({ selectedTimeZone: menu.label })
                  }
                  itemClassName=""
                />
              </div>
            </div>

            <div className="row align-items-center mb-4">
              <div className="col-auto" style={{ width: "12rem" }}>
                <label className="settings-content-layout-label">
                  Language
                </label>
              </div>

              <div className="col">
                <DropDown
                  disabled
                  btnClassName="settings-content-inp settings-content-layout-inp"
                  actionButton={() => (
                    <div className="row">
                      <div className="col-auto">
                        <span
                          className={`${
                            selectedLang
                              ? "settings-content-layout-label"
                              : "settings-channel-col-title"
                          }`}
                        >
                          {selectedLang || "Select language"}
                        </span>
                      </div>
                      <div className="col d-flex align-items-center justify-content-end">
                        {getIcon("chevronDown", "#C4C4C6", "", 10)}
                      </div>
                    </div>
                  )}
                  onActionItemClick={(menu) => {}}
                  itemClassName=""
                />
              </div>
            </div>

            <div className="row align-items-center mb-4">
              <div className="col-auto" style={{ width: "12rem" }}>
                <label className="settings-content-layout-label">
                  Bot Category
                </label>
              </div>
              <div className="col">
                <DropDown
                  isContent
                  btnClassName="settings-content-inp settings-content-layout-inp"
                  dropdownList={[
                    { label: "Workflow Bot" },
                    { label: "Knowledgebase Bot" },
                    { label: "Workflow & Knowledgebase Bot" },
                  ]}
                  actionButton={() => (
                    <div className="row">
                      <div className="col-auto">
                        <span
                          className={`${
                            selectedBotCategory
                              ? "settings-content-layout-label"
                              : "settings-channel-col-title"
                          }`}
                        >
                          {selectedBotCategory === "wf"
                            ? "Workflow Bot"
                            : selectedBotCategory === "kb"
                            ? "Knowledgebase Bot"
                            : selectedBotCategory === "wf_kb"
                            ? "Workflow & Knowledgebase Bot"
                            : "Select Bot Category"}
                        </span>
                      </div>
                      <div className="col d-flex align-items-center justify-content-end">
                        {getIcon("chevronDown", "#C4C4C6", "", 10)}
                      </div>
                    </div>
                  )}
                  onActionItemClick={({ label }) => {
                    if (label === "Workflow Bot")
                      this.setState({ selectedBotCategory: "wf" });
                    else if (label === "Knowledgebase Bot")
                      this.setState({ selectedBotCategory: "kb" });
                    else if (label === "Workflow & Knowledgebase Bot")
                      this.setState({ selectedBotCategory: "wf_kb" });
                  }}
                  itemClassName=""
                />
              </div>
            </div>

            {/* language detection */}
            <div className="row align-items-center mb-4">
              <div className="col-auto" style={{ width: "12rem" }}>
                <label className="settings-content-layout-label">
                  Language Detection
                </label>
              </div>
              <div className="col">
                <Toggle
                  checked={this.state.languageDetection}
                  // isDisabled={this.props.permissions.slots.modify !== "enabled"}
                  onChange={() => {
                    this.setState({
                      languageDetection: !this.state.languageDetection,
                    });
                  }}
                />
              </div>
            </div>

            <div className="row align-items-center mb-4">
              <div className="col-auto" style={{ width: "12rem" }}>
                <label className="settings-content-layout-label">
                  Link bot
                </label>
              </div>

              <div className="col" ref={this.linkBotDropRef}>
                <DropDown
                  isDropdownBtns={true}
                  manualAction={true}
                  manualOpen={this.state.isLinkOpen}
                  handleToggleClick={this.toggleLinkDropdown}
                  isCustomMenuStyle={{
                    width: "35rem",
                    padding: "1rem",
                    maxHeight: "30vh",
                    overflow: "auto",
                  }}
                  itemClassName="py-2"
                  btnClassName="settings-content-inp settings-content-layout-inp"
                  dropdownList={linkBotsList.map((ele) => {
                    ele.isSelected && ele.icon
                      ? (ele.icon = svgIcons("check-box-marked"))
                      : (ele.icon = svgIcons("check-box-outline-blank"));
                    return ele;
                  })}
                  actionButton={() => (
                    <div
                      className="row"
                      onClick={() => this.setState({ isLinkOpen: true })}
                    >
                      <div className="col-auto d-flex text-trim">
                        <div
                          className={`${
                            !!BotslinkedList.length
                              ? "settings-content-layout-label"
                              : "settings-channel-col-title"
                          } d-flex`}
                        >
                          {!!BotslinkedList.length
                            ? BotslinkedList.map((e, i) => {
                                return (
                                  <span
                                    className={`${
                                      i !== BotslinkedList.length - 1 &&
                                      "span-border-right"
                                    } px-2 py-1`}
                                  >
                                    {e.name}
                                  </span>
                                );
                              })
                            : "Select a bot"}
                        </div>
                      </div>
                      <div className="col d-flex align-items-center justify-content-end">
                        {getIcon("chevronDown", "#C4C4C6", "", 10)}
                      </div>
                    </div>
                  )}
                  onActionItemClick={(menu) =>
                    this.handleLinkBotClick(menu, "list")
                  }
                  isButtons={
                    <div className="row m-0 justify-content-between px-3 mt-3">
                      <Button
                        className="col-auto link-cancel-btn"
                        onClick={() => this.handleLinkBotClick("", "cancel")}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="col-auto link-confirm-btn"
                        onClick={() => this.handleLinkBotClick("", "confirm")}
                      >
                        Confirm
                      </Button>
                    </div>
                  }
                />
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-auto" style={{ width: "12rem" }}>
                <label
                  className="settings-content-layout-label"
                  htmlFor="settings-label-desc"
                >
                  Description
                </label>
              </div>
              <div className="col">
                <TextArea
                  className="settings-content-inp settings-content-layout-inp"
                  id="settings-label-desc"
                  name="bot_description"
                  onchange={this.handleOnChange}
                  placeholder="Tell something about your bot"
                  rows="4"
                  value={botData.bot_description}
                />
              </div>
            </div>
          </div>

          <div
            className="col-auto bg-light mt-1 text-center"
            style={{ width: "15rem" }}
          >
            {botData.bot_icon ? (
              <img
                alt="Bot Icon"
                className="img-fluid mb-3"
                src={getImageUrl(botData.bot_icon)}
              />
            ) : (
              <div className="settings-bot-img-container">
                <img
                  alt="Settings page bot icon."
                  className="img-fluid"
                  src={plusIcon}
                />
              </div>
            )}
            <div>
              <div className="text-center">
                <Input
                  id="settings-img-upload"
                  onChange={this.handleImgUpload}
                  type="file"
                  accept="image/*"
                  hidden
                />
                <label htmlFor="settings-img-upload">
                  <i className="fas fa-edit me-2" />
                  <span className="settings-bot-img-name">Bot Icon</span>
                </label>
              </div>
            </div>

            <div className="row mt-5">
              {svgIcons("circle-exclaim", "", "fs-1 text-warning")}
              <p className="bot-del-warning">
                This action cannot be undone. Be careful.
              </p>
              <DeleteConfirmation
                isButton
                open={false}
                delete={() => {
                  apiService
                    .deleteBot(botService.getSelectedBot())
                    .then((res) => {
                      if (res.status === "success") {
                        this.props.setNotifications({
                          color: "success",
                          message: res.message,
                        });
                        this.props.history.push("/admin/home");
                      }
                    })
                    .catch((err) =>
                      this.props.setNotifications({
                        color: "danger",
                        message: err.message,
                      })
                    );
                }}
              />
            </div>
          </div>
        </div>

        <div className="d-none row mb-4">
          <div className="col-auto" style={{ width: "12rem" }}>
            <span className="settings-content-layout-label">Channel</span>
          </div>
          <div className="col" style={{ width: "calc(100% - 28rem)" }}>
            <p className="settings-channel-col-title">
              You can choose multiple channels
            </p>
            <HorizontalScroll
              items={channels}
              isWithIcon={true}
              isFlows={true}
              handleSelect={this.handleChannelSelection}
              selected_item={selectedChannel}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-auto" style={{ width: "12rem" }}>
            <span
              className="settings-content-layout-label"
              style={{ color: "#707070" }}
            >
              Call Type
            </span>
          </div>
          <div className="col d-flex align-items-center">
            <div
              className={`settings-call-container ${
                botData.bot_type === "inbound" &&
                "settings-call-container-selected"
              }`}
            >
              {getIcon("hangCall")} <p>Inbound</p>
            </div>
            <div
              className={`settings-call-container ${
                botData.bot_type === "outbound" &&
                "settings-call-container-selected"
              }`}
            >
              {getIcon("pickCall")} <p>Outbound</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderTableData = () => {
    const { backUpData, backUpIndex, isRestoreModal } = this.state;
    return backUpData.map((b, i) => (
      <tr
        key={i}
        style={{
          borderTop: i === 0 ? "hidden" : "initial",
          verticalAlign: "middle",
        }}
      >
        <th scope="row">{i + 1}.</th>
        <td>
          {backUpIndex === i ? (
            <Input
              onChange={(e) => this.handleBackUpDataRename(e, i, "onChange")}
              onKeyPress={this.handleBackUpDataRename}
              value={b.backup_name}
            />
          ) : (
            b.backup_name
          )}
        </td>
        <td>{b.backup_date}</td>
        <td className="cursor-pointer">
          <DropDown
            actionButton={() => getIcon("verticalOptions")}
            dropdownList={[
              { label: "Rename", value: "_rename", icon: svgIcons("edit") },
              {
                label: "Restore",
                value: "_restore",
                icon: <i className="fas fa-sync text-muted" />,
              },
              {
                label: "Download",
                value: "_download",
                icon: <i className="fas fa-file-download text-muted" />,
              },
              {
                label: "Delete",
                value: "_delete",
                icon: this.getDeleteModal(b.backup_name),
              },
            ]}
            onActionItemClick={(item) => this.handleDropDownActions(item, b, i)}
          />

          <Modal
            addNewModalOpen={isRestoreModal}
            className="backup-restore-modal"
            isHeader={false}
            modalSize="md"
          >
            <div className="container">
              <p>
                This will replace current bot flow and configs. Are you sure?
              </p>
              <div className="row mt-4">
                <div className="col text-end">
                  <Button
                    className="shared-modal-confirm-btn"
                    onClick={() => this.restoreBackUp(b.backup_name)}
                  >
                    Restore
                  </Button>
                </div>
                <div className="col-auto">
                  <Button
                    className="shared-modal-cancel-btn"
                    onClick={() => this.setState({ isRestoreModal: false })}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        </td>
      </tr>
    ));
  };

  handleUserAccessUpdate = async ({ email, access }) => {
    try {
      const r = await settingsAPIService.updateUserAccess({ email, access });

      if (r.status === "success") {
        this.props.setNotifications({
          color: "success",
          message: "Permission successfully changed.",
        });
        this.getUserAccess();
      } else {
        this.props.setNotifications({ color: "danger", message: r.message });
      }
    } catch (e) {
      this.props.setNotifications({ color: "danger", message: e.message });
      console.log(e);
    }
  };

  renderTableDataForShare = () => {
    return this.state.sharedUserData.map((b, i) => (
      <tr
        key={i}
        style={{
          borderTop: i === 0 ? "hidden" : "initial",
          verticalAlign: "middle",
        }}
      >
        <td className="settings-share-table-data">
          {b.first_name} {b.last_name}
        </td>
        <td className="settings-share-table-data">{b.email}</td>
        <td className="settings-share-table-data">2022/01/07</td>
        <td className="settings-share-table-data cursor-pointer">
          <DropDown
            actionButton={() => (
              <span>
                {b.user_access === "write" ? "Read/Write" : "Read Only"}{" "}
                <span className="ms-2">
                  {getIcon("chevronDown", "", "", 10)}
                </span>
              </span>
            )}
            dropdownList={dropDownListForShare}
            onActionItemClick={(m) =>
              this.handleUserAccessUpdate({ email: b.email, access: m.value })
            }
          />
        </td>
        <td style={{ minWidth: "7rem" }}>
          <div
            className="action-btn cursor-pointer"
            onClick={() => this.handleRemoveUser(b.email)}
          >
            {getIcon("delete")} <span>Revoke</span>
          </div>
        </td>
      </tr>
    ));
  };

  renderBackUpTab = () => {
    const { backUpData, createBackUpName, isCreateInp } = this.state;

    return (
      <div className="container-fluid pt-4 h-100">
        <div className="row">
          <div className="col">
            {isCreateInp && (
              <Input
                className="settings-backup-create-inp"
                placeholder="Enter backup name"
                onChange={(e) =>
                  this.setState({ createBackUpName: e.target.value })
                }
                onKeyPress={(e) => e.key === "Enter" && this.createBackUp()}
                value={createBackUpName}
              />
            )}

            <Button
              className="settings-backup-create-backup-btn"
              isDisabled={backUpData.length > 4}
              onClick={
                isCreateInp ? this.createBackUp : this.toggleCreateBackUpInp
              }
            >
              {isCreateInp ? (
                <span>Create</span>
              ) : (
                <>
                  <span className="me-2">
                    {getIcon("plus", "#ffffff", "", 10)}
                  </span>
                  <span>Create Backup</span>
                </>
              )}
            </Button>
          </div>
          <div className="col-auto">
            <div className="row">
              <div className="col">
                <div className="settings-backup-imp-btns">
                  <Input
                    type="file"
                    id="settings-backup-imp-file-import"
                    onChange={this.handleFileUpload}
                    hidden
                    accept=".json"
                  />
                  <label
                    className="cursor-pointer"
                    htmlFor="settings-backup-imp-file-import"
                  >
                    Import
                  </label>
                </div>
              </div>
              <div className="col">
                <Button
                  className="settings-backup-exp-btns"
                  onClick={this.downloadFullBackUp}
                >
                  Export
                </Button>
              </div>
            </div>
          </div>
        </div>
        {backUpData.length > 4 && (
          <div className="row">
            <div className="col">
              <p className="settings-backup-error-msg">
                * You have created maximum backups, kindly delete some to add
                new backup
              </p>
            </div>
          </div>
        )}
        <div className="row" style={{ height: "calc(100% - 5rem)" }}>
          <div className="col-6 mt-5">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Name</th>
                  <th scope="col">
                    Modified
                    <span className="ms-1">
                      {getIcon("chevronDown", "#3B3B3B", "", 10)}
                    </span>
                  </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>{this.renderTableData()}</tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <p className="settings-backup-note">* Note: Botframework</p>
          </div>
        </div>
      </div>
    );
  };

  renderBackUp2Tab = () => {
    return <BackUp getUserDetails={this.props.getUserDetails} />;
  };

  renderWebsdk2Tab = () => {
    return <WebSdkConfigurations />;
  };

  handleOnConfirm = async () => {
    const { access, email } = this.state;
    const body = {
      email,
      access,
    };

    try {
      const r = await settingsAPIService.addUserAccess(body);

      if (r.status === "success") {
        this.setState({ email: "", access: "" });
        this.props.setNotifications({
          color: "success",
          message: "Successfully Shared",
        });
        this.getUserAccess();
      } else {
        this.props.setNotifications({
          color: "danger",
          message: r.message,
        });
      }
    } catch (e) {
      this.props.setNotifications({
        color: "success",
        message: e.message,
      });
      console.log(e);
    }
  };

  renderShareTab = () => {
    const { access, email } = this.state;
    return (
      <div className="container-fluid mt-5">
        <p className="settings-share-titles">To Share</p>

        <div className="row settings-share-section">
          <div className="col-auto">
            <div className="row">
              <div className="col">
                <p className="settings-share-sec-titles">Share with</p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Input
                  className="settings-share-inp"
                  type="email"
                  placeholder="Enter email address"
                  onChange={(e) => this.setState({ email: e.target.value })}
                  value={email}
                />
              </div>
            </div>
          </div>
          <div className="col-auto">
            <div className="row">
              <div className="col">
                <p className="settings-share-sec-titles">Permission</p>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Button
                  className={`settings-share-permissions-btns ${
                    access === "write" && "settings-share-selected-access"
                  }`}
                  onClick={() => this.setState({ access: "write" })}
                >
                  Read/Write
                </Button>
              </div>
              <div className="col">
                <Button
                  className={`settings-share-permissions-btns ${
                    this.state.access === "read" &&
                    "settings-share-selected-access "
                  }`}
                  onClick={() => this.setState({ access: "read" })}
                >
                  Read Only
                </Button>
              </div>
            </div>
          </div>
          <div className="col">
            <Button
              className="settings-share-confirm-btn"
              isDisabled={!email || !access}
              onClick={this.handleOnConfirm}
            >
              CONFIRM
            </Button>
          </div>
        </div>

        <p className="settings-share-titles mt-5">Members</p>

        <div className="row settings-share-section">
          <div className="col">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col" className="settings-share-table-th">
                    Name
                  </th>
                  <th scope="col" className="settings-share-table-th">
                    Email Address
                  </th>
                  <th scope="col" className="settings-share-table-th">
                    Modified
                  </th>
                  <th scope="col" className="settings-share-table-th">
                    Permission
                  </th>
                  <th scope="col" className="settings-share-table-th"></th>
                </tr>
              </thead>
              <tbody>{this.renderTableDataForShare()}</tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { sideBarList } = this.state;
    // const isShareTab =
    // sideBarList.filter((s) => s.isSelected)[0].value === "_share";

    return (
      <div className="row m-0 h-100">
        <BotLayoutLeftPanel
          isSettingsPage={true}
          sideBarList={sideBarList}
          handleSelect={this.handleSideBarSelection}
        />

        <BotLayoutContent isSettingsPage>
          <div
            className={`col bg-light mt-2 h-100 ${
              this.state.sideBarList.filter((s) => s.isSelected)[0].value !==
                "_websdkconfigurations" && "overflow-auto"
            }`}
            style={{
              width: "calc(100% - 16rem)",
            }}
          >
            {this.handleTabs()()}
          </div>
        </BotLayoutContent>
        {/* {isShareTab && <BotLayoutRightPanel />} */}
      </div>
    );
  }
}

export default function Settings(props) {
  const { setNotifications } = useContext(NotificationContext);
  const { getUserDetails } = useContext(UserContext);

  return (
    <SETTINGS_COMP
      {...props}
      setNotifications={setNotifications}
      permissions={getUserDetails().feature_permission}
      getUserDetails={getUserDetails}
    />
  );
}
