import React from "react";
import { useContext, useState } from "react";

import apiServices from "../../services/api.services";
import baseUrl from "../../services/baseURL.service";

import Input from "../../components/fields/input/input.component";
import Buttons from "../../components/fields/button/button.component";
import gnaniLogo from "../../assets/images/gnani-logoV.png";
import { NotificationContext } from "../../contexts/notficationContext";
import { UserContext } from "../../contexts/userContext";
import { setToken } from "../../services/authUtil";

import "./superAdmin.scss";

function SuperAdminLogin(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { setNotifications } = useContext(NotificationContext);
  const { setSuperAdminDetails, isSuperAdminLoggedIn } =
    useContext(UserContext);

  isSuperAdminLoggedIn(props.history, "/super_admin/list_orgs");

  const handleLogin = async (e) => {
    e.preventDefault();

    if (email && password) {
      const requestOptions = {
        body: JSON.stringify({ email, password }),
        headers: { "Content-Type": "application/json" },
        method: "POST",
        mode: "cors",
      };

      try {
        const { data, message, status } = await apiServices.fetchData(
          `${baseUrl}/super_admin_login`,
          requestOptions
        );
        if (status === "success") {
          props.history.push("/super_admin/list_orgs");
          setToken(data.token);
          setSuperAdminDetails(data);
        } else setNotifications({ color: "danger", message });
      } catch ({ message }) {
        setNotifications({ color: "danger", message });
      }
    }
  };

  return (
    <div className="row m-0 mt-5">
      <div className="col-4">{""}</div>
      <div className="justify-content-center col-4 mx-3">
        <div className="align-items-center text-center my-5 me-5 header-container">
          <img
            alt="Gnani Logo"
            className="img-fluid me-4 mb-1"
            src={gnaniLogo}
          />
          <span className="m-0">Super Admin Login</span>
        </div>
        <form className="mb-1 needs-validation" onSubmit={handleLogin}>
          <div className="align-items-center text-center mb-5">
            <div className="row m-0 mb-3">
              <div className="col-3 text-start m-auto name-font">User Name</div>
              <div className="col">
                <Input
                  className="user-input px-2"
                  placeholder="Enter your user name"
                  value={email}
                  onChange={({ target }) => setEmail(target.value)}
                />
              </div>
            </div>
            <div className="row m-0">
              <div className="col-3 text-start m-auto name-font">Password</div>
              <div className="col">
                <Input
                  className="user-input px-2"
                  placeholder="Enter your password"
                  type="password"
                  value={password}
                  onChange={({ target }) => setPassword(target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row m-0 align-items-center justify-content-center">
            <Buttons className="col-auto me-3 login-btn-cancel">Cancel</Buttons>
            <Buttons
              className="col-auto ms-3 super-login-btn-confirm"
              type="submit"
            >
              Confirm
            </Buttons>
          </div>
        </form>
      </div>
      <div className="col-4">{""}</div>
    </div>
  );
}

export default SuperAdminLogin;
