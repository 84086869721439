import { useContext, useEffect, useState } from "react";

import DateRangePicker from "react-bootstrap-daterangepicker";
import DropDown from "../../components/fields/dropDown/dropDown.component";
import Input from "../../components/fields/input/input.component";

import getIcon from "../../services/icon.service";
import statService from "../../services/api.services/statisticsApi.service";

import { NotificationContext } from "../../contexts/notficationContext";
import Buttons from "../../components/fields/button/button.component";
import AddNewModal from "../../components/modal/modal.component";

const conDetailsLeft = [
  {
    label: "Conversation Duration",
    value: "conversation_end_time",
    iconName: "time",
  },
  {
    label: "Date",
    value: "conversation_date",
    iconName: "time",
  },
  {
    label: "Time",
    value: "conversation_start_time",
    iconName: "time",
  },
  {
    label: "Conversation split",
    value: "conversation_split",
    iconName: "message",
  },
];
const conDetailsRight = [
  {
    label: "Messages received",
    value: "messages_received",
    iconName: "sendMsg",
  },
  {
    label: "Messages Sent",
    value: "messages_sent",
    iconName: "receiveMsg",
  },
  {
    label: "Fallback",
    value: "fallback_count",
    iconName: "thunder",
  },
];

const CallStats = (props) => {
  const [state, setState] = useState({
    chatHistory: {},
    chatListCount: 10,
    chatList: {
      copy: [],
      count: "",
      original: [],
    },
    conversationId: "",
    fields: {
      search: "",
      isChecked: false,
      isModal: false,
      isAccordian: undefined,
    },
    selectedConvIds: [],
  });

  const [audio, setAudio] = useState("");
  const [isModal, setModal] = useState(false);
  const [conversationLog, setConversationLog] = useState([]);
  const [trueVoice, setTrueVoice] = useState({});

  const toggle = () => {
    setModal(!isModal);
  };

  const { setNotifications } = useContext(NotificationContext);
  useEffect(() => {
    let conversationData = {
      conversation_id: state.conversationId,
      conversation_log: conversationLog,
    };
    getGptStats(conversationData);
  }, [state.conversationId]);
  useEffect(() => getChatList(), [state.chatListCount]);

  const getChatList = async () => {
    try {
      const r = await statService.getChatList("", "", state.chatListCount);

      if (r.status === "success") {
        const localState = { ...state };
        const { count, data } = r;

        localState.chatList.original = data;
        if (state.chatListCount > 10) {
          localState.chatList.copy = data.slice(state.chatListCount - 10);
        } else {
          localState.chatList.copy = data;
        }
        localState.chatList.count = count;

        setState(localState);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getChatHistory = async (chatId) => {
    try {
      const r = await statService.getChatHistory(chatId);
      if (r.status === "success") {
        const localState = { ...state };

        localState.chatHistory = r.data;
        localState.conversationId = chatId;
        setConversationLog(
          r.data.conversation.map((b) => {
            return { user: b.user, bot: b.bot[0].responses.text };
          })
        );

        setState(localState);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getGptStats = (data) => {
    statService
      .getGptStats(data)
      .then((r) => {
        if (r.status === "success") {
          console.log(r.data);
          let truevoice = {
            Sentiments: r.data.sentiment,
            TrueVoice: r.data.true_voice,
            Keywords: r.data.keywords,
            Objections: r.data.objections,
            ActionPlan: r.data.action_plan,
          };
          setTrueVoice(truevoice);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getCallAudio = async (body) => {
    try {
      const r = await statService.getCallAudio(body);

      if (r) {
        const url = window.URL.createObjectURL(r);
        setAudio(url);
      }
    } catch (e) {
      console.log("Failed: ", e);
    }
  };

  const exportChatConversation = async () => {
    try {
      const r = await statService.exportChatConversation(state.conversationId);
      handleDownload(r);
    } catch (e) {
      setNotifications({ color: "danger", message: e.message });
      console.log(e);
    }
  };

  const exportChatListConversation = async () => {
    try {
      const localState = { ...state };
      const listOfConv = localState.selectedConvIds.toString();

      const r = await statService.exportChatListConversation(listOfConv);

      if (r.status === "success") {
        handleDownload(r.data);

        localState.chatList.copy = localState.chatList.original.map((c) => {
          c.isChecked = false;
          return c;
        });

        localState.fields.isChecked = false;
        localState.selectedConvIds = [];

        setState(localState);
      } else {
        setNotifications({ color: "danger", message: r.message });
        console.log(r);
      }
    } catch (e) {
      setNotifications({ color: "danger", message: e.message });
      console.log(e);
    }
  };

  const handleSearch = ({ target }) => {
    const localState = { ...state };
    const { value } = target;
    const regex = new RegExp(value);

    localState.chatList.copy = localState.chatList.original.filter((c) =>
      c.conversation_id.match(regex)
    );
    localState.fields.search = value;

    setState(localState);
  };

  const handleClearSearch = () => {
    const localState = { ...state };

    localState.chatList.copy = localState.chatList.original;
    localState.fields.search = "";

    setState(localState);
  };

  const handleNextRPrevChatList = (type) => {
    const localState = { ...state };

    if (
      type === "right" &&
      localState.chatListCount < localState.chatList.count
    ) {
      localState.chatListCount = localState.chatListCount + 10;
      setState(localState);
    }

    if (type === "left" && localState.chatListCount > 10) {
      localState.chatListCount = localState.chatListCount - 10;
      setState(localState);
    }
  };

  const handleDownload = (data) => {
    const url = window.URL.createObjectURL(
      new Blob([JSON.stringify(data)], {
        type: "application/json",
      })
    );
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", "file.json");

    document.body.appendChild(link);

    link.click();
    setNotifications({ color: "success", message: "Export successful" });
  };

  const toggleCheckBox = ({ target }, index, item) => {
    const localState = { ...state };
    const { checked } = target;

    if (index !== undefined) {
      localState.chatList.copy[index].isChecked = checked;
      if (checked) {
        localState.selectedConvIds.push(item.conversation_id);
      } else {
        localState.selectedConvIds = localState.selectedConvIds.filter(
          (s) => s !== item.conversation_id
        );
      }
      localState.fields.isChecked = false;

      const isEvery = localState.chatList.copy.every((c) => c.isChecked);

      isEvery && (localState.fields.isChecked = checked);
    } else {
      localState.chatList.copy = localState.chatList.original.map((c) => {
        if (checked) {
          if (!localState.selectedConvIds.includes(c.conversation_id)) {
            localState.selectedConvIds.push(c.conversation_id);
          }
        }
        c.isChecked = checked;
        return c;
      });

      localState.fields.isChecked = checked;

      if (!checked) localState.selectedConvIds = [];
    }

    setState(localState);
  };

  const renderChatList = () => {
    return state.chatList.copy.map((item, index) => (
      <div
        className={`row chat-stats-chat-data-container ${
          state.conversationId === item.conversation_id && "selected-chatList"
        }`}
        key={index}
      >
        <div className="col-auto pe-1 mt-1">
          <Input
            type="checkbox"
            checked={item.isChecked}
            onChange={(e) => toggleCheckBox(e, index, item)}
          />
        </div>
        <div
          className="col"
          onClick={() => {
            getChatHistory(item.conversation_id);
            getCallAudio({
              call_date: item.conversation_date,
              conversation_id: item.conversation_id,
            });
          }}
        >
          <p className="chat-list-con-id">{item.conversation_id}</p>
          <p className="call-stats-pg-value m-0"> {item.conversation_date}</p>
        </div>
      </div>
    ));
  };

  const renderConDetails = (details) => {
    return details.map((c, i) => (
      <div className="row my-2" key={i}>
        <div
          className="col-auto pe-0"
          style={{ color: "#7B7B7B", width: "13rem" }}
        >
          {getIcon(c.iconName)}
          <span className="chat-con-details-key">{c.label}</span>
        </div>
        <div className="col chat-con-details-value">
          {state.chatHistory[c.value]}
        </div>
      </div>
    ));
  };

  // const handleAccToggle = (index) => {
  //   const localState = { ...state };

  //   if (localState.fields.isAccordian !== index) {
  //     localState.fields.isAccordian = index;
  //   } else {
  //     localState.fields.isAccordian =
  //       localState.fields.isAccordian === undefined ? index : undefined;
  //   }

  //   setState(localState);
  // };

  const handleString = (str) => {
    if (str && str.includes("\\n")) {
      let temp = str
        .split("\\n")
        .map((s) => s.trim())
        .filter((s) => s.length > 0)
        .map((s) => {
          if (s.includes("*")) {
            let x = s
              .split("*")
              .filter((s) => s.length > 0)
              .map((s) => (s[s.length - 1] !== ":" ? "*".concat(s) : s));
            return x;
          } else {
            return s;
          }
        });
      return temp;
    } else if (str && str.includes("\n")) {
      let temp = str
        .split("\n")
        .map((s) => s.trim())
        .filter((s) => s.length > 0)
        .map((s) => {
          if (s.includes("*")) {
            let x = s
              .split("*")
              .filter((s) => s.length > 0)
              .map((s) => (s[s.length - 1] !== ":" ? "*".concat(s) : s));
            return x;
          } else {
            return s;
          }
        });
      return temp;
    } else if (str && str.includes("*")) {
      let temp = str
        .split("*")
        .filter((s) => s.length > 0)
        .map((s) => (!s.endsWith(":") ? "*".concat(s) : s));
      console.log(temp);
      return temp;
    } else {
      return str;
    }
  };

  const getUserClass = (c) => {
    let classes = "chat-stat-chat-history ";
    classes +=
      c?.bot_meta?.primary_intent_classifier?.primary_intent?.intent_name ===
      "nlu_fallback"
        ? "bg-warning"
        : "chat-user-reply";
    return classes;
  };

  const renderConversation = () => {
    return state.chatHistory.conversation.map((c, i) => (
      <div className="row" key={i}>
        <div className="col d-flex align-items-end flex-column">
          <div className={getUserClass(c)}>
            {c?.bot_meta?.primary_intent_classifier?.primary_intent
              ?.intent_name === "nlu_fallback" ? (
              <i className="me-2 fa fa-exclamation-circle"></i>
            ) : (
              ""
            )}
            {c.user && c.user}
          </div>
          {/* <p
            className="debug-info"
            onClick={() => handleAccToggle(i)}
          >{`> Debug info`}</p> */}
          {state.fields.isAccordian === i && JSON.stringify(c.bot_meta)}
        </div>
        {c.bot?.length > 0 &&
          c.bot.map((conv, inx) => (
            <div className="col-12" key={inx}>
              {!!Object.keys(conv.responses).length && (
                <div className="chat-stat-chat-history chat-bot-reply">
                  {typeof handleString(
                    conv.responses?.text || conv.responses[0]?.text
                  ) === "object"
                    ? handleString(
                        conv.responses?.text || conv.responses[0]?.text
                      ).map((s) =>
                        typeof s === "object" ? (
                          s.map((st) => <p className="m-0">{st}</p>)
                        ) : (
                          <p className="m-0">{s}</p>
                        )
                      )
                    : handleString(
                        conv.responses?.text || conv.responses[0]?.text
                      )}
                  {conv.responses?.image && (
                    <div className="text-center my-2">
                      <img alt="Chat" src={conv.responses?.image} />
                    </div>
                  )}

                  {conv.responses?.buttons?.length > 0 &&
                    conv.responses?.buttons.map((btn, ibx) => (
                      <button
                        className="btn btn-secondary m-1"
                        disabled
                        key={ibx}
                      >
                        {btn.title}
                      </button>
                    ))}
                  {conv.responses.image && conv.responses.image !== "" && (
                    <image src={conv.responses.image} />
                  )}
                </div>
              )}
            </div>
          ))}
      </div>
    ));
  };

  const handleModal = () => {
    const localState = { ...state };

    localState.fields.isModal = !localState.fields.isModal;

    setState(localState);
  };

  const handleDropDown = ({ value }) => {
    const localState = { ...state };

    switch (value) {
      case "_date":
        return (localState.fields.isModal = false);
      default:
        return setState(localState);
    }
  };

  const handleDateCancel = () => {
    const localState = { ...state };

    localState.fields.isModal = false;

    setState(localState);
  };

  return (
    <div className="h-100">
      <div className="row h-100">
        <div className="col-auto h-100" style={{ width: "20rem" }}>
          <div className="call-stats-left-panel">
            <div className="row align-items-center">
              <div className="col call-stats-left-panel-header-inp-cont">
                <div>
                  <i
                    className="fas fa-search ms-2"
                    style={{ color: "#707070" }}
                  />
                </div>
                <Input
                  className="call-stats-left-panel-header-inp"
                  placeholder="Search..."
                  onChange={handleSearch}
                  value={state.fields.search}
                />
                <div className="cursor-pointer" onClick={handleClearSearch}>
                  {getIcon("refresh")}
                </div>
              </div>

              <div className="col-auto p-0">
                <DropDown
                  disabled={true}
                  actionButton={() => getIcon("filter")}
                  btnClassName="px-2 ps-3 disabled-dropdown-btn"
                  dropdownList={[
                    { label: "Channels", value: "_channel" },
                    { label: "Archieve", value: "_archieve" },
                    { label: "Conversation ID", value: "_conversationId" },
                    {
                      label: (
                        <DateRangePicker
                          initialSettings={{ alwaysShowCalendars: true }}
                          onCancel={handleDateCancel}
                          onCallback={handleDateCancel}
                        >
                          <button className="btn p-0">Date Picker</button>
                        </DateRangePicker>
                      ),
                      value: "_date",
                    },
                    { label: "Recently added", value: "_recent" },
                  ]}
                  onActionItemClick={handleDropDown}
                  manualAction
                  manualOpen={false}
                  manualOnClick={handleModal}
                />
              </div>

              <div className="col-auto p-0">
                {props.permissions.export !== "hide" && (
                  <DropDown
                    actionButton={() => getIcon("verticalOptions")}
                    btnClassName={`p-0 ${
                      !state.selectedConvIds.length && "disabled-dropdown-btn"
                    }`}
                    dropdownList={[{ label: "Export" }]}
                    onActionItemClick={exportChatListConversation}
                    disabled={!state.selectedConvIds.length}
                  />
                )}
              </div>
            </div>

            <div className="row mt-4 align-items-center py-2">
              <div className="col-auto pe-1">
                <Input
                  type="checkbox"
                  checked={state.fields.isChecked}
                  onChange={toggleCheckBox}
                />
              </div>
              <div className="col-auto pe-0">
                <span className="call-stats-all-sel-sec-label">
                  Conversations
                </span>
              </div>
              <div className="col pe-0">
                <span className="call-stats-pg-value">
                  {state.chatListCount === 10 ? 1 : state.chatListCount - 9}-
                  {state.chatListCount < state.chatList.count
                    ? state.chatListCount
                    : state.chatList.count}{" "}
                  of {state.chatList.count}
                </span>
                <i
                  className="fas fa-angle-left mx-2 cursor-pointer"
                  onClick={() => handleNextRPrevChatList("left")}
                />
                <i
                  className="fas fa-angle-right cursor-pointer"
                  onClick={() => handleNextRPrevChatList("right")}
                />
              </div>
            </div>
            <div
              className="overflow-auto mt-1 pb-4"
              style={{ height: "calc(100% - 4.5rem)" }}
            >
              {renderChatList()}
            </div>
          </div>
        </div>

        {!!Object.keys(state.chatHistory).length && (
          <div
            className="col overflow-auto h-100 mt-4"
            style={{ padding: "0 5%" }}
          >
            <div className="row align-items-center mb-2">
              <div className="col pe-0">
                <span className="chat-stats-right-panel-title">
                  Conversation Id:
                </span>{" "}
                <span className="chat-stats-right-panel-val">
                  {state.conversationId}
                </span>
              </div>
              <div className="col-auto">
                <div className="row">
                  <div className="col-auto p-0 m-0">
                    <Buttons
                      className="intents-btn intent-font-save rounded py-2"
                      onClick={() => {
                        // let conversationData = {
                        //   conversation_id: state.conversationId,
                        //   conversation_log: conversationLog,
                        // };
                        // getGptStats(conversationData);
                        toggle();
                      }}
                    >
                      Details
                    </Buttons>
                  </div>
                  <div className="col-auto">
                    {props.permissions.export !== "hide" && (
                      <DropDown
                        actionButton={() => getIcon("verticalOptions")}
                        dropdownList={[{ label: "Export" }]}
                        onActionItemClick={() => exportChatConversation()}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row align-items-center mb-2">
              <div className="col-auto p-2 chat-stats-history-type-cont">
                {getIcon(state.chatHistory.channel, "", "", 18)}
                <span className="chat-stats-history-type">
                  {state.chatHistory.channel}
                </span>
              </div>
            </div>
            <div className="row">
              {state.chatHistory.first_name && (
                <div className="col-auto shared-user-info-container chat-stat-user-info me-2">
                  <i className="fas fa-user me-2" />
                  <span>{state.chatHistory.first_name}</span>
                </div>
              )}
              {state.chatHistory.phone_number && (
                <div className="col-auto shared-user-info-container chat-stat-user-info mx-2">
                  <i className="fas fa-phone me-2" />
                  <span>{state.chatHistory.phone_number}</span>
                </div>
              )}
              {state.chatHistory.email && (
                <div className="col-auto shared-user-info-container chat-stat-user-info">
                  <i className="fas fa-envelope me-2" />
                  <span>{state.chatHistory.email}</span>
                </div>
              )}
            </div>

            <div className="row me-4" style={{ marginTop: "6%" }}>
              <p className="chat-con-details-heading">Conversation Details</p>
              <div className="col">{renderConDetails(conDetailsLeft)}</div>
              <div className="col-auto">
                {renderConDetails(conDetailsRight)}
              </div>
            </div>

            <div className="row h-100" style={{ marginTop: "6%" }}>
              <div className="col">
                <div className="row m-0 me-3">
                  <p className="chat-con-details-heading col p-0">
                    User Conversation
                  </p>
                  <div className="col-auto chat-con-details-key">
                    {audio &&
                    ["gnani_voice", "twilio_voice"].includes(
                      state.chatHistory.channel
                    ) ? (
                      <audio controls src={audio}></audio>
                    ) : ["gnani_voice", "twilio_voice"].includes(
                        state.chatHistory.channel
                      ) ? (
                      "No audio available"
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="chat-stats-chat-details">
                  {renderConversation()}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <AddNewModal
        toggle={toggle}
        noCloseBtn={false}
        addNewModalOpen={isModal}
        headerClassName={"ps-4 ms-4"}
        title={"True Voice"}
        children={
          <div className="p-3 m-3">
            <table class="table table-bordered table-md">
              <thead>
                <tr>
                  <th
                    scope="col"
                    style={{
                      fontSize: "20px",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    Parameters
                  </th>
                  <th
                    scope="col"
                    style={{
                      fontSize: "20px",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    Description
                  </th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(trueVoice).map((p) => {
                  return (
                    <tr>
                      <th
                        scope="row"
                        style={{ fontWeight: "400", textAlign: "center" }}
                      >
                        {p}
                      </th>
                      {p === "Keywords" ? (
                        <td>
                          {trueVoice[p] === null ? (
                            <td style={{ color: "#51597E" }}>none</td>
                          ) : (
                            <div className="row px-2">
                              {trueVoice[p].map((d) => {
                                return (
                                  <div
                                    className="col-auto px-2 py-1 m-1"
                                    style={{
                                      color: "#51597E",
                                      border: "1px solid #51597E",
                                      borderRadius: "3px",
                                    }}
                                  >
                                    {d}
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </td>
                      ) : (
                        <td style={{ color: "#51597E" }}>
                          {trueVoice[p] === null ? "none" : trueVoice[p]}
                        </td>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        }
      ></AddNewModal>
    </div>
  );
};

export default CallStats;
