import React, { Component, useContext, createRef } from "react";

import vmsg from "vmsg";
import AceEditor from "react-ace";
import BotLayoutContent from "../../components/botLayout/botLayoutContent.component";
import HorizontalScroll from "../../components/horizontalScroll/resHorizontalScroll";
import BotLayoutLeftPanel from "../../components/botLayout/botLayoutLeftPanel.component";
import BotLayoutRightPanel from "../../components/botLayout/botLayoutRightPanel.component";

import botService from "../../services/bot.service";
import apiService from "../../services/api.services";
import { BotContext } from "../../contexts/BotContext";
import { UserContext } from "../../contexts/userContext";
import { getImageUrl } from "../../services/baseURL.service";
import BulkImport from "../../components/bulkImport.component";
import faqServices from "../../services/api.services/faqsApi.service";
import { NotificationContext } from "../../contexts/notficationContext";
import resService from "../../services/api.services/utteranceApi.service";
import actionServices from "../../services/api.services/actionsApi.service";

import getIcon from "../../services/icon.service";
import Modal from "../../components/modal/modal.component";
import Toggle from "../../components/fields/toggle/toggle.jsx";
import Input from "../../components/fields/input/input.component";
import Buttons from "../../components/fields/button/button.component";
import DropDown from "../../components/fields/dropDown/dropDown.component";
import DeleteConfirmation from "../../components/deleteConfirmation.component";

import "./faqs.scss";

const recorder = new vmsg.Recorder({
  wasmURL: "https://unpkg.com/vmsg@0.3.0/vmsg.wasm",
});

class Faqs extends Component {
  static contextType = BotContext;
  constructor(props) {
    super(props);
    this.state = {
      faqsList: [],
      trainedFaqsList: [],
      selectedFaq: "",
      addNewFaq: "",
      faqNewQuestion: "",
      faqNewAnswer: "",
      isSelected: false,
      faqOldName: null,
      faqNewName: null,
      oldQuestion: "",
      oldAnswer: "",
      modal: false,
      listId: "",
      oldCode: "",
      isEditBtnModal: null,
      isAddImgModal: null,
      isAddBtnModal: false,
      isAddPhraseAudio: null,
      delPhraseAudio: false,
      isRecording: false,
      isMicLoading: false,
      currentlyPlaying: null,
      isDelFaqActionModal: false,
      isDelFaqAnswersModal: false,
      fetchedAudio: { url: "", index: "" },
      modalInps: { title: "", value: "" },
      dynamic: { show: false, type: "", actionName: "" },
      addNewHeader: { key: "", value: "", oldIndex: null, error: false },
      changeActionType: { show: false, type: "" },
      channels: [],
      channelsCopy: [],
      navBarMenu: [{ label: "Default", value: "Default" }],
      selectedChannel: null,
      selectedChannelFaq: {
        newPhraseName: "",
        nonTrained: [],
        trained: [],
      },
      phrasesToDelete: [],
      uploadingPhrase: {
        buttons: [],
        image: "",
        audios: [],
      },
      addBtnToNewPhrase: {
        title: "",
        value: "",
      },
      isDelete: {
        isShown: false,
        index: "",
        selectedBtns: [],
      },
      phraseAudios: [],
      bulkImportModal: false,
    };
    this.dropDownBtnNewTitle = this.utilizeFocus();
    this.dropDownBtnNewVal = this.utilizeFocus();
    this.sharePhraseRef = React.createRef();
    this.listIdRef = React.createRef();
    this.phraseIndex = "";
    this.listIdRef = React.createRef();
  }

  // setNavBarButtons() {
  //   this.context.setNavBarButtons(
  //     <div className="row">
  //       <div className="col-auto">
  //         <BulkImportComp
  //           onPageRefresh={() => {
  //             this.onPageRefresh();
  //           }}
  //         />
  //       </div>
  //     </div>
  //   );
  // }

  bulkImportComp = () => {
    return (
      <BulkImport
        isFile
        open={this.state.bulkImportModal}
        onlyIcon={true}
        sampleUrl="/sample_bulk_import_faq_sample_json_file"
        sampleFileName="Faq-Sample.json"
        description={{
          title:
            "Import a text file with paragraph or You can also import json file as per Faq-Sample.json.",
          format: "",
        }}
        importFile={"Select the file to import Faqs"}
        confirmSuccess={(e) => {
          let formData = new FormData();
          formData.append("file", e.target.files[0]);
          faqServices.importFaq(e.target.files[0]).then((response) => {
            if (response.status === "success") {
              this.onPageRefresh();
              this.props.setNotifications({
                color: "success",
                message: response.message,
              });
            } else {
              this.props.setNotifications({
                color: "danger",
                message: response.message,
              });
            }
          });
        }}
        allowedExts=".txt,.json"
      />
    );
  };

  onClickImport = () => {
    this.setState({ bulkImportModal: true });
    setTimeout(() => {
      this.setState({ bulkImportModal: false });
    }, 500);
  };

  utilizeFocus = () => {
    const ref = createRef();
    const setFocus = () => {
      ref.current && ref.current.focus();
    };

    return { setFocus, ref };
  };

  componentWillUnmount() {
    this.context.setNavBarButtons("");
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  componentDidMount() {
    this.getBotFaqs();
    this.getChannels();
    document.addEventListener("mousedown", this.handleOutsideClick);
  }

  handleOutsideClick = ({ target }) => {
    if (this.listIdRef.current && !this.listIdRef.current.contains(target)) {
      this.setState({ listId: "" });
    }
  };

  getChannels = async () => {
    try {
      const r = await apiService.getChannels();

      if (r.status === "success") {
        let channels = r.data
          .filter((c) => c.category === "chat" || c.category === "voice")
          .map((c, i) => ({
            label: c.name,
            isSelected: i === 0,
            isUsed: false,
            value: c.key,
          }));
        let shareList = channels.map((ele) => {
          return {
            label: ele.label,
            icon2: getIcon(ele.value),
            isUsed: false,
            isSelected: false,
            value: ele.value,
          };
        });
        this.setState({
          channels,
          shareList,
          shareListCopy: shareList,
          channelsCopy: channels.unshift({
            label: "Default",
            value: "Default",
          }),
        });
      }
    } catch (e) {
      console.log("Failed: ", e);
    }
  };

  getBotFaqs = async () => {
    try {
      const r = await faqServices.getBotFaqs();

      if (r.status === "success") {
        const faqsList = r.data.map((d) => ({ label: d, isSelected: false }));

        return this.setState({
          faqsList,
          trainedFaqsList: r.trained,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  getBotFaqsDetails(intent) {
    faqServices
      .getBotFaqDetails(intent)
      .then((res) => {
        if (res.status === "success") {
          const { selectedChannel } = this.state;
          const phraseChannels = [];
          let channels = [...this.state.channels];
          const selectedChannelFaq = { ...this.state.selectedChannelFaq };
          let phraseAudios = [];

          if (selectedChannel && selectedChannel !== "Default") {
            selectedChannelFaq.nonTrained = res.data.answers.filter(
              (obj) => obj.channel === selectedChannel
            );
          } else {
            selectedChannelFaq.nonTrained = res.data.answers.filter(
              (obj) => !obj.hasOwnProperty("channel")
            );
          }

          selectedChannelFaq.nonTrained.forEach((ele, i) => {
            if (ele.text.includes(".wav")) {
              ele.text.split(" ").forEach((e) => {
                if (e.includes(".wav]") && e.includes("["))
                  phraseAudios.push({
                    title: e.substring(1, e.length - 1),
                    isSelected: false,
                    index: i,
                    channel: ele.channel,
                  });
                else if (e.includes(".wav"))
                  phraseAudios.push({
                    title: e,
                    isSelected: false,
                    index: i,
                    channel: ele.channel,
                  });
              });
            }
          });

          res.data.answers.forEach((d) => {
            const { channel } = d;
            if (channel) phraseChannels.push(channel);
          });

          if (phraseChannels.length) {
            channels = channels.map((c, i) => {
              if (phraseChannels.includes(c.value)) {
                c.isUsed = true;
                c.isSelected = false;
              } else {
                c.isUsed = false;
                c.isSelected = false;
              }
              return c;
            });
          } else {
            channels = channels.map((c, i) => {
              if (i === 0) {
                c.isSelected = true;
              }
              return c;
            });
          }

          let temp = [
            { label: "Default", value: "Default" },
            ...channels.filter((e) => e.isUsed),
          ];

          selectedChannelFaq.nonTrained.map((obj) => {
            obj["isSelected"] = false;
            return obj;
          });

          this.setState({
            faqDetails: {
              questions: res.data.questions,
              answers: res.data.answers,
              faq: res.data.faq,
            },
            trainedFaqDetails: res.trained,
            selectedChannelFaq,
            navBarMenu: [...temp],
            phraseAudios: phraseAudios,
          });
        }
      })
      .catch((error) => {
        this.props.setNotifications({
          color: "danger",
          message: error.message,
        });
      });
  }

  getBracketedText = (ele) => {
    if (ele.text.includes(".wav")) {
      ele.text = ele.text
        .split(" ")
        .map((str) => {
          if (
            str.includes(".wav") &&
            !str.includes(".wav]") &&
            !str.includes("[")
          )
            return `[${str}]`;
          else return str;
        })
        .join(" ");
      return ele;
    } else return ele;
  };

  getFaqActionDetails(action) {
    actionServices.getActionDetails(action, true).then((res) => {
      if (res.status === "success") {
        res.data[0] && Object.keys(res.data[0]).length > 0
          ? this.setState({
              actionDetails: res.data[0],
              trainedActionDetails: res.trained,
              dynamic: {
                show: true,
                type: res.data[0]?.type ? "Code" : "Config API",
                actionName: action,
              },
              actionData: true,
            })
          : this.setState({
              actionDetails: null,
              dynamic: {
                show: false,
                type: null,
                actionName: null,
              },
            });
      }
    });
  }

  addFaqAction = (type) => {
    let payload = { action: this.state.faqDetails.faq };
    if (type === "Code") {
      payload.data = [
        {
          type: "code",
          code:
            `def ${this.state.faqNewName}` +
            "(bot_details):\n" +
            '    """\n' +
            "    Note: action name and the function name should match\n" +
            "    Mandtory: response_object has to be returned. \n" +
            '    """\n' +
            "    # Response object - it can be used to send back response messages and dialog instances\n" +
            "    response_object = Response()\n" +
            '    response_object.add_response(text="Sending Sample message")\n' +
            "    # response object has to be returned\n" +
            "    return response_object",
        },
      ];
    } else {
      payload.data = [
        { url: "", headers: { token: botService.getSelectedBotId() } },
      ];
    }
    actionServices
      .addBotAction(payload, true)
      .then((res) => {
        if (res.status === "success") {
          this.getBotFaqsDetails(this.state.selectedFaq);
          this.getFaqActionDetails(this.state.selectedFaq);
          setTimeout(() => {
            this.toggle("_update", this.state.faqDetails);
          }, 1000);
          this.props.setNotifications({
            color: "success",
            message: res.message,
          });
        } else {
          this.props.setNotifications({
            color: "danger",
            message: res.message,
          });
        }
      })
      .catch((err) =>
        this.props.setNotifications({
          color: "danger",
          message: err.message,
        })
      );
  };

  delFaqAction = (name, callback) => {
    actionServices
      .deleteBotAction(name, true)
      .then((r) => {
        if (r.status === "success") {
          callback && callback();
        }
      })
      .catch((e) =>
        this.props.setNotifications({ color: "danger", message: e.message })
      );
  };

  renameFaqAction = (body, callback) => {
    actionServices
      .renameBotAction(body, true)
      .then((res) => {
        if (res.status === "success") {
          this.props.setNotifications({
            color: "success",
            message: res.message,
          });
          callback();
        } else {
          this.props.setNotifications({
            color: "danger",
            message: res.message,
          });
        }
      })
      .catch((e) =>
        this.props.setNotifications({
          color: "success",
          message: e.message,
        })
      );
  };

  uploadAudio = async (formData, faq, newPhrase) => {
    try {
      const r = await resService.uploadAudio(formData);
      if (r.status === "success")
        this.setState({ isAddPhraseAudio: null }, () => {
          this.getBotFaqsDetails(faq);
          this.props.setNotifications({
            color: "success",
            message: r.message,
          });
        });
      else
        this.props.setNotifications({
          color: "danger",
          message: r.message,
        });
    } catch (e) {
      this.props.setNotifications({ color: "danger", message: e.message });
    }
  };

  getPhraseAudio = (index, title) => {
    const body = {
      response_type: "faq_utterances",
      utterance_name: this.state.selectedFaq,
      file_names_list: [title],
    };

    resService
      .getAudio(body)
      .then((res) => {
        if (res.status === "success") {
          this.setState({
            fetchedAudio: { url: res.data[title], index: index },
          });
        } else {
          this.props.setNotifications({
            color: "danger",
            message: res.message,
          });
        }
      })
      .catch((e) =>
        this.props.setNotifications({ color: "danger", message: e.message })
      );
  };

  handlePhraseImgUpload = async ({ target }) => {
    const file = target.files;

    if (file.length) {
      const formData = new FormData();

      formData.append("file", file[0]);

      try {
        const r = await resService.uploadImgForUtterance(formData);

        if (r.status === "success") {
          const { uploadingPhrase, selectedChannelFaq } = this.state;
          let { faqDetails } = this.state;
          const localPhrase = { ...selectedChannelFaq };
          const newPhrase = { ...uploadingPhrase };

          if (this.phraseIndex !== "") {
            localPhrase.nonTrained[this.phraseIndex].image = r.url;
            let phrase = localPhrase.nonTrained[this.phraseIndex];
            let mainIndex = faqDetails.answers.findIndex(
              (ele) => JSON.stringify(ele) === JSON.stringify(phrase)
            );
            faqDetails.answers[mainIndex].image = r.url;
          } else {
            newPhrase.image = r.url;
          }
          this.setState(
            {
              faqDetails,
              uploadingPhrase: newPhrase,
              isAddImgModal: false,
            },
            () => {
              if (this.phraseIndex !== "") {
                this.toggle("_update", faqDetails);
                this.phraseIndex = "";
              }
            }
          );
        }
      } catch (e) {
        console.log(`Failed: ${e}`);
      }
      target.value = "";
    }
  };

  handleNewAudioUpload = () => {
    const { uploadingPhrase, faqDetails, selectedFaq } = this.state;
    let index = faqDetails.answers.length - 1;

    if (!!uploadingPhrase.audios?.length) {
      const formData = new FormData();
      formData.append("response_type", "faq_utterances");
      formData.append("utterance", selectedFaq);
      formData.append("audio_file_index", 0);

      uploadingPhrase.audios?.map((file, i) => {
        // let file = new File([blob], `${response.resName}_${i}.wav`, {
        //   type: "audio/wav",
        // });
        formData.append(
          "file",
          file,
          `${selectedFaq}_${index}_${i}.${file.name.split(".")[1]}`
        );
      });

      setTimeout(() => {
        this.setState({
          uploadingPhrase: {
            buttons: [],
            image: "",
            audios: [],
          },
        });
        this.uploadAudio(formData, selectedFaq);
      }, 100);
    }
  };

  toggle(action, index, newName) {
    let { selectedFaq, dynamic } = this.state;

    if (action === "_update") {
      faqServices
        .updateBotFaq(
          {
            answers: index.answers,
            questions: index.questions,
            faq: selectedFaq,
          },
          dynamic.show
        )
        .then((res) => {
          if (res.status === "success") {
            console.log(newName);
            newName
              ? this.handleNewAudioUpload()
              : this.getBotFaqsDetails(selectedFaq);
          }
        })
        .catch((err) =>
          this.props.setNotifications({
            color: "danger",
            message: err.message,
          })
        );
    } else if (action === "_delete") {
      faqServices
        .deleteBotFaq({ faq: index })
        .then((res) => {
          if (res.status === "success") {
            this.props.setNotifications({
              color: "success",
              message: res.message,
            });
            this.onPageRefresh();
          } else {
            this.props.setNotifications({
              color: "danger",
              message: res.message,
            });
          }
        })
        .catch((err) =>
          this.props.setNotifications({
            color: "danger",
            message: err.message,
          })
        );
    } else if (action === "_addFaq") {
      faqServices
        .addBotFaq({
          faq: index,
          questions: [],
          answers: [],
        })
        .then((res) => {
          if (res.status === "success") {
            this.props.setNotifications({
              color: "success",
              message: res.message,
            });
          } else {
            this.props.setNotifications({
              color: "danger",
              message: res.message,
            });
          }
        })
        .catch((err) =>
          this.props.setNotifications({
            color: "danger",
            message: err.message,
          })
        );
      this.onPageRefresh();
    } else if (action === "_rename") {
      faqServices
        .renameBotFaq({ old_name: index, new_name: newName })
        .then((res) => {
          if (res.status === "success") {
            this.props.setNotifications({
              color: "success",
              message: res.message,
            });
            this.renameFaqAction(
              { old_name: index, new_name: newName },
              this.getBotFaqs
            );
          } else {
            this.props.setNotifications({
              color: "danger",
              message: res.message,
            });
          }
        })
        .catch((err) =>
          this.props.setNotifications({
            color: "danger",
            message: err.message,
          })
        );
      // this.getBotFaqs();
    } else if (action === "_updateFaqAction") {
      actionServices
        .updateBotAction({ action: index, data: newName }, true)
        .then((res) => console.log(res))
        .catch((err) =>
          this.context.setNotifications({
            color: "danger",
            message: err.message,
          })
        );
    } else {
      return;
    }
  }

  handleOnActionItemClick = (menu, i) => {
    switch (menu.label) {
      case "Edit":
        let div = document.getElementById(i);
        div.querySelector(".bot-layout-greetings-inp").focus();
        break;
      case "Delete":
        this.setState({ modal: true });
        setTimeout(() => {
          this.setState({ modal: false });
        }, 100);
        break;
      default:
        break;
    }
  };

  handlePhraseDelImgOrBtn = (index, name) => {
    const { faqDetails, isDelete, selectedChannelFaq } = this.state;
    const nonTrained = [...faqDetails.answers];

    let temp = selectedChannelFaq.nonTrained.find((b, i) => i === index);

    let mainIndex = faqDetails.answers.findIndex(
      (obj) => obj.text === temp.text && obj.channel === temp.channel
    );

    if (name === "buttons") {
      nonTrained[mainIndex][name] = nonTrained[mainIndex][name].filter(
        (b) => !isDelete.selectedBtns.includes(b.title)
      );
    } else delete nonTrained[mainIndex][name];

    this.setState(
      {
        faqDetails: { ...faqDetails, answers: nonTrained },
        isDelete: { ...isDelete, isShown: false },
      },
      () => {
        // if (isDelete.selectedBtns.length)
        this.toggle("_update", faqDetails);
      }
    );
  };

  handleBtnDeleteSelection = ({ title }, pIndex, btnIndex) => {
    const isDelete = { ...this.state.isDelete };

    isDelete.index = pIndex;

    if (isDelete.isShown) {
      if (isDelete.selectedBtns.includes(title))
        isDelete.selectedBtns = isDelete.selectedBtns.filter(
          (b) => b !== title
        );
      else isDelete.selectedBtns.push(title);

      this.setState({ isDelete });
    } else {
      this.setState({ isEditBtnModal: btnIndex });
    }
  };

  handlePharseDelete = (index, obj, multiDel) => {
    let { faqDetails, channels, selectedChannelFaq } = this.state;

    let localChannels = [...channels];
    let channel;

    if (multiDel) {
      if (index.length > 0) {
        let temp = faqDetails.answers.filter(
          (ele) =>
            index.map((e) => JSON.stringify(e)).indexOf(JSON.stringify(ele)) ===
            -1
        );
        faqDetails.answers = temp;
        this.setState(
          {
            faqDetails,
            phrasesToDelete: [],
          },
          () => this.toggle("_update", faqDetails)
        );
      }
    } else {
      let mainIndex = faqDetails.answers.findIndex(
        (ele) => JSON.stringify(ele) === JSON.stringify(obj)
      );
      faqDetails.answers.splice(mainIndex, 1);

      this.setState(
        {
          faqDetails,
          channels: localChannels,
        },
        this.toggle("_update", faqDetails)
      );
    }
  };

  getDeleteModal = (action, index, isButton, obj, toggle) => {
    let { faqDetails, faqsList, selectedFaq, changeActionType } = this.state;
    return (
      <DeleteConfirmation
        isButton={isButton}
        isToggle={toggle}
        cancel={
          toggle &&
          (() =>
            this.setState({
              isDelFaqActionModal: false,
              isDelFaqAnswersModal: false,
              changeActionType: { show: false, type: "" },
            }))
        }
        open={
          action === "Delete Image"
            ? this.state.imgModal
            : action === "delete_action"
            ? this.state.isDelFaqActionModal
            : action === "change_action_Type"
            ? changeActionType.show
            : action === "delete_answers"
            ? this.state.isDelFaqAnswersModal
            : this.state.modal
        }
        btnClassName={"res-phrase-delete-btn"}
        delete={() => {
          if (action === "question") {
            faqDetails.questions.splice(index, 1);
            this.setState({ faqDetails }, () => {
              this.toggle("_update", faqDetails);
            });
          } else if (action === "faq") {
            faqsList.filter((faq) => faq !== selectedFaq);
            this.setState({ faqsList, selectedFaq: "" }, () => {
              this.toggle("_delete", selectedFaq);
            });
          } else if (action === "phrase") {
            this.handlePharseDelete(index, obj);
          } else if (action === "delete_btn") {
            this.handlePhraseDelImgOrBtn(index, "buttons");
          } else if (action === "Delete Image") {
            this.handlePhraseDelImgOrBtn(index, "image");
          } else if (action === "delete_audio") {
            this.handlePhraseAudioDel(index, obj);
          } else if (action === "delete_action") {
            this.setState({ isDelFaqActionModal: false }, () =>
              this.delFaqAction(index, () => {
                this.getBotFaqsDetails(selectedFaq);
                this.getFaqActionDetails(selectedFaq);
              })
            );
          } else if (action === "change_action_Type") {
            this.setState({ changeActionType: false }, () =>
              this.delFaqAction(index, () =>
                this.addFaqAction(changeActionType.type)
              )
            );
          } else if (action === "delete_answers") {
            let temp = { ...faqDetails, answers: [] };
            this.setState(
              {
                faqDetails: temp,
                dynamic: { show: true, type: "", actionName: "" },
              },
              this.toggle("_update", temp)
            );
          }
        }}
        buttons={() => (
          <div className="row">
            <div className="col-auto pe-0">{getIcon("delete", "white")}</div>
            <div className="col">
              {isButton === "isAudio" ? "Delete" : "Delete button"}
            </div>
          </div>
        )}
      />
    );
  };

  renderQuestions = () => {
    let {
      faqDetails,
      trainedFaqDetails,
      oldQuestion,
      onQuePhraseHover,
      listId,
    } = this.state;

    return (
      faqDetails &&
      faqDetails?.questions?.map((b, i) => (
        <div
          className={`col-12 greeting-msg-container rounded ${
            onQuePhraseHover === i ? " shadow-sm" : ""
          }`}
          style={{ height: "fit-content" }}
          key={i}
          id={i}
          onMouseEnter={() => this.setState({ onQuePhraseHover: i })}
          onMouseLeave={() => this.setState({ onQuePhraseHover: false })}
        >
          <div className="row m-0 align-items-center">
            <div className="col d-flex align-items-center py-2">
              <div className="img-fluid me-4">
                {trainedFaqDetails?.questions?.indexOf(b) > -1
                  ? getIcon("activeBot", "#25293D")
                  : getIcon("activeBot", "#e2e2e2")}
              </div>
              <Input
                isLiteration
                className="bot-layout-greetings-inp"
                value={b}
                readOnly={
                  this.props.permissions.knowledge_base.modify !== "enabled"
                }
                onChange={(e) => {
                  if (!oldQuestion) {
                    let oldQuestion = faqDetails.questions[i];
                    this.setState({ oldQuestion });
                  }
                  if (typeof e === "string") {
                    faqDetails.questions[i] = e;
                  } else {
                    faqDetails.questions[i] = e.target.value;
                  }
                  this.setState({ faqDetails });
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    this.toggle("_update", faqDetails);
                    let div = document.getElementById(i);
                    div.querySelector(".bot-layout-greetings-inp").blur();
                  }
                }}
                onBlur={() => {
                  if (oldQuestion && faqDetails?.questions[i] !== oldQuestion) {
                    this.toggle("_update", faqDetails);
                    this.setState({ oldQuestion: "" });
                  }
                }}
              />
            </div>
            <div
              className={`col-auto pe-1 ${
                onQuePhraseHover === i || listId === i ? " d-flex" : "d-none"
              }`}
              ref={this.listIdRef}
              onClick={() => this.setState({ listId: i })}
            >
              <DropDown
                dropdownList={[
                  { label: "Disable", id: i, icon: getIcon("disable") },
                  {
                    label: "Edit",
                    id: i,
                    icon: getIcon("edit"),
                    isUsed:
                      this.props.permissions.knowledge_base.modify !==
                      "enabled",
                  },
                  {
                    label: "Delete",
                    id: i,
                    icon: this.getDeleteModal("question", i),
                    isUsed:
                      this.props.permissions.knowledge_base.modify !==
                      "enabled",
                  },
                ]}
                actionButton={() => getIcon("verticalOptions")}
                onActionItemClick={(menu) =>
                  this.handleOnActionItemClick(menu, i)
                }
                btnClassName="px-0"
                isContent={true}
              />
            </div>
          </div>
        </div>
      ))
    );
  };

  // rendering list of faq answers separated based on channels
  renderAnswers = () => {
    let {
      faqDetails,
      trainedFaqDetails,
      oldAnswer,
      onAnsPhraseHover,
      listId,
      selectedChannelFaq,
      isDelete,
    } = this.state;
    return (
      selectedChannelFaq &&
      selectedChannelFaq?.nonTrained?.map((b, i) => (
        <div className="row m-0 mb-3 px-0" key={`${i}-answers`}>
          <div
            className="col-auto me-2 px-0"
            onClick={() =>
              this.props.permissions.knowledge_base.modify === "enabled" &&
              this.handleSelectPhrase(b)
            }
          >
            {b?.isSelected
              ? getIcon("check-box-marked")
              : getIcon("check-box-outline-blank")}
          </div>
          <div
            className={`col ps-0 utterence-sentence-bottom-panel${
              onAnsPhraseHover === i ? " shadow-sm" : ""
            }`}
            key={i}
            id={`${i}a`}
            onMouseEnter={() => this.setState({ onAnsPhraseHover: i })}
            onMouseLeave={() =>
              this.setState({
                onAnsPhraseHover: false,
                isDelete: {
                  isShown: false,
                  index: "",
                  selectedBtns: [],
                },
              })
            }
          >
            <div
              className={`row m-0 ps-2 align-items-center ${
                (b.image || !!b.buttons?.length) && "border-bottom"
              }`}
              style={{
                background: `${b?.isSelected ? "#F2F4FF" : "#fafafa"}`,
                border: `${b?.isSelected ? "1px solid #46569940" : ""}`,
              }}
            >
              <div className="col py-2">
                <div className="row">
                  <div className="col-auto u-bottom-panel-header ps-2 u-b-p-header-key">
                    Phrase:
                  </div>
                  <div className="col u-bottom-panel-header">
                    <Input
                      isLiteration
                      className="u-bottom-panel-inp"
                      readOnly={
                        this.props.permissions.knowledge_base.modify !==
                        "enabled"
                      }
                      onBlur={(e) => this.handlePhraseEdit(e, i, b, "onBlur")}
                      onChange={(e) => this.handlePhraseEdit(e, i, b)}
                      onKeyPress={(e) => this.handlePhraseEdit(e, i, b)}
                      value={b.text}
                    />
                  </div>
                </div>
              </div>
              <div
                className={`col-auto pe-1 ${
                  onAnsPhraseHover === i || listId === i ? " d-flex" : "d-none"
                }`}
                ref={this.listIdRef}
                onClick={() => this.setState({ listId: i })}
              >
                {isDelete.isShown && isDelete.index === i ? (
                  <div className="row align-items-center">
                    <div className="col">
                      <p className="res-phrase-delete-imp-text">
                        * You can Select multiple buttons
                      </p>
                    </div>
                    <div className="col">
                      {["gnani_voice", "twilio_voice"].includes(b.channel)
                        ? this.getDeleteModal("delete_audio", i, "isAudio", b)
                        : this.getDeleteModal("delete_btn", i, "isButton")}
                    </div>
                  </div>
                ) : (
                  <DropDown
                    actionButton={() => getIcon("verticalOptions")}
                    direction="left"
                    btnClassName="px-0"
                    dropdownList={this.handlePhraseActionList(b, i)}
                    onActionItemClick={(m) => this.handlePhraseAction(m, i)}
                    dropDownMenuMinWidth="12rem"
                  />
                )}
              </div>
            </div>
            {this.renderBottomPanel(b, i)}
          </div>
          {["gnani_voice", "twilio_voice"].includes(b.channel) &&
            this.state.isAddPhraseAudio === i && (
              <div className="row m-0">{this.renderUploadAudioBtnCont()}</div>
            )}
        </div>
      ))
    );
  };

  renderBottomPanel = (
    { buttons = [], image, isSelected, channel },
    pIndex
  ) => {
    const { selectedBtns, index, isShown } = this.state.isDelete;

    if (buttons.length || image || !!this.state?.phraseAudios?.length) {
      return (
        <div
          className="row m-0"
          style={{
            background: `${isSelected ? "#F2F4FF" : "#fafafa"}`,
            border: `${isSelected ? "1px solid #46569940" : ""}`,
          }}
        >
          {image && (
            <div className="col ps-3 py-3 border-end">
              <p className="u-s-b-p-text">Image</p>
              <div className="u-s-bottom-content-holder">
                <img
                  alt=""
                  className="img-fluid utterence-sentence-img"
                  src={getImageUrl(image)}
                />
              </div>
            </div>
          )}
          {!!buttons.length && (
            <div className={`col py-3 ${!image ? "ps-3" : "ps-4"}`}>
              <p className="u-s-b-p-text">Button</p>
              <div className="u-s-bottom-content-holder py-2 ps-2">
                {buttons.map((b, i) => (
                  <>
                    <Buttons
                      className={`utterence-sentence-bottom-btn me-2 ${
                        selectedBtns.includes(b.title) &&
                        index === pIndex &&
                        "res-phrase-del-btn-border"
                      }`}
                      key={i}
                      onClick={() =>
                        this.handleBtnDeleteSelection(b, pIndex, i)
                      }
                    >
                      {b.title}
                    </Buttons>
                    {this.state.isEditBtnModal === i &&
                      this.getEditBtnModal(pIndex, i)}
                  </>
                ))}
              </div>
            </div>
          )}
          {this.state.phraseAudios.some((ele) => ele.channel === channel) &&
            this.state.phraseAudios.some((ele) => ele.index === pIndex) && (
              <div className={`col py-3 ${!image ? "ps-3" : "ps-4"}`}>
                <p className="u-s-b-p-text">Audio</p>
                <div className="row">
                  {this.state?.phraseAudios?.map(
                    (b, i) =>
                      b.index === pIndex && (
                        <div className="col-6 row m-0 justify-content-between mb-3">
                          <div
                            className="col-auto d-flex ps-0"
                            onClick={() =>
                              this.handleAudioDeleteSelection(b, pIndex, i)
                            }
                          >
                            {isShown && index === pIndex && (
                              <div className="align-items-center me-2 m-auto">
                                {getIcon(
                                  `${
                                    selectedBtns.includes(b.title)
                                      ? "check-box-marked"
                                      : "check-box-outline-blank"
                                  }`
                                )}
                              </div>
                            )}
                            <div className="u-audio-icn-cont">
                              {getIcon("headphones-grey")}
                            </div>
                            <p className="align-self-center m-auto">
                              {b.title}
                            </p>
                          </div>
                          <div
                            className="col-auto cursor-pointer"
                            onClick={() => {
                              if (this.state.fetchedAudio.url) {
                                this.setState({
                                  fetchedAudio: { url: "", index: null },
                                });
                              } else {
                                this.getPhraseAudio(i, b.title);
                              }
                            }}
                          >
                            {this.state.fetchedAudio.url && (
                              <audio
                                src={this.state.fetchedAudio.url}
                                id={`phraseAudio-${i}`}
                                autoPlay
                              />
                            )}
                            {this.state.fetchedAudio.index === i
                              ? getIcon("stop-circle")
                              : getIcon("play-circle", "none")}
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>
            )}
        </div>
      );
    }
  };

  handlePhraseActionList = (pObj, i) => {
    const phraseActionsList = [
      {
        icon: this.getDeleteModal("phrase", i, "", pObj),
        label: "Delete phrase",
        value: "delete_phrase",
        isUsed: this.props.permissions.knowledge_base.modify !== "enabled",
      },
    ];

    const channel = pObj.channel;

    if (channel) {
      const btnChannels = ["telegram", "gnani_chat"];
      const isImg = ["whatsapp", "twilio_whatsapp", ...btnChannels].includes(
        channel
      );
      const isVoice = ["gnani_voice", "twilio_voice"].includes(channel);
      const isBtn = btnChannels.includes(channel);

      if (isImg)
        phraseActionsList.push({
          icon: <i className="fas fa-image" style={{ color: "#919191C3" }} />,
          label: "Add Image",
          value: "add_img",
          isUsed: this.props.permissions.knowledge_base.modify !== "enabled",
        });

      if (isBtn)
        phraseActionsList.push({
          icon: (
            <i className="fas fa-sync-alt" style={{ color: "#919191C3" }} />
          ),
          label: "Add Button",
          value: "add_btn",
          isUsed: this.props.permissions.knowledge_base.modify !== "enabled",
        });

      if (isVoice)
        phraseActionsList.unshift({
          label: "Add Audio",
          value: "add_audio",
          icon: getIcon("headphones-grey"),
          isUsed: this.props.permissions.knowledge_base.modify !== "enabled",
        });
    }

    pObj.image &&
      phraseActionsList.splice(2, 0, {
        icon: this.getDeleteModal("Delete Image", i),
        label: "Delete Image",
        value: "delete_img",
        isUsed: this.props.permissions.knowledge_base.modify !== "enabled",
      });
    pObj.buttons?.length &&
      phraseActionsList.push({
        icon: getIcon("delete", "#919191C3"),
        label: "Delete Button",
        value: "delete_btn",
        isUsed: this.props.permissions.knowledge_base.modify !== "enabled",
      });

    pObj?.text?.includes(".wav") &&
      phraseActionsList.splice(1, 0, {
        icon: getIcon("delete", "#919191C3"),
        label: "Delete Audio",
        value: "delete_audio",
        isUsed: this.props.permissions.knowledge_base.modify !== "enabled",
      });

    return phraseActionsList;
  };

  handlePhraseAction = ({ value }, index) => {
    switch (value) {
      case "delete_phrase":
        this.setState({ modal: true });
        setTimeout(() => {
          this.setState({ modal: false });
        }, 100);
        console.log("delete phrase", this.state.selectedChannel);
        return;
      case "delete_img":
        this.setState({ imgModal: true });
        setTimeout(() => {
          this.setState({ imgModal: false });
        }, 100);
        console.log("delete image");
        break;
      case "delete_btn":
        const isDelete = { ...this.state.isDelete };
        isDelete.isShown = true;
        isDelete.index = index;
        return this.setState({ isDelete });
      case "add_img":
        this.phraseIndex = index;
        return this.setState({ isAddImgModal: true });
      // return document.getElementById("res-top-img-upload").click();
      case "delete_audio":
        const isDeleteAudio = { ...this.state.isDelete };
        isDeleteAudio.isShown = true;
        isDeleteAudio.index = index;
        return this.setState({ isDelete: isDeleteAudio });
      case "add_audio":
        return this.setState({ isAddPhraseAudio: index });
      default:
        this.phraseIndex = index;
        this.setState({ isAddBtnModal: true });
        return;
    }
  };

  handleBtnEdit = ({ target }, pIndex, btnIndex, isAddBtnModal) => {
    let { selectedChannelFaq, modalInps } = this.state;

    const { name, value } = target;
    if (isAddBtnModal) {
      modalInps[name] = value;
      console.log(name);
    } else {
      selectedChannelFaq.nonTrained[pIndex].buttons[btnIndex][name] = value;
    }
    this.setState({ selectedChannelFaq, modalInps });
  };

  handlePhraseEdit = (e, i, obj, type) => {
    if (e.key === "Enter" || type)
      this.toggle("_update", this.state.faqDetails);
    else {
      const { selectedChannelFaq, faqDetails } = this.state;
      const nonTrained = [...selectedChannelFaq.nonTrained];
      let realIndex = faqDetails.answers.findIndex(
        (ele) => JSON.stringify(ele) === JSON.stringify(obj)
      );
      if (typeof e === "string") {
        nonTrained[i].text = e;
      } else {
        nonTrained[i].text = e.target.value;
      }
      faqDetails.answers[realIndex] = nonTrained[i];
      this.setState({
        faqDetails,
        selectedChannelFaq: { ...selectedChannelFaq, nonTrained },
      });
    }
  };

  handleModalAddBtn = () => {
    const { faqDetails, modalInps, selectedChannelFaq } = this.state;
    const { title, value } = modalInps;
    const nonTrained = [...selectedChannelFaq.nonTrained];

    nonTrained[this.phraseIndex].buttons.push({ title, payload: value });

    this.setState(
      {
        faqDetails,
        isAddBtnModal: false,
        modalInps: { title: "", value: "" },
      },
      () => {
        this.phraseIndex = "";
        this.toggle("_update", faqDetails);
      }
    );
  };

  getEditBtnModal = (pIndex, btnIndex) => {
    let { isEditBtnModal, modalInps, selectedChannelFaq, isAddBtnModal } =
      this.state;

    return (
      <Modal
        addNewModalOpen={isEditBtnModal >= 0 || isAddBtnModal}
        className="duplicate-modal-container"
        isHeader={false}
        bodyClassName="p-0"
        modalSize="md"
      >
        <div className="row mb-3 align-items-center">
          <div className="col-auto u-s-dropdown-list-btn-title">Title</div>
          <div className="col" style={{ width: "calc(100% - 4rem)" }}>
            <Input
              autoFocus={true}
              className="u-s-dropdown-list-inp"
              name="title"
              onChange={(e) =>
                this.handleBtnEdit(e, pIndex, btnIndex, isAddBtnModal)
              }
              placeholder="Button title"
              value={
                isAddBtnModal
                  ? modalInps.title
                  : selectedChannelFaq?.nonTrained[pIndex]?.buttons[btnIndex]
                      ?.title
              }
            />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-auto u-s-dropdown-list-btn-title">Value</div>
          <div className="col" style={{ width: "calc(100% - 4rem)" }}>
            <Input
              className="u-s-dropdown-list-inp"
              name={isAddBtnModal ? "value" : "payload"}
              onChange={(e) =>
                this.handleBtnEdit(e, pIndex, btnIndex, isAddBtnModal)
              }
              placeholder="Button value"
              value={
                isAddBtnModal
                  ? modalInps.value
                  : selectedChannelFaq?.nonTrained[pIndex]?.buttons[btnIndex]
                      ?.payload
              }
            />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col text-end">
            <Buttons
              className="btn-outline-light rounded-3 px-4 py-2 btn-secondary"
              styles={{ width: "10rem" }}
              onClick={() =>
                this.setState({ isEditBtnModal: null, isAddBtnModal: false })
              }
            >
              Cancel
            </Buttons>
          </div>
          <div className="col-auto">
            <Buttons
              className="duplicate-modal-confirm-btn"
              // isDisabled={!modalInps.title || !modalInps.value}
              onClick={() =>
                this.setState(
                  { isEditBtnModal: null, isAddBtnModal: false },
                  () => {
                    if (isAddBtnModal) {
                      this.handleModalAddBtn();
                    } else {
                      this.toggle("_update", this.state.faqDetails);
                    }
                  }
                )
              }
            >
              Add Button
            </Buttons>
          </div>
        </div>
      </Modal>
    );
  };

  getImgModal = () => {
    const { isAddImgModal } = this.state;
    return (
      <Modal
        addNewModalOpen={isAddImgModal}
        className="duplicate-modal-container"
        isHeader={false}
        bodyClassName="p-0"
        modalSize="md"
      >
        <div className="mb-3">
          <Input
            accept="image/*"
            className="form-control form-control-sm"
            id="formFileSm"
            onChange={this.handlePhraseImgUpload}
            type="file"
          />
        </div>
        <div className="row mt-4">
          <div className="col text-end">
            <Buttons
              className="btn-outline-light rounded-3 px-4 py-2 btn-secondary"
              styles={{ width: "10rem" }}
              onClick={() => this.setState({ isAddImgModal: false })}
            >
              Cancel
            </Buttons>
          </div>
        </div>
      </Modal>
    );
  };

  //action based faq component
  handleAddNewHeader = (e) => {
    let { actionDetails, dynamic, addNewHeader } = this.state;
    if (!addNewHeader.oldIndex) {
      addNewHeader.oldIndex = Object.keys(actionDetails.headers).length;
      this.setState({ addNewHeader });
    }

    if (e.key === "Enter") {
      actionDetails.headers[`${addNewHeader.key}`] = addNewHeader.value;
      this.setState({ actionDetails });
      if (
        addNewHeader.oldIndex &&
        Object.keys(actionDetails.headers).length !== addNewHeader.oldIndex
      ) {
        this.toggle("_updateFaqAction", dynamic.actionName, [actionDetails]);
        this.setState({
          addNewHeader: { key: "", value: "", oldIndex: null, error: false },
        });
      }
    }
  };

  // components related channel based faq answers
  handleSelectPhrase(p) {
    let { phrasesToDelete, selectedChannelFaq } = this.state;

    p.isSelected = !p.isSelected;
    phrasesToDelete = selectedChannelFaq.nonTrained.filter((e) => e.isSelected);
    this.setState(
      { selectedChannelFaq, phrasesToDelete },
      this.handleShareDropDownValidation(phrasesToDelete)
    );
  }

  // channel select func from navbar for faq answers
  handleNavMenuSelect = (menu, isDropdown) => {
    let { faqDetails, channels, navBarMenu, phraseAudios } = this.state;
    let temp = {};
    let selectedChannel;
    let navBarMenuTemp;

    if (isDropdown) {
      channels.map((i) => {
        if (i.label === menu.label) {
          i.isUsed = true;
          i.isSelected = true;
        } else i.isSelected = false;
        return i;
      });
      selectedChannel = channels.filter((c) => c.label === menu.label)[0]
        ?.value;
    } else {
      if (menu.value === "Default") {
        temp.nonTrained = faqDetails.answers.filter(
          (obj) => !obj.hasOwnProperty("channel")
        );
        channels.map((c) =>
          c.value === menu.value
            ? (c.isSelected = true)
            : (c.isSelected = false)
        );
      } else {
        temp.nonTrained = faqDetails.answers.filter(
          (obj) => obj.channel === menu.value
        );
        channels.map((c) =>
          c.value === menu.value
            ? (c.isSelected = true)
            : (c.isSelected = false)
        );
      }
      temp.nonTrained.map((ele) => (ele.isSelected = false));
      temp.newPhraseName = "";
      selectedChannel = channels.filter((c) => c.isSelected)[0]?.value;

      if (
        ["gnani_voice", "twilio_voice"].includes(selectedChannel) &&
        !phraseAudios.some((ele) => ele.channel === selectedChannel)
      ) {
        temp.nonTrained.map((ele, i) => {
          if (ele.text.includes(".wav")) {
            ele.text.split(" ").forEach((e) => {
              if (e.includes(".wav]") && e.includes("["))
                phraseAudios.push({
                  title: e.substring(1, e.length - 1),
                  isSelected: false,
                  index: i,
                  channel: ele.channel,
                });
              else if (e.includes(".wav"))
                phraseAudios.push({
                  title: e,
                  isSelected: false,
                  index: i,
                  channel: ele.channel,
                });
            });
          }
        });
      }
    }
    navBarMenuTemp = channels.filter((e) => e.isUsed);
    navBarMenuTemp.unshift({ label: "Default", value: "Default" });

    this.setState({
      selectedChannelFaq: temp,
      channels,
      selectedChannel,
      navBarMenu: navBarMenuTemp,
      phraseAudios,
      faqNewAnswer: "",
      uploadingPhrase: {
        buttons: [],
        image: "",
        audios: [],
      },
    });
  };
  // channel delete func from navbar for faq answers
  deleteChannel = (channel) => {
    let { faqDetails, navBarMenu, selectedChannelFaq, channels } = this.state;

    if (this.props.permissions.knowledge_base.modify === "enabled") {
      let temp = faqDetails.answers.filter(
        (ele) => ele.channel !== channel.value
      );
      selectedChannelFaq.nonTrained = faqDetails.answers.filter(
        (obj) => !obj.hasOwnProperty("channel")
      );
      let tmpChannel = channels.map((e) => {
        if (e.label === channel.label) {
          e.isUsed = false;
          e.isSelected = false;
        }
        return e;
      });
      let tmpNav = navBarMenu.filter((e) => e.label !== channel.label);
      this.setState(
        {
          faqDetails: { ...faqDetails, answers: temp },
          selectedChannel: "Default",
          selectedChannelFaq,
          navBarMenu: tmpNav,
          channels: tmpChannel,
        },
        () => this.toggle("_update", { ...faqDetails, answers: temp })
      );
    }
  };

  handleShareDropDownValidation(phrasesToDelete) {
    let { shareListCopy } = this.state;

    const isImage = [
      "whatsapp",
      "twilio_whatsapp",
      "gnani_chat",
      "telegram",
      "slack",
      "microsoft_botframework",
    ];
    const isBtn = ["gnani_chat", "telegram", "slack", "microsoft_botframework"];

    if (phrasesToDelete.some((ele) => ele.buttons?.length > 0)) {
      let temp = shareListCopy.map((c) => {
        !isBtn.includes(c.value) && (c.isUsed = true);
        return c;
      });
      this.setState({ shareListCopy: temp });
    } else if (phrasesToDelete.some((ele) => ele?.image)) {
      let temp = shareListCopy.map((c) => {
        !isImage.includes(c.value) && (c.isUsed = true);
        return c;
      });
      this.setState({ shareListCopy: temp });
    }
  }

  handleShareSelectedPhrases = () => {
    let { shareListCopy, phrasesToDelete, faqDetails } = this.state;

    let temp = [];
    shareListCopy.forEach((c) => {
      if (c.isSelected) {
        phrasesToDelete.forEach((p) => {
          temp.push({ ...p, channel: c.value });
        });
      }
    });

    let tempList = shareListCopy.map((e) => {
      e.isSelected = false;
      return e;
    });

    this.setState(
      {
        faqDetails: {
          ...faqDetails,
          answers: [...faqDetails.answers, ...temp],
        },
        phrasesToDelete: [],
        shareListCopy: tempList,
      },
      this.toggle("_update", faqDetails)
    );
    this.sharePhraseRef.current.toggle();
  };

  handleCancelShareDropDown = () => {
    const { shareListCopy } = this.state;
    this.setState({
      shareListCopy: shareListCopy.map((e) => {
        e.isSelected = false;
        return e;
      }),
    });
    this.sharePhraseRef.current.toggle();
  };

  renderDelShareBtn = () => {
    let { shareList, shareListCopy, phrasesToDelete } = this.state;
    return (
      <>
        <div className="col-auto response-font p-0"> Responses</div>
        <div className="col-auto row m-0 pe-1">
          <Buttons
            isDisabled={!phrasesToDelete.length}
            styles={{ opacity: `${!!phrasesToDelete.length ? "1" : "0.4"}` }}
            onClick={() => this.handlePharseDelete(phrasesToDelete, "", true)}
            className={"col-auto me-3 new-phrase-dlt-btn"}
          >
            Delete
          </Buttons>
          <div className="col-auto pe-1 ps-0">
            <DropDown
              isContent={true}
              globalIntent={true}
              ref={this.sharePhraseRef}
              disabled={!phrasesToDelete.length}
              btnClassName={"new-phrase-dlt-btn"}
              dropdownList={shareListCopy
                ?.map((e) => {
                  e.isSelected
                    ? (e.icon = getIcon("check-box-marked"))
                    : (e.icon = getIcon("check-box-outline-blank"));
                  return e;
                })
                .sort((x) => (x.isUsed ? 1 : -1))}
              actionButton={() => <span>Share</span>}
              onActionItemClick={(m) => {
                this.sharePhraseRef.current.state = true;
                shareListCopy.map(
                  (e) => e.label === m.label && (e.isSelected = !e.isSelected)
                );
                this.setState({ shareListCopy });
              }}
              isInput={
                <div className="px-2 my-2 d-flex align-items-center">
                  <Input
                    className="p-2 share-drop-down-search"
                    placeholder="Search for channels"
                    onChange={(e) => {
                      let temp = shareList.filter((ele) =>
                        ele.label.toLowerCase().includes(e.target.value)
                      );
                      this.setState({ shareListCopy: temp });
                    }}
                  />
                  {getIcon("search", "", "share-drop-down-search-icon")}
                </div>
              }
              isButtons={
                <div className="row m-0 justify-content-around mt-3">
                  <Buttons
                    className="col-auto btn share-drop-down-cancel"
                    onClick={this.handleCancelShareDropDown}
                  >
                    Cancel
                  </Buttons>
                  <Buttons
                    className="col-auto btn share-drop-down-confirm"
                    onClick={this.handleShareSelectedPhrases}
                  >
                    Confirm
                  </Buttons>
                </div>
              }
            />
          </div>
        </div>
      </>
    );
  };

  // Main input field to add new faq answers and its components
  renderAddNewPhraseInpCont = () => {
    const { selectedChannel, faqDetails, uploadingPhrase } = this.state;
    let { faqNewAnswer } = this.state;
    const pLen = faqDetails?.answers?.length;

    const isImage = [
      "whatsapp",
      "twilio_whatsapp",
      "gnani_chat",
      "telegram",
      "slack",
      "microsoft_botframework",
    ].includes(selectedChannel);

    const isBtn = [
      "gnani_chat",
      "telegram",
      "slack",
      "microsoft_botframework",
    ].includes(selectedChannel);

    const isAudio = ["gnani_voice", "twilio_voice"].includes(selectedChannel);

    return (
      <div className="col">
        <div
          className={` ${
            !(uploadingPhrase.image || uploadingPhrase.buttons.length) &&
            "align-items-center"
          }`}
        >
          <div className="col utterence-sentence-add-section mb-2">
            <div className="row m-auto align-items-center">
              <div className="col px-0 border-0">
                <Input
                  isLiteration
                  value={faqNewAnswer}
                  className="bot-layout-inp border-0"
                  placeholder="In other words user may say..."
                  readOnly={
                    this.props.permissions.knowledge_base.modify !== "enabled"
                  }
                  onChange={(e) =>
                    typeof e === "string"
                      ? this.setState({ faqNewAnswer: e })
                      : this.setState({ faqNewAnswer: e.target.value })
                  }
                  onKeyPress={this.handleAddNewFaqAnswer}
                />
              </div>
            </div>

            {this.props.permissions.knowledge_base.modify === "enabled" &&
              this.renderUploadingImgOrBtn()}
          </div>
          {!!pLen && (
            <div
              className="row m-auto align-items-center"
              // style={{ background: "#F2F2F2" }}
            >
              {isImage &&
                this.props.permissions.knowledge_base.modify === "enabled" && (
                  <div
                    className={`col-auto ps-1 ${
                      !isImage && "isDisabled isDisabled-padding"
                    }`}
                  >
                    <div className="row align-items-center">
                      <div className="col">
                        <Input
                          accept="image/*"
                          hidden
                          id="res-top-img-upload"
                          onChange={isImage ? this.handlePhraseImgUpload : null}
                          type={isImage ? "file" : "text"}
                        />
                        <label
                          className="cursor-pointer row"
                          htmlFor="res-top-img-upload"
                        >
                          <div className="mb-1 col-auto align-items-center m-auto">
                            {getIcon("plus", "#495072", "", 10)}
                          </div>
                          <div className="col-auto d-flex align-items-center">
                            <i className="fas fa-image me-2" />
                            <span>Image</span>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                )}
              {isBtn && (
                <div className={`col-auto ${!isBtn && "isDisabled"}`}>
                  <DropDown
                    id="addBtnToNewPhrase"
                    actionButton={this.renderDropDownBtnActionContainer}
                    dropdownList={this.renderDropDownListBtnContainer}
                    isCustomDropDownList
                    isCustomStyle
                    toggle={(e) => {
                      // console.log(e)
                    }}
                    disabled={
                      !isBtn ||
                      this.props.permissions.knowledge_base.modify !== "enabled"
                    }
                  />
                </div>
              )}
              {isAudio &&
                this.state.isAddPhraseAudio === null &&
                this.props.permissions.knowledge_base.modify === "enabled" &&
                this.renderUploadAudioBtnCont()}
            </div>
          )}
        </div>
      </div>
    );
  };

  renderUploadingImgOrBtn = () => {
    const { image, buttons, audios } = this.state.uploadingPhrase;
    return (
      !!(image || buttons?.length || audios?.length) && (
        <div className="row m-auto border-top">
          <div className="col p-3">
            {image && (
              <div className="row">
                <div className="col">
                  <p className="res-new-phrase-img-text ms-0">Image</p>
                  <img alt="" height="73" src={getImageUrl(image)} width="73" />
                </div>
              </div>
            )}

            {!!buttons?.length && (
              <div className={`row ${image && "mt-3"}`}>
                <div className="col">
                  <p className="res-new-phrase-img-text ms-0">Buttons</p>
                  {this.renderUploadingBtns()}
                </div>
              </div>
            )}
            {!!audios?.length && (
              <div className="row">
                <div className="col">
                  <p className="res-new-phrase-img-text text-dark">Audio</p>
                  {this.renderUploadingAudios(audios)}
                </div>
              </div>
            )}
          </div>
        </div>
      )
    );
  };

  renderUploadingBtns = () => {
    const { buttons } = this.state.uploadingPhrase;

    return buttons.map((b, i) => (
      <Buttons className="utterence-sentence-bottom-btn me-2" key={i}>
        {b.title}
      </Buttons>
    ));
  };

  renderUploadingAudios = (audios) => {
    let { uploadingPhrase, selectedFaq, currentlyPlaying } = this.state;
    return (
      <div className="row justify-content-between align-items-center m-0">
        {audios.map((a, i) => {
          // const audio = new Audio(URL.createObjectURL(a));
          return (
            <div className="col-6 mb-2" key={i}>
              <div className="row justify-content-between">
                <div className="col-auto d-flex">
                  <div className="u-audio-icn-cont">
                    {getIcon("headphones-grey")}
                  </div>
                  <p className="align-self-center m-auto res-uploading-audioname-trim">
                    {`${selectedFaq}_${i + 1}`}
                  </p>
                </div>
                <div className="col-auto cursor-pointer d-flex">
                  <audio
                    className=""
                    src={URL.createObjectURL(a)}
                    id={`faqSound${i}`}
                  />
                  <div
                    className="px-2"
                    onClick={() => {
                      if (currentlyPlaying === null) {
                        this.setState({ currentlyPlaying: i }, () =>
                          document.getElementById(`faqSound${i}`).play()
                        );
                      } else {
                        this.setState({ currentlyPlaying: null }, () =>
                          document.getElementById(`faqSound${i}`).pause()
                        );
                      }
                    }}
                  >
                    {currentlyPlaying === i
                      ? getIcon("stop-circle")
                      : getIcon("play-circle", "none")}
                  </div>
                  <div
                    className="px-2"
                    onClick={() => {
                      uploadingPhrase.audios = audios.filter(
                        (aud, ix) => ix !== i
                      );
                      this.setState({ uploadingPhrase });
                    }}
                  >
                    {getIcon("close-small")}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  handlePhraseAudioUpload = ({ target }) => {
    let {
      uploadingPhrase,
      selectedFaq,
      selectedChannelFaq,
      isAddPhraseAudio,
      faqDetails,
      phraseAudios,
    } = this.state;
    const file = target.files;

    if (file.length) {
      if (isAddPhraseAudio !== null) {
        let temp = selectedChannelFaq.nonTrained[isAddPhraseAudio];
        let index = faqDetails.answers.findIndex(
          (ele) => JSON.stringify(ele) === JSON.stringify(temp)
        );
        let newIndex = phraseAudios.filter(
          (ele) => ele.index === isAddPhraseAudio
        ).length;
        const formData = new FormData();
        formData.append(
          "file",
          file[0],
          `${selectedFaq}_${index}_${newIndex}.${file[0].name.split(".")[1]}`
        );
        formData.append("response_type", "faq_utterances");
        formData.append("utterance", selectedFaq);
        formData.append("audio_file_index", index);

        this.uploadAudio(formData, selectedFaq);
      } else {
        // file[0].arrayBuffer().then((arrayBuffer) => {
        //   const blob = new Blob([new Uint8Array(arrayBuffer)], {
        //     type: "audio/wav",
        //   });
        // });
        if (file[0].size / 1024 < 5632) {
          uploadingPhrase.audios.push(file[0]);
          this.setState({ uploadingPhrase });
        } else {
          this.props.setNotifications({
            color: "danger",
            message: "File size should be less than 5mb",
          });
        }
      }
    }
  };

  record = async () => {
    let {
      uploadingPhrase,
      selectedChannelFaq,
      faqDetails,
      isAddPhraseAudio,
      selectedFaq,
      phraseAudios,
    } = this.state;
    this.setState({ isMicLoading: true });

    if (this.state.isRecording) {
      const blob = await recorder.stopRecording();

      if (isAddPhraseAudio !== null) {
        let number = phraseAudios.filter(
          (ele) => ele.index === isAddPhraseAudio
        ).length;
        let temp = selectedChannelFaq.nonTrained[isAddPhraseAudio];
        let index = faqDetails.answers.findIndex(
          (ele) => JSON.stringify(ele) === JSON.stringify(temp)
        );
        const file = new File([blob], `${selectedFaq}_${index}_${number}.mp3`);
        const formData = new FormData();
        formData.append("file", file);
        formData.append("response_type", "faq_utterances");
        formData.append("utterance", selectedFaq);
        formData.append("audio_file_index", index);

        this.uploadAudio(formData, selectedFaq);
      } else {
        const file = new File([blob], `voice.mp3`);
        file.size / 1024 < 5632
          ? uploadingPhrase.audios.push(file)
          : this.props.setNotifications({
              color: "danger",
              message: "File size should be less than 5mb",
            });
      }
      this.setState({
        isMicLoading: false,
        isRecording: false,
        // isAddPhraseAudio: null,
        uploadingPhrase,
      });
    } else {
      try {
        await recorder.initAudio();
        await recorder.initWorker();
        recorder.startRecording();
        this.setState({ isMicLoading: false, isRecording: true });
      } catch (e) {
        console.error(e);
        this.setState({ isMicLoading: false });
      }
    }
  };

  handleAudioDeleteSelection = ({ title }, pIndex) => {
    const isDelete = { ...this.state.isDelete };

    isDelete.index = pIndex;

    if (isDelete.isShown) {
      if (isDelete.selectedBtns.includes(title))
        isDelete.selectedBtns = isDelete.selectedBtns.filter(
          (b) => b !== title
        );
      else isDelete.selectedBtns.push(title);

      this.setState({ isDelete });
    }
  };

  handlePhraseAudioDel = (index, pObj) => {
    const { faqDetails, isDelete, selectedFaq } = this.state;
    let mainIndex = faqDetails.answers.findIndex(
      (ele) => JSON.stringify(ele) === JSON.stringify(pObj)
    );
    let dataObj = {
      response_type: "faq_utterances",
      utterance_name: faqDetails.faq,
      file_names_list: isDelete.selectedBtns,
      audio_file_index: mainIndex,
    };

    resService
      .deleteAudio(dataObj)
      .then((res) => {
        if (res.status === "success")
          this.setState(
            {
              isDelete: { index: null, isShown: false, selectedBtns: [] },
            },
            () => {
              this.props.setNotifications({
                color: "success",
                message: res.message,
              });
              this.getBotFaqsDetails(selectedFaq);
            }
          );
        else
          this.props.setNotifications({
            color: "danger",
            message: res.message,
          });
      })
      .catch((e) =>
        this.props.setNotifications({
          color: "danger",
          message: e.message,
        })
      );
  };

  renderUploadAudioBtnCont = () => {
    const { selectedChannel, phrase, uploadingPhrase } = this.state;
    const isAudio = ["gnani_voice", "twilio_voice"].includes(selectedChannel);
    return (
      <>
        <div className="col-auto d-flex align-items-center">
          <div
            className={`${
              uploadingPhrase.audios?.length >= 10 && "opacity-25"
            } row align-items-center`}
          >
            <div className="col ps-1">
              <Input
                accept="audio/*"
                hidden
                id="faq-top-img-upload"
                onChange={this.handlePhraseAudioUpload}
                type={"file"}
                disabled={uploadingPhrase.audios?.length > 10}
              />
              {!(uploadingPhrase.audios?.length >= 10) ? (
                <label
                  className="cursor-pointer row"
                  htmlFor="faq-top-img-upload"
                >
                  <div className="col-auto align-items-center m-auto">
                    {getIcon("plus", "#495072", "", 10)}
                  </div>
                  <div className="col-auto d-flex align-items-center">
                    {getIcon("headphones")}
                    <p className="m-0 ms-2">Upload Audio</p>
                  </div>
                </label>
              ) : (
                <span>{getIcon("plus", "#495072", "", 10)}</span>
              )}
            </div>
          </div>
        </div>
        <div className="col-auto">
          <Buttons
            className={`${
              uploadingPhrase.audios?.length >= 10 && "opacity-25"
            } d-flex align-items-center`}
            onClick={this.record}
            isDisabled={
              this.state.isMicLoading || uploadingPhrase.audios?.length >= 10
            }
          >
            {this.state.isRecording
              ? getIcon("stop-circle")
              : getIcon("microphone")}
            <p
              className={`${this.state.isRecording && "text-danger"} m-0 ms-2`}
            >
              {this.state.isRecording ? "Stop Recording" : "Record Audio"}
            </p>
          </Buttons>
        </div>
      </>
    );
  };

  renderDropDownListBtnContainer = () => {
    const { title, value } = this.state.addBtnToNewPhrase;

    return (
      <>
        <div className="row mb-3 align-items-center">
          <div className="col-auto u-s-dropdown-list-btn-title">Title</div>
          <div className="col" style={{ width: "calc(100% - 4rem)" }}>
            <Input
              autoFocus={true}
              className="u-s-dropdown-list-inp"
              name="title"
              onChange={this.handleAddBtnToNewPhrase}
              onKeyPress={this.handleAddBtnToNewPhrase}
              placeholder="Button title"
              ref={this.dropDownBtnNewTitle.ref}
              value={title}
            />
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-auto u-s-dropdown-list-btn-title">Value</div>
          <div className="col" style={{ width: "calc(100% - 4rem)" }}>
            <Input
              className="u-s-dropdown-list-inp"
              name="value"
              onChange={this.handleAddBtnToNewPhrase}
              onKeyPress={this.handleAddBtnToNewPhrase}
              placeholder="Button value"
              ref={this.dropDownBtnNewVal.ref}
              value={value}
            />
          </div>
        </div>
      </>
    );
  };

  renderDropDownBtnActionContainer = () => {
    return (
      <div className="row align-items-center">
        <div className="col cursor-pointer">
          {getIcon("plus", "#495072", "", "10")}
        </div>
        <div className="col-auto pt-1">
          <i className="fas fa-image me-2" />
          <span>Button</span>
        </div>
      </div>
    );
  };

  handleAddNewFaqAnswer = (e) => {
    const {
      selectedChannel,
      faqNewAnswer,
      faqDetails,
      uploadingPhrase,
      channels,
    } = this.state;
    const isAudio = ["gnani_voice", "twilio_voice"].includes(selectedChannel);

    if (e.key === "Enter" && /^(?!\s*$).+/.test(faqNewAnswer)) {
      if (
        selectedChannel === null ||
        (selectedChannel && selectedChannel === "Default")
      ) {
        faqDetails.answers.unshift({ text: faqNewAnswer });
      } else if (selectedChannel && selectedChannel !== "Default") {
        faqDetails.answers.unshift({
          text: faqNewAnswer,
          channel: selectedChannel,
          buttons: uploadingPhrase.buttons,
          image: uploadingPhrase.image,
        });
        channels.map((c, i) => {
          if (c.isSelected) {
            c.isUsed = true;
          }
          return c;
        });
      }
      this.setState(
        {
          faqDetails,
          faqNewAnswer: "",
          channels,
        },
        () => {
          isAudio && !!uploadingPhrase.audios.length
            ? this.toggle("_update", faqDetails, true)
            : this.toggle("_update", faqDetails);
        }
      );
    }
  };

  handleAddBtnToNewPhrase = ({ key, target }) => {
    const { addBtnToNewPhrase, faqDetails, uploadingPhrase } = this.state;
    const { title, value } = addBtnToNewPhrase;

    if (key === "Enter") {
      if (!title || !value) {
        target.name === "title"
          ? this.dropDownBtnNewVal.setFocus()
          : this.dropDownBtnNewTitle.setFocus();
      } else {
        const answers = [...faqDetails.answers];
        const newPhrase = { ...uploadingPhrase };

        if (this.phraseIndex !== "") {
          answers[this.phraseIndex].buttons.push({ title, payload: value });
        } else newPhrase.buttons.push({ title, payload: value });

        document.getElementById("addBtnToNewPhrase").classList.remove("show");

        this.setState(
          {
            addBtnToNewPhrase: { title: "", value: "" },
            faqDetails: { ...faqDetails, answers },
            uploadingPhrase: newPhrase,
          },
          () => {
            if (this.phraseIndex !== "") {
              // this.toggle("_update", faqDetails)
              this.phraseIndex = "";
            }
          }
        );
      }
    } else {
      const localAddBtnToNewPhrase = { ...addBtnToNewPhrase };
      localAddBtnToNewPhrase[target.name] = target.value;
      this.setState({ addBtnToNewPhrase: localAddBtnToNewPhrase });
    }
  };

  handleActionTabs = (who) => {
    const { actionData, dynamic, selectedFaq, actionDetails } = this.state;
    if (who === "codeBtn") {
      if (actionData && dynamic.type === "Config API") {
        this.setState({ changeActionType: { show: true, type: "Code" } });
      } else if (actionDetails === null) {
        this.addFaqAction("Code");
        console.log("here");
      } else {
        console.log("action_type: Code");
      }
    } else if (who === "configBtn") {
      if (actionData && dynamic.type === "Code") {
        this.setState({ changeActionType: { show: true, type: "config" } });
      } else if (actionDetails === null) {
        this.addFaqAction("config");
      } else {
        console.log("action_type: config");
      }
    }
  };

  // functions to render faq answers based on action or channel
  renderActionBasedResponse = () => {
    let { dynamic, addNewHeader, actionDetails, oldCode } = this.state;
    return (
      <>
        <div className="mb-2 px-4">
          <Buttons
            isDisabled={
              this.props.permissions.knowledge_base.modify !== "enabled"
            }
            className={`${
              dynamic.type === "Code"
                ? "action-btn-selected"
                : "action-btn-unselected"
            } me-1`}
            onClick={() => this.handleActionTabs("codeBtn")}
          >
            Action code
          </Buttons>
          <Buttons
            isDisabled={
              this.props.permissions.knowledge_base.modify !== "enabled"
            }
            className={`${
              dynamic.type === "Config API"
                ? "action-btn-selected"
                : "action-btn-unselected"
            }`}
            onClick={() => this.handleActionTabs("configBtn")}
          >
            Action API
          </Buttons>
          {this.state.changeActionType.show &&
            this.getDeleteModal(
              "change_action_Type",
              {
                action: this.state.selectedFaq,
              },
              "",
              "",
              true
            )}
        </div>
        {dynamic.type === "Code" ? (
          <div className="h-100 px-4">
            <AceEditor
              mode="python"
              theme="xcode"
              // name={selectedAction}
              readOnly={
                this.props.permissions.knowledge_base.modify !== "enabled"
              }
              value={actionDetails.code}
              height="380px"
              width="100%"
              fontSize={20}
              onChange={(e) => {
                if (!oldCode) {
                  oldCode = actionDetails.code;
                }
                actionDetails.code = e;
                this.setState({ actionDetails, oldCode });
              }}
              onBlur={() => {
                if (oldCode && actionDetails.code !== oldCode) {
                  this.toggle("_updateFaqAction", dynamic.actionName, [
                    actionDetails,
                  ]);
                  this.setState({ oldCode: "" });
                }
              }}
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
              }}
            />
          </div>
        ) : dynamic.type === "Config API" ? (
          <div
            className="px-4 overflow-auto"
            style={{ height: "calc(100% - 5.7rem)" }}
          >
            <div className="row m-0 p-0 align-items-center mb-5">
              <div className="col-2 pe-4">
                <strong className="font">URL</strong>
              </div>
              <div className="col d-flex flex-column">
                <Input
                  className="action-input px-3"
                  placeholder="Enter URL for the action..."
                  value={actionDetails.url}
                  readOnly={
                    this.props.permissions.knowledge_base.modify !== "enabled"
                  }
                  onChange={(e) => {
                    actionDetails.url = e.target.value;
                    this.setState({ actionDetails });
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      /[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:(\.(?:[a-zA-Z\u00a1-\uffff]{2,}))|(:[0-9]{3,4}))(?::\d{2,5})?(?:\/[^\s]*)+(?:\.[a-z]+)*(?::\d+)?(?![^<]*(?:<\/\w+>|\/?>))?/.test(
                        actionDetails.url
                      )
                        ? this.setState({ error: false }, () =>
                            this.toggleSave(
                              "_updateFaqAction",
                              dynamic.actionName,
                              [actionDetails]
                            )
                          )
                        : this.setState({ error: true });
                    }
                  }}
                />
                {addNewHeader.error && (
                  <small className="text-danger mt-2">
                    * Enter a valid url
                  </small>
                )}
              </div>
            </div>

            {/*    headers  */}
            <div className="mb-4">
              <p className="px-2 font" style={{ color: " #979797" }}>
                Header
              </p>
              <div className="row m-0 p-0 align-items-center mb-4">
                <div className="col-2 pe-4">
                  <strong className="font">Key</strong>
                </div>
                <div className="col">
                  <Input
                    className="action-input px-3"
                    placeholder="Enter Header value.."
                    value={addNewHeader.key}
                    readOnly={
                      this.props.permissions.knowledge_base.modify !== "enabled"
                    }
                    onChange={(e) => {
                      addNewHeader.key = e.target.value;
                      this.setState({ addNewHeader });
                    }}
                    onKeyPress={(e) => {
                      e.key === "Enter" &&
                        document.getElementById(`val-input`).focus();
                    }}
                  />
                </div>
              </div>
              <div className="row m-0 p-0 align-items-center">
                <div className="col-2">
                  <strong className="font">Value</strong>
                </div>
                <div className="col">
                  <Input
                    id={`val-input`}
                    className="action-input px-3"
                    placeholder="Enter Key value..."
                    value={addNewHeader.value}
                    readOnly={
                      this.props.permissions.knowledge_base.modify !== "enabled"
                    }
                    onChange={(e) => {
                      addNewHeader.value = e.target.value;
                      this.setState({ addNewHeader });
                    }}
                    onKeyPress={(e) => {
                      this.handleAddNewHeader(e);
                    }}
                  />
                </div>
              </div>
            </div>

            {/* token container */}
            {actionDetails.headers &&
              Object.keys(actionDetails.headers).length > 0 &&
              Object.keys(actionDetails.headers).map((a, ix) => {
                return (
                  <div className="mb-3">
                    <div className="row p-2 m-0 ms-2 token-cont token rounded">
                      <div className="col" key={ix}>
                        <div className="row m-0 p-0 py-2 align-items-center">
                          <div className="col-auto" style={{ width: "6rem" }}>
                            <span
                              className="text-center font"
                              style={{ color: "#737373" }}
                            >
                              Key
                            </span>
                          </div>
                          <div className="col">
                            <Input
                              readOnly
                              className="token-cont-inp p-3"
                              value={a}
                            />
                          </div>
                        </div>
                        <div className="row m-0 p-0 align-items-center">
                          <div className="col-auto" style={{ width: "6rem" }}>
                            <span
                              className="text-center font"
                              style={{ color: "#737373" }}
                            >
                              Value
                            </span>
                          </div>
                          <div className="col">
                            <Input
                              readOnly
                              className="token-cont-inp p-3"
                              value={actionDetails.headers[a]}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-auto p-0">
                        <DropDown
                          isContent
                          actionButton={() => getIcon("verticalOptions")}
                          dropdownList={
                            a !== "token"
                              ? [
                                  {
                                    label: "Copy",
                                    icon: getIcon("copy"),
                                    isUsed:
                                      this.props.permissions.knowledge_base
                                        .modify !== "enabled",
                                  },
                                  {
                                    label: "Delete",
                                    icon: this.getDeleteModal("header", a),
                                    isUsed:
                                      this.props.permissions.knowledge_base
                                        .modify !== "enabled",
                                  },
                                ]
                              : [
                                  {
                                    label: "Copy",
                                    icon: getIcon("copy"),
                                    isUsed:
                                      this.props.permissions.knowledge_base
                                        .modify !== "enabled",
                                  },
                                ]
                          }
                          onActionItemClick={(menu) => {
                            if (menu.label === "Copy") {
                              navigator.clipboard.writeText(
                                a + " : " + actionDetails.headers[a]
                              );
                              // this.setState({ toolTip: true });
                            } else {
                              // this.setState({ modal: true });
                              // setTimeout(() => {
                              //   this.setState({ modal: false });
                              // }, 100);
                            }
                          }}
                        />
                      </div>
                    </div>
                    {ix === Object.keys(actionDetails.headers).length - 1 && (
                      <small className="text-muted px-4 mb-2 info-font">
                        * Use this token to verify the call was made by bot.
                      </small>
                    )}
                  </div>
                );
              })}
          </div>
        ) : (
          <p
            className="mx-4 mt-3"
            style={{
              color: "#868686",
              font: "normal normal 500 17px/20px PoppinsMedium",
            }}
          >
            Please select a type of action to proceed
          </p>
        )}
      </>
    );
  };

  renderChannelBasedResponse = () => {
    let { faqNewAnswer, faqDetails } = this.state;
    return (
      <>
        <div
          className="px-0 text-white mb-3 w-100"
          style={{ background: "#25293D", height: "fit-content" }}
        >
          <HorizontalScroll
            items={this.state.channels}
            selectedChannel={this.state.selectedChannel}
            handleNavMenuSelect={this.handleNavMenuSelect}
            deleteChannel={this.deleteChannel}
            navBarMenu={this.state.navBarMenu}
            navDropDownDisable={
              this.props.permissions.knowledge_base.modify !== "enabled"
            }
          />
        </div>
        <div className="col-12 px-4 mb-3" style={{ height: "fit-content" }}>
          {this.renderAddNewPhraseInpCont()}
        </div>
        <div className="row m-0  px-4" style={{ height: "calc(100% - 13rem)" }}>
          <div
            className="col-12 row m-0 mb-2 justify-content-between p-0"
            style={{ height: "2rem" }}
          >
            {this.renderDelShareBtn()}
          </div>
          <div
            className="p-0 overflow-auto"
            style={{ height: "calc(100% - 2rem)" }}
          >
            <div className=" row m-0">{this.renderAnswers()}</div>
          </div>
        </div>
      </>
    );
  };

  onPageRefresh = (callback) => {
    this.setState(
      {
        faqsList: [],
        trainedFaqsList: [],
        faqDetails: [],
        selectedFaq: undefined,
        addNewFaq: "",
        faqNewAnswer: "",
        faqNewQuestion: "",
        isSelected: false,
        faqNewName: "",
        faqOldName: "",
        modal: false,
      },
      () => {
        setTimeout(() => {
          this.getBotFaqs();
        }, 200);
      }
    );
  };

  render() {
    let {
      faqsList,
      trainedFaqsList,
      faqDetails,
      selectedFaq,
      addNewFaq,
      faqNewAnswer,
      faqNewQuestion,
      isSelected,
      faqNewName,
      faqOldName,
      dynamic,
    } = this.state;

    return (
      <div className="container-fluid mt-1 h-100">
        <div className="row h-100">
          <BotLayoutLeftPanel
            title="Knowledge Base"
            sideBarList={faqsList}
            isFaq={true}
            value={addNewFaq}
            trainedIntent={trainedFaqsList}
            onPageRefresh={this.onPageRefresh}
            BulkImport={this.bulkImportComp}
            onClickImport={this.onClickImport}
            addBtnHide={this.props.permissions.knowledge_base.add}
            disableMultiDelete={
              this.props.permissions.knowledge_base.delete === "disabled"
            }
            onMenuSelect={({ label }) => {
              if (this.props.permissions.knowledge_base.list === "enabled") {
                const localFaqsList = faqsList.map((f) => {
                  f.isSelected = f.label === label;
                  return f;
                });

                this.setState(
                  {
                    faqNewName: label,
                    faqsList: localFaqsList,
                    isSelected: true,
                    selectedFaq: label,
                    selectedChannel: "Default",
                  },
                  () => {
                    if (
                      this.props.permissions.knowledge_base.list === "enabled"
                    ) {
                      this.getBotFaqsDetails(label);
                      this.getFaqActionDetails(label);
                    }
                  }
                );
              }
            }}
            onChange={(e) => this.setState({ addNewFaq: e.target.value })}
            onKeyPress={(e) => {
              if (e.key === "Enter" && addNewFaq) {
                if (!faqsList.find((f) => f.label === addNewFaq)) {
                  if (/^[A-Za-z][A-Za-z0-9_]+$/.test(addNewFaq)) {
                    faqsList.push(addNewFaq);
                    this.setState({ faqsList, addNewFaq: "" }, () => {
                      this.toggle("_addFaq", addNewFaq);
                    });
                  } else {
                    this.props.setNotifications({
                      color: "black",
                      message:
                        "Faq name should not contain special characters and spaces",
                    });
                  }
                } else {
                  this.setState({ addNewFaq: "" });
                  this.props.setNotifications({
                    color: "black",
                    message: "FAQ already exists",
                  });
                }
              }
            }}
          />
          <BotLayoutContent
            isFaqWidth
            isFaq={true}
            titleValue={faqNewName}
            faqDetails={faqDetails}
            isSelected={isSelected}
            addNewPlacholder="In other words user may say…"
            titleReadOnly={
              this.props.permissions.knowledge_base.modify !== "enabled"
            }
            titleOnChange={(e, type) => {
              if (!faqOldName) {
                faqOldName = selectedFaq;
              }
              this.setState({ faqNewName: e.target.value, faqOldName });
              if (e.key === "Enter" || type) {
                if (faqOldName && faqNewName && faqNewName !== faqOldName) {
                  if (!faqsList.find((f) => f.label === faqNewName)) {
                    if (/^[A-Za-z][A-Za-z0-9_]+$/.test(faqNewName)) {
                      this.toggle("_rename", faqOldName, faqNewName);
                      this.onPageRefresh();
                    } else {
                      this.props.setNotifications({
                        color: "black",
                        message:
                          "Faq name should not contain special characters and spaces",
                      });
                    }
                  } else {
                    this.props.setNotifications({
                      color: "black",
                      message: "FAQ already exists",
                    });
                  }
                }
              }
            }}
            actions={[
              {
                label: "Delete",
                icon: this.getDeleteModal("faq"),
                isUsed:
                  this.props.permissions.knowledge_base.delete === "disabled",
              },
            ]}
            onActionClick={() => {
              this.setState({ modal: true });
              setTimeout(() => {
                this.setState({ modal: false });
              }, 100);
            }}
            isShowContent={
              this.props.permissions.knowledge_base.content === "enabled"
            }
          >
            {!!faqsList.filter((faq) => faq.label === selectedFaq).length &&
              this.props.permissions.knowledge_base.content === "enabled" && (
                <>
                  <div
                    className="mb-2 px-4"
                    style={{ height: "calc(40% - .5rem)" }}
                  >
                    <p className="mb-2 faq-info-font">Questions</p>
                    <div className="col-12">
                      <Input
                        isLiteration
                        value={faqNewQuestion}
                        readOnly={
                          this.props.permissions.knowledge_base.modify !==
                          "enabled"
                        }
                        className="bot-layout-inp"
                        placeholder="In other words user may say..."
                        onChange={(e) =>
                          typeof e === "string"
                            ? this.setState({ faqNewQuestion: e })
                            : this.setState({ faqNewQuestion: e.target.value })
                        }
                        onKeyPress={(e) => {
                          if (
                            e.key === "Enter" &&
                            /^(?!\s*$).+/.test(faqNewQuestion)
                          ) {
                            faqDetails.questions.unshift(faqNewQuestion);
                            this.setState(
                              { faqDetails, faqNewQuestion: "" },
                              () => {
                                this.toggle("_update", faqDetails);
                              }
                            );
                          }
                        }}
                      />
                    </div>
                    <div
                      className="row m-0 overflow-auto"
                      style={{ height: "calc(100% - 5.7rem)" }}
                    >
                      <div className="p-0">
                        <div className="row m-0"> {this.renderQuestions()}</div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="mb-2"
                    style={{ height: "calc(60% - 0.5rem)" }}
                  >
                    <div className="row m-0 justify-content-between mb-4 px-4">
                      <div className="col-auto faq-info-font ps-0">Answers</div>
                      <div className="col-auto d-flex">
                        <p className="m-0 me-3 faq-info-font">
                          {dynamic.show ? "Dynamic" : "Static"}
                        </p>
                        <Toggle
                          isFaq={true}
                          checked={dynamic.show}
                          isDisabled={
                            this.props.permissions.knowledge_base.modify !==
                            "enabled"
                          }
                          onChange={() => {
                            if (dynamic.show && this.state.actionData) {
                              this.setState({ isDelFaqActionModal: true });
                            } else if (!!faqDetails?.answers?.length) {
                              this.setState({ isDelFaqAnswersModal: true });
                            } else {
                              this.setState({
                                dynamic: { ...dynamic, show: !dynamic.show },
                              });
                            }
                          }}
                        />
                        {this.state.isDelFaqActionModal &&
                        !this.state.isDelFaqAnswersModal
                          ? this.getDeleteModal(
                              "delete_action",
                              { action: faqDetails?.faq },
                              "",
                              "",
                              true
                            )
                          : this.state.isDelFaqAnswersModal &&
                            !this.state.isDelFaqActionModal
                          ? this.getDeleteModal(
                              "delete_answers",
                              faqDetails,
                              "",
                              "",
                              true
                            )
                          : ""}
                      </div>
                    </div>
                    {dynamic.show
                      ? this.renderActionBasedResponse()
                      : this.renderChannelBasedResponse()}
                  </div>
                </>
              )}
          </BotLayoutContent>
          <BotLayoutRightPanel />
        </div>
        {this.getImgModal()}
        {this.state.isAddBtnModal && this.getEditBtnModal()}
      </div>
    );
  }
}
export default function Faq() {
  const { setNotifications } = useContext(NotificationContext);
  const { getUserDetails } = useContext(UserContext);

  return (
    <Faqs
      setNotifications={setNotifications}
      permissions={getUserDetails().feature_permission}
    />
  );
}
