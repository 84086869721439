import React from "react";
import Select from "react-dropdown-select";
import "./nodes.scss";

function SelectSearch(props) {
  let { who, selections, selectionList } = props;

  const botType = {
    Bot: "bot",
    User: "user",
    Decision: "decision",
    Restart: "restart",
    EOC: "eoc",
    TTA: "tta",
    DTMF: "DTMF",
    SLOT: "slot",
    FORM: "form",
    ACTION: "action",
  };

  const customItemRenderer = ({ item, itemIndex, props, state, methods }) => (
    <div className="rounded-2 pe-auto px-3 options">
      {!item.isHeader && (
        <div className="p-2 mt-1" onClick={() => methods.addItem(item)}>
          {item.label}
        </div>
      )}
      {item.isHeader && (
        <>
          <h5 className="text-center text-capitalize pt-3">{item.label}</h5>{" "}
          <hr></hr>
        </>
      )}
    </div>
  );

  if (who === "user") {
    selectionList = selectionList.filter(
      (ele) => ele !== "nlu_fallback" && ele !== "repeat"
    );
  }

  const options = () =>
    (selectionList &&
      selectionList.map((user) => ({
        label: user,
        value: user,
        isHeader: false,
      }))) ||
    [];

  return (
    <Select
      keepSelectedInList={false}
      // disabled={pathName === "main_path" && who === "action"}
      disabled={props.disabled}
      options={options()}
      values={
        selections
          ? [
              {
                label: selections,
                value: selections,
                isHeader: false,
              },
            ]
          : []
      }
      itemRenderer={customItemRenderer}
      dropdownGap={0}
      dropdownHandle={false}
      placeholder={`${
        who === botType.Bot
          ? "Enter response name"
          : who === botType.User
          ? "Enter intent name"
          : who === botType.SLOT
          ? "Enter slot name"
          : who === botType.FORM
          ? "Enter form name"
          : who === botType.ACTION
          ? "Enter action name"
          : ""
      }`}
      style={{
        border: "none",
        width: "185px",
        // font: "normal normal 500 20px/31px PoppinsMedium",
        // opacity: `${
        //   pathName === "main_path" && who === "action" ? "0.75" : "1"
        // }`,
      }}
      create={["bot", "user"].includes(who) && true}
      onCreateNew={(e) => {
        props.onChange(e, true);
      }}
      onChange={(e) => {
        props.onChange(e);
      }}
    />
  );
}

export default SelectSearch;
