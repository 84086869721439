import Toggle from "react-toggle";
import "react-toggle/style.css";

const Switch = ({
  checked,
  defaultChecked,
  id,
  onChange,
  isDisabled = false,
}) => {
  return (
    <Toggle
      checked={checked}
      defaultChecked={defaultChecked}
      icons={false}
      id={id}
      onChange={onChange}
      disabled={isDisabled}
    />
  );
};

export default Switch;
