import { useContext, useEffect, useState } from "react";
import { UncontrolledTooltip } from "reactstrap";
import moment from "moment";

import Button from "../../components/fields/button/button.component";
import Input from "../../components/fields/input/input.component";
import Modal from "../../components/modal/modal.component";

import { NotificationContext } from "../../contexts/notficationContext";
// import { UserContext } from "../../contexts/userContext";

import apiService from "../../services/api.services";
import getIcon from "../../services/icon.service";
import settingsAPIService from "../../services/api.services/settingsApi.service";

const BackUp = (props) => {
  const [state, setState] = useState({
    bools: {
      isModal: false,
    },
    data: {
      backUp: [],
    },
    fields: {
      createBackUp: "",
    },
    helpers: {
      modalType: "",
      modalSuccessMsg: "",
      tableData: {
        id: "",
        inputId: "",
        name: "",
        oldName: "",
      },
    },
  });
  const { setNotifications } = useContext(NotificationContext);
  const { getUserDetails } = props;

  useEffect(() => {
    getBackUp();
  }, []);

  const createBackUp = async () => {
    try {
      const body = {
        backup_name: state.fields.createBackUp,
        overwrite: false,
      };
      const r = await settingsAPIService.createBackUp(body);

      if (r.status === "success") {
        const localState = { ...state };
        localState.helpers.modalSuccessMsg = "Backup successfully created!";
        localState.helpers.modalType = "_success";
        localState.fields.createBackUp = "";
        setState(localState);

        getBackUp();

        // setNotifications({
        //   color: "success",
        //   message: "Cloud backup added successfully",
        // });
      } else {
        setNotifications({
          color: "danger",
          message: r.message,
        });
      }
    } catch (e) {
      setNotifications({
        color: "danger",
        message: e.message,
      });
      console.log(e);
    }
  };

  const downloadFullBackUp = async () => {
    try {
      const r = await settingsAPIService.downloadFullBackUp();
      handleDownload(r, "Export Successful!");
    } catch (e) {
      setNotifications({ color: "danger", message: e.message });
      console.log(e);
    }
  };

  const downloadSingleBackUp = async () => {
    try {
      const backup_name = state.helpers.tableData.name;

      const r = await settingsAPIService.downloadSingleBackUp(backup_name);

      const localState = { ...state };
      localState.helpers.tableData = { id: "", name: "" };
      setState(localState);

      handleDownload(r, "Download Successful!");
    } catch (e) {
      setNotifications({ color: "danger", message: e.message });
      console.log(e);
    }
  };

  const getBackUp = async () => {
    try {
      const r = await settingsAPIService.getBackUp();

      if (r.status === "success") {
        const localState = { ...state };
        localState.data.backUp = r.data;
        setState(localState);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleFileUpload = async ({ target }) => {
    try {
      const file = target.files;

      if (file.length) {
        const formData = new FormData();
        formData.append("file", file[0]);

        const r = await apiService.importFileData(formData);

        if (r.status === "success") {
          setNotifications({
            color: "success",
            message: "Import Successful!",
          });
        } else {
          setNotifications({
            color: "danger",
            message: r.message,
          });
        }
        target.value = null;
      }
    } catch (e) {
      setNotifications({
        color: "danger",
        message: e.message,
      });
    }
  };

  const handleImportConfigDeploy = ({ target }) => {
    const file = target.files;
    console.log(file);
    setNotifications({
      color: "success",
      message: "Config successfully imported",
    });
  };

  const removeBackUp = async () => {
    try {
      const r = await settingsAPIService.removeBackUp({
        backup_name: state.helpers.tableData.name,
      });

      if (r.status === "success") {
        const localState = { ...state };
        localState.helpers.modalSuccessMsg = "Successfully deleted!";
        localState.helpers.modalType = "_success";
        localState.helpers.tableData = { id: "", name: "" };
        setState(localState);

        // setNotifications({ color: "success", message: r.message });

        getBackUp();
      } else {
        setNotifications({ color: "danger", message: r.message });
      }
    } catch (e) {
      setNotifications({ color: "danger", message: e.message });
      console.log(e);
    }
  };

  const renameBackUp = async () => {
    try {
      const { name, oldName } = state.helpers.tableData;
      const body = {
        old_name: oldName,
        new_name: name,
      };
      const r = await settingsAPIService.renameBackUp(body);

      if (r.status === "success") {
        const localState = { ...state };
        localState.helpers.tableData = { id: "", name: "" };
        setState(localState);
        getBackUp();
        setNotifications({ color: "success", message: r.message });
      } else {
        setNotifications({ color: "danger", message: r.message });
      }
    } catch (e) {
      setNotifications({ color: "danger", message: e.message });
      console.log(e);
    }
  };

  const restoreBackUp = async () => {
    try {
      const body = {
        backup_name: state.helpers.tableData.name,
        retrain: false,
      };
      const r = await settingsAPIService.restoreBackUp(body);

      if (r.status === "success") {
        const localState = { ...state };
        localState.helpers.modalSuccessMsg = "Successfully restored!";
        localState.helpers.modalType = "_success";
        localState.helpers.tableData = { id: "", name: "" };
        setState(localState);

        // setNotifications({
        //   color: "success",
        //   message: "Bot restored",
        // });
      } else {
        setNotifications({ color: "danger", message: r.message });
      }
    } catch (e) {
      setNotifications({ color: "danger", message: e.message });
      console.log(e);
    }
  };

  const handleBools = (label, action = "get", value) => {
    const localState = { ...state };

    if (action === "set") {
      localState.bools[label] =
        value === undefined ? !localState.bools[label] : value;

      setState(localState);
    } else return state.bools[label];
  };

  const handleCreateBackUp = () => {
    if (state.data.backUp.length > 9) return;

    const localState = { ...state };
    localState.helpers.modalType = "_create";
    localState.bools.isModal = true;
    setState(localState);
  };

  const handleDeleteNRestoreModal = (modalType) => {
    let title = "Delete";
    let warningMsg = "Once deleted, you won't be able to recover this backup.";
    if (modalType === "_restore") {
      title = "Restore";
      warningMsg =
        "Once restored, it will overwrite your current bot config and data for both stagging and production config.";
    }

    return (
      <div className="container">
        <p className="backup-create-modal-title mb-4">{title}</p>

        <p className="backup-delete-modal-content">
          Are you sure you want to {title.toLowerCase()} this file?
        </p>
        <p className="backup-delete-modal-sub-content">{warningMsg}</p>

        <div className="row mt-4 justify-content-center">
          <div className="col-auto">
            <Button
              className="backup-create-modal-cancel-btn"
              onClick={() => handleBools("isModal", "set")}
            >
              CANCEL
            </Button>
          </div>

          <div className="col-auto">
            <Button
              className="backup-create-modal-Ok-btn"
              onClick={modalType === "_delete" ? removeBackUp : restoreBackUp}
            >
              {title}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const handleDownload = (data, message) => {
    const url = window.URL.createObjectURL(
      new Blob([JSON.stringify(data)], {
        type: "application/json",
      })
    );
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", "file.json");

    document.body.appendChild(link);

    link.click();

    setNotifications({
      color: "success",
      message: message,
    });
  };

  const handleOnChange = ({ target }) => {
    const localState = { ...state };
    const { name, value } = target;

    localState.fields[name] = value;

    setState(localState);
  };

  const handleModalContent = () => {
    const { modalType } = state.helpers;

    switch (modalType) {
      case "_create":
        return (
          <div className="container">
            <p className="backup-create-modal-title">Create Backup</p>

            <div className="row align-items-center my-4">
              <div className="col text-end">
                <span className="backup-create-modal-inp-label">
                  Backup name
                </span>
              </div>
              <div className="col-auto">
                <Input
                  className="backup-create-modal-inp"
                  name="createBackUp"
                  onChange={handleOnChange}
                  placeholder="Enter backup name"
                  value={state.fields.createBackUp}
                />
              </div>
            </div>

            <div className="row mt-5 justify-content-center">
              <div className="col-auto">
                <Button
                  className="backup-create-modal-cancel-btn"
                  onClick={() => handleBools("isModal", "set")}
                >
                  CANCEL
                </Button>
              </div>

              <div className="col-auto">
                <Button
                  className="backup-create-modal-Ok-btn"
                  isDisabled={!state.fields.createBackUp}
                  onClick={createBackUp}
                >
                  CREATE
                </Button>
              </div>
            </div>
          </div>
        );
      case "_success":
        return (
          <div className="container text-center">
            <div className="row mb-4">
              <div className="col text-end">
                <label
                  className="cursor-pointer"
                  onClick={() => handleBools("isModal", "set")}
                >
                  {getIcon("modal-close")}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col">{getIcon("check-circle")}</div>
            </div>
            <div className="row mt-5">
              <div className="col">
                <p className="backup-modal-success-msg">
                  {state.helpers.modalSuccessMsg}
                </p>
              </div>
            </div>
          </div>
        );
      default:
        return handleDeleteNRestoreModal(modalType);
    }
  };

  const handleSelectionTableRow = (data, index) => {
    const localState = { ...state };

    if (index === state.helpers.tableData.id) {
      localState.helpers.tableData.id = "";
    } else {
      localState.helpers.tableData.id = index;
      localState.helpers.tableData.name = data.backup_name;
      localState.helpers.tableData.oldName = data.backup_name;
    }

    setState(localState);
  };

  const handleModalTypeChange = (modalType) => {
    const localState = { ...state };
    localState.helpers.modalType = modalType;
    localState.bools.isModal = true;
    setState(localState);
  };

  const handleRename = () => {
    const localState = { ...state };
    localState.helpers.tableData.inputId = localState.helpers.tableData.id;
    setState(localState);
  };

  const handleTableBackUpOnChange = ({ target }) => {
    const localState = { ...state };
    localState.helpers.tableData.name = target.value;
    setState(localState);
  };

  const renderTableData = () => {
    const {
      data,
      helpers: { tableData },
    } = state;

    return data.backUp.map((b, i) => (
      <tr
        key={i}
        className="backup-table-data-row"
        style={{
          borderTop: i === 0 ? "hidden" : "3px solid #F5F5F5",
          verticalAlign: "middle",
        }}
        onClick={() => handleSelectionTableRow(b, i)}
      >
        <th>
          {tableData.id === i ? (
            getIcon("small-checkmark")
          ) : (
            <div className="rounded-circle border p-2"></div>
          )}
        </th>
        <th className="backup-table-data" scope="row">
          {i + 1}.
        </th>
        <td className="backup-table-data" style={{ width: "18rem" }}>
          {state.helpers.tableData.inputId === i ? (
            <Input
              autoFocus
              className="backup-list-input"
              onBlur={() =>
                tableData.oldName !== tableData.name.trim() && renameBackUp()
              }
              onChange={handleTableBackUpOnChange}
              onKeyPress={(e) => {
                e.key === "Enter" &&
                  tableData.oldName !== tableData.name.trim() &&
                  renameBackUp();
              }}
              value={state.helpers.tableData.name}
            />
          ) : (
            b.backup_name
          )}
        </td>
        <td className="backup-table-data-modified">
          {moment(new Date(b.backup_date)).format("MMMM Do YYYY, h:mm:ss a")}
        </td>
      </tr>
    ));
  };
  return (
    <div className="container-fluid mt-5">
      <div className="row">
        <div className="col">
          <p className="backup-title">Backup and Restore</p>
        </div>
      </div>

      <div className="row">
        <div className="col-auto">
          <div className="backup-actions-container">
            <div className="row h-100 justify-content-center align-items-center mb-4">
              <div className="col-auto">
                <div
                  className={`backup-action-container ${
                    state.data.backUp.length > 9
                      ? "backup-action-container-disabled"
                      : "cursor-pointer"
                  }`}
                  onClick={handleCreateBackUp}
                >
                  {getIcon("backup-plus", "#ffffff", "", 30)}
                </div>
                <p
                  className={`backup-action-name ${
                    state.data.backUp.length > 9 &&
                    "backup-action-name-disabled"
                  }`}
                >
                  Create Backup
                </p>
              </div>
              {getUserDetails().feature_permission.settings.backup_settings
                .import === "enabled" && (
                <div className="col-auto">
                  <div className="backup-action-container">
                    <Input
                      accept=".json"
                      hidden
                      id="backup-import"
                      onChange={handleFileUpload}
                      type="file"
                    />
                    <label className="cursor-pointer" htmlFor="backup-import">
                      {getIcon("backup-import", "#ffffff", "", 30)}
                    </label>
                  </div>
                  <p className="backup-action-name">
                    Import
                    <span className="ms-2" id="backup-import-info">
                      {getIcon("knowMore")}
                    </span>
                    <UncontrolledTooltip
                      placement="top"
                      target="backup-import-info"
                    >
                      Please select an existing local bot config backup from
                      your system.
                    </UncontrolledTooltip>
                  </p>
                </div>
              )}
              {getUserDetails().feature_permission.settings.backup_settings
                .export === "enabled" && (
                <div className="col-auto">
                  <div
                    className="backup-action-container cursor-pointer"
                    onClick={downloadFullBackUp}
                  >
                    {getIcon("backup-export", "#ffffff", "", 30)}
                  </div>
                  <p className="backup-action-name">
                    Export
                    <span className="ms-2" id="backup-export-info">
                      {getIcon("knowMore")}
                    </span>
                    <UncontrolledTooltip
                      placement="top"
                      target="backup-export-info"
                    >
                      Please select this to export the current active bot config
                      to your local system
                    </UncontrolledTooltip>
                  </p>
                </div>
              )}
            </div>
            {state.data.backUp.length > 9 && (
              <p className="backup-create-warning-msg">
                * You have created maximum backups, kindly delete some to add
                new backup.
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="row my-5">
        <div className="col">
          <p className="backup-title m-0">Backup List</p>
        </div>
      </div>

      <div className="row" style={{ width: "661px" }}>
        <div className="col-auto">
          <Button
            className="backup-action-btn"
            isDisabled={state.helpers.tableData.id === ""}
            onClick={handleRename}
          >
            Rename
          </Button>
        </div>
        <div className="col-auto">
          <Button
            className="backup-action-btn"
            isDisabled={state.helpers.tableData.id === ""}
            onClick={() => handleModalTypeChange("_restore")}
          >
            Restore
          </Button>
        </div>
        {getUserDetails().feature_permission.settings.backup_settings
          .download === "enabled" && (
          <div className="col-auto">
            <Button
              className="backup-action-btn"
              isDisabled={
                state.helpers.tableData.id === "" ||
                getUserDetails().feature_permission.settings.backup_settings
                  .import === "disabled"
              }
              onClick={downloadSingleBackUp}
            >
              Download
            </Button>
          </div>
        )}
        <div className="col text-end">
          <Button
            className="backup-action-btn"
            isDisabled={state.helpers.tableData.id === ""}
            onClick={() => handleModalTypeChange("_delete")}
          >
            Delete
          </Button>
        </div>
      </div>

      <div className="row">
        <div className="col-auto mt-5" style={{ width: "661px" }}>
          <table className="backup-table table table-hover">
            <thead className="backup-table-header">
              <tr>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col">Name</th>
                <th scope="col">
                  Modified
                  <span className="ms-2">
                    {getIcon("chevronDown", "#3B3B3B", "", 10)}
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>{renderTableData()}</tbody>
          </table>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-6">
          {/* <p className="settings-backup-note">* Note: Botframework</p> */}
        </div>
      </div>

      <Modal
        // className="backup-restore-modal"
        addNewModalOpen={state.bools.isModal}
        bodyClassName={`backup-modal-container ${
          state.helpers.modalType !== "_success" ? "py-5" : "pt-4 pb-5"
        }`}
        isHeader={false}
        modalSize="md"
      >
        {handleModalContent()}
      </Modal>
    </div>
  );
};

export default BackUp;
