import botService from "../bot.service";
import baseUrl from "../baseURL.service";
import { getToken } from "../authUtil";
import apiServices from "../api.services";

const fetchData = (url, options) => {
  return apiServices.fetchData(url, { ...options });
};

function getBotEntity() {
  let reqOptions = {
    method: "GET",
    headers: {
      token: getToken(),
    },
  };
  return fetchData(
    `${baseUrl}/get_bot_entities/${botService.getSelectedBotId()}`,
    reqOptions
  );
}

const deleteBotEntity = (data) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(data),
  };
  return fetchData(
    `${baseUrl}/delete_bot_entity/${botService.getSelectedBotId()}`,
    requestOptions
  );
};

const validateData = () => {
  const botId = botService.getSelectedBotId();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };

  return fetchData(
    `${baseUrl}/${botId}/bot/stage/webhooks/gnani_chat/data_validator?validation_type=outlier`,
    requestOptions
  );
};

const updateBotEntity = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(data),
  };
  return fetchData(
    `${baseUrl}/modify_bot_entity/${botService.getSelectedBotId()}`,
    requestOptions
  );
};

const addBotEntity = (body) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(body),
  };

  return fetchData(
    `${baseUrl}/add_bot_entity/${botService.getSelectedBotId()}`,
    requestOptions
  );
};

const renameBotEntity = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(data),
  };
  return fetchData(
    `${baseUrl}/rename_bot_entity/${botService.getSelectedBotId()}`,
    requestOptions
  );
};

const getEntityValues = (intent) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  return fetchData(
    `${baseUrl}/get_entity_values/${botService.getSelectedBotId()}?entity=${intent}`,
    requestOptions
  );
};

const getSystemEntities = () => {
  let reqOptions = {
    method: "GET",
    headers: {
      token: getToken(),
    },
  };
  return fetchData(
    `${baseUrl}/get_system_entities/${botService.getSelectedBotId()}`,
    reqOptions
  );
};

const updateSystemEntities = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(data),
  };
  return fetchData(
    `${baseUrl}/update_system_entities/${botService.getSelectedBotId()}`,
    requestOptions
  );
};

const importEntites = (file, update_config_data) => {
  let formData = new FormData();
  formData.append("file", file);
  let ext = file?.name?.split(".").pop();
  let url =
    ext === "json"
      ? `import_json/${botService.getSelectedBotId()}?config_import_key=entities&update_config_data=${
          update_config_data ? "True" : "False"
        }`
      : `import_entities/${botService.getSelectedBotId()}?update_config_data=${
          update_config_data ? "True" : "False"
        }`;
  const requestOptions = {
    method: "POST",
    headers: { token: getToken() },
    body: formData,
  };
  return fetchData(`${baseUrl}/${url}`, requestOptions);
};

const validateImport = (file) => {
  let formData = new FormData();
  formData.append("file", file);
  let ext = file?.name?.split(".").pop();
  let url =
    ext === "json"
      ? `validate_import_json/${botService.getSelectedBotId()}?config_import_key=entities`
      : `validate_import_csv/${botService.getSelectedBotId()}?config_import_key=entities`;
  const requestOptions = {
    method: "POST",
    headers: { token: getToken() },
    body: formData,
  };
  return fetchData(`${baseUrl}/${url}`, requestOptions);
};

const entityService = {
  getBotEntity,
  validateData,
  getEntityValues,
  deleteBotEntity,
  updateBotEntity,
  renameBotEntity,
  addBotEntity,
  importEntites,
  updateSystemEntities,
  getSystemEntities,
  validateImport
};
export default entityService;
