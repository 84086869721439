import React, { useState, useContext, useRef } from "react";
import Input from "../../components/fields/input/input.component";
import Button from "../../components/fields/button/button.component";
import contactusImg from "../../assets/icons/contactusImg.svg";
import "./contactUs.scss";
import apiServices from "../../services/api.services";
import loginImg4 from "../../assets/icons/left-background.svg";
import baseUrl from "../../services/baseURL.service";
import { NotificationContext } from "../../contexts/notficationContext";
import ReCAPTCHA from "react-google-recaptcha";

function ContactUs(props) {
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [contactUsEmail, setContactUsEmail] = useState("");
  const [textarea, setTextArea] = useState("");
  const [isDisabled, setDisabled] = useState(true);

  const { setNotifications } = useContext(NotificationContext);
  const recaptchaRef = useRef();

  // V2 Dev google re captcha key
  // const reCAPTCHA_site_key = "6LdCnEoeAAAAAPWpgdM4AdFHVO8zffjXj5jBMs62";

  // V2 Prod google re captcha key
  const reCAPTCHA_site_key = "6LebMiolAAAAABbuPVVqhbNdBrOAjX965Q9Vq44K";

  // localhost testing key
  // const reCAPTCHA_site_key = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";

  const handleContactUs = async (e) => {
    e.preventDefault();

    if (contactUsEmail && name && message && textarea) {
      const requestOptions = {
        body: JSON.stringify({
          name,
          email: contactUsEmail,
          subject: message,
          message: textarea,
        }),
        headers: { "Content-Type": "application/json" },
        method: "POST",
        mode: "cors",
      };

      try {
        const { message, status } = await apiServices.fetchData(
          `${baseUrl}/contact_us`,
          requestOptions
        );

        if (status === "success") {
          setNotifications({ color: "success", message });
          props.history.push("/auth/login");
        } else {
          setNotifications({ color: "danger", message });
        }
      } catch ({ message }) {
        setNotifications({ color: "danger", message });
      }
    }
    setTextArea("");
    setContactUsEmail("");
    setName("");
    setMessage("");
  };

  const toggle = () => {
    props.history.push("/auth/login");
  };

  return (
    <div className="d-flex m-0 h-100 ">
      <div
        className="left-window"
        style={{ backgroundImage: `url(${loginImg4})`, width: "60.9375rem" }}
      >
        <div className="left-panel-details p-5">
          <div className="left-panel-text">
            If you have any questions or need help, please fill out the form. We
            do our best to respond within 1 business day.
          </div>
        </div>

        <img alt="" src={contactusImg} className="contactUs-img img-fluid" />
      </div>

      <div
        className="d-flex flex-column contactus-right-side"
        style={{ width: "59.0625rem" }}
      >
        <div className="contactus-form py-5 px-3 m-auto">
          <div className="sign-in-text mb-3">Contact Us</div>
          <div className="sign-in-text mb-5">To register your organisation</div>
          <form className="needs-validation p-3" onSubmit={handleContactUs}>
            <div className="form-group my-3">
              <Input
                className="p-3 form-control inp-placeholder-clr-contactUs"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group my-3">
              <Input
                type="email"
                placeholder="Email"
                errorText="Email field is empty!"
                className="p-3 form-control inp-placeholder-clr-contactUs"
                value={contactUsEmail}
                onChange={(e) => setContactUsEmail(e.target.value)}
              />
            </div>
            <div className="form-group my-3">
              <Input
                className="p-3 form-control inp-placeholder-clr-contactUs"
                placeholder="Subject"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <div className="form-group my-3">
              <textarea
                style={{ height: "6rem" }}
                className="p-3 form-control inp-placeholder-clr-contactUs"
                placeholder="Message"
                value={textarea}
                onChange={(e) => setTextArea(e.target.value)}
              />
            </div>

            <ReCAPTCHA
              ref={recaptchaRef}
              className="mb-2"
              sitekey={reCAPTCHA_site_key}
              onChange={() => setDisabled(false)}
            />

            <Button
              className="mt-3 w-100 login-btn"
              type="submit"
              isDisabled={isDisabled}
            >
              Submit
            </Button>
            <div className="mt-3">
              <span className="login-dont-have-acc-text me-2">
                Have an account?
              </span>
              <a
                className="login-contact-us-link"
                onClick={toggle}
                role="button"
              >
                Login
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
