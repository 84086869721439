import { useContext, useEffect, useRef, useState } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  Tooltip,
  UncontrolledTooltip,
} from "reactstrap";

import Button from "../../components/fields/button/button.component";
import DropDown from "../fields/dropDown/dropDown.component";
import AddNewModal from "../modal/modal.component";

import botService from "../../services/bot.service";
import baseUrl, { getImageUrl } from "../../services/baseURL.service";
import { getToken } from "../../services/authUtil";

import gnaniLogo from "../../assets/images/gnani-logo.png";
// import DeleteConfirmation from "../../components/deleteConfirmation.component";
import gnaniLogoV from "../../assets/images/gnani-logoV.png";
import userLogo from "../../assets/icons/user_logo.svg";
import { NotificationContext } from "../../contexts/notficationContext";
import { UserContext } from "../../contexts/userContext";
import { BotContext } from "../../contexts/BotContext";

import "./navbar.scss";
import apiServices from "../../services/api.services";
import settingsAPIService from "../../services/api.services/settingsApi.service";

import svgIcons from "../../services/icon.service";
import ChatWindow from "../chatWindow";
import Input from "../fields/input/input.component";
import Buttons from "../../components/fields/button/button.component";

const isLink = (link) => {
  const pathName = window.location.pathname.split("/")[2];
  return pathName === link ? "nav-link-active" : "nav-link-font";
};

const navItems = [
  {
    className: `nav-links ${isLink("home")}`,
    href: "home",
    label: "My Bots",
    key: "my_bots",
  },
  {
    className: `nav-links ${isLink("sharedbots")}`,
    href: "sharedbots",
    label: "Shared Bots",
    key: "shared_bots",
  },
  {
    className: `nav-links ${isLink("marketPlace")}`,
    href: "marketPlace",
    label: "Market Place",
    key: "marketplace",
  },
  {
    className: `nav-links ${isLink("knowledgeBase")}`,
    href: "knowledgeBase",
    label: "Knowledge Base",
    key: "knowledge_base",
  },
  // {
  //   className: `nav-links ${isLink("createBot")}`,
  //   href: "createBot",
  //   label: "Create Bot",
  //   key: "create_bot",
  // },
];

const isExport = ["/bot/faqs", "/bot/intents", "/bot/response", "/bot/entity"];

const page = {
  intents: "intents",
  response: "responses",
  entity: "entity",
  faqs: "knowledge_base",
};

const dropDownList = (getUserDetails, clearStorage, props) => {
  return (
    <>
      <div
        className="row m-0 py-2 cursor-pointer highlight"
        onClick={() => props.history.push("/admin/profile_settings")}
      >
        <div className="col-auto px-2 ps-3 m-auto align-items-center">
          <div className="navbar-profile-icon-container">
            {getUserDetails() ? (
              <img
                alt=""
                style={{ width: "1.5rem", height: "1.5rem" }}
                src={
                  getUserDetails().profile_pic
                    ? getImageUrl(getUserDetails().profile_pic)
                    : userLogo
                }
              />
            ) : (
              <i className="fas fa-user" />
            )}
          </div>
        </div>
        <div className="col ps-1 user-name-font">
          <span>
            {" "}
            {getUserDetails()
              ? getUserDetails().first_name + " " + getUserDetails().last_name
              : "User"}
          </span>
          <br />
          <span className="user-email-font">{getUserDetails()?.email}</span>
        </div>
      </div>
      <hr className="mt-2 mx-2" />
      {getUserDetails().user_type === "admin" ? (
        <div
          style={{ height: "3rem" }}
          className="row m-0 py-2 cursor-pointer highlight"
          onClick={() => {
            props.history.push("/admin/organisation_settings");
          }}
        >
          <div className="col-3 px-2 ps-3 text-center">
            {svgIcons("user-setting")}
          </div>
          <span className="col logout-font">Admin Settings</span>{" "}
        </div>
      ) : (
        <div style={{ height: "3rem" }}></div>
      )}
      <hr className="my-2 mx-2" />
      <div
        className="row m-0 py-2 cursor-pointer highlight"
        onClick={() => {
          clearStorage();
          props.history.push("/auth/login");
        }}
      >
        <div className="col-3 px-2 ps-3 text-center">
          {svgIcons("sign-out")}
        </div>
        <span className="col logout-font">Logout</span>
      </div>
    </>
  );
};

const renderNavItems = (permissions) =>
  navItems.map((n, i) =>
    permissions &&
    ["enabled", "disabled", "read_only"].includes(
      permissions.dashboard[n.key]
    ) ? (
      <NavItem className="col-auto d-flex align-items-center" key={i}>
        <NavLink
          className={n.className}
          href={
            ["enabled", "read_only"].includes(permissions.dashboard[n.key])
              ? n.href
              : ""
          }
        >
          {n.label}
        </NavLink>
      </NavItem>
    ) : (
      n.key === "knowledge_base" && (
        <NavItem className="col-auto d-flex align-items-center" key={i}>
          <NavLink className={n.className} href={n.href}>
            {n.label}
          </NavLink>
        </NavItem>
      )
    )
  );

const AdminNavBar = (props) => {
  const { getUserDetails, clearStorage } = useContext(UserContext);
  return (
    <div className="row m-0 align-items-center w-100">
      <div className="col-auto">
        <img alt="Gnani Logo" className="img-fluid" src={gnaniLogo} />
      </div>

      <div className="col">
        <Nav className="row m-0">
          {renderNavItems(getUserDetails().feature_permission)}
        </Nav>
      </div>

      <div className="col-auto py-2 px-4">
        <Button
          className=" rounded-2 train-btn intent-font-train "
          onClick={() => {
            ["enabled", "read_only"].includes(
              getUserDetails().feature_permission.dashboard["create_bot"]
            ) && props.history.push("/admin/createbot");
          }}
        >
          Create Bot
        </Button>
      </div>

      <div className="col-auto py-2 px-0 user-name-font">
        {getUserDetails().first_name + " " + getUserDetails().last_name}
      </div>
      <div className="col-auto">
        <DropDown
          actionButton={() => (
            <div className="navbar-profile-icon-container">
              {getUserDetails() ? (
                <img
                  alt=""
                  style={{ width: "2rem", height: "2rem" }}
                  // src={getImageUrl(getUserDetails().profile_pic)}
                  src={
                    getUserDetails().profile_pic
                      ? getImageUrl(getUserDetails().profile_pic)
                      : userLogo
                  }
                />
              ) : (
                <i className="fas fa-user" />
              )}
            </div>
          )}
          btnClassName="p-0 text-light"
          isCustomDropDownList
          navWidth
          dropdownList={() => dropDownList(getUserDetails, clearStorage, props)}
        />
      </div>
    </div>
  );
};

const BotsNavBar = (props) => {
  const [trainStatus, setTrainStatus] = useState(0);
  const [trainDate, setTrainDate] = useState("");
  const [trainCancel, setTrainCancel] = useState(false);
  const [deployModal, setDeployModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState({});
  const [selectedBotId, setSelectedBotId] = useState(
    botService.getSelectedBotId()
  );
  const [botConfig, setBotConfig] = useState({});
  const [deploymentDetails, setDeploymentDetails] = useState();
  const [deployBotPassword, setDeployBotPassword] = useState("");
  const [flowID, setFlowID] = useState("");
  const [newFlowID, setNewFlowID] = useState("");
  const [enviStatus, setEnviStatus] = useState({
    envi: "Development",
    bot: "",
  });
  const [toolTip, setToolTip] = useState(false);

  const { paths, savePathWithTrain, setTainingLog } = useContext(BotContext);
  const { getUserDetails, clearStorage } = useContext(UserContext);
  const { setNotifications } = useContext(NotificationContext);

  const trainingInfoRef = useRef(null);

  const location = props.location.pathname.split("bot/")[1];

  useEffect(() => {
    setSelectedContact(getUserDetails());
  }, []);

  const handleClickOutside = (event) => {
    if (
      trainingInfoRef.current &&
      !trainingInfoRef.current.contains(event.target)
    ) {
      setToolTip(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  let botList = [];
  botList = botService.getBotList();
  botList =
    botList?.data?.length > 0
      ? botList.data.map((bot) => {
          return {
            bot,
            label: bot.bot_name,
          };
        })
      : [];

  const selectedBot = botService.getSelectedBot();
  const loadingText = useContext(NotificationContext);
  const { navBarButtons } = useContext(BotContext);

  useEffect(() => {
    getLastTrainedDate();
    getBotStatus(botService.getSelectedBotId());
    getBotConfig(botService.getSelectedBotId());
  }, [navBarButtons]);

  function timeout(ms, promise) {
    return new Promise((resolve, reject) => {
      const timer = setTimeout(() => {
        reject(new Error("TIMEOUT"));
      }, ms);

      promise
        .then((value) => {
          clearTimeout(timer);
          resolve(value);
        })
        .catch((reason) => {
          clearTimeout(timer);
          reject(reason);
        });
    });
  }

  function getBotConfig(bot_ID) {
    botService
      .getBotConfig(bot_ID)
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          botService.setSelectedBot(response.data);
          setBotConfig(response.data);
          getDeploymentDetails();
        }
      })
      .catch((error) => {
        console.error("Failed: ", error);
      });
  }

  const getBotStatus = (bot_id) => {
    apiServices
      .getBotStatus(bot_id)
      .then((res) => {
        if (res.status === "success")
          setEnviStatus({ ...enviStatus, bot: res.bot_status.status });
      })
      .catch((e) => console.log("Failed: ", e));
  };

  function getDeploymentDetails() {
    let bot_id = botService.getSelectedBotId() || this.props.test_bot_ID;
    let reqOptions = {
      method: "GET",
      headers: {
        token: getToken(),
      },
    };
    apiServices
      .fetchData(`${baseUrl}/deployment_details/${bot_id}`, reqOptions)
      .then((response) => {
        if (response.status === "success") {
          setDeploymentDetails(response.data);
        } else {
          setDeploymentDetails({ error: response.message });
        }
      })
      .catch((error) => {
        console.error("Failed: ", error);
      });
  }

  const onTrainClick = () => {
    let reqOptions = {
      method: "GET",
      headers: { token: getToken() },
    };
    setTrainStatus(1);
    timeout(
      300000,
      fetch(
        `${baseUrl}/train_bot/v3/${botService.getSelectedBotId()}`,
        reqOptions
      )
    )
      .then((r) => r.json())
      .then((r) => {
        console.log(r);
        if (r.status === "success") {
          setNotifications({ color: "success", message: r.message });
        } else {
          setTrainStatus(3);
          setNotifications({ color: "danger", message: r.message });
        }
      })
      .catch((e) => {
        console.log(e);
        setTrainStatus(3);
        setNotifications({ color: "danger", message: e.message });
      });
  };

  const cancelTain = () => {
    const body = {
      bot_id: botService.getSelectedBotId(),
    };
    const reqOption = {
      method: "GET",
      headers: { token: getToken() },
      // body: JSON.stringify(body),
    };

    fetch(
      `${baseUrl}/training_cancel/${botService.getSelectedBotId()}`,
      reqOption
    )
      .then((r) => r.json())
      .then((r) => {
        if (r.message === "the bot training is cancelled") {
          setNotifications({ color: "success", message: r.message });
          setTrainStatus(3);
          getLastTrainedDate();
        } else {
          setNotifications({ color: "danger", message: r.message });
          // setTrainStatus(3);
        }
      })
      .catch((e) => {
        setNotifications({ color: "danger", message: e.message });
      });
  };

  const getTrainLog = () => {
    setTainingLog(true);

    let reqOptions = {
      method: "GET",
      headers: { token: getToken() },
    };
    fetch(
      `${baseUrl}/training_logs/${botService.getSelectedBotId()}`,
      reqOptions
    )
      .then((r) => r.json())
      .then((r) => {
        setTainingLog(true, r);
      })
      .catch((e) => setNotifications({ color: "danger", message: e.message }));
  };

  const getLastTrainedDate = () => {
    if (botService.getSelectedBotId()) {
      let reqOptions = {
        method: "GET",
        headers: { token: getToken() },
      };
      apiServices
        .fetchData(
          `${baseUrl}/training_status/${botService.getSelectedBotId()}`,
          reqOptions
        )
        .then((response) => {
          if (response.status === "success") {
            setTrainDate(response);
            setTrainStatus(
              response.training_status === "unknown"
                ? 0
                : response.training_status === "success"
                ? 2
                : response.training_status === "failed"
                ? 3
                : response.training_status === "cancelled"
                ? 3
                : 1
            );
            response.training_status === "in-progress"
              ? setTrainCancel(true)
              : setTrainCancel(false);
          } else setTrainDate(response);
        })
        .catch((error) => {});
    }
  };

  const onDeployClick = () => {
    let reqOptions = {
      method: "POST",
      headers: { token: getToken() },
      body: JSON.stringify({
        language: selectedBot.language,
        bot_type: selectedBot.bot_type,
        channels: selectedBot.bot_channels,
        flow_id: newFlowID,
      }),
    };
    apiServices
      .fetchData(
        `${baseUrl}/deploy_bot/${botService.getSelectedBotId()}`,
        reqOptions
      )
      .then((res) => {
        if (res.status === "success") {
          let temp = {
            ...selectedBot,
            bot_id: `${selectedBot.bot_id.split("-")[0]}-kubernetes-cprod`,
          };
          botService.setSelectedBot(temp);
          botService.setSelectedBotId(
            `${selectedBot.bot_id.split("-")[0]}-kubernetes-cprod`
          );
          props.history.push(`${props.location.pathname}`);
          setEnviStatus({ envi: "Production", bot: enviStatus.bot });
          // loadingText.setNotifications({
          //   color: "black",
          //   message:
          //     "Please contact sales at salesteam@gnani.ai for further details",
          // });
        } else {
          loadingText.setNotifications({
            color: "success",
            message: "Your bot has been deployed with the latest config",
          });
        }
      })
      .catch((err) =>
        loadingText.setNotifications({
          color: "danger",
          message: err.message,
        })
      );
  };

  const getDateTimeFormatedString = (dateStr) => {
    let date;
    try {
      date = new Date(dateStr).toGMTString();
      date = date === "Invalid Date" ? dateStr : date;
    } catch (error) {
      date = dateStr;
    }
    return date;
  };

  const toggleInfo = () => {
    setToolTip(!toolTip);
    setTimeout(() => {
      arrowColor();
    }, 100);
  };

  const arrowColor = () => {
    let div = document.querySelector(".training-info-arrow");
    let arrow = div.querySelector(".arrow");
    arrow.className += " training-info-arrow";
  };

  const validateNode = () => {
    let x = [];
    Object.keys(paths).forEach((pathName) => {
      paths[pathName].forEach((obj) => {
        let type = [
          "actions",
          "intent",
          "form",
          "slots",
          "type",
          "digits",
          "flow",
        ];
        if (!["decision", "dtmf-decision", "slot-decision"].includes(obj.who)) {
          const whichType = Object.keys(obj).filter((e) => type.includes(e));
          x.push(obj[whichType[0]]);
        } else if (obj.who === "decision") {
          Object.keys(obj.checkpoints).forEach((checkpoint) => {
            if (obj.checkpoints[checkpoint].length > 1) {
              obj.checkpoints[checkpoint].forEach((int, i) => {
                x.push(int.intent);
              });
            } else {
              x.push(obj.checkpoints[checkpoint][0]["intent"]);
            }
          });
        } else if (obj.who === "slot-decision") {
          Object.keys(obj.checkpoints).forEach((checkpoint) => {
            x.push(obj.checkpoints[checkpoint][0]["slots"]);
          });
        }
      });
    });
    return x;
  };

  const handleDownload = (data, message) => {
    const url = window.URL.createObjectURL(
      new Blob([JSON.stringify(data)], {
        type: "application/json",
      })
    );
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", "file.json");

    document.body.appendChild(link);

    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);

    setNotifications({
      color: "success",
      message: message,
    });
  };

  const handleExport = async (filter) => {
    try {
      const r = await settingsAPIService.exportIndividualConfig(filter);
      handleDownload(r, "Export Successful!", filter);
    } catch (e) {
      setNotifications({ color: "danger", message: e.message });
      console.log(e);
    }
  };

  const getExportFilter = () => {
    const page = props.location.pathname.split("/")[2];

    switch (page) {
      case "intents":
        handleExport("intents");
        break;
      case "response":
        handleExport("responses");
        break;
      case "entity":
        handleExport("entities");
        break;
      case "faqs":
        handleExport("faq");
        break;
      default:
        console.log(page);
        break;
    }
  };

  const renderExportBtn = () => {
    return (
      <div className="col-auto">
        <Button
          className="intents-btn intent-font-save rounded"
          onClick={getExportFilter}
          isDisabled={
            selectedContact.feature_permission &&
            selectedContact.feature_permission[page[location]].export ===
              "disabled"
          }
          styles={{
            opacity: `${
              selectedContact.feature_permission &&
              selectedContact.feature_permission[page[location]].export ===
                "disabled"
                ? "0.6"
                : "1"
            }`,
          }}
        >
          {svgIcons("file-export", "white", "me-1")}
          Export
        </Button>
      </div>
    );
  };

  const getBotProdFlowID = () => {
    let bot_id = `${selectedBot.bot_id.split("-")[0]}-kubernetes-cprod`;
    apiServices.getBotFlowID(bot_id).then((res) => {
      if (res.status === "success") {
        setFlowID(res.flow_id);
        setNewFlowID(res.flow_id);
      }
    });
  };

  const updateBotProdFlowID = (newFlowID) => {
    let bot_id = `${selectedBot.bot_id.split("-")[0]}-kubernetes-cprod`;

    apiServices.updateBotFlowID({ flow_id: newFlowID }, bot_id).then((res) => {
      if (res.status === "success") {
        onDeployClick();
        setDeployModal(false);
        setDeployBotPassword("");
        loadingText.setNotifications({
          color: "black",
          message: "Please wait for 5 min till the bot gets deployed",
        });
      }
    });
  };

  const getDeployConfirmationModal = () => {
    return (
      <AddNewModal
        maxwidth={"652px"}
        isHeader={true}
        noCloseBtn={true}
        bodyClassName="p-4"
        headerClassName={"pb-4"}
        title="Production Deployment"
        addNewModalOpen={deployModal}
        className="rounded-3"
      >
        <div className="deploy-info-1 mb-5 mx-3">
          Doing this will push all the changes you have made to your bot in the
          development workspace and deploy it to a scalable production
          environment through which the bot can be deployed to live customer
          channels. After going into the Production workspace please add your
          production facing integrations.
        </div>
        <div className="deploy-info-2 mb-4 mx-5">
          Please enter the password given to you to deploy your changes to
          production
        </div>
        <div
          className="d-flex justify-content-center align-items-center mb-3"
          style={{ flexDirection: "column" }}
        >
          <form>
            <Input
              id="modal-deploy-input"
              className="deploy-comp-paswrd-input px-3 text-center"
              placeholder="Enter Password"
              type="password"
              value={deployBotPassword}
              onChange={({ target }) => setDeployBotPassword(target.value)}
            />
          </form>
          <div className="row m-0 align-items-center mt-3 deploy-comp-paswrd-input">
            <lable className="col-3">Flow ID: </lable>
            <Input
              className="col ps-0 px-3 offset-md-2 border-0"
              placeholder="Flow ID"
              style={{}}
              value={newFlowID}
              onChange={({ target }) => setNewFlowID(target.value)}
            />
          </div>
        </div>
        <div className="deploy-info-3 mb-5 mx-5">
          Note - If you don’t have the deployment password, please reach out to
          your contact at gnani and we will help you with it.
        </div>
        <div className="row m-0 justify-content-center">
          <Buttons
            className={"col-auto deploy-cancel-btn mx-2"}
            onClick={() => {
              setDeployBotPassword("");
              setDeployModal(false);
            }}
          >
            Cancel
          </Buttons>
          <Buttons
            className={"col-auto deploy-confirm-btn mx-2"}
            onClick={() => {
              if (deployBotPassword === "deployBot@123#7") {
                if (
                  (!flowID && newFlowID) ||
                  (flowID && flowID === newFlowID)
                ) {
                  onDeployClick();
                  setDeployModal(false);
                  setDeployBotPassword("");
                  loadingText.setNotifications({
                    color: "black",
                    message: "Please wait for 5 min till the bot gets deployed",
                  });
                } else if (flowID && flowID !== newFlowID) {
                  updateBotProdFlowID(newFlowID);
                }
              } else {
                setDeployModal(false);
                setDeployBotPassword("");
                loadingText.setNotifications({
                  color: "danger",
                  message: "Incorrect Passsword or Flow ID",
                });
              }
            }}
          >
            Confirm
          </Buttons>
        </div>
      </AddNewModal>
    );
  };

  return (
    <div className="row align-items-center w-100 m-0">
      <div className="col-auto ps-0">
        <DropDown
          dropdownList={botList}
          btnClassName="intents-dropDown py-2"
          actionButton={() => (
            <div className="row">
              <div
                className="col-auto intents-dropDown-text truncate"
                id={`bot-${selectedBot.bot_id}`}
              >
                {selectedBot && selectedBot.bot_name}
              </div>
              <UncontrolledTooltip
                placement="right"
                target={`bot-${selectedBot.bot_id}`}
              >
                {selectedBot && selectedBot.bot_name}
              </UncontrolledTooltip>
              <div className="col d-flex justify-content-end align-items-center">
                {svgIcons("chevronDown", "#FFF", "", 12)}
              </div>
            </div>
          )}
          onActionItemClick={({ bot }) => {
            botService.setSelectedBot(bot);
            props.history.push("/bot/flows");
            setSelectedBotId(botService.getSelectedBotId());
          }}
          isCustomMenuStyle={{
            width: "100%",
            maxHeight: "30vh",
            overflow: "auto",
          }}
        />
      </div>
      {isExport.includes(props.location.pathname) &&
        selectedContact.feature_permission &&
        selectedContact.feature_permission[page[location]].export !== "hide" &&
        renderExportBtn()}
      <div className="col-auto">{navBarButtons}</div>
      <div className="col text-center">
        <Button
          id="trainButton"
          toolTipText={getDateTimeFormatedString(trainDate.last_train_req)}
          isDisabled={
            trainStatus === 1 ||
            (selectedContact.feature_permission &&
              selectedContact.feature_permission.top_header.training !==
                "enabled")
          }
          className="train-btn intent-font-train w-auto"
          onClick={() => {
            if (
              !validateNode().some(
                (ele) =>
                  ele === "" ||
                  ele === undefined ||
                  (typeof ele === "object" &&
                    Array.isArray(ele) &&
                    ele.length < 1) ||
                  (typeof ele === "object" &&
                    Array.isArray(ele) &&
                    ele.some((ele) => ele.length === 0)) ||
                  (typeof ele === "object" &&
                    !Array.isArray(ele) &&
                    ele !== null &&
                    ele[Object.keys(ele)[0]] === "")
              )
            ) {
              Object.keys(paths).length > 0 &&
                savePathWithTrain(paths, onTrainClick);
              setTrainCancel(true);
            } else {
              setNotifications({
                color: "danger",
                message: "select values for each node before training",
              });
            }
          }}
        >
          {trainStatus !== 0 ? (
            <h5 className="d-inline me-2">
              {svgIcons(
                trainStatus === 1
                  ? "spinner"
                  : trainStatus === 2
                  ? "circle-check"
                  : "circle-exclaim",
                "",
                "align-middle"
              )}
            </h5>
          ) : (
            ""
          )}
          Train Bot
        </Button>
        {trainCancel && (
          <Button
            id="canel-train-btn"
            toolTipText={"Cancel"}
            className={"ps-2"}
            onClick={cancelTain}
          >
            {svgIcons("close-btn")}
          </Button>
        )}
        {selectedContact.feature_permission &&
          selectedContact.feature_permission.top_header.training_logs !==
            "hide" && (
            <div className="d-inline-block" ref={trainingInfoRef}>
              <Button id="training-info" onClick={toggleInfo}>
                {svgIcons("training-info", "#495072")}
              </Button>
              <Tooltip
                target="training-info"
                placement="bottom"
                isOpen={toolTip}
                innerClassName="training-info-container"
                className="training-info-arrow"
              >
                <div>
                  <div className="my-2 py-2">
                    <span className="training-status">Training status</span>
                    {getUserDetails()?.email?.includes("@gnani.ai") && (
                      <div className="row m-0 mt-3">
                        <label className="col-4 training-key">Status</label>
                        <span className="col-8 training-value">
                          <a
                            aria-disabled
                            // href={`https://jenkins.gnani.ai/job/NLP-BOT-PIPELINE/job/botframework/job/training-job-stats/job/${botService.getSelectedBotId()}`}
                            // target={"_blank"}
                            className="training-value"
                            onClick={getTrainLog}
                          >
                            Click here for Status
                          </a>
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="">
                    <div className="training-info-subheader align-items-center justify-content-center d-flex">
                      <span className="text-center">Previous train status</span>
                    </div>
                    <div className="row m-0 py-3">
                      <label className="col-4 training-key mb-1 py-1">
                        Status
                      </label>
                      <span className="col-8 training-value py-1">
                        {trainDate.training_status &&
                          trainDate.training_status
                            .substring(0, 1)
                            .toUpperCase() +
                            trainDate.training_status.substring(1)}
                      </span>
                      <label className="col-4 training-key mb-1 py-1">
                        Date
                      </label>
                      <span className="col-8 training-value py-1">
                        {getDateTimeFormatedString(
                          trainDate?.last_train_req
                        )?.substring(0, 16)}
                      </span>
                      <label className="col-4 training-key mb-1 py-1">
                        Time
                      </label>
                      <span className="col-8 training-value py-1">
                        {getDateTimeFormatedString(
                          trainDate?.last_train_req
                        )?.substring(16)}
                      </span>
                    </div>
                  </div>
                  <div className="">
                    <div className="training-info-subheader align-items-center justify-content-center d-flex">
                      <span className="text-center">
                        Last successful training
                      </span>
                    </div>
                    <div className="row m-0 py-3">
                      <label className="col-4 training-key mb-1 py-1">
                        Date
                      </label>
                      <span className="col-8 training-value py-1">
                        {getDateTimeFormatedString(
                          trainDate?.last_train_success_req
                        )?.substring(0, 16)}
                      </span>
                      <label className="col-4 training-key mb-1 py-1">
                        Time
                      </label>
                      <span className="col-8 training-value py-1">
                        {getDateTimeFormatedString(
                          trainDate.last_train_success_req
                        )?.substring(16)}
                      </span>
                    </div>
                  </div>
                  <div className="">
                    <div className="training-info-subheader align-items-center justify-content-center d-flex">
                      <span className="text-center">Last failed training</span>
                    </div>
                    <div className="row m-0 py-3">
                      <label className="col-4 training-key mb-1 py-1">
                        Date
                      </label>
                      <span className="col-8 training-value py-1">
                        {getDateTimeFormatedString(
                          trainDate?.last_train_failed_req
                        )?.substring(0, 16)}
                      </span>
                      <label className="col-4 training-key mb-1 py-1">
                        Time
                      </label>
                      <span className="col-8 training-value py-1">
                        {getDateTimeFormatedString(
                          trainDate?.last_train_failed_req
                        )?.substring(16)}
                      </span>
                    </div>
                  </div>
                </div>
              </Tooltip>
            </div>
          )}
      </div>

      <div className="col-auto d-flex align-items-center pe-0">
        {selectedContact.feature_permission &&
          selectedContact.feature_permission.top_header.testing ===
            "enabled" && (
            <ChatWindow
              botId={selectedBotId}
              botConfig={botConfig}
              selectedContact={selectedContact}
              deploymentDetails={deploymentDetails}
            />
          )}
        {selectedContact.feature_permission &&
          selectedContact.feature_permission.top_header.deployment !==
            "hide" && (
            <>
              <Button
                id="DeployTooltip"
                className="intents-play-btn rounded-2 px-3 me-2"
                onClick={() => {
                  getBotProdFlowID();
                  setDeployBotPassword("");
                  trainStatus === 2 && setDeployModal(true);
                }}
                toolTipText={`${
                  trainStatus !== 2 ? "Please train the bot to deploy" : ""
                }`}
                styles={{
                  color: "#51597e",
                  opacity: `${
                    botService.getSelectedBotId().includes("cprod") ||
                    trainStatus !== 2 ||
                    (selectedContact.feature_permission &&
                      selectedContact.feature_permission.top_header
                        .deployment !== "enabled")
                      ? "0.6"
                      : "1"
                  }`,
                }}
                isDisabled={
                  botService.getSelectedBotId().includes("cprod") ||
                  deployBotPassword ||
                  (selectedContact.feature_permission &&
                    selectedContact.feature_permission.top_header.deployment !==
                      "enabled")
                }
              >
                Deploy
              </Button>
              <DropDown
                btnClassName="env-drop-down-btn"
                disabled={
                  selectedContact.feature_permission &&
                  selectedContact.feature_permission.top_header.deployment !==
                    "enabled"
                }
                dropdownList={[
                  { label: "Development" },
                  {
                    label: "Production",
                    // isUsed: enviStatus.bot && enviStatus.bot !== "running",
                  },
                ]}
                actionButton={() => (
                  <div className="row m-0">
                    <div className="col px-2" style={{ color: "#51597E" }}>
                      {botService.getSelectedBotId().includes("cprod")
                        ? "Production"
                        : "Development"}
                    </div>
                    <div className="col-auto d-flex flex-column px-0">
                      {svgIcons("angle-down", "", "arrow-icon-clr mt-1")}
                    </div>
                  </div>
                )}
                onActionItemClick={(m) => {
                  if (m.label === "Development") {
                    let tempId = selectedBot.bot_id.replace("cprod", "cdev");
                    let tempBot = { ...selectedBot, bot_id: tempId };
                    botService.setSelectedBot(tempBot);
                    botService.setSelectedBotId(tempId);
                    if (botService.getSelectedBotId().includes("cdev")) {
                      props.history.push(`${props.location.pathname}`);
                      setEnviStatus({
                        envi: "Development",
                        bot: enviStatus.bot,
                      });
                      loadingText.setNotifications({
                        color: "success",
                        message: "Environment changed to Development",
                      });
                    }
                  } else {
                    let tempId = selectedBot.bot_id.replace("cdev", "cprod");
                    let tempBot = { ...selectedBot, bot_id: tempId };
                    botService.setSelectedBot(tempBot);
                    botService.setSelectedBotId(tempId);
                    if (botService.getSelectedBotId().includes("cprod")) {
                      props.history.push(`${props.location.pathname}`);
                      setEnviStatus({
                        envi: "Production",
                        bot: enviStatus.bot,
                      });
                      loadingText.setNotifications({
                        color: "success",
                        message: "Environment changed to Production",
                      });
                    }
                  }
                }}
              />
            </>
          )}
        <div
          className="col-auto rounded-circle ms-2"
          style={{ width: "2.5rem", height: "2.5rem" }}
        >
          <DropDown
            actionButton={() => (
              <div className="navbar-profile-icon-container">
                {getUserDetails() ? (
                  <img
                    alt=""
                    style={{ width: "2rem", height: "2rem" }}
                    src={
                      getUserDetails().profile_pic
                        ? getImageUrl(getUserDetails().profile_pic)
                        : userLogo
                    }
                  />
                ) : (
                  <i className="fas fa-user" />
                )}
              </div>
            )}
            btnClassName="p-0 text-light"
            isCustomDropDownList
            navWidth
            dropdownList={() =>
              dropDownList(getUserDetails, clearStorage, props)
            }
          />
        </div>
        {deployModal && getDeployConfirmationModal()}
      </div>
    </div>
  );
};

const ProfileNavBar = (props) => {
  const { navBarButtons } = useContext(BotContext);

  return (
    <div className="row m-0 align-items-center w-100">
      <div
        className="col-auto cursor-pointer"
        onClick={() => props.history.push("/admin/home")}
      >
        <img alt="Gnani Logo" className="img-fluid" src={gnaniLogoV} />
      </div>
      <div className="col"></div>
      <div className="col-auto">{navBarButtons}</div>
    </div>
  );
};

const whichNavBar = (props) => {
  const isAdmin = window.location.pathname.includes("admin");
  const isProfileNav = window.location.pathname.includes(
    "admin/profile_settings"
  );
  if (isProfileNav) {
    return <AdminNavBar {...props} />;
  } else if (isAdmin) {
    return <AdminNavBar {...props} />;
  } else {
    return <BotsNavBar {...props} />;
  }
};

const Navbar = (props) => {
  return (
    <main
      className="container-fluid navbar-main-container"
      style={{
        boxShadow: "2px 3px 3px #00000028",
      }}
    >
      {whichNavBar(props)}
    </main>
  );
};

export default Navbar;
