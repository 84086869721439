import DropDown from "../../components/fields/dropDown/dropDown.component";
import Input from "../../components/fields/input/input.component";
import svgIcons from "../../services/icon.service";

import getIcon from "../../services/icon.service";

import ShowInput from "./showInput";
import ResHorizontalScroll from "../horizontalScroll/resHorizontalScroll";

export default function BotLayoutContent({
  addNewPlacholder,
  addNewValue,
  children,
  isFaq,
  isSelected,
  isSettingsPage,
  isStats,
  actions,
  onActionClick,
  onAddNewChange,
  responsePage = {},
  titleOnChange,
  titleValue,
  titleReadOnly = false,
  isFormsPage,
  faqDetails,
  horizontalMenu,
  handleNavMenuSelect,
  selectedChannel,
  deleteChannel,
  navBarMenu,
  isFaqWidth,
  validateCodeBtn,
  isShowContent,
  navDropDownDisable,
  disableAddNew,
  intentsWarning,
  actionLogBtn,
}) {
  const handleFaqWarning = () => {
    if (faqDetails?.questions?.length < 2) {
      return (
        <div className="col p-0 px-4">
          <small className="text-danger faq-warning-font">
            * Add atleast 2 questions and answers to train bot
          </small>
        </div>
      );
    } else if (faqDetails?.answers?.length < 2) {
      return (
        <div className="col p-0 px-4">
          <small className="text-danger faq-warning-font">
            * Add atleast 2 questions and answers to train bot
          </small>
        </div>
      );
    }
  };

  return isSettingsPage ? (
    children
  ) : (
    <div
      className={`col h-100 ${isFaq && "px-0"} ${
        horizontalMenu && isSelected ? "p-0 m-0" : "py-3"
      } ${
        responsePage.isAvail && isSelected
          ? "mt-0"
          : isFaqWidth
          ? "mt-0"
          : "mt-1"
      }`}
      style={{
        width: "calc(100% - 32rem)",
        border: "solid #ececec",
        borderWidth: "0 1px",
        background: `${
          (responsePage.isAvail && isSelected) || isFaq ? "#F2F2F2" : "#fafafa"
        }`,
      }}
    >
      {horizontalMenu && isSelected && isShowContent && (
        <div
          className="px-0 text-white mb-4 w-100"
          style={{ background: "#25293D" }}
        >
          <ResHorizontalScroll
            items={horizontalMenu}
            navBarMenu={navBarMenu}
            deleteChannel={deleteChannel}
            selectedChannel={selectedChannel}
            navDropDownDisable={navDropDownDisable}
            handleNavMenuSelect={handleNavMenuSelect}
          />
        </div>
      )}
      <div
        className={`${isFaqWidth ? "px-0" : !isStats ? "px-4" : "px-4"} h-100`}
      >
        {isSelected && isShowContent && (
          <div className={`${isFaq && "px-4"} row m-0 justify-content-between`}>
            <div className="col ps-0">
              {!isFormsPage && svgIcons("edit")}
              <Input
                readOnly={titleReadOnly}
                className={`${
                  isFormsPage ? "bot-content-title-forms" : "bot-content-title"
                }`}
                onChange={titleOnChange}
                onKeyPress={titleOnChange}
                onBlur={(e) => titleOnChange(e, "onBlur")}
                value={titleValue}
              />
            </div>
            {handleFaqWarning()}
            {intentsWarning && (
              <div className="col text-danger faq-warning-font">
                {intentsWarning}
              </div>
            )}
            <div className="col-auto pe-0 d-flex">
              {actionLogBtn && (
                <div className="d-flex mx-2 align-items-center">
                  {actionLogBtn()}
                </div>
              )}
              {validateCodeBtn && (
                <div className="d-flex mx-2 align-items-center">
                  {validateCodeBtn()}
                </div>
              )}
              <DropDown
                isContent
                actionButton={() => getIcon("verticalOptions")}
                dropdownList={actions}
                onActionItemClick={onActionClick}
              />
            </div>
          </div>
        )}

        <div className="row mt-4">
          {/* {isSelected && responsePage.isAvail && responsePage.resPgCont()} */}
          <ShowInput
            isFaq={isFaq}
            isSelected={isStats || isSelected}
            onChange={onAddNewChange}
            onKeypress={onAddNewChange}
            placeholder={addNewPlacholder}
            titleValue={titleValue}
            value={addNewValue}
            isShowContent={isShowContent}
            disableAddNew={disableAddNew}
          />
        </div>

        <div
          className={`w-100 ${(!isStats || !isFaqWidth) && "overflow-auto"}`}
          style={{
            height: `calc(100% - ${
              responsePage.isAvail ? 8.5 : isFaq ? 4.8 : 8
            }rem)`,
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
