// const baseUrl = "http://172.16.9.27:8009/";
const baseUrl = "https://botbuilder-prod.gnani.site";
// const baseUrl = "https://botframework-dev2.gnani.site";


export const getImageUrl = (url) => {
  return url && (url.includes(baseUrl) || url.includes('https') || url.includes('http')) ? url : baseUrl + url;
};

export const instanceUrl = "https://botbuilder-prod.gnani.site"
export default baseUrl;

// https://botframework-dev.gnani.site
// https://botframework-dev-v2.gnani.site
