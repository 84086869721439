import { Component, createRef } from "react";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { UncontrolledTooltip } from "reactstrap";
import ChartDataLabels from "chartjs-plugin-datalabels";

import CallStats from "./callStats";

import BotLayoutLeftPanel from "../../components/botLayout/botLayoutLeftPanel.component";
import BotLayoutContent from "../../components/botLayout/botLayoutContent.component";
import DropDown from "../../components/fields/dropDown/dropDown.component";

import getIcon from "../../services/icon.service";
import statService from "../../services/api.services/statisticsApi.service";
import { UserContext } from "../../contexts/userContext";

import "./statistics.scss";

ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      grid: {
        display: false,
        drawBorder: false,
        drawOnChartArea: false,
        drawTicks: false,
      },
      stacked: true,
    },
    y: {
      grid: {
        display: true,
        drawBorder: false,
        drawOnChartArea: true,
        drawTicks: true,
      },
      stacked: true,
    },
  },
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        usePointStyle: true,
      },
    },
  },
};
const DoughnutOptions = {
  ...options,
  scales: {
    yAxes: [
      {
        gridLines: {
          drawBorder: false,
        },
      },
    ],
  },
  plugins: {
    datalabels: {
      color: "#fff",
      // anchor: "end",
      // align: "end",
      // offset: 2,
      labels: {
        title: {
          font: {
            weight: "bold",
          },
        },
      },
    },
    legend: {
      display: false,
    },
  },
};

class Statistics extends Component {
  static contextType = UserContext;
  constructor(props) {
    super(props);
    this.state = {
      charts: [],
      chartsData: {
        conversationCompletionStats: {},
        botAutomationTrendStats: {},
        conversationTrendStats: {},
        conversationSplitStats: {},
        conversationLengthStats: {},
        conversationDurationStats: {},
        fallbackRateStats: {},
      },
      doughnutData: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: ["#3148bf", "#8752bf", "#c255a4", "#64c0ba"],
          },
        ],
      },
      sideBarList: [
        { label: "Overall Statistics", isSelected: true, value: 1 },
        { label: "Chat/Call Statistics", isSelected: false, value: 2 },
        // { label: "Call Statistics", isSelected: false, value: 3 },
      ],
      stats: [],
      selectedDates: {
        endDate: "",
        isSelected: false,
        startDate: "",
      },
    };
    this.multipleDropDownRef = createRef();
  }

  componentDidMount() {
    this.getStats();
  }

  componentDidUpdate(prevProps, prevState) {
    // if (prevState.sideBarList[0].isSelected) {
    //   this.getStats()
    // };

    if (
      prevState.selectedDates.endDate !== this.state.selectedDates.endDate ||
      prevState.selectedDates.startDate !== this.state.selectedDates.startDate
    ) {
      this.getStats();
    }
  }

  getStats = async () => {
    try {
      const [
        callChat,
        channelExchange,
        topIntents,
        topEntities,
        topActions,
        conversationCompletionRes,
        botAutomationTrendRes,
        conversationTrendRes,
        conversationSplitRes,
        conversationLengthRes,
        conversationDurationRes,
        fallbackRateRes,
      ] = await Promise.all([
        statService.getCallChatStats(this.state.selectedDates),
        statService.getChannelExchangeStats(this.state.selectedDates),
        statService.getTopIntents(this.state.selectedDates),
        statService.getTopEntities(this.state.selectedDates),
        statService.getTopActions(this.state.selectedDates),
        statService.getConversationCompletionRate(this.state.selectedDates),
        statService.getBotAutomationTrend(this.state.selectedDates),
        statService.getConversationTrend(this.state.selectedDates),
        statService.getConversationSplitStats(this.state.selectedDates),
        statService.getConversationLengthStats(this.state.selectedDates),
        statService.getConversationDurationStats(this.state.selectedDates),
        statService.getFallbackRateStats(this.state.selectedDates),
      ]);

      const { callData, chatData } = this.getCallChatData(callChat);
      const { data: channelData } = this.getData(channelExchange, {
        label: "Channel(s)",
        value: "Messages",
      });
      const {
        data: topIntentsData,
        isChartData,
        chartData,
      } = this.getData(topIntents, {
        label: "Intents",
        value: "Percentage",
      });
      const stats = [
        {
          header: "Calls",
          data: callData,
          toolTip:
            "Contains list of total number of calls that have taken place in a bot, gives info on connected calls, not connected calls, call duration",
        },
        {
          header: "Chats",
          data: chatData,
          toolTip:
            "Gives number of total chats handled by bot and how many messages were sent and received",
        },
        {
          header: "Channel Exchanges",
          data: channelData,
          toolTip:
            "Gives total number and percentage of messages exchanged on different channels",
        },
        {
          header: "Top Intents",
          data: topIntentsData,
          isChart: isChartData,
          chartTitle: "Doughnut Chart",
          chart: (
            <Doughnut
              data={chartData}
              options={DoughnutOptions}
              plugins={[ChartDataLabels]}
            />
          ),
          toolTip:
            "Gives number of messages which were addressed for a specific intents and what percentage does each of them have",
        },
      ];

      if (topEntities.data.length) {
        const {
          data: topEntitiesData,
          isChartData,
          chartData,
        } = this.getData(topEntities, {
          label: "Entities",
          value: "Percentage",
        });

        stats.push({
          header: "Top Entities",
          data: topEntitiesData,
          isChart: isChartData,
          chartTitle: "Doughnut Chart",
          chart: (
            <Doughnut
              data={chartData}
              options={DoughnutOptions}
              plugins={[ChartDataLabels]}
            />
          ),
          toolTip:
            "Gives number of messages which were addressed for a specific entities and what percentage does each of them have",
        });
      }

      if (topActions.data.length) {
        const {
          data: topActionsData,
          isChartData,
          chartData,
        } = this.getData(topActions, {
          label: "Actions",
          value: "Percentage",
        });

        stats.push({
          header: "Top Actions",
          data: topActionsData,
          isChart: isChartData,
          chartTitle: "Doughnut Chart",
          chart: (
            <Doughnut
              data={chartData}
              options={DoughnutOptions}
              plugins={[ChartDataLabels]}
            />
          ),
          toolTip:
            "Gives the number of times specific actions were used for a bot and the percentage each of them carry",
        });
      }

      const charts = this.handleCharts({
        conversationCompletionRes,
        botAutomationTrendRes,
        conversationTrendRes,
        conversationSplitRes,
        conversationLengthRes,
        conversationDurationRes,
        fallbackRateRes,
      });

      this.setState({ charts, stats });
    } catch (e) {
      console.log(e);
    }
  };

  getData = (r, { label, value }) => {
    if (r.status === "success") {
      const isChart = value.includes("Percentage");
      const labels = [];
      const chartValues = [];

      const data = r.data.map((c) => {
        const key = Object.keys(c)[0];
        const count = c[key].count;
        const per = c[key].per;

        if (isChart) {
          labels.push(key);
          chartValues.push(count);
        }

        return {
          label: key.split("_").join(" "),
          key,
          isChannel: true,
          value: count,
          subValue: per,
        };
      });

      data.unshift({ label, value, isHeader: true });

      const isChartData = chartValues.some((c) => c !== 0);

      if (isChartData) {
        const chartData = {
          labels,
          datasets: [
            {
              label: "Total Queries",
              data: chartValues,
              backgroundColor: this.handleChartColors(label, "bg"),
              borderColor: this.handleChartColors(label, "br"),
              borderWidth: 1,
            },
          ],
        };
        return { data, isChartData, chartData };
      }
      return { data };
    }
  };

  getCallChatData = (r) => {
    if (r.status === "success") {
      const callData = [];
      const chatData = [];

      for (const key in r.data) {
        const obj = {
          label: key.split("_").join(" "),
          value: r.data[key],
          key,
        };

        if (key.includes("messages")) chatData.push(obj);
        else callData.push(obj);
      }

      return { callData, chatData };
    }
  };

  handleChartColors = (chartName, actionType) => {
    const color = (bg, br) => (actionType === "bg" ? bg : br);

    switch (chartName) {
      case "botAutomationTrendStats":
        return color("#ffe20e", "#ffe20e");
      case "conversationTrendStats":
        return color("#e693ef", "#e693ef");
      case "conversationLengthStats":
        return color("#c5ef94", "#c5ef94");
      case "conversationDurationStats":
        return color(
          ["#667d13", "#a2c32c", "#94ce21", "#77cb1e"],
          ["#667d13", "#a2c32c", "#94ce21", "#77cb1e"]
        );
      case "Intents":
        return color(
          ["#3148bf", "#8752bf", "#c255a4", "#64c0ba"],
          ["#3148bf", "#8752bf", "#c255a4", "#64c0ba"]
        );
      case "Entities":
        return color(
          ["#3148bf", "#8752bf", "#c255a4", "#64c0ba"],
          ["#3148bf", "#8752bf", "#c255a4", "#64c0ba"]
        );
      case "Actions":
        return color(
          ["#3148bf", "#8752bf", "#c255a4", "#64c0ba"],
          ["#3148bf", "#8752bf", "#c255a4", "#64c0ba"]
        );
      default:
        return color("#ffe20e", "#ffe20e");
    }
  };

  getChartsData = (r, chartName, chartType) => {
    if (r.status === "success") {
      const chartsData = { ...this.state.chartsData };
      const chartData = [];
      let chartDatasets = [];

      const labels = r.data.map((f, i) => {
        const key = Object.keys(f)[0];

        if (chartType === "bot") {
          chartData.push(f[key]["bot"]);
        } else if (chartType === "con") {
          if (i === 0) {
            chartDatasets[0] = {
              label: "In Complete Converstions",
              data: [],
              backgroundColor: "#e98485",
              borderColor: "#e98485",
              barPercentage: 0.3,
            };
            chartDatasets[1] = {
              label: "Complete Converstions",
              data: [],
              backgroundColor: "#efb794",
              borderColor: "#efb794",
              barPercentage: 0.3,
            };
          }
          chartDatasets[0].data.push(f[key]["completed"]);
          chartDatasets[1].data.push(f[key]["incomplete"]);
        } else chartData.push(f[key]);

        return key;
      });

      chartsData[chartName] = {
        labels,
        datasets:
          chartData.length === 0
            ? chartDatasets
            : [
                {
                  label: "Total Queries",
                  data: chartData,
                  backgroundColor: this.handleChartColors(chartName, "bg"),
                  borderColor: this.handleChartColors(chartName, "br"),
                  borderWidth: 1,
                },
              ],
      };

      return chartsData;
    }
  };

  handleCharts = ({
    conversationCompletionRes,
    botAutomationTrendRes,
    conversationTrendRes,
    conversationSplitRes,
    conversationLengthRes,
    conversationDurationRes,
    fallbackRateRes,
  }) => {
    const charts = [];

    const [
      { conversationCompletionStats },
      { botAutomationTrendStats },
      { conversationTrendStats },
      { conversationLengthStats },
      { conversationDurationStats },
      { fallbackRateStats },
    ] = [
      this.getChartsData(
        conversationCompletionRes,
        "conversationCompletionStats",
        "con"
      ),
      this.getChartsData(
        botAutomationTrendRes,
        "botAutomationTrendStats",
        "bot"
      ),
      this.getChartsData(conversationTrendRes, "conversationTrendStats"),
      this.getChartsData(conversationLengthRes, "conversationLengthStats"),
      this.getChartsData(conversationDurationRes, "conversationDurationStats"),
      this.getChartsData(fallbackRateRes, "fallbackRateStats"),
    ];

    charts.push(
      {
        chart: this.handleNoDataDisplay(
          conversationCompletionStats,
          <Bar options={options} data={conversationCompletionStats} />,
          "conversation"
        ),
        label:
          conversationCompletionRes.total["completed"] !== 0
            ? "Conversation Completion Rate"
            : "Conversation In Completion Rate",
        value:
          conversationCompletionRes.total["completed-per"] === 0
            ? conversationCompletionRes.total["completed_per"]
            : conversationCompletionRes.total["incomplete-per"],

        toolTip:
          "shows a bar graph of what was the conversation completion rate, how many conversation were completed and not completed and total conversations that took place",
      },
      {
        chart: this.handleNoDataDisplay(
          botAutomationTrendStats,
          <Line options={options} data={botAutomationTrendStats} />
        ),
        label: "Bot Automation",
        value: botAutomationTrendRes.total["bot-per"],
        toolTip:
          "Gives a bar graph view of what is the bot automation rate percentage, so basically how percentage of conversations are being responsed to by bot rather than by agents",
      },
      {
        chart: this.handleNoDataDisplay(
          conversationTrendStats,
          <Line options={options} data={conversationTrendStats} />
        ),
        label: "Conversations",
        value: conversationTrendRes.total,
        toolTip:
          "Shows a line graph view of how many conversations in total happened in the bot on differnt days, months, year according to the filter",
      },
      {
        chart: this.renderConversationSplit(conversationSplitRes),
        label: "Conversation Split",
        value: "",
        toolTip:
          "Shows a horizontal bar graph view of the total conversation split in terms of how many conversation were automated, broken, transferred to agent",
      },
      {
        chart: this.handleNoDataDisplay(
          conversationLengthStats,
          <Bar options={options} data={conversationLengthStats} />
        ),
        label: "Conversation Length",
        value: "",
        toolTip:
          "Shows a bar graph view of what was the conversation length in terms of utterances that were shared with a bot in a day,week, month etc according to filter",
      },
      {
        chart: this.handleNoDataDisplay(
          conversationDurationStats,
          <Doughnut
            options={DoughnutOptions}
            data={conversationDurationStats}
            plugins={[ChartDataLabels]}
          />
        ),
        label: "Conversation Duration",
        value: "",
        toolTip:
          "Shows a pie chart view of total number of voice call conversation duration that have happened. Example conversation which happened for 0-30 seconds, 30-70 etc",
      },
      {
        chart: this.handleNoDataDisplay(
          fallbackRateStats,
          <Bar options={options} data={fallbackRateStats} />
        ),
        label: "Fall Back Rate",
        value: "",
        toolTip:
          "Shows a line graph view to show how many user intents/messages were not understood by the bot and were handled by the NLU fallback",
      }
    );
    return charts;
  };

  handleSelect = ({ value }) => {
    const sideBarList = this.state.sideBarList.map((s) => {
      s.value === value ? (s.isSelected = true) : (s.isSelected = false);
      return s;
    });

    this.setState({ sideBarList });
  };

  handleNoDataDisplay = (chartData, chart, chartName) => {
    let isNoDataDisplay;

    if (chartName === "conversation") {
      if (chartData.datasets.length) {
        let dataset = chartData.datasets[0].data.every((d) => {
          return d === 0;
        });
        let dataset_2 = chartData.datasets[1].data.every((d) => {
          return d === 0;
        });
        isNoDataDisplay = [dataset, dataset_2].every((d) => {
          return d === true;
        });
      } else {
        isNoDataDisplay = true;
      }
    } else {
      isNoDataDisplay = chartData.datasets.length
        ? chartData.datasets[0].data.every((d) => {
            return d === 0;
          })
        : true;
    }
    return isNoDataDisplay ? this.renderNoDataDisplay() : chart;
  };

  handleDropDownSelection = ({ value }, startDateObj, endDateObj, event) => {
    const selectedDates = { ...this.state.selectedDates };
    const date = new Date();
    let current_date = `${date.getFullYear()}/${
      date.getMonth() + 1
    }/${date.getDate()}`;
    const todaysDate = current_date.toLocaleString().split(",")[0];
    const previousDate = (reduceDays) => {
      let prev_date_format = new Date(
        date.setDate(date.getDate() - reduceDays)
      );
      let prev_date = `${prev_date_format.getFullYear()}/${
        prev_date_format.getMonth() + 1
      }/${prev_date_format.getDate()}`;
      return prev_date.toLocaleString().split(",")[0];
    };

    selectedDates.isSelected = true;

    switch (value) {
      case "_today":
        selectedDates.startDate = todaysDate;
        selectedDates.endDate = todaysDate;

        this.multipleDropDownRef.current.toggle();

        return this.setState({ selectedDates });
      case "_yesterday":
        selectedDates.startDate = previousDate(1);
        selectedDates.endDate = todaysDate;

        this.multipleDropDownRef.current.toggle();

        return this.setState({ selectedDates });
      case "_lastWeek":
        selectedDates.startDate = previousDate(7);
        selectedDates.endDate = todaysDate;

        this.multipleDropDownRef.current.toggle();

        return this.setState({ selectedDates });
      default:
        if (event) {
          event.preventDefault();
        }
        if (startDateObj) {
          let start = `${startDateObj._d.getFullYear()}/${
            startDateObj._d.getMonth() + 1
          }/${startDateObj._d.getDate()}`;
          let end = `${endDateObj._d.getFullYear()}/${
            endDateObj._d.getMonth() + 1
          }/${endDateObj._d.getDate()}`;
          const [_startDate, _endDate] = [start, end].map(
            (d) => d.toLocaleString().split(",")[0]
          );
          selectedDates.startDate = _startDate;
          selectedDates.endDate = _endDate;

          return this.setState({ selectedDates }, () =>
            this.multipleDropDownRef.current.toggle()
          );
        }
    }
  };

  renderNoDataDisplay = () => {
    return (
      <div
        className="d-flex align-items-center justify-content-center fw-bolder"
        style={{ height: "23rem" }}
      >
        No data to display
      </div>
    );
  };

  renderConversationSplit = (r) => {
    if (r.status === "success")
      return (
        <div className="row align-items-start">
          <div className="col-auto">
            <div className="row my-4">
              <div className="col">
                <p className="stats-con-split-chart-value">{r.data.bot}</p>
                <span className="stats-con-split-chart-title">
                  Conversations
                </span>
              </div>
            </div>
            <div className="row my-4">
              <div className="col">
                <p className="stats-con-split-chart-value">{r.data.broken}</p>
                <span className="stats-con-split-chart-title">
                  Conversations
                </span>
              </div>
            </div>
            <div className="row my-4">
              <div className="col">
                <p className="stats-con-split-chart-value">{r.data.agent}</p>
                <span className="stats-con-split-chart-title">
                  Conversations
                </span>
              </div>
            </div>

            <div className="row my-4">
              <div className="col">
                <p className="stats-con-split-chart-value">{r.data.total}</p>
                <span className="stats-con-split-chart-title">
                  Conversations
                </span>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="row my-4">
              <div className="col">
                <p className="stats-con-split-chart-bar-title">
                  Bot Automation
                </p>
                {/* <span className="stats-con-split-chart-title">Sample text</span> */}
                <div className="row align-items-center">
                  <div className="col pe-0">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: r.data["bot-per"] }}
                        aria-valuenow={r.data.bot}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="col-auto stats-con-split-chart-per-value">
                    <span>{r.data["bot-per"]}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-4">
              <div className="col">
                <p className="stats-con-split-chart-bar-title">Broken</p>
                {/* <span className="stats-con-split-chart-title">Sample text</span> */}
                <div className="row align-items-center">
                  <div className="col pe-0">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: r.data["broken-per"] }}
                        aria-valuenow={r.data.broken}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="col-auto stats-con-split-chart-per-value">
                    <span>{r.data["broken-per"]}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-4">
              <div className="col">
                <p className="stats-con-split-chart-bar-title">
                  Agent Automation
                </p>
                {/* <span className="stats-con-split-chart-title">Sample text</span> */}
                <div className="row align-items-center">
                  <div className="col pe-0">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: r.data["agent-per"] }}
                        aria-valuenow={r.data.agent}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="col-auto stats-con-split-chart-per-value">
                    <span>{r.data["agent-per"]}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row my-4">
              <div className="col">
                <p className="stats-con-split-chart-bar-title">
                  Total Automation
                </p>
                {/* <span className="stats-con-split-chart-title">Sample text</span> */}
                <div className="row align-items-center">
                  <div className="col pe-0">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: r.data["total-per"] }}
                        aria-valuenow={r.data.total}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="col-auto stats-con-split-chart-per-value">
                    <span>{r.data["total-per"]}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  };

  renderStatsInfo = (stats) => {
    return stats.map((s, i) => {
      return (
        <div className="row my-3" key={i}>
          <div className="col">
            <span
              className={`text-capitalize ${
                s.isHeader
                  ? "overAll-stat-left-isHeader"
                  : "overAll-stat-left-nonHeader"
              }`}
            >
              {s.label}
            </span>
          </div>
          <div className="col-auto">
            <span
              className={`${
                s.isChannel
                  ? "overAll-stat-left-val"
                  : "overAll-stat-left-isHeader"
              }`}
            >
              {s.value}
            </span>
            {s.isChannel && (
              <span className="stats-info-sub-value">{s.subValue}</span>
            )}
          </div>
          {s.isHeader && <hr className="m-0" />}
        </div>
      );
    });
  };

  renderStatsInfoContainer = () => {
    return this.state.stats.map((c, i) => (
      <div className="row mb-4" key={i}>
        <div className="col">
          <div className="d-flex">
            <span className="stats-info-container-title">{c.header}</span>
            <span className="cursor-pointer ms-2" id={`chart-data-toolTip${i}`}>
              {getIcon("knowMore")}
            </span>
            <UncontrolledTooltip
              placement="top"
              target={`chart-data-toolTip${i}`}
            >
              {c.toolTip}
            </UncontrolledTooltip>
          </div>
          <div
            className={`stats-info-container`}
            style={{ paddingBottom: c.isChart && "1.25rem" }}
          >
            {this.renderStatsInfo(c.data)}
            {c.isChart && (
              <div>
                <span className="overAll-stat-left-chart-name">
                  {c.chartTitle}
                </span>
                <div className="mt-3" style={{ height: "10rem" }}>
                  {c.chart}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    ));
  };

  renderCharts = () => {
    return this.state.charts.map((c, i) => (
      <div className="mb-5" key={i}>
        <div className="row">
          <div className="col d-flex">
            <span className="stats-info-container-title">{c.label}</span>
            <span
              className="cursor-pointer ms-2"
              id={`chart-graph-toolTip${i}`}
            >
              {getIcon("knowMore")}
            </span>
            <UncontrolledTooltip
              placement="top"
              target={`chart-graph-toolTip${i}`}
            >
              {c.toolTip}
            </UncontrolledTooltip>
          </div>
          <div className="col-auto">
            <span className="stats-info-container-title">{c.value}</span>
          </div>
        </div>
        <div
          className={`bg-white px-3 py-2 rounded mt-2 ${
            c.label === "Conversation Duration" && "py-4"
          }`}
          style={{ minHeight: "23rem" }}
        >
          {c.chart}
        </div>
      </div>
    ));
  };

  renderDropDownActionBtn = () => {
    let { endDate, isSelected, startDate } = this.state.selectedDates;
    startDate = startDate.split("/").reverse().join("/");
    endDate = endDate.split("/").reverse().join("/");

    return isSelected ? (
      <div className="row stats-drop-down">
        <div className="col">
          <div className="row">
            <div className="col border-end">
              <span className="chat-stat-date">{startDate}</span>
              <span className="ms-2">
                {getIcon("chevronDown", "#FFFFFF", "", 10)}
              </span>
            </div>
            <div className="col">
              <span className="chat-stat-date">{endDate}</span>
              <span className="ms-2">
                {getIcon("chevronDown", "#FFFFFF", "", 10)}
              </span>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="row stats-drop-down">
        <div className="col">
          <span className="chat-stat-date">Date Filter</span>
        </div>
        <div className="col-auto">
          {getIcon("chevronDown", "#FFFFFF", "", 10)}
        </div>
      </div>
    );
  };

  renderTabOne = () => {
    return (
      <div className="h-100 pt-3" style={{ paddingBottom: "3.3rem" }}>
        <div className="row pe-5">
          <div className="col">
            <span className="stats-pg-title">Overall Statistics</span>
            <span className="stats-pg-sub-title">
              Check you bot performance here...
            </span>
          </div>
          <div className="col-auto date-picker">
            <p className="stat-pg-select-date">Select date range</p>
            <DropDown
              itemClassName="stats-multi-level-drop-down-item"
              actionButton={this.renderDropDownActionBtn}
              isMultiLevelDropDown
              subMenuPosition="left"
              dropdownList={[
                { label: "Today", value: "_today" },
                { label: "Yesterday", value: "_yesterday" },
                { label: "Last Week", value: "_lastWeek" },
                {
                  label: (
                    <DateRangePicker
                      initialSettings={{
                        alwaysShowCalendars: true,
                        parentEl: ".Dropdown_menu-right__1zrzt",
                      }}
                      // onCancel={() => this.setState({ isDropDown: false })}
                      onCallback={(s, e) => {
                        this.handleDropDownSelection("_customDate", s, e);
                      }}
                    >
                      <button className="btn p-0 stats-multi-level-drop-down-item">
                        Custom Date
                      </button>
                    </DateRangePicker>
                  ),
                  value: "_customDate",
                },
              ]}
              onActionItemClick={(i, e) =>
                this.handleDropDownSelection(i, "", "", e)
              }
              ref={this.multipleDropDownRef}
            />
          </div>
        </div>

        <div
          className="row h-100 mt-4 px-4 pt-3"
          style={{ background: "#f0f0f0" }}
        >
          <div className="col-auto overflow-auto h-100 pb-4">
            {this.renderStatsInfoContainer()}
          </div>

          {/* Charts container */}
          <div className="col overflow-auto h-100 ps-5">
            {this.renderCharts()}
          </div>
        </div>
      </div>
    );
  };

  renderTabTwo = () => {
    return (
      <CallStats
        permissions={
          this.context.getUserDetails().feature_permission.statistics
        }
      />
    );
  };

  renderTabThree = () => {
    return (
      <div className="d-flex justify-content-center align-items-center h-100">
        <h5 className="text-muted">Coming Soon...</h5>
      </div>
    );
  };

  renderTabs = () => {
    const selectedTab = this.state.sideBarList.filter((s) => s.isSelected)[0]
      .value;

    switch (selectedTab) {
      case 1:
        if (
          this.context.getUserDetails().feature_permission.statistics
            .overall === "enabled"
        )
          return this.renderTabOne();
      case 2:
        if (
          this.context.getUserDetails().feature_permission.statistics
            .conversation_logs === "enabled"
        )
          return this.renderTabTwo();
      default:
        return this.renderTabThree();
    }
  };

  render() {
    const { sideBarList } = this.state;

    return (
      <div className="m-0 row h-100">
        <BotLayoutLeftPanel
          handleSelect={this.handleSelect}
          isSettingsPage
          sideBarList={sideBarList}
        />

        <BotLayoutContent isFaq isStats isSettingsPage>
          <div
            className={`col mt-1 h-100 ${sideBarList[1].isSelected && "p-0"}`}
            style={{ background: "#f5f5f5" }}
          >
            {this.renderTabs()}
          </div>
        </BotLayoutContent>
      </div>
    );
  }
}

export default Statistics;
