import baseUrl from "./baseURL.service";
import {
  getToken,
  getRefreshToken,
  setToken,
  setUserDetails,
  getUserDetails,
} from "./authUtil";
import { Tooltip } from "reactstrap";
import { Subject } from "rxjs";
import jwt_decode from "jwt-decode";

var authTokenStatus = new Subject();
var authRefreshInProgress = false;

const messageService = {
  sendMessage: (message) => authTokenStatus.next(message),
  clearMessages: () => authTokenStatus.next(),
  getMessage: () => authTokenStatus.asObservable(),
};

const botService = {
  getSelectedBotSavedDate,
  getSelectedBotId,
  setSelectedBotId,
  clearBotId,
  getBotIntents,
  getBotUtterences,
  clearStorage,
  getBotResponses,
  getBotEntity,
  getBotActions,
  getBotFaqs,
  getBotForms,
  // getTrainingStatus,
  callTraining,
  getBotConfig,
  getSharedBots,
  setSelectedBotName,
  getSelectedBotName,
  getTrashIcon,
  getHRWithText,
  getTrainButton,
  getBotList,
  getTemplateList,
  getBotSlots,
  getSelectedBotLang,
  setSelectedBotLang,
  setSelectedBot,
  getSelectedBot,
  clearSelectedBot,
  fetchData,
};

/** Old Fetch Data Logic. */
// function fetchData(url, options, callBack = (res) => res.json()) {
//   return fetch(url, { ...options, mode: "cors" })
//     .then(callBack)
//     .catch((error) => {
//       if (error.code === 403) {
//         fetch(`${baseUrl}/refresh_token`, {
//           method: "POST",
//           body: JSON.stringify({
//             refresh_token: getRefreshToken(),
//           }),
//         })
//           .then((r) => r.json())
//           .then((r) => {
//             setToken(r.token);
//             const userDetails = getUserDetails();
//             setUserDetails({
//               ...userDetails,
//               refresh_token: r.refresh_token,
//               token: r.token,
//             });
//           })
//           .catch((error) => {
//             const { clearStorage } = useContext(UserContext);
//             clearStorage();
//             window.location("/");
//           });
//       }
//     });
// }

function reloadToken() {
  // console.log('Reload called.')
  let tokenTimeBuffer;
  try {
    tokenTimeBuffer = getToken()
      ? new Date() - new Date(jwt_decode(getToken()).exp * 1000)
      : null;
    tokenTimeBuffer = tokenTimeBuffer / 60000;
  } catch (error) {
    tokenTimeBuffer = null;
  }
  // console.log('tokenTimeBuffer>> ', new Date())
  // console.log('tokenTimeBuffer>> ', new Date (jwt_decode(getToken()).exp * 1000))
  // console.log('tokenTimeBuffer>> ', jwt_decode(getToken()))
  // console.log('tokenTimeBuffer>> ', tokenTimeBuffer)
  if (tokenTimeBuffer && parseInt(tokenTimeBuffer) < -1) {
    // console.log('Skip Refresh>> ')
    messageService.sendMessage(true);
  } else {
    // console.log('Make call>>')
    authRefreshInProgress = true;
    fetch(`${baseUrl}/refresh_token`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        refresh_token: getRefreshToken(),
      }),
    })
      .then((r) => r.json())
      .then((r) => {
        authRefreshInProgress = false;
        if (r.status === "refresh_expired") {
          authRefreshInProgress = false;
          sessionStorage.clear();
          messageService.sendMessage(false);
          setTimeout(() => {
            window.location = "/";
          }, 3000);
        } else {
          setToken(r.token);
          const userDetails = getUserDetails();
          setUserDetails({
            ...userDetails,
            refresh_token: r.refresh_token,
            token: r.token,
          });
          setToken(r.token);
          setTimeout(() => {
            messageService.sendMessage(r.token);
          }, 300);
        }
      })
      .catch((error) => {
        authRefreshInProgress = false;
        sessionStorage.clear();
        clearStorage();
        messageService.sendMessage(false);
        window.location = "/";
      });
  }
}

function fetchData(url, options, callBack = (res) => res.json()) {
  return new Promise((resolve, reject) => {
    if (
      [
        `${baseUrl}/get_profile`,
        `${baseUrl}/login`,
        `${baseUrl}/reset_password`,
        `${baseUrl}/check_ms_user`,
        `${baseUrl}/super_admin_login`,
        `${baseUrl}/forgot_password`
      ].includes(url)
    ) {
      fetch(url, { ...options, mode: "cors" })
        .then(callBack)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      let _subscription = messageService.getMessage().subscribe((status) => {
        if (status) {
          let token = getToken();
          options.headers["token"] = token;
          // options.headers['mode'] = "cors";
          fetch(url, options)
            .then(callBack)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
          _subscription.unsubscribe();
        } else {
          // _subscription.unsubscribe();
        }
      });
      if (!authRefreshInProgress) {
        reloadToken();
      }
    }
  });
}

function timeout(ms, promise) {
  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      reject(new Error("TIMEOUT"));
    }, ms);

    promise
      .then((value) => {
        clearTimeout(timer);
        resolve(value);
      })
      .catch((reason) => {
        clearTimeout(timer);
        reject(reason);
      });
  });
}

function callTraining() {
  let bot_id = getSelectedBotId();
  let reqOptions = {
    method: "GET",
    headers: {
      token: getToken(),
    },
    mode: "cors",
  };
  return timeout(
    300000,
    fetch(`${baseUrl}/train_bot/v3/${bot_id}`, reqOptions)
  );
}

function setSelectedBot(data) {
  try {
    if (data) {
      sessionStorage.setItem(btoa("selectedBot"), JSON.stringify(data));
    }
  } catch (error) {
    console.log(error);
  }
}

function getSelectedBot() {
  let bot = sessionStorage.getItem(btoa("selectedBot"));
  if (bot) {
    return JSON.parse(bot);
  } else {
    return bot;
  }
}
function clearSelectedBot() {
  sessionStorage.removeItem(btoa("selectedBot"));
}

function getSelectedBotId(mode = "") {
  let bot = getSelectedBot();
  if (bot) {
    return bot.bot_id + mode;
  } else {
    return null;
  }
}

function getDateTimeFormatedString(dateStr) {
  let date;
  try {
    date = new Date(dateStr).toGMTString();
    date = date === "Invalid Date" ? dateStr : date;
  } catch (error) {
    date = dateStr;
  }
  return date;
}

function getSelectedBotSavedDate() {
  let bot = getSelectedBot();
  if (bot) {
    return getDateTimeFormatedString(bot.last_modified_date);
  } else {
    return null;
  }
}

function setSelectedBotId(id) {
  sessionStorage.setItem(btoa("selectedBotID"), id);
}

function getSelectedBotLang() {
  let bot = getSelectedBot();
  if (bot) {
    return bot.language;
  } else {
    return null;
  }
}

function setSelectedBotLang(lang) {
  sessionStorage.setItem(btoa("selectedBotLang"), lang);
}

function getSelectedBotName() {
  return sessionStorage.getItem(btoa("selectedBotName"));
}

function setSelectedBotName(name) {
  sessionStorage.setItem(btoa("selectedBotName"), name);
}

function clearBotId() {
  sessionStorage.removeItem(btoa("selectedBotID"));
}

function clearStorage() {
  sessionStorage.clear();
  localStorage.clear();
}

function getBotIntents() {
  let bot_id = getSelectedBotId();
  let reqOptions = {
    method: "GET",
    headers: {
      token: getToken(),
    },
    mode: "cors",
  };
  return fetch(`${baseUrl}/get_bot_intents/${bot_id}`, reqOptions);
}

function getBotResponses() {
  let bot_id = getSelectedBotId();
  let reqOptions = {
    method: "GET",
    headers: {
      token: getToken(),
    },
    mode: "cors",
  };
  return fetch(`${baseUrl}/get_bot_utterances_list/${bot_id}`, reqOptions);
}

function getBotEntity() {
  let bot_id = getSelectedBotId();
  let reqOptions = {
    method: "GET",
    headers: {
      token: getToken(),
    },
    mode: "cors",
  };
  return fetch(`${baseUrl}/get_bot_entities/${bot_id}`, reqOptions);
}

function getBotActions() {
  let bot_id = getSelectedBotId();
  let reqOptions = {
    method: "GET",
    headers: {
      token: getToken(),
    },
    mode: "cors",
  };
  return fetch(`${baseUrl}/get_bot_actions_list/${bot_id}`, reqOptions);
}

function getBotSlots() {
  let bot_id = getSelectedBotId();
  let reqOptions = {
    method: "GET",
    headers: {
      token: getToken(),
    },
    mode: "cors",
  };
  return fetch(`${baseUrl}/get_bot_slots/${bot_id}`, reqOptions);
}

function getBotFaqs() {
  let bot_id = getSelectedBotId();
  let reqOptions = {
    method: "GET",
    headers: {
      token: getToken(),
    },
    mode: "cors",
  };
  return fetch(`${baseUrl}/get_bot_faqs_list/${bot_id}`, reqOptions);
}

function getBotForms() {
  let bot_id = getSelectedBotId();
  let reqOptions = {
    method: "GET",
    headers: {
      token: getToken(),
    },
    mode: "cors",
  };
  return fetch(`${baseUrl}/get_bot_forms/${bot_id}`, reqOptions);
}

function getBotUtterences() {
  let bot_id = getSelectedBotId();
  let reqOptions = {
    method: "GET",
    headers: {
      token: getToken(),
    },
    mode: "cors",
  };
  return fetch(`${baseUrl}/get_bot_utterances_list/${bot_id}`, reqOptions);
}

function getBotConfig(botID) {
  let bot_id = botID || getSelectedBotId();
  let reqOptions = {
    method: "GET",
    headers: {
      token: getToken(),
    },
    mode: "cors",
  };
  return fetch(`${baseUrl}/read_bot_config/${bot_id}`, reqOptions);
}

// function getBotList() {
//   let reqOptions = {
//     method: "GET",
//     headers: { token: getToken() },
//   };
//   return fetch(`${baseUrl}/list_bots`, reqOptions).then((res) => res.json());
// }

function getBotList(isForceFetch) {
  if (!isForceFetch) {
    return storageGetItem("botsList");
  } else {
    return new Promise((resolve, reject) => {
      let reqOptions = {
        method: "GET",
        headers: { token: getToken() },
      };
      fetchData(`${baseUrl}/list_bots?size=${200}&page=${0}`, reqOptions)
        .then((response) => {
          if (response.status === "success") {
            storageSetItem("botsList", response);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

function getSharedBots(size = 200, page = 0, shared = "shared") {
  const reqOptions = {
    method: "GET",
    headers: { token: getToken() },
  };

  return fetchData(
    `${baseUrl}/list_bots?size=${size}&page=${page}&own_or_shared=${shared}`,
    reqOptions
  );
}

function storageSetItem(key, data) {
  try {
    sessionStorage.setItem(btoa(key), btoa(JSON.stringify(data)));
  } catch (error) {
    console.error("storageSetItem: ", error);
  }
}

function storageGetItem(key) {
  try {
    let value = sessionStorage.getItem(btoa(key));
    return value ? JSON.parse(atob(value)) : value;
  } catch (error) {
    console.error("storageGetItem: ", error);
  }
}

function getTemplateList() {
  let reqOptions = {
    method: "GET",
    headers: { token: getToken() },
    mode: "cors",
  };
  return fetch(`${baseUrl}/get_templates`, reqOptions).then((res) =>
    res.json()
  );
}

function getTrashIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      className="bi bi-trash-fill"
      viewBox="0 0 16 16"
    >
      <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z" />
    </svg>
  );
}

function getHRWithText(text) {
  return (
    <h4
      className="w-100 text-center my-5"
      style={{
        borderBottom: "2px solid slategrey",
        lineHeight: "0.1em",
      }}
    >
      <span
        className="bg-second"
        style={{
          padding: "0 10px",
        }}
      >
        {text}
      </span>
    </h4>
  );
}

function getTrainButton(trainingStatus, tooltipOpen, updateState) {
  trainingStatus = trainingStatus || "_init";
  return (
    <>
      <button
        id="TooltipExample"
        disabled={trainingStatus === "_loading"}
        className="btn btn-blue"
        onClick={() => {
          updateState({ trainingStatus: "_loading" });
          callTraining()
            .then((response) => response.json())
            .then((response) => {
              if (response.status === "success")
                updateState({ trainingStatus: "_init" });
              else updateState({ trainingStatus: "_error" });
            })
            .catch((error) => {
              updateState({ trainingStatus: "_fail" });
              console.error("Failed: ", error);
            });
        }}
      >
        Train
        {trainingStatus === "_loading" && (
          <div
            style={{ width: "1rem", height: "1rem" }}
            className="ml-2 spinner-border text-white"
            role="status"
          />
        )}
        {trainingStatus === "_init" && (
          <svg
            className="ml-2 text-success bg-light"
            style={{ border: "1px solid #fff", borderRadius: ".5rem" }}
            width="20"
            height="20"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
          </svg>
        )}
        {trainingStatus === "_fail" && (
          <svg
            className="ml-2 text-danger"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
          </svg>
        )}
        {trainingStatus === "_error" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="ml-2 text-danger"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
          </svg>
        )}
      </button>
      <Tooltip
        placement="right"
        isOpen={tooltipOpen}
        target="TooltipExample"
        toggle={() => {
          updateState({ tooltipOpen: !tooltipOpen });
        }}
      >
        {trainingStatus === "_loading" && "In Progress."}
        {trainingStatus === "_init" && "Ready to Train."}
        {trainingStatus === "_fail" && "Train Failed."}
        {trainingStatus === "_error" && "Error in Training."}
      </Tooltip>
    </>
  );
}

export default botService;
