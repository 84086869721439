import React, { Component, useContext } from "react";
import BotLayoutLeftPanel from "../../components/botLayout/botLayoutLeftPanel.component";
import BotLayoutRightPanel from "../../components/botLayout/botLayoutRightPanel.component";
import BotLayoutContent from "../../components/botLayout/botLayoutContent.component";
import botService from "../../services/bot.service";
import formsServices from "../../services/api.services/formsApi.service";
import slotService from "../../services/api.services/slotsApi.service";
import utternaceServices from "../../services/api.services/utteranceApi.service";
import intentServices from "../../services/api.services/intentsApi.service";
import entityServices from "../../services/api.services/entityApi.service";

import svgIcons from "../../services/icon.service";
import Input from "../../components/fields/input/input.component";
import Buttons from "../../components/fields/button/button.component";
import DropDown from "../../components/fields/dropDown/dropDown.component";
import Select from "react-dropdown-select";
import { Card } from "../../components/NewComponents/DragDrop";

import "./forms.scss";
import DeleteConfirmation from "../../components/deleteConfirmation.component";
import { NotificationContext } from "../../contexts/notficationContext";
import { BotContext } from "../../contexts/BotContext";
import { UserContext } from "../../contexts/userContext";
import { Tooltip } from "reactstrap";

class Forms_ extends Component {
  static contextType = NotificationContext;
  constructor(props) {
    super(props);
    this.state = {
      newForms: "",
      addNewFormDetail: "",
      selectedForm: null,
      selectedFormValidation: null,
      isSelected: "",
      formOldName: "",
      oldSlot: "",
      oldQuestion: "",
      ifIntent: false,
      textValue: "",
      readOnly: "",
      modal: false,
      slotIndex: "",
    };
  }

  setNavBarButtons = (isDisabled) => {
    this.props.setNavBarButtons(
      <div className="row">
        <div className="col-auto">
          <Buttons
            id="saveButton"
            className="btn intents-btn rounded-2 intent-font-save"
            isDisabled={isDisabled}
            onClick={() =>
              this.state.formDetails &&
              this.state.formDetails[this.state.slotIndex]?.data?.length > 0 &&
              this.toggle(
                "_update",
                this.state.selectedForm,
                this.state.formDetails,
                "",
                true
              )
            }
          >
            Save
          </Buttons>
        </div>
      </div>
    );
  };

  componentDidMount() {
    let bot_id = botService.getSelectedBot().bot_id;
    if (bot_id) {
      this.getBotForms();
      this.getApis();
      this.setNavBarButtons(true);
    }
  }

  componentWillUnmount() {
    this.props.setNavBarButtons("");
  }

  getBotForms = async () => {
    try {
      const r = await formsServices.getBotForms();

      if (r.status === "success") {
        const formList = r.data.map((d) => ({ label: d, isSelected: false }));

        this.setState({
          formList,
          trainedFormList: r.trained,
          formListValidation: r.validation,
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  getBotFormDetails = async (form, callback) => {
    try {
      const r = await formsServices.getBotFormDetails(form);

      if (r.status === "success") {
        const formDetails = r.data.map((d) => ({
          ...d,
          isSelected: false,
        }));

        this.setState(
          { formDetails, selectedFormValidation: r.validation },
          () => {
            callback && callback(this.state.formDetails);
          }
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  getIntents() {
    return intentServices
      .getBotIntents()
      .then((res) => res)
      .catch((err) => console.log("Failed: ", err));
  }

  getBotSlots() {
    return slotService
      .getBotSlots()
      .then((res) => res)
      .catch((err) => console.log("Failed: ", err));
  }

  getResponses() {
    return utternaceServices
      .getBotUtterances()
      .then((res) => res)
      .catch((err) => console.log("Failed: ", err));
  }

  getEntity() {
    return entityServices
      .getBotEntity()
      .then((res) => res)
      .catch((err) => console.log("Failed: ", err));
  }

  getSystemEntity() {
    return entityServices
      .getSystemEntities()
      .then((res) => res)
      .catch((e) => console.log("Failed: ", e));
  }

  getApis = async () => {
    let [slots, responses, intents, entity, systemEntity] = await Promise.all([
      this.getBotSlots(),
      this.getResponses(),
      this.getIntents(),
      this.getEntity(),
      this.getSystemEntity(),
    ]);

    slots = slots.data.map((s) => ({ label: s }));
    responses = responses.data.map((r) => ({ label: r }));
    intents = intents.data.map((i) => ({
      label: i,
      value: i,
    }));
    //intents.unshift({ label: "Existing intents", value: "", isHeader: true });
    entity = entity.data.map((e) => ({ label: e }));
    systemEntity = Object.keys(systemEntity.data).map((e) => ({ label: e }));
    entity = [...entity, ...systemEntity];
    this.setState({ slots, responses, intents, entity });
  };

  customItemRenderer = ({ item, itemIndex, props, state, methods }) => (
    <div className="rounded-2 pe-auto options">
      {!item.isHeader && (
        <div
          className="p-1 px-2 form-text4"
          onClick={() => methods.addItem(item)}
        >
          {item.label}
        </div>
      )}
      {item.isHeader && (
        <>
          <p className="px-2 fw-bold">{item.label}</p>{" "}
        </>
      )}
    </div>
  );

  customOptionRenderer = ({ item, itemIndex, props, state, methods }) => (
    <React.Fragment>
      <div className="multi-select-options ">
        <div className="px-2 py-1">
          <span className="p-2 form-text5">{item.label}</span>
          <i
            className="fas fa-times text-dark"
            onClick={(event) => methods.removeItem(event, item, true)}
          />{" "}
        </div>
      </div>
    </React.Fragment>
  );

  handleAddQuestion = (form) => {
    this.getBotFormDetails(form, (temp) => {
      temp.push({
        data: [],
        question: "untitled" + Date.now(),
        slot: "untitled",
      });
      this.setState(
        { formDetails: temp }
        //   () => {
        //   this.toggle("_update", form, temp, () => this.getBotFormDetails(form));
        // }
      );
    });
  };

  toggle = (action, payload, payload2, callback, showNoti) => {
    switch (action) {
      case "_rename":
        formsServices
          .renameBotForm({ old_name: payload.label, new_name: payload2 })
          .then((res) => {
            if (res.status === "success") {
              this.context.setNotifications({
                color: "success",
                message: res.message,
              });
            } else {
              this.context.setNotifications({
                color: "danger",
                message: res.message,
              });
            }
          })
          .catch((err) =>
            this.context.setNotifications({
              color: "danger",
              message: err.message,
            })
          );
        this.onPageRefresh();
        break;
      case "_update":
        formsServices
          .updateBotForm(payload, payload2, this.state.selectedFormValidation)
          .then(
            (res) =>
              res.status === "success" &&
              showNoti &&
              this.context.setNotifications({
                color: "success",
                message: res.message,
              })
          )
          .catch((err) =>
            this.context.setNotifications({
              color: "danger",
              message: err.message,
            })
          );
        // this.onPageRefresh();
        this.getBotForms();
        break;
      case "_addForm":
        formsServices
          .addBotForm(payload)
          .then((res) => {
            if (res.status === "success") {
              this.context.setNotifications({
                color: "success",
                message: res.message,
              });
            } else {
              this.context.setNotifications({
                color: "danger",
                message: res.message,
              });
            }
          })
          .catch((err) =>
            this.context.setNotifications({
              color: "danger",
              message: err.message,
            })
          );
        this.onPageRefresh();
        break;
      case "_delete":
        formsServices
          .deleteBotForm(payload)
          .then((res) => {
            if (
              res.status === "success" &&
              Object.keys(res.failed || {}).length === 0
            ) {
              this.context.setNotifications({
                color: "success",
                message: res.message,
              });
            } else {
              this.context.setNotifications({
                color: "danger",
                message:
                  Object.keys(res.failed || {}).length === 0
                    ? res.message
                    : res.failed[Object.keys(res.failed)[0]],
              });
            }
          })
          .catch((err) =>
            this.context.setNotifications({
              color: "danger",
              message: err.message,
            })
          );
        this.onPageRefresh();
        break;
      default:
        break;
    }
  };

  dropdownList = (name) => {
    let { formListValidation } = this.state;
    return [
      {
        label: "Add Slot",
        icon: svgIcons("add"),
        isUsed: this.props.permissions.forms.modify !== "enabled",
      },
      {
        label: "Validate Form",
        icon: svgIcons(formListValidation[name] ? "toggle-on" : "toggle-off"),
        isUsed: this.props.permissions.forms.modify !== "enabled",
      },
      {
        label: "Rename Form",
        icon: svgIcons("edit"),
        isUsed: this.props.permissions.forms.modify !== "enabled",
      },
      {
        label: "Delete Form",
        icon: this.getDeleteModal("deleteForm"),
        isUsed: this.props.permissions.forms.modify !== "enabled",
      },
    ];
  };

  handleDropDown = (menu, i, item, index, setIndex) => {
    let { formDetails } = this.state;

    switch (menu.label) {
      case "Validate Form":
        formsServices.getBotFormDetails(item).then((response) => {
          formsServices
            .updateBotForm(item, response.data, !response.validation)
            .then((res) => this.onPageRefresh());
        });
        break;
      case "Add Slot":
        if (index !== i) {
          this.handleAddQuestion(item);
          setTimeout(() => {
            setIndex(i);
          }, 500);
        } else {
          formDetails &&
            formDetails.push({
              data: [],
              question: "untitled" + Date.now(),
              slot: "untitled",
            });
          this.setState({ formDetails });
        }
        break;
      case "Rename Form":
        this.setState({ readOnly: i });
        let div = document.getElementById(i);
        this.props.permissions.forms.modify === "enabled" &&
          div.querySelector(".forms-menu-text").focus();
        break;
      case "Delete Form":
        this.setState({ modal: true });
        setTimeout(() => {
          this.setState({ modal: false });
        }, 100);
        break;
      default:
        break;
    }
  };

  handleRenameBlur = () => {
    let { formOldName, formNewName, formList } = this.state;

    if (
      formOldName &&
      formNewName &&
      formOldName !== formNewName &&
      this.props.permissions.forms.modify === "enabled"
    ) {
      if (!formList.some((e) => e.label === formNewName)) {
        if (/^[A-Za-z][A-Za-z0-9_]+$/.test(formNewName))
          this.toggle("_rename", formOldName, formNewName);
        else
          this.context.setNotifications({
            color: "black",
            message:
              "Form name should not contain any special characters or spaces",
          });
      } else {
        this.context.setNotifications({
          color: "black",
          message: "Form already exists",
        });
      }
    }

    this.setState({ readOnly: null });
  };

  moveSlotCards = (dragIndex, hoverIndex) => {
    let { formDetails } = this.state;
    if (this.props.permissions.forms.list === "enabled") {
      formDetails.splice(hoverIndex, 0, formDetails.splice(dragIndex, 1)[0]);
      this.setState({ formDetails }, () => {
        this.setNavBarButtons(false);
      });
    }
  };

  saveFormAfterDrop = () => {
    const { selectedForm, formDetails } = this.state;
    this.props.permissions.forms.list === "enabled" &&
      this.toggle("_update", selectedForm, formDetails);
  };

  renderSlotCards = (card, index) => {
    return (
      <Card
        key={index}
        index={index}
        id={index}
        text={card.slot}
        moveCard={this.moveSlotCards}
        questionSelect={(item, ix) => {
          if (this.props.permissions.forms.list === "enabled") {
            const localFormDetails = this.state.formDetails.map((f) => {
              f.isSelected = f.slot === item;
              return f;
            });

            this.setState(
              {
                formDetails: localFormDetails,
                formIndex: ix,
                ifEntity: "",
                ifIntent: "",
                ifText: "",
                isSelected: true,
                selectedQuesiton: item,
                slotIndex: ix,
              },
              () =>
                this.setNavBarButtons(
                  !this.state.formDetails[ix].data.some((ele) => ele.type)
                )
            );
          }
        }}
        form={card}
        saveForm={this.saveFormAfterDrop}
      />
    );
  };

  handleDropDownToggleClick = (item, setIndex) => {
    this.setState({ selectedForm: item });
  };

  getDeleteModal = (action, i) => {
    let { formDetails, formIndex, selectedForm, formList } = this.state;
    return (
      <DeleteConfirmation
        open={this.state.modal}
        delete={() => {
          if (action === "phrase") {
            if (formDetails[formIndex].data.length > 1) {
              formDetails[formIndex].data.splice(i, 1);
              this.setState(
                { formDetails, ifEntity: "", ifText: "", ifIntent: "" },
                () => this.toggle("_update", selectedForm, formDetails)
              );
            } else {
              this.context.setNotifications({
                color: "danger",
                message: "Cannot delete all the conditions",
              });
            }
          } else if (action === "deleteForm") {
            let temp = formList.filter((item) => item !== selectedForm);
            this.setState(
              { formList: temp, selectedQuesiton: "", isSelected: false },
              () => {
                this.toggle("_delete", selectedForm);
              }
            );
          } else {
            formDetails.splice(formIndex, 1);
            this.setState(
              { formDetails, selectedQuesiton: "", isSelected: false },
              () => {
                this.toggle("_update", selectedForm, formDetails);
              }
            );
          }
        }}
      />
    );
  };

  handleNameChange = (e, index) => {
    let { formList, formOldName, formNewName } = this.state;
    if (!formOldName && this.props.permissions.forms.modify === "enabled")
      this.setState({ formOldName: formList[index] });
    formNewName = e.target.value;
    formList[index] = formNewName;
    this.setState({ formList, formNewName });
    if (
      e.key === "Enter" &&
      formOldName &&
      formNewName &&
      formOldName !== formNewName &&
      this.props.permissions.forms.modify === "enabled"
    ) {
      if (!formList.some((e) => e.label === formNewName)) {
        if (/^[A-Za-z][A-Za-z0-9_]+$/.test(formNewName))
          this.toggle("_rename", formOldName, formNewName);
        else
          this.context.setNotifications({
            color: "black",
            message:
              "Form name should not contain any special characters or spaces",
          });
      } else {
        this.context.setNotifications({
          color: "black",
          message: "Form already exists",
        });
      }
    }
  };

  toggleToolTip = () => {
    this.setState({ toolTip: true });
    setTimeout(() => {
      this.setState({ toolTip: false });
    }, 1500);
  };

  addDefaultCondition = (type) => {
    let { formDetails, formIndex, selectedForm } = this.state;
    formDetails[formIndex].data.push({
      intent: [],
      not_intent: [],
      type: type,
    });
    this.setState({ formDetails }, () => {
      this.toggle("_update", selectedForm, formDetails);
      this.setNavBarButtons();
    });
  };

  renderBotForm = () => {
    let {
      slots,
      selectedSlot,
      responses,
      selectedResponse,
      selectedForm,
      intents,
      // selectedIntent,
      entity,
      selectedEntity,
      isIntentOneOf,
      ifIntent,
      ifEntity,
      ifText,
      formIndex,
      formDetails,
      // textValue,
      oldSlot,
      oldQuestion,
    } = this.state;
    return (
      <div className="mt-4">
        <div className="row m-0">
          <div className="col-auto ps-0 mb-4">
            <label className="mx-1 mb-1 form-text">Pick a Slot *</label>
            <DropDown
              dropdownList={slots}
              btnClassName="slot-DropDown"
              itemClassName="form-text3"
              disabled={this.props.permissions.forms.modify !== "enabled"}
              actionButton={() => (
                <div className="row justify-content-between">
                  <div className="col dropdown-text">
                    {(
                      <span className="form-text2">
                        {formDetails[formIndex].slot || selectedSlot}
                      </span>
                    ) || <p style={{ color: "#BCBCBC" }}>Pick a slot</p>}
                  </div>
                  <div className="col-auto me-2">
                    {svgIcons("chevronDown", "black", "", 15)}
                  </div>
                </div>
              )}
              onActionItemClick={({ label }) => {
                if (!oldSlot) {
                  oldSlot = formDetails[formIndex].slot;
                  this.setState({ oldSlot });
                }
                formDetails[formIndex].slot = label;
                this.setState(
                  {
                    selectedSlot: label,
                    formDetails,
                    selectedQuesiton: label,
                  }
                  // () =>
                  //   oldSlot !== label &&
                  //   slots
                  //     .map((i) => i.label)
                  //     .includes(formDetails[formIndex].slot) &&
                  //   this.toggle("_update", selectedForm, formDetails)
                );
              }}
            />
          </div>
          <div className="col ps-0 mb-4">
            <label className="mx-1 mb-1 form-text">Pick a Question *</label>
            <DropDown
              dropdownList={responses}
              btnClassName="response-DropDown"
              itemClassName="form-text4"
              disabled={this.props.permissions.forms.modify !== "enabled"}
              actionButton={() => (
                <div className="row justify-content-between">
                  <div className="col-auto dropdown-text">
                    {(
                      <span className="form-text2">
                        {formDetails[formIndex].question || selectedResponse}
                      </span>
                    ) || (
                      <p style={{ color: "#BCBCBC" }}>
                        Pick slot question from bot responses
                      </p>
                    )}
                  </div>
                  <div className="col-auto me-2">
                    {svgIcons("chevronDown", "black", "", 15)}
                  </div>
                </div>
              )}
              onActionItemClick={({ label }) => {
                if (!oldQuestion)
                  this.setState({
                    oldQuestion: formDetails[formIndex].question,
                  });
                formDetails[formIndex].question = label;
                this.setState(
                  {
                    selectedResponse: label,
                    formDetails,
                  }
                  // () =>
                  //   oldQuestion !== label &&
                  //   slots
                  //     .map((i) => i.label)
                  //     .includes(formDetails[formIndex].slot) &&
                  //   this.toggle("_update", selectedForm, formDetails)
                );
              }}
            />
          </div>
        </div>
        <div
          className={`d-flex justify-content-start align-items-center ${
            !slots.map((i) => i.label).includes(formDetails[formIndex].slot) &&
            !responses
              .map((i) => i.label)
              .includes(formDetails[formIndex].question) &&
            "opacity-50"
          }`}
        >
          <span id="condition" className="me-3 text-center form-condition-text">
            Condition
          </span>
          <div
            className="cursor-pointer"
            onClick={() => {
              if (
                slots
                  .map((i) => i.label)
                  .includes(formDetails[formIndex].slot) &&
                responses
                  .map((i) => i.label)
                  .includes(formDetails[formIndex].question) &&
                formDetails[formIndex].data.length > 0 &&
                this.props.permissions.forms.modify === "enabled"
              ) {
                formDetails[formIndex].data.push({
                  intent: [],
                  not_intent: [],
                  type: "",
                });
                this.setState({ formDetails });
              } else {
                this.toggleToolTip();
              }
            }}
          >
            {svgIcons("add")}
          </div>
          <Tooltip
            target="condition"
            isOpen={this.state.toolTip}
            placement="bottom"
          >
            Pick a slot and question
          </Tooltip>
          {/* {!formDetails[formIndex]?.data?.length > 0 && ( */}
          <small className="text-muted ms-3 mt-2">
            *Please add a condition to save form
          </small>
          {/* )}{" "} */}
        </div>
        {selectedSlot &&
          selectedResponse &&
          formDetails[formIndex].data &&
          formDetails[formIndex].data.length < 1 && (
            <div className="col-12 greeting-msg-container py-3">
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <span className="text-center form-text me-4">
                    Condition Based on
                  </span>
                  <Buttons
                    className={"btn btn-small me-2 py-1 condition-btn"}
                    onClick={() => this.addDefaultCondition("from_intent")}
                    isDisabled={
                      this.props.permissions.forms.modify !== "enabled"
                    }
                  >
                    Intent
                  </Buttons>
                  <Buttons
                    className={"btn btn-small me-2 py-1 condition-btn"}
                    onClick={() => this.addDefaultCondition("from_entity")}
                    isDisabled={
                      this.props.permissions.forms.modify !== "enabled"
                    }
                  >
                    Entity
                  </Buttons>
                  <Buttons
                    className={"btn btn-small me-2 py-1 condition-btn"}
                    onClick={() => this.addDefaultCondition("from_text")}
                    isDisabled={
                      this.props.permissions.forms.modify !== "enabled"
                    }
                  >
                    Text
                  </Buttons>
                </div>
              </div>
            </div>
          )}
        <div className="row m-0">
          {formDetails[formIndex].data &&
            formDetails[formIndex].data.map((obj, il) => (
              <div className="col-12 greeting-msg-container py-3" key={il}>
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <span className="text-center form-text me-4">
                      Condition Based on
                    </span>
                    <Buttons
                      isDisabled={
                        this.props.permissions.forms.modify !== "enabled"
                      }
                      className={`${
                        ifIntent === il || obj.type === "from_intent"
                          ? "selected-btn"
                          : "condition-btn"
                      } btn btn-small me-2 py-1`}
                      onClick={() => {
                        if (obj.type !== "from_intent") {
                          obj.intent = [];
                          obj.not_intent = [];
                          obj.type = "from_intent";
                          obj.value = "";
                          Object.keys(obj).includes("entity") &&
                            delete obj.entity;
                        } else {
                          obj.type = "from_intent";
                        }
                        if (
                          formDetails[formIndex] &&
                          formDetails[formIndex].data.some(
                            (ele) => ele.type !== ""
                          )
                        ) {
                          this.setNavBarButtons();
                        }
                        this.setState(
                          {
                            ifIntent: il,
                            isIntentOneOf: "",
                            formDetails,
                            ifEntity: "",
                            ifText: "",
                          },
                          () =>
                            this.toggle("_update", selectedForm, formDetails)
                        );
                      }}
                    >
                      Intent
                    </Buttons>
                    <Buttons
                      isDisabled={
                        this.props.permissions.forms.modify !== "enabled"
                      }
                      className={`${
                        ifEntity === il || obj.type === "from_entity"
                          ? "selected-btn"
                          : "condition-btn"
                      } btn btn-small me-2 py-1`}
                      onClick={() => {
                        if (obj.type !== "from_entity") {
                          obj.intent = [];
                          obj.not_intent = [];
                          obj.entity = "";
                          obj.type = "from_entity";
                          Object.keys(obj).includes("value") &&
                            delete obj.value;
                        } else {
                          obj.type = "from_entity";
                        }
                        if (
                          formDetails[formIndex] &&
                          formDetails[formIndex].data.some(
                            (ele) => ele.type !== ""
                          )
                        ) {
                          this.setNavBarButtons();
                        }
                        this.setState(
                          {
                            ifEntity: il,
                            ifIntent: "",
                            ifText: "",
                            formDetails,
                            isIntentOneOf: "",
                          },
                          () =>
                            this.toggle("_update", selectedForm, formDetails)
                        );
                      }}
                    >
                      Entity
                    </Buttons>
                    <Buttons
                      isDisabled={
                        this.props.permissions.forms.modify !== "enabled"
                      }
                      className={`${
                        ifText === il || obj.type === "from_text"
                          ? "selected-btn"
                          : "condition-btn"
                      } btn btn-small me-2 py-1`}
                      onClick={() => {
                        if (obj.type !== "from_text") {
                          obj.intent = [];
                          obj.not_intent = [];
                          obj.type = "from_text";
                          Object.keys(obj).includes("value") &&
                            delete obj.value;
                          Object.keys(obj).includes("entity") &&
                            delete obj.entity;
                        } else {
                          obj.type = "from_text";
                        }
                        if (
                          formDetails[formIndex] &&
                          formDetails[formIndex].data.some(
                            (ele) => ele.type !== ""
                          )
                        ) {
                          this.setNavBarButtons();
                        }
                        this.setState(
                          {
                            ifEntity: "",
                            ifIntent: "",
                            ifText: il,
                            formDetails,
                            isIntentOneOf: "",
                          },
                          () =>
                            this.toggle("_update", selectedForm, formDetails)
                        );
                      }}
                    >
                      Text
                    </Buttons>
                  </div>
                  <DropDown
                    isContent={true}
                    btnClassName="px-1"
                    dropdownList={[
                      {
                        label: "Delete",
                        icon: this.getDeleteModal("phrase", il),
                        isUsed:
                          this.props.permissions.forms.modify !== "enabled",
                      },
                    ]}
                    actionButton={() => svgIcons("verticalOptions")}
                    onActionItemClick={() => {
                      this.setState({ modal: true });
                      setTimeout(() => {
                        this.setState({ modal: false });
                      }, 100);
                    }}
                  />
                </div>
                {ifIntent === il && (
                  <div className="row m-0 mt-4">
                    <div className="col-auto mb-4 ps-0">
                      <DropDown
                        isContent={true}
                        disabled={
                          this.props.permissions.forms.modify !== "enabled"
                        }
                        dropdownList={[
                          { label: "If intent is one of" },
                          { label: "If intent is not one of" },
                        ]}
                        btnClassName="isIntentOneof-DropDown"
                        actionButton={() => (
                          <div className="row justify-content-between">
                            <div className="col-auto form-text2 pt-1">
                              {isIntentOneOf ? (
                                isIntentOneOf
                              ) : obj.intent.length > 0 ? (
                                "If intent is one of"
                              ) : obj.not_intent.length > 0 ? (
                                "If intent is not one of"
                              ) : (
                                <p style={{ color: "#BCBCBC" }}>
                                  If intent is one of
                                </p>
                              )}
                            </div>
                            <div className="col-auto me-2">
                              {svgIcons("chevronDown", "black", "", 15)}
                            </div>
                          </div>
                        )}
                        onActionItemClick={({ label }) => {
                          if (obj.intent || obj.not_intent) {
                            obj.intent = [];
                            obj.not_intent = [];
                          }
                          this.setState({
                            isIntentOneOf: label,
                            formDetails,
                          });
                        }}
                      />
                    </div>
                    <div className="col ps-0 mb-4">
                      <Select
                        multi={true}
                        options={intents}
                        searchable={false}
                        placeholder="Choose intent"
                        className="multi-select border-0 "
                        itemRenderer={this.customItemRenderer}
                        optionRenderer={this.customOptionRenderer}
                        disabled={
                          this.props.permissions.forms.modify !== "enabled"
                        }
                        onDropdownClose={() =>
                          this.toggle("_update", selectedForm, formDetails)
                        }
                        onChange={(values) => {
                          if (
                            obj.intent.length > 0 ||
                            isIntentOneOf === "If intent is one of"
                          ) {
                            let value = values.map((item) => item.label);
                            formDetails[formIndex].data[il].intent = value;
                          } else if (
                            obj.not_intent.length > 0 ||
                            isIntentOneOf === "If intent is not one of"
                          ) {
                            let val = values.map((item) => item.label);
                            formDetails[formIndex].data[il].not_intent = val;
                          }
                          this.setState({ formDetails });
                        }}
                        values={
                          obj.intent.length > 0
                            ? intents.filter((item) =>
                                obj.intent.includes(item.label)
                              )
                            : obj.not_intent.length > 0
                            ? intents.filter((item) =>
                                obj.not_intent.includes(item.label)
                              )
                            : []
                        }
                      />
                    </div>
                    <div className="d-flex flex-column p-0">
                      <span className="mb-2 form-text">Default value</span>
                      <Input
                        isLiteration
                        id={`${il}-x`}
                        readOnly={
                          this.props.permissions.forms.modify !== "enabled"
                        }
                        className="forms-input px-3"
                        placeholder="This value is considered if user doesn't enter any data"
                        value={obj.value}
                        onChange={(e) => {
                          typeof e === "string"
                            ? (obj.value = e)
                            : (obj.value = e.target.value);
                          this.setState({ formDetails });
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            this.toggle("_update", selectedForm, formDetails);
                            document.getElementById(`${il}-x`).blur();
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
                {ifEntity === il && (
                  <div className="row m-0 mt-4">
                    <div className="col-auto mb-4 ps-0">
                      <DropDown
                        isContent={true}
                        dropdownList={[
                          { label: "If intent is one of" },
                          { label: "If intent is not one of" },
                        ]}
                        btnClassName="isIntentOneof-DropDown"
                        disabled={
                          this.props.permissions.forms.modify !== "enabled"
                        }
                        actionButton={() => (
                          <div className="row justify-content-between">
                            <div className="col-auto form-text2 pt-1">
                              {isIntentOneOf ? (
                                isIntentOneOf
                              ) : obj.intent.length > 0 ? (
                                "If intent is one of"
                              ) : obj.not_intent.length > 0 ? (
                                "If intent is not one of"
                              ) : (
                                <p style={{ color: "#BCBCBC" }}>
                                  If intent is one of
                                </p>
                              )}
                            </div>
                            <div className="col-auto me-2">
                              {svgIcons("chevronDown", "black", "", 15)}
                            </div>
                          </div>
                        )}
                        onActionItemClick={({ label }) => {
                          if (obj.intent || obj.not_intent) {
                            obj.intent = [];
                            obj.not_intent = [];
                          }
                          this.setState({
                            isIntentOneOf: label,
                            formDetails,
                          });
                        }}
                      />
                    </div>
                    <div className="col ps-0 mb-4">
                      <Select
                        multi={true}
                        options={intents}
                        searchable={false}
                        placeholder="Choose intent"
                        className="multi-select border-0 "
                        itemRenderer={this.customItemRenderer}
                        optionRenderer={this.customOptionRenderer}
                        disabled={
                          this.props.permissions.forms.modify !== "enabled"
                        }
                        onDropdownClose={() =>
                          this.toggle("_update", selectedForm, formDetails)
                        }
                        onChange={(values) => {
                          if (
                            obj.intent.length > 0 ||
                            isIntentOneOf === "If intent is one of"
                          ) {
                            let value = values.map((item) => item.label);
                            formDetails[formIndex].data[il].intent = value;
                          } else if (
                            obj.not_intent.length > 0 ||
                            isIntentOneOf === "If intent is not one of"
                          ) {
                            let val = values.map((item) => item.label);
                            formDetails[formIndex].data[il].not_intent = val;
                          }
                          this.setState({ formDetails });
                        }}
                        values={
                          obj.intent.length > 0
                            ? intents.filter((item) =>
                                obj.intent.includes(item.label)
                              )
                            : obj.not_intent.length > 0
                            ? intents.filter((item) =>
                                obj.not_intent.includes(item.label)
                              )
                            : []
                        }
                      />
                    </div>
                    <div className="col-12 ps-0">
                      <DropDown
                        isContent={true}
                        dropdownList={entity}
                        btnClassName="slot-DropDown px-2"
                        disabled={
                          this.props.permissions.forms.modify !== "enabled"
                        }
                        actionButton={() => (
                          <div className="row">
                            <div className="col-auto">
                              {obj.entity || selectedEntity || (
                                <p style={{ color: "#BCBCBC" }}>
                                  Pick an entity
                                </p>
                              )}
                            </div>
                            <div className="col"></div>
                            <div className="col-auto me-2">
                              {svgIcons("chevronDown", "black", "", 15)}
                            </div>
                          </div>
                        )}
                        onActionItemClick={({ label }) => {
                          let temp = obj.entity;
                          obj.entity = label;
                          this.setState(
                            {
                              selectedEntity: label,
                              formDetails,
                            },
                            () =>
                              temp !== label &&
                              this.toggle("_update", selectedForm, formDetails)
                          );
                        }}
                      />
                    </div>
                  </div>
                )}
                {ifText === il && (
                  <div className="row m-0 mt-4">
                    <div className="col-auto mb-4 mt-1 ps-0">
                      <DropDown
                        isContent={true}
                        dropdownList={[
                          { label: "If intent is one of" },
                          { label: "If intent is not one of" },
                        ]}
                        btnClassName="isIntentOneof-DropDown"
                        disabled={
                          this.props.permissions.forms.modify !== "enabled"
                        }
                        actionButton={() => (
                          <div className="row justify-content-between">
                            <div className="col-auto">
                              {isIntentOneOf ? (
                                isIntentOneOf
                              ) : obj.intent.length > 0 ? (
                                "If intent is one of"
                              ) : obj.not_intent.length > 0 ? (
                                "If intent is not one of"
                              ) : (
                                <p style={{ color: "#BCBCBC" }}>
                                  If intent is one of
                                </p>
                              )}
                            </div>
                            <div className="col-auto me-2">
                              {svgIcons("chevronDown", "black", "", 15)}
                            </div>
                          </div>
                        )}
                        onActionItemClick={({ label }) => {
                          this.setState({
                            isIntentOneOf: label,
                          });
                        }}
                      />
                    </div>
                    <div className="col ps-0 mb-4">
                      <Select
                        multi={true}
                        options={intents}
                        searchable={false}
                        placeholder="Choose intent"
                        className="multi-select border-0 "
                        itemRenderer={this.customItemRenderer}
                        optionRenderer={this.customOptionRenderer}
                        disabled={
                          this.props.permissions.forms.modify !== "enabled"
                        }
                        onDropdownClose={() =>
                          this.toggle("_update", selectedForm, formDetails)
                        }
                        onChange={(values) => {
                          if (
                            obj.intent.length > 0 ||
                            isIntentOneOf === "If intent is one of"
                          ) {
                            let value = values.map((item) => item.label);
                            formDetails[formIndex].data[il].intent = value;
                          } else if (
                            obj.not_intent.length > 0 ||
                            isIntentOneOf === "If intent is not one of"
                          ) {
                            let val = values.map((item) => item.label);
                            formDetails[formIndex].data[il].not_intent = val;
                          }
                          this.setState({ formDetails });
                        }}
                        values={
                          obj.intent.length > 0
                            ? intents.filter((item) =>
                                obj.intent.includes(item.label)
                              )
                            : obj.not_intent.length > 0
                            ? intents.filter((item) =>
                                obj.not_intent.includes(item.label)
                              )
                            : []
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    );
  };

  onPageRefresh(callback) {
    this.setState(
      {
        formList: [],
        trainedFormList: [],
        formDetails: [],
        selectedForm: undefined,
        selectedFormValidation: undefined,
        selectedQuesiton: "",
        newForm: "",
        isSelected: false,
        formOldName: "",
        formNewName: "",
        readOnly: undefined,
        formIndex: undefined,
        modal: false,
      },
      () => {
        setTimeout(() => {
          this.getBotForms();
        }, 100);
      }
    );
  }

  render() {
    let {
      formList,
      trainedFormList,
      formDetails,
      selectedForm,
      selectedQuesiton,
      newForm,
      isSelected,
      readOnly,
    } = this.state;
    return (
      <div className="container-fluid h-100">
        <div className="row h-100">
          <BotLayoutLeftPanel
            title="Forms"
            isFormsPage
            value={newForm}
            sideBarList={formList}
            readOnly={readOnly}
            formDetails={formDetails}
            selectedIntent={selectedForm}
            trainedIntent={trainedFormList}
            dropdownList={this.dropdownList}
            handleDropDown={this.handleDropDown}
            renderSlotCards={this.renderSlotCards}
            handleRenameBlur={this.handleRenameBlur}
            handleNameChange={this.handleNameChange}
            handleAddQuestion={this.handleAddQuestion}
            handleDropDownToggleClick={this.handleDropDownToggleClick}
            onChange={(e) => this.setState({ newForm: e.target.value })}
            addBtnHide={this.props.permissions.forms.add}
            onMenuSelect={(label, i) => {
              this.setState(
                {
                  formNewName: label,
                  selectedForm: label,
                  selectedQuesiton: "",
                },
                () =>
                  this.props.permissions.forms.content === "enabled" &&
                  this.getBotFormDetails(label)
              );
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter" && newForm) {
                if (!formList.find((f) => f.label === newForm)) {
                  if (/^[A-Za-z][A-Za-z0-9_]+$/.test(newForm)) {
                    formList.push(newForm);
                    this.setState({ formList }, () =>
                      this.toggle("_addForm", newForm)
                    );
                  } else {
                    this.setState({ newForm: "" });
                    this.context.setNotifications({
                      color: "black",
                      message:
                        "Form name should not contain any special characters or spaces",
                    });
                  }
                } else {
                  this.setState({ newForm: "" });
                  this.context.setNotifications({
                    color: "black",
                    message: "Form already exists",
                  });
                }
              }
            }}
            questionSelect={(item, ix) => {
              const localFormDetails = formDetails.map((f) => {
                f.isSelected = f.slot === item;
                return f;
              });
              this.setState({
                formDetails: localFormDetails,
                formIndex: ix,
                ifEntity: "",
                ifIntent: "",
                ifText: "",
                isSelected: true,
                selectedQuesiton: item,
                slotIndex: ix,
              });
            }}
          />
          <BotLayoutContent
            isFormsPage
            titleReadOnly
            titleOnChange={() => {}}
            titleValue={selectedQuesiton}
            actions={[
              {
                label: "Delete",
                icon: this.getDeleteModal(),
                isUsed: this.props.permissions.forms.delete === "disabled",
              },
            ]}
            onActionClick={(m) => {
              if (m.label === "Delete") {
                this.setState({ modal: true });
                setTimeout(() => {
                  this.setState({ modal: false });
                }, 100);
              }
            }}
            isSelected={isSelected}
            isFaq={true}
            isShowContent={this.props.permissions.forms.content === "enabled"}
          >
            {selectedQuesiton &&
              this.props.permissions.forms.content === "enabled" &&
              this.renderBotForm()}
          </BotLayoutContent>
          <BotLayoutRightPanel />
        </div>
      </div>
    );
  }
}

export default function Forms() {
  const { setNavBarButtons } = useContext(BotContext);
  const { getUserDetails } = useContext(UserContext);

  return (
    <Forms_
      setNavBarButtons={setNavBarButtons}
      permissions={getUserDetails().feature_permission}
    />
  );
}
