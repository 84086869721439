import baseUrl from "../baseURL.service";
import botService from "../bot.service";
import { getToken } from "../authUtil";
import apiServices from "../api.services";

const fetchData = (url, options) => {
  return apiServices.fetchData(url, { ...options });
};

const getBotDetails = () => {
  const bot_id = botService.getSelectedBotId();
  const reqOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };

  return fetchData(`${baseUrl}/get_bot_details/${bot_id}`, reqOptions);
};

const createBackUp = (dataObj) => {
  const bot_id = botService.getSelectedBotId();
  const reqOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };

  return fetchData(`${baseUrl}/create_backup/${bot_id}`, reqOptions);
};

const getBackUp = () => {
  const bot_id = botService.getSelectedBotId();
  const reqOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };

  return fetchData(`${baseUrl}/get_backups/${bot_id}`, reqOptions);
};

const removeBackUp = (dataObj) => {
  const bot_id = botService.getSelectedBotId();
  const reqOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };

  return fetchData(`${baseUrl}/remove_backup/${bot_id}`, reqOptions);
};

const downloadSingleBackUp = (backup_name) => {
  const bot_id = botService.getSelectedBotId();
  const reqOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };

  return fetchData(
    `${baseUrl}/download_backup/${bot_id}?backup_name=${backup_name}`,
    reqOptions
  );
};

const downloadFullBackUp = () => {
  const bot_id = botService.getSelectedBotId();
  const reqOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };

  return fetchData(`${baseUrl}/export/${bot_id}`, reqOptions);
};

const exportIndividualConfig = (filter) => {
  const bot_id = botService.getSelectedBotId();
  const reqOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };

  return fetchData(
    `${baseUrl}/export_json/${bot_id}?config_filter_key=${filter}`,
    reqOptions
  );
};

const restoreBackUp = (dataObj) => {
  const bot_id = botService.getSelectedBotId();
  const reqOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };

  return fetchData(`${baseUrl}/restore_backup/${bot_id}`, reqOptions);
};

const renameBackUp = (dataObj) => {
  const bot_id = botService.getSelectedBotId();
  const reqOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };

  return fetchData(`${baseUrl}/rename_backup/${bot_id}`, reqOptions);
};

const addUserAccess = (dataObj, botId) => {
  const bot_id = botService.getSelectedBotId() || botId;
  const reqOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };

  return fetchData(`${baseUrl}/add_user_access/${bot_id}`, reqOptions);
};

const removeUserAccess = (dataObj) => {
  const bot_id = botService.getSelectedBotId();
  const reqOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };

  return fetchData(`${baseUrl}/remove_user_access/${bot_id}`, reqOptions);
};

const updateUserAccess = (dataObj) => {
  const bot_id = botService.getSelectedBotId();
  const reqOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };

  return fetchData(`${baseUrl}/update_user_access/${bot_id}`, reqOptions);
};

const getUserAccess = () => {
  const bot_id = botService.getSelectedBotId();
  const reqOptions = {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(),
  };

  return fetchData(`${baseUrl}/get_user_access/${bot_id}`, reqOptions);
};

const getLinkBotsAvail = () => {
  const bot_id = botService.getSelectedBotId();
  const reqOptions = {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(),
  };

  return fetchData(`${baseUrl}/get_link_bot_details/${bot_id}`, reqOptions);
};

const unLinkBots = (body) => {
  const bot_id = botService.getSelectedBotId();
  const reqOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(body),
  };

  return fetchData(`${baseUrl}/unlink_bot/${bot_id}`, reqOptions);
};

const getBotsLinked = (value) => {
  const bot_id = botService.getSelectedBotId();
  const reqOptions = {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };

  return fetchData(
    `${baseUrl}/get_linked_bots/${bot_id}?language_detection=${value}`,
    reqOptions
  );
};

const linkBots = (body) => {
  const bot_id = botService.getSelectedBotId();
  const reqOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(body),
  };

  return fetchData(`${baseUrl}/link_bot/${bot_id}`, reqOptions);
};

const SettingsApiService = {
  createBackUp,
  getUserAccess,
  updateUserAccess,
  removeUserAccess,
  downloadFullBackUp,
  downloadSingleBackUp,
  getBackUp,
  getBotDetails,
  removeBackUp,
  renameBackUp,
  restoreBackUp,
  addUserAccess,
  exportIndividualConfig,
  getLinkBotsAvail,
  getBotsLinked,
  linkBots,
  unLinkBots,
};
export default SettingsApiService;
