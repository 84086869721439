const TextArea = ({
  className,
  cols,
  id,
  name,
  onchange,
  placeholder,
  rows,
  value,
}) => {
  return (
    <textarea
      className={className}
      cols={cols}
      id={id}
      name={name}
      onChange={onchange}
      placeholder={placeholder}
      rows={rows}
      value={value}
    ></textarea>
  );
};

export default TextArea;
