import React, { Component, useContext } from "react";
import "./organisationSettings.scss";

import Toggle from "../../components/fields/toggle/toggle";
import DropDown from "../../components/fields/dropDown/dropDown.component";
import Input from "../../components/fields/input/input.component";
import Buttons from "../../components/fields/button/button.component";
import Modal from "../../components/modal/modal.component";
import svgIcons from "../../services/icon.service";

import orgApiServices from "../../services/api.services/orgSettingApi.service";
import { NotificationContext } from "../../contexts/notficationContext";
import { UserContext } from "../../contexts/userContext";
import { getImageUrl } from "../../services/baseURL.service";
class OrganisationSettings_ extends Component {
  static contextType = NotificationContext;
  constructor(props) {
    super(props);
    this.state = {
      userDeleteModal: false,
      addUserModal: false,
      addSuccessful: false,
      isEditUserBots: false,
      isSelectSpecificUser: false,
      deleteUserModal: false,
      deleteBotModal: false,
      isShareDropdown: false,
      isAddBotDropdown: false,
      selectedUser: null,
      addUserDetails: {
        name: "",
        email: "",
        phone: "",
      },
      usersList: [],
      adminBotsList: [],
      selctedUserBots: [],
      shareDropdownList: [],
      addBotDropdownlist: [],
      adminUserDetails: null,
      searchBarShow: false,
      botSearchBarShow: false,
      size: 30,
      pageNo: 0,
      count: "",
    };
  }

  componentDidMount() {
    this.getUsers();
    this.getAdminBotsList();
    this.getOrgBots();
  }

  getUsers = () => {
    orgApiServices
      .getUserList()
      .then((res) => {
        if (res.status === "success") {
          let temp = res.data.map((ele) => {
            ele.isSelected = false;
            return ele;
          });
          let temp2 = res.data.map((u) => {
            u.isSelected = false;
            u.permit = "";
            return u;
          });
          this.setState({
            usersList: temp,
            shareDropdownList: temp2,
            shareDropdownListCopy: temp2,
            adminUserDetails: this.props.getAdminUserDetails(),
          });
        } else {
          this.context.setNotifications({
            color: "danger",
            message: res.message,
          });
        }
      })
      .catch((e) =>
        this.context.setNotifications({
          color: "danger",
          message: e.message,
        })
      );
  };

  getAdminBotsList = () => {
    orgApiServices
      .getUserBotList()
      .then((res) => {
        if (res.status === "success") {
          this.setState({ adminBotsList: res.data });
        } else {
          this.context.setNotifications({
            color: "danger",
            message: res.message,
          });
        }
      })
      .catch((e) =>
        this.context.setNotifications({
          color: "danger",
          message: e.message,
        })
      );
  };

  getUserBotsList = (user) => {
    orgApiServices.getUserBots({ email_id: user.email }).then((res) => {
      if (res.status === "success") {
        let temp = res.data.map((b) => {
          return { ...b, isSelected: false };
        });
        this.setState({
          selctedUserBots: temp,
          selectedUser: user,
          count: res.count,
        });
      }
    });
  };

  getOrgBots = () => {
    orgApiServices
      .orgBotsList({
        org_admin_email_id: this.props.getAdminUserDetails().email,
      })
      .then((res) => {
        if (res.status === "success") {
          let temp = res.data.map((bot) => {
            bot.isSelected = false;
            bot.label = bot.bot_name;
            return bot;
          });
          this.setState({
            addBotDropdownlist: temp,
            addBotDropdownlistCopy: temp,
          });
        }
      });
  };

  enableDisableUser = (action, body) => {
    if (action === "enable") {
      orgApiServices
        .activateUser(body)
        .then((res) =>
          res.status === "success"
            ? this.context.setNotifications({
                color: "success",
                message: res.message,
              })
            : this.context.setNotifications({
                color: "danger",
                message: res.message,
              })
        )
        .catch((e) =>
          this.context.setNotifications({
            color: "danger",
            message: e.message,
          })
        );
    } else {
      orgApiServices
        .deActivateUser(body)
        .then((res) =>
          res.status === "success"
            ? this.context.setNotifications({
                color: "success",
                message: res.message,
              })
            : this.context.setNotifications({
                color: "danger",
                message: res.message,
              })
        )
        .catch((e) =>
          this.context.setNotifications({
            color: "danger",
            message: e.message,
          })
        );
    }
  };

  handleAddUser = (data) => {
    orgApiServices
      .addUser(data)
      .then((res) => {
        if (res.status === "success") {
          this.context.setNotifications({
            color: "success",
            message: res.message,
          });
          this.setState({ addSuccessful: true });
        }
      })
      .catch((e) =>
        this.context.setNotifications({
          color: "danger",
          message: e.message,
        })
      );
  };

  handleDeleteUser = (data) => {
    orgApiServices
      .removeUser(data)
      .then((res) => {
        if (res.status === "success") {
          this.context.setNotifications({
            color: "success",
            message: res.message,
          });
          this.setState({ isSelectSpecificUser: false });
        }
      })
      .catch((e) =>
        this.context.setNotifications({
          color: "danger",
          message: e.message,
        })
      );
  };

  handleDeleteUserBots = (data) => {
    orgApiServices
      .deleteUserBots(data)
      .then((res) => {
        if (res.status === "success") {
          this.getUserBotsList(this.state.selectedUser);
          this.context.setNotifications({
            color: "success",
            message: res.message,
          });
        } else
          this.context.setNotifications({
            color: "danger",
            message: res.message,
          });
      })
      .catch((e) =>
        this.context.setNotifications({
          color: "danger",
          message: e.message,
        })
      );
  };

  addShareBots = (data) => {
    orgApiServices
      .addShareBots(data)
      .then((res) => {
        if (res.status === "success") {
          this.getUserBotsList(this.state.selectedUser);
          this.context.setNotifications({
            color: "success",
            message: res.message,
          });
        } else
          this.context.setNotifications({
            color: "danger",
            message: res.message,
          });
      })
      .catch((e) =>
        this.context.setNotifications({
          color: "danger",
          message: e.message,
        })
      );
  };

  handleOnChange = ({ target }) => {
    const { name, value } = target;
    let { addUserDetails } = this.state;

    addUserDetails[name] = value;
    this.setState({ addUserDetails });
  };

  handleToggleClick = (action) => {
    if (action === "Add Bot") {
      this.setState({ isAddBotDropdown: !this.state.isAddBotDropdown });
    } else {
      this.setState({ isShareDropdown: !this.state.isShareDropdown });
    }
  };

  handleAddShareBot = (action) => {
    if (action === "Add Bot") {
      let bots = {};
      this.state.addBotDropdownlist
        .filter((e) => e.isSelected)
        .map((e) => e.bot_id)
        .forEach((e) => (bots[e] = "write"));

      this.setState({ isAddBotDropdown: false }, () =>
        this.addShareBots({
          share_with_users: [this.state.selectedUser],
          bots_dict: bots,
        })
      );
    } else {
      let bots = {};
      this.state.selctedUserBots
        .filter((e) => e.isSelected)
        .map((e) => e.bot_id)
        .forEach((e) => (bots[e] = "read"));

      let users = this.state.shareDropdownList
        .filter((e) => e.isSelected)
        .map((e) => e.email);
      this.setState({ isShareDropdown: false }, () =>
        this.addShareBots({ share_with_users: users, bots_dict: bots })
      );
    }
  };

  handleAddShareDropdownClick = (menu, action) => {
    let { shareDropdownList, addBotDropdownlist } = this.state;
    if (action === "add") {
      let list = addBotDropdownlist.map((ele) => {
        ele.label === menu.label && (ele.isSelected = !ele.isSelected);
        return ele;
      });
      this.setState({ addBotDropdownlist: list });
    } else if (action === "share") {
      let list = shareDropdownList.map((ele) => {
        ele.first_name === menu.first_name &&
          ele.last_name === menu.last_name &&
          (ele.isSelected = !ele.isSelected);
        return ele;
      });
      this.setState({ shareDropdownList: list });
    }
  };

  handleAddShareSearch = ({ target }, action) => {
    if (action === "add") {
      let temp = this.state.addBotDropdownlistCopy.filter((bot) =>
        bot.bot_name.toLowerCase().includes(target.value)
      );
      this.setState({ addBotDropdownlist: temp });
    } else {
      let temp = this.state.shareDropdownListCopy.filter(
        (u) =>
          u.first_name.toLowerCase().includes(target.value) ||
          u.last_name.toLowerCase().includes(target.value)
      );
      this.setState({ shareDropdownList: temp });
    }
  };

  handleBotsPagination = (action) => {
    const { pageNo, size, count } = this.state;

    if (action === "next") {
      if ((pageNo ? pageNo : pageNo + 1) * size < count) {
        this.setState({ pageNo: pageNo + 1 }, () =>
          this.getUserBotsList(this.state.selectedUser)
        );
      }
    } else {
      if (pageNo >= 1) {
        this.setState({ pageNo: pageNo - 1 }, () =>
          this.getUserBotsList(this.state.selectedUser)
        );
      }
    }
  };

  getAddUserModal = () => {
    let { addUserDetails, addSuccessful } = this.state;
    return (
      <Modal
        maxwidth={addSuccessful ? "622px" : "707px"}
        title={addSuccessful ? "Account added successfully" : "Add User"}
        bodyClassName={"p-4"}
        headerClassName={"add-modal-header pb-3 px-4 border-bottom"}
        addNewModalOpen={this.state.addUserModal}
        closeIcon="close-small"
        toggle={() => this.setState({ addUserModal: !this.state.addUserModal })}
      >
        <div className="text-center">
          {addSuccessful ? (
            <>
              <div className="modal-msg-font mb-5 mt-4">
                Create Password link has been successfully sent to
                ************@gmail.com
              </div>
              <div className="d-flex justify-content-center">
                <Buttons
                  className={"add-user-confirm-btn"}
                  styles={{ width: "93px" }}
                  onClick={() =>
                    this.setState(
                      { addSuccessful: false, addUserModal: false },
                      () => this.getUsers()
                    )
                  }
                >
                  Ok
                </Buttons>
              </div>
            </>
          ) : (
            <>
              <div className="m-0 my-4">
                <div className="modal-label-font mb-3 align-items-center m-auto">
                  Name
                </div>
                <Input
                  name="name"
                  className="modal-inp px-3 w-100"
                  placeholder="Enter user name"
                  value={addUserDetails.name}
                  onChange={this.handleOnChange}
                />
              </div>
              <div className="m-0 mb-4">
                <div className="modal-label-font mb-3 align-items-center m-auto">
                  Email
                </div>
                <Input
                  name="email"
                  className="modal-inp px-3 w-100"
                  placeholder="Enter user email"
                  value={addUserDetails.email}
                  onChange={this.handleOnChange}
                />
              </div>
              <div className="m-0 mb-5">
                <div className="modal-label-font mb-3 align-items-center m-auto">
                  Phone No
                </div>
                <Input
                  name="phone"
                  className="modal-inp px-3 w-100"
                  placeholder="Enter user phone number"
                  value={addUserDetails.phone}
                  onChange={this.handleOnChange}
                />
              </div>
              <div className="row m-0 justify-content-between border-top">
                <Buttons
                  className={"col-auto add-user-cancel-btn mt-3"}
                  onClick={() =>
                    this.setState({
                      addUserModal: false,
                      addUserDetails: {
                        name: "",
                        email: "",
                        phone: "",
                      },
                    })
                  }
                >
                  Cancel
                </Buttons>
                <Buttons
                  className={"col-auto add-user-confirm-btn mt-3"}
                  onClick={() => {
                    let temp = {
                      first_name: addUserDetails.name.split(" ")[0],
                      last_name: addUserDetails.name.split(" ")[1],
                      email: addUserDetails.email,
                    };
                    this.handleAddUser(temp);
                  }}
                >
                  Done
                </Buttons>
              </div>
            </>
          )}
        </div>
      </Modal>
    );
  };

  getDeleteModal = () => {
    const {
      deleteUserModal,
      deleteBotModal,
      usersList,
      addSuccessful,
      deleteSingleUserModal,
      selctedUserBots,
    } = this.state;
    return (
      <Modal
        maxwidth={deleteBotModal ? "711px" : "494px"}
        noCloseBtn={!deleteBotModal}
        title={addSuccessful ? "Delete User" : "Delete Bot"}
        bodyClassName={`p-5 pb-4 ${deleteBotModal ? "pe-4" : ""}`}
        headerClassName={"add-modal-header pb-3 px-5 pe-3"}
        addNewModalOpen={
          deleteUserModal || deleteBotModal || deleteSingleUserModal
        }
        closeIcon="close-small"
        toggle={() => {
          let temp = selctedUserBots.map((e) => {
            e.isSelected = false;
            return e;
          });
          this.setState({
            selctedUserBots: temp,
            deleteBotModal: false,
          });
        }}
      >
        {deleteUserModal || deleteSingleUserModal ? (
          <>
            <div className="text-center del-modal-info mb-5">
              Are you sure you want to delete{" "}
              {deleteSingleUserModal
                ? "user?"
                : `${usersList.filter((e) => e.isSelected).length} users?`}
            </div>
            <div className="row m-0 justify-content-center">
              <Buttons
                className={"outline-btn mx-2"}
                styles={{ width: "126px", height: "46px" }}
                onClick={() => {
                  let temp = usersList.map((e) => {
                    e.isSelected = false;
                    return e;
                  });
                  this.setState({
                    usersList: temp,
                    deleteUserModal: false,
                    deleteSingleUserModal: false,
                  });
                }}
              >
                Cancel
              </Buttons>
              <Buttons
                className={"add-user-confirm-btn"}
                styles={{ width: "120px", borderRadius: "2px" }}
                onClick={() => {
                  this.setState({ deleteUserModal: false }, () => {
                    if (deleteSingleUserModal) {
                      this.handleDeleteUser({
                        email_id: this.state.selectedUser.email,
                      });
                    } else if (deleteUserModal) {
                      this.handleDeleteUser({
                        email_id: usersList
                          .filter((e) => e.isSelected)
                          .map((e) => e.email),
                      });
                    }
                  });
                }}
              >
                Delete
              </Buttons>
            </div>
          </>
        ) : deleteBotModal ? (
          <>
            <div className="row m-0 mb-5" style={{ height: "28rem" }}>
              <div className="col-6 h-100 p-0">
                <div className="d-flex mb-3 align-items-center">
                  <span className="del-modal-sub-header">Created by you</span>{" "}
                  <span className="ms-2 bot-count-cont">
                    {selctedUserBots.length}
                  </span>
                </div>
                <div
                  className="overflow-auto"
                  style={{ height: "calc(100% - 1.5rem)" }}
                >
                  <div className="row m-0">
                    {selctedUserBots.map((bot, i) => {
                      return (
                        <div className="col-12 d-flex mb-2 ps-0">
                          <span
                            className="me-2"
                            onClick={() => {
                              selctedUserBots[i].isSelected =
                                !selctedUserBots[i].isSelected;
                              this.setState({ selctedUserBots });
                            }}
                          >
                            {svgIcons(
                              bot.isSelected
                                ? "new-filled-check-box"
                                : "new-blank-check-box"
                            )}
                          </span>
                          <div
                            style={{ width: "calc(100% - 2rem)" }}
                            className={`${
                              bot.isSelected && "selected-bg"
                            } bot-card row m-0`}
                          >
                            <div className="col-auto align-items-center d-flex pe-0">
                              <img
                                style={{ width: "40px", height: "40px" }}
                                src={getImageUrl(bot.bot_icon)}
                              />
                            </div>
                            <div className="col align-items-center m-auto">
                              <p
                                style={{ width: "125px" }}
                                className="mb-0 truncate-text"
                              >
                                {bot.bot_name}
                              </p>
                              <span
                                style={{ width: "125px" }}
                                className="truncate-text"
                              >
                                {bot.bot_name}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-0 justify-content-between">
              <div className="col-auto font-nm-12 d-flex align-items-center">
                Note : Do you want to proceed delete and remove operation for
                above bots ?{" "}
              </div>
              <Buttons
                className={"add-user-confirm-btn col-auto"}
                onClick={() => {
                  this.setState({ deleteBotModal: false }, () =>
                    this.handleDeleteUserBots({
                      bots_list: selctedUserBots
                        .filter((e) => e.isSelected)
                        .map((e) => e.bot_id),
                    })
                  );
                }}
              >
                Done
              </Buttons>
            </div>
          </>
        ) : (
          <></>
        )}
      </Modal>
    );
  };

  getAddShareBotModal = () => {
    const {
      isShareDropdown,
      isAddBotDropdown,
      addBotDropdownlist,
      shareDropdownList,
    } = this.state;
    return (
      <Modal
        maxwidth={"711px"}
        title={isShareDropdown ? "Share Bot" : "Add Bots"}
        bodyClassName={"p-4"}
        headerClassName={"add-modal-header pb-3 px-4 pe-3"}
        addNewModalOpen={isShareDropdown || isAddBotDropdown}
        closeIcon="close-small"
        toggle={() =>
          this.setState({ isShareDropdown: false, isAddBotDropdown: false })
        }
      >
        <>
          <div className="mb-3">
            {svgIcons("search", "", "add-bot-modal-inp-icn-pos")}
            <Input
              className="w-100 add-bot-modal-inp"
              placeholder="Search"
              onChange={(e) =>
                this.handleAddShareSearch(e, isAddBotDropdown ? "add" : "share")
              }
            />
          </div>
          <div className="w-100 overflow-auto" style={{ height: "35rem" }}>
            {isAddBotDropdown
              ? addBotDropdownlist.map((bot) => {
                  return (
                    <div className="row m-0  align-items-center py-3 border-bottom">
                      <span
                        className="col-auto cursor-pointer"
                        onClick={() =>
                          this.handleAddShareDropdownClick(bot, "add")
                        }
                      >
                        {svgIcons(
                          bot.isSelected
                            ? "new-filled-check-box"
                            : "new-blank-check-box"
                        )}
                      </span>
                      <div className="col-auto m-auto align-items-center rounded-circle">
                        <img
                          className="rounded-circle"
                          style={{ width: "40px", height: "40px" }}
                          src={getImageUrl(bot.bot_icon)}
                        />
                      </div>
                      <div className="col">
                        <p className="mb-0 add-bot-name">{bot.bot_name}</p>
                        <span className="add-bot-industry">
                          {bot.bot_industry}
                        </span>
                      </div>
                    </div>
                  );
                })
              : shareDropdownList.map((u) => {
                  return (
                    <div className="row m-0 align-items-center py-3 border-bottom">
                      <span
                        className="col-auto cursor-pointer"
                        onClick={() =>
                          this.handleAddShareDropdownClick(u, "share")
                        }
                      >
                        {svgIcons(
                          u.isSelected
                            ? "new-filled-check-box"
                            : "new-blank-check-box"
                        )}
                      </span>
                      <div
                        style={{ width: "32px", height: "32px" }}
                        className="col-auto ms-2 navbar-profile-icon-container align-self-center"
                      >
                        {" "}
                        {/* <img
                          className="rounded-circle"
                          style={{ width: "40px", height: "40px" }}
                          // src={getImageUrl(bot.bot_icon)}
                        /> */}
                        <i className="fas fa-user" />
                      </div>
                      <div className="col">
                        <p className="mb-0 add-bot-name align-self-center">
                          {u.first_name + " " + u.last_name}
                        </p>
                      </div>
                      <div className="col-auto justify-self-end">
                        <DropDown
                          isContent={true}
                          btnClassName={""}
                          actionButton={() => (
                            <span className="read-write-btn">
                              {u.permit || "Read/Write"}
                            </span>
                          )}
                          dropdownList={[
                            { label: "Read" },
                            { label: "Write", isUsed: true },
                          ]}
                          onActionItemClick={(m) => {
                            u.permit = m.label;
                            this.setState({ shareDropdownList });
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
          </div>
          <div
            className="d-flex justify-content-end"
            style={{ height: "40px" }}
          >
            <Buttons
              className={"add-user-confirm-btn"}
              onClick={() => {
                this.setState(
                  { isAddBotDropdown: false, isShareDropdown: false },
                  () =>
                    this.handleAddShareBot(
                      isAddBotDropdown ? "Add Bot" : "share"
                    )
                );
              }}
            >
              Done
            </Buttons>
          </div>
        </>
      </Modal>
    );
  };

  noUserDetailDisplay = () => {
    return (
      <div className="align-items-center justify-content-center d-flex h-100">
        <div className="no-user-cont d-flex flex-column align-items-center">
          <div>{svgIcons("new-bot-group")}</div>
          <p className="no-user-info m-0">Bots and User details</p>
          <p className="no-user-small-text">
            select one user from left panel to view user and bot details
          </p>
        </div>
      </div>
    );
  };

  userDetailDisplay = () => {
    const {
      isEditUserBots,
      selctedUserBots,
      selectedUser,
      adminUserDetails,
      pageNo,
      size,
      count,
      botSearchBarShow,
    } = this.state;
    return (
      <>
        <div className="org-user-detail-card my-4 mx-4 p-4 pb-3">
          <div className="row m-0 justify-content-between mb-4">
            <div className="org-user-detail-card-header col-auto d-flex align-items-center">
              User details
            </div>
            <div className="col-auto row m-0 pe-0">
              <div className="col-auto align-items-center d-flex">
                <span className="me-3 font-md-18">Enable User</span>
                <Toggle
                  checked={selectedUser.active}
                  onChange={() =>
                    selectedUser.active
                      ? this.enableDisableUser("disable", {
                          org_admin_email: adminUserDetails.email,
                          user_email: selectedUser.email,
                        })
                      : this.enableDisableUser("enable", {
                          org_admin_email: adminUserDetails.email,
                          user_email: selectedUser.email,
                        })
                  }
                />
              </div>
              <div className="col-auto px-0">
                <DropDown
                  btnClassName="pe-0"
                  actionButton={() => svgIcons("verticalOptions")}
                  dropdownList={[{ label: "Delete", icon: svgIcons("delete") }]}
                  onActionItemClick={(m) =>
                    this.setState({ deleteSingleUserModal: true })
                  }
                  isIntentModal={this.getDeleteModal}
                />
              </div>
            </div>
          </div>

          <div className="row m-0 font-1421">
            <div className="col" style={{ borderRight: "1px solid #70707034" }}>
              <div className="d-flex">
                <span className="me-2 font-1631">
                  {selectedUser.first_name + " " + selectedUser.last_name}
                </span>
                <div className="org-set-user-status-cont px-2">
                  {[null, true, "none"].includes(selectedUser.active)
                    ? "Active"
                    : "Deactive"}
                </div>
              </div>
              <span className="col font-1631" style={{ color: " #8F8F8F" }}>
                {selectedUser.email}
              </span>
            </div>

            <div
              className="col align-items-center m-auto"
              style={{ borderRight: "1px solid #70707034" }}
            >
              <div className="row m-0 mb-2">
                <label className="col-auto">Contact</label>
                <span className="col">{selectedUser.contact}</span>
              </div>
              <div className="row m-0">
                <label className="col-auto">User Since</label>
                <span className="col">
                  {selectedUser.created_on
                    ? selectedUser.created_on.split(" ")[0]
                    : ""}
                </span>
              </div>
            </div>

            <div
              className="col align-items-center m-auto"
              style={{ borderRight: "1px solid #70707034" }}
            >
              <div className="row m-0 mb-2">
                <label className="col-auto">Total bots</label>
                <span className="col">
                  {selectedUser.shared_user_count +
                    selectedUser.owner_user_count}
                </span>
              </div>
              <div className="row m-0">
                <label className="col-auto">Created bots</label>
                <span className="col">{selectedUser.owner_user_count}</span>
              </div>
            </div>

            <div className="col align-items-center m-auto">
              <div className="row m-0 mb-2">
                <label className="col-auto">Shared bots</label>
                <span className="col">{selectedUser.shared_user_count}</span>
              </div>
              <div className="row m-0">
                <label className="col-auto">Bots shared to</label>
                <span className="col">{""}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="row m-0 mt-3 mb-5 px-4 justify-content-between">
          <div className="col-auto d-flex align-items-center">
            <div className="me-1 align-items-start m-auto">
              <DropDown
                actionButton={() => svgIcons("new-check-box-arrow")}
                btnClassName="p-0 m-0 caret-icon mb-1"
                dropdownList={[
                  { label: "Select All" },
                  { label: "Select Specific" },
                  { label: "Clear" },
                ]}
                onActionItemClick={({ label }) => {
                  if (label === "Clear") {
                    this.setState({
                      selctedUserBots: selctedUserBots.map((bot) => {
                        bot.isSelected = false;
                        return bot;
                      }),
                      isEditUserBots: false,
                    });
                  } else if (label === "Select All") {
                    this.setState({
                      selctedUserBots: selctedUserBots.map((bot) => {
                        bot.isSelected = true;
                        return bot;
                      }),
                      isEditUserBots: true,
                    });
                  } else {
                    this.setState({ isEditUserBots: true });
                  }
                }}
              />
            </div>
            <span className="org-user-detail-card-header me-2">Bot list</span>
            <span className="bot-count-cont">{count}</span>
          </div>
          <div className="col-auto d-flex">
            <div
              className="mx-1 align-items-center m-auto"
              onClick={() => this.setState({ botSearchBarShow: true })}
            >
              {botSearchBarShow ? (
                <>
                  {svgIcons("search", "", "user-search-icn-pos")}
                  <Input
                    style={{ width: "175px" }}
                    className="org-set-search-inp"
                    placeholder="Search"
                  />
                </>
              ) : (
                svgIcons("search-blue")
              )}
            </div>
            <DropDown
              btnClassName={`px-3 ${
                !selctedUserBots.some((e) => e.isSelected) && "pe-4"
              }`}
              actionButton={() => svgIcons("filter-blue")}
            />
            {selctedUserBots.some((e) => e.isSelected) ? (
              <>
                <Buttons onClick={() => this.handleToggleClick("Share Bot")}>
                  {svgIcons("share_icon")}
                </Buttons>
                <Buttons
                  onClick={() => this.setState({ deleteBotModal: true })}
                >
                  {svgIcons("delete", "red")}
                </Buttons>
                {this.state.deleteBotModal &&
                  this.getDeleteModal("delete_bots")}
              </>
            ) : (
              <>
                <Buttons
                  className={"org-set-add-user-btn"}
                  onClick={() => this.handleToggleClick("Add Bot")}
                >
                  <span className="me-1">{svgIcons("user-plus")}</span> Add Bot
                </Buttons>
              </>
            )}
            {(this.state.isAddBotDropdown || this.state.isShareDropdown) &&
              this.getAddShareBotModal()}
          </div>
        </div>

        <div
          className="row m-0 overflow-auto px-4"
          style={{ maxHeight: "calc(100% - 350px)" }}
        >
          {selctedUserBots.map((bot, i) => {
            return (
              <div className="col-auto d-flex mb-4" style={{ width: "312px" }}>
                {isEditUserBots && (
                  <span
                    className={`me-2 cursor-pointer`}
                    onClick={() => {
                      selctedUserBots[i].isSelected =
                        !selctedUserBots[i].isSelected;
                      this.setState({ selctedUserBots });
                    }}
                  >
                    {svgIcons(
                      bot.isSelected
                        ? "new-filled-check-box"
                        : "new-blank-check-box"
                    )}
                  </span>
                )}
                <div
                  style={{ width: "288px" }}
                  className={`${
                    bot.isSelected && "selected-bg"
                  } bot-card row m-0`}
                >
                  <div className="col-auto m-auto align-items-center rounded-circle">
                    <img
                      className="rounded-circle"
                      style={{ width: "42px", height: "42px" }}
                      src={getImageUrl(bot.bot_icon)}
                    />
                  </div>
                  <div className="col align-items-center m-auto">
                    <p className="mb-0">{bot.bot_name}</p>
                    <span>{bot.bot_name}</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {/* <div className="w-100 align-items-center d-flex justify-content-center pagination-cont"> */}
        <div className="d-flex align-items-center justify-content-center pagination-cont">
          <i
            className={`fas fa-angle-left mx-2 col-auto fs-5 me-2 ${
              pageNo >= 1 ? "cursor-pointer" : "text-muted"
            }`}
            onClick={() => this.handleBotsPagination("back")}
          />
          <span className="intent-pagination-text px-3">
            {" "}
            {pageNo === 0 ? 1 : pageNo * size} -{" "}
            {pageNo * size + selctedUserBots?.length} of {count}{" "}
          </span>
          <i
            className={`fas fa-angle-right mx-2 col-auto fs-5 ms-2 ${
              (pageNo ? pageNo : pageNo + 1) * size < count
                ? "cursor-pointer"
                : "text-muted"
            }`}
            onClick={() => this.handleBotsPagination("next")}
          />
        </div>
        {/* </div> */}
      </>
    );
  };

  renderLeftPanel = () => {
    let {
      usersList,
      usersListCopy,
      isSelectSpecificUser,
      selectedUser,
      adminUserDetails,
      adminBotsList,
      searchBarShow,
    } = this.state;

    return (
      <>
        <div className="row m-0 justify-content-between mt-4   mb-3">
          <div className="col-auto">
            <div className="d-flex mb-1 align-items-center">
              <strong className="me-2 org-set-font">
                {adminUserDetails
                  ? adminUserDetails.first_name +
                    " " +
                    adminUserDetails.last_name
                  : ""}
              </strong>
              <div className="bot-count-cont ms-1">
                {adminBotsList.length} bots
              </div>
            </div>
            <div className="org-set-email-font">
              {adminUserDetails ? adminUserDetails.email : ""}
            </div>
          </div>
          <div className="col-auto">
            <Buttons
              className="org-set-add-user-btn"
              onClick={() => this.setState({ addUserModal: true })}
            >
              {svgIcons("user-plus")}
              <span className="ms-2">Add User</span>
            </Buttons>
            {this.getAddUserModal()}
          </div>
        </div>

        <div className="row m-0 justify-content-between py-3 bor-btm bor-top">
          <div className="col-auto d-flex align-items-center">
            <div className="me-1 mb-1">
              <DropDown
                actionButton={() => svgIcons("new-check-box-arrow")}
                btnClassName="p-0 m-0 caret-icon"
                dropdownList={[
                  { label: "Select All" },
                  { label: "Select Specific" },
                  { label: "Clear" },
                ]}
                onActionItemClick={(m) => {
                  if (m.label === "Select Specific") {
                    this.setState({ isSelectSpecificUser: true });
                  } else if (m.label === "Select All") {
                    this.setState({
                      usersList: usersList.map((ele) => {
                        ele.isSelected = true;
                        return ele;
                      }),
                      isSelectSpecificUser: true,
                    });
                  } else {
                    this.setState({
                      usersList: usersList.map((ele) => {
                        ele.isSelected = false;
                        return ele;
                      }),
                      isSelectSpecificUser: false,
                    });
                  }
                }}
              />
            </div>
            <div className="">
              <span className="org-set-user-head me-2">Users</span>
              <span className="font-1527 ms-1">
                <i className="fa fa-user me-1" />
                {usersList.length}
              </span>
            </div>
          </div>
          <div className="col-auto d-flex align-items-center cursor-pointer pe-2">
            {!searchBarShow && (
              <span
                onClick={() => this.setState({ searchBarShow: !searchBarShow })}
              >
                {svgIcons("search-blue")}
              </span>
            )}

            <DropDown
              btnClassName="pe-0"
              actionButton={() => svgIcons("filter-blue")}
            />

            <DropDown
              actionButton={() => svgIcons("verticalOptions", "#0D2981")}
              dropdownList={[
                {
                  label: "Delete",
                  importModal: this.getDeleteModal(),
                  isUsed: !usersList.some((ele) => ele.isSelected),
                },
              ]}
              onActionItemClick={() => this.setState({ deleteUserModal: true })}
            />
          </div>
          {searchBarShow && (
            <div className="org-search-cont my-3 col-12">
              {svgIcons("search", "", "seacrh-icn-position")}
              <Input
                className="w-100 org-set-search-inp"
                placeholder="Search"
                value={""}
                onChange={({ target }) => {
                  let temp = usersListCopy.filter(
                    (ele) =>
                      ele.email.includes(target.value) ||
                      ele.first_name.includes(ele) ||
                      ele.last_name.includes(ele)
                  );
                  this.setState({ usersList: temp });
                }}
              />
              <span
                onClick={() => this.setState({ searchBarShow: !searchBarShow })}
              >
                {svgIcons("close", "", "seacrh-cancel-position")}
              </span>
            </div>
          )}
        </div>

        <div className="overflow-auto" style={{ height: "calc(100% - 14rem)" }}>
          <div className="row m-0 overflow-auto">
            {usersList.map((u, i) => {
              return (
                <div
                  className={`${
                    u.isSelected ||
                    (selectedUser &&
                      u.email === selectedUser.email &&
                      "user-selected")
                  } col-12 row m-0 py-3 ps-0 cursor-pointer bor-btm
                  `}
                  key={i}
                  onClick={() => {
                    this.setState({ selectedUser: u }, () =>
                      this.getUserBotsList(u)
                    );
                  }}
                >
                  {isSelectSpecificUser ? (
                    <div
                      className={`col-auto ${
                        isSelectSpecificUser && "cursor-pointer"
                      }`}
                      onClick={() => {
                        usersList[i].isSelected = !usersList[i].isSelected;
                        this.setState({ usersList });
                      }}
                    >
                      {svgIcons(
                        u.isSelected
                          ? "new-filled-check-box"
                          : "new-blank-check-box"
                      )}
                    </div>
                  ) : (
                    <div
                      style={{ width: "32px", height: "32px" }}
                      className="col-auto ms-2 navbar-profile-icon-container align-self-center"
                    >
                      {/* {getUserDetails() ? (
                          <img
                            alt=""
                            style={{ width: "1.5rem", height: "1.5rem" }}
                            src={
                              getUserDetails().profile_pic
                                ? getImageUrl(getUserDetails().profile_pic)
                                : userLogo
                            }
                          />
                        ) : ( */}
                      <i className="fas fa-user" />
                      {/* )} */}
                    </div>
                  )}
                  <div className="col">
                    <p
                      className="mb-1 font-md-1624"
                      style={{ color: "#000000BE" }}
                    >
                      {u.first_name} {u.last_name}
                    </p>
                    <p className="m-0 font-nm-14" style={{ color: "#767676" }}>
                      {u.email}
                    </p>
                  </div>
                  <div
                    className="col-auto text-center font-nm-12"
                    style={{ color: "#9C9C9C" }}
                  >
                    {u.date}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  };

  render() {
    const { selectedUser } = this.state;
    return (
      <div className="row m-0 h-100 right-panel">
        <div className="col left-panel mt-1 px-3" style={{ maxWidth: "27%" }}>
          {this.renderLeftPanel()}
        </div>

        <div className="col h-100" style={{ background: "#F8F8F8" }}>
          {selectedUser ? this.userDetailDisplay() : this.noUserDetailDisplay()}
        </div>
      </div>
    );
  }
}

export default function OrganisationSettings() {
  const { getUserDetails } = useContext(UserContext);

  return <OrganisationSettings_ getAdminUserDetails={getUserDetails} />;
}
