import React, { Component, useContext } from "react";
import BotLayoutContent from "../../components/botLayout/botLayoutContent.component";
import BotLayoutLeftPanel from "../../components/botLayout/botLayoutLeftPanel.component";
import BotLayoutRightPanel from "../../components/botLayout/botLayoutRightPanel.component";
import actionServices from "../../services/api.services/actionsApi.service";
import svgIcons from "../../services/icon.service";
import Input from "../../components/fields/input/input.component";
import DropDown from "../../components/fields/dropDown/dropDown.component";
import "./actions.scss";

import botService from "../../services/bot.service";
import faqServices from "../../services/api.services/faqsApi.service";
import { NotificationContext } from "../../contexts/notficationContext";
import { UserContext } from "../../contexts/userContext";

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-xcode";
import "ace-builds/src-noconflict/ext-language_tools";
import DeleteConfirmation from "../../components/deleteConfirmation.component";
import Buttons from "../../components/fields/button/button.component";
import TrainLog from "../../components/trainingLog.component";

class _Actions extends Component {
  static contextType = NotificationContext;
  constructor(props) {
    super(props);
    this.state = {
      addNewActions: "",
      actions: [],
      actionDetails: [],
      addNewAction: [],
      actionNewName: "",
      actionOldName: "",
      oldCode: "",
      modal: false,
      codeError: [],
      errorMarker: [],
      actionLogs: false,
      actionLogData: null,
    };
  }

  componentDidMount() {
    this.getBotActions();
    this.getBotFaqs();
  }

  getBotActions = async () => {
    try {
      const r = await actionServices.getBotActions();

      if (r.status === "success") {
        const actions = r.data.map((d) => ({ label: d, isSelected: false }));

        return this.setState({ actions, trainedActions: r.trained });
      }
    } catch (e) {
      console.log("Failed: ", e);
    }
  };

  getBotFaqs = async () => {
    try {
      const r = await faqServices.getBotFaqs();

      if (r.status === "success") {
        const faqsList = r.data;
        this.setState({ faqsList });
      }
    } catch (e) {
      console.log(e);
    }
  };

  getActionLogs = async () => {
    try {
      const r = await actionServices.getActionLogs();

      if (r) this.setState({ actionLogData: r });
    } catch (e) {
      console.log("Failed: ", e.message);
    }
  };

  getBotActionDetails(action) {
    actionServices.getActionDetails(action).then((res) => {
      if (res.status === "success") {
        if (!!res.data.length)
          this.setState(
            {
              actionDetails: res.data,
              trainedActionDetails: res.trained,
            },
            () =>
              res.data[0].type === "code" &&
              this.validateActionCode({ ...res.data[0], action: "valdiate" })
          );
        else this.getBotActionDetails(action);
      }
    });
  }

  validateActionCode = (code) => {
    actionServices.validateCodeSyntax(code).then((res) => {
      if (res.status === "success") {
        let temp = res.data.errors.map((e) => {
          return {
            startRow: e.lineno,
            endRow: e.lineno,
            startCol: 0,
            endCol: 100,
            className: e.type === "W" ? "error-marker2" : "error-marker",
            type: "background",
          };
        });
        let temp2 = res.data.errors.map((e) => {
          return {
            row: e.lineno,
            col: e.charno,
            text: e.message,
            type: e.type === "W" ? "warning" : "error",
          };
        });
        this.setState({ codeError: temp2, errorMarker: temp });
      }
    });
  };

  handleActionRename = (e, type) => {
    let { actionNewName, actionOldName, selectedAction, faqsList, actions } =
      this.state;

    if (!actionOldName) {
      actionOldName = selectedAction;
    }
    this.setState({ actionNewName: e.target.value, actionOldName });
    if (e.key === "Enter" || type) {
      if (actionOldName && actionNewName && actionNewName !== actionOldName) {
        if (
          faqsList.find((e) => e.label === actionNewName) ||
          actions.find((e) => e.label === actionNewName)
        ) {
          this.context.setNotifications({
            color: "black",
            message: "This action already exists",
          });
        } else {
          if (/^[A-Za-z][A-Za-z0-9_]+$/.test(actionNewName)) {
            this.toggleSave("_rename", actionOldName, actionNewName);
            this.setState({
              actionOldName: "",
              selectedAction: actionNewName,
            });
          }
        }
      }
    }
  };

  handleAddNewHeader = (e) => {
    let { addNewKey, addNewValue, actionDetails, selectedAction, oldIndex } =
      this.state;
    if (!oldIndex) {
      oldIndex = Object.keys(actionDetails[0].headers).length;
      this.setState({ oldIndex });
    }

    if (e.key === "Enter") {
      actionDetails[0].headers[`${addNewKey}`] = addNewValue;
      this.setState({ actionDetails, addNewValue: "", addNewKey: "" });
      if (
        oldIndex &&
        Object.keys(actionDetails[0].headers).length !== oldIndex
      ) {
        this.toggleSave("_update", selectedAction, actionDetails);
      }
    }
  };

  getDeleteModal = (action, i) => {
    let { selectedAction, actionDetails } = this.state;
    return (
      <DeleteConfirmation
        open={this.state.modal}
        delete={() => {
          if (action === "header") {
            delete actionDetails[0].headers[i];
            this.setState({ actionDetails }, () => {
              this.toggleSave("_update", selectedAction, actionDetails);
            });
          } else {
            this.toggleSave("_delete", selectedAction);
          }
        }}
      />
    );
  };

  getValidateCodeBtn = () => {
    return this.state.actionDetails[0]?.type &&
      this.state.actionDetails[0].type === "code" ? (
      <Buttons
        className={"intents-btn rounded"}
        onClick={() => {
          this.validateActionCode({
            ...this.state.actionDetails[0],
            action: "validate",
          });
        }}
      >
        Validate
      </Buttons>
    ) : (
      <></>
    );
  };

  getActionLogBtn = () => {
    return (
      <Buttons
        className={"intents-btn rounded"}
        onClick={() => {
          this.getActionLogs();
          this.setState({ actionLogs: true });
        }}
      >
        Action logs
      </Buttons>
    );
  };

  renderBotActions() {
    let {
      actionDetails,
      selectedAction,
      addNewKey,
      addNewValue,
      oldCode,
      codeError,
      errorMarker,
    } = this.state;
    return (
      actionDetails &&
      actionDetails.map((action, i) => {
        return action && action.type === "code" ? (
          <div className="h-75 px-4" key={i}>
            <AceEditor
              mode="python"
              theme="xcode"
              readOnly={this.props.permissions.actions.modify !== "enabled"}
              name={selectedAction}
              value={action.code}
              height="700px"
              width="100%"
              fontSize={20}
              onChange={(e) => {
                if (!oldCode) {
                  oldCode = action.code;
                }
                action.code = e;
                this.setState({ actionDetails, oldCode });
              }}
              onBlur={() => {
                if (oldCode && action.code !== oldCode) {
                  this.validateActionCode({ ...action, action: "validate" });
                  this.toggleSave("_update", selectedAction, actionDetails);
                  this.setState({ oldCode: "" });
                }
              }}
              showPrintMargin={true}
              showGutter={true}
              highlightActiveLine={true}
              setOptions={{
                enableBasicAutocompletion: false,
                enableLiveAutocompletion: false,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 2,
              }}
              annotations={codeError}
              markers={errorMarker}
            />
          </div>
        ) : (
          <div key={i}>
            <div className="row m-0 p-0 align-items-center mb-5">
              <div className="col-2 pe-4">
                <strong className="font">URL</strong>
              </div>
              <div className="col d-flex" style={{ flexDirection: "column" }}>
                <Input
                  className="action-input px-3"
                  placeholder="Enter URL for the action..."
                  readOnly={this.props.permissions.actions.modify !== "enabled"}
                  value={action.url}
                  onChange={(e) => {
                    actionDetails[i].url = e.target.value;
                    this.setState({ actionDetails });
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      /[Hh][Tt][Tt][Pp][Ss]?:\/\/(?:(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]+-?)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:(\.(?:[a-zA-Z\u00a1-\uffff]{2,}))|(:[0-9]{3,4}))(?::\d{2,5})?(?:\/[^\s]*)+(?:\.[a-z]+)*(?::\d+)?(?![^<]*(?:<\/\w+>|\/?>))?/.test(
                        action.url
                      )
                        ? this.setState({ error: false }, () =>
                            this.toggleSave(
                              "_update",
                              selectedAction,
                              actionDetails
                            )
                          )
                        : this.setState({ error: true });
                    }
                  }}
                  // onFocus={() => this.setState({ error: false })}
                />
                {this.state.error && (
                  <small className="text-danger mt-2">
                    * Enter a valid url
                  </small>
                )}
              </div>
            </div>

            {/*    headers  */}

            <div className="mb-4">
              <p className="px-2 font" style={{ color: " #979797" }}>
                Header
              </p>
              <div className="row m-0 p-0 align-items-center mb-4">
                <div className="col-2 pe-4">
                  <strong className="font">Key</strong>
                </div>
                <div className="col">
                  <Input
                    className="action-input px-3"
                    placeholder="Enter Header value.."
                    readOnly={
                      this.props.permissions.actions.modify !== "enabled"
                    }
                    value={addNewKey}
                    onChange={(e) => {
                      addNewKey = e.target.value;
                      this.setState({ addNewKey });
                    }}
                    onKeyPress={(e) => {
                      e.key === "Enter" && document.getElementById(i).focus();
                    }}
                  />
                </div>
              </div>
              <div className="row m-0 p-0 align-items-center">
                <div className="col-2">
                  <strong className="font">Value</strong>
                </div>
                <div className="col">
                  <Input
                    id={i}
                    className="action-input px-3"
                    placeholder="Enter Key value..."
                    value={addNewValue}
                    readOnly={
                      this.props.permissions.actions.modify !== "enabled"
                    }
                    onChange={(e) => {
                      addNewValue = e.target.value;
                      this.setState({ addNewValue });
                    }}
                    onKeyPress={(e) => {
                      this.handleAddNewHeader(e);
                    }}
                  />
                </div>
              </div>
            </div>

            {/* token container */}
            {action.headers &&
              Object.keys(action.headers).length > 0 &&
              Object.keys(action.headers)
                .reverse()
                .map((a, ix) => {
                  return (
                    <div className="mb-3">
                      <div className="row p-2 m-0 ms-2 token-cont token rounded">
                        <div className="col" key={ix}>
                          <div className="row m-0 p-0 py-2 align-items-center">
                            <div className="col-auto" style={{ width: "6rem" }}>
                              <span
                                className="text-center font"
                                style={{ color: "#737373" }}
                              >
                                Key
                              </span>
                            </div>
                            <div className="col">
                              <Input
                                readOnly
                                className="token-cont-inp p-3"
                                value={a}
                              />
                            </div>
                          </div>
                          <div className="row m-0 p-0 align-items-center">
                            <div className="col-auto" style={{ width: "6rem" }}>
                              <span
                                className="text-center font"
                                style={{ color: "#737373" }}
                              >
                                Value
                              </span>
                            </div>
                            <div className="col">
                              <Input
                                readOnly
                                className="token-cont-inp p-3"
                                value={action.headers[a]}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-auto p-0">
                          <DropDown
                            isContent
                            actionButton={() => svgIcons("verticalOptions")}
                            dropdownList={
                              a !== "token"
                                ? [
                                    {
                                      label: "Copy",
                                      icon: svgIcons("copy"),
                                      isUsed:
                                        this.props.permissions.actions
                                          .modify !== "enabled",
                                    },
                                    {
                                      label: "Delete",
                                      icon: this.getDeleteModal("header", a),
                                      isUsed:
                                        this.props.permissions.actions
                                          .modify !== "enabled",
                                    },
                                  ]
                                : [
                                    {
                                      label: "Copy",
                                      icon: svgIcons("copy"),
                                      isUsed:
                                        this.props.permissions.actions
                                          .modify !== "enabled",
                                    },
                                  ]
                            }
                            onActionItemClick={(menu) => {
                              if (menu.label === "Copy") {
                                navigator.clipboard.writeText(
                                  a + " : " + action.headers[a]
                                );
                                this.setState({ toolTip: true });
                              } else {
                                this.setState({ modal: true });
                                setTimeout(() => {
                                  this.setState({ modal: false });
                                }, 100);
                              }
                            }}
                          />
                        </div>
                      </div>
                      {ix === Object.keys(action.headers).length - 1 && (
                        <small className="text-muted px-4 mb-2 info-font">
                          * Use this token to verify the call was made by bot.
                        </small>
                      )}
                    </div>
                  );
                })}
          </div>
        );
      })
    );
  }

  toggleField = (menu) => {
    let random = Date.now();

    if (menu.label === "Code") {
      let payload = {
        action: `untitled_${random}`,
        data: [
          {
            type: "code",
            code:
              `def untitled_${random}` +
              "(bot_details):\n" +
              '    """\n' +
              "    Note: action name and the function name should match\n" +
              "    Mandtory: response_object has to be returned. \n" +
              '    """\n' +
              "    # Response object - it can be used to send back response messages and dialog instances\n" +
              "    response_object = Response()\n" +
              '    response_object.add_response(text="Sending Sample message")\n' +
              "    # response object has to be returned\n" +
              "    return response_object",
          },
        ],
      };
      actionServices
        .addBotAction(payload)
        .then((res) => {
          if (res.status === "success") {
            this.context.setNotifications({
              color: "success",
              message: res.message,
            });
            this.onPageRefresh(() => {
              this.getBotActionDetails(payload.action);
              this.setState({
                selectedAction: payload.action,
                actionNewName: payload.action,
              });
            });
          } else {
            this.context.setNotifications({
              color: "danger",
              message: res.message,
            });
          }
        })
        .catch((err) =>
          this.context.setNotifications({
            color: "danger",
            message: err.message,
          })
        );
    } else {
      let payload = {
        action: `untitled_${random}`,
        data: [{ url: "", headers: { token: botService.getSelectedBotId() } }],
      };
      actionServices
        .addBotAction(payload)
        .then((res) => {
          if (res.status === "success") {
            this.context.setNotifications({
              color: "success",
              message: res.message,
            });
            this.onPageRefresh(() => {
              this.getBotActionDetails(payload.action);
              this.setState({
                selectedAction: payload.action,
                actionNewName: payload.action,
              });
            });
          } else {
            this.context.setNotifications({
              color: "danger",
              message: res.message,
            });
          }
        })
        .catch((err) =>
          this.context.setNotifications({
            color: "danger",
            message: err.message,
          })
        );
    }
  };

  toggleSave = (action, index, newName) => {
    let { actions } = this.state;

    if (action === "_delete") {
      let temp = actions.filter((item) => item !== index);
      this.setState({ selectedAction: "", actions: temp }, () => {
        actionServices
          .deleteBotAction({ action: index })
          .then((res) => {
            if (
              res.status === "success" &&
              Object.keys(res.failed || {}).length === 0
            ) {
              this.context.setNotifications({
                color: "success",
                message: res.message,
              });
            } else {
              this.context.setNotifications({
                color: "danger",
                message:
                  Object.keys(res.failed || {}).length === 0
                    ? res.message
                    : res.failed[Object.keys(res.failed)[0]],
              });
            }
          })
          .catch((err) =>
            this.context.setNotifications({
              color: "danger",
              message: err.message,
            })
          );
        this.onPageRefresh();
      });
    } else if (action === "_update") {
      actionServices
        .updateBotAction({ action: index, data: newName })
        .then((res) => console.log(res))
        .catch((err) =>
          this.context.setNotifications({
            color: "danger",
            message: err.message,
          })
        );
    } else if (action === "_rename") {
      actionServices
        .renameBotAction({ old_name: index, new_name: newName })
        .then((res) => {
          if (res.status === "success") {
            this.context.setNotifications({
              color: "success",
              message: res.message,
            });
            window.location.reload();
            this.getBotActions();
          } else {
            this.context.setNotifications({
              color: "danger",
              message: res.message,
            });
          }
        })
        .catch((err) =>
          this.context.setNotifications({
            color: "danger",
            message: err.message,
          })
        );
      // this.onPageRefresh();
    } else {
      return null;
    }
  };

  onPageRefresh(callback) {
    this.setState(
      {
        faqsList: [],
        actions: [],
        trainedActions: [],
        selectedAction: undefined,
        addNewPhrase: "",
        actionNewName: "",
        actionOldName: "",
        modal: false,
      },
      () => {
        setTimeout(() => {
          this.getBotActions();
          callback && callback();
        }, 100);
      }
    );
  }

  renderActionLogs() {
    return (
      <div className="p-0 action-log">
        <TrainLog
          title={"Action logs"}
          data={this.state.actionLogData}
          toggleClose={() =>
            this.setState({ actionLogs: false, actionLogData: null })
          }
        />
      </div>
    );
  }

  render() {
    let {
      actions,
      trainedActions,
      selectedAction,
      addNewPhrase,
      actionNewName,
      actionOldName,
    } = this.state;

    return (
      <div className="container-fluid h-100">
        <div className="row h-100">
          <BotLayoutLeftPanel
            sideBarList={actions}
            title="Actions"
            trainedIntent={trainedActions}
            onMenuSelect={({ label }) => {
              if (this.props.permissions.actions.list === "enabled") {
                const localActions = actions.map((a) => {
                  a.isSelected = a.label === label;
                  return a;
                });

                this.setState(
                  {
                    actions: localActions,
                    selectedAction: label,
                    actionNewName: label,
                    actionDetails: [],
                  },
                  () =>
                    this.props.permissions.actions.content === "enabled" &&
                    this.getBotActionDetails(label)
                );
              }
            }}
            toggle={this.toggleField}
            addBtnHide={this.props.permissions.actions.add}
          />

          <BotLayoutContent
            titleValue={actionNewName}
            titleReadOnly={this.props.permissions.actions.modify !== "enabled"}
            titleOnChange={(e, type) => this.handleActionRename(e, type)}
            actions={[
              {
                label: "Delete",
                icon: this.getDeleteModal(),
                isUsed: this.props.permissions.actions.delete === "disabled",
              },
            ]}
            onActionClick={(menu) => this.setState({ modal: true })}
            onAddNewChange={this.handleAddNewValue}
            isSelected={selectedAction}
            addNewValue={addNewPhrase}
            addNewPlacholder="In other words user may say…"
            isFaq={true}
            validateCodeBtn={this.getValidateCodeBtn}
            actionLogBtn={this.getActionLogBtn}
            isShowContent={this.props.permissions.actions.content === "enabled"}
          >
            {selectedAction &&
              this.props.permissions.actions.content === "enabled" && (
                <div className="row m-0">
                  {this.renderBotActions()}
                  {this.state.actionLogs && this.renderActionLogs()}
                </div>
              )}
          </BotLayoutContent>
          <BotLayoutRightPanel />
        </div>
      </div>
    );
  }
}

export default function Actions() {
  const { getUserDetails } = useContext(UserContext);
  return <_Actions permissions={getUserDetails().feature_permission} />;
}
