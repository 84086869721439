import React, { useContext } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import Navbar from "../../components/navbar/navbar.component";
import TrainLog from "../../components/trainingLog.component";
import routes from "../../routes";
import Sidebar from "../../components/sidebar/sidebar";
import { UserContext } from "../../contexts/userContext";
import { BotContext } from "../../contexts/BotContext";
import { useLocation } from "react-router-dom";

const getRoutes = (routes, log, permissions) => {
  return routes.map((prop, key) => {
    if (prop.layout === "/bot") {
      return log.show ? (
        <TrainLog data={log.data} />
      ) : permissions.left_nav[prop.iconName] === "enabled" ? (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={useLocation().key}
        />
      ) : null;
    } else {
      return null;
    }
  });
};

const isIntent = () => window.location.pathname.includes("bot");

function Bot(props) {
  const { isUserLoggedIn, getUserDetails } = useContext(UserContext);
  const { getTrainingLog, setTrainingLog } = useContext(BotContext);
  isUserLoggedIn(props.history);

  return (
    <div className="d-flex h-100 overflow-hidden w-100">
      {isIntent && (
        <Sidebar
          {...props}
          routes={routes}
          permissions={getUserDetails().feature_permission}
        />
      )}
      <div className="h-100 w-100">
        <Navbar {...props} />
        <div
          className="container-fluid px-0 w-100"
          style={{ height: `calc(100% - 3.2rem)` }}
        >
          <Switch>
            {getRoutes(
              routes,
              getTrainingLog,
              getUserDetails().feature_permission
            )}
            <Redirect from="*" to="/admin/home" />
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default Bot;
