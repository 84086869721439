import React, { useEffect, useState } from "react";
import {
  Collapse,
  CardBody,
  Card,
  CardHeader,
  Button as button,
} from "reactstrap";
import svgIcons from "../../../services/icon.service";
import "../../botLayout/botLayout.scss";

function Accordion(props) {
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(null);
  const [sen, setSen] = useState(false);
  const [entity, setEntity] = useState(false);

  useEffect(() => {
    setData(props.libraryData);
  }, [props.libraryData]);

  useEffect(() => {
    if (!props.addLibraryStatus) {
      setOpen(null);
      setEntity(false);
      setSen(false);
    }
  }, [props.addLibraryStatus]);

  function getIntentPhraseEntityView(phrase) {
    let splitArr = phrase.split(/([\[\}]+)/);
    let setArray = [];
    splitArr.forEach((ele) => {
      if (["[", "}}"].indexOf(ele) > -1) {
        setArray.push("");
      } else if (ele.indexOf("]${{") > -1) {
        setArray.push({
          type: "span",
          txt: ele.split("]${{")[0],
        });
      } else if (ele.trim() !== "") {
        setArray.push({
          type: "noSpan",
          txt: ele,
        });
      }
    });
    if (phrase.indexOf("[") > -1) {
      return setArray.map((ele, index) => {
        return getEntityStr2(ele, index);
      });
    } else {
      return <span>{phrase}</span>;
    }
  }

  function getEntityStr2(ele, index) {
    if (ele !== "" && ele.type === "span") {
      return (
        <span
          key={index}
          style={{ backgroundColor: "#D9736A", border: "1px solid red" }}
          className="p-0 px-1 mx-1 text-white btn-sm rounded-2 btn position-relative"
        >
          {ele.txt}
        </span>
      );
    } else if (ele !== "" && ele.type === "noSpan") {
      return <span key={index}>{ele.txt}</span>;
    } else {
      return;
    }
  }

  const onClick = (id, obj) => {
    if (open === id) setOpen(null);
    else {
      setOpen(id);
      props.isNoSubAccordion
        ? props.handleLibraryExpand(obj)
        : props.handleLibraryExpand(obj.industry, obj.intent);
    }
    setEntity(false);
    setSen(false);
  };

  const toggle = (header) => {
    if (header === "Entity") {
      setSen(false);
      setEntity(!entity);
    } else {
      setSen(!sen);
      setEntity(false);
    }
  };

  const renderSubAcc = (data, header, id) => {
    return (
      <Card>
        <CardHeader className="p-0">
          <div
            className="p-2 cursor-pointer row m-0"
            onClick={() => toggle(header)}
          >
            <span className="col">{header}</span>
            <span className="col-auto">
              {data?.length} {header === "Entity" ? "entity" : "sentences"}
            </span>
          </div>
        </CardHeader>
        <Collapse isOpen={header === "Entity" ? entity : sen && open === id}>
          <CardBody className="overflow-auto" style={{ height: "15rem" }}>
            {data?.map((sen) =>
              header === "Entity" ? (
                <p>{sen}</p>
              ) : (
                <p>{getIntentPhraseEntityView(sen)}</p>
              )
            )}
          </CardBody>
        </Collapse>
      </Card>
    );
  };

  function renderBanner() {
    return data?.map((obj, bannerId) => (
      <div className="row m-0 mb-3" key={bannerId}>
        {!props.isNoSubAccordion && (
          <div
            className={`col-auto alig-items-center m-auto ${"cursor-pointer"}`}
            onClick={() => props.handleLibraryClick(obj, bannerId)}
          >
            {obj.isSelected
              ? svgIcons("check-box-marked")
              : svgIcons("check-box-outline-blank")}
          </div>
        )}
        <Card className="col p-0" key={bannerId}>
          <CardHeader className="p-0">
            <div
              className="row m-0 p-2 cursor-pointer"
              onClick={() => onClick(bannerId, obj)}
            >
              <div className="col-auto">{svgIcons("file-svg")}</div>
              <div className="col text-start">
                {!props.isNoSubAccordion ? obj.intent : obj}
              </div>
              <span className="col-auto align-self-center">
                <i
                  className={`fas ${
                    open === bannerId ? "fa-angle-up" : "fa-angle-down"
                  }`}
                />
              </span>
            </div>
          </CardHeader>
          <Collapse isOpen={open === bannerId}>
            {!props.isNoSubAccordion ? (
              <CardBody>
                {renderSubAcc(obj.sentences, "Intent Sentences", bannerId)}
                {renderSubAcc(obj.entities, "Entity", bannerId)}
              </CardBody>
            ) : (
              <CardBody className="overflow-auto" style={{ height: "15rem" }}>
                <div className="d-flex justify-content-between border-bottom py-2">
                  <span className="import-sub-header">Sentences</span>
                  <span className="import-sub-header">
                    Sentences :{" "}
                    {props.intentSentences &&
                    Array.isArray(props.intentSentences)
                      ? props.intentSentences.length
                      : props.intentSentences &&
                        Object.keys(props.intentSentences).length}
                  </span>
                </div>
                {props.intentSentences &&
                Array.isArray(props.intentSentences) ? (
                  props.intentSentences.map((e, i) => {
                    return (
                      <div
                        key={`import-intent-${i}`}
                        className="import-sub-header px-2 py-2 border-bottom"
                        style={{ color: "#181818" }}
                      >
                        {e}
                      </div>
                    );
                  })
                ) : props.intentSentences ? (
                  Object.keys(props.intentSentences).map((e, i) => {
                    return (
                      <div
                        key={`modal-intent-${i}`}
                        className="import-sub-header px-2 py-2 border-bottom"
                        style={{ color: "#181818" }}
                      >
                        {e}
                        <p className="m-0 mt-2 modal-err-font">
                          {props.intentSentences[e]}
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </CardBody>
            )}
          </Collapse>
        </Card>
      </div>
    ));
  }

  return (
    <>
      {data.length > 0 ? (
        <div className="">{renderBanner()}</div>
      ) : (
        <div className="text-center h-100 w-100 mt-5 text-muted ">
          Please search an "Intent Category" by name or select
          "Industry/Industries" to show list of Intents available under it.
        </div>
      )}
    </>
  );
}

export default Accordion;
