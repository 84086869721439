import Actions from "./pages/actions/actions";
import CreateBot from "./pages/createBot/createBot";
import Entity from "./pages/entity/entity";
import Faqs from "./pages/faqs/faqs";
import FlowChart from "./pages/flowChart/flowChart";
import Forms from "./pages/forms/forms";
import Home from "./pages/home/home";
import Integrations from "./pages/integrations/integrations";
import Intents from "./pages/intents/intents";
import Login from "./pages/login/login";
import Response from "./pages/response/response";
import Settings from "./pages/settings/settings";
import Slots from "./pages/slots/slots";
import Statistics from "./pages/statistics/statistics";
import ProfileSettings from "./pages/profileSettings/profileSettings";
import SuperAdmin from "../src/pages/superAdmin/superAdmin";
import SuperAdminLogin from "../src/pages/superAdmin/superAdminLogin";
import OrganisationSettings from "../src/pages/organisationSettings/organisationSettings";

import getIcon from "./services/icon.service";
import KnowledgeBase from "./pages/knowledgeBase/knowledgeBase";

const routes = [
  {
    component: CreateBot,
    icon: "tim-icons icon-chart-pie-36",
    layout: "/admin",
    name: "CreateBot",
    path: "/createBot",
    key: "create_bot",
  },
  {
    component: KnowledgeBase,
    icon: "tim-icons icon-chart-pie-36",
    layout: "/admin",
    name: "KnowledgeBase",
    path: "/knowledgeBase",
    key: "knowledge_base",
  },

  {
    component: Home,
    icon: "tim-icons icon-chart-pie-36",
    layout: "/admin",
    name: "Home",
    path: "/home",
    key: "my_bots",
  },
  {
    component: FlowChart,
    iconName: "flowChart",
    layout: "/bot",
    name: "FlowChart",
    path: "/flows",
    svg: getIcon,
  },
  {
    component: Intents,
    iconName: "intents",
    layout: "/bot",
    name: "Intents",
    path: "/intents",
    svg: getIcon,
  },
  {
    component: Response,
    iconName: "response",
    layout: "/bot",
    name: "Response",
    path: "/response",
    svg: getIcon,
  },
  {
    component: Entity,
    iconName: "entity",
    layout: "/bot",
    name: "Entity",
    path: "/entity",
    svg: getIcon,
  },
  {
    component: Actions,
    iconName: "actions",
    layout: "/bot",
    name: "Actions",
    path: "/actions",
    svg: getIcon,
  },
  {
    component: Faqs,
    iconName: "knowledge_base",
    layout: "/bot",
    name: "Knowledge Base",
    path: "/faqs",
    svg: getIcon,
  },
  {
    component: Login,
    icon: "tim-icons icon-atom",
    layout: "/auth",
    name: "Login",
    path: "/login",
  },
  {
    component: Home,
    icon: "tim-icons icon-chart-pie-36",
    layout: "/admin",
    name: "MarketPlace",
    path: "/marketPlace",
    key: "marketplace",
  },
  {
    component: Home,
    icon: "tim-icons icon-chart-pie-36",
    layout: "/admin",
    name: "SharedBots",
    path: "/sharedbots",
    key: "shared_bots",
  },
  {
    component: ProfileSettings,
    icon: "tim-icons icon-chart-pie-36",
    layout: "/admin",
    name: "ProfileSettings",
    path: "/profile_settings",
  },
  {
    component: Slots,
    iconName: "slots",
    layout: "/bot",
    name: "Slots",
    path: "/slots",
    svg: getIcon,
  },

  {
    component: Forms,
    iconName: "forms",
    layout: "/bot",
    name: "Forms",
    path: "/forms",
    svg: getIcon,
  },
  {
    component: Statistics,
    iconName: "statistics",
    layout: "/bot",
    name: "Statistics",
    path: "/statistics",
    svg: getIcon,
  },
  {
    component: Integrations,
    iconName: "integrations",
    layout: "/bot",
    name: "Integrations",
    path: "/integrations",
    svg: getIcon,
  },
  {
    component: Settings,
    iconName: "settings",
    layout: "/bot",
    name: "Settings",
    path: "/settings",
    svg: getIcon,
  },
  {
    component: SuperAdminLogin,
    icon: "tim-icons icon-atom",
    layout: "/super_admin",
    name: "adminLogin",
    path: "/login",
  },
  {
    component: SuperAdmin,
    icon: "tim-icons icon-atom",
    layout: "/super_admin",
    name: "super_admin_page",
    path: "/list_orgs",
  },
  {
    component: OrganisationSettings,
    icon: "tim-icons icon-chart-pie-36",
    layout: "/admin",
    name: "OrganisationSettings",
    path: "/organisation_settings",
  },
];

export default routes;
