import React, { Component, useContext } from "react";
import svgIcons from "../../services/icon.service";
import superAdminServices from "../../services/api.services/superAdminApi.service";
import { NotificationContext } from "../../contexts/notficationContext";
import { UserContext } from "../../contexts/userContext";

import Buttons from "../../components/fields/button/button.component";
import Input from "../../components/fields/input/input.component";
import AddNewModal from "../../components/modal/modal.component";
import DropDown from "../../components/fields/dropDown/dropDown.component";

import "./superAdmin.scss";

class SuperAdmin extends Component {
  static contextType = NotificationContext;
  constructor(props) {
    super(props);
    this.state = {
      organisationList: [],
      forgotModal: false,
      onConfirm: false,
      newOrganisationModal: false,
      isEdit: false,
      org_name: "",
      org_email_domain: "",
      org_admin_name: "",
      org_admin_email: "",
      org_admin_phone: "",
      tableData: {
        id: "",
        status: "",
        org_admin_email: "",
        org_admin_name: "",
        org_admin_phone: "",
      },
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.getOrganisationList();
    }, 100);
  }

  getOrganisationList = () => {
    superAdminServices
      .getOrganisationList()
      .then((r) => {
        if (r.status === "success") this.setState({ organisationList: r.data });
      })
      .catch((e) => console.log(e));
  };

  addNewOrganisation = () => {
    const {
      org_name,
      org_email_domain,
      org_admin_phone,
      org_admin_email,
      org_admin_name,
    } = this.state;

    let data = {
      org_name,
      org_email_domain,
      org_admin_phone,
      org_admin_email,
      org_admin_name,
    };
    superAdminServices
      .addNewOrganisation(data)
      .then((r) => {
        if (r.status === "success") {
          this.context.setNotifications({
            color: "success",
            message: r.message,
          });
          this.getOrganisationList();
        } else {
          this.context.setNotifications({
            color: "danger",
            message: r.message,
          });
        }
      })
      .catch((err) =>
        this.context.setNotifications({
          color: "danger",
          message: err.message,
        })
      );
  };

  activateOrg = () => {
    let { tableData } = this.state;

    let data = { org_admin_email: tableData.org_admin_email };

    tableData.org_admin_email &&
      superAdminServices
        .activateOrganisation(data)
        .then((r) => {
          if (r.status === "success") {
            this.context.setNotifications({
              color: "success",
              message: r.message,
            });
            tableData.status = true;
            this.setState({ tableData }, () => this.getOrganisationList());
          } else {
            this.context.setNotifications({
              color: "danger",
              message: r.message,
            });
          }
        })
        .catch((err) =>
          this.context.setNotifications({
            color: "danger",
            message: err.message,
          })
        );
  };

  deactivateOrg = () => {
    let { tableData } = this.state;

    let data = { org_admin_email: tableData.org_admin_email };

    tableData.org_admin_email &&
      superAdminServices
        .deactivateOrganisation(data)
        .then((r) => {
          if (r.status === "success") {
            this.context.setNotifications({
              color: "success",
              message: r.message,
            });
            tableData.status = false;
            this.setState({ tableData }, () => this.getOrganisationList());
          } else {
            this.context.setNotifications({
              color: "danger",
              message: r.message,
            });
          }
        })
        .catch((err) =>
          this.context.setNotifications({
            color: "danger",
            message: err.message,
          })
        );
  };

  deleteOrg = () => {
    let { tableData } = this.state;

    let data = { org_admin_email: tableData.org_admin_email };

    tableData.org_admin_email &&
      superAdminServices
        .deleteOrganisation(data)
        .then((r) => {
          if (r.status === "success") {
            this.context.setNotifications({
              color: "success",
              message: r.message,
            });
            tableData.id = "";
            this.setState({ tableData });
            this.getOrganisationList();
          } else {
            this.context.setNotifications({
              color: "danger",
              message: r.message,
            });
          }
        })
        .catch((err) =>
          this.context.setNotifications({
            color: "danger",
            message: err.message,
          })
        );
  };

  updateOrgDetails = () => {
    let { tableData } = this.state;

    let data = {
      org_admin_email: tableData.org_admin_email,
      org_admin_name: tableData.org_admin_name,
      org_admin_phone: tableData.org_admin_phone,
    };

    tableData.org_admin_email &&
      superAdminServices
        .updateOrgDetails(data)
        .then((r) => {
          if (r.status === "success") {
            this.context.setNotifications({
              color: "success",
              message: r.message,
            });
            this.getOrganisationList();
          } else {
            this.context.setNotifications({
              color: "danger",
              message: r.message,
            });
          }
        })
        .catch((err) =>
          this.context.setNotifications({
            color: "danger",
            message: err.message,
          })
        );
  };

  handleTableClick = (data, i) => {
    let { tableData } = this.state;
    if (tableData.id === i) {
      tableData.id = "";
      this.setState({ tableData });
    } else {
      tableData.id = i;
      tableData.status = data.active;
      tableData.org_admin_email = data.org_admin_email;
      tableData.org_admin_name = data.org_admin_name;
      tableData.org_admin_phone = data.org_admin_phone;
      this.setState({ tableData });
    }
  };

  renderTableData = () => {
    let { organisationList, tableData, isEdit } = this.state;

    return organisationList.map((org, i) => (
      <tr
        key={i}
        className={`${
          tableData.id === i && "selected-org"
        } org-table-data-row `}
        style={{
          borderTop: i === 0 ? "hidden" : "initial",
          verticalAlign: "middle",
        }}
        onClick={() => this.handleTableClick(org, i)}
      >
        <td className="organisation-table-data">
          <div className="row m-0">
            <div className="col-auto">
              {tableData.id === i
                ? svgIcons("small-checkmark")
                : svgIcons("table-unchecked")}
            </div>
            <div
              className={`col ${
                tableData.id === i
                  ? "selected-org-clr"
                  : "org-table-detail-font"
              }`}
            >
              {org.registered_at ? org.registered_at.split(" ")[0] : "-"}
            </div>
          </div>
        </td>
        <td className="organisation-table-data">
          <span
            className={`${
              tableData.id === i ? "selected-org-clr" : "org-table-detail-font"
            }`}
          >
            {org.org_name ? org.org_name : "-"}
          </span>
          <br />
          <span
            className={`${
              tableData.id === i ? "selected-org-clr" : "org-table-detail-font"
            }`}
          >
            {org.org_admin_email ? org.org_admin_email : "-"}
          </span>
        </td>
        <td className="organisation-table-data">
          {tableData.id === i && isEdit ? (
            <Input
              className="org-edit-inp px-1"
              autoFocus={isEdit}
              value={tableData.org_admin_name}
              onChange={(e) => {
                tableData.org_admin_name = e.target.value;
                this.setState({ tableData });
              }}
              onBlur={() =>
                tableData.org_admin_name !== org.org_admin_name &&
                this.updateOrgDetails()
              }
            />
          ) : (
            <span
              className={`${
                tableData.id === i
                  ? "selected-org-clr"
                  : "org-table-detail-font"
              }`}
            >
              {org.org_admin_name ? org.org_admin_name : "-"}
            </span>
          )}
          <br />
          <span
            className={`${
              tableData.id === i ? "selected-org-clr" : "org-table-detail-font"
            }`}
          >
            {org.org_admin_email ? org.org_admin_email : "-"}
          </span>
          <br />
          {/* {tableData.id === i && isEdit ? (
            <Input
              className="org-edit-inp px-1"
              value={tableData.org_admin_phone}
              onChange={(e) => {
                tableData.org_admin_phone = e.target.value;
                this.setState({ tableData });
              }}
              onBlur={() =>
                tableData.org_admin_phone !== org.org_admin_phone &&
                this.updateOrgDetails()
              }
            />
          ) : ( */}
          <span
            className={`${
              tableData.id === i ? "selected-org-clr" : "org-table-detail-font"
            }`}
          >
            {org.org_admin_phone ? org.org_admin_phone : "-"}
          </span>
          {/* )}{" "} */}
        </td>
        <td className="organisation-table-data">{org.total_bots}</td>
        <td className="organisation-table-data">
          <span
            className={`${
              tableData.id === i ? "selected-org-clr" : "org-table-detail-font"
            }`}
          >
            {org.active ? "Active" : "Disabled"}
          </span>
        </td>
      </tr>
    ));
  };

  toggleModal = () => {
    this.setState({ newOrganisationModal: !this.state.newOrganisationModal });
  };

  getAddOrganisationModal = () => {
    let {
      newOrganisationModal,
      org_name,
      org_email_domain,
      org_admin_name,
      org_admin_email,
      org_admin_phone,
    } = this.state;
    return (
      <AddNewModal
        noCloseBtn
        modalSize="md"
        maxwidth="575px"
        bodyClassName="p-5"
        title="Add Organisation"
        toggle={this.toggleModal}
        addNewModalOpen={newOrganisationModal}
      >
        <div className="my-5">
          <p className="modal-heading mb-4">Organisation Details</p>
          <div className="row m-0 mb-3 align-items-center">
            <label className="col-4 org-modal-label">Name</label>
            <Input
              className="col org-modal-inp"
              placeholder="Enter organisation name"
              value={org_name}
              onChange={(e) => this.setState({ org_name: e.target.value })}
            />
          </div>
          <div className="row m-0 align-items-center">
            <label className="col-4 org-modal-label">E-Mail</label>
            <Input
              className="col org-modal-inp"
              type="email"
              placeholder="Enter organisation domain"
              value={org_email_domain}
              onChange={(e) =>
                this.setState({ org_email_domain: e.target.value })
              }
            />
          </div>
        </div>
        <div>
          <p className="modal-heading mb-4">Admin Details</p>
          <div className="row m-0 align-items-center mb-3">
            <label className="col-4 org-modal-label">Name</label>
            <Input
              className="col org-modal-inp"
              placeholder="Enter admin name"
              value={org_admin_name}
              onChange={(e) =>
                this.setState({ org_admin_name: e.target.value })
              }
            />
          </div>
          <div className="row m-0 align-items-center mb-3">
            <label className="col-4 org-modal-label">E-Mail</label>
            <Input
              className="col org-modal-inp"
              type="email"
              placeholder="Enter admin email"
              value={org_admin_email}
              onChange={(e) =>
                this.setState({ org_admin_email: e.target.value })
              }
            />
          </div>
          <div className="row m-0 align-items-center">
            <label className="col-4 org-modal-label">Phone No</label>
            <Input
              className="col org-modal-inp"
              placeholder="Enter admin phone number"
              value={org_admin_phone}
              onChange={(e) =>
                this.setState({ org_admin_phone: e.target.value })
              }
            />
          </div>
        </div>
        <div className="w-100 text-center mt-5">
          <Buttons
            className="org-modal-cancel-btn me-4"
            onClick={this.toggleModal}
          >
            Cancel
          </Buttons>
          <Buttons
            className="org-modal-add-btn"
            onClick={() => {
              this.addNewOrganisation();
              this.setState({
                org_name: "",
                org_email_domain: "",
                org_admin_name: "",
                org_admin_email: "",
                org_admin_phone: "",
                newOrganisationModal: false,
              });
            }}
          >
            Add Organisation
          </Buttons>
        </div>
      </AddNewModal>
    );
  };

  resetPassword = () => {
    let { tableData } = this.state;

    let data = { email: tableData.org_admin_email };

    tableData.org_admin_email &&
      superAdminServices
        .resetOrgPassword(data)
        .then((r) => {
          if (r.status === "success") {
            this.context.setNotifications({
              color: "success",
              message: r.message,
            });
            tableData.id = "";
            this.setState({ onConfirm: true, tableData });
          } else {
            this.context.setNotifications({
              color: "danger",
              message: r.message,
            });
          }
        })
        .catch((err) =>
          this.context.setNotifications({
            color: "danger",
            message: err.message,
          })
        );
  };

  toggleForgotModal = () => {
    this.setState({ forgotModal: !this.state.forgotModal });
  };

  getForgotModal = () => {
    let { onConfirm, forgotModal, tableData } = this.state;
    return (
      <AddNewModal
        noCloseBtn
        modalSize="md"
        bodyClassName="p-5"
        title={`Reset password`}
        toggle={this.toggleForgotModal}
        addNewModalOpen={forgotModal}
      >
        <div className={`mt-4 mt-5 d-flex justify-content-center px-3`}>
          {!onConfirm && (
            <div className="">
              <div>
                <small className="mb-3">
                  Would you like to reset password to{" "}
                  {tableData?.org_admin_name?.substring(0, 1).toUpperCase() +
                    tableData?.org_admin_name?.substring(1)}{" "}
                  ?
                </small>{" "}
              </div>
              <div className="mt-5 text-center">
                <Buttons
                  onClick={this.toggleForgotModal}
                  className="org-modal-cancel-btn me-3"
                >
                  Cancel
                </Buttons>
                <Buttons
                  onClick={this.resetPassword}
                  className="org-password-confirm-btn"
                >
                  Confirm
                </Buttons>
              </div>
            </div>
          )}

          {onConfirm && (
            <div className="">
              <div>
                <small className="mb-3">
                  Click on the link which we sent to
                </small>{" "}
                <br />
                <span>
                  **********@{tableData.org_admin_email.split("@")[1]}
                </span>
              </div>
              <div className="mt-5 text-center">
                <Buttons
                  onClick={this.toggleForgotModal}
                  className="org-password-confirm-btn"
                >
                  Ok
                </Buttons>
              </div>
            </div>
          )}
        </div>
      </AddNewModal>
    );
  };

  render() {
    let { tableData } = this.state;
    return (
      <div className="p-5" style={{ height: "100%" }}>
        <div className="row m-0 justify-content-between align-items-center mb-5">
          <span className="col-auto super-admin-title">Super Admin</span>
          <div className="col-auto align-items-center d-flex">
            {svgIcons("search", "", "search-icon")}
            <Input
              className="super-admin-search-bar me-4 px-2 ps-5"
              placeholder="Search"
            />
            <Buttons className="new-org-btn me-4" onClick={this.toggleModal}>
              {svgIcons("small-plus", "", "text-white pe-2")}NEW ORGANISATION
            </Buttons>
            {this.getAddOrganisationModal()}
            <DropDown
              actionButton={() => (
                <div className="navbar-profile-icon-container">
                  <i className="fas fa-user" />
                </div>
              )}
              btnClassName="p-0 text-light"
              dropdownList={[{ label: "Logout", icon: svgIcons("sign-out") }]}
              onActionItemClick={() => {
                this.props.clearStorage();
                window.location.replace("/super_admin/login");
              }}
            />
          </div>
        </div>
        <div
          className={`${
            tableData.id === "" && "deactive-btn-row"
          } row m-0 justify-content-between mb-5`}
        >
          <div className="col-auto row m-0">
            <Buttons
              isDisabled={tableData.id === ""}
              className="admin-edit-btn col-auto me-4"
              onClick={() => this.setState({ isEdit: true })}
            >
              Edit
            </Buttons>
            <div className="col-auto active-btn-container d-flex align-items-center justify-content-center">
              <Buttons
                isDisabled={tableData.id === ""}
                className={`${
                  tableData.status &&
                  tableData.id &&
                  "active-background text-white"
                } active-btn-font`}
                onClick={this.activateOrg}
              >
                Active
              </Buttons>
              <Buttons
                isDisabled={tableData.id === ""}
                className={`${
                  !tableData.status &&
                  tableData.id &&
                  "active-background text-white"
                } active-btn-font`}
                onClick={this.deactivateOrg}
              >
                Deactivate
              </Buttons>
            </div>
          </div>
          <div className="col-auto">
            <Buttons
              isDisabled={tableData.id === ""}
              className="super-admin-reset-btn me-4"
              onClick={this.toggleForgotModal}
            >
              Reset password
            </Buttons>
            {this.getForgotModal()}
            <Buttons
              isDisabled={tableData.id === ""}
              className="super-admin-delete-btn"
              onClick={this.deleteOrg}
            >
              Delete
            </Buttons>
          </div>
        </div>
        <div
          className="px-3 overflow-auto"
          style={{ height: "calc(100% - 11rem)" }}
        >
          <span className="organisation-list">List of organisations</span>
          <table className="mt-3 w-100">
            <thead>
              <tr>
                <th
                  style={{ width: "185px" }}
                  className="organisation-table-th"
                >
                  Date Created
                </th>
                <th className="organisation-table-th">Organisation Detail</th>
                <th className="organisation-table-th ">Admin Details</th>
                <th
                  className="organisation-table-th "
                  style={{ width: "185px" }}
                >
                  Total Bots
                </th>
                <th
                  className="organisation-table-th "
                  style={{ width: "185px" }}
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody>{this.renderTableData()}</tbody>
          </table>
        </div>
      </div>
    );
  }
}

// export default SuperAdmin;

export default function SuperAdmin2() {
  const { clearStorage } = useContext(UserContext);

  return <SuperAdmin clearStorage={clearStorage} />;
}
