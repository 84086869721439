import React, { useState, useEffect } from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import svgIcons from "../services/icon.service";
import AddNewModal from "./modal/modal.component";
import baseUrl from "../services/baseURL.service";
import { getToken } from "../services/authUtil";
import apiServices from "../services/api.services";
import Buttons from "../components/fields/button/button.component";
import Accordion from "./fields/accordion/accordion.component";
import "../components/botLayout/botLayout.scss";

const BulkImport = (props) => {
  const [isModal, setModal] = useState(props.open);
  const [isFile, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [sentences, setSentences] = useState(null);
  const [importDataLength, setImportDataLength] = useState(false);

  const toggle = () => {
    props.isContentSwitch && props.resetState && props.resetState();
    setModal(!isModal);
    setFile(undefined);
    setSentences(null);
  };

  const upload = (e) => {
    if (props.isFile) {
      setLoading(true);
      setFile(e);
      setTimeout(() => {
        setLoading(false);
      }, 300);
    } else {
      setLoading(true);
      let { files } = e.target;
      let f = files[0];
      let reader = new FileReader();

      reader.onload = (function (theFile) {
        return function ({ target }) {
          let { result } = target;
          setFile(result);
        };
      })(f);

      reader.readAsText(f);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      props.importData &&
      props.isEntity &&
      props.validationStatus === "validationComplete"
    ) {
      setImportDataLength(!!props.importData.invalid_entity_names.length);
    } else if (
      props.importData &&
      props.validationStatus === "validationComplete"
    ) {
      setImportDataLength(
        !!Object.keys(props.importData.invalid_sentences_with_given_intents)
          .length
      );
    }
  }, [props.importData]);

  const getLibraryData = (isEntity) => {
    if (isEntity) {
      return [
        ...props.importData.existing_entities_with_conflict,
        ...props.importData.new_entities,
      ];
    } else {
      return [
        ...props.importData.existing_intents_with_conflict,
        ...props.importData.new_intents,
      ];
    }
  };

  const handleExpandConflictResults = (int) => {
    if (props.isEntity) {
      if (props.importData.new_entities.includes(int)) {
        setSentences(props.importData.new_valid_entity_values[int]);
      } else {
        setSentences(
          props.importData.existing_entities_with_more_valid_values[int]
        );
      }
    } else {
      if (props.importData.new_intents.includes(int)) {
        setSentences(props.importData.new_intents_with_valid_sentences[int]);
      } else {
        setSentences(
          props.importData.existing_intents_with_more_valid_sentences[int]
        );
      }
    }
  };

  const handleValidatedResult = (int) => {
    if (props.isEntity) {
      setSentences(
        props.importData.invalid_entity_values_with_given_entities[int]
      );
    } else {
      setSentences(props.importData.invalid_sentences_with_given_intents[int]);
    }
  };

  const getNumberOfSen = () => {
    if (props.isEntity) {
      return props.validationStatus === "validationComplete" && props.importData
        ? props.importData.invalid_entity_names.length
        : props.validationStatus === "importStage" &&
            props.importData &&
            props.importData.new_entities.length;
    } else {
      return props.validationStatus === "validationComplete" && props.importData
        ? Object.keys(props.importData.invalid_sentences_with_given_intents)
            .length
        : props.validationStatus === "importStage" &&
            props.importData &&
            props.importData.new_intents.length;
    }
  };

  return (
    <>
      {props.onlyIcon && <i className={props.onlyIcon} onClick={toggle}></i>}
      {!props.onlyIcon && (
        <Buttons
          className="intents-btn intent-font-save rounded"
          onClick={toggle}
        >
          {svgIcons("file-import")}
          Import
        </Buttons>
      )}
      <AddNewModal
        toggle={toggle}
        noCloseBtn={true}
        addNewModalOpen={isModal}
        title={props.importFile}
        footerContent={
          !props.isContentSwitch && (
            <>
              <Button
                // className="btn px-5 btns-font"
                className="confirm-btn-no-width"
                style={{ width: "99px" }}
                disabled={!isFile}
                onClick={() => {
                  toggle();
                  props.confirmSuccess(isFile);
                }}
              >
                Import
              </Button>
              <Button
                // className="btn px-5 py-2 btns-font"
                className="no-border-cancel-btn"
                style={{ width: "99px" }}
                onClick={toggle}
              >
                Cancel
              </Button>
            </>
          )
        }
      >
        {!props.importData ? (
          <Row className="m-0">
            <Col className="col-8">
              <input
                className="mt-5 mb-3 me-2 rounded border"
                accept={props.allowedExts || ".csv,.json"}
                onChange={upload}
                type="file"
              />
              {loading && (
                <>
                  <Spinner
                    style={{ width: "1rem", height: "1rem" }}
                    className="opacity-50"
                  />
                  <small className="ms-2 text-muted">loading...</small>
                </>
              )}

              <br />
              {props.description && (
                <small className="text-muted mt-5">
                  {props.description.title}{" "}
                  <pre className="mt-1">{props.description.format}</pre>
                </small>
              )}
            </Col>
            {props.sampleUrl && (
              <Col className="col-auto align-self-center">
                <button
                  className="btn"
                  onClick={() => {
                    apiServices
                      .fetchData(
                        `${baseUrl}/${props.sampleUrl}`,
                        {
                          method: "GET",
                          headers: { Accept: "*/*", token: getToken() },
                        },
                        (response) => response.blob()
                      )
                      .then((blob) => {
                        // Create blob link to download
                        const url = window.URL.createObjectURL(
                          new Blob([blob])
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", props.sampleFileName);

                        // Append to html link element page
                        document.body.appendChild(link);

                        // Start download
                        link.click();

                        // Clean up and remove the link
                        link.parentNode.removeChild(link);
                      });
                  }}
                >
                  {props.sampleFileName} {svgIcons("file-download")}
                </button>
              </Col>
            )}
          </Row>
        ) : (
          <div className="mt-3" style={{ height: "480px" }}>
            {importDataLength && (
              <>
                <div className="mb-4">
                  <span
                    className="bulk-import-sub-heading"
                    style={{ color: "#000000" }}
                  >
                    Selected File:{" "}
                  </span>
                  <span className="import-sub-header">{props.fileName}</span>
                </div>
                <div className="mb-3 d-flex justify-content-between">
                  <span className="bulk-import-sub-heading">
                    {props.isEntity
                      ? "Entity With Changes"
                      : "Intent With Changes"}
                  </span>
                  <span className="import-sub-header">
                    {props.isEntity ? "Entities" : "Intents"} :{" "}
                    {getNumberOfSen()}
                  </span>
                </div>
              </>
            )}
            <div
              className="overflow-auto"
              style={{ height: "calc(100% - 4rem)" }}
            >
              {props.validationStatus === "validationComplete" &&
              importDataLength ? (
                <Accordion
                  isNoSubAccordion={true}
                  intentSentences={sentences}
                  libraryData={
                    props.isEntity
                      ? props.importData.invalid_entity_names
                      : Object.keys(
                          props.importData.invalid_sentences_with_given_intents
                        )
                  }
                  handleLibraryExpand={(int) => handleValidatedResult(int)}
                />
              ) : props.validationStatus === "validationComplete" &&
                !importDataLength ? (
                <div className="d-flex flex-column align-items-center justify-content-center h-100">
                  <div className="mb-5">{svgIcons("check-circle-blue")}</div>
                  <p className="mb-1 imp-validation-msg">
                    All sentences in{" "}
                    <span
                      style={{
                        color: "#525A7E",
                        textDecoration: "underline #525A7E",
                      }}
                    >
                      {props.fileName}
                    </span>{" "}
                    are valid.
                  </p>
                  <p className="m-0 imp-validation-msg">
                    Click on “Proceed” button to move ahead with the import
                  </p>
                </div>
              ) : (
                <Accordion
                  isNoSubAccordion={true}
                  intentSentences={sentences}
                  libraryData={getLibraryData(props.isEntity)}
                  handleLibraryExpand={(int) =>
                    handleExpandConflictResults(int)
                  }
                />
              )}
            </div>
          </div>
        )}
        {props.isContentSwitch && (
          <div className={`row m-0  justify-content-between mt-5`}>
            <div className="col">
              {props.validationStatus === "validationComplete" &&
                importDataLength && (
                  <span className="imp-warning-font col">
                    Note: If you proceed with import, these sentences will not
                    be imported due to the errors mentioned. Click on proceed to
                    import rest of the valid sentences.
                  </span>
                )}
            </div>
            <div className="col-auto">
              <Buttons
                className="no-border-cancel-btn col-auto"
                styles={{ width: "99px" }}
                onClick={toggle}
              >
                Cancel
              </Buttons>
              <Buttons
                className="confirm-btn-no-width col-auto mx-2"
                styles={{ width: "99px" }}
                disabled={!isFile}
                onClick={() => {
                  if (
                    props.isContentSwitch &&
                    props.validationStatus === "validationPending"
                  )
                    props.validateImport(isFile);
                  else if (
                    props.isContentSwitch &&
                    props.validationStatus === "validationComplete"
                  )
                    props.confirmSuccess(isFile);
                  else if (props.validationStatus === "importStage") {
                    toggle();
                    props.confirmSuccess(isFile, true);
                  }
                }}
              >
                {props.validationStatus === "validationPending"
                  ? "Validate"
                  : props.validationStatus === "validationComplete"
                  ? "Proceed"
                  : "Import"}
              </Buttons>
            </div>
          </div>
        )}
      </AddNewModal>
    </>
  );
};

export default BulkImport;

// MIME Validation Logic
// var blob = e.target.files[0]; // See step 1 above
// var fileReader = new FileReader();
// fileReader.onloadend = function(e) {
//   var arr = (new Uint8Array(e.target.result)).subarray(0, 4);
//   var header = "";
//   for(var i = 0; i < arr.length; i++) {
//     header += arr[i].toString(16);
//   }
//   console.log(header);

//   // Check the file signature against known types

// };
// fileReader.readAsArrayBuffer(blob);
