import { forwardRef, useEffect, useState } from "react";
import botService from "../../../services/bot.service";

import { ReactTransliterate, TriggerKeys } from "react-transliterate";
import "react-transliterate/dist/index.css";

const languages = {
  "english-in": "en",
  "english-uk": "en",
  "english-us": "en",
  "urdu pk": "ur",
  bengali: "bn",
  gujarati: "gu",
  hindi: "hi",
  kannada: "kn",
  malayalam: "ml",
  marathi: "mr",
  nepali: "ne",
  punjabi: "pa",
  tamil: "ta",
  telugu: "te",
  urdu: "ur",
  french: "fr",
  german: "de",
  italian: "it",
  spanish: "es",
  "spanish us": "es",
  bahasa: "en",
  arabic: "ar",
};

const Input = forwardRef(
  (
    {
      accept,
      autoFocus,
      checked,
      className,
      defaultValue,
      hidden,
      id,
      isRequired,
      name,
      onBlur,
      onChange,
      onFocus,
      onKeyPress,
      onSelect,
      placeholder,
      readOnly,
      style,
      type = "text",
      value,
      isLiteration,
      offsetX,
      offsetY,
      maxOptions,
      chatWindow,
      disabled = false,
      multiple = false,
    },
    ref
  ) => {
    const [text, setText] = useState("");
    const [lang, setLang] = useState("");

    useEffect(() => {
      if (botService.getSelectedBotLang()) {
        var temp = botService
          .getSelectedBotLang()
          .replace("-", " ")
          .toLowerCase();
        setLang(temp);
      }
    }, []);

    return (
      <>
        {isLiteration &&
        !["english uk", "english in", "english us"].includes(lang) ? (
          <ReactTransliterate
            className={className}
            renderComponent={(props) => (
              <input type="text" id={id} {...props} autoComplete="off" />
            )}
            name={name}
            value={text || value}
            onChangeText={(e) => {
              setText(e);
              onChange(e);
            }}
            lang={
              botService.getSelectedBot()?.transliteration_code ||
              languages[lang]
            }
            onBlur={onBlur}
            placeholder={placeholder}
            autoFocus={autoFocus}
            onFocus={onFocus}
            style={style}
            readOnly={readOnly}
            onKeyPress={(e) => {
              onKeyPress(e);
              e.key === "Enter" && setText("");
            }}
            onSelect={onSelect}
            offsetX={offsetX}
            offsetY={offsetY}
            maxOptions={maxOptions || 4}
            containerClassName={`text-dark ${chatWindow ? "w-75" : "w-100"}`}
            triggerKeys={["Enter"]}
          />
        ) : (
          <input
            accept={accept}
            autoFocus={autoFocus}
            className={className}
            defaultValue={defaultValue}
            hidden={hidden}
            id={id}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            onKeyPress={onKeyPress}
            onSelect={onSelect}
            placeholder={placeholder}
            readOnly={readOnly}
            ref={ref}
            required={isRequired}
            style={style}
            type={type}
            value={value}
            checked={checked}
            disabled={disabled}
            multiple={multiple}
          />
        )}
      </>
    );
  }
);

export default Input;
