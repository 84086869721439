import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import "./horizontalScroll.scss";
import svgIcons from "../../services/icon.service";
import DeleteConfirmation from "../deleteConfirmation.component";

const HorizontalScroll = ({
  items,
  selected_item,
  isFlows,
  isWithIcon,
  handleSelect,
  isRes,
  selectedChannel,
  deleteChannel,
}) => {
  const scrollRef = useRef();
  const [selectedItem, setSelectedItem] = useState("all");
  const [modal, setModal] = useState(false);

  // useEffect(() => {
  //   isRes && items.length > 6 && handleScroll(+200);
  // }, [items]);

  const getDeleteModal = (item) => {
    return (
      <DeleteConfirmation
        isButton
        open={modal}
        btnClassName={"btn btn-sm px-1"}
        delete={() => deleteChannel(item)}
        buttons={() => (
          <span className="">{svgIcons("delete", "white", "me-2")}</span>
        )}
      />
    );
  };

  const handleOnClick = ({ value }) => {
    setSelectedItem(value);
    selected_item(value);
  };

  const handleChannelSelect = (m) => {
    handleSelect(m);
  };

  const handleScroll = (direction) => {
    let scrollAmount = 0;

    const slideTimer = setInterval(() => {
      scrollRef.current.scrollLeft += direction;
      scrollAmount += Math.abs(direction);
      scrollAmount >= 100 && clearInterval(slideTimer);
    }, 25);
  };

  const renderCategories = (items) =>
    items.map((item, index) => (
      <li
        className={`${
          selectedItem === item.value && "horizontal-scroll-selected-item"
        }`}
        key={index}
        onClick={() => handleOnClick(item)}
      >
        {item.label}
      </li>
    ));

  const renderChannels = (items) => {
    return items?.map((item, index) => (
      <li
        className={`${
          selectedChannel === item.value && "selected-channel-nav"
        } text-white h-100 d-flex align-items-center m-0 p-0`}
        key={index}
      >
        <div
          className={`h-100 align-items-center d-flex `}
          onClick={() => handleChannelSelect(item)}
          style={{ marginLeft: "12px", marginRight: "36px" }}
        >
          {item.label}
        </div>
        {item.label !== "Default" &&
          selectedChannel === item.value &&
          getDeleteModal(item)}
      </li>
    ));
  };

  const renderWithIcons = (items) => {
    return items.map((item, index) => (
      <div
        className={`hzs-channel-icon-container ${
          selected_item?.length > 0 &&
          selected_item.includes(item.label) &&
          "hzs-channel-icon-selected"
        }`}
        key={index}
        onClick={() => handleSelect(item)}
      >
        <img
          alt={item.label}
          className="img-fluid"
          style={{ width: "2.3rem" }}
          src={item.icon}
        />
        <p className="m-0 create-bot-inp-label">{item.label}</p>
      </div>
    ));
  };

  const checkIsWithIcon = () => {
    return isWithIcon ? (
      <div className="d-flex align-items-center">
        <FontAwesomeIcon
          className="cursor-pointer"
          icon={faChevronLeft}
          onClick={() => handleScroll(-10)}
        />
        <div className="hzs-channel-icons-container" ref={scrollRef}>
          {renderWithIcons(items)}
        </div>
        <FontAwesomeIcon
          className="cursor-pointer"
          icon={faChevronRight}
          onClick={() => handleScroll(10)}
        />
      </div>
    ) : (
      <div
        className={`d-flex justify-content-evenly ${
          isFlows || isRes ? "p-1" : "px-5 pt-5"
        }`}
      >
        <FontAwesomeIcon
          className="cursor-pointer"
          icon={faChevronLeft}
          onClick={() => handleScroll(-10)}
        />
        <ul className="horizontal-scroll-container mx-3" ref={scrollRef}>
          {renderCategories(items)}
        </ul>
        <FontAwesomeIcon
          className="cursor-pointer"
          icon={faChevronRight}
          onClick={() => handleScroll(10)}
        />
      </div>
    );
  };

  const channelsMenu = () => {
    return (
      <div
        className={`d-flex justify-content align-items-center w-100`}
        style={{ height: "40px" }}
      >
        <span className="cursor-pointer mb-1" onClick={() => handleScroll(-20)}>
          {svgIcons("arrow-double-left")}
        </span>
        <ul className="channel-horizontal-container mx-3 h-100" ref={scrollRef}>
          {renderChannels(items)}
        </ul>
        <span className="cursor-pointer mb-1" onClick={() => handleScroll(10)}>
          {svgIcons("arrow-double-right")}
        </span>
      </div>
    );
  };

  if (isRes) return channelsMenu();
  else return checkIsWithIcon();
};

export default HorizontalScroll;
