import baseUrl from "../baseURL.service";
import { getToken } from "../authUtil";
import apiServices from "../api.services";

const fetchData = (url, options) => {
  return apiServices.fetchData(url, { ...options });
};

const getOrganisationList = () => {
  let reqOptions = {
    method: "GET",
    headers: {
      token: getToken(),
    },
    mode: "cors",
  };
  return fetchData(`${baseUrl}/list_organizations`, reqOptions);
};

const addNewOrganisation = (body) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(body),
  };
  return fetchData(`${baseUrl}/add_organization`, requestOptions);
};

const activateOrganisation = (body) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(body),
  };
  return fetchData(`${baseUrl}/activate_organization`, requestOptions);
};

const deactivateOrganisation = (body) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(body),
  };
  return fetchData(`${baseUrl}/deactivate_organization`, requestOptions);
};

const deleteOrganisation = (body) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(body),
  };
  return fetchData(`${baseUrl}/delete_organization`, requestOptions);
};

const updateOrgDetails = (body) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(body),
  };
  return fetchData(`${baseUrl}/update_admin_details`, requestOptions);
};

const resetOrgPassword = (body) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(body),
  };
  return fetchData(`${baseUrl}/forgot_organization_password`, requestOptions);
};

const superAdminServices = {
  getOrganisationList,
  addNewOrganisation,
  activateOrganisation,
  deactivateOrganisation,
  deleteOrganisation,
  resetOrgPassword,
  updateOrgDetails
};

export default superAdminServices;
