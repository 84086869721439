import { useState, useRef, useContext } from "react";
import { Col, Row, UncontrolledTooltip } from "reactstrap";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import groupIcon from "../../assets/icons/flowsIcon.svg";
import DropDown from "../fields/dropDown/dropDown.component";
import Input from "../../components/fields/input/input.component";
import Button from "../fields/button/button.component";
import AddNewModal from "../modal/modal.component";
import Accordion from "../fields/accordion/accordion.component";
import svgIcons from "../../services/icon.service";
import botService from "../../services/bot.service";
import ApiService from "../../services/api.services";
import DeleteConfirmation from "../deleteConfirmation.component";
import { NotificationContext } from "../../contexts/notficationContext";

import "./botLayout.scss";

const RenderSideBarItems = ({
  dropdownList,
  entity,
  formDetails,
  handleAddQuestion,
  handleDropDown,
  handleDropDownToggleClick,
  handleNameChange,
  handleSelect,
  handleToggleCheck,
  index,
  isFormsPage,
  isIntegrations,
  isSettingsPage,
  onMenuSelect,
  onQuePhraseHover,
  questionSelect,
  readOnly,
  selectedIntegration,
  selectedIntent,
  setEntity,
  setIndex,
  setQuePhraseHover,
  sideBarList,
  systemEntities,
  title,
  trainedIntent,
  handleRenameBlur,
  checkedLabel,
  toggleSubCheckbox,
  dropdownLabel,
  renderSlotCards,
}) => {
  const handleChange = (name, checked) => {
    toggleSubCheckbox(checked, name);
  };
  const displayCheckbox = (checkedValue) => {
    if (dropdownLabel !== "Clear") {
      if (dropdownLabel === "Select All") {
        return checkedValue
          ? svgIcons("checkbox-marked-outline")
          : svgIcons("check-box-outline-blank");
      }
      if (dropdownLabel === "Select Specific") {
        return checkedValue
          ? svgIcons("checkbox-marked-outline")
          : svgIcons("check-box-outline-blank");
      }
    }
  };

  if ((sideBarList && sideBarList.length) || title === "Entity") {
    if (isSettingsPage) {
      return sideBarList.map((s, i) => (
        <div
          className={`bot-layout-settings-pg-left-panel ${
            s.isSelected && "bot-layout-selected-item"
          }`}
          key={i}
          onClick={() => handleSelect(s)}
        >
          <span className="bot-layout-sidebar-text">{s.label}</span>
        </div>
      ));
    } else if (isFormsPage) {
      return sideBarList.map((item, i) => (
        <div
          key={i}
          id={i}
          className={`${onQuePhraseHover === i ? " shadow-sm" : ""} my-2`}
          onMouseEnter={() => setQuePhraseHover(i)}
          onMouseLeave={() => setQuePhraseHover(false)}
        >
          <div className="row justify-content-between m-0 px-3 py-1">
            <div className="col-auto d-flex align-items-center">
              <div className="me-3 pb-2">
                {trainedIntent && trainedIntent.indexOf(item.label) > -1
                  ? svgIcons("activeBot", "#25293D")
                  : svgIcons("activeBot", "#e2e2e2")}
              </div>
              <i
                className={`fas ${
                  index === i ? "fa-caret-down" : "fa-caret-right"
                }  me-2 cursor-pointer`}
                onClick={() => {
                  if (index === null) {
                    onMenuSelect(item.label);
                    setIndex(i);
                  } else {
                    setIndex(null);
                  }
                }}
              />
              <Input
                className="forms-menu-text  text-overflow border-0"
                id={`formTip-${i}`}
                value={item.label}
                readOnly={readOnly === i ? false : true}
                onChange={(e) => handleNameChange(e, i)}
                onKeyPress={(e) => handleNameChange(e, i)}
                onBlur={() => handleRenameBlur()}
              />
              <UncontrolledTooltip placement="right" target={`formTip-${i}`}>
                {item.label}
              </UncontrolledTooltip>
            </div>
            <div
              className={`col-auto align-self-center p-0 ${
                onQuePhraseHover === i ? " d-flex" : " d-none"
              } `}
            >
              <DropDown
                isContent
                btnClassName="p-0"
                dropDownMenuMinWidth="11.5rem"
                actionButton={() => svgIcons("verticalOptions")}
                handleToggleClick={() =>
                  handleDropDownToggleClick(item.label, setIndex)
                }
                dropdownList={
                  typeof dropdownList === "function"
                    ? dropdownList(item.label)
                    : dropdownList
                }
                onActionItemClick={(menu) =>
                  handleDropDown(menu, i, item.label, index, setIndex)
                }
              />
            </div>
          </div>
          {/* {index === i &&
            formDetails &&
            formDetails.map((form, ix) => (
              <div
                className={`bot-menu py-2 ${
                  form.isSelected && "bot-layout-selected-item"
                }`}
              >
                <div
                  className="name-trim "
                  key={ix}
                  onClick={() => questionSelect(form.slot, ix)}
                >
                  <span className="offset-md-4 py-2"> {form.slot}</span>
                </div>
              </div>
            ))} */}
          {index === i && formDetails && (
            <div>
              <DndProvider backend={HTML5Backend}>
                {formDetails.map((form, index) => {
                  return renderSlotCards(form, index);
                })}
              </DndProvider>
            </div>
          )}
        </div>
      ));
    } else if (title === "Integrations") {
      const isLen = sideBarList.length;
      return sideBarList.map((s, i) => (
        <div className="row m-0 p-0" key={i}>
          <div className={`${isLen && i !== 0 && "mt-3"} p-0`}>
            <Row className="integration-sidebar-title">{s.type}</Row>
            {s.data.map((int, ind) => (
              <Row
                className={`m-0 integration-menu align-items-center ${
                  selectedIntegration?.label === int.value &&
                  "bot-layout-selected-item"
                }`}
                key={ind}
                onClick={() => onMenuSelect(int, i)}
              >
                <Col className="col-auto align-self-center w-45px">
                  {int.icon && int.icon}
                </Col>
                <Col className="pe-0 ps-2 name-trim integration-pg-side-bar-name">
                  <p className="m-0 name-trim">{int.label}</p>
                  <small className="text-muted">{int.description}</small>
                </Col>
              </Row>
            ))}
          </div>
        </div>
      ));
    } else if (title === "Flows") {
      return (
        <>
          <div className="row p-0 m-0 justify-content-start mt-3 bot-menu">
            <div className="col-2 ms-3 d-flex align-items-center">
              <img src={groupIcon} alt="groupIcon" style={{ height: "15px" }} />
            </div>
            <div className="col p-0 d-flex align-items-center ">
              <div
                className="name-trim"
                style={{ width: "180px" }}
                onClick={() =>
                  onMenuSelect(botService.getSelectedBot().bot_name)
                }
              >
                <strong className="pt-1 flow-path-header">
                  {botService.getSelectedBot().bot_name}
                </strong>
              </div>
            </div>
          </div>
          {sideBarList.map((s, i) => (
            <div
              className={`row m-0 px-25 bot-menu ${
                s.isSelected ? "bot-layout-selected-item" : ""
              }`}
              key={i}
              onClick={() => onMenuSelect(s.label)}
            >
              <div className="col-12 d-flex py-3 offset-1 align-items-center justify-content-between">
                <div className="bot-layout-sidebar-text ms-3 name-trim">
                  <span id={`flowTip-${i}`}>
                    {s.label === "main_path"
                      ? s.label.replace("_", " ")
                      : s.label.split(":")[0].substr(4)}
                  </span>
                  <UncontrolledTooltip
                    placement="right"
                    target={`flowTip-${i}`}
                  >
                    {s.label === "main_path"
                      ? s.label.replace("_", " ")
                      : s.label.split(":")[0].substr(4)}
                  </UncontrolledTooltip>
                </div>
              </div>
            </div>
          ))}
        </>
      );
    } else {
      if (title === "Entity") {
        return (
          <>
            <div className="row justify-content-between m-0 p-3 ">
              <div className="col-auto d-flex align-items-center">
                <i
                  className={`fas ${
                    entity === 1 ? "fa-caret-down" : "fa-caret-right"
                  }  me-2 cursor-pointer`}
                  onClick={() => {
                    entity === null || entity === 2
                      ? setEntity(1)
                      : setEntity(null);
                  }}
                />
                <span className="fw-bold">System Entities</span>
              </div>
              {systemEntities &&
                entity === 1 &&
                Object.keys(systemEntities).map((item, iy) => (
                  <div className="row bot-menu py-2 mx-0" key={iy}>
                    <div
                      className="col-auto pe-0"
                      // onClick={() => handleToggleCheck(item)}
                    >
                      {systemEntities[item]
                        ? svgIcons("check-box-marked")
                        : svgIcons("check-box")}
                    </div>
                    <div className="col name-trim">
                      <span className="">{item}</span>
                    </div>
                  </div>
                ))}
            </div>
            <div className="row justify-content-between m-0 p-3">
              <div className="col-auto d-flex align-items-center">
                <i
                  className={`fas ${
                    entity === 2 ? "fa-caret-down" : "fa-caret-right"
                  }  me-2 cursor-pointer`}
                  onClick={() => {
                    entity === null || entity === 1
                      ? setEntity(2)
                      : setEntity(null);
                  }}
                />
                <span className="fw-bold">Custom Entities</span>
              </div>
              {sideBarList &&
                entity === 2 &&
                sideBarList.map((item, ix) => {
                  let checkedValue =
                    checkedLabel.length && checkedLabel.includes(item.label);
                  return (
                    <div
                      className={`row bot-menu py-2 ${
                        item.isSelected && "bot-layout-selected-item"
                      }`}
                      key={ix}
                      onClick={() => onMenuSelect(item, ix)}
                    >
                      <div
                        className="col-auto pe-0 my-auto"
                        onClick={() => handleChange(item.label, !checkedValue)}
                      >
                        {displayCheckbox(checkedValue)}
                      </div>
                      <div className="img-fluid col-auto pe-0">
                        {trainedIntent && trainedIntent.indexOf(item.label) > -1
                          ? svgIcons("activeBot", "#25293D") //#329fff")
                          : svgIcons("activeBot", "#e2e2e2")}
                      </div>
                      <div className="col name-trim">
                        <span id={`entityTip-${ix}`}>{item.label}</span>
                        <UncontrolledTooltip
                          placement="right"
                          target={`entityTip-${ix}`}
                        >
                          {item.label}
                        </UncontrolledTooltip>
                      </div>
                    </div>
                  );
                })}
              <span></span>
            </div>
          </>
        );
      } else {
        return sideBarList.map((s, i) => {
          let checkedValue =
            checkedLabel &&
            checkedLabel.length &&
            checkedLabel.includes(s.label);
          return (
            <div
              className={`row m-0 px-25 bot-menu ${
                isIntegrations ? "pt-2 " : ""
              }${
                !isIntegrations
                  ? s.isSelected
                    ? "bot-layout-selected-item"
                    : ""
                  : selectedIntegration?.label === s.value
                  ? "bot-layout-selected-item"
                  : ""
              }`}
              key={i}
              onClick={() => onMenuSelect(s, i)}
            >
              {!isIntegrations && (
                <div
                  className={`col-12 d-flex ${isSettingsPage ? "p-0" : "py-3"}`}
                >
                  <div
                    className="col-auto px-1 pe-0 my-auto"
                    onClick={() => handleChange(s.label, !checkedValue)}
                  >
                    {displayCheckbox(checkedValue)}
                  </div>
                  <div className="my-auto px-3">
                    {
                      !isSettingsPage &&
                        // <img
                        //   alt="Bot"
                        //   className="mb-1 img-fluid me-3"
                        //   src={
                        (trainedIntent && trainedIntent.indexOf(s.label) > -1
                          ? svgIcons("activeBot", "#25293D")
                          : svgIcons("activeBot", "#e2e2e2"))
                      //   }
                      // />
                    }
                  </div>

                  <div className="bot-layout-sidebar-text name-trim my-auto">
                    <span id={`labelTip-${i}`}>{s.label}</span>
                  </div>
                  <UncontrolledTooltip
                    placement="right"
                    target={`labelTip-${i}`}
                  >
                    {s.label}
                  </UncontrolledTooltip>
                </div>
              )}
            </div>
          );
        });
      }
    }
  } else return null;
};
const isImport = ["Intent", "Entity", "Response", "Knowledge Base"];

const renderDropDownList = (title, dropDownItems, BulkImport, addBtnHide) => {
  let dropDownList = isImport.includes(title)
    ? [
        {
          label: `Add New ${title}`,
          isUsed: addBtnHide.new !== "enabled",
        },
        {
          label: `Import ${title}`,
          importModal: BulkImport(),
          isUsed: addBtnHide.import !== "enabled",
        },
      ]
    : [];

  switch (title) {
    case "Actions":
      dropDownList.push(
        { label: "Add new action", header: true },
        { label: "Code", isUsed: addBtnHide.new_action_code !== "enabled" },
        { label: "Config API", isUsed: addBtnHide.new_action_api !== "enabled" }
      );
      addBtnHide.new_action_api === "hide" &&
        (dropDownList = dropDownList.filter((e) => e.label !== "Config API"));
      addBtnHide.new_action_code === "hide" &&
        (dropDownList = dropDownList.filter((e) => e.label !== "Code"));
      break;
    case "Integrations":
      dropDownItems().forEach((d) => {
        const { list, title } = d;
        dropDownList.push({ label: title, subMenu: list, value: title });
      });
      break;
    case "Intent":
      dropDownList.splice(1, 0, {
        label: "Intent Library",
        isUsed: addBtnHide.library !== "enabled",
      });
      addBtnHide.library === "hide" &&
        (dropDownList = dropDownList.filter(
          (e) => e.label !== "Intent Library"
        ));
      break;
    case "Slots":
      dropDownList.push(
        { label: "Add new slot", header: true },
        { label: "Bool", value: "bool" },
        { label: "Categorical", value: "categorical" },
        { label: "List", value: "list" },
        { label: "Text", value: "text" },
        { label: "Unfeaturized", value: "unfeaturized" }
      );
      break;
    default:
      break;
  }

  addBtnHide.import === "hide" &&
    (dropDownList = dropDownList.filter((e) => e.label !== `Import ${title}`));
  addBtnHide.new === "hide" &&
    (dropDownList = dropDownList.filter((e) => e.label !== `Add New ${title}`));
  return dropDownList;
};

export default function BotLayoutLeftPanel({
  sideBarList,
  addFormQuestion,
  dropdownList,
  formDetails,
  handleAddQuestion,
  handleDropDown,
  handleDropDownToggleClick,
  handleNameChange,
  handleRenameBlur,
  handleSelect,
  handleToggleCheck,
  integrationDropDownItems,
  integrationsAddClick,
  intents,
  isFormsPage,
  isIntegrations,
  isSettingsPage,
  onChange,
  onKeyPress,
  onMenuSelect,
  questionSelect,
  readOnly,
  selectedIntegration,
  selectedIntent,
  // sideBarList = [],
  systemEntities,
  title,
  toggle,
  trainedIntent,
  value,
  isEntity,
  isIntent,
  isFaq,
  isResponse,
  getResponses,
  onPageRefresh,
  industryList,
  industryClick,
  libraryData,
  handleLibraryClick,
  addIntentLibrary,
  addLibraryStatus,
  searchLibrary,
  onCancelClick,
  handleLibraryExpand,
  renderSlotCards,
  BulkImport,
  onClickImport,
  addBtnHide,
  disableMultiDelete,
}) {
  const [isAddNew, setIsAddNew] = useState(false);
  const [index, setIndex] = useState(null);
  const [entity, setEntity] = useState(2);
  const [onQuePhraseHover, setQuePhraseHover] = useState(null);
  const dropDownRef = useRef();
  const industryDropDownRef = useRef();
  const [dropdownLabel, setDropdownLabel] = useState("");
  const [checkedLabel, setCheckedLabel] = useState([]);
  const [librayModal, setLibrayModal] = useState(false);
  const [delModal, setDelModal] = useState(false);
  const { setNotifications } = useContext(NotificationContext);

  const toggleCheckBox = (flag) => {
    if (flag.label === "Select All") {
      let updatedIntents = sideBarList.map((item) => {
        return item.label;
      });
      setCheckedLabel(updatedIntents);
      return;
    }
    if (flag.label === "Clear") {
      let updatedIntents = [];
      setCheckedLabel(updatedIntents);
      return;
    }
  };

  const toggleSubCheckbox = (check, label) => {
    if (check) {
      let updatedCheckedLabel = [...checkedLabel, label];
      setCheckedLabel(updatedCheckedLabel);
    } else {
      let index = checkedLabel.indexOf(label);
      checkedLabel.splice(index, 1);
      setCheckedLabel(checkedLabel);
    }
  };

  const multiSelectionDelete = () => {
    setCheckedLabel([]);
    setTimeout(() => {
      onPageRefresh();
    }, 100);
  };

  const getModal = () => {
    return (
      <AddNewModal
        noCloseBtn
        addNewModalOpen={librayModal}
        headerClassName={"mb-5"}
        bodyClassName={"p-5 pb-3"}
        toggle={() => setLibrayModal(!librayModal)}
        title={"Global Intent Library"}
        children={
          <>
            <div className=" mb-3">
              <div className="">
                <div className="d-flex align-items-center mb-4">
                  <Input
                    className="bot-layout-inp pe-5"
                    placeholder="Search intents from library"
                    onChange={searchLibrary}
                    onKeyPress={searchLibrary}
                  />
                  {svgIcons("search", "", "library-search")}
                </div>
                <div className="row m-0 mb-4 align-items-center m-auto">
                  <div className="col-auto ps-0">
                    <DropDown
                      btnClassName="intent-library-industry"
                      dropdownList={[
                        { label: "Select All" },
                        { label: "Clear" },
                      ]}
                      actionButton={() => (
                        <div className="row justify-content-between">
                          <div className="col-auto">Select All</div>
                          <div className="col-auto me-2">
                            {svgIcons("chevronDown", "black", "", 15)}
                          </div>
                        </div>
                      )}
                      onActionItemClick={(m) => handleLibraryClick(m)}
                    />
                  </div>
                  <div className="col-auto">
                    <DropDown
                      globalIntent={true}
                      ref={industryDropDownRef}
                      btnClassName="intent-library-industry"
                      dropdownList={industryList.map((ele) => {
                        ele.isSelected && ele.icon
                          ? (ele.icon = svgIcons("check-box-marked"))
                          : (ele.icon = svgIcons("check-box-outline-blank"));
                        return ele;
                      })}
                      actionButton={() => (
                        <div className="row justify-content-between m-0">
                          <div className="col-auto p-0">
                            {industryList.filter((ele) => ele.isSelected)
                              .length > 0
                              ? "Industry selected"
                              : "Industry"}
                          </div>
                          <div className="col-auto p-0">
                            {svgIcons("chevronDown", "black", "", 15)}
                          </div>
                        </div>
                      )}
                      onActionItemClick={(i) =>
                        industryClick(i, "", industryDropDownRef)
                      }
                      isButtons={
                        <div className="row m-0 justify-content-around mt-3">
                          <Button
                            className="col-auto btn btn-sm btn-light"
                            onClick={() =>
                              industryClick("", "cancel", industryDropDownRef)
                            }
                            styles={{
                              border: "1px solid #505981",
                              color: "#515a81",
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="col-auto btn btn-sm text-white"
                            onClick={() =>
                              industryClick("", "confirm", industryDropDownRef)
                            }
                            styles={{ background: "#515a81" }}
                          >
                            Confirm
                          </Button>
                        </div>
                      }
                    />
                  </div>
                  <div className="col justify-content-end align-items-end d-flex">
                    {libraryData.length > 0 &&
                      `No. of Intents - ${libraryData.length}`}
                  </div>
                </div>
                <div className="library-accordian-cont overflow-auto">
                  <Accordion
                    libraryData={libraryData}
                    addLibraryStatus={addLibraryStatus}
                    handleLibraryClick={handleLibraryClick}
                    handleLibraryExpand={handleLibraryExpand}
                  />
                </div>
              </div>
            </div>
            <div className="row m-0 justify-content-around">
              <Button
                className="btn col-auto btn-cancel-modal"
                onClick={() => onCancelClick(() => setLibrayModal(false))}
              >
                Cancel
              </Button>
              <Button
                isDisabled={!libraryData.filter((e) => e.isSelected).length}
                className="btn col-auto btn-confirm-modal"
                onClick={() => addIntentLibrary(() => setLibrayModal(false))}
              >
                Add
              </Button>
            </div>
          </>
        }
      />
    );
  };

  const dropDownAction = (m) => {
    if (isIntegrations) {
      integrationsAddClick(m);
      dropDownRef.current.toggle();
    } else if (isImport.includes(title)) {
      m.label === `Add New ${title}` && setIsAddNew(true);
      m.label === `Import ${title}` && onClickImport();
      m.label === "Intent Library" && setLibrayModal(true);
    } else toggle(m);
  };

  const handleDel = (title) => {
    if (title === "Response") {
      ApiService.deleteBotUtterance({
        utterance: checkedLabel,
      }).then(() => {
        setCheckedLabel([]);
        setTimeout(() => {
          getResponses();
        }, 100);
      });
    } else if (title === "Knowledge Base") {
      ApiService.deleteBotFaq({ faq: checkedLabel }).then(multiSelectionDelete);
    } else if (title === "Entity") {
      ApiService.deleteBotEntity({ entity: checkedLabel }).then(
        multiSelectionDelete
      );
    } else if (title === "Intent") {
      ApiService.deleteBotIntent({ intent: checkedLabel }).then(
        multiSelectionDelete
      );
    }
  };

  const getDeleteModal = (title) => {
    return (
      <DeleteConfirmation
        open={delModal}
        iconColor={"#51597e"}
        delete={() => handleDel(title)}
      />
    );
  };

  return (
    <div
      className={`col-auto h-100 p-0 ${isSettingsPage && "p-0 border"}`}
      style={{ width: "16rem" }}
    >
      {/* Removed overflow-hidden 'BS' class on first div tag because in integration page it's not necessary also checked other pages & it was not necessary. */}
      <div
        className={`row align-items-center mx-0 px-25${
          !isSettingsPage ? " my-25" : ""
        }`}
      >
        <div className="col-auto p-0 pe-1 d-flex">
          {!isSettingsPage &&
          checkedLabel &&
          (isEntity || isIntent || isFaq || isResponse)
            ? (checkedLabel.length === 0 && (
                <div className="mb-0 ms-3">
                  {svgIcons("check-box-outline-blank")}
                </div>
              )) ||
              (checkedLabel.length > 0 && (
                <div className="ms-3"> {svgIcons("check-box-minus")}</div>
              ))
            : ""}
          {!isSettingsPage &&
            checkedLabel &&
            (isEntity || isIntent || isFaq || isResponse) && (
              <DropDown
                caret={true}
                disabled={disableMultiDelete}
                actionButton={() => (
                  <i className="fas fa-caret-down mx-2 mt-1" />
                )}
                btnClassName="p-0 m-0 caret-icon"
                dropdownList={[
                  { label: "Select All" },
                  { label: "Select Specific" },
                  { label: "Clear" },
                ]}
                onActionItemClick={(label) => {
                  setDropdownLabel(label.label);
                  toggleCheckBox(label);
                }}
              />
            )}
        </div>
        {!isSettingsPage && (
          <>
            <div className="col p-0">
              <p className="bot-left-main-title m-0">{title}</p>
            </div>
            {[
              "Actions",
              "Integrations",
              "Slots",
              "Intent",
              "Knowledge Base",
              "Entity",
              "Response",
            ].includes(title) &&
            addBtnHide &&
            Object.keys(addBtnHide).some((e) => addBtnHide[e] !== "hide") ? (
              <div className="col-auto p-0 align-items-center d-flex">
                {checkedLabel?.length > 0 ? (
                  <span
                    className="cursor-pointer"
                    onClick={() => setDelModal(true)}
                  >
                    {svgIcons("delete", "#51597e")}
                  </span>
                ) : (
                  <DropDown
                    ref={dropDownRef}
                    isIntentModal={title === "Intent" && getModal}
                    actionButton={() => svgIcons("add", "#51597e")}
                    btnClassName="p-0 m-0"
                    dropdownList={renderDropDownList(
                      title,
                      integrationDropDownItems,
                      BulkImport,
                      addBtnHide
                    )}
                    // isContent={!isIntegrations}
                    isMultiLevelDropDown={isIntegrations}
                    itemClassName={`${
                      isIntegrations && "integration-dropdown-item"
                    }`}
                    subMenuClass="my-1 submenu"
                    onActionItemClick={(m) => dropDownAction(m)}
                  />
                )}
              </div>
            ) : addBtnHide &&
              Object.keys(addBtnHide).some((e) => addBtnHide[e] !== "hide") ? (
              <div
                className="col-auto p-0 align-items-center d-flex cursor-pointer"
                onClick={() => {
                  if (checkedLabel.length) {
                    setDelModal(true);
                  } else {
                    setIsAddNew(true);
                    setEntity(false);
                  }
                }}
              >
                {checkedLabel && checkedLabel.length
                  ? svgIcons("delete", "#51597e")
                  : (!isAddNew && svgIcons("add", "#51597e")) || null}
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
      <hr className="m-0 ms-2" />
      {isAddNew && (
        <div className="row m-0 mt-2 px-25">
          <Input
            className="bot-layout-inp rounded"
            placeholder={`Enter new ${title}`}
            onKeyPress={(e) => {
              onKeyPress && onKeyPress(e);
              if (e.key === "Enter") {
                setIsAddNew(false);
                setIndex(null);
                setEntity(2);
              }
            }}
            onBlur={() => {
              setIsAddNew(false);
              setEntity(2);
            }}
            onChange={onChange}
            value={value}
            autoFocus={true}
          />
        </div>
      )}

      <div
        className={`overflow-auto ${
          !isSettingsPage && title !== "Flows" && "mt-3"
        }`}
        style={{ height: "calc(100% - 5.5rem)" }}
      >
        <RenderSideBarItems
          addFormQuestion={addFormQuestion}
          dropdownList={dropdownList}
          entity={entity}
          formDetails={formDetails}
          handleAddQuestion={handleAddQuestion}
          handleDropDown={handleDropDown}
          handleDropDownToggleClick={handleDropDownToggleClick}
          handleNameChange={handleNameChange}
          handleSelect={handleSelect}
          handleToggleCheck={handleToggleCheck}
          index={index}
          intents={intents}
          isFormsPage={isFormsPage}
          isIntegrations={isIntegrations}
          isSettingsPage={isSettingsPage}
          onMenuSelect={(menu, i) => onMenuSelect(menu, i)}
          // onMenuSelect={onMenuSelect}
          onQuePhraseHover={onQuePhraseHover}
          questionSelect={questionSelect}
          readOnly={readOnly}
          selectedIntegration={selectedIntegration}
          selectedIntent={selectedIntent}
          setEntity={setEntity}
          setIndex={setIndex}
          setQuePhraseHover={setQuePhraseHover}
          sideBarList={sideBarList}
          systemEntities={systemEntities}
          title={title}
          trainedIntent={trainedIntent}
          handleRenameBlur={handleRenameBlur}
          checkedLabel={checkedLabel}
          toggleSubCheckbox={toggleSubCheckbox}
          dropdownLabel={dropdownLabel}
          renderSlotCards={renderSlotCards}
        />
      </div>
      {delModal && getDeleteModal(title)}
    </div>
  );
}
