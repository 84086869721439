import { Component } from "react";

import Button from "../../components/fields/button/button.component";
import DropDown from "../../components/fields/dropDown/dropDown.component";
import Input from "../../components/fields/input/input.component";
import TextArea from "../../components/fields/textArea/textArea.component";

import dropDown from "../../assets/icons/dropDown.svg";
import maskOne from "../../assets/icons/mask-1.svg";

import apiService from "../../services/api.services";
import botServices from "../../services/bot.service";
import getIcon from "../../services/icon.service";

import "./createBot.scss";
import baseUrl from "../../services/baseURL.service";
import svgIcons from "../../services/icon.service";

class CreateBot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      channelIcons: [],
      regions: [],
      timeZones: [],
      industries: [],
      languages: [],
      selectedLanguage: "",
      selectedRegion: "",
      selectedTimeZone: "",
      selectedIndustry: "",
      selectedIndustryIcon: "",
      bot: {
        name: "",
        channelName: [],
        callType: "",
        desc: "",
      },
      selectedCallType: [],
      selectedBotCategory: "wf",
    };
  }

  componentDidMount() {
    const bot = this.props.location.state?.bot;
    if (bot) {
      this.setState(
        {
          bot: { ...this.state.bot, ...bot, callType: bot.bot_type },
          selectedLanguage: bot.language,
          selectedIndustry: bot.bot_industry,
          selectedTimeZone: bot.timezone,
          selectedRegion: bot.region,
          selectedIndustryIcon: bot.icon,
          selectedCallType: bot.bot_type ? ["_voiceBased"] : [],
        },
        () => this.getLanguages()
      );
    }
  }

  getRegions = () => {
    if (this.state.regions.length === 0) {
      apiService
        .getRegions()
        .then((res) => {
          let regions = res.data.map((r) => ({ label: r }));
          this.setState({ regions });
        })
        .catch((error) => console.log(error));
    }
  };

  getLanguages = () => {
    if (this.state.languages.length === 0) {
      apiService
        .getLanguages()
        .then((res) => {
          let languages = res.data.map((language) => {
            const separetedText = language.split("-");
            let label = "";
            if (separetedText.length > 1) {
              const _1stLetter = separetedText[0][0].toUpperCase();
              const remainingLetters = separetedText[0].substr(1);

              label = `${_1stLetter}${remainingLetters} ${separetedText[1].toUpperCase()}`;
            } else label = language[0].toUpperCase() + language.substr(1);

            return {
              label,
              value: language,
            };
          });
          const transliteration_code = res.lan_transliteration_code;
          this.setState({ languages, transliteration_code });
        })
        .catch((error) => console.log(error));
    }
  };

  getTimeZone = () => {
    apiService
      .getTimeZone(this.state.selectedRegion)
      .then((res) => {
        const timeZones = res.data.map((r) => ({
          label: r.timezone + " - (" + r.offset + ")",
        }));
        this.setState({ timeZones });
      })
      .catch((error) => console.log(error));
  };

  getIndustries = () => {
    if (this.state.industries.length === 0) {
      apiService
        .getIndustries()
        .then((res) => {
          let industries = res.data.map((i) => ({
            label: i.industry,
            iconURL: i.icons[0],
          }));
          this.setState({ industries });
        })
        .catch((error) => console.log(error));
    }
  };

  createBot = async () => {
    try {
      const {
        bot,
        languages,
        selectedCallType,
        selectedIndustry,
        selectedIndustryIcon,
        selectedLanguage,
        selectedRegion,
        selectedTimeZone,
        transliteration_code,
        selectedBotCategory,
      } = this.state;

      const body = {
        bot_description: bot.desc,
        bot_icon: selectedIndustryIcon,
        bot_industry: selectedIndustry,
        bot_name: bot.name,
        language:
          languages.filter((language) => language.label === selectedLanguage)[0]
            ?.value || selectedLanguage,
        region: selectedRegion,
        timezone: selectedTimeZone,
        transliteration_code:
          transliteration_code[
            languages.filter(
              (language) => language.label === selectedLanguage
            )[0]?.value
          ] || transliteration_code[selectedLanguage],
        bot_category: selectedBotCategory,
      };

      bot.icon && (body.template_id = bot.template_id);

      if (selectedCallType.length) {
        if (selectedCallType.includes("_voiceBased")) {
          body.voice_bot = true;
          body.bot_type = bot.callType;
        }
        if (selectedCallType.includes("_chatBased")) {
          body.chat_bot = true;
        }
      }

      const r = await apiService.createBotInFlowChart(body);

      if (r.status === "success") {
        const botId = r.data.bot_id;
        botServices.setSelectedBotId(botId);
        this.getBotDetails(botId);
      }
    } catch (e) {
      console.log(e);
    }
  };

  getBotDetails = (botId) => {
    apiService
      .getBotDetails(botId)
      .then((res) => {
        if (res.status === "success") {
          botServices.setSelectedBot(res.data);
          this.props.history.push("/bot/flows");
        }
      })
      .catch((error) => console.log(error));
  };

  // renderChannelIcons = () => {
  //   const { channelIcons, bot } = this.state;
  //   return channelIcons.map((c, i) => (
  //     <div
  //       className={`channel-icon-container ${
  //         bot.channelName.includes(c.label) && "selected-bot-channel"
  //       }`}
  //       onClick={() => this.handleChannelSelection(c)}
  //       key={i}
  //     >
  //       <img
  //         alt={c.label}
  //         className="img-fluid"
  //         style={{ width: "2.5rem" }}
  //         src={c.icon}
  //       />
  //       <p className={`mb-0 create-bot-inp-label text-center`}>{c.label}</p>
  //     </div>
  //   ));
  // };

  handleChange = (e, name) => {
    const bot = { ...this.state.bot };
    bot[name] = e.target.value;
    this.setState({ bot });
  };

  handleChannelSelection = (c) => {
    const bot = { ...this.state.bot };
    let channels = [...bot.channelName];
    const isSelected = channels.includes(c.label);

    if (isSelected) channels = channels.filter((ch) => c.label !== ch);
    else channels = [...channels, c.label];

    bot.channelName = channels;

    this.setState({ bot });
  };

  handleCallTypeSelection = (callType) => {
    const bot = { ...this.state.bot };
    bot.callType = callType;
    this.setState({ bot });
  };

  handleClear = () => {
    let bot = {
      ...this.state.bot,
      callType: "",
      channelName: [],
      desc: "",
      name: "",
    };

    this.setState({
      bot,
      selectedIndustry: "",
      selectedIndustryIcon: "",
      selectedLanguage: "",
      selectedRegion: "",
      selectedTimeZone: "",
      selectedBotCategory: "wf",
    });
  };

  handleImgUpload = ({ target }) => {
    let file = target.files;
    if (file.length) {
      const formData = new FormData();
      formData.append("file", file[0]);
      apiService
        .uploadImageOnCustRep(formData)
        .then((res) => {
          if (res.status === "success") {
            this.setState(
              { selectedIndustryIcon: res.url },
              (target.value = null)
            );
          }
        })
        .catch((err) => console.log(err));
    }
  };

  handleBtnDisable = () => {
    const {
      bot,
      selectedLanguage,
      selectedRegion,
      selectedTimeZone,
      selectedCallType,
      selectedIndustry,
      selectedBotCategory,
      disableBtn,
    } = this.state;

    return (
      disableBtn ||
      !selectedLanguage ||
      !bot.name ||
      !selectedRegion ||
      !selectedIndustry ||
      !selectedTimeZone ||
      !selectedCallType.length ||
      !selectedBotCategory ||
      (selectedCallType.includes("_voiceBased") && !bot.callType)
      // !bot.channelName.length ||
      // !bot.callType
    );
  };

  handleSelectionOfCallType = (type) => {
    let selectedCallType = [...this.state.selectedCallType];

    if (!selectedCallType.includes(type)) selectedCallType.push(type);
    else selectedCallType = selectedCallType.filter((s) => s !== type);

    this.setState({ selectedCallType });
  };

  render() {
    const {
      bot,
      regions,
      selectedRegion,
      selectedTimeZone,
      timeZones,
      industries,
      selectedIndustry,
      languages,
      selectedLanguage,
      selectedIndustryIcon,
      selectedCallType,
      selectedBotCategory,
    } = this.state;

    return (
      <div className="container-fluid h-100 overflow-auto mt-1 bg-smoke">
        <div className={`container ${bot.icon ? "pt-4" : "pt-5"}`}>
          {bot.icon && (
            <div className="row mt-1">
              <div className="col-6">
                <div className="row">
                  <div className="col-3 d-flex align-items-center">
                    <label className="create-bot-inp-label">
                      Template Name
                    </label>
                  </div>
                  <div className="col">
                    <Input
                      className="create-bot-input ps-4"
                      type="text"
                      value={bot.template_name}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="row my-4">
            <div className="col-6">
              <div className="row">
                <div className="col-3 d-flex align-items-center">
                  <label
                    className="create-bot-inp-label"
                    htmlFor="create-bot-name"
                  >
                    Name
                  </label>
                </div>
                <div className="col">
                  <Input
                    className="create-bot-input ps-4"
                    id="create-bot-name"
                    onChange={(e) => this.handleChange(e, "name")}
                    placeholder="Give a name for your bot"
                    type="text"
                    value={bot.name}
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-3 d-flex align-items-center">
                  <label
                    className="create-bot-inp-label"
                    htmlFor="create-bot-industry"
                  >
                    Industry
                  </label>
                </div>
                <div className="col">
                  <DropDown
                    handleToggleClick={this.getIndustries}
                    dropdownList={industries}
                    isContent={true}
                    btnClassName="create-bot-input"
                    actionButton={() => (
                      <div className="row">
                        <div
                          className={`col-auto ms-4 ${
                            selectedIndustry
                              ? "create-bot-inp-label"
                              : "create-bot-secondary-text"
                          }`}
                        >
                          {selectedIndustry || "Select Industry"}
                        </div>
                        <div className="col"></div>
                        <div className="col-auto me-4">
                          <img
                            alt="Chevron down"
                            className="img-fluid"
                            src={dropDown}
                          />
                        </div>
                      </div>
                    )}
                    onActionItemClick={({ label, iconURL }) => {
                      this.setState({
                        selectedIndustry: label,
                        selectedIndustryIcon: iconURL,
                      });
                    }}
                    itemClassName=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="row">
                <div className="col-3 d-flex align-items-center">
                  <label
                    className="create-bot-inp-label"
                    htmlFor="create-bot-time-zone"
                  >
                    Region
                  </label>
                </div>
                <div className="col">
                  <DropDown
                    handleToggleClick={this.getRegions}
                    dropdownList={regions}
                    isContent={true}
                    btnClassName="create-bot-input"
                    actionButton={() => (
                      <div className="row">
                        <div
                          className={`col ms-4 text-start ${
                            selectedRegion
                              ? "create-bot-inp-label"
                              : "create-bot-secondary-text"
                          }`}
                        >
                          {selectedRegion || "Select Region"}
                        </div>
                        <div className="col-auto me-4">
                          <img
                            alt="Chevron down"
                            className="img-fluid"
                            src={dropDown}
                          />
                        </div>
                      </div>
                    )}
                    onActionItemClick={({ label }) => {
                      this.setState(
                        { selectedRegion: label, selectedTimeZone: "" },
                        this.getTimeZone
                      );
                    }}
                    itemClassName=""
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-3 d-flex align-items-center">
                  <label
                    className="create-bot-inp-label"
                    htmlFor="create-bot-lang"
                  >
                    Language
                  </label>
                </div>
                <div className="col">
                  <DropDown
                    handleToggleClick={this.getLanguages}
                    dropdownList={bot.language ? [] : languages}
                    isContent
                    btnClassName="create-bot-input"
                    actionButton={() => (
                      <div className="row">
                        <div
                          className={`col ms-4 text-start ${
                            selectedLanguage
                              ? "create-bot-inp-label"
                              : "create-bot-secondary-text"
                          }`}
                        >
                          {selectedLanguage ||
                            bot.language ||
                            "Select Language"}
                        </div>
                        <div className="col-auto me-4">
                          <img
                            alt="Chevron down"
                            className="img-fluid"
                            src={dropDown}
                          />
                        </div>
                      </div>
                    )}
                    onActionItemClick={({ label }) => {
                      this.setState({ selectedLanguage: label });
                    }}
                    itemClassName=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row my-4">
            <div className="col-6">
              <div className="row mb-5">
                <div className="col-3 d-flex align-items-center">
                  <label
                    className="create-bot-inp-label"
                    htmlFor="create-bot-lang"
                  >
                    Time zone
                  </label>
                </div>
                <div className="col">
                  <DropDown
                    disabled={!selectedRegion && true}
                    isContent={true}
                    dropdownList={timeZones}
                    btnClassName="create-bot-input"
                    actionButton={() => (
                      <div className="row">
                        <div
                          className={`col ms-4 text-start ${
                            selectedTimeZone
                              ? "create-bot-inp-label"
                              : "create-bot-secondary-text"
                          }`}
                        >
                          {selectedTimeZone || "Select time zone"}
                        </div>
                        <div className="col-auto me-4">
                          <img
                            alt="Chevron down"
                            className="img-fluid"
                            src={dropDown}
                          />
                        </div>
                      </div>
                    )}
                    onActionItemClick={({ label }) => {
                      this.setState({ selectedTimeZone: label });
                    }}
                    itemClassName="overflow-auto"
                  />
                </div>
              </div>
              <div className="col mx-2">
                <div className="row">
                  <div className="col-auto px-1" style={{ width: "10rem" }}>
                    <p className="create-bot-inp-label">Bot Icon</p>
                  </div>
                  <div className="col-auto">
                    <div className="create-bot-plus-icon-container">
                      <Input
                        type="file"
                        id="create-bot-img-upload"
                        onChange={this.handleImgUpload}
                        accept="image/*"
                        hidden
                      />
                      <label
                        className="d-flex align-items-center cursor-pointer"
                        htmlFor="create-bot-img-upload"
                      >
                        {getIcon("plus", "white")}
                      </label>
                    </div>
                    <p className="create-bot-secondary-text mt-4">Gallery</p>
                  </div>
                  <div className="col-auto ps-4">
                    <div className="row justify-content-center">
                      <div style={{ width: "6rem" }}>
                        <img
                          alt="default bot icon"
                          style={{ height: "3.9rem", width: "3.9rem" }}
                          className="cursor-pointer rounded-circle"
                          src={
                            selectedIndustryIcon
                              ? baseUrl + selectedIndustryIcon
                              : maskOne
                          }
                        />
                      </div>
                    </div>
                    <p className="create-bot-secondary-text mt-4">
                      {selectedIndustryIcon ? "Selected Icon" : "Default Icons"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row align-items-centr mb-4">
                <div className="col-3 d-flex align-items-center">
                  <label
                    className="create-bot-inp-label"
                    htmlFor="create-bot-lang"
                  >
                    Bot Category
                  </label>
                </div>
                <div className="col">
                  <DropDown
                    dropdownList={[
                      { label: "Workflow Bot" },
                      { label: "Knowledgebase Bot" },
                      { label: "Workflow & Knowledgebase Bot" },
                    ]}
                    isContent
                    btnClassName="create-bot-input"
                    actionButton={() => (
                      <div className="row">
                        <div
                          className={
                            "col ms-4 text-start  create-bot-inp-label"
                          }
                        >
                          {selectedBotCategory === "wf"
                            ? "Workflow Bot"
                            : selectedBotCategory === "kb"
                            ? "Knowledgebase Bot"
                            : "Workflow & Knowledgebase Bot"}
                        </div>
                        <div className="col-auto me-4">
                          <img
                            alt="Chevron down"
                            className="img-fluid"
                            src={dropDown}
                          />
                        </div>
                      </div>
                    )}
                    onActionItemClick={({ label }) => {
                      if (label === "Workflow Bot")
                        this.setState({ selectedBotCategory: "wf" });
                      else if (label === "Knowledgebase Bot")
                        this.setState({ selectedBotCategory: "kb" });
                      else if (label === "Workflow & Knowledgebase Bot")
                        this.setState({ selectedBotCategory: "wf_kb" });
                    }}
                    itemClassName=""
                  />
                </div>
              </div>
              <div className="row align-items-centr">
                <div className="col-3">
                  <label
                    className="create-bot-inp-label mb-4"
                    htmlFor="create-bot-desc"
                  >
                    Description
                  </label>
                </div>
                <div className="col">
                  <TextArea
                    className="create-bot-input p-2"
                    placeholder="Type your description"
                    id="create-bot-desc"
                    rows={4}
                    onchange={(e) => this.handleChange(e, "desc")}
                    value={bot.desc}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row my-4">
            <div className="col">
              <div className="row">
                <div className="col-auto" style={{ width: "9.3rem" }}>
                  <p className="create-bot-inp-label m-0 mt-2">Bot Type</p>
                </div>
                <div className="col d-flex align-items-center px-2 mx-3">
                  <div className="row m-0">
                    <div
                      className="col-auto ps-1"
                      onClick={() =>
                        this.handleSelectionOfCallType("_chatBased")
                      }
                    >
                      {selectedCallType.includes("_chatBased")
                        ? svgIcons("check-box-marked")
                        : svgIcons("check-box")}
                    </div>
                    <label className="col mb-3 icon-select-font">
                      Enable chat bot
                    </label>
                    <div className="row m-0 p-0">
                      <div
                        className="col-auto ps-1"
                        onClick={() =>
                          this.handleSelectionOfCallType("_voiceBased")
                        }
                      >
                        {selectedCallType.includes("_voiceBased")
                          ? svgIcons("check-box-marked")
                          : svgIcons("check-box")}
                      </div>
                      <label className="col icon-select-font">
                        Enable voice bot
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row offset-1" style={{ height: "5rem" }}>
            {selectedCallType.includes("_voiceBased") && (
              <div className="col d-flex mx-4 px-3">
                <div
                  className={`create-bot-call-type border ${
                    bot.callType === "inbound" && "selected-call-type"
                  }`}
                  onClick={() => this.handleCallTypeSelection("inbound")}
                >
                  {getIcon("hangCall")} <p>Inbound</p>
                </div>
                <div
                  className={`create-bot-call-type border ${
                    bot.callType === "outbound" && "selected-call-type"
                  }`}
                  onClick={() => this.handleCallTypeSelection("outbound")}
                >
                  {getIcon("pickCall")} <p>Outbound</p>
                </div>
              </div>
            )}
          </div>

          <div className="row" style={{ margin: "5rem 0 3rem" }}>
            <div className="col-6 text-end">
              <Button
                className="create-bot-btn"
                type="reset"
                onClick={this.handleClear}
              >
                Clear
              </Button>
            </div>
            <div className="col-6">
              <Button
                className="create-bot-btn create-bot-btn-ok"
                onClick={() => {
                  this.setState({ disableBtn: true });
                  this.createBot();
                }}
                styles={{ opacity: `${this.handleBtnDisable() ? "0.4" : "1"}` }}
                isDisabled={this.handleBtnDisable()}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateBot;
