import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import svgIcons from "../services/icon.service";
import Buttons from "./fields/button/button.component";

const DeleteConfirmation = (props) => {
  const [isModal, setModal] = useState(props.open);

  const toggle = () => setModal(!isModal);

  const deleted = () => props.delete();

  const notDeleted = () => {
    // console.log("The element has not been deleted")
  };

  return (
    <>
      {props.isButton ? (
        <Buttons
          className={`${
            props.btnClassName ? props.btnClassName : "alert-danger"
          } bot-del-btn-font`}
          onClick={() => {
            toggle();
          }}
        >
          {props.buttons ? (
            props.buttons()
          ) : (
            <>{svgIcons("delete", "red", "me-2")}Delete</>
          )}
        </Buttons>
      ) : props.isToggle ? (
        ""
      ) : (
        svgIcons("delete", props.iconColor && props.iconColor)
      )}
      <Modal
        color="black"
        isOpen={isModal}
        style={{ marginTop: "40vh" }}
        toggle={toggle}
      >
        <ModalBody className="bg-white my-modal bot-del-btn-font">
          Are you sure you want to Delete?
        </ModalBody>
        <ModalFooter className="bg-white border-0 modal-footer">
          <Button
            className="btn intents-btn w-auto rounded-3 prevent-click-event-triger px-5 py-2 text-white btns-font"
            onClick={() => {
              toggle();
              deleted();
            }}
          >
            Delete
          </Button>
          <Button
            className="btn btn-outline-light w-auto rounded-3 prevent-click-event-triger px-5 py-2 btns-font"
            onClick={() => {
              toggle();
              notDeleted();
              props.cancel && props.cancel();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DeleteConfirmation;
