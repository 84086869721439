import React, { useEffect, useState } from "react";
import HorizontalScroll from "./horizontalScroll";
import DropDown from "../fields/dropDown/dropDown.component";
import getIcon from "../../services/icon.service";
import "./horizontalScroll.scss";

function ResHorizontalScroll({
  items,
  handleNavMenuSelect,
  selectedChannel,
  deleteChannel,
  navBarMenu,
  navDropDownDisable,
}) {
  const [menu, setMenu] = useState([]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     let temp = items.filter((i) => i.isUsed || i.label === "Default");
  //     setMenu([...temp]);
  //   }, 200);
  // }, [items]);
  return (
    <div className="ps-1 row m-0 align-items-center w-100">
      <div className="col" style={{ width: "calc(100% - 10rem)" }}>
        <HorizontalScroll
          items={navBarMenu}
          isRes={true}
          handleSelect={handleNavMenuSelect}
          selectedChannel={selectedChannel}
          deleteChannel={deleteChannel}
        />
      </div>
      <div
        className="col-auto text-white d-flex justify-content-center ps-0"
        style={{ width: "10rem" }}
      >
        <DropDown
          disabled={navDropDownDisable}
          actionButton={() => (
            <span>{getIcon("plus", "white", "pe-2", "11")} Channel</span>
          )}
          btnClassName="text-white btn-sm channel-dropdown p-0"
          dropdownList={items
            ?.filter((i) => i?.value !== "Default")
            ?.map((i) => {
              return {
                ...i,
                icon: getIcon(i.value, "", "me-3"),
                channel: true,
                isUsed: navDropDownDisable || i.isUsed,
              };
            })
            .sort((x) => (x.isUsed ? 1 : -1))}
          onActionItemClick={(m) => {
            // setMenu([...menu, m]);
            handleNavMenuSelect(m, true);
          }}
        />
      </div>
    </div>
  );
}

export default ResHorizontalScroll;
