import React, { useEffect, useState, useRef, useContext } from "react";
import { Button, Input } from "reactstrap";
import { ChromePicker } from "react-color";
import "./WebSdkConfigurations.scss";
import { getToken } from "../../services/authUtil";
import baseUrl from "../../services/baseURL.service";
import botService from "../../services/bot.service";
import getIcon from "../../services/icon.service";
import ApiService from "../../services/api.services";
import { NotificationContext } from "../../contexts/notficationContext";

const popover = {
  // position: "absolute",
  zIndex: "2",
};
const cover = {
  position: "fixed",
  top: "0px",
  right: "0px",
  bottom: "0px",
  left: "0px",
};

const WebSdkConfigurations = () => {
  const [widgetIcon, setWidgetIcon] = useState("");
  const [widgetTitle, setWidgetTitle] = useState("");
  const [widgetIconBgColor, setWidgetIconBgColor] = useState("");
  const [widgetBotIcon, setWidgetBotIcon] = useState();
  const bgRef = useRef();
  const tRef = useRef();
  const [widgetFontColor, setWidgetFontColor] = useState("");
  const [selectColor, setSelectColor] = useState("");
  const [widgetShape, setWidgetShape] = useState();
  const [uploadWidgetImage, setUploadWidgetImage] = useState("");
  const [uploadBotImage, setUploadBotImage] = useState("");

  const [selectedWidgetShape, setSelectedWidgetShape] = useState();
  const [selectedBotImage, setSelectedBotImage] = useState();

  const [tColor, setTColor] = useState(false);
  const [bgColor, setBgColor] = useState(false);

  const { setNotifications } = useContext(NotificationContext);

  useEffect(() => {
    getBotConfig();
  }, []);

  const getBotConfig = () => {
    let bot_id = botService.getSelectedBotId();
    let reqOptions = {
      method: "GET",
      headers: {
        token: getToken(),
      },
      mode: "cors",
    };
    fetch(`${baseUrl}/get_bot_sdk/${bot_id}`, reqOptions)
      .then((response) => response.json())
      .then((respJson) => {
        if (respJson.status === "success") {
          respJson.data.bot_icon
            ? setSelectedBotImage(respJson.data.bot_icon)
            : setSelectedBotImage(botService.getSelectedBot().bot_icon);
          setWidgetTitle(respJson.data.web_widget_bot_title);
          setSelectColor(respJson.data.web_widget_theme_color);
          setSelectedWidgetShape(respJson.data.web_widget_shape);
          setWidgetIconBgColor(respJson.data.web_widget_background_color);
          setWidgetIcon(respJson.data.web_widget_icon);
        } else {
          setSelectedWidgetShape({
            backgroundColor: "#25293d",
            borderRadius: "50px 50px 0px 50px ",
            height: "80px",
            width: "80px",
          });
          setSelectedBotImage("");
          setSelectedBotImage(botService.getSelectedBot().bot_icon);
        }
      });
  };

  const handleWidgetImgUpload = ({ target }) => {
    let file = target.files;
    if (file.length) {
      const formData = new FormData();
      formData.append("file", file[0]);
      ApiService.uploadImageOnCustRep(formData)
        .then((res) => {
          if (res.status === "success") {
            setUploadWidgetImage(res.url);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handleBotImgUpload = ({ target }) => {
    let file = target.files;
    if (file.length) {
      const formData = new FormData();
      formData.append("file", file[0]);
      ApiService.uploadImageOnCustRep(formData)
        .then((res) => {
          if (res.status === "success") {
            setUploadBotImage(res.url);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const toggleShape = (widgetShapeName, shapeClass) => {
    setWidgetShape(widgetShapeName);
    setSelectedWidgetShape(shapeClass);
  };

  const undoAllChanges = () => {
    setWidgetBotIcon("");
    setWidgetShape("");
    setWidgetFontColor("");
  };

  const saveWidget = () => {
    const data = {
      widgetIcon: uploadWidgetImage || widgetIcon,
      widgetTitle: widgetTitle,
      widgetBotIcon: uploadBotImage ? uploadBotImage : selectedBotImage,
      widgetShape: selectedWidgetShape,
      widgetIconBgColor: widgetIconBgColor,
      widgetFontColor: "",
      selectColor: selectColor,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: getToken(),
      },
      body: JSON.stringify(data),
    };

    fetch(
      `${baseUrl}/update_bot_sdk/${botService.getSelectedBotId()}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          setNotifications({ color: "success", message: response.message });
          getBotConfig();
          undoAllChanges();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="h-100">
      <div className="ps-5 mt-3 title-font" style={{ height: "3rem" }}>
        Widget Customization/Configuration
      </div>
      <div className="row m-0" style={{ height: "calc(100% - 5rem)" }}>
        {/*configuration panel  */}
        <div className="col web-sdk-background ps-5 h-100 overflow-auto">
          <div className="my-5 customization-heading">Widget Customization</div>
          <div className="">
            <div className="mb-5">
              <div className="row m-0 mb-5">
                <div className="col-5 mb-2 d-flex align-items-center d-flex align-items-center">
                  <div className="key-font">Choose Shape</div>
                </div>
                <div className="col-auto">
                  <div className="row m-0">
                    <div
                      className={`col-3 border border-3 web-sdk-shape me-3 mb-1   ${
                        widgetShape == "shape1" ? "shape-selector" : ""
                      }`}
                      style={{
                        height: "80px",
                        width: "80px",
                        borderRadius: "20%",
                      }}
                      onClick={() =>
                        toggleShape("shape1", {
                          height: "80px",
                          width: "80px",
                          borderRadius: "20%",
                          backgroundColor: `${
                            widgetIconBgColor ? widgetIconBgColor : ""
                          }`,
                        })
                      }
                    ></div>
                    <div
                      className={`col-3 border border-3 web-sdk-shape me-3 mb-1  ${
                        widgetShape == "shape2" ? "shape-selector" : ""
                      }`}
                      style={{
                        height: "80px",
                        width: "80px",
                        borderRadius: "50%",
                      }}
                      onClick={() =>
                        toggleShape("shape2", {
                          height: "80px",
                          width: "80px",
                          borderRadius: "50%",
                          backgroundColor: `${
                            widgetIconBgColor.length > 0
                              ? widgetIconBgColor
                              : ""
                          }`,
                        })
                      }
                    ></div>
                    <div
                      className={`col-3 border border-3 web-sdk-shape me-3 mb-1  ${
                        widgetShape == "shape3" ? "shape-selector" : ""
                      }`}
                      style={{
                        height: "80px",
                        width: "80px",
                      }}
                      onClick={() =>
                        toggleShape("shape3", {
                          height: "80px",
                          width: "80px",
                          backgroundColor: `${
                            widgetIconBgColor.length > 0
                              ? widgetIconBgColor
                              : ""
                          }`,
                        })
                      }
                    ></div>
                    <div
                      className={`col-3 border border-3 web-sdk-shape me-3 mb-1  ${
                        widgetShape == "shape4" ? "shape-selector" : ""
                      }`}
                      style={{
                        height: "80px",
                        width: "80px",
                        borderRadius: "50px 50px 0px 50px ",
                      }}
                      onClick={() =>
                        toggleShape("shape4", {
                          height: "80px",
                          width: "80px",
                          borderRadius: "50px 50px 0px 50px ",
                          backgroundColor: `${
                            widgetIconBgColor.length > 0
                              ? widgetIconBgColor
                              : ""
                          }`,
                        })
                      }
                    ></div>
                  </div>
                </div>
              </div>

              <div className="row m-0 mb-5">
                <div className="col-5 d-flex align-items-center d-flex align-items-center">
                  <div className="key-font">Choose Background Color</div>
                </div>
                <div className="col-auto">
                  <div
                    ref={bgRef}
                    className="d-flex p-2 border border-3 justify-content-around align-items-center"
                    style={{ width: "140px" }}
                    onClick={() => {
                      setBgColor(!bgColor);
                    }}
                  >
                    <div
                      className="rounded-circle"
                      style={{
                        height: "30px",
                        width: "30px",
                        backgroundColor: `${
                          widgetIconBgColor ? widgetIconBgColor : ""
                        }`,
                      }}
                    ></div>
                    <div className="selected-color">{widgetIconBgColor}</div>
                  </div>
                </div>

                {bgColor ? (
                  <div style={popover}>
                    <div
                      style={cover}
                      onClick={() => {
                        setBgColor(false);
                      }}
                    />
                    <ChromePicker
                      // width="30%"
                      color={widgetIconBgColor}
                      onChange={(e) => {
                        setWidgetIconBgColor(e.hex);
                      }}
                    />
                  </div>
                ) : null}
              </div>

              <div className="row m-0 mb-5">
                <div className="col-5 mb-2 d-flex align-items-center">
                  <div className="key-font">Choose Icon</div>
                </div>
                <div className="col-auto">
                  <div className="row">
                    <div className="col-auto">
                      <div
                        style={{
                          height: "70px",
                          width: "70px",
                          backgroundImage: `url(${
                            uploadWidgetImage
                              ? baseUrl + uploadWidgetImage
                              : baseUrl + widgetIcon
                          })`,
                          backgroundSize: "70px 70px",
                        }}
                      ></div>
                    </div>

                    <div className="col d-flex align-items-center ">
                      <input
                        type="file"
                        name="file"
                        onChange={handleWidgetImgUpload}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="my-5 customization-heading">
                Chat Window Customization
              </div>

              <div className="row m-0 mb-5">
                <div className="col-5 d-flex align-items-center">
                  <div className="key-font">Choose Theme color</div>
                </div>
                <div className="col-auto">
                  <div
                    ref={tRef}
                    className="d-flex p-2 border border-3 justify-content-around align-items-center"
                    style={{ width: "140px" }}
                    onClick={() => {
                      setTColor(!tColor);
                    }}
                  >
                    <div
                      className="rounded-circle"
                      style={{
                        height: "30px",
                        width: "30px",
                        backgroundColor: `${
                          selectColor.length > 0 ? selectColor : ""
                        }`,
                      }}
                    ></div>
                    <div className="selected-color">{selectColor}</div>
                  </div>
                </div>

                {tColor ? (
                  <div style={popover}>
                    <div
                      style={cover}
                      onClick={() => {
                        setTColor(false);
                      }}
                    />
                    <ChromePicker
                      color={selectColor}
                      onChange={(e) => {
                        setSelectColor(e.hex);
                      }}
                    />
                  </div>
                ) : null}
              </div>
              <div className="row m-0 mb-5">
                <div className="col-5">
                  <div className="key-font">Web Widget Bot Title</div>
                </div>

                <div className="col-auto d-flex align-items-center">
                  <input
                    className=" p-2 form-control"
                    type="text"
                    onChange={(e) => {
                      setWidgetTitle(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="row m-0">
                <div className="col-5 mb-2 d-flex align-items-center">
                  <div className="key-font">Choose Icon</div>
                </div>
                <div className="col-auto">
                  <div className="row">
                    <div className="col-auto">
                      <div
                        style={{
                          height: "70px",
                          width: "70px",
                          backgroundImage: `url(${
                            uploadBotImage
                              ? baseUrl + uploadBotImage
                              : baseUrl + selectedBotImage
                          })`,
                          backgroundSize: "70px 70px",
                        }}
                      ></div>
                    </div>

                    <div className="col-auto d-flex align-items-center ">
                      <input
                        type="file"
                        name="file"
                        onChange={handleBotImgUpload}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-0 py-3 justify-content-center">
              <div className="col">
                <Button
                  className="btn btn-outline-light w-auto rounded-3 prevent-click-event-triger px-5 py-2 btns-font"
                  onClick={undoAllChanges}
                >
                  Cancel
                </Button>
              </div>

              <div className="col">
                <Button
                  style={{ whiteSpace: "nowrap" }}
                  className="btn intents-btn w-auto rounded-3 prevent-click-event-triger text-white px-5 py-2 btns-font"
                  onClick={saveWidget}
                >
                  Save Widget Configurations
                </Button>
              </div>
            </div>

            {/*<div className="row mb-3">
                <div className="col d-flex align-items-center">
                  <h3> Web Widget Font color</h3>
                </div>
                <div className="mb-4">
                  <ChromePicker
                    width="30%"
                    color={widgetFontColor}
                    onChange={(e) => {
                      setWidgetFontColor(e.hex);
                    }}
                  />
                </div> 
            </div>*/}
          </div>
        </div>

        {/* chat window representation */}
        <div className="col-auto d-flex">
          <div className="mx-auto mt-5  px-3 chat-window">
            <div className="row m-0 chat-window-border1 position-relative">
              <div
                className={`col text-white chat-window-header py-3 align-self-center ${
                  selectColor ? "" : " theme-color-selector"
                }`}
                style={{
                  backgroundColor: `${selectColor ? selectColor : ""}`,
                }}
              >
                <div className="row m-0 w-100 m-0">
                  <div
                    className="col-auto rounded-circle"
                    style={{
                      height: "60px",
                      width: "60px",
                      backgroundImage: `url(${
                        uploadBotImage
                          ? baseUrl + uploadBotImage
                          : baseUrl + selectedBotImage
                      })`,
                      backgroundSize: "60px 60px",
                    }}
                  ></div>
                  <div className="col-auto">
                    <div className="col-auto chat-window-title">
                      {widgetTitle ? widgetTitle : "Title"}
                    </div>
                  </div>
                  <div className="col p-0 text-end">
                    <i className="fas fa-window-minimize text-white me-3"></i>
                    <i className=" fas fa-times"></i>
                  </div>
                </div>
              </div>
              <div className=" col-12 chat-window-body">
                <div className="wrapper mb-2 mt-4 ms-2">
                  <p
                    className={`speech-bubble-bot bot-response py-1 px-2 float-start text rounded-3${
                      widgetFontColor.length > 0 ? "" : "red"
                    }`}
                    style={{
                      color: `${
                        widgetFontColor.length > 0 ? widgetFontColor : ""
                      }`,
                    }}
                  >
                    Welcome to Gnani Demo Framework
                  </p>
                </div>
                <div
                  className={`me-2 text-end px-2 my-5 py-1 mb-2 float-end rounded-3 speech-bubble-user ${
                    widgetFontColor.length > 0 ? "" : "red"
                  }`}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: `${
                      widgetFontColor.length > 0 ? widgetFontColor : ""
                    }`,
                  }}
                >
                  hi
                </div>
                <div className="wrapper mt-5 ms-2">
                  <p
                    className={`speech-bubble-bot bot-response py-1 mt-4 px-2 float-start text rounded-3${
                      widgetFontColor.length > 0 ? "" : "red"
                    }`}
                    style={{
                      color: `${
                        widgetFontColor.length > 0 ? widgetFontColor : ""
                      }`,
                    }}
                  >
                    How can I help you?
                  </p>
                </div>
              </div>

              <div className=" col-12 chat-window-footer d-flex align-items-center">
                <div className="chatbox__footer row m-0 p-2  w-100">
                  <input
                    placeholder="Send a message..."
                    className="col rounded-3 border border-2 border-muted p-3"
                  />
                  <button className="col-auto btn text-primary outline-0">
                    <i className="fas fa-play"></i>
                  </button>
                  <button className="col-auto btn text-primary outline-0">
                    <i className="fas fa-sync"></i>
                  </button>
                </div>
              </div>
              <div
                className={`position-absolute d-flex align-items-center justify-content-center ${
                  widgetIconBgColor ? "" : "theme-color-selector"
                }`}
                style={{
                  height: "100px",
                  width: "100px",
                  borderRadius: selectedWidgetShape?.borderRadius,
                  backgroundColor: `${
                    widgetIconBgColor ? widgetIconBgColor : ""
                  }`,
                  right: "0%",
                  bottom: "-20%",
                }}
              >
                <div
                  className="rounded-circle"
                  style={{
                    height: "60px",
                    width: "60px",
                    backgroundImage: `url(${
                      uploadWidgetImage
                        ? baseUrl + uploadWidgetImage
                        : baseUrl + widgetIcon
                    })`,
                    backgroundSize: "60px 60px",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebSdkConfigurations;
