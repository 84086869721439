import botService from "../bot.service";
import baseUrl from "../baseURL.service";
import { getToken } from "../authUtil";
import apiServices from "../api.services";

const fetchData = (url, options) => {
  return apiServices.fetchData(url, { ...options });
};

function getBotIntegrations() {
  let bot_id = botService.getSelectedBotId();
  let reqOptions = {
    method: "GET",
    headers: {
      token: getToken(),
    },
  };
  return Promise.all([
    fetchData(`${baseUrl}/getBotCrms/${bot_id}`, reqOptions),
    fetchData(`${baseUrl}/get_channel_details/${bot_id}`, reqOptions),
    fetchData(`${baseUrl}/getBotTicketingTools/${bot_id}`, reqOptions),
    fetchData(`${baseUrl}/getBotRpa/${bot_id}`, reqOptions),
  ]);
}

function deleteChannelIntegration(channel) {
  let bot_id = botService.getSelectedBotId();
  let reqOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify({ channel }),
  };
  return fetchData(`${baseUrl}/delete_channel/${bot_id}`, reqOptions);
}

function deleteTicketingIntegration(type) {
  let bot_id = botService.getSelectedBotId();
  let reqOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify({ type }),
  };
  return fetchData(`${baseUrl}/deleteTicketingTool/${bot_id}`, reqOptions);
}

function deleteCRMIntegration(type) {
  let bot_id = botService.getSelectedBotId();
  let reqOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify({ type }),
  };
  return fetchData(`${baseUrl}/deleteBotCrm/${bot_id}`, reqOptions);
}

function deleteRPAIntegration(type) {
  let bot_id = botService.getSelectedBotId();
  let reqOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify({ type }),
  };
  return fetchData(`${baseUrl}/deleteBotRpa/${bot_id}`, reqOptions);
}

function createUpdateIntegration(url, details) {
  let reqOptions = {
    method: "POST",
    headers: { token: getToken(), "Content-Type": "application/json" },
    body: JSON.stringify(details),
  };
  return apiServices.fetchData(
    `${baseUrl}/${url}/${botService.getSelectedBotId()}`,
    reqOptions
  );
}

function getChannelConfig(channel) {
  const reqObj = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  return apiServices.fetchData(
    `${baseUrl}/${botService.getSelectedBotId()}/channel_information?channel_name=${channel}`,
    reqObj
  );
}

const integrationServices = {
  getBotIntegrations,
  deleteChannelIntegration,
  createUpdateIntegration,
  deleteTicketingIntegration,
  deleteCRMIntegration,
  deleteRPAIntegration,
  getChannelConfig
};

export default integrationServices;
