import React, { useContext } from "react";
import svgIcons from "../services/icon.service";
import { BotContext } from "../contexts/BotContext";
import Buttons from "./fields/button/button.component";

function TrainLog({ data, toggleClose, title }) {
  const context = useContext(BotContext);
  return (
    <div
      className="train-log-cont h-100 overflow-auto"
      style={
        title
          ? { borderBottomRightRadius: "0px", borderBottomLeftRadius: "0px" }
          : {}
      }
    >
      <div className="train-log-nav row m-0 align-items-center mb-4">
        <div className="col text-center">{title ? title : "Training logs"}</div>
        <Buttons
          className="col-auto"
          onClick={() => {
            toggleClose ? toggleClose() : context.setTainingLog(false, []);
          }}
        >
          {svgIcons("close-btn", "#DF4B16")}
        </Buttons>
      </div>
      <div className="">
        {data && !!Object.keys(data).length ? (
          Object.keys(data)
          .sort(function (a, b) {
            var numA = parseInt(a.match(/\d+/)[0]);
            var numB = parseInt(b.match(/\d+/)[0]);

            if ((numA >= 10 && numB >= 10) || (numA < 10 && numB < 10)) {
              return numA - numB;
            } else if (numA >= 10) {
              return 1;
            } else {
              return -1;
            }
          })
          .map((mes) => {
            return (
              <div className="row m-0 mb-3">
                {!title ? (
                  <>
                    <label className="col-2 pe-0 train-label-font">
                      {data[mes].asctime}
                    </label>
                    <label className="col-auto train-info-font">
                      {data[mes].levelname}
                    </label>
                    <p className="col mb-0 train-value-font">
                      {data[mes].message}
                    </p>
                  </>
                ) : (
                  <>
                    <label className="col-2 pe-0 train-label-font">{mes}</label>
                    <p className="col mb-0 train-value-font">{data[mes]}</p>
                  </>
                )}
              </div>
            );
          })
        ) : (
          <div className="align-items-center m-auto">
            <p className="col mx-4 mb-0 train-value-font">No logs to display</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default TrainLog;
