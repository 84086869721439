import React, { useEffect } from "react";
import NotificationAlert from "react-notification-alert";

import { Alert } from "reactstrap";
import { useContext } from "react";
import { NotificationContext } from "../../contexts/notficationContext";
import svgIcons from "../../services/icon.service";
import "./notification.scss";

function Notifications() {
  const { notifications } = useContext(NotificationContext);

  useEffect(() => {
    notifications &&
      notifications.forEach((notification) => {
        setTimeout(() => {
          notify(notification);
        }, 300);
      });
  }, [notifications]);

  const notificationAlertRef = React.useRef(null);
  const notify = (notification) => {
    var { color, message } = notification;
    var options = {};
    options = {
      place: "tr",
      message: (
        <Alert color={color} className="border-0 p-0 m-0 bg-white row">
          <div className="col-auto ps-0 align-items-center m-auto">
            {color === "success"
              ? svgIcons("color-block", "green")
              : color === "danger"
              ? svgIcons("color-block", "danger")
              : svgIcons("color-block", "black")}
          </div>
          <div className="col-auto m-auto align-items-center px-3">
            {color === "success"
              ? svgIcons("check-notification", "green")
              : color === "danger"
              ? svgIcons("warning")
              : svgIcons("info-circle")}
          </div>
          <div
            className={`col ${
              color === "black" && message.length > 60
                ? "me-5"
                : color === "black"
                ? "me-3"
                : ""
            }`}
          >
            <div className="my-2">{""}</div>
            <span className="fw-bold">
              {color === "success"
                ? "Success!"
                : color === "danger"
                ? "Something went wrong"
                : color === "black"
                ? "Info"
                : "Warning!"}
            </span>{" "}
            <br />
            <span style={{ color: "#9D9D9D" }}>{message}</span>
          </div>
        </Alert>
      ),
      type: "light",
      closeButton: true,
      autoDismiss: 5,
    };
    notificationAlertRef?.current?.notificationAlert(options);
  };
  return (
    <>
      <div className="react-notification-alert-container">
        <NotificationAlert ref={notificationAlertRef} className="text" />
      </div>
    </>
  );
}

export default Notifications;
