import baseUrl from "../baseURL.service";
import botService from "../bot.service";

import { getToken } from "../authUtil";

const fetchData = (url, options, callback) => {
  return botService.fetchData(url, options, callback);
};

const getCallChatStats = ({ endDate, startDate }) => {
  const bot_id = botService.getSelectedBotId();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  let url = `${baseUrl}/call_chat_stats/${bot_id}`;
  if (endDate && startDate) {
    url += `?startDate=${startDate}&endDate=${endDate}`;
  } else {
    let time = "monthly";
    url += `?daily_or_monthly=${time}`;
  }
  return fetchData(url, requestOptions);
};

const getChannelExchangeStats = ({ endDate, startDate }) => {
  const bot_id = botService.getSelectedBotId();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  let url = `${baseUrl}/channel_exchange_stats/${bot_id}`;
  if (startDate && endDate) {
    url += `?startDate=${startDate}&endDate=${endDate}`;
  } else {
    let time = "monthly";
    url += `?daily_or_monthly=${time}`;
  }
  return fetchData(url, requestOptions);
};

const getTopIntents = ({ endDate, startDate }) => {
  const bot_id = botService.getSelectedBotId();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  let url = `${baseUrl}/get_top_intents/${bot_id}`;
  if (startDate && endDate) {
    url += `?startDate=${startDate}&endDate=${endDate}`;
  } else {
    let time = "monthly";
    url += `?daily_or_monthly=${time}`;
  }

  return fetchData(url, requestOptions);
};

const getTopEntities = ({ endDate, startDate }) => {
  const bot_id = botService.getSelectedBotId();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  let url = `${baseUrl}/get_top_entities/${bot_id}`;
  if (startDate && endDate) {
    url += `?startDate=${startDate}&endDate=${endDate}`;
  } else {
    let time = "monthly";
    url += `?daily_or_monthly=${time}`;
  }

  return fetchData(url, requestOptions);
};

const getTopActions = ({ endDate, startDate }) => {
  const bot_id = botService.getSelectedBotId();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  let url = `${baseUrl}/get_top_actions/${bot_id}`;
  if (startDate && endDate) {
    url += `?startDate=${startDate}&endDate=${endDate}`;
  } else {
    let time = "monthly";
    url += `?daily_or_monthly=${time}`;
  }

  return fetchData(url, requestOptions);
};

const getFallbackRateStats = ({ startDate, endDate }) => {
  const bot_id = botService.getSelectedBotId();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  let url = `${baseUrl}/fallback_rate_stats/${bot_id}`;
  if (startDate && endDate) {
    url += `?startDate=${startDate}&endDate=${endDate}`;
  } else {
    let time = "monthly";
    url += `?daily_or_monthly=${time}`;
  }

  return fetchData(url, requestOptions);
};

const getConversationDurationStats = ({ endDate, startDate }) => {
  const bot_id = botService.getSelectedBotId();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  let url = `${baseUrl}/conversation_duration_stats/${bot_id}`;
  if (startDate && endDate) {
    url += `?startDate=${startDate}&endDate=${endDate}`;
  } else {
    let time = "monthly";
    url += `?daily_or_monthly=${time}`;
  }

  return fetchData(url, requestOptions);
};

const getConversationLengthStats = ({ endDate, startDate }) => {
  const bot_id = botService.getSelectedBotId();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  let url = `${baseUrl}/conversation_length_stats/${bot_id}`;
  if (startDate && endDate) {
    url += `?startDate=${startDate}&endDate=${endDate}`;
  } else {
    let time = "monthly";
    url += `?daily_or_monthly=${time}`;
  }

  return fetchData(url, requestOptions);
};

const getConversationSplitStats = ({ endDate, startDate }) => {
  const bot_id = botService.getSelectedBotId();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  let url = `${baseUrl}/conversation_split_stats/${bot_id}`;
  if (startDate && endDate) {
    url += `?startDate=${startDate}&endDate=${endDate}`;
  } else {
    let time = "monthly";
    url += `?daily_or_monthly=${time}`;
  }

  return fetchData(url, requestOptions);
};

const getBotAutomationTrend = ({ startDate, endDate }) => {
  const bot_id = botService.getSelectedBotId();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  let url = `${baseUrl}/bot_automation_trend/${bot_id}`;
  if (startDate && endDate) {
    url += `?startDate=${startDate}&endDate=${endDate}`;
  } else {
    let time = "monthly";
    url += `?daily_or_monthly=${time}`;
  }

  return fetchData(url, requestOptions);
};

const getConversationCompletionRate = ({ startDate, endDate }) => {
  const bot_id = botService.getSelectedBotId();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  let url = `${baseUrl}/conversation_completion_rate_trend/${bot_id}`;
  if (startDate && endDate) {
    url += `?startDate=${startDate}&endDate=${endDate}`;
  } else {
    let time = "monthly";
    url += `?daily_or_monthly=${time}`;
  }

  return fetchData(url, requestOptions);
};

const getChatList = (
  sortBy = "conversation_end_time",
  pageNo = 0,
  pageSize = 10
) => {
  const bot_id = botService.getSelectedBotId();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };

  return fetchData(
    `${baseUrl}/get_chat_list/${bot_id}?sortBy=${sortBy}&pageNo=${pageNo}&pageSize=${pageSize}`,
    requestOptions
  );
};

const getChatHistory = (chatId) => {
  const bot_id = botService.getSelectedBotId();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };

  return fetchData(
    `${baseUrl}/get_chat_history/${bot_id}/${chatId}`,
    requestOptions
  );
};

const getCallAudio = (body) => {
  const bot_id = botService.getSelectedBotId();

  const reqOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(body),
  };

  return fetchData(
    `${baseUrl}/get_conversation_audio/${bot_id}`,
    reqOptions,
    (res) => res.blob()
  );
};

const getConversationTrend = ({ startDate, endDate }) => {
  const bot_id = botService.getSelectedBotId();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  let url = `${baseUrl}/conversation_trend/${bot_id}`;
  if (startDate && endDate) {
    url += `?startDate=${startDate}&endDate=${endDate}`;
  } else {
    let time = "monthly";
    url += `?daily_or_monthly=${time}`;
  }

  return fetchData(url, requestOptions);
};

const exportChatConversation = (chatId) => {
  const bot_id = botService.getSelectedBotId();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };

  return fetchData(
    `${baseUrl}/export_chat_conversation/${bot_id}/${chatId}`,
    requestOptions
  );
};

const exportChatListConversation = (chatListConv) => {
  const bot_id = botService.getSelectedBotId();

  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };

  return fetchData(
    `${baseUrl}/export_conversations/${bot_id}?conversation_ids=${chatListConv}`,
    requestOptions
  );
};

const getGptStats = (data) => {
  console.log(data);
  const bot_id = botService.getSelectedBotId();

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(data),
  };

  return fetchData(`${baseUrl}/gpt_stats/${bot_id}`, requestOptions);
};

const statisticsApiService = {
  exportChatListConversation,
  exportChatConversation,
  getBotAutomationTrend,
  getCallChatStats,
  getChannelExchangeStats,
  getChatHistory,
  getGptStats,
  getChatList,
  getConversationCompletionRate,
  getConversationDurationStats,
  getConversationLengthStats,
  getConversationSplitStats,
  getConversationTrend,
  getFallbackRateStats,
  getTopActions,
  getTopEntities,
  getTopIntents,
  getCallAudio,
};
export default statisticsApiService;
