import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import Button from "../fields/button/button.component";
import getIcon from "../../services/icon.service";

const ConfirmationModal = (props) => {
  const [isModal, setModal] = useState(props.open);
  const toggle = () => setModal(!isModal);
  const confirm = () => props.confirm();

  return (
    <Modal
      color="black"
      isOpen={isModal}
      style={{ marginTop: "40vh" }}
      toggle={toggle}
    >
      <ModalBody className="bg-white my-modal bot-del-btn-font row m-0">
        <span className="col">Please save your changes before leaving?</span>
        <span
          className="col-auto cursor-pointer"
          onClick={() => {
            toggle();
            props.close && props.close();
          }}
        >
          {getIcon("close")}
        </span>
      </ModalBody>
      <ModalFooter className="bg-white border-0 modal-footer">
        <Button
          className="btn intents-btn w-auto rounded-3 prevent-click-event-triger px-5 py-2 text-white btns-font"
          onClick={() => {
            toggle();
            confirm();
          }}
        >
          Yes
        </Button>
        <Button
          className="btn btn-outline-light w-auto rounded-3 prevent-click-event-triger px-5 py-2 btns-font"
          onClick={() => {
            toggle();
            props.cancel && props.cancel();
          }}
        >
          No
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
