import botService from "../bot.service";
import baseUrl from "../baseURL.service";
import { getToken } from "../authUtil";
import apiServices from "../api.services";

const fetchData = (url, options) => {
  return apiServices.fetchData(url, { ...options });
};

function getBotForms() {
  let bot_id = botService.getSelectedBotId();
  let reqOptions = {
    method: "GET",
    headers: {
      token: getToken(),
    },
  };
  return fetchData(`${baseUrl}/get_bot_forms/${bot_id}`, reqOptions);
}

function getBotFormDetails(form) {
  let bot_id = botService.getSelectedBotId();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  return fetchData(
    `${baseUrl}/get_form_details/${bot_id}?form=${form}`,
    requestOptions
  );
}

function renameBotForm(dataObj) {
  let bot_id = botService.getSelectedBotId();

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };
  return fetchData(`${baseUrl}/rename_bot_form/${bot_id}`, requestOptions);
}

function addBotForm(newFormName) {
  let bot_id = botService.getSelectedBotId();

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify({ form: newFormName, data: [] }),
  };
  return fetchData(`${baseUrl}/add_bot_form/${bot_id}`, requestOptions);
}

function updateBotForm(selectedForm, formDetails, validation = false) {
  let bot_id = botService.getSelectedBotId();

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify({ form: selectedForm, data: formDetails, validation }),
  };
  return fetchData(`${baseUrl}/modify_bot_form/${bot_id}`, requestOptions);
}

function deleteBotForm(selectedForm) {
  let bot_id = botService.getSelectedBotId();

  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify({ form: selectedForm }),
  };
  return fetchData(`${baseUrl}/delete_bot_form/${bot_id}`, requestOptions);
}

const formsServices = {
  getBotForms,
  getBotFormDetails,
  renameBotForm,
  updateBotForm,
  addBotForm,
  deleteBotForm,
};
export default formsServices;
