import React, { useContext, useEffect, useState } from "react";
import svgIcons from "../../services/icon.service";
import Input from "../../components/fields/input/input.component";
import DropDown from "../../components/fields/dropDown/dropDown.component";
import Buttons from "../../components/fields/button/button.component";
import "./knowledgeBase.scss";
import DeleteConfirmation from "../../components/deleteConfirmation.component";
import RecrawlModal from "../../components/recrawlModal";
import StatisticsDetail from "./statistics";
import kbServices from "../../services/api.services/kbApi.service";
import { UserContext } from "../../contexts/userContext";
import LinkUnlinkModal from "../../components/linkUnlinkModal";
import botService from "../../services/bot.service";
import { NotificationContext } from "../../contexts/notficationContext";
import CreateKbBotModal from "../../components/createKbModal";

const KnowledgeBase = (props) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [recrawlModal, setRecrawlModal] = useState(false);
  const [linkModal, setLinkModal] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [recrawlDepth, setRecrawlDepth] = useState(null);
  const [urlList, setUrlList] = useState([]);
  const [state, setState] = useState({
    isChecked: false,
    search: "",
    isShared: false,
    myBots: { copy: [], original: [] },
    sharedBots: { copy: [], original: [] },
    updatedLinkedBots: {},
    unlinkBots: {},
    botid: "",
    createUrlModal: false,
    index: null,
    websiteUrl: "",
  });
  let myInterval;

  const { getUserDetails } = useContext(UserContext);
  const org_id = getUserDetails().organization_id;
  const { setNotifications } = useContext(NotificationContext);

  const tableHeading = [
    "Pages",
    "Depth",
    "Last crawled date & time",
    "Statistics",
    "Status",
    "Link bots",
  ];

  useEffect(() => {
    getUrlList(org_id);
    myInterval = setInterval(() => {
      getUrlList(org_id);
    }, 20000);
    return () => clearInterval(myInterval);
  }, []);

  const getSharedBots = async (i) => {
    try {
      const r = await botService.getSharedBots();
      if (r.status === "success") {
        let bots = r.data;
        setState((prevstate) => {
          let bot = [];
          bot = bots.filter((b) => {
            if (!b.kb_bot_id) {
              b.isChecked = false;
              return b;
            } else if (b.kb_bot_id && urlList[i].kb_bot_id === b.kb_bot_id) {
              b.isChecked = true;
              return b;
            }
          });
          return { ...prevstate, sharedBots: { copy: bot, original: bot } };
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getBotList = async (i) => {
    try {
      const r = await botService.getBotList(true);
      let bots = r.data;
      setState((prevstate) => {
        let bot = [];
        bot = bots.filter((b) => {
          if (!b.kb_bot_id) {
            b.isChecked = false;
            return b;
          } else if (b.kb_bot_id && urlList[i].kb_bot_id === b.kb_bot_id) {
            b.isChecked = true;
            return b;
          }
        });
        return { ...prevstate, myBots: { copy: bot, original: bot } };
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getUrlList = async (org_id) => {
    try {
      const r = await kbServices.getUrlList(org_id);

      if (r.status === "success") {
        const urlList = r.data.url_list;
        setUrlList(urlList);
        if (urlList.length !== 0) {
          urlList[urlList.length - 1].status !== "in-progress" &&
            clearInterval(myInterval);
        } else clearInterval(myInterval);
      }
    } catch (e) {
      console.log(e.message);
      clearInterval(myInterval);
      setNotifications({
        color: "danger",
        message: e.message,
      });
    }
  };

  const submitUrl = async (body) => {
    try {
      const r = await kbServices.submitUrl(body);

      if (r.status === "success") {
        setNotifications({
          color: "success",
          message: r.message,
        });
        getUrlList(org_id);
        setRecrawlDepth(null);
        const myInterval = setInterval(() => {
          getUrlList(org_id);
          if (urlList[urlList.length - 1].status !== "in-progress") {
            clearInterval(myInterval);
          }
        }, 20000);
      } else {
        setNotifications({
          color: "info",
          message: r.message,
        });
      }
    } catch (e) {
      setNotifications({
        color: "danger",
        message: e.message,
      });
    }
  };

  const deleteUrl = async (body, i) => {
    try {
      const r = await kbServices.deleteUrl(body);

      if (r.status === "success") {
        setNotifications({
          color: "success",
          message: r.message,
        });
        getUrlList(org_id);
      } else
        setNotifications({
          color: "danger",
          message: r.message,
        });
    } catch (e) {
      setNotifications({
        color: "danger",
        message: e.message,
      });
    }
  };

  const getAssociateBots = async (body) => {
    try {
      const r = await kbServices.getAssociateBots(body);

      if (r.status === "success") {
        setNotifications({
          color: "success",
          message: r.message,
        });
      } else
        setNotifications({
          color: "danger",
          message: r.message,
        });
    } catch (e) {
      setNotifications({
        color: "danger",
        message: e.message,
      });
    }
  };

  const getCompletedUrls = async (body) => {
    try {
      const r = await kbServices.getCompletedUrls(body);

      if (r.status === "success") {
        setNotifications({
          color: "success",
          message: r.message,
        });
        handleDownload({ data: r.data });
      } else
        setNotifications({
          color: "danger",
          message: r.message,
        });
    } catch (e) {
      setNotifications({
        color: "danger",
        message: e.message,
      });
    }
  };

  const getDeleteModal = (i) => {
    return (
      <DeleteConfirmation
        open={deleteModal}
        delete={() => {
          let body = {
            organization_id: org_id,
            kb_bot_id: urlList[i].kb_bot_id,
          };
          getBotList(i);

          deleteUrl(body, i);
          setDeleteModal(false);
        }}
      />
    );
  };
  const getRecrawlModal = (i) => {
    return (
      <RecrawlModal
        websiteUrl={urlList[i].website_url}
        depth={urlList[i].depth}
        setRecrawlDepth={setRecrawlDepth}
        open={recrawlModal}
        submit={() => {
          let body = {
            website_url: urlList[i].website_url,
            organization_id: org_id,
            depth: recrawlDepth,
            recrawl: "yes",
            openapi_config: urlList[i].openapi_config,
          };
          submitUrl(body);
          setRecrawlModal(false);
        }}
      />
    );
  };
  const handleDropDownClick = (m) => {
    if (m.label === "Delete") {
      setDeleteModal(true);
      setTimeout(() => {
        setDeleteModal(false);
      }, 100);
    } else if (m.label === "Recrawl") {
      setRecrawlModal(true);
      setTimeout(() => {
        setRecrawlModal(false);
      }, 100);
    }
  };
  const handleDropdown = (m, i) => {
    if (m.label === "My Bots") {
      console.log(i);
      setState((prevstate) => {
        return {
          ...prevstate,
          isShared: false,
          search: "",
        };
      });
      getBotList(i);
    } else if (m.label === "Shared Bots") {
      setState((prevstate) => {
        return { ...prevstate, isShared: true, search: "" };
      });
      getSharedBots(i);
    }
  };

  const renderBots = (bots) => {
    return bots.copy?.map((b, index) => {
      return (
        <div
          className={`row my-2 py-2`}
          style={{ background: "#f7f8ff" }}
          key={index}
        >
          <div className="col-auto ms-3 pt-1">
            <Input
              className={"cursor-pointer"}
              type="checkbox"
              checked={b.isChecked}
              onChange={(e) => {
                state.isShared
                  ? toggleSharedbotCheckBox(e, index, b)
                  : toggleMybotCheckBox(e, index, b);
              }}
            />
          </div>
          <div className="col px-0">
            <div className="bot-name-text">{b.bot_name}</div>
            <div className="bot-id-text">{`BOT ID: ${b.bot_id}`}</div>
          </div>
        </div>
      );
    });
  };
  const toggleSharedbotCheckBox = ({ target }, index, item) => {
    const localState = { ...state };
    const { checked } = target;

    if (index !== undefined) {
      localState.sharedBots.copy[index].isChecked = checked;
      if (checked) {
        localState.updatedLinkedBots[item.bot_id] = "link";
      } else {
        localState.updatedLinkedBots[item.bot_id] = "unlink";
      }
      localState.isChecked = false;

      const isEvery = localState.sharedBots.original.every((c) => c.isChecked);

      isEvery && (localState.isChecked = checked);
    } else {
      localState.sharedBots.copy = localState.sharedBots.original.map((c) => {
        if (checked && c.isChecked === false) {
          localState.updatedLinkedBots[c.bot_id] = "link";
        } else if (!checked && c.isChecked === true) {
          localState.updatedLinkedBots[c.bot_id] = "unlink";
        }
        c.isChecked = checked;
        return c;
      });

      localState.isChecked = checked;
    }

    setState(localState);
  };
  const toggleMybotCheckBox = ({ target }, index, item) => {
    const localState = { ...state };
    const { checked } = target;

    if (index !== undefined) {
      localState.myBots.copy[index].isChecked = checked;
      if (checked) {
        localState.updatedLinkedBots[item.bot_id] = "link";
      } else {
        localState.updatedLinkedBots[item.bot_id] = "unlink";
      }
      localState.isChecked = false;

      const isEvery = localState.myBots.original.every((c) => c.isChecked);

      isEvery && (localState.isChecked = checked);
    } else {
      localState.myBots.copy = localState.myBots.original.map((c) => {
        if (checked && c.isChecked === false) {
          localState.updatedLinkedBots[c.bot_id] = "link";
        } else if (!checked && c.isChecked === true) {
          localState.updatedLinkedBots[c.bot_id] = "unlink";
        }
        c.isChecked = checked;
        return c;
      });

      localState.isChecked = checked;
    }

    setState(localState);
  };
  const handleSubmit = (i) => {
    let body = state.updatedLinkedBots;
    getAssociateBots({ kb_bot_id: urlList[i].kb_bot_id, data: body });
    setLinkModal(false);
    setState((prevstate) => {
      return {
        ...prevstate,
        updatedLinkedBots: {},
        search: "",
        isShared: false,
      };
    });
  };

  const handleSearch = ({ target }) => {
    const localState = { ...state };
    const { value } = target;
    const regex = new RegExp(value);
    {
      state.isShared
        ? (localState.sharedBots.copy = localState.sharedBots.original.filter(
            (c) => c.bot_name.match(regex)
          ))
        : (localState.myBots.copy = localState.myBots.original.filter((c) =>
            c.bot_name.match(regex)
          ));
    }

    localState.search = value;

    setState(localState);
  };

  const handleDownload = (data) => {
    const url = window.URL.createObjectURL(
      new Blob([JSON.stringify(data)], {
        type: "application/json",
      })
    );
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute("download", "file.json");

    document.body.appendChild(link);

    link.click();
    setNotifications({ color: "success", message: "Export successful" });
  };

  return (
    <div className="h-100">
      <div className=" my-3 mx-4">
        <span className="website-url py-2 ">Website URL</span>
      </div>
      {!viewDetails ? (
        <div className="px-4 py-3 h-100" style={{ background: "#F2F2F2" }}>
          <Buttons
            className={"submit-button py-2 px-2"}
            onClick={() => {
              setState((prevstate) => {
                return { ...prevstate, createUrlModal: true };
              });
            }}
          >
            Create KB Bot
          </Buttons>
          {urlList.length !== 0 ? (
            <div className="mt-4 table-container">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col">
                      <span
                        className=" badge-style p-1"
                        style={{ borderRadius: "50%" }}
                      >
                        {svgIcons("link-icon")}
                      </span>
                      <span className="mx-2">Website URL</span>
                      <span className="badge rounded-pill px-1 py-0 badge-style">
                        {`${urlList.length} links`}
                      </span>
                    </th>
                    {tableHeading.map((m, i) => {
                      return (
                        <th scope="col" key={i}>
                          <span className="pe-2">{m}</span>
                          {svgIcons("help")}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="py-3">
                  {urlList.map((d, i) => {
                    return (
                      <tr key={i} className="py-3">
                        <td>
                          {d.status === "completed" ? (
                            <span
                              className=" badge-style p-1"
                              style={{ borderRadius: "50%" }}
                            >
                              {svgIcons("link-icon")}
                            </span>
                          ) : (
                            <span
                              className="spinner-border spinner-border-sm"
                              style={{ color: "#0d2981" }}
                            ></span>
                          )}
                          <span className="px-3 text-16">{d.website_url}</span>
                        </td>
                        <td>
                          <span
                            className="text-14 cursor-pointer"
                            style={{ textDecorationLine: "underline" }}
                            onClick={() => {
                              getCompletedUrls(d.kb_bot_id);
                            }}
                          >
                            {d.pages_count}
                          </span>
                        </td>
                        <td>
                          <span
                            className="text-14"
                            style={{ color: "rgba(0, 0, 0, 0.75)" }}
                          >
                            {d.depth}
                          </span>
                        </td>
                        <td>
                          <span className="date-text">
                            {`${d.created_datetime.slice(
                              0,
                              10
                            )} ${d.created_datetime.slice(11, 19)}`}
                          </span>
                        </td>
                        <td>
                          <span
                            className={`${
                              d.status === "completed"
                                ? "cursor-pointer text-enabled-14"
                                : "text-disabled-14"
                            }`}
                            onClick={() => {
                              if (d.status === "completed") {
                                setViewDetails(true);
                                setState((prevstate) => {
                                  return {
                                    ...prevstate,
                                    botid: urlList[i].kb_bot_id,
                                    websiteUrl: urlList[i].website_url,
                                  };
                                });
                              }
                            }}
                          >
                            View Details
                          </span>
                        </td>
                        <td>
                          <span
                            className={`badge rounded-pill ${
                              d.status === "completed"
                                ? "completed-text"
                                : "in-progress-text"
                            }`}
                          >
                            {d.status}
                          </span>
                        </td>
                        <td>
                          <div className="d-flex justify-content-between">
                            <span
                              className={`${
                                d.status === "completed"
                                  ? "cursor-pointer text-enabled-14"
                                  : "text-disabled-14"
                              }`}
                              onClick={() => {
                                if (d.status === "completed") {
                                  getBotList(i);
                                  setLinkModal(true);
                                  setState({ ...state, index: i });
                                }
                              }}
                            >
                              Link/Unlink
                            </span>
                            <DropDown
                              disabled={d.status === "completed" ? false : true}
                              dropdownList={[
                                {
                                  label: "Recrawl",
                                  id: i,
                                  icon: getRecrawlModal(i),
                                },
                                {
                                  label: "Delete",
                                  id: i,
                                  icon: getDeleteModal(i),
                                },
                              ]}
                              actionButton={() => svgIcons("verticalOptions")}
                              onActionItemClick={(menu) => {
                                handleDropDownClick(menu, i);
                              }}
                              btnClassName="px-1 py-0"
                              isContent={true}
                            />
                          </div>
                        </td>
                        {linkModal === true && (
                          <LinkUnlinkModal
                            open={linkModal}
                            cancel={() => {
                              setLinkModal(false);
                              setState({
                                ...state,
                                isShared: false,
                                search: "",
                                updatedLinkedBots: {},
                              });
                            }}
                            toggle={() => {
                              setLinkModal(!linkModal);
                              setState({
                                ...state,
                                isShared: false,
                                search: "",
                                updatedLinkedBots: {},
                              });
                            }}
                            submit={() => {
                              handleSubmit(state.index);
                            }}
                            heading={"Link Bots"}
                            subheading={
                              "Select the bots you want to link with the website"
                            }
                          >
                            <div className="row">
                              <div className="col-auto">
                                <DropDown
                                  dropdownList={[
                                    { label: "My Bots" },
                                    { label: "Shared Bots" },
                                  ]}
                                  actionButton={() => (
                                    <div className="link-bot-dropdown p-2">
                                      <span className="me-2">
                                        {state.isShared
                                          ? "Shared Bots"
                                          : "My Bots"}{" "}
                                      </span>
                                      {svgIcons("angle-down")}
                                    </div>
                                  )}
                                  onActionItemClick={(menu) => {
                                    handleDropdown(menu, state.index);
                                  }}
                                  btnClassName="p-0"
                                  isContent={true}
                                />
                              </div>
                              <div className="col me-2 d-flex align-items-center py-1 link-bot-dropdown">
                                <div>
                                  <i
                                    className="fas fa-search me-2"
                                    style={{ color: "#707070" }}
                                  />
                                </div>
                                <Input
                                  className="w-100 link-modal-search"
                                  placeholder="Search by bot_name..."
                                  onChange={handleSearch}
                                  value={state.search}
                                />
                              </div>
                            </div>
                            <div className="row pt-3 pb-2 ms-1 d-flex align-items-center">
                              <div className="col-auto pe-1">
                                <Input
                                  className="cursor-pointer"
                                  type="checkbox"
                                  checked={state.isChecked}
                                  onChange={
                                    state.isShared
                                      ? toggleSharedbotCheckBox
                                      : toggleMybotCheckBox
                                  }
                                />
                              </div>
                              <div className="col-auto px-2 modal-heading-text">
                                BOTS
                              </div>
                              <div className="col-auto px-0">
                                <span className="badge rounded-pill px-2 py-0 badge-style">
                                  {`${
                                    state.isShared
                                      ? state.sharedBots.copy.length
                                      : state.myBots.copy.length
                                  } bots`}
                                </span>
                              </div>
                            </div>
                            <div
                              className="overflow-auto"
                              style={{ height: "300px" }}
                            >
                              {state.isShared
                                ? renderBots(state.sharedBots)
                                : renderBots(state.myBots)}
                            </div>
                          </LinkUnlinkModal>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <div
              className="d-flex flex-column align-items-center w-100"
              style={{
                position: "absolute",
                top: "50%",
              }}
            >
              {svgIcons("knowledgeBaseNoContent")}
              <p>Enter the link & select the depth in above input field</p>
            </div>
          )}
        </div>
      ) : (
        <StatisticsDetail
          setViewDetails={setViewDetails}
          botId={state.botid}
          handleDownload={handleDownload}
          websiteUrl={state.websiteUrl}
        ></StatisticsDetail>
      )}
      {state.createUrlModal && (
        <CreateKbBotModal
          open={state.createUrlModal}
          cancel={() => {
            setState({ ...state, createUrlModal: false });
          }}
          submit={() => {
            setState({ ...state, createUrlModal: false });
          }}
          toggle={() => {
            setState({ ...state, createUrlModal: !state.createUrlModal });
          }}
          org_id={org_id}
          submitUrl={submitUrl}
          heading={"Create Knowledge Base Bot"}
        ></CreateKbBotModal>
      )}
    </div>
  );
};

export default KnowledgeBase;
