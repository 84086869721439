import baseUrl from "../baseURL.service";
import botService from "../bot.service";

import { getToken } from "../authUtil";
import apiServices from "../api.services";

const fetchData = (url, options) => {
  return apiServices.fetchData(url, { ...options });
};

function getBotSlots() {
  const bot_id = botService.getSelectedBotId();
  const reqOptions = {
    method: "GET",
    headers: {
      token: getToken(),
    },
  };

  return fetchData(`${baseUrl}/get_bot_slots/${bot_id}`, reqOptions);
}

function getBotCategoricalSlots() {
  const bot_id = botService.getSelectedBotId();
  const reqOptions = {
    method: "GET",
    headers: {
      token: getToken(),
    },
  };

  return fetchData(`${baseUrl}/get_categorical_slots/${bot_id}`, reqOptions);
}

const addBotSlot = (newSlotType) => {
  const bot_id = botService.getSelectedBotId();
  const body = {
    influence_conversation: true,
    slot: `untitled_${Date.now()}`,
    data: {
      type: newSlotType,
    },
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(body),
  };

  return fetchData(`${baseUrl}/add_bot_slot/${bot_id}`, requestOptions);
};

const renameBotSlot = (new_name, old_name) => {
  const bot_id = botService.getSelectedBotId();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify({ new_name, old_name }),
  };

  return fetchData(`${baseUrl}/rename_bot_slot/${bot_id}`, requestOptions);
};

const getSlotDetails = (slotType) => {
  const bot_id = botService.getSelectedBotId();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  return fetchData(
    `${baseUrl}/get_slot_details/${bot_id}?slot=${slotType}`,
    requestOptions
  );
};

const deleteBotSlot = (selectedSlot) => {
  const bot_id = botService.getSelectedBotId();
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify({ slot: selectedSlot }),
  };

  return fetchData(`${baseUrl}/delete_bot_slot/${bot_id}`, requestOptions);
};

const updateSlotData = (dataObj) => {
  const bot_id = botService.getSelectedBotId();
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };

  return fetchData(`${baseUrl}/modify_bot_slot/${bot_id}`, requestOptions);
};

const slotService = {
  addBotSlot,
  deleteBotSlot,
  getBotSlots,
  getSlotDetails,
  renameBotSlot,
  updateSlotData,
  getBotCategoricalSlots,
};

export default slotService;
