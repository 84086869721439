import React, { useRef, useState, useContext } from "react";
import { Tooltip } from "reactstrap";
import {
  ArcElement,
  Chart as ChartJS,
  Title,
  Tooltip as ChartJSToolTip,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

import Button from "../fields/button/button.component";
import DeleteConfirmation from "../../components/deleteConfirmation.component";
import DropDown from "../../components/fields/dropDown/dropDown.component";
import Graph from "../../components/graph/graph";
import Input from "../fields/input/input.component";
import Modal from "../modal/modal.component";

import apiService from "../../services/api.services";
import baseUrl from "../../services/baseURL.service";
import getIcon from "../../services/icon.service";
import settingsAPIService from "../../services/api.services/settingsApi.service";

import { NotificationContext } from "../../contexts/notficationContext";
import { UserContext } from "../../contexts/userContext";

import chatWindow from "../../assets/images/chat-window.png";

import "./homeRightContent.scss";

ChartJS.register(ArcElement, ChartJSToolTip, Title);

const HomeRightContent = ({
  basicStats,
  bot,
  deleteBot,
  getBotList,
  graphData,
}) => {
  const toolTipRef = useRef();
  const [isBools, setBools] = useState([
    { label: "toolTip", value: false },
    { label: "isModal", value: false },
    { label: "isModalForDuplicate", value: false },
  ]);
  const [email, setEmail] = useState("");
  const [access, setAccess] = useState("");
  const [modal, setModal] = useState(false);
  const [state, setState] = useState({
    chartData: {
      datasets: [
        {
          data: graphData,
        },
      ],
    },
    fields: {
      inpDuplicate: "",
    },
  });

  const capitalise = (str) => {
    if (str) {
      let firstLetter = str.charAt(0).toUpperCase();
      let otherLetters = str.substring(1);
      return firstLetter + otherLetters;
    }
  };

  const { setNotifications } = useContext(NotificationContext);
  const { getUserDetails } = useContext(UserContext);

  const botDetailList = [
    { label: "Type of call", value: capitalise(bot.bot_type) || "Default" },
    { label: "Language", value: capitalise(bot.language) },
    { label: "Type of bot", value: capitalise(bot.bot_industry) },
    {
      label: "Channels Supported",
      value: capitalise(bot.bot_channels) || "Default",
    },
    {
      label: "Integrations Supported",
      value: capitalise(bot.bot_integrations) || "Default",
    },
    { label: "Time Zone", value: bot.timezone },
  ];

  const chartOptions = {
    cutout: 43,
    radius: "90%",
    rotation: 95,
    backgroundColor: ["#E0E5FF", "#757EAA"],
    borderColor: ["#E0E5FF", "#757EAA"],
    plugins: {
      title: {
        color: "#ffffff",
        display: true,
        font: { family: "Avenir" },
        position: "bottom",
        text: "Based On 3 Responses",
      },
    },
  };

  const duplicate_bot = async () => {
    try {
      const r = await apiService.duplicate_bot(
        {
          new_bot_name: state.fields.inpDuplicate,
        },
        bot.bot_id
      );

      if (r.status === "success") {
        getBotList();
        handleBools("isModalForDuplicate", "set");
        setNotifications({
          color: "success",
          message: "Duplicate bot successfully created.",
        });
      } else {
        setNotifications({ color: "danger", message: r.message });
      }
    } catch (e) {
      setNotifications({ color: "danger", message: e.message });
      console.log(e);
    }
  };

  const getDeleteModal = () => {
    return <DeleteConfirmation open={modal} delete={() => deleteBot(bot)} />;
  };

  const dropDownList = [
    {
      label: "Delete",
      value: "_delete",
      icon: getDeleteModal(),
      isUsed:
        getUserDetails().feature_permission &&
        getUserDetails().feature_permission.my_bots.delete_button !== "enabled",
    },
    {
      label: "Share Bot",
      value: "_share",
      icon: getIcon("share"),
      isUsed:
        getUserDetails().feature_permission &&
        getUserDetails().feature_permission.my_bots.share_button !== "enabled",
    },
    {
      label: "Disable",
      value: "_disable",
      icon: getIcon("disable"),
    },
    {
      label: "Duplicate",
      value: "_duplicate",
      icon: getIcon("copy"),
      isUsed:
        getUserDetails().feature_permission &&
        getUserDetails().feature_permission.my_bots.duplicate_button !==
          "enabled",
    },
  ];
  const isMarketPlace = window.location.pathname.includes("marketPlace");
  const isSharedBot = window.location.pathname.includes("sharedbots");

  const handleBools = (labelName, actionType = "get", value) => {
    const tempIsBools = [...isBools];
    const index = tempIsBools.findIndex(({ label }) => label === labelName);

    if (actionType === "set") {
      tempIsBools[index].value =
        value === undefined ? !tempIsBools[index].value : value;
      return setBools(tempIsBools);
    }

    return isBools[index].value;
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `<script defer id="chat_bot_widget" src="${baseUrl}/static/chat_bot_widget.js?bot_id=${bot.bot_id}"
      ></script>`
    );
    handleBools("toolTip", "set");
    return setTimeout(() => handleBools("toolTip", "set"), 3000);
  };

  const renderBotDetails = () =>
    botDetailList.map((t, i) => (
      <div className="my-1 row m-0" key={i}>
        <span className="home-bot-bio-label ps-0 col">{t.label}:</span>
        <span className="home-bot-bio-value col">{t.value}</span>
      </div>
    ));

  const renderGraph = () => (
    <Doughnut
      data={state.chartData}
      options={chartOptions}
      // plugins={[ChartDataLabels]}
    />
  );

  const handleActions = ({ value }) => {
    switch (value) {
      case "_delete":
        setModal(true);
        setTimeout(() => {
          setModal(false);
        }, 100);
        break;
      case "_share":
        return handleBools("isModal", "set");
      default:
        return handleBools("isModalForDuplicate", "set");
    }
  };

  const handleCancelModal = () => {
    setEmail("");
    setAccess("");
    handleBools("isModal", "set");
  };

  const handleConfirm = async () => {
    try {
      const r = await settingsAPIService.addUserAccess(
        { email, access },
        bot.bot_id
      );
      if (r.status === "success") {
        handleCancelModal();
        setNotifications({ color: "success", message: r.message });
      } else setNotifications({ color: "danger", message: r.message });
    } catch (e) {
      setNotifications({ color: "danger", message: e.message });
      console.log(e);
    }
  };

  const renderSharedBlock = () => {
    return (
      <div>
        <p className="shared-user-info-title">Sharing Info</p>
        <div className="row">
          <div className="col-auto mb-2">
            <div className="shared-user-info-container">
              <i className="fas fa-user me-2" />
              {bot.owner_details.first_name} {bot.owner_details.last_name}
            </div>
          </div>
          <div className="col mb-2">
            <div className="shared-user-info-container">
              <i className="fas fa-key me-2" />
              {bot.access_type === "write" ? "Read/Write" : "Read Only"}
            </div>
          </div>
        </div>

        <div className="shared-user-info-container">
          <span className="me-2">{getIcon("email")}</span>
          {bot.owner_details.email}
        </div>

        <p className="shared-user-info-title mt-4">Description</p>
      </div>
    );
  };

  const handleLastTrained = ({ last_trained }) => {
    const [date, time] = new Date(last_trained).toLocaleString().split(",");

    return `${time}, ${date}`;
  };

  const handleInpDuplicate = ({ target }) => {
    const localState = { ...state };
    localState.fields.inpDuplicate = target.value;
    setState(localState);
  };

  const handleCancelForDuplicate = () => {
    const localState = { ...state };
    localState.fields.inpDuplicate = "";
    setState(localState);
    handleBools("isModalForDuplicate", "set");
  };

  return (
    <>
      <div className="align-items-center d-flex justify-content-between mb-3">
        <h4 className="home-right-content-header-container">
          {bot.bot_name || bot.template_name}
        </h4>
        {!(isSharedBot || isMarketPlace) && (
          <DropDown
            actionButton={() => getIcon("verticalOptions")}
            dropdownList={dropDownList}
            onActionItemClick={handleActions}
          />
        )}
      </div>

      {isSharedBot && renderSharedBlock()}

      <div
        className={`mb-3 ${
          isSharedBot ? "shared-user-desc-container" : "home-bot-bio"
        }`}
      >
        <span className="home-bot-bio-label" style={{ fontSize: "15px" }}>
          Description:
        </span>
        <div className={"row"}>
          <div className={"col-auto home-bot-bio"}>
            {bot.bot_description ||
              bot.template_description ||
              "No Description given..."}
          </div>
        </div>
      </div>

      {renderBotDetails()}

      <Modal
        addNewModalOpen={handleBools("isModal")}
        className="shared-modal-container"
        modalSize="md"
        isHeader={false}
      >
        <div className="container">
          <p className="shared-bot-modal-title">Share Bot</p>
          <div className="row m-0 align-items-center justify-content-center">
            <div className="col-auto">
              <label className="shared-bot-modal-label" htmlFor="shared_email">
                Shared with
              </label>
            </div>
            <div className="col">
              <Input
                className="shared-bot-modal-inp px-2"
                placeholder="Enter email address"
                id="shared_email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="row m-0 my-4 align-items-center justify-content-center">
            <div className="col-auto px-3">
              <label className="shared-bot-modal-label">Permission</label>
            </div>
            <div className="col">
              <div className="row">
                <div className="col-auto">
                  <Button
                    className={`settings-share-permissions-btns ${
                      access === "write" && "settings-share-selected-access"
                    }`}
                    onClick={() => setAccess("write")}
                  >
                    Read/Write
                  </Button>
                </div>
                <div className="col ps-0">
                  <Button
                    className={`settings-share-permissions-btns ${
                      access === "read" && "settings-share-selected-access"
                    }`}
                    onClick={() => setAccess("read")}
                  >
                    Only Read
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <Button
              className="shared-modal-cancel-btn"
              onClick={handleCancelModal}
            >
              Cancel
            </Button>
            <Button
              className="shared-modal-confirm-btn"
              onClick={handleConfirm}
              isDisabled={!email || !access}
            >
              CONFIRM
            </Button>
          </div>
        </div>
      </Modal>

      {isMarketPlace ? (
        <div className="text-center">
          {/* <img alt="chat-window" className="img-fluid my-4" src={chatWindow} /> */}
        </div>
      ) : (
        <div>
          <div className="mt-3 bot-web-sdk-container">
            <h6 className="mb-1">Web SDK</h6>
            <p className="mb-2">
              {/* At accusam et justo duo dolores et ea rebum. Stet clita kasd
              gubergren, no sea takimata sanctus est Lorem ipsum dolor. no sea
              takimata sanctus est Lorem ipsum dolor. */}
            </p>

            <div className="d-flex" id="copied">
              <div className="bot-web-sdk-script-container" ref={toolTipRef}>
                <p className="m-0 pb-0">{"<script defer"}</p>
                <p className="m-0 ps-4 py-0">{`id="chat_bot_widget"`}</p>
                <p className="m-0 ps-4 py-0">{`src="${baseUrl}/static/chat_bot_widget.js?bot_id=${bot.bot_id}"`}</p>
                <p className="m-0 py-0">{">"}</p>
                <p className="m-0 pt-0">{"</script>"}</p>
              </div>
              <Tooltip isOpen={handleBools("toolTip")} target="copied">
                Copied!
              </Tooltip>
              <div className="bot-web-sdk-icon-container" onClick={handleCopy}>
                {getIcon("copy", "white")}
              </div>
            </div>
          </div>

          <div className="bot-status-container">
            <h6 className="mb-1">Bot Last Trained</h6>
            <p>
              {bot.last_trained ? handleLastTrained(bot) : "Not trained yet"}
            </p>
          </div>

          <div className="bot-statistics-container">
            <h6 className="m-0">Statistics</h6>

            <div className="row">
              <div className="col-6">
                <Graph
                  footer={<span>{basicStats["total-conversations"]}</span>}
                  header={"Total Conversations"}
                />
                <Graph
                  footer={<span>{basicStats["total-completed-per"]}</span>}
                  header={"Conversation Completion Rate"}
                />
              </div>
              <div className="col-6">
                <Graph
                  footer={
                    <span>{basicStats["bot-automation-conversation-per"]}</span>
                  }
                  header={"Bot Automation"}
                />
              </div>
            </div>
          </div>
          <Modal
            addNewModalOpen={handleBools("isModalForDuplicate")}
            className="duplicate-modal-container"
            isHeader={false}
            bodyClassName="p-0"
            modalSize="md"
          >
            <div className="container">
              <div className="row text-center">
                <div className="col">
                  <label
                    className="shared-bot-modal-label"
                    htmlFor="shared_email"
                  >
                    Are you sure, you want to duplicate bot?
                  </label>
                </div>
              </div>
              <div className="row my-3 text-center">
                <div className="col">
                  <Input
                    className="shared-bot-modal-inp"
                    placeholder="Enter duplicate bot name"
                    id="shared_email"
                    value={state.fields.inpDuplicate}
                    onChange={handleInpDuplicate}
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col text-end">
                  <Button
                    className="btn-outline-light rounded-3 px-4 py-2 btn-secondary"
                    styles={{ width: "10rem" }}
                    onClick={handleCancelForDuplicate}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="col">
                  <Button
                    className="duplicate-modal-confirm-btn"
                    onClick={duplicate_bot}
                    isDisabled={!state.fields.inpDuplicate}
                  >
                    CONFIRM
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default HomeRightContent;
