import React, { useState } from "react";
import { Tooltip } from "reactstrap";
import "../../botLayout/botLayout.scss"

const Buttons = ({
  id,
  children,
  className,
  isDisabled,
  onClick,
  styles,
  type,
  toolTipText,
  toolTipPlacement,
}) => (
  <button
    id={id}
    className={`button ${className} ${isDisabled && "btn-disable"}`}
    disabled={isDisabled}
    onClick={onClick}
    style={styles}
    type={type}
  >
    {children}
    {toolTipText && id && (
      <ToolTipComp
        toolTipPlacement={toolTipPlacement}
        id={id}
        toolTipText={toolTipText}
      />
    )}
  </button>
);

export default Buttons;

function ToolTipComp({ toolTipText, id, toolTipPlacement = "bottom" }) {
  const [toolTip, setToolTip] = useState(false);

  return (
    <Tooltip
      placement={toolTipPlacement}
      isOpen={toolTip}
      target={id}
      toggle={() => setToolTip(!toolTip)}
    >
      {toolTipText}
    </Tooltip>
  );
}
