import React, { useContext, useEffect, useState } from "react";
import "./knowledgeBase.scss";
import svgIcons from "../../services/icon.service";
import kbServices from "../../services/api.services/kbApi.service";
import Input from "../../components/fields/input/input.component";
import DropDown from "../../components/fields/dropDown/dropDown.component";
import { NotificationContext } from "../../contexts/notficationContext";

const StatisticsDetail = ({
  setViewDetails,
  botId,
  handleDownload,
  websiteUrl,
}) => {
  const [creationStats, setCreationStats] = useState([
    { label: "Total URLs", value: "0" },
    { label: "Total tokens used", value: "0" },
    { label: "Embedding cost", value: "0" },
  ]);
  const [usageStats, setUsageStats] = useState([
    { label: "Total responses generated", value: "0" },
    { label: "Total tokens used", value: "0" },
    { label: "Response cost", value: "0" },
  ]);
  const [state, setState] = useState({
    chatList: [],
    selectedConversationId: "",
    search: "",
    isChecked: false,
    conversationId: [],
    date: "",
    time: "",
    messageReceived: null,
    messageSent: null,
    conversations: [],
  });
  const conDetails = [
    {
      label: "Date",
      value: state.date,
      iconName: "time",
    },
    {
      label: "Messages received",
      value: state.messageReceived,
      iconName: "sendMsg",
    },
    {
      label: "Time",
      value: state.time,
      iconName: "time",
    },

    {
      label: "Messages Sent",
      value: state.messageSent,
      iconName: "receiveMsg",
    },
  ];
  const { setNotifications } = useContext(NotificationContext);

  useEffect(() => {
    getKbCreCost(botId);
    getConversations(botId);
    getKbUsageCost(botId);
  }, []);

  const getKbCreCost = async (botId) => {
    try {
      const r = await kbServices.getKbCreCost(botId);

      if (r.status === "success") {
        const data = r.result;
        let stats = [
          { label: "Total URLs", value: data.total_urls },
          { label: "Total tokens used", value: data.total_tokens_used },
          { label: "Embedding cost", value: data.embedding_cost },
        ];
        setCreationStats(stats);
      }
    } catch (e) {
      setNotifications({
        color: "danger",
        message: e.message,
      });
    }
  };

  const getKbUsageCost = async (botId) => {
    try {
      const r = await kbServices.getKbUsageCost(botId);

      if (r.status === "success") {
        const data = r.result;
        let stats = [
          { label: "Total responses generated", value: data.total_responses },
          { label: "Total tokens used", value: data.total_tokens_used },
          { label: "Response cost", value: data.response_cost },
        ];
        setUsageStats(stats);
      }
    } catch (e) {
      setNotifications({
        color: "danger",
        message: e.message,
      });
    }
  };

  const getConversations = async (botId) => {
    try {
      const r = await kbServices.getConversations(botId);

      if (r.status === "success") {
        const conversations = r.result.conversations;
        setState((prevState) => {
          return { ...prevState, chatList: conversations };
        });
      }
    } catch (e) {
      setNotifications({
        color: "danger",
        message: e.message,
      });
    }
  };

  // const getStatistics = async (botId) => {
  //   try {
  //     const r = await kbServices.getStatistics(botId);

  //     if (r.status === "success") {
  //       const statistics = r.result.statistics;
  //       let data = [
  //         {
  //           label: "Response generated",
  //           value: statistics.responses_count,
  //         },
  //         { label: "Tokens used", value: statistics.tokens_count },
  //         { label: "No of pages", value: statistics.pages_count },
  //         {
  //           label: "Tokens per page",
  //           value: statistics.tokens_per_page,
  //         },
  //         {
  //           label: "Cost for embedding",
  //           value: statistics.embedding_cost,
  //         },
  //         {
  //           label: "Cost for response generation",
  //           value: statistics.response_generation_cost,
  //         },
  //       ];
  //       setStatisticsData(data);
  //     }
  //   } catch (e) {
  //     setNotifications({
  //       color: "danger",
  //       message: e.message,
  //     });
  //   }
  // };

  const getConversation = async (convId) => {
    try {
      const r = await kbServices.getConversation(convId, botId);

      if (r.status === "success") {
        const conv = r.result.conversation_log;
        let bot = [];
        let user = [];
        bot = conv.map((c) => {
          if (
            c["bot_message"].bot_response &&
            c["bot_message"].bot_response !== ""
          )
            return c["bot_message"].bot_response;
        });
        user = conv.map((c) => {
          if (c.user_message && c.user_message !== "") return c.user_message;
        });
        setState((prevState) => {
          return {
            ...prevState,
            conversations: conv,
            messageReceived: user.length,
            messageSent: bot.length,
          };
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const renderChatList = () => {
    return state.chatList
      .filter((l) => {
        if (state.search) return l.conversation_id.includes(state.search);
        else return l;
      })
      .map((item, index) => (
        <div
          className={`row my-1 pt-2 ${
            state.selectedConversationId === item.conversation_id &&
            "selected-chatList"
          }`}
          style={{ background: "#FAFAFA" }}
          key={index}
        >
          <div className="col-auto ms-2">
            <Input
              className={"cursor-pointer"}
              type="checkbox"
              checked={item.isChecked}
              onChange={(e) => toggleCheckBox(e, index, item)}
            />
          </div>
          <div
            className="col cursor-pointer px-0"
            onClick={() => {
              getConversation(item.conversation_id);
              setState((prevState) => {
                return {
                  ...prevState,
                  selectedConversationId: item.conversation_id,
                  date: item.date_time.slice(0, 10),
                  time: item.date_time.slice(11, 19),
                };
              });
            }}
          >
            <p className="chat-list-con-id">{item.conversation_id}</p>
            <p className="call-stats-pg-value py-1">
              {item.date_time.slice(0, 10)}
            </p>
          </div>
        </div>
      ));
  };

  const toggleCheckBox = ({ target }, index, item) => {
    const localState = { ...state };
    const { checked } = target;

    if (index !== undefined) {
      localState.chatList[index].isChecked = checked;
      if (checked) {
        localState.conversationId.push(item.conversation_id);
      } else {
        localState.conversationId = localState.conversationId.filter(
          (s) => s !== item.conversation_id
        );
      }
      localState.isChecked = false;

      const isEvery = localState.chatList.every((c) => c.isChecked);

      isEvery && (localState.isChecked = checked);
    } else {
      localState.chatList = localState.chatList.map((c) => {
        if (checked) {
          if (!localState.conversationId.includes(c.conversation_id)) {
            localState.conversationId.push(c.conversation_id);
          }
        }
        c.isChecked = checked;
        return c;
      });

      localState.isChecked = checked;

      if (!checked) localState.conversationId = [];
    }

    setState(localState);
  };

  const handleSearch = (e) => {
    setState((prevState) => {
      return { ...prevState, search: e.target.value };
    });
  };

  const handleClearSearch = () => {
    setState((prevState) => {
      return { ...prevState, search: "" };
    });
  };

  const renderConversation = () => {
    return state.conversations.map((c, i) => (
      <div className="row" key={i}>
        <div className="col d-flex align-items-end flex-column">
          <div className={"stat-chat-history user-reply"}>
            {c.user_message && c.user_message}
          </div>
        </div>
        {c["bot_message"].bot_response && (
          <div className="col-12">
            <div className="stat-chat-history bot-reply">
              <p className="m-0">{c["bot_message"].bot_response}</p>
            </div>
          </div>
        )}
        {c["bot_message"].citation && (
          <div className="col-12">
            <div className="stat-chat-history bot-reply">
              <p className="m-0 text-break">
                For further information on this topic, please visit the
                following website: {c["bot_message"].citation}
              </p>
            </div>
          </div>
        )}
      </div>
    ));
  };

  const exportChatConversation = async () => {
    try {
      const r = await kbServices.getDownloadReport(botId);
      let conv = r.report;
      conv = conv.filter((c) => {
        if (state.selectedConversationId === c.conversation_id) return c;
      });
      handleDownload({ data: conv });
    } catch (e) {
      setNotifications({
        color: "danger",
        message: e.message,
      });
    }
  };

  const exportChatListConversation = async () => {
    try {
      const r = await kbServices.getDownloadReport(botId);
      let conv = r.report;
      conv = conv.filter((c) => {
        if (state.conversationId.includes(c.conversation_id)) return c;
      });
      handleDownload({ data: conv });
    } catch (e) {
      setNotifications({
        color: "danger",
        message: e.message,
      });
    }
  };

  return (
    <div className="m-0 row h-100 ">
      <div
        className={"col-auto h-100 border me-2"}
        style={{ width: "25%", padding: "32px" }}
      >
        <div>
          <span
            className="cursor-pointer"
            onClick={() => {
              setViewDetails(false);
            }}
          >
            {svgIcons("back-arrow")}
          </span>
          <span className="url-details-text px-3">Website URL Details</span>
        </div>
        <div className="my-4 url-badge-style py-2">
          <span className="mx-2 ps-1">{svgIcons("link-icon")}</span>
          <span>{websiteUrl}</span>
        </div>
        <div className="mb-3">
          <div className="my-2 statistics-text">
            Knowledge Base Creation Stats
          </div>
          {creationStats.map((d, i) => {
            return (
              <div className="d-flex justify-content-between my-2 py-1" key={i}>
                <div className="statistics-label-text">{d.label}</div>
                <div className="statistics-text">{d.value}</div>
              </div>
            );
          })}
        </div>
        <div className="mt-3">
          <div className="my-2 statistics-text">Knowledge Base Usage Stats</div>
          {usageStats.map((d, i) => {
            return (
              <div className="d-flex justify-content-between my-2 py-1" key={i}>
                <div className="statistics-label-text">{d.label}</div>
                <div className="statistics-text">{d.value}</div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={`col row h-100 p-0`} style={{ background: "#f5f5f5" }}>
        <div
          className="col-auto h-100 stats-left-panel"
          //   style={{ width: "28%", border: "1px solid #D9D9D9" }}
          style={{ width: "23rem", border: "1px solid #D9D9D9" }}
        >
          <div className=" mx-2 my-4 statistics-text d-flex justify-content-between">
            <div> Overall conversations</div>
            <div>
              <DropDown
                actionButton={() => svgIcons("verticalOptions")}
                btnClassName={`p-0
                ${!state.conversationId.length && "disabled-dropdown-btn"}
                `}
                dropdownList={[{ label: "Export" }]}
                onActionItemClick={exportChatListConversation}
                disabled={!state.conversationId.length}
              />
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-auto ms-2 mt-1">
              <Input
                className="cursor-pointer"
                type="checkbox"
                checked={state.isChecked}
                onChange={toggleCheckBox}
              />
            </div>
            <div
              className="col-auto d-flex align-items-center py-1"
              style={{ background: "#F5F5F5", borderRadius: "6px" }}
            >
              <div>
                <i
                  className="fas fa-search me-2"
                  style={{ color: "#B9B9B9" }}
                />
              </div>
              <Input
                className="stats-left-panel-header-inp"
                placeholder="Search..."
                onChange={handleSearch}
                value={state.search}
              />
              <div className="cursor-pointer" onClick={handleClearSearch}>
                {svgIcons("refresh")}
              </div>
            </div>
          </div>

          <div
            className="overflow-auto mt-1 pb-4"
            style={{ height: "calc(100% - 7.5rem)" }}
          >
            {renderChatList()}
          </div>
        </div>

        {state.selectedConversationId && (
          <div
            className="col overflow-auto h-100 my-4"
            style={{ padding: "0 10%" }}
          >
            <div className="row ">
              <div className="col">
                <span className="stats-right-panel-title pe-1">
                  Conversation Id:
                </span>
                <span className="stats-right-panel-val ps-2">
                  {state.selectedConversationId}
                </span>
              </div>
              <div className="col-auto">
                <DropDown
                  actionButton={() => svgIcons("verticalOptions")}
                  dropdownList={[{ label: "Export" }]}
                  onActionItemClick={() => exportChatConversation()}
                />
              </div>
            </div>
            <div className="row py-2 my-1">
              <div className="col-auto url-badge-style py-1">
                <span className="me-2">{svgIcons("link-icon")}</span>
                <span>{websiteUrl}</span>
              </div>
              <div className="col-auto mx-3 url-badge-style py-1">
                <span className="me-2">{svgIcons("chat-icon")}</span>
                <span>Chat</span>
              </div>
            </div>

            <div className="statistics-text my-3">Conversation Details</div>
            <div className="row ms-1">
              {conDetails.map((d, i) => {
                return (
                  <div
                    className="col-6 row"
                    key={i}
                    style={{ padding: "6px 0" }}
                  >
                    <div className="col-auto pe-0">{svgIcons(d.iconName)}</div>
                    <div className="col-auto statistics-label-text">
                      {d.label}
                    </div>
                    <div className="col statistics-text">{d.value}</div>
                  </div>
                );
              })}
            </div>

            <div className="row h-100" style={{ marginTop: "6%" }}>
              <div className="col">
                <div className="row m-0 me-3">
                  <p className="chat-con-details-heading col p-0">
                    User Conversation
                  </p>
                </div>
                <div className="chat-stats-chat-details">
                  {renderConversation()}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StatisticsDetail;
