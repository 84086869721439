import React, { useState } from 'react';

export const NotificationContext = React.createContext();

export const NotificationContextWrapper = (props) => {
  const [notifications, setNotifications] = useState([]);
  const [loadingText, setLoadingText] = useState('Last saved 53 mins ago…');

  return (
    <NotificationContext.Provider value={{ 
        notifications,
        loadingText,
        clearNotifications: () => setNotifications([]),
        setNotifications: (value) => {
            setNotifications([]);
            setNotifications([value]);
        },
        setLoadingText
    }}>
      {props.children}
    </NotificationContext.Provider>
  );
}

