import { Component, useContext } from "react";

import BotLayoutContent from "../../components/botLayout/botLayoutContent.component";
import BotLayoutLeftPanel from "../../components/botLayout/botLayoutLeftPanel.component";
import BotLayoutRightPanel from "../../components/botLayout/botLayoutRightPanel.component";
import DropDown from "../../components/fields/dropDown/dropDown.component";
import Input from "../../components/fields/input/input.component";
import Toggle from "../../components/fields/toggle/toggle.jsx";

import apiService from "../../services/api.services/slotsApi.service";
import entityService from "../../services/api.services/entityApi.service";
import getIcon from "../../services/icon.service";
import DeleteConfirmation from "../../components/deleteConfirmation.component";

import { BotContext } from "../../contexts/BotContext";
import { NotificationContext } from "../../contexts/notficationContext";
import { UserContext } from "../../contexts/userContext";
import Button from "../../components/fields/button/button.component";
import botService from "../../services/bot.service";

import "./slots.scss";

class Slots extends Component {
  static contextType = BotContext;
  constructor(props) {
    super(props);
    this.state = {
      isSelected: false,
      slots: [],
      selectedSlot: "",
      selectedSlotName: "",
      selectedSlotType: "",
      trainedSlots: [],
      addNewSlot: "",
      slotData: {},
      addNewCate: "",
      entities: [],
      modal: false,
    };
  }

  componentDidMount() {
    this.getBotSlots();
    this.getBotEntities();

    // this.setNavBarButtons();
  }

  // componentWillUnmount() {
  //   this.context.setNavBarButtons("");
  // }

  setNavBarButtons = () => {
    this.context.setNavBarButtons(
      <>
        <Button
          id="saveButton"
          toolTipText={botService.getSelectedBotSavedDate()}
          className="btn intents-btn intent-font-save"
          onClick={this.updateSlotData}
        >
          Save
        </Button>
      </>
    );
  };

  getBotEntities() {
    entityService.getBotEntity().then((res) => {
      if (res.status === "success") this.setState({ entities: res.data });
    });
  }

  updateSlotData = () => {
    const { slotData, selectedSlot } = this.state;
    let data = slotData;
    let body = {
      slot: selectedSlot,
      data,
    };

    apiService
      .updateSlotData(body)
      .then((res) => {
        // console.log(res)
      })
      .catch((err) =>
        this.props.setNotifications({
          color: "danger",
          message: err.message,
        })
      );
  };

  getBotSlots = async () => {
    try {
      const r = await apiService.getBotSlots();

      if (r.status === "success") {
        const slots = r.data.map((d) => ({ label: d, isSelected: false }));

        return this.setState({ slots, trainedSlots: r.trained });
      }
    } catch (e) {
      console.log(e);
    }
  };

  deleteSelectedBotSlot = () => {
    apiService
      .deleteBotSlot(this.state.selectedSlot)
      .then((res) => {
        if (
          res.status === "success" &&
          Object.keys(res.failed || {}).length === 0
        ) {
          this.setState({ isSelected: false }, () => this.getBotSlots());
          this.props.setNotifications({
            color: "success",
            message: res.message,
          });
        } else {
          this.props.setNotifications({
            color: "danger",
            message:
              Object.keys(res.failed || {}).length === 0
                ? res.message
                : res.failed[Object.keys(res.failed)[0]],
          });
        }
      })
      .catch((err) =>
        this.props.setNotifications({
          color: "danger",
          message: err.message,
        })
      );
  };

  renameSelectedBot = async () => {
    try {
      const { selectedSlot, selectedSlotName } = this.state;
      const r = await apiService.renameBotSlot(selectedSlot, selectedSlotName);

      if (r.status === "success") {
        this.props.setNotifications({
          color: "success",
          message: r.message,
        });
        this.setState({ selectedSlotName: selectedSlot }, this.getBotSlots);
      } else {
        this.props.setNotifications({
          color: "danger",
          message: r.message,
        });
      }
    } catch (e) {
      this.props.setNotifications({
        color: "danger",
        message: e.message,
      });
      console.log(e);
    }
  };

  handleOnActionClick = ({ label }) => {
    switch (label) {
      case "Delete":
        this.setState({ modal: true });
        setTimeout(() => {
          this.setState({ modal: false });
        }, 100);
        break;
      default:
        break;
    }
  };

  handleAddNewSlot = ({ value }) => {
    apiService
      .addBotSlot(value)
      .then((res) => {
        if (res.status === "success") {
          this.getBotSlots();
          this.props.setNotifications({
            color: "success",
            message: res.message,
          });
        } else {
          this.props.setNotifications({
            color: "danger",
            message: res.message,
          });
        }
      })
      .catch((err) =>
        this.props.setNotifications({
          color: "danger",
          message: err.message,
        })
      );
  };

  handleOnAddNewChange = ({ target }) => {
    this.setState({ addNewSlot: target.value });
  };

  handleTitleOnChange = ({ key, target }, type) => {
    const { selectedSlot, selectedSlotName, slots } = this.state;

    if ((key === "Enter" || type) && selectedSlot !== selectedSlotName) {
      if (!slots.find((ele) => ele.label === selectedSlot)) {
        if (/^[A-Za-z][A-Za-z0-9_]+$/.test(selectedSlot)) {
          this.renameSelectedBot();
        } else {
          this.props.setNotifications({
            color: "black",
            message:
              "Slot name should not contain any special characters or spaces",
          });
        }
      } else {
        this.props.setNotifications({
          color: "black",
          message: "Slot already exists",
        });
      }
    } else this.setState({ selectedSlot: target.value });
  };

  handleOnMenuSelect = async ({ label }) => {
    try {
      const r = await apiService.getSlotDetails(label);

      if (r.status === "success") {
        const slots = this.state.slots.map((s) => {
          s.isSelected = s.label === label;
          return s;
        });

        this.setState({
          isSelected: true,
          selectedSlot: label,
          selectedSlotName: label,
          selectedSlotType: r.data.type,
          slotData: r.data,
          slots,
        });
      }
    } catch (e) {
      this.props.setNotifications({
        color: "danger",
        message: e.message,
      });
      console.log(e);
    }
  };

  handleAddNewCate = (e) => {
    const { slotData, addNewCate } = this.state;
    if (e.key === "Enter") {
      let tempData = { ...slotData };
      tempData.values
        ? tempData.values.push(addNewCate)
        : (tempData.values = [addNewCate]);
      this.setState({ slotData: tempData, addNewCate: "" }, () =>
        this.updateSlotData()
      );
    } else {
      typeof e === "string"
        ? this.setState({ addNewCate: e })
        : this.setState({ addNewCate: e.target.value });
    }
  };

  handleSubCate = (v) => {
    let tempData = { ...this.state.slotData };
    tempData.values = tempData.values.filter((t) => t !== v);
    this.setState({ slotData: tempData });
  };

  updateInitialValue = (e) => {
    const data = { ...this.state.slotData };
    typeof e === "string"
      ? (data.initial_value = e)
      : (data.initial_value = e.target.value);
    this.setState({ slotData: data });
  };

  renderEleAccordingToType = () => {
    const { selectedSlotType, slotData, entities, addNewCate } = this.state;

    switch (selectedSlotType.toLowerCase()) {
      case "bool":
        return (
          <div className="row m-0 mt-3">
            <div className="col-3">
              <span className="create-bot-inp-label">Initial Value</span>
            </div>
            <div className="col-auto">
              <Toggle
                checked={slotData.initial_value}
                isDisabled={this.props.permissions.slots.modify !== "enabled"}
                onChange={() => {
                  const data = { ...slotData };
                  data.initial_value = !data.initial_value;
                  this.setState({ slotData: data }, () =>
                    this.updateSlotData()
                  );
                }}
              />
            </div>
          </div>
        );
      case "categorical":
        return (
          <div>
            <div className="row m-0 align-items-center my-2">
              <div className="col-3">
                <label
                  className="create-bot-inp-label"
                  htmlFor="slot-inp-init-val-cate"
                >
                  Initial Value
                </label>
              </div>
              <div className="col">
                <Input
                  isLiteration
                  id="slot-inp-init-val-cate"
                  readOnly={this.props.permissions.slots.modify !== "enabled"}
                  className="create-bot-input slot-inp px-4 w-100"
                  placeholder="Testing"
                  onChange={(e) => {
                    const data = { ...slotData };
                    typeof e === "string"
                      ? (data.initial_value = e)
                      : (data.initial_value = e.target.value);
                    this.setState({ slotData: data });
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") this.updateSlotData();
                  }}
                  value={slotData.initial_value}
                />
              </div>
            </div>

            <div className="row m-0 align-items-center my-1">
              <div className="col-3">
                <label
                  htmlFor="slot-inp-init-val-cate-add"
                  className="create-bot-inp-label"
                >
                  Categories
                </label>
              </div>
              <div className="col">
                <Input
                  isLiteration
                  readOnly={this.props.permissions.slots.modify !== "enabled"}
                  id="slot-inp-init-val-cate-add"
                  className="create-bot-input slot-inp px-4 w-100"
                  onChange={this.handleAddNewCate}
                  onKeyPress={this.handleAddNewCate}
                  placeholder="Add new Categories"
                  value={addNewCate}
                />
              </div>
            </div>

            <div className="row m-0">
              <div className="col offset-3">
                {slotData.values &&
                  slotData.values.map((v, i) => (
                    <div className="row bg-white m-auto my-3 p-2" key={i}>
                      <div className="col">{v}</div>
                      <div className="col-auto cursor-pointer">
                        <i
                          className="fas fa-times"
                          onClick={() =>
                            this.props.permissions.slots.modify !== "enabled" &&
                            this.handleSubCate(v)
                          }
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        );
      case "list":
        return (
          <div className="row m-0 align-items-center my-2">
            <div className="col-3">
              <label
                className="create-bot-inp-label"
                htmlFor="slot-inp-init-val-list"
              >
                Initial Value
              </label>
            </div>
            <div className="col">
              <Input
                isLiteration
                readOnly={this.props.permissions.slots.modify !== "enabled"}
                id="slot-inp-init-val-list"
                className="create-bot-input slot-inp px-3 w-100"
                placeholder="Initial value"
                onChange={this.updateInitialValue}
                value={slotData.initial_value}
                onKeyPress={({ key }) => {
                  if (key === "Enter") this.updateSlotData();
                }}
              />
            </div>
          </div>
        );
      case "text":
        return (
          <div>
            <div className="row m-0 align-items-center my-2">
              <div className="col-3">
                <label
                  className="create-bot-inp-label"
                  htmlFor="slot-inp-init-val-text"
                >
                  Initial Value
                </label>
              </div>
              <div className="col">
                <Input
                  isLiteration
                  readOnly={this.props.permissions.slots.modify !== "enabled"}
                  id="slot-inp-init-val-text"
                  className="create-bot-input slot-inp px-3 w-100"
                  placeholder="Initial value"
                  value={slotData.initial_value}
                  onChange={this.updateInitialValue}
                  onKeyPress={({ key }) => {
                    if (key === "Enter") this.updateSlotData();
                  }}
                />
              </div>
            </div>

            {/* <div className="row mt-5 m-0 my-3">
              <h6>Mappings</h6>
            </div>

            <div className="row m-0">
              <div className="col-3">
                <label className="create-bot-inp-label">Custom: </label>
              </div>
              <div className="col">
                <Toggle
                  checked={slotData.mappings?.custom}
                  onChange={() => {
                    const data = { ...slotData };
                    data.mappings?.custom
                      ? (data.mappings = !data.mappings.custom)
                      : (data.mappings = { custom: true });

                    this.setState({ slotData: data }, () =>
                      this.updateSlotData()
                    );
                  }}
                />
              </div>
            </div> */}

            {/* <div className="row m-0 align-items-center">
              <div className="col-3">
                <label className="create-bot-inp-label my-3">Entities: </label>
              </div>
              <div className="col ps-0">
                <DropDown
                  disabled={!entities}
                  dropdownList={entities.map((e) => ({
                    label: e,
                  }))}
                  isContent
                  btnClassName="w-100"
                  actionButton={() => (
                    <div className="row m-0 bg-white p-2">
                      <div className="col-auto">
                        <span className="text-muted">Select One</span>
                      </div>
                      <div className="col"></div>
                      <div className="col-auto">
                        {getIcon("chevronDown", "black", "", 12)}
                      </div>
                    </div>
                  )}
                  onActionItemClick={({ label }) => {
                    const data = { ...this.state.slotData };
                    if (data.mappings) {
                      if (data.mappings.entities) {
                        data.mappings.entities = [
                          ...data.mappings.entities,
                          label,
                        ];
                      } else data.mappings.entities = [label];
                    } else {
                      data.mappings = { entities: [label] };
                    }
                    this.setState({ slotData: data }, () =>
                      this.updateSlotData()
                    );
                  }}
                />
              </div>
            </div> */}

            {/* {slotData.mappings?.entities?.map((s, i) => (
              <div className="row m-0 my-1" key={i}>
                <div className="col bg-white py-2 offset-3">{s}</div>
                <div className="col-auto bg-white py-2  cursor-pointer">
                  <i
                    className="fas fa-times"
                    onClick={() => {
                      const data = { ...slotData };
                      data.mappings.entities = data.mappings.entities.filter(
                        (d) => d !== s
                      );
                      this.setState({ slotData: data });
                    }}
                  />
                </div>
              </div>
            ))} */}
          </div>
        );
      default:
        break;
    }
  };

  getDeleteModal = (action, i) => {
    return (
      <DeleteConfirmation
        open={this.state.modal}
        delete={() => {
          if (action === "phrase") {
          } else {
            this.deleteSelectedBotSlot();
          }
        }}
      />
    );
  };

  render() {
    const {
      slots,
      trainedSlots,
      addNewSlot,
      isSelected,
      selectedSlot,
      selectedSlotType,
    } = this.state;

    return (
      <div className="container-fluid h-100">
        <div className="row h-100">
          <BotLayoutLeftPanel
            onMenuSelect={(m) =>
              this.props.permissions.slots.content === "enabled" &&
              this.handleOnMenuSelect(m)
            }
            sideBarList={slots}
            title="Slots"
            toggle={this.handleAddNewSlot}
            trainedIntent={trainedSlots}
            addBtnHide={this.props.permissions.slots.add}
          />

          <BotLayoutContent
            isFaq
            onAddNewChange={this.handleOnAddNewChange}
            actions={[
              {
                label: "Delete",
                icon: this.getDeleteModal(),
                isUsed: this.props.permissions.slots.delete === "disabled",
              },
            ]}
            addNewPlacholder="In other words user may say…"
            addNewValue={addNewSlot}
            isSelected={isSelected}
            onActionClick={this.handleOnActionClick}
            titleOnChange={this.handleTitleOnChange}
            titleValue={selectedSlot}
            titleReadOnly={this.props.permissions.slots.modify !== "enabled"}
            isShowContent={this.props.permissions.slots.content === "enabled"}
          >
            {isSelected && this.props.permissions.slots.content === "enabled" && (
              <>
                <div className="row m-0">
                  <div className="col-3">
                    <span className="create-bot-inp-label">Slot Type:</span>
                  </div>
                  <div className="col-3">{selectedSlotType?.toUpperCase()}</div>
                </div>
                {this.renderEleAccordingToType()}
              </>
            )}
          </BotLayoutContent>

          <BotLayoutRightPanel />
        </div>
      </div>
    );
  }
}

export default function Slot() {
  const { setNotifications } = useContext(NotificationContext);
  const { getUserDetails } = useContext(UserContext);

  return (
    <Slots
      setNotifications={setNotifications}
      permissions={getUserDetails().feature_permission}
    />
  );
}
