import React from "react";
import Input from "../fields/input/input.component";
import placeholderImg from "../../assets/images/placeholderImg.png";
import svgIcons from "../../services/icon.service";

function ShowInput({
  placeholder,
  onChange,
  onKeypress,
  value,
  isFaq,
  isSelected,
  isShowContent,
  disableAddNew
}) {
  return (
    <>
      {!isSelected ? (
        <div
          className=""
          style={{
            width: "fit-content",
            position: "absolute",
            top: "40%",
            left: "45%",
          }}
        >
          <p className="mb-4" style={{ color: "#6060604E" }}>
            Select existing option from left side Pannel.
          </p>
          <img className="text-center w-100 px-3" alt="" src={placeholderImg} />
          <div style={{ position: "absolute", left: "4rem", top: "8rem" }}>
            {svgIcons("arrow-up-left")}
          </div>
        </div>
      ) : isSelected && !isShowContent ? (
        <div
          className=""
          style={{
            width: "fit-content",
            position: "absolute",
            top: "45%",
            left: "45%",
          }}
        >
          <p className="mb-4" style={{ color: "#6060604E" }}>
            Contents are restricted to this user.
          </p>
        </div>
      ) : (
        !isFaq && (
          <div className="col-12">
            <Input
              isLiteration
              className="bot-layout-inp"
              placeholder={placeholder}
              onChange={onChange}
              onKeyPress={onKeypress}
              value={value}
              readOnly={disableAddNew}
            />
          </div>
        )
      )}
    </>
  );
}

export default ShowInput;
