import { useContext, useState } from "react";
import {
  CarouselItem,
  Carousel,
  Label,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";
import { NotificationContext } from "../../contexts/notficationContext";
import { UserContext } from "../../contexts/userContext";
import { setToken } from "../../services/authUtil";
import baseUrl from "../../services/baseURL.service";
import Button from "../../components/fields/button/button.component";
import Input from "../../components/fields/input/input.component";
import gnaniLogo from "../../assets/images/gnani-logo.png";
import loginImg4 from "../../assets/icons/left-background.svg";

// import googleSignInOpt from "../../assets/icons/google-sign-in-opt.svg";
import loginImg1 from "../../assets/icons/loginImg1.svg";
import loginImg2 from "../../assets/icons/loginImg2.svg";
import loginImg3 from "../../assets/icons/loginImg3.svg";
// import loginImg4 from "../../assets/icons/4.svg";
import AddNewModal from "../../components/modal/modal.component";
import * as msal from "@azure/msal-browser";
// import microsoft from "../../assets/images/microsoft.png";
import apiServices from "../../services/api.services";

// import ReCAPTCHA from "react-google-recaptcha";

import "./login.scss";
import svgIcons from "../../services/icon.service";

// V2 Dev google re captcha key
// const reCAPTCHA_site_key = "6LdCnEoeAAAAAPWpgdM4AdFHVO8zffjXj5jBMs62";

// V2 Prod google re captcha key
// const reCAPTCHA_site_key = "6Lew8EoeAAAAACNG1M8g98c907C7xZMvCVJpaZBQ";

// localhost testing key
// const reCAPTCHA_site_key = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";

const defaultPermission = {
  feature_permission: {
    dashboard: {
      my_bots: "enabled",
      shared_bots: "enabled",
      marketplace: "enabled",
      create_bot: "enabled",
    },
    my_bots: {
      mybot_content: "enabled",
      share_button: "enabled",
      delete_button: "enabled",
      duplicate_button: "enabled",
    },
    left_nav: {
      flowChart: "enabled",
      intents: "enabled",
      response: "enabled",
      entity: "enabled",
      actions: "enabled",
      knowledge_base: "enabled",
      slots: "enabled",
      forms: "enabled",
      statistics: "enabled",
      integrations: "enabled",
      settings: "enabled",
    },
    top_header: {
      save: "enabled",
      training: "enabled",
      training_logs: "enabled",
      testing: "enabled",
      deployment: "enabled",
    },
    flowChart: {
      list: "enabled",
      content: "enabled",
      edit: "enabled",
      add: { new: "enabled" },
    },
    intents: {
      list: "enabled",
      content: "enabled",
      add: {
        new: "enabled",
        import: "enabled",
        library: "enabled",
      },
      modify: "enabled",
      delete: "enabled",
      export: "enabled",
    },
    responses: {
      list: "enabled",
      content: "enabled",
      add: {
        new: "enabled",
        import: "enabled",
      },
      modify: "enabled",
      delete: "enabled",
      export: "enabled",
    },
    entity: {
      list: "enabled",
      content: "enabled",
      add: {
        new: "enabled",
        import: "enabled",
      },
      modify: "enabled",
      delete: "enabled",
      export: "enabled",
    },
    actions: {
      list: "enabled",
      content: "enabled",
      add: {
        new_action_code: "enabled",
        new_action_api: "enabled",
      },
      modify: "enabled",
      delete: "enabled",
      export: "enabled",
    },
    knowledge_base: {
      list: "enabled",
      content: "enabled",
      add: {
        new: "enabled",
        import: "enabled",
      },
      modify: "enabled",
      delete: "enabled",
      export: "enabled",
    },
    slots: {
      list: "enabled",
      content: "enabled",
      add: {
        new: "enabled",
        import: "enabled",
      },
      modify: "enabled",
      delete: "enabled",
      export: "enabled",
    },
    forms: {
      list: "enabled",
      content: "enabled",
      add: {
        new: "enabled",
        import: "enabled",
      },
      modify: "enabled",
      delete: "enabled",
      export: "enabled",
    },
    statistics: {
      overall: "enabled",
      conversation_logs: "enabled",
      export: "enabled",
    },
    integrations: {
      list: "enabled",
      content: "enabled",
      add: {
        new: "enabled",
        import: "enabled",
      },
      modify: "enabled",
      delete: "enabled",
      export: "enabled",
    },
    settings: {
      general: "enabled",
      backup: "enabled",
      share: "enabled",
      sdk_config: "enabled",
      backup_settings: {
        import: "enabled",
        export: "enabled",
        download: "enabled",
      },
    },
  },
};

const loginImgs = [
  {
    altText: "",
    key: 1,
    src: loginImg1,
    id: 1,
    txt: "Access pre-built templates, multiple rich responses, and more in the repository",
  },
  {
    altText: "",
    key: 2,
    src: loginImg2,
    id: 2,
    txt: "Automate conversations in 20+ languages",
  },
  {
    altText: "",
    key: 3,
    src: loginImg3,
    id: 3,
    txt: "UI-rich dashboard for tracking and optimizing bot performance",
  },
];

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [isBtnClicked, setBtnClick] = useState(false);
  const [password, setPassword] = useState("");
  const [isNewForm, setIsNewForm] = useState(true);
  const [forgotModal, setForgotModal] = useState(false);
  const [forgotEmail, setForgotEmail] = useState("");
  const [isEmailCorrect, setIsEmailCorrect] = useState(false);
  const [inCorrect, setInCorrect] = useState(false);
  const [animating, setAnimating] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const { setNotifications } = useContext(NotificationContext);
  const { setUserDetails, isUserLoggedIn, isSuperAdminLoggedIn } =
    useContext(UserContext);

  isUserLoggedIn(props.history, "/admin/home");
  isSuperAdminLoggedIn(props.history, "/super_admin/list_orgs");

  const getUserProfile = (data, callBack) => {
    let reqOptions = {
      method: "GET",
      headers: { token: data.token },
    };
    apiServices
      .fetchData(`${baseUrl}/get_profile`, reqOptions)
      .then((res) => {
        getFeaturPermission(data, res.data, callBack);
      })
      .catch((err) => console.log(err));
  };

  const getFeaturPermission = (loginData, userData, callBack) => {
    const reqOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", token: loginData.token },
      body: JSON.stringify({ email: email }),
    };
    apiServices
      .fetchData(`${baseUrl}/get_user_permissions`, reqOptions)
      .then((res) => {
        if (res.status === "success")
          setUserDetails({ ...loginData, ...userData, ...res.data });
        else
          setUserDetails({ ...loginData, ...userData, ...defaultPermission });
        callBack && callBack();
      });
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (email && password) {
      const requestOptions = {
        body: JSON.stringify({ email, password }),
        headers: { "Content-Type": "application/json" },
        method: "POST",
        mode: "cors",
      };

      try {
        const { data, message, status } = await apiServices.fetchData(
          `${baseUrl}/login`,
          requestOptions
        );

        if (status === "success") {
          getUserProfile(data, () => props.history.push("/admin/home"));
          setToken(data.token);
        } else setNotifications({ color: "danger", message });
      } catch ({ message }) {
        setNotifications({ color: "danger", message });
      }
      setPassword("");
      setEmail("");
    } else setBtnClick(true);
  };

  const toggle = () => {
    props.history.push("/contact_us");
  };

  const toggleForgotModal = () => {
    setForgotModal(!forgotModal);
  };

  const handleForgotEmail = async (e) => {
    e.preventDefault();

    if (forgotEmail) {
      const requestOptions = {
        body: JSON.stringify({
          email: forgotEmail,
        }),
        headers: { "Content-Type": "application/json" },
        method: "POST",
        mode: "cors",
      };

      try {
        const { message, status } = await apiServices.fetchData(
          `${baseUrl}/forgot_password`,
          requestOptions
        );

        if (status === "success") {
          setIsNewForm(false);
          setIsEmailCorrect(true);
          setNotifications({ color: "success", message });
        } else {
          setInCorrect(true);
        }
      } catch ({ message }) {
        setInCorrect(true);
      }
    }
  };

  const getForgotModal = () => {
    return (
      <AddNewModal
        noCloseBtn
        modalSize="md"
        bodyClassName="p-5"
        title={`${isEmailCorrect ? "Forgot password" : "Update your password"}`}
        toggle={toggleForgotModal}
        addNewModalOpen={forgotModal}
      >
        <div
          className={`${
            isEmailCorrect ? "mt-4" : "mt-5"
          } d-flex justify-content-center px-3`}
        >
          {isNewForm && (
            <form className="needs-validation" onSubmit={handleForgotEmail}>
              <div className="row mb-3 me-1">
                <label
                  for="email"
                  className="col-auto me-3 align-self-center form-label text"
                >
                  Email address
                </label>
                <Input
                  type="email"
                  id="email"
                  className={`${
                    inCorrect ? "is-invalid" : "valid"
                  } col p-2 form-control inp-placeholder-clr text`}
                  placeholder="Enter your email address"
                  value={forgotEmail}
                  onChange={(e) => setForgotEmail(e.target.value)}
                />
                <div
                  id="emailFeedback"
                  className="invalid-feedback text-center"
                >
                  Sorry this email address is not registered...!
                </div>
              </div>
              <div className="d-flex justify-content-center mt-4 p-3 pb-0">
                <Button
                  className="me-2 new-cancel px-4"
                  type="reset"
                  onClick={() => {
                    setForgotModal(false);
                    setForgotEmail("");
                    setInCorrect(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="new-confirm px-4 ms-2"
                  type="submit"
                  isDisabled={!forgotEmail}
                >
                  {inCorrect ? "Try Again" : "Confirm"}
                </Button>
              </div>
            </form>
          )}
          {isEmailCorrect && (
            <div className="">
              <div>
                <small className="mb-3">
                  Click on the link which we sent to
                </small>{" "}
                <br />
                <span>**********@gmail.com</span>
              </div>
              <div className="mt-5 text-center">
                <Button
                  onClick={() => {
                    toggleForgotModal();
                    setIsNewForm(true);
                    setIsEmailCorrect(false);
                    setForgotEmail("");
                  }}
                  className="confirm-btn px-4"
                >
                  Ok
                </Button>
              </div>
            </div>
          )}
        </div>
      </AddNewModal>
    );
  };

  const slides = loginImgs.map((item, i) => {
    return (
      <CarouselItem
        className=""
        onExited={() => setAnimating(false)}
        onExiting={() => setAnimating(true)}
        key={i}
      >
        <div className="left-panel-text">{item.txt}</div>
        <img alt="" src={item.src} className="slider-images" />
      </CarouselItem>
    );
  });

  function next() {
    if (animating) return;
    const nextIndex =
      activeIndex === loginImgs.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const msalConfig = {
    auth: {
      clientId: "56914abc-62d4-4e9a-aa96-709073d00b1c",
      authority:
        "https://login.microsoftonline.com/7e1fe7d8-4066-4691-ac74-000d6185708d",
    },
  };
  const msalInstance = new msal.PublicClientApplication(msalConfig);

  var loginRequest = {
    scopes: ["user.read", "mail.send"], // optional Array<string>
  };

  let MSLoginSuccess = async (msg) => {
    const user = msg.account;
    sessionStorage.setItem("env", user.environment);
    sessionStorage.setItem("username", user.username);
    let email = user.username;
    let name = user.name;
    const requestOptions = {
      body: JSON.stringify({ email, name }),
      method: "POST",
    };
    let data, message;

    apiServices
      .fetchData(`${baseUrl}/check_ms_user`, requestOptions)
      .then((resp) => {
        if (resp.status === "success") {
          data = resp.data;
          message = resp.data.message;
          setToken(data.token);
          getUserProfile(data, () => props.history.push("/admin/home"));
        } else {
          console.log("this is resp", resp);
        }
      })
      .catch((e) => {
        setNotifications({ color: "danger", message });
      });

    // changeLoggedIn(true)
  };

  let startMsLogin = () => {
    msalInstance
      .loginPopup(loginRequest)
      .then((res) => {
        MSLoginSuccess(res);
      })
      .catch((err) => {
        alert("login failed");
        console.log(err, "is error");
      });
  };

  function previous() {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? loginImgs.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  return (
    <div className="d-flex m-0 h-100 ">
      <div
        className="left-window"
        style={{ backgroundImage: `url(${loginImg4})`, width: "60.9375rem" }}
      >
        <Carousel
          className="carousel-panel"
          next={next}
          pause={false}
          ride="carousel"
          previous={previous}
          activeIndex={activeIndex}
        >
          <CarouselIndicators
            className="carousel-indicators1"
            items={loginImgs}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />

          {slides}
        </Carousel>
      </div>

      <div
        className="d-flex flex-column login-right-side"
        style={{ width: "59.0625rem" }}
      >
        <div className="loginPage-form py-5 m-auto">
          <div className="sign-in-text mb-3">Login</div>
          <div className="sign-in-text mb-5">To access Bot Builder</div>

          <form className="needs-validation" onSubmit={handleLogin}>
            <div className="form-group mt-5 mb-3">
              <Label className="input-label pb-2" for="exampleEmail">
                Email address
              </Label>
              <Input
                className="form-control inp-placeholder-clr-login px-3"
                errorText="Email field is empty!"
                isBtnClicked={isBtnClicked}
                onChange={({ target }) => setEmail(target.value)}
                placeholder="name@gmail.com"
                type="email"
                value={email}
              />
            </div>

            <div className="form-group mt-3 mb-5 password-container">
              <Label className="input-label py-2" for="exampleEmail">
                Password
              </Label>
              <Input
                className="form-control inp-placeholder-clr-login px-3"
                errorText="Password field is empty!"
                isBtnClicked={isBtnClicked}
                onChange={({ target }) => setPassword(target.value)}
                placeholder="********************"
                type="password"
                value={password}
              />
              <a
                className="forgot-pwd-link forgot-password-link"
                onClick={toggleForgotModal}
                role="button"
              >
                Forgot Password
              </a>
            </div>
            <Button className="mt-3 w-100 btn-login my-1" type="submit">
              Login
            </Button>
            <div className="mt-3">
              <span className="login-dont-have-acc-text me-2">
                Don't have an account?
              </span>{" "}
              <a
                className="login-contact-us-link"
                onClick={toggle}
                role="button"
              >
                Contact us
              </a>
            </div>
          </form>
          {forgotModal && getForgotModal()}

          <div className="row align-items-center my-5">
            <div className="col">
              <hr className="hr-line" />
            </div>
            <div className="col-auto or-text">Or</div>
            <div className="col">
              <hr className="hr-line" />
            </div>
          </div>

          <Button className="w-100 ms-login-btn" onClick={startMsLogin}>
            <div className="d-flex justify-content-center">
              <div className="mx-2">{svgIcons("ms_login")}</div>
              <div>Login with Microsoft (Gnani.ai only)</div>
            </div>
          </Button>
        </div>

        {/* <p className="login-sign-in-text mb-1">Sign in using</p>
                  <div className="google-sign-in-opt-img-container">
                    <img alt="google-sign-in-opt" src={googleSignInOpt} />
                  </div> */}
      </div>
    </div>
  );
};

export default Login;
