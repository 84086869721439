import botService from "../bot.service";
import baseUrl from "../baseURL.service";
import { getToken } from "../authUtil";
import apiServices from "../api.services";

const fetchData = (url, options) => {
  return apiServices.fetchData(url, { ...options });
};

function getBotIntents() {
  let reqOptions = {
    method: "GET",
    headers: {
      token: getToken(),
    },
  };
  return fetchData(
    `${baseUrl}/get_bot_intents/${botService.getSelectedBotId()}`,
    reqOptions
  );
}

function getIntentLibrary(params) {
  const reqOptions = {
    method: "GET",
    headers: {
      token: getToken(),
    },
  };
  return fetchData(
    `${baseUrl}/get_intent_library_details?${params}`,
    reqOptions
  );
}

const getIntentSentences = (intent, page = 1) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  return fetchData(
    `${baseUrl}/get_intent_sentences/${botService.getSelectedBotId()}?intent=${intent}&page_number=${page}`,
    requestOptions
  );
};

const deleteBotIntent = (data) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(data),
  };
  return fetchData(
    `${baseUrl}/delete_bot_intent/${botService.getSelectedBotId()}`,
    requestOptions
  );
};

const updateBotIntent = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(data),
  };
  return fetchData(
    `${baseUrl}/modify_bot_intent/${botService.getSelectedBotId()}`,
    requestOptions
  );
};

const addBotIntent = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(data),
  };
  return fetchData(
    `${baseUrl}/add_bot_intent/${botService.getSelectedBotId()}`,
    requestOptions
  );
};

const addIntentLibrary = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify({ data: data }),
  };
  return fetchData(
    `${baseUrl}/add_intent_library/${botService.getSelectedBotId()}`,
    requestOptions
  );
};

const renameBotIntent = (dataObj) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };
  return fetchData(
    `${baseUrl}/rename_bot_intent/${botService.getSelectedBotId()}`,
    requestOptions
  );
};

const importIntents = (file, update_config_data) => {
  let formData = new FormData();
  formData.append("file", file);
  let ext = file?.name?.split(".").pop();
  let url =
    ext === "json"
      ? `import_json/${botService.getSelectedBotId()}?config_import_key=intents&update_config_data=${
          update_config_data ? "True" : "False"
        }`
      : `import_intents/${botService.getSelectedBotId()}?update_config_data=${
          update_config_data ? "True" : "False"
        }`;
  const requestOptions = {
    method: "POST",
    headers: { token: getToken() },
    body: formData,
  };
  return fetchData(`${baseUrl}/${url}`, requestOptions);
};

const validateImport = (file) => {
  let formData = new FormData();
  formData.append("file", file);
  let ext = file?.name?.split(".").pop();
  let url =
    ext === "json"
      ? `validate_import_json/${botService.getSelectedBotId()}?config_import_key=intents`
      : `validate_import_csv/${botService.getSelectedBotId()}?config_import_key=intents`;
  const requestOptions = {
    method: "POST",
    headers: { token: getToken() },
    body: formData,
  };
  return fetchData(`${baseUrl}/${url}`, requestOptions);
};

const intentServices = {
  getBotIntents,
  renameBotIntent,
  deleteBotIntent,
  addBotIntent,
  getIntentSentences,
  updateBotIntent,
  importIntents,
  getIntentLibrary,
  addIntentLibrary,
  validateImport,
};

export default intentServices;
