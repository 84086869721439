import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import svgIcons from "../services/icon.service";
import DropDown from "./fields/dropDown/dropDown.component";
import Buttons from "./fields/button/button.component";

const RecrawlModal = (props) => {
  const [isModal, setModal] = useState(props.open);
  const [updatedDepth, setUpdatedDepth] = useState(null);

  useEffect(() => {
    props.setRecrawlDepth(props.depth);
  }, []);

  const toggle = () => setModal(!isModal);

  const submit = () => {
    props.submit();
  };

  return (
    <>
      {svgIcons("recrawl")}
      <Modal
        color="black"
        isOpen={isModal}
        style={{ marginTop: "40vh" }}
        toggle={toggle}
      >
        <ModalHeader className="d-flex justify-content-center">
          Recrawl Website
        </ModalHeader>
        <ModalBody className=" bot-del-btn-font pt-4">
          <form>
            <div className="mb-3 ms-2 me-3 row">
              <label htmlFor="website-link" className="col-form-label col-auto">
                Website Link
              </label>
              <input
                type="text"
                className=" col"
                style={{
                  background: "#fff",
                  border: "1px solid rgba(0, 0, 0, 0.11)",
                  borderRadius: "6px",
                }}
                id="website-link"
                disabled
                placeholder={props.websiteUrl}
              />
            </div>
            <div className="mb-3 ms-2 me-3 row">
              <label className="col-form-label col-auto">Select Depth</label>
              <div className="col px-0">
                <DropDown
                  btnClassName="recrawl-depth-dropdown"
                  dropdownList={[
                    { label: 1 },
                    { label: 2 },
                    { label: 3 },
                    { label: 4 },
                    { label: 5 },
                  ]}
                  actionButton={() => (
                    <div className="d-flex justify-content-between align-items-center depth-dropdown-text">
                      <div>
                        {updatedDepth === null ? props.depth : updatedDepth}
                      </div>
                      {svgIcons("angle-down")}
                    </div>
                  )}
                  onActionItemClick={(l) => {
                    setUpdatedDepth(l.label);
                    props.setRecrawlDepth(l.label);
                  }}
                />
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter className="bg-white border-0 modal-footer d-flex justify-content-center">
          <Buttons
            className="w-auto rounded-1 py-2 modal-btn-cancel"
            onClick={() => {
              toggle();
              props.cancel && props.cancel();
            }}
          >
            Cancel
          </Buttons>
          <Buttons
            className="w-auto rounded-1 py-2 text-white modal-btn-confirm"
            onClick={() => {
              toggle();
              submit();
            }}
          >
            Submit
          </Buttons>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default RecrawlModal;
