import baseUrl from "./baseURL.service";
import botService from "./bot.service";
import { getToken } from "../services/authUtil";

let bot_id = botService.getSelectedBotId();

const fetchData = (url, options, callBack) => {
  return botService.fetchData(url, { ...options }, callBack);
};

const getChannels = () => {
  const requestOptions = {
    method: "GET",
    headers: {
      token: getToken(),
    },
  };
  return fetchData(`${baseUrl}/channels`, requestOptions);
};

const getRegions = () => {
  const requestOptions = {
    method: "GET",
    headers: {
      token: getToken(),
    },
  };
  return fetchData(`${baseUrl}/regions`, requestOptions);
};

const getTimeZone = (region) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  return fetchData(`${baseUrl}/timezones/${region}`, requestOptions);
};

const getIndustries = () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  return fetchData(`${baseUrl}/industries`, requestOptions);
};

const getLanguages = () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  return fetchData(`${baseUrl}/languages`, requestOptions);
};

const deleteBotEntity = (data) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(data),
  };
  return fetchData(`${baseUrl}/delete_bot_entity/${bot_id}`, requestOptions);
};

const updateBotEntity = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(data),
  };
  return fetchData(`${baseUrl}/modify_bot_entity/${bot_id}`, requestOptions);
};

const addBotEntity = (body) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(body),
  };

  return fetchData(`${baseUrl}/add_bot_entity/${bot_id}`, requestOptions);
};

const renameBotEntity = (data) => {
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify({
      data,
    }),
  };
  return fetchData(`${baseUrl}/rename_bot_entity/${bot_id}`, requestOptions);
};

const getEntityValues = (intent) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  return fetchData(
    `${baseUrl}/get_entity_values/${bot_id}?entity=${intent}`,
    requestOptions
  );
};

const deleteBotIntent = (data) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(data),
  };
  return fetchData(`${baseUrl}/delete_bot_intent/${bot_id}`, requestOptions);
};

const updateBotIntent = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(data),
  };
  return fetchData(`${baseUrl}/modify_bot_intent/${bot_id}`, requestOptions);
};

const addBotIntent = (data) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(data),
  };
  return fetchData(`${baseUrl}/add_bot_intent/${bot_id}`, requestOptions);
};

const renameBotIntent = (dataObj) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };
  return fetchData(`${baseUrl}/rename_bot_intent/${bot_id}`, requestOptions);
};

const getIntentSentences = (intent) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  return fetchData(
    `${baseUrl}/get_intent_sentences/${bot_id}?intent=${intent}`,
    requestOptions
  );
};

const addBotUtterance = (dataObj) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };
  return fetchData(`${baseUrl}/add_bot_utterance/${bot_id}`, requestOptions);
};

const renameBotUtterance = (dataObj) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };
  return fetchData(`${baseUrl}/rename_bot_utterance/${bot_id}`, requestOptions);
};

const getBotUtterance = (intent) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  return fetchData(
    `${baseUrl}/get_utterance_responses/${bot_id}?utterance=${intent}`,
    requestOptions
  );
};

const deleteBotUtterance = (dataObj) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };
  return fetchData(`${baseUrl}/delete_bot_utterance/${bot_id}`, requestOptions);
};

const updateBotUtterance = (dataObj) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };

  return fetchData(`${baseUrl}/modify_bot_utterance/${bot_id}`, requestOptions);
};

const uploadImageForResponse = (formData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      // "Content-Type": "application/json",
      token: getToken(),
    },
    body: formData,
  };
  return fetchData(`${baseUrl}/upload_image/${bot_id}`, requestOptions);
};

const uploadImageOnCustRep = (formData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      // "Content-Type": "application/json",
      token: getToken(),
    },
    body: formData,
  };
  return fetchData(`${baseUrl}/upload_image/bot_icons`, requestOptions);
};

const downloadStatistics = () => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "applications/json",
      token: getToken(),
    },
  };
  return fetchData(`${baseUrl}/download_report/${bot_id}`, requestOptions);
};

const uploadFaq = (formData) => {
  const requestOptions = {
    method: "POST",
    headers: {
      // "Content-Type": "application/json",
      token: getToken(),
    },
    body: formData,
  };
  fetchData(`${baseUrl}/upload_faq/${bot_id}`, requestOptions).then(
    (response) => response.json()
  );
};

const deleteBotFaq = (dataObj) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };
  return fetchData(`${baseUrl}/delete_bot_faq/${bot_id}`, requestOptions);
};

const updateBotFaq = (intentSentences) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(intentSentences),
  };
  return fetchData(`${baseUrl}/modify_bot_faq/${bot_id}`, requestOptions);
};

const addBotFaq = (dataObj) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };
  return fetchData(`${baseUrl}/add_bot_faq/${bot_id}`, requestOptions);
};

const renameBotFaq = (dataObj) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };
  return fetchData(`${baseUrl}/rename_bot_faq/${bot_id}`, requestOptions);
};
const getBotFaqDetails = (intent) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  return fetchData(
    `${baseUrl}/get_bot_faq_details/${bot_id}?faq=${intent}`,
    requestOptions
  );
};

const deleteBotAction = (dataObj) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };
  return fetchData(`${baseUrl}/delete_bot_action/${bot_id}`, requestOptions);
};

const updateBotAction = (dataObj) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };
  return fetchData(`${baseUrl}/modify_bot_action/${bot_id}`, requestOptions);
};

const addBotAction = (body) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(body),
  };
  return fetchData(`${baseUrl}/add_bot_action/${bot_id}`, requestOptions);
};

const renameBotAction = (dataObj) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };

  return fetchData(`${baseUrl}/rename_bot_action/${bot_id}`, requestOptions);
};

const getActionDetails = (intent) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  return fetchData(
    `${baseUrl}/get_action_details/${bot_id}?action=${intent}`,
    requestOptions
  );
};

const getSettingToken = (dataObj) => {
  let reqOptions = {
    method: "POST",
    headers: { token: getToken(), "Content-Type": "application/json" },
    body: JSON.stringify(dataObj),
  };
  return fetchData(`${baseUrl}/getToken`, reqOptions);
};

const getTopEntitiesStatistics = (getUrlParams) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "applications/json",
      token: getToken(),
    },
  };
  return fetchData(
    `${baseUrl}/get_top_entities/${bot_id}${getUrlParams()}`,
    requestOptions
  );
};

const getTopIntentsStatistics = (getUrlParams) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "applications/json",
      token: getToken(),
    },
  };
  return fetchData(
    `${baseUrl}/get_top_intents/${bot_id}${getUrlParams()}`,
    requestOptions
  );
};

const getCountStatistics = (getUrlParams) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  return fetchData(
    `${baseUrl}/count/${bot_id}${getUrlParams()}`,
    requestOptions
  );
};

const getChannelStatsStatistics = (getUrlParams) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "applications/json",
      token: getToken(),
    },
  };
  return fetchData(
    `${baseUrl}/channel_stats/${bot_id}${getUrlParams()}`,
    requestOptions
  );
};

const getStatsCountStatistics = (getUrlParams) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  return fetchData(
    `${baseUrl}/call_stats/${bot_id}${getUrlParams()}`,
    requestOptions
  );
};

const createEditTreeInFlow = () => {
  let bot_id = botService.getSelectedBotId();
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  return fetchData(`${baseUrl}/get_bot_paths/${bot_id}`, requestOptions);
};

const updateBotPath = (data) => {
  let bot_id = botService.getSelectedBotId();
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  return fetchData(`${baseUrl}/update_bot_paths/${bot_id}`, requestOptions);
};

const createBotInFlowChart = (body) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(body),
  };
  return fetchData(`${baseUrl}/create_bot`, requestOptions);
};

const getBotDetails = (botId) => {
  const reqOptions = {
    method: "GET",
    headers: {
      token: getToken(),
    },
  };
  return fetchData(`${baseUrl}/get_bot_details/${botId}`, reqOptions);
};

const deleteBot = (bot) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      token: getToken(),
    },
  };
  // console.log(bot, bot_id);
  return fetchData(
    `${baseUrl}/delete_bot/${bot?.bot_id || bot_id}`,
    requestOptions
  );
};

const loginMet = (dataObj) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(dataObj),
    headers: { "Content-Type": "application/json" },
  };
  return fetchData(`${baseUrl}/login`, requestOptions);
};

const registerMet = (dataObj) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(dataObj),
    headers: { "Content-Type": "application/json" },
  };
  return fetchData(`${baseUrl}/register`, requestOptions);
};

const getChatRes = (dataObj) => {
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(dataObj),
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };
  return fetchData(`${baseUrl}/talk/${bot_id}`, requestOptions);
};

const getChatBotMsg = () => {
  let reqOptions = {
    method: "GET",
    headers: {
      token: getToken(),
    },
  };
  return fetchData(`${baseUrl}/get_initial_message/${bot_id}`, reqOptions);
};

const deploymentDetails = () => {
  let reqOptions = {
    method: "GET",
    headers: {
      token: getToken(),
    },
  };
  return fetchData(`${baseUrl}/deployment_details/${bot_id}`, reqOptions);
};

const triggerCallDet = (dataObj) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };

  let bot_id = botService.getSelectedBotId() || this.props.test_bot_ID;
  return fetchData(`${baseUrl}/trigger_call/${bot_id}`, requestOptions);
};

const updateBotDetails = (dataObj, id) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };

  id && (bot_id = id);

  return fetchData(`${baseUrl}/update_bot_details/${bot_id}`, requestOptions);
};

const importFileData = (formData) => {
  let bot_id = botService.getSelectedBotId();

  const reqObj = {
    method: "POST",
    headers: {
      // "Content-Type": "multipart/form-data",
      token: getToken(),
    },
    body: formData,
  };

  return fetchData(`${baseUrl}/import/${bot_id}`, reqObj);
};

const duplicate_bot = (dataObj, bot_id) => {
  const reqObj = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };

  return fetchData(`${baseUrl}/duplicate_bot/${bot_id}`, reqObj);
};

const getBasicStats = (bot_id) => {
  const reqObj = {
    method: "get",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };

  return fetchData(`${baseUrl}/basic_statistics/${bot_id}`, reqObj);
};

const getBotStatus = (bot_id) => {
  const reqObj = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };

  return fetchData(`${baseUrl}/fetch_bot_status/${bot_id}`, reqObj);
};

const getBotFlowID = (bot_id) => {
  const reqObj = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
  };

  return fetchData(`${baseUrl}/get_prod_flow_id/${bot_id}`, reqObj);
};

const updateBotFlowID = (dataObj, bot_id) => {
  const reqObj = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      token: getToken(),
    },
    body: JSON.stringify(dataObj),
  };

  return fetchData(`${baseUrl}/update_prod_flow_id/${bot_id}`, reqObj);
};

const ApiService = {
  fetchData,
  getBasicStats,
  duplicate_bot,
  importFileData,
  deleteBotEntity,
  updateBotEntity,
  addBotEntity,
  renameBotEntity,
  getEntityValues,
  deleteBotIntent,
  updateBotIntent,
  addBotIntent,
  renameBotIntent,
  getIntentSentences,
  addBotUtterance,
  renameBotUtterance,
  getBotUtterance,
  deleteBotUtterance,
  updateBotUtterance,
  uploadImageForResponse,
  downloadStatistics,
  uploadFaq,
  deleteBotFaq,
  updateBotFaq,
  addBotFaq,
  renameBotFaq,
  getBotFaqDetails,
  deleteBotAction,
  updateBotAction,
  addBotAction,
  renameBotAction,
  getActionDetails,
  getSettingToken,
  getStatsCountStatistics,
  getTopIntentsStatistics,
  getCountStatistics,
  getChannelStatsStatistics,
  getTopEntitiesStatistics,
  createEditTreeInFlow,
  updateBotPath,
  createBotInFlowChart,
  deleteBot,
  loginMet,
  registerMet,
  getChatRes,
  getChatBotMsg,
  deploymentDetails,
  triggerCallDet,
  getRegions,
  getTimeZone,
  getIndustries,
  getLanguages,
  uploadImageOnCustRep,
  getChannels,
  getBotDetails,
  updateBotDetails,
  getBotStatus,
  getBotFlowID,
  updateBotFlowID,
};
export default ApiService;
