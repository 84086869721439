import "./graph.scss";

const Graph = ({ footer, header }) => {
  return (
    <div className="graph mt-2 mb-3">
      <header>
        <span>{header}</span>
      </header>
      <footer>{footer}</footer>
    </div>
  );
};

export default Graph;
