import { Component, useContext, useState } from "react";
import { Tooltip } from "reactstrap";

import BotLayoutLeftPanel from "../../components/botLayout/botLayoutLeftPanel.component";
import BotLayoutContent from "../../components/botLayout/botLayoutContent.component";
import ModalComponent from "../../components/modal/modal.component";
import integrationServices from "../../services/api.services/integrationsApi.service";
import Avaya from "../../assets/images/Voice/Avaya.png";
import Cisco from "../../assets/images/Voice/Cisco.png";
import Genesys from "../../assets/images/Voice/Genesys.png";
import Blue_Prism from "../../assets/images/RPA/Blue_Prism.png";
import Oracle from "../../assets/images/CRM/Oracle.png";
import Microsoft from "../../assets/images/CRM/microsoft-dynamics.png";
import "./integrations.scss";
import svgIcons from "../../services/icon.service";
import Input from "../../components/fields/input/input.component";
import { Col, Row } from "reactstrap";
import { BotContext } from "../../contexts/BotContext";
import DropDown from "../../components/fields/dropDown/dropDown.component";
import { NotificationContext } from "../../contexts/notficationContext";
import { UserContext } from "../../contexts/userContext";
import botService from "../../services/bot.service";
import baseUrl from "../../services/baseURL.service";
import { instanceUrl } from "../../services/baseURL.service";
import Button from "../../components/fields/button/button.component";
import ShowInput from "../../components/botLayout/showInput";

const _integrations = [
  {
    title: "Voice",
    list: [
      {
        _type: "Voice",
        icon: svgIcons("gnani_voice"),
        label: "Gnani Voice",
        newObject: {
          channel: "gnani_voice",
          details: { agent_transfer_number: [] },
        },
      },
      {
        _type: "Voice",
        icon: svgIcons("twilio_voice"),
        label: "Twillio Voice",
        newObject: {
          channel: "twilio_voice",
          details: {
            agent_transfer_number: [],
            sip_domain_friendly_name: "",
            twilio_auth_token: "",
            twilio_caller_id: "",
            twilio_sid: "",
          },
        },
      },
      {
        image: <img src={Avaya} alt="avaya" width={"42"} height={"12"} />,
        label: "Avaya",
        _type: "Voice",
      },
      {
        image: <img src={Cisco} alt="cisco" width={"45"} height={"30"} />,
        label: "Cisco",
        _type: "Voice",
      },
      {
        image: <img src={Genesys} alt="genesys" width={"45"} height={"11"} />,
        label: "Genesys",
        _type: "Voice",
      },
    ],
  },
  {
    title: "Chat",
    list: [
      // {
      //   _type: "Chat",
      //   icon: svgIcons("ui_path"),
      //   label: "UI Path",
      //   newObject: {
      //     channel: "ui_path",
      //     details: { client_id: '', user_id: '', process_name: '', robot_name: '', service_name: '', account_name: '' },
      //   },
      // },
      {
        _type: "Chat",
        icon: svgIcons("whatsapp"),
        label: "Gupshup Whatsapp",
        newObject: {
          channel: "whatsapp_gupshup",
          details: { app: "", gupshup_number: "", api_key: "" },
        },
      },
      {
        _type: "Chat",
        icon: svgIcons("whatsapp"),
        label: "Twilio Whatsapp",
        newObject: {
          channel: "twilio_whatsapp",
          details: { account_sid: "", auth_token: "", twilio_number: "" },
        },
      },
      {
        _type: "Chat",
        icon: svgIcons("twilio_sms"),
        label: "Twilio SMS",
        newObject: {
          channel: "twilio_sms",
          details: { account_sid: "", auth_token: "", message_sid: "" },
        },
      },
      {
        _type: "Chat",
        icon: svgIcons("twilio_messaging"),
        label: "Twilio Messaging",
        newObject: {
          channel: "twilio_messaging",
          details: { account_sid: "" },
        },
      },
      {
        _type: "Chat",
        icon: svgIcons("telegram"),
        label: "Telegram",
        newObject: {
          channel: "telegram",
          details: { access_token: "", verify: "" },
        },
      },
      {
        _type: "Chat",
        icon: svgIcons("slack"),
        label: "Slack",
        newObject: {
          channel: "slack",
          details: {
            slack_channel: "",
            slack_token: "",
            slack_signing_secret: "",
          },
        },
      },
      {
        _type: "Chat",
        icon: svgIcons("microsoft_botframework", "#000"),
        label: "Microsoft Teams",
        newObject: {
          channel: "microsoft_botframework",
          details: { app_id: "", app_password: "" },
        },
      },
      {
        // _type: "Chat",
        icon: svgIcons("facebook"),
        label: "Facebook Messenger",
        // disabled: "Coming soon...",
      },
      // { icon: svgIcons('close'), label: 'Slack' },
      // { icon: svgIcons('close'), label: 'Microsoft Bot Framework' },
      // { icon: svgIcons('close'), label: 'Webex' },
      // { icon: svgIcons('close'), label: 'Google Hangouts' },
    ],
  },
  {
    title: "CRM",
    list: [
      {
        _type: "CRM",
        icon: svgIcons("freshworks"),
        label: "Freshworks",
        newObject: { type: "freshworks", api_key: "", domain_url: "" },
      },
      {
        _type: "CRM",
        icon: svgIcons("zoho"),
        label: "Zoho",
        newObject: {
          type: "zoho",
          client_id: "",
          client_secret: "",
          code: "",
          base_url: "accounts.zoho.in",
        },
      },
      {
        _type: "CRM",
        icon: svgIcons("zendesk"),
        label: "Zendesk",
        newObject: {
          type: "zendesk_sell",
          code: "",
          redirect_uri: `${instanceUrl}/bot/integrations`,
          client_id: "",
          client_secret: "",
        },
      },
      {
        _type: "CRM",
        icon: svgIcons("salesforce"),
        label: "Salesforce",
        newObject: {
          instance_url: "",
          code: "",
          redirect_uri: `${instanceUrl}/bot/integrations`,
          client_id: "",
          client_secret: "",
          type: "salesforce",
        },
      },
      {
        _type: "CRM",
        icon: svgIcons("servicenow"),
        label: "Servicenow",
        newObject: {
          code: "",
          redirect_uri: `${instanceUrl}/bot/integrations`,
          client_id: "",
          client_secret: "",
          domain_url: "",
          type: "servicenow",
        },
      },
      {
        image: (
          <img src={Microsoft} alt="microsoft" width={"45"} height={"14"} />
        ),
        label: "Microsoft Dynamics",
      },
      {
        image: <img src={Oracle} alt="oracle" width={"45"} height={"8"} />,
        label: "Oracle",
      },
    ],
  },
  {
    title: "Ticketing",
    list: [
      {
        _type: "Ticket",
        icon: svgIcons("freshservice"),
        label: "Freshservice",
        newObject: { type: "freshservice", api_key: "", domain_url: "" },
      },
      {
        _type: "Ticket",
        icon: svgIcons("zoho"),
        label: "Zoho desk",
        newObject: {
          type: "zoho_desk",
          client_id: "",
          client_secret: "",
          code: "",
          base_url: "",
        },
      },
      {
        _type: "Ticket",
        icon: svgIcons("zendesk"),
        label: "Zendesk",
        newObject: {
          code: "",
          client_id: "",
          client_secret: "",
          instance_url: "",
          redirect_uri: `${instanceUrl}/bot/integrations`,
          type: "zen_desk",
        },
      },
      {
        _type: "Ticket",
        icon: svgIcons("salesforce"),
        label: "Salesforce",
        newObject: {
          instance_url: "",
          code: "",
          redirect_uri: `${instanceUrl}/bot/integrations`,
          client_id: "",
          client_secret: "",
          type: "salesforce",
        },
      },
      {
        _type: "Ticket",
        icon: svgIcons("servicenow"),
        label: "Servicenow",
        newObject: {
          code: "",
          redirect_uri: `${instanceUrl}/bot/integrations`,
          client_id: "",
          client_secret: "",
          domain_url: "",
          type: "servicenow",
        },
      },
      {
        // _type: "Ticket",
        icon: svgIcons("jira"),
        label: "Jira",
        // disabled: "Coming soon...",
      },
    ],
  },
  {
    title: "Agent Transfer",
    list: [
      {
        _type: "Chat",
        icon: svgIcons("freshchat"),
        label: "Freshchat",
        newObject: {
          channel: "freshchat",
          details: {
            agent_id: "",
            app_id: "",
            channel_id: "",
            domain_url: "",
            token: "",
          },
        },
      },
      {
        _type: "Chat",
        icon: svgIcons("salesforce_chat"),
        label: "Salesforce Chat",
        newObject: {
          channel: "salesforce_chat",
          details: {
            organization_id: "",
            domain_url: "",
            deployment_id: "",
            button_id: "",
          },
        },
      },
      {
        _type: "Chat",
        icon: svgIcons("servicenow_chat"),
        label: "Service Now Chat",
        newObject: {
          channel: "servicenow_chat",
          details: {
            domain_url: "",
          },
        },
      },
    ],
  },
  {
    title: "Calendar",
    list: [
      {
        _type: "Chat",
        icon: svgIcons("google_calendar"),
        label: "Google Calendar",
        newObject: { channel: "google_calendar", details: { calendar_id: "" } },
      },
      {
        image: svgIcons("outlook"),
        label: "Outlook",
      },
    ],
  },
  {
    title: "RPA",
    list: [
      {
        _type: "RPA",
        icon: svgIcons("uipath"),
        label: "UI Path",
        newObject: {
          type: "uipath",
          details: {
            client_id: "",
            user_id: "",
            process_name: "",
            robot_name: "",
            service_name: "",
            account_name: "",
          },
        },
      },
      {
        image: <img src={Blue_Prism} alt="Bp" width={"45"} height={"10"} />,
        label: "Blue Prism",
      },
    ],
  },
  {
    title: "Banking",
    list: [
      {
        _type: "Banking",
        icon: svgIcons("jack_henry"),
        label: "Jack Henry (Episys)",
        newObject: {
          channel: "jack_henry",
          details: {
            client_id: "78e6da69-27a4-4939-b218-14dd66e0cdd5",
            client_secret: "1d4c29b0-7a26-4b62-8d9a-8a43213e0813",
            domain_url: "https://digital.garden-fi.com",
          },
        },
      },
    ],
  },
];

const properName = {
  salesforce_Ticket: "Salesforce_Ticketing",
  servicenow_Ticket: "servicenow_ticketing",
  salesforce_CRM: "Salesforce_CRM",
  servicenow_CRM: "servicenow_crm",
  twilio_whatsapp: "Twilio Whatsapp",
  gnani_voice: "Gnani Voice",
  twilio_voice: "Twilio Voice",
  whatsapp_gupshup: "Gupshup_Whatsapp",
  twilio_sms: "Twilio SMS",
  telegram: "Telegram",
  slack: "Slack",
  microsoft_botframework: "Microsoft Botframework",
  zendesk_sell: "Zendesk Sell",
  freshworks: "Freshworks",
  zoho: "Zoho",
  freshservice: "Freshservice",
  zoho_desk: "Zoho Desk",
  zen_desk: "Zendesk",
  freshchat: "FreshChat",
  google_calendar: "Google Calendar",
  uipath: "UI Path",
  salesforce_chat: "Salesforce_Chat",
  servicenow_chat: "servicenow_chat",
  twilio_messaging: "Twilio Messaging",
  Genesys: "Genesys",
  Cisco: "Cisco",
  Avaya: "Avaya",
};

class IntegrationsComp extends Component {
  static contextType = BotContext;
  constructor(props) {
    super(props);
    this.state = {
      integrations: [],
      integrationGroupedNames: [],
      isToolTip: false,
      number: "",
      selectedIntegration: undefined,
      selectedIntegrationsList: {},
      selectedIntegrationConfig: {},
    };
  }

  integrateToken(searchParam) {
    if (localStorage.getItem(btoa("temp_salesforce_config"))) {
      this.integrateSalesforce(searchParam);
    }
    if (localStorage.getItem(btoa("temp_servicenow_config"))) {
      this.integrateServiceNow(searchParam);
    }
    if (localStorage.getItem(btoa("temp_zendesk_config"))) {
      this.integrateZenDesk(searchParam);
    }
  }

  integrateSalesforce(searchParam) {
    let { integrations } = this.state;
    let unsavedConfig = localStorage.getItem(btoa("temp_salesforce_config"));
    if (searchParam.includes("?code=") && unsavedConfig) {
      unsavedConfig = JSON.parse(unsavedConfig);
      unsavedConfig.code = unsavedConfig.newObject.code = searchParam.replace(
        "?code=",
        ""
      );
      integrations.push(unsavedConfig);
      this.setState({ integrations, selectedIntegration: unsavedConfig }, () =>
        this.setNavBarButtons()
      );
    }
    localStorage.removeItem(btoa("temp_salesforce_config"));
  }

  integrateServiceNow(searchParam) {
    let { integrations } = this.state;
    let unsavedConfig = localStorage.getItem(btoa("temp_servicenow_config"));
    if (searchParam.includes("?code=") && unsavedConfig) {
      unsavedConfig = JSON.parse(unsavedConfig);
      searchParam = searchParam.split("&")[0];
      unsavedConfig.code = unsavedConfig.newObject.code = searchParam.replace(
        "?code=",
        ""
      );
      integrations.push(unsavedConfig);
      this.setState({ integrations, selectedIntegration: unsavedConfig }, () =>
        this.setNavBarButtons()
      );
    }
    localStorage.removeItem(btoa("temp_servicenow_config"));
  }

  integrateZenDesk(searchParam) {
    let { integrations } = this.state;
    let unsavedConfig = localStorage.getItem(btoa("temp_zendesk_config"));
    if (searchParam.includes("?code=") && unsavedConfig) {
      unsavedConfig = JSON.parse(unsavedConfig);
      searchParam = searchParam.split("&")[0];
      unsavedConfig.code = unsavedConfig.newObject.code = searchParam.replace(
        "?code=",
        ""
      );
      integrations.push(unsavedConfig);
      this.setState({ integrations, selectedIntegration: unsavedConfig }, () =>
        this.setNavBarButtons()
      );
    }
    localStorage.removeItem(btoa("temp_zendesk_config"));
  }

  integrationsConfigurations() {
    let filteredIntegrations = _integrations;
    let isVoiceBot = botService.getSelectedBot().voice_bot;
    let isChatBot = botService.getSelectedBot().chat_bot;
    let isBotRegionIndia =
      botService.getSelectedBot().region === "Asia" &&
      botService.getSelectedBot().timezone === "Asia/Kolkata - (UTC +05:30)";

    filteredIntegrations.forEach((integration, index) => {
      if (isVoiceBot && integration.title === "Voice") {
        filteredIntegrations[index].list = filteredIntegrations[
          index
        ].list.filter(
          (config) =>
            isBotRegionIndia ||
            // && config.label === "Gnani Voice"
            (!isBotRegionIndia && config.label !== "Gnani Voice")
        );
      }

      if (!isChatBot && integration.title === "Chat") {
        filteredIntegrations = filteredIntegrations.splice(index, 1);
      }

      if (
        !isChatBot &&
        integration.title === "Agent Transfer" &&
        filteredIntegrations[index]
      ) {
        filteredIntegrations[index].list = filteredIntegrations[
          index
        ].list.filter((int) => int.label !== "Freshchat");
      }

      if (!isVoiceBot && integration.title === "Voice") {
        filteredIntegrations = filteredIntegrations.splice(index, 1);
      }
    });

    return filteredIntegrations;
  }

  setNavBarButtons() {
    this.context.setNavBarButtons(
      <div className="row">
        <div className="col-auto">
          <Button
            id="saveButton"
            toolTipText={botService.getSelectedBotSavedDate()}
            isDisabled={
              !this.state.selectedIntegration ||
              this.props.permissions.integrations.modify !== "enabled"
            }
            className="btn intents-btn intent-font-save rounded-2"
            onClick={(e) => {
              e.preventDefault();
              this.setState({ saveStatus: true });
              this.onSave();
            }}
          >
            {this.state.saveStatus && svgIcons("spinner", "", "me-2")}
            Save
          </Button>
        </div>
      </div>
    );
  }

  onSave() {
    let { selectedIntegration } = this.state;

    if (!selectedIntegration) return;

    let { _type } = selectedIntegration;
    let url = "integrate_channel";
    let reqObject = {};
    switch (_type) {
      case "Chat":
        if (
          selectedIntegration.newObject &&
          selectedIntegration.newObject.details
        ) {
          reqObject["channel"] = selectedIntegration.newObject["channel"];
          reqObject.details = {};
          Object.keys(selectedIntegration.newObject.details).forEach((key) => {
            reqObject.details[key] = selectedIntegration[key];
          });
        } else {
          reqObject["channel"] = selectedIntegration.label;
          delete selectedIntegration.label;
          delete selectedIntegration._type;
          reqObject.details = { ...selectedIntegration };
        }
        break;
      case "Voice":
        if (
          selectedIntegration.newObject &&
          selectedIntegration.newObject.details
        ) {
          reqObject["channel"] = selectedIntegration.newObject["channel"];
          reqObject.details = {};
          Object.keys(selectedIntegration.newObject.details).forEach((key) => {
            reqObject.details[key] = selectedIntegration[key];
          });
        } else {
          reqObject["channel"] = selectedIntegration.label;
          delete selectedIntegration.label;
          delete selectedIntegration._type;
          reqObject.details = { ...selectedIntegration };
        }
        break;
      case "CRM":
        url = "getToken";
        if (selectedIntegration.newObject) {
          Object.keys(selectedIntegration.newObject).forEach((key) => {
            reqObject[key] = selectedIntegration[key];
          });
        } else {
          delete selectedIntegration.label;
          delete selectedIntegration._type;
          reqObject = { ...selectedIntegration };
        }
        break;
      case "Ticket":
        url = "addTicketingTool";
        if (selectedIntegration.newObject) {
          Object.keys(selectedIntegration.newObject).forEach((key) => {
            reqObject[key] = selectedIntegration[key];
          });
        } else {
          delete selectedIntegration.label;
          delete selectedIntegration._type;
          reqObject = { ...selectedIntegration };
        }
        break;
      case "RPA":
        url = "add_rpa_integration";
        if (selectedIntegration.newObject) {
          Object.keys(selectedIntegration.newObject.details).forEach((key) => {
            reqObject[key] = selectedIntegration[key] || "";
          });
          reqObject["type"] = selectedIntegration.newObject.type;
        } else {
          delete selectedIntegration.label;
          delete selectedIntegration._type;
          reqObject = { ...selectedIntegration };
        }
        break;
      default:
        break;
    }
    integrationServices
      .createUpdateIntegration(url, reqObject)
      .then((response) => {
        // console.log(response);
        if (response.status === "success") {
          this.pageRefresh();
          this.props.setNotifications({
            color: "success",
            message: response.message,
          });
        } else {
          this.pageRefresh();
          this.props.setNotifications({
            color: "danger",
            message: response.message,
          });
        }
      })
      .catch((error) =>
        this.props.setNotifications({ color: "danger", message: error.message })
      );
  }

  componentWillUnmount() {
    this.context.setNavBarButtons("");
  }

  getChannelConfig(channel) {
    integrationServices
      .getChannelConfig(channel)
      .then((res) => {
        if (res.status === "success") {
          this.setState({
            selectedIntegrationConfig: res.data,
          });
        } else {
          this.setState({ selectedIntegrationConfig: {} });
        }
      })
      .catch((e) =>
        this.props.setNotifications({ message: e.message, color: "danger" })
      );
  }

  pageRefresh() {
    this.setState(
      {
        selectedIntegration: undefined,
        addNewModalOpen: false,
        saveStatus: false,
      },
      this.componentDidMount()
    );
  }

  getSideBarList() {
    const finalArray = this.getProperDS();

    const list = finalArray
      .map((int) => {
        const data = int.data.map((d) => {
          const { label } = d;
          let name;

          if (label) {
            if (label === "microsoft_botframework") {
              name = "Microsoft Teams";
            } else {
              name = label[0].toUpperCase() + label.substr(1).replace("_", " ");
            }
          } else name = "";

          return {
            label: name,
            description: "", //"Description Here.",
            icon: svgIcons(label),
            value: label,
          };
        });

        return { type: int.type, data };
      })
      .filter((d) => d.data.length);

    return list;
  }

  getProperDS = () => {
    const { integrationGroupedNames, integrations } = this.state;

    const groupedInts = integrations.reduce((int, item) => {
      let agentTransferIntegrations = _integrations.find(
        (integration) => integration.title === "Agent Transfer"
      );
      let calenderIntegrations = _integrations.find(
        (integration) => integration.title === "Calendar"
      );
      let RPAIntegrations = _integrations.find(
        (integration) => integration.title === "RPA"
      );
      let bankingIntegrations = _integrations.find(
        (integration) => integration.title === "Banking"
      );
      agentTransferIntegrations =
        agentTransferIntegrations?.list?.map(
          (integration) => integration.newObject?.channel
        ) || [];
      calenderIntegrations =
        calenderIntegrations?.list?.map(
          (integration) => integration.newObject?.channel
        ) || [];
      RPAIntegrations =
        RPAIntegrations?.list?.map(
          (integration) => integration.newObject?.channel
        ) || [];
      bankingIntegrations =
        bankingIntegrations?.list?.map(
          (integration) => integration.newObject?.channel
        ) || [];
      let otherType =
        item._type === "Chat" && agentTransferIntegrations.includes(item.label)
          ? "Agent Transfer"
          : item._type;
      otherType =
        item._type === "Chat" && calenderIntegrations.includes(item.label)
          ? "Calendar"
          : otherType;
      otherType =
        item._type === "RPA" && RPAIntegrations.includes(item.label)
          ? "RPA"
          : otherType;
      otherType =
        item._type === "Banking" && bankingIntegrations.includes(item.label)
          ? "Banking"
          : otherType;

      const type = int[otherType] || [];
      type.push(item);
      int[otherType] = type;
      return int;
    }, {});

    const finalArray = [
      { type: "CRM", data: [] },
      { type: "Chat", data: [] },
      { type: "Voice", data: [] },
      { type: "Ticket", data: [] },
      { type: "Agent Transfer", data: [] },
      { type: "Calendar", data: [] },
      { type: "RPA", data: [] },
      { type: "Banking", data: [] },
    ];

    const integrationKeys = Object.keys(groupedInts) || [];

    if (integrationKeys.includes("CRM")) {
      finalArray[0].data = [
        ...groupedInts.CRM,
        ...integrationGroupedNames[0].data,
      ].filter((v, i, s) => i === s.findIndex((int) => int.label === v.label));
    }

    if (integrationKeys.includes("Chat")) {
      finalArray[1].data = [
        ...groupedInts.Chat,
        // ...integrationGroupedNames[1].data,
      ].filter((v, i, s) => i === s.findIndex((int) => int.label === v.label));
    }

    if (integrationKeys.includes("Voice")) {
      finalArray[2].data = [
        ...groupedInts.Voice,
        ...integrationGroupedNames[2].data,
      ].filter((v, i, s) => i === s.findIndex((int) => int.label === v.label));
    }

    if (integrationKeys.includes("Ticket")) {
      finalArray[3].data = [
        ...groupedInts.Ticket,
        ...integrationGroupedNames[3].data,
      ].filter((v, i, s) => i === s.findIndex((int) => int.label === v.label));
    }

    if (integrationKeys.includes("Agent Transfer")) {
      finalArray[4].data = [
        ...groupedInts["Agent Transfer"],
        ...integrationGroupedNames[4].data,
      ].filter((v, i, s) => i === s.findIndex((int) => int.label === v.label));
    }

    if (integrationKeys.includes("Calendar")) {
      finalArray[5].data = [
        ...groupedInts.Calendar,
        ...integrationGroupedNames[5].data,
      ].filter((v, i, s) => i === s.findIndex((int) => int.label === v.label));
    }

    if (integrationKeys.includes("RPA")) {
      finalArray[6].data = [
        ...groupedInts.RPA,
        ...integrationGroupedNames[6].data,
      ].filter((v, i, s) => i === s.findIndex((int) => int.label === v.label));
    }

    if (integrationKeys.includes("Banking")) {
      finalArray[7].data = [
        ...groupedInts.Banking,
        ...integrationGroupedNames[7].data,
      ].filter((v, i, s) => i === s.findIndex((int) => int.label === v.label));
    }

    return finalArray;
  };

  componentDidMount() {
    this.setNavBarButtons();
    integrationServices.getBotIntegrations().then((response) => {
      let crmIntegrations =
        response[0].status === "success"
          ? response[0].data.map((crm) => {
              return {
                _type: "CRM",
                label: crm,
                name: this.getProperName(crm),
                ...response[0].crm_details[crm],
              };
            })
          : [];

      let RPAIntegrations =
        response[3].status === "success"
          ? response[3].data.map((rpa) => {
              return {
                _type: "RPA",
                label: rpa,
                name: this.getProperName(rpa),
                ...response[3].rpa_details[rpa],
              };
            })
          : [];

      const voices = ["gnani_voice", "twilio_voice"];
      const agentTransfers =
        _integrations.find(
          (integration) => integration.title === "Agent Transfer"
        )?.list || [];
      const calendars =
        _integrations.find((integration) => integration.title === "Calendar")
          ?.list || [];
      const chatIntegrations = [];
      const voiceIntegrations = [];
      const agentTransferIntegrations = [];
      const calendarIntegrations = [];
      const BankingIntegrations =
        botService.getSelectedBotId() ===
        "a92c1ee80c9014e428cc284adf39a2882-kubernetes-idev"
          ? [
              {
                _type: "Banking",
                label: "jack_henry",
                name: "Jack Henry",
                ..._integrations[7].list[0].newObject.details,
              },
            ]
          : [];
      if (response[1].status === "success") {
        Object.keys(response[1].data).forEach((platform) => {
          if (voices.includes(platform)) {
            voiceIntegrations.push({
              _type: "Voice",
              label: platform,
              name: this.getProperName(platform),
              type: platform,
              ...response[1].data[platform],
            });
          } else if (agentTransfers.includes(platform)) {
            agentTransferIntegrations.push({
              _type: "Agent Transfer",
              label: platform,
              name: this.getProperName(platform),
              type: platform,
              ...response[1].data[platform],
            });
          } else if (calendars.includes(platform)) {
            calendarIntegrations.push({
              _type: "Calendar",
              label: platform,
              name: this.getProperName(platform),
              type: platform,
              ...response[1].data[platform],
            });
          } else
            chatIntegrations.push({
              _type: "Chat",
              label: platform,
              name: this.getProperName(platform),
              type: platform,
              ...response[1].data[platform],
            });
        });
      }

      let ticketingIntegrations =
        response[2].status === "success"
          ? response[2].data.map((ticket) => {
              return {
                _type: "Ticket",
                label: ticket,
                name: this.getProperName(ticket),
                ...response[2].ticketing_tool_details[ticket],
              };
            })
          : [];

      const integrationGroupedNames = [
        { type: "CRM", data: crmIntegrations },
        { type: "Chat", data: chatIntegrations },
        { type: "Voice", data: voiceIntegrations },
        { type: "Ticketing", data: ticketingIntegrations },
        { type: "Agent Transfer", data: agentTransferIntegrations },
        { type: "Calendar", data: calendarIntegrations },
        { type: "RPA", data: RPAIntegrations },
        { type: "Banking", data: BankingIntegrations },
      ];

      this.setState(
        {
          integrations: crmIntegrations
            .concat(chatIntegrations)
            .concat(voiceIntegrations)
            .concat(ticketingIntegrations)
            .concat(agentTransferIntegrations)
            .concat(calendarIntegrations)
            .concat(RPAIntegrations)
            .concat(BankingIntegrations),
          integrationGroupedNames,
        },
        () => {
          if (window.location.search !== "")
            this.integrateToken(window.location.search);
        }
      );
    });
  }

  componentDidUpdate(prevProps, prevState) {
    prevState.saveStatus !== this.state.saveStatus && this.setNavBarButtons();
  }

  getProperName = (name) => {
    let typeName;

    if (name === "microsoft_botframework") typeName = "Microsoft Teams";
    else {
      typeName = name
        .split("_")
        .map((n) => n[0].toUpperCase() + n.substring(1))
        .join(" ");
    }

    return typeName;
  };

  handleCategorySelection = (item) => {
    const { integrations } = this.state;

    let index = integrations.findIndex((int) => {
      return (
        int?.label?.replace("-", " ").toUpperCase() === item.label.toUpperCase()
      );
    });

    if (index > -1) {
      this.setState(
        {
          selectedIntegration: integrations[index],
          selectedIntegrationsList: this.integrationsConfigurations()[0],
        },
        () => {
          this.context.setNavBarButtons("");
          this.setNavBarButtons();
        }
      );
    } else {
      if (
        [
          "Avaya",
          "Genesys",
          "Cisco",
          "Oracle",
          "Microsoft Dynamics",
          "Outlook",
          "Blue Prism",
          "Facebook Messenger",
          "Jira",
        ].includes(item.label)
      ) {
        this.props.setNotifications({
          color: "black",
          message: "Please contact sales@gnani.ai for this integration",
        });
      } else {
        let obj = {
          _type: item._type,
          label:
            item._type === "Chat" ||
            item._type === "Voice" ||
            item._type === "Banking"
              ? item.newObject.channel
              : item.newObject.type,
          ...item.newObject,
          newObject: item.newObject,
        };
        delete obj.channel;
        integrations.push(obj);

        this.setState({
          integrations,
          selectedIntegrationsList: this.integrationsConfigurations()[0],
        });
      }
    }
  };

  render() {
    const {
      addNewModalOpen,
      selectedIntegration,
      selectedIntegrationsList,
      selectedIntegrationConfig,
    } = this.state;

    return (
      <main className="container-fluid h-100">
        <div className="row h-100">
          <BotLayoutLeftPanel
            integrationDropDownItems={this.integrationsConfigurations}
            isIntegrations={true}
            selectedIntegration={selectedIntegration}
            title="Integrations"
            integrationsAddClick={(item) => {
              if (this.props.permissions.integrations.add.new !== "hide") {
                this.handleCategorySelection(item);
                this.setState({
                  selectedIntegrationsList:
                    this.integrationsConfigurations()[0],
                });
              }
            }}
            sideBarList={this.getSideBarList()}
            onMenuSelect={(selection, index) => {
              if (this.props.permissions.integrations.content === "enabled") {
                let selectedIntegration;

                const localIntegrationGroupedNames = this.getProperDS().filter(
                  (int) => int.data.length
                );

                localIntegrationGroupedNames[index].data.forEach((int) => {
                  if (int.label === selection.value) {
                    selectedIntegration = int;
                  }
                });

                this.setState(
                  {
                    selectedIntegration,
                    selectedIntegrationConfig: [],
                  },
                  () => {
                    this.context.setNavBarButtons("");
                    this.setNavBarButtons();
                    if (
                      ["salesforce", "servicenow"].includes(
                        selectedIntegration.type
                      )
                    ) {
                      this.getChannelConfig(
                        properName[
                          `${selectedIntegration.type}_${selectedIntegration._type}`
                        ]
                      );
                    } else {
                      this.getChannelConfig(properName[selection.value]);
                    }
                  }
                );
              } else {
                this.setState({ selectedIntegration: true });
              }
            }}
            addBtnHide={this.props.permissions.integrations.add}
          />

          <BotLayoutContent isSettingsPage={true}>
            <div
              className="col bg-light mt-1"
              style={{ width: "calc(100% - 16rem)" }}
            >
              <ShowInput
                isFaq={true}
                isSelected={selectedIntegration}
                isShowContent={
                  this.props.permissions.integrations.content === "enabled"
                }
              />
              {selectedIntegration && (
                <IntegrationsDetails
                  details={selectedIntegration}
                  onRefresh={() => {
                    this.pageRefresh();
                  }}
                  onUpdateDetails={(details) => {
                    this.setState({ selectedIntegration: details });
                  }}
                  number={this.state.number}
                  setNumber={(e) => this.setState({ number: e })}
                  permissions={this.props.permissions.integrations}
                  channelConfig={selectedIntegrationConfig}
                />
              )}
            </div>
          </BotLayoutContent>
        </div>

        {/* Add New Integrations Modal */}
        <ModalComponent
          title="Integrations"
          addNewModalOpen={addNewModalOpen}
          toggle={() => {
            this.setState({
              addNewModalOpen: !addNewModalOpen,
              selectedIntegrationsList: this.integrationsConfigurations()[0],
            });
          }}
        >
          <div className="row m-0" style={{ height: "20rem" }}>
            <div
              className="col-5 border-secondary p-0"
              style={{ borderRight: "1px solid" }}
            >
              {this.integrationsConfigurations().map((integration, index) => (
                <button
                  key={index}
                  onClick={() => {
                    this.setState({ selectedIntegrationsList: integration });
                  }}
                  className={`btn hover-text-color rounded-0 mb-2 w-100 text-start${
                    selectedIntegrationsList?.title === integration.title
                      ? " active"
                      : " btn-transparent"
                  }`}
                >
                  {integration.title}
                </button>
              ))}
            </div>
            <div className="col">
              {selectedIntegrationsList?.list?.length > 0 &&
                selectedIntegrationsList.list.map((item, index) => (
                  <button
                    className="btn hover-text-color rounded-0 mb-2 w-100 text-start"
                    disabled={item.disabled}
                    key={index}
                    onClick={() => this.handleCategorySelection(item)}
                  >
                    {item.icon}
                    <div className="d-inline mx-3">
                      {item.label}
                      {item.disabled ? `( ${item.disabled})` : ""}
                    </div>
                  </button>
                ))}
            </div>
          </div>
        </ModalComponent>
      </main>
    );
  }
}

function IntegrationsDetails({
  details,
  onRefresh,
  onUpdateDetails,
  number,
  setNumber,
  permissions,
  channelConfig,
}) {
  const { setNotifications } = useContext(NotificationContext);
  return details ? (
    <div className="px-3 w-75">
      {/* {JSON.stringify(details)} */}
      {details?.label && (
        <Row className="ms-0 mb-4 px-2">
          <Col className="ps-0">
            <h5 className="mt-4 mt-225">
              <span className="me-3">{svgIcons(details.label)}</span>
              <span className="integration-main-title">
                {details?.label[0].toUpperCase() +
                  details?.label.substr(1).replace("-", " ").replace("_", " ")}
              </span>
            </h5>
            <p className="integration-subtitle">{details._type} Integration</p>
          </Col>
          <Col className="col-auto pe-0 pt-065rem">
            <DropDown
              dropdownList={[
                {
                  label: "Delete",
                  icon: svgIcons("delete"),
                  isUsed: permissions.delete === "disabled",
                },
              ]}
              btnClassName=""
              actionButton={() => (
                <div className="img-fluid cursor-pointer">
                  {svgIcons("verticalOptions", "black")}
                </div>
              )}
              onActionItemClick={(menu) => {
                if (
                  menu.label === "Delete" &&
                  ["Chat", "Voice"].includes(details._type)
                ) {
                  integrationServices
                    .deleteChannelIntegration(details.label)
                    .then((response) => {
                      if (response.status === "success") {
                        setNotifications({
                          color: "success",
                          message: response.message,
                        });
                        // onRefresh();
                      }
                    })
                    .finally(() => onRefresh());
                } else if (
                  menu.label === "Delete" &&
                  details._type === "Ticket"
                ) {
                  integrationServices
                    .deleteTicketingIntegration(details.label)
                    .then((response) => {
                      if (response.status === "success") {
                        setNotifications({
                          color: "success",
                          message: response.message,
                        });
                        // onRefresh();
                      }
                    })
                    .finally(() => onRefresh());
                } else if (menu.label === "Delete" && details._type === "CRM") {
                  integrationServices
                    .deleteCRMIntegration(details.label)
                    .then((response) => {
                      if (response.status === "success") {
                        setNotifications({
                          color: "success",
                          message: response.message,
                        });
                        // onRefresh();
                      }
                    })
                    .finally(() => onRefresh());
                } else if (menu.label === "Delete" && details._type === "RPA") {
                  integrationServices
                    .deleteRPAIntegration(details.label)
                    .then((response) => {
                      if (response.status === "success") {
                        setNotifications({
                          color: "success",
                          message: response.message,
                        });
                        // onRefresh();
                      }
                    })
                    .finally(() => onRefresh());
                }
              }}
              itemClassName=""
              isContent={true}
            />
          </Col>
        </Row>
      )}
      <form className="mx-1">
        {/* RPA Forms */}
        {details._type === "RPA" && (
          <RPAForm
            rpaDetails={details}
            onChange={(details) => {
              onUpdateDetails(details);
            }}
            permissions={permissions}
            channelConfig={channelConfig}
          />
        )}

        {/* CRM Forms */}
        {details._type === "CRM" && (
          <CRMForm
            crmDetails={details}
            onChange={(details) => {
              onUpdateDetails(details);
            }}
            permissions={permissions}
            channelConfig={channelConfig}
          />
        )}

        {/* Ticketing Forms */}
        {details._type === "Ticket" && (
          <TicketingForm
            ticketingDetails={details}
            onChange={(details) => {
              onUpdateDetails(details);
            }}
            permissions={permissions}
            channelConfig={channelConfig}
          />
        )}

        {/* Chat Platform Forms */}
        {details._type === "Chat" && (
          <PlatformForm
            platformDetails={details}
            onChange={(details) => {
              onUpdateDetails(details);
            }}
            number={number}
            setNumber={setNumber}
            permissions={permissions}
            channelConfig={channelConfig}
          />
        )}
        {details._type === "Voice" && (
          <PlatformForm
            platformDetails={details}
            onChange={(details) => {
              onUpdateDetails(details);
            }}
            number={number}
            setNumber={setNumber}
            permissions={permissions}
            channelConfig={channelConfig}
          />
        )}

        {/* Banking form */}
        {details._type === "Banking" && (
          <BankingForm
            platformDetails={details}
            onChange={(details) => {
              onUpdateDetails(details);
            }}
            permissions={permissions}
          />
        )}
      </form>
    </div>
  ) : (
    ""
  );
}

const FormGroup = ({
  label,
  inputPlaceholder,
  inputValue,
  inputOnChange,
  descriptionText,
  descriptionLink,
  onKeyPress,
  actionButton,
  readOnly,
}) => (
  <div className="form-group">
    <Row className="m-0">
      <Col className="align-self-center">
        <label className="integration-inp-label">{label}</label>
      </Col>
      <Col
        className={`${
          label === "Agent Transfer Phone Number" ? "col-8" : "col-9"
        }`}
      >
        <div className="row m-0">
          <div className="col p-0">
            <Input
              className="integration-input rounded px-3 w-100"
              placeholder={inputPlaceholder}
              value={inputValue}
              onChange={inputOnChange}
              onKeyPress={onKeyPress}
              readOnly={readOnly}
            />
          </div>
          {actionButton && <div className="col-auto pe-0">{actionButton}</div>}
        </div>
      </Col>
      <Col className="offset-3 col-9">
        <p>
          <small style={{ opacity: ".7" }} className="form-text text-mute">
            <p className="integration-inp-subtitle mb-0 ms-1 mt-2">
              {descriptionText}
              <span className="d-inline ms-2">{descriptionLink}</span>
            </p>
          </small>
        </p>
      </Col>
    </Row>
  </div>
);

function RPAForm({ rpaDetails, onChange, permissions, channelConfig }) {
  const rpaConfigs = [
    {
      name: "uipath",
      title: "UI Path",
      inputs: [
        { label: "Client ID", key: "client_id" },
        { label: "User Key", key: "user_id" },
        { label: "Process Name", key: "process_name" },
        { label: "Robot Name", key: "robot_name" },
        { label: "Service Name", key: "service_name" },
        { label: "Account Name", key: "account_name" },
      ],
    },
  ];

  let selectedRPA = rpaConfigs.find((rpa) => rpa.name === rpaDetails.label);

  let selectedFields = Object.keys(channelConfig).filter(
    (f) => f !== "callback_url"
  );

  return (
    <>
      {selectedRPA?.inputs?.length > 0 &&
        selectedRPA.inputs.map((rpa, index) => (
          <FormGroup
            key={index + selectedRPA.name}
            label={rpa.label}
            inputValue={rpaDetails[rpa.key]}
            inputPlaceholder={rpa.placeholder}
            inputOnChange={({ target }) => {
              rpaDetails[rpa.key] = target.value;
              onChange(rpaDetails);
            }}
            onKeyPress={(e) => {
              if (e.code === "Enter") {
                e.preventDefault();
                onChange(rpaDetails);
              }
            }}
            descriptionText={rpa.descriptionText}
            readOnly={permissions.modify !== "enabled"}
          />
        ))}
    </>
  );
}

function CRMForm({ crmDetails, onChange, permissions, channelConfig }) {
  const { setNotifications } = useContext(NotificationContext);
  const crmConfigs = [
    {
      name: "zoho",
      title: "Zoho",
      // inputs: [
      //   {
      //     label: "Client ID",
      //     key: "client_id",
      //     placeholder: "Enter your client ID...",
      //     descriptionText: "you can get the client ID from here",
      //     descriptionLink: "https://api-console.zoho.in/client",
      //   },
      //   {
      //     label: "Client Secret",
      //     key: "client_secret",
      //     placeholder: "Enter your client secret...",
      //     descriptionText: "you can get the client secret from here",
      //     descriptionLink: "https://api-console.zoho.in/client",
      //   },
      //   {
      //     label: "Code",
      //     key: "code",
      //     placeholder: "Enter your code...",
      //     descriptionText: "you can get the code from here",
      //     descriptionLink: "https://api-console.zoho.in/client",
      //   },
      // ],
      inputs: {
        client_id: { client_id: "Client ID", type: "text" },
        client_secret: { client_secret: "Client Secret", type: "text" },
        code: { code: "Code", type: "text" },
      },
    },
    {
      name: "freshworks",
      title: "Freshworks",
      // inputs: [
      //   {
      //     label: "API Key",
      //     key: "api_key",
      //     placeholder: "Enter your API Key...",
      //     descriptionText: "your Freshworks API key",
      //   },
      //   {
      //     label: "Domain url",
      //     key: "domain_url",
      //     placeholder: "Enter your Domain url...",
      //     descriptionText: "your Freshworks domain url",
      //   },
      // ],
      inputs: {
        api_key: { api_key: "API Key", type: "text" },
        domain_url: { domain_url: "Domain url", type: "text" },
      },
    },
    {
      name: "zendesk_sell",
      title: "Zendesk",
      // inputs: [
      //   {
      //     label: "Client ID",
      //     key: "client_id",
      //     placeholder: "Enter your client ID...",
      //     // descriptionText: "Enter your client ID here",
      //   },
      // ],
      inputs: {
        client_id: { client_id: "Client ID", type: "text" },
      },
    },
    {
      name: "salesforce",
      title: "Salesforce",
      inputs: {
        client_id: {
          client_id: "Client ID",
          placeholder: "Enter your client ID...",
          descriptionText: "you can get the client ID from here",
          descriptionLink: "https://salesforce.com",
        },
        client_secret: {
          client_secret: "Client Secret",
          placeholder: "Enter your client secret...",
          descriptionText: "you can get the client secret from here",
          descriptionLink: "https://salesforce.com",
        },
      },
    },
    {
      name: "servicenow",
      title: "Servicenow",
      // inputs: [
      //   {
      //     label: "Client ID",
      //     key: "client_id",
      //     placeholder: "Enter your client ID...",
      //     descriptionText: "you can get the client ID from Servicenow",
      //   },
      //   {
      //     label: "Client Secret",
      //     key: "client_secret",
      //     placeholder: "Enter your client secret...",
      //     descriptionText: "you can get the client secret from Servicenow",
      //   },
      // ],
      inputs: {
        client_id: { client_id: "Client ID", type: "text" },
        client_secret: { client_secret: "Client Secret", type: "text" },
      },
    },
  ];

  const fetchedKeys = !!Object.keys(channelConfig).length;

  let selectedCRMConfig = crmConfigs.find(
    (config) => config.name === crmDetails.label
  );

  let selectedFields = fetchedKeys
    ? Object.keys(channelConfig).filter((f) => f !== "instance_url")
    : Object.keys(selectedCRMConfig.inputs);

  return (
    <>
      {!!selectedFields.length &&
        selectedFields.map((confg, index) => (
          <FormGroup
            key={index}
            label={
              confg === "callback_url"
                ? "Callback URL"
                : fetchedKeys
                ? channelConfig[confg][confg]
                : selectedCRMConfig.inputs[confg][confg]
            }
            inputValue={
              confg === "callback_url" && fetchedKeys
                ? `${baseUrl}${channelConfig[confg][confg]}`
                : crmDetails[confg]
            }
            inputOnChange={({ target }) => {
              crmDetails[confg] = target.value;
              onChange(crmDetails);
            }}
            readOnly={
              permissions.modify !== "enabled" || confg === "callback_url"
            }
            // descriptionText={confg.descriptionText}
            // descriptionLink={confg.descriptionLink}
            // inputPlaceholder={confg.placeholder}
          />
        ))}

      {["salesforce", "servicenow", "zendesk_sell"].includes(
        selectedCRMConfig?.name
      ) && (
        <FormGroup
          readOnly={permissions.modify !== "enabled"}
          label={`${
            selectedCRMConfig?.name === "servicenow"
              ? "Domain URL"
              : selectedCRMConfig?.name === "zendesk_sell"
              ? "Client Secret"
              : "Instance URL"
          }`}
          inputValue={
            selectedCRMConfig?.name === "servicenow"
              ? crmDetails["domain_url"]
              : selectedCRMConfig?.name === "zendesk_sell"
              ? crmDetails["client_secret"]
              : crmDetails["instance_url"]
          }
          inputPlaceholder={`${
            selectedCRMConfig?.name === "servicenow"
              ? "Enter your domain URL here"
              : selectedCRMConfig?.name === "zendesk_sell"
              ? "Enter your client secret..."
              : "Enter your instance URL here"
          }`}
          inputOnChange={({ target }) => {
            if (selectedCRMConfig?.name === "servicenow") {
              crmDetails["domain_url"] = target.value;
            } else if (selectedCRMConfig?.name === "zendesk_sell") {
              crmDetails["client_secret"] = target.value;
            } else {
              crmDetails["instance_url"] = target.value;
            }
            onChange(crmDetails);
          }}
          descriptionText={`you can get the ${
            selectedCRMConfig?.name === "servicenow"
              ? "domain URL from servicenow"
              : selectedCRMConfig?.name === "zendesk_sell"
              ? "client secret from zendesk"
              : "instance URL from salesforce"
          }`}
          actionButton={
            <button
              disabled={
                permissions.modify === "disabled" ||
                crmDetails["client_id"] === "" ||
                crmDetails["client_secret"] === "" ||
                (selectedCRMConfig?.name === "servicenow" &&
                  crmDetails["domain_url"] === "") ||
                (selectedCRMConfig?.name === "salesforce" &&
                  crmDetails["instance_url"] === "") ||
                crmDetails["code"] !== ""
              }
              type="button"
              className={`btn intents-btn intent-font-save rounded-2 h-100${
                crmDetails["code"] !== "" ? " btn-success" : ""
              }`}
              onClick={(e) => {
                let storageKey =
                  selectedCRMConfig?.name === "servicenow"
                    ? btoa("temp_servicenow_config")
                    : selectedCRMConfig?.name === "zendesk_sell"
                    ? btoa("temp_zendesk_config")
                    : btoa("temp_salesforce_config");
                localStorage.setItem(storageKey, JSON.stringify(crmDetails));
                try {
                  var url = "";
                  if (selectedCRMConfig?.name === "servicenow")
                    url = new URL(`${crmDetails["domain_url"]}/oauth_auth.do`);
                  else if (selectedCRMConfig?.name === "zendesk_sell")
                    url = new URL(`https://api.getbase.com/oauth2/authorize`);
                  else if (selectedCRMConfig?.name === "salesforce")
                    url = new URL(
                      `${crmDetails["instance_url"]}/services/oauth2/authorize`
                    );
                  url.searchParams.append("response_type", "code");
                  url.searchParams.append(
                    "redirect_uri",
                    `${instanceUrl}/bot/integrations`
                  );
                  url.searchParams.append("client_id", crmDetails["client_id"]);
                  if (
                    ["servicenow", "zendesk_sell"].includes(
                      selectedCRMConfig?.name
                    )
                  )
                    url.searchParams.append("state", "random");
                  const a = document.createElement("a");
                  a.href = url;
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
                } catch (error) {
                  setNotifications({
                    color: "danger",
                    message: `${
                      selectedCRMConfig?.name === "servicenow"
                        ? "Domain"
                        : "Instance"
                    } URL is not in proper format.`,
                  });
                }
              }}
            >
              {crmDetails["code"] !== "" ? (
                <>{svgIcons("check")} Authenticated</>
              ) : (
                "Authenticate"
              )}
            </button>
          }
        />
      )}
    </>
  );
}

function TicketingForm({
  ticketingDetails,
  onChange,
  permissions,
  channelConfig,
}) {
  const { setNotifications } = useContext(NotificationContext);
  const ticketingConfigs = [
    {
      name: "jira",
      title: "Jira",
      // inputs: [
      //   {
      //     label: "User Name",
      //     key: "username",
      //     placeholder: "Enter your user name...",
      //     descriptionText: "your Jira user name here",
      //   },
      //   {
      //     label: "Password",
      //     key: "passowrd",
      //     placeholder: "Enter your password...",
      //     descriptionText: "your Jira password here",
      //   },
      // ],
      inputs: {
        username: { username: "User Name" },
        passowrd: { passowrd: "Passowrd" },
      },
    },
    {
      name: "freshservice",
      title: "Freshservice",
      // inputs: [
      //   {
      //     label: "API Key",
      //     key: "api_key",
      //     placeholder: "Enter your API Key...",
      //     descriptionText: "your Freshservice API key here",
      //   },
      //   {
      //     label: "Domain URL",
      //     key: "domain_url",
      //     placeholder: "Enter your Domain URL...",
      //     descriptionText: "your Freshservice Domain URL here",
      //   },
      // ],
      inputs: {
        api_key: { api_key: "API Key" },
        domain_url: { domain_url: "Domain URL" },
      },
    },
    {
      name: "zen_desk",
      title: "Zen Desk",
      // inputs: [
      //   {
      //     label: "Client ID",
      //     key: "client_id",
      //     placeholder: "Enter your client ID...",
      //     // descriptionText: "you can get the client ID from here",
      //   },
      //   {
      //     label: "Client Secret",
      //     key: "client_secret",
      //     placeholder: "Enter your client secret...",
      //   },
      // ],
      inputs: {
        client_id: { client_id: "Client ID" },
        client_secret: { client_secret: "Client Secret" },
      },
    },
    {
      name: "zoho_desk",
      title: "Zoho Desk",
      // inputs: [
      //   {
      //     label: "Client ID",
      //     key: "client_id",
      //     placeholder: "Enter your Client ID...",
      //     descriptionText: "your Zoho Desk client id here",
      //   },
      //   {
      //     label: "Client Secret",
      //     key: "client_secret",
      //     placeholder: "Enter your Client Secret...",
      //     descriptionText: "your Zoho Desk client secret here",
      //   },
      //   {
      //     label: "Code",
      //     key: "code",
      //     placeholder: "Enter your Code...",
      //     descriptionText: "your Zoho Desk code here",
      //   },
      //   {
      //     label: "Base Url",
      //     key: "base_url",
      //     placeholder: "Enter your base url...",
      //     descriptionText: "your Zoho Desk base url here",
      //   },
      // ],
      inputs: {
        base_url: { base_url: "Base Url" },
        client_id: { client_id: "Client ID" },
        code: { code: "Code" },
        client_secret: { client_secret: "Client Secret" },
      },
    },
    {
      name: "salesforce",
      title: "Salesforce",
      inputs: {
        client_id: {
          client_id: "Client ID",
          placeholder: "Enter your client ID...",
          descriptionText: "you can get the client ID from here",
          descriptionLink: "https://salesforce.com",
        },
        client_secret: {
          client_secret: "Client Secret",
          placeholder: "Enter your client secret...",
          descriptionText: "you can get the client secret from here",
          descriptionLink: "https://salesforce.com",
        },
      },
    },
    {
      name: "servicenow",
      title: "Servicenow",
      // inputs: [
      //   {
      //     label: "Client ID",
      //     key: "client_id",
      //     placeholder: "Enter your client ID...",
      //     descriptionText: "you can get the client ID from Servicenow",
      //   },
      //   {
      //     label: "Client Secret",
      //     key: "client_secret",
      //     placeholder: "Enter your client secret...",
      //     descriptionText: "you can get the client secret from Servicenow",
      //   },
      // ],
      inputs: {
        client_id: { client_id: "Client ID" },
        client_secret: { client_secret: "Client Secret" },
      },
    },
  ];

  const fetchedKeys = !!Object.keys(channelConfig).length;

  let selectedTicketingConfig = ticketingConfigs.find(
    (config) => config.name === ticketingDetails.label
  );

  let selectedFields = fetchedKeys
    ? Object.keys(channelConfig).filter((f) => f !== "instance_url")
    : Object.keys(selectedTicketingConfig.inputs);

  return (
    <>
      {!!selectedFields.length &&
        selectedFields.map((confg, index) => (
          <FormGroup
            key={index}
            label={
              confg === "callback_url"
                ? "Callback URL"
                : fetchedKeys
                ? channelConfig[confg][confg]
                : selectedTicketingConfig.inputs[confg][confg]
            }
            inputValue={
              confg === "callback_url" && fetchedKeys
                ? `${baseUrl}${channelConfig[confg][confg]}`
                : ticketingDetails[confg]
            }
            inputOnChange={({ target }) => {
              ticketingDetails[confg] = target.value;
              onChange(ticketingDetails);
            }}
            readOnly={
              permissions.modify !== "enabled" || confg === "callback_url"
            }
            // inputPlaceholder={confg.placeholder}
            // descriptionText={confg.descriptionText}
            // descriptionLink={confg.descriptionLink}
          />
        ))}

      {["salesforce", "servicenow", "zen_desk"].includes(
        selectedTicketingConfig?.name
      ) && (
        <FormGroup
          readOnly={permissions.modify !== "enabled"}
          label={`${
            selectedTicketingConfig?.name === "servicenow"
              ? "Domain URL"
              : "Instance URL"
          }`}
          inputValue={
            selectedTicketingConfig?.name === "servicenow"
              ? ticketingDetails["domain_url"]
              : selectedTicketingConfig?.name === "zen_desk"
              ? ticketingDetails["instance_url"]
              : ticketingDetails["instance_url"]
          }
          inputPlaceholder={`Enter your ${
            selectedTicketingConfig?.name === "servicenow"
              ? "domain"
              : "instance"
          } URL here`}
          inputOnChange={({ target }) => {
            if (selectedTicketingConfig?.name === "servicenow")
              ticketingDetails["domain_url"] = target.value;
            else if (selectedTicketingConfig?.name === "zen_desk")
              ticketingDetails["instance_url"] = target.value;
            else ticketingDetails["instance_url"] = target.value;

            onChange(ticketingDetails);
          }}
          descriptionText={`you can get the ${
            selectedTicketingConfig?.name === "servicenow"
              ? "domain URL from servicenow"
              : selectedTicketingConfig?.name === "zen_desk"
              ? "instance URL from zendesk"
              : "instance URL from salesforce"
          }`}
          actionButton={
            <button
              disabled={
                permissions.modify !== "enabled" ||
                ticketingDetails["client_id"] === "" ||
                ticketingDetails["client_secret"] === "" ||
                (selectedTicketingConfig?.name === "servicenow" &&
                  ticketingDetails["domain_url"] === "") ||
                (selectedTicketingConfig?.name === "salesforce" &&
                  ticketingDetails["instance_url"] === "") ||
                (selectedTicketingConfig?.name === "zen_desk" &&
                  ticketingDetails["instance_url"] === "") ||
                ticketingDetails["code"] !== ""
              }
              type="button"
              className={`btn intents-btn intent-font-save rounded-2 h-100${
                ticketingDetails["code"] !== "" ? " btn-success" : ""
              }`}
              onClick={(e) => {
                let storageKey =
                  selectedTicketingConfig?.name === "servicenow"
                    ? btoa("temp_servicenow_config")
                    : selectedTicketingConfig?.name === "zen_desk"
                    ? btoa("temp_zendesk_config")
                    : btoa("temp_salesforce_config");
                localStorage.setItem(
                  storageKey,
                  JSON.stringify(ticketingDetails)
                );
                try {
                  var url = "";
                  if (selectedTicketingConfig?.name === "servicenow")
                    url = new URL(
                      `${ticketingDetails["domain_url"]}/oauth_auth.do`
                    );
                  else if (selectedTicketingConfig?.name === "salesforce")
                    url = new URL(
                      `${ticketingDetails["instance_url"]}/services/oauth2/authorize`
                    );
                  else
                    url = new URL(
                      `${ticketingDetails["instance_url"]}/oauth/authorizations/new`
                    );
                  url.searchParams.append("response_type", "code");
                  url.searchParams.append(
                    "redirect_uri",
                    `${instanceUrl}/bot/integrations`
                  );
                  url.searchParams.append(
                    "client_id",
                    ticketingDetails["client_id"]
                  );
                  if (selectedTicketingConfig?.name === "zen_desk") {
                    url.searchParams.append("scope", "read write");
                    url.searchParams.append(
                      "subdomain",
                      ticketingDetails["instance_url"]?.slice(8).split(".")[0]
                    );
                  }
                  if (selectedTicketingConfig?.name === "servicenow")
                    url.searchParams.append("state", "random");
                  const a = document.createElement("a");
                  a.href = url;
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
                } catch (error) {
                  setNotifications({
                    color: "danger",
                    message: `${
                      selectedTicketingConfig?.name === "servicenow"
                        ? "Domain"
                        : "Instance"
                    } URL is not in proper format.`,
                  });
                }
              }}
            >
              {ticketingDetails["code"] !== "" ? (
                <>{svgIcons("check")} Authenticated</>
              ) : (
                "Authenticate"
              )}
            </button>
          }
        />
      )}
    </>
  );
}

function PlatformForm({
  platformDetails,
  onChange,
  number,
  setNumber,
  permissions,
  channelConfig,
}) {
  const [isCopied, setCopied] = useState(false);
  const platforms = [
    {
      name: "twilio_whatsapp",
      title: "Twilio Whatsapp",
      callback_url: true,
      inputs: {
        account_sid: { account_sid: "Account SID", key: "account_sid" },
        auth_token: { auth_token: "Auth Token", key: "auth_token" },
        twilio_number: { twilio_number: "Twilio Number", key: "twilio_number" },
      },
    },
    {
      name: "whatsapp_gupshup",
      title: "Gupshup Whatsapp",
      callback_url: true,
      inputs: {
        app: { app: "App", key: "app" },
        gupshup_number: {
          gupshup_number: "Gupshup Number",
          key: "gupshup_number",
        },
        api_key: { api_key: "Api Key", key: "api_key" },
      },
    },
    {
      name: "twilio_sms",
      title: "Twilio SMS",
      inputs: {
        account_sid: { account_sid: "Account SID", key: "account_sid" },
        auth_token: { auth_token: "Auth Token", key: "auth_token" },
        message_sid: {
          message_sid: "Messaging Service SID",
          key: "message_sid",
        },
      },
    },
    {
      name: "twilio_messaging",
      title: "Twilio Messaging",
      callback_url: true,
      inputs: {
        account_sid: { account_sid: "Account SID", key: "account_sid" },
        auth_token: { auth_token: "Auth Token", key: "auth_token" },
        message_sid: {
          message_sid: "Messaging Service SID",
          key: "message_sid",
        },
      },
    },
    {
      name: "telegram",
      title: "Telegram",
      callback_url: true,
      inputs: {
        access_token: { access_token: "Access Token", key: "access_token" },
        verify: { verify: "Bot Name", key: "verify" },
      },
    },
    {
      name: "facebook",
      title: "Facebook Messenger",
      inputs: {
        verify: { verify: "Verification Token", key: "verify" },
        secret: { secret: "Secret Key", key: "secret" },
        "page-access-token": {
          "page-access-token": "Page Access Token",
          key: "page-access-token",
        },
      },
    },
    {
      name: "slack",
      title: "Slack",
      callback_url: true,
      inputs: {
        slack_channel: {
          slack_channel: "Slack Channel ID",
          key: "slack_channel",
        },
        slack_token: { slack_token: "Slack Token", key: "slack_token" },
        slack_signing_secret: {
          slack_signing_secret: "Slack Signing Secret",
          key: "slack_signing_secret",
        },
      },
    },
    {
      name: "microsoft_botframework",
      title: "Microsoft Teams",
      callback_url: true,
      inputs: {
        app_id: { app_id: "App ID", key: "app_id" },
        app_password: {
          app_password: "App Password",
          key: "app_password",
        },
      },
    },
    {
      name: "webexteams",
      title: "Cisco Webex",
      inputs: {
        access_token: { access_token: "Access Token", key: "access_token" },
        room: { room: "Room ID", key: "room" },
      },
    },
    {
      name: "hangouts",
      title: "Google Hangouts",
      inputs: { project_id: { project_id: "Project ID", key: "project_id" } },
    },
    {
      name: "freshchat",
      title: "Freshchat",
      callback_url: true,
      inputs: {
        agent_id: { agent_id: "Agent ID", key: "agent_id" },
        app_id: { app_id: "App ID", key: "app_id" },
        channel_id: { channel_id: "Channel ID", key: "channel_id" },
        domain_url: { domain_url: "Domain URL", key: "domain_url" },
        token: { token: "Token", key: "token" },
      },
    },
    {
      name: "google_calendar",
      title: "Google Calendar",
      inputs: {
        calendar_id: { calendar_id: "Calendar ID", key: "calendar_id" },
      },
    },
    {
      name: "gnani_voice",
      title: "Gnani Voice",
      inputs: {
        agent_transfer_number: {
          agent_transfer_number: "Agent Transfer Phone Number",
          key: "",
        },
      },
    },
    {
      name: "twilio_voice",
      title: "Twilio Voice",
      callback_url: true,
      inputs: {
        sip_domain_friendly_name: {
          sip_domain_friendly_name: "SIP Domain Friendly Name",
        },
        twilio_auth_token: { twilio_auth_token: "Auth Token" },
        twilio_caller_id: { twilio_caller_id: "Caller ID" },
        twilio_sid: { twilio_sid: "SID", key: "" },
        agent_transfer_number: {
          agent_transfer_number: "Agent Transfer Phone Number",
          key: "",
        },
      },
    },
    {
      name: "salesforce_chat",
      title: "Salesforce Chat",
      callback_url: true,
      inputs: {
        domain_url: { domain_url: "Domain URL", key: "domain_url" },
        organization_id: {
          organization_id: "Organization ID",
          key: "organization_id",
        },
        deployment_id: { deployment_id: "Deployment ID", key: "deployment_id" },
        button_id: { button_id: "Button ID", key: "button_id" },
      },
    },
    {
      name: "servicenow_chat",
      title: "Service Now Chat",
      inputs: { domain_url: { domain_url: "Domain URL", key: "domain_url" } },
    },
    {
      name: "jack_henry",
      title: "Jack Henry",
      inputs: {
        client_id: { client_id: "Client ID", key: "client_id" },
        client_secret: { client_secret: "Client Secret", key: "client_secret" },
        domain_url: { domain_url: "Domain URL", key: "domain_url" },
      },
    },
  ];
  const fetchedKeys = !!Object.keys(channelConfig).length;
  const localPlatform = platforms.find(
    (platform) => platform.name === platformDetails.label
  );

  let selectedPlatform = fetchedKeys
    ? Object.keys(channelConfig).filter((n) => n !== "callback_url")
    : Object.keys(localPlatform.inputs);

  return (
    <>
      {channelConfig?.callback_url?.type === "display" && (
        <div className="form-group">
          <Row className="m-0">
            <Col className="align-self-center">
              <label className="integration-inp-label">Webhook URL</label>
            </Col>
            <Col className="col-9">
              <div className="integration-webhook-container">
                <input
                  className="integration-webhook-url-inp border-0"
                  readOnly
                  value={`${baseUrl}${channelConfig.callback_url.callback_url}`}
                />
                <button
                  id="integration-inp-url-copied"
                  className="btn align-baseline d-inline h-100 px-2"
                  disabled={permissions.modify !== "enabled"}
                  onClick={(e) => {
                    e.preventDefault();
                    navigator.clipboard.writeText(
                      `${baseUrl}${channelConfig.callback_url.callback_url}`
                    );
                    setCopied(true);
                    setTimeout(() => {
                      setCopied(false);
                    }, 3500);
                  }}
                >
                  {svgIcons("copy")}
                </button>
                <Tooltip isOpen={isCopied} target="integration-inp-url-copied">
                  Copied!
                </Tooltip>
              </div>
            </Col>
            <Col className="offset-3 col-9">
              <p>
                <small
                  style={{ opacity: ".7" }}
                  className="form-text text-mute"
                >
                  <p className="mb-0">
                    {}
                    <span className="d-inline ms-2">{}</span>
                  </p>
                </small>
              </p>
            </Col>
          </Row>
        </div>
      )}

      {selectedPlatform?.length > 0 &&
        selectedPlatform.map((platform, index) => (
          <>
            <FormGroup
              key={index + "selectedPlatform.name"}
              label={localPlatform.inputs[platform][platform]}
              readOnly={permissions.modify !== "enabled"}
              inputValue={
                platform === "agent_transfer_number"
                  ? number
                  : platformDetails[platform]
              }
              inputOnChange={({ target }) => {
                if (platform === "agent_transfer_number") {
                  setNumber(target.value);
                } else {
                  platformDetails[platform] = target.value;
                  onChange(platformDetails);
                }
              }}
              onKeyPress={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  if (
                    platform === "agent_transfer_number" &&
                    number.length > 0
                  ) {
                    !platformDetails.agent_transfer_number
                      ? (platformDetails.agent_transfer_number = [number])
                      : platformDetails.agent_transfer_number.push(number);
                  }
                  onChange(platformDetails);
                  setNumber("");
                }
              }}
              // descriptionText={platform.descriptionText}
              // inputPlaceholder={platform.placeholder}
            />
          </>
        ))}

      <div className="overflow-auto" style={{ height: "15rem" }}>
        {platformDetails.agent_transfer_number?.map((num, i) => (
          <div className="row m-0" key={i}>
            <div className="col offset-3">
              <div className="row bg-white m-auto my-3 p-2">
                <div className="col integration-agent-num">{num}</div>
                <div
                  className="col-auto cursor-pointer"
                  onClick={() => {
                    if (permissions.modify === "enabled") {
                      platformDetails.agent_transfer_number.splice(i, 1);
                      onChange(platformDetails);
                    }
                  }}
                >
                  <i className="fas fa-times" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

function BankingForm({ platformDetails, onChange, permissions }) {
  const platforms = [
    {
      name: "jack_henry",
      title: "Jack Henry",
      inputs: [
        { label: "Client ID", key: "client_id" },
        { label: "Client Secret", key: "client_secret" },
        { label: "Domain URL", key: "domain_url" },
      ],
    },
  ];
  let selectedPlatform = platforms.find(
    (platform) => platform.name === platformDetails.label
  );

  return (
    <>
      {selectedPlatform?.inputs?.length > 0 &&
        selectedPlatform.inputs.map((platform, index) => (
          <FormGroup
            key={index + selectedPlatform.name}
            label={platform.label}
            inputValue={platformDetails[platform.key]}
            inputPlaceholder={platform.placeholder}
            inputOnChange={({ target }) => {
              platformDetails[platform.key] = target.value;
              onChange(platformDetails);
            }}
            onKeyPress={(e) => {
              if (e.code === "Enter") {
                e.preventDefault();
                onChange(platformDetails);
              }
            }}
            descriptionText={platform.descriptionText}
            readOnly={permissions.modify !== "enabled"}
          />
        ))}
    </>
  );
}

export default function Integrations() {
  const { setNotifications } = useContext(NotificationContext);
  const { getUserDetails } = useContext(UserContext);
  return (
    <IntegrationsComp
      setNotifications={setNotifications}
      permissions={getUserDetails().feature_permission}
    />
  );
}
