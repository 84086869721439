import React, { Component, useContext } from "react";
import botService from "../../services/bot.service";
import { getToken } from "../../services/authUtil";
import baseUrl from "../../services/baseURL.service";
import { BotContext } from "../../contexts/BotContext";
import NodeTree from "../../components/NodeTree/NodeTree";
import { NotificationContext } from "../../contexts/notficationContext";
import { UserContext } from "../../contexts/userContext";
import Button from "../../components/fields/button/button.component";
import apiServices from "../../services/api.services";
import BotLayoutLeftPanel from "../../components/botLayout/botLayoutLeftPanel.component";
import DeleteConfirmation from "../../components/deleteConfirmation.component";
import DropDown from "../../components/fields/dropDown/dropDown.component";
import Input from "../../components/fields/input/input.component";
import svgIcons from "../../services/icon.service";
import ConfirmationModal from "../../components/modal/pageSwitchConfirmation";
import settingsAPIService from "../../services/api.services/settingsApi.service";

class FlowChartComp extends Component {
  static contextType = BotContext;
  constructor(props) {
    super(props);
    this.state = {
      prevIndex: 0,
      intents: [],
      utters: [],
      paths: [],
      isModal: false,
      subPathName: "Main Path",
      oldName: undefined,
      fullPathView: false,
      fullPath: false,
    };
  }

  setNavBarButtons() {
    this.context.setNavBarButtons(
      <div className="row">
        <div className="col-auto">
          <Button
            id="saveButton"
            toolTipText={botService.getSelectedBotSavedDate()}
            className="intents-btn rounded-2 intent-font-save"
            onClick={() => {
              this.context.updatePosition();
              if (Object.keys(this.context.paths).length > 0) {
                this.savePaths(this.context.paths, () =>
                  this.props.setUserFlows(this.context.paths)
                );
              }
            }}
          >
            Save
          </Button>
        </div>
        <div className="col-auto">
          <Button
            id="jsonButton"
            toolTipText={"convert bot config"}
            className="intents-btn rounded-2 intent-font-save"
            onClick={() => {
              settingsAPIService.downloadFullBackUp().then((r) => {
                let newPaths = this.context.getGraphStructure(this.state.paths);
                r["paths"] = newPaths;
                this.handleDownload(r);
              });
            }}
          >
            Config
          </Button>
        </div>
      </div>
    );
  }

  handleDownload = (data) => {
    const url = window.URL.createObjectURL(
      new Blob([JSON.stringify(data)], {
        type: "application/json",
      })
    );
    const link = document.createElement("a");

    link.href = url;
    link.setAttribute(
      "download",
      `${botService.getSelectedBot().bot_name}_config.json`
    );

    document.body.appendChild(link);

    link.click();
  };

  savePaths(paths, clearStorage) {
    if (
      !this.validateNode(this.context.paths).some(
        (ele) =>
          ele === "" ||
          ele === undefined ||
          (typeof ele === "object" && Array.isArray(ele) && ele.length < 1) ||
          (typeof ele === "object" &&
            Array.isArray(ele) &&
            ele.some((ele) => ele.length === 0)) ||
          (typeof ele === "object" &&
            !Array.isArray(ele) &&
            ele !== null &&
            ele[Object.keys(ele)[0]] === "")
      )
    ) {
      this.context.removeUnusedPaths(paths);
      const requestOptions = {
        method: "POST",
        body: JSON.stringify(paths),
        headers: {
          "Content-Type": "application/json",
          token: getToken(),
        },
      };

      let bot_id = botService.getSelectedBotId();
      apiServices
        .fetchData(`${baseUrl}/update_bot_paths/${bot_id}`, requestOptions)
        .then((response) => {
          if (response.status === "success") {
            this.props.setNotifications({
              color: "success",
              message: response.message,
            });
            clearStorage && clearStorage();
          }
        })
        .catch((error) => {
          this.props.setNotifications({
            color: "danger",
            message: error.message,
          });
        });
    } else {
      this.props.setNotifications({
        color: "danger",
        message: "select values for each node to save",
      });
    }
  }

  validateNode = (paths) => {
    let x = [];
    Object.keys(paths).forEach((pathName) => {
      paths[pathName].forEach((obj) => {
        let type = [
          "actions",
          "intent",
          "form",
          "slots",
          "type",
          "digits",
          "flow",
        ];
        if (
          !["decision", "dtmf-decision", "slot-decision"].includes(obj.who) &&
          pathName !== "main_path" &&
          obj.who !== "actions"
        ) {
          const whichType = Object.keys(obj).filter((e) => type.includes(e));
          x.push(obj[whichType[0]]);
        } else if (obj.who === "decision") {
          Object.keys(obj.checkpoints).forEach((checkpoint) => {
            if (obj.checkpoints[checkpoint].length > 1) {
              obj.checkpoints[checkpoint].forEach((int, i) => {
                x.push(int.intent);
              });
            } else {
              !!obj.checkpoints[checkpoint].length &&
                x.push(obj.checkpoints[checkpoint][0]["intent"]);
            }
          });
        } else if (obj.who === "slot-decision") {
          Object.keys(obj.checkpoints).forEach((checkpoint) => {
            x.push(obj.checkpoints[checkpoint][0]["slots"]);
          });
        }
      });
    });
    return x;
  };

  componentWillUnmount() {
    this.context.setNavBarButtons("");
  }

  componentDidMount() {
    let bot_id = botService.getSelectedBotId();
    this.context.currentPath && this.context.getSubFlow("main_path");

    if (bot_id) {
      this.props.permissions &&
        this.props.permissions.flowChart.edit !== "disabled" &&
        this.setNavBarButtons();
      this.getIntents(() => {
        this.getUtterances(() => this.creatEditTree());
        this.context.setForms();
        this.context.setSlots();
        this.context.setActions();
        this.context.setChannels();
      });
    }
  }

  setName(name) {
    let { subPathName } = this.state;
    if (name) {
      name !== "main_path"
        ? (subPathName = name.split(":")[0].substr(4))
        : (subPathName = "Main Path");
      this.setState({ subPathName, fullPath: false });
    }
  }

  handleNameChange(e) {
    let { oldName, subPathName } = this.state;

    !oldName && (oldName = subPathName);
    this.setState({ subPathName: e.target.value, oldName });

    if (e.key === "Enter") {
      let tempName = `sub-${subPathName}:${Date.now()}`;
      if (oldName && oldName !== subPathName) {
        if (
          !this.context.subPathList.find((e) =>
            e.label.includes(`sub-${subPathName}:}`)
          )
        ) {
          if (/^[A-Za-z][A-Za-z0-9_]+$/.test(subPathName))
            this.context.renameSubPath(tempName);
          else
            this.props.setNotifications({
              color: "black",
              message:
                "Path name should not contain special characters and spaces",
            });
        } else {
          this.props.setNotifications({
            color: "black",
            message: "Path already exists",
          });
        }
      }
      this.setState({ oldName: undefined });
    }
  }

  handleRenameBlur() {
    let { oldName, subPathName } = this.state;
    let tempName = `sub-${subPathName}:${Date.now()}`;
    if (oldName && oldName !== subPathName) {
      if (
        !this.context.subPathList.find((e) =>
          e.label.includes(`sub-${subPathName}:}`)
        )
      ) {
        if (/^[A-Za-z][A-Za-z0-9_]+$/.test(subPathName)) {
          this.context.renameSubPath(tempName);
          this.setState({ oldName: undefined }, () => {
            setTimeout(() => {
              oldName && oldName !== subPathName && this.creatEditTree();
            }, 100);
          });
        } else
          this.props.setNotifications({
            color: "black",
            message:
              "Path name should not contain special characters and spaces",
          });
      } else {
        this.props.setNotifications({
          color: "black",
          message: "Path already exists",
        });
      }
    }
  }

  getIntents(callBack) {
    this.context.setIntents();
    callBack();
  }

  getUtterances(callBack) {
    this.context.setUtterances();
    callBack();
  }

  creatEditTree(callback) {
    let bot_id = botService.getSelectedBotId();
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: getToken(),
      },
      mode: "cors",
    };
    apiServices
      .fetchData(`${baseUrl}/get_bot_paths/${bot_id}`, requestOptions)
      .then((response) => {
        if (response.status === "success") {
          if (response.data && Object.keys(response.data).length > 0) {
            let paths = response.data;
            this.context.setPaths(paths);
            this.setState({ paths }, () => {
              this.props.setUserFlows(response.data);
              callback && callback();
            });
          } else {
            // this.createRoot();
          }
        } else {
          console.error("Failed to get Bot config: ", response);
        }
      })
      .catch((error) => {
        console.error("Failed: ", error);
      });
  }

  getDeleteModal() {
    return (
      <DeleteConfirmation
        open={this.state.isModal}
        delete={() => {
          this.context.deleteSubPath();
          this.setName("main_path");
        }}
      />
    );
  }

  render() {
    return (
      <div className="m-0 row h-100">
        {this.context.confirmModal && (
          <ConfirmationModal
            open={true}
            confirm={() => {
              this.savePaths(this.context.paths, () =>
                this.props.setUserFlows({})
              );
              this.context.locationPush();
              this.context.setModal(false, () => console.log(""));
            }}
            cancel={() => {
              this.props.setUserFlows({});
              this.context.locationPush();
              this.context.setModal(false, () => console.log(""));
            }}
            close={() => this.context.setModal(false, () => console.log(""))}
          />
        )}
        <BotContext.Consumer>
          {(context) => (
            <>
              <div className="p-0 m-0 h-100 shadow" style={{ width: "16rem" }}>
                {/* <FlowSideBar /> */}
                <BotLayoutLeftPanel
                  title="Flows"
                  sideBarList={context.subPathList}
                  value={context.subFlowName}
                  onChange={context.setSubFlowName}
                  onKeyPress={context.addNewSubFlow}
                  onMenuSelect={(pathName, i) => {
                    if (
                      pathName === botService.getSelectedBot().bot_name &&
                      this.props.permissions &&
                      this.props.permissions.flowChart.list === "enabled"
                    ) {
                      context.subPathList.map((s) => (s.isSelected = false));
                      this.context.getFullPath(this.state.paths);
                      this.setState({ fullPath: true });
                    } else if (
                      this.props.permissions &&
                      this.props.permissions.flowChart.list === "enabled"
                    ) {
                      context.subPathList.map(
                        (s) => (s.isSelected = s.label === pathName)
                      );
                      context.getSubFlow(pathName, !i && true);
                      this.setName(pathName);
                    }
                  }}
                  addBtnHide={{
                    new:
                      this.props.permissions &&
                      this.props.permissions.flowChart.add,
                  }}
                />
              </div>
              <div
                className={`col border ${this.state.fullPath && "px-0"}  `}
                style={{ background: "#f3f4f5" }}
              >
                <div className="p-1 d-flex justify-content-between">
                  <div className="row m-0">
                    <div className="col-auto d-flex align-items-center">
                      {context.currentPath &&
                        context.currentPath !== "main_path" &&
                        !this.state.fullPath &&
                        svgIcons("edit")}
                    </div>
                    <Input
                      className="bot-content-title fs-6 col"
                      readOnly={
                        this.state.subPathName === "Main Path" ||
                        (this.props.permissions &&
                          this.props.permissions.flowChart.edit ===
                            "disabled") ||
                        this.state.fullPath
                      }
                      value={
                        this.state.fullPath
                          ? botService.getSelectedBot().bot_name
                          : this.state.subPathName
                      }
                      onChange={(e) => this.handleNameChange(e)}
                      onKeyPress={(e) => this.handleNameChange(e)}
                      onBlur={() => this.handleRenameBlur()}
                    />
                  </div>
                  {context.currentPath &&
                    context.currentPath !== "main_path" &&
                    this.props.permissions.flowChart.edit !== "disabled" &&
                    !this.state.fullPath && (
                      <DropDown
                        dropdownList={[
                          {
                            label: "Delete",
                            icon: this.getDeleteModal(),
                          },
                        ]}
                        actionButton={() =>
                          svgIcons("verticalOptions", "black")
                        }
                        onActionItemClick={(menu) => {
                          this.setState({ isModal: true });
                          setTimeout(() => {
                            this.setState({ isModal: false });
                          }, 100);
                        }}
                        isContent={true}
                      />
                    )}
                </div>
                <div style={{ height: `calc(100% - 2.5rem)` }}>
                  <NodeTree
                    elements={
                      context && this.state.fullPath
                        ? context.fullPathList
                        : context.flowData
                    }
                    onHover={context.addPlaceHolderNode}
                    paths={this.state.paths}
                    onMouseLeave={context.onMouseLeave}
                    addNode={context.addNode}
                    intents={this.state.intents}
                    setZoom={context.setZoom}
                    getZoom={context.getZoom}
                    onDragUpdatePosition={context.onDragUpdatePosition}
                    disableDrag={context && this.state.fullPath}
                  />
                </div>
              </div>
            </>
          )}
        </BotContext.Consumer>
      </div>
    );
  }
}

export default function FlowChart() {
  const { setNotifications } = useContext(NotificationContext);
  const { getUserDetails, setUserFlows, getUserFlows } =
    useContext(UserContext);
  return (
    <FlowChartComp
      setNotifications={setNotifications}
      setUserFlows={setUserFlows}
      getUserFlows={getUserFlows}
      permissions={getUserDetails().feature_permission}
    />
  );
}
